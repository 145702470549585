import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"


const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
        e.preventDefault();
    localStorage.setItem('firstTimeCustomer',false);
    console.log((localStorage.getItem('firstTimeCustomer')));
    childToParentCus1e3(dataCus1);
    }
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({

          NumberForeignTrips: '',
          TravelLocation1: '',
          StayingTime1: '',
          ReasonTravel1: '',
          VisitedCities1: '',
          TravelLocation2: '',
          StayingTime2: '',
          ReasonTravel2: '',
          VisitedCities2: '',
          TravelLocation3: '',
          StayingTime3: '',
          ReasonTravel3: '',
          VisitedCities3: '',
          TravelLocation4: '',
          StayingTime4: '',
          ReasonTravel4: '',
          VisitedCities4: '',
          SchengenNumber: '',
          SpecialVisaNumber1: '',
          VisaName1: '',
          SpecialVisaNumber2: '',
          VisaName2: '',
          SpecialVisaNumber3: '',
          VisaName3: '',
          SpecialVisaNumber4: '',
          VisaName4: '',
          SpecialVisaNumber5: '',
          VisaName5: '',
          SpecialVisaNumber6: '',
          VisaName6: '',
          TheCountryRejected1:'',
          RejectVisaType1:'',
          ReasonRejection1: '',
          TheCountryRejected2:'',
          RejectVisaType2:'',
          ReasonRejection2: '',
          TheCountryRejected3:'',
          RejectVisaType3:'',
          ReasonRejection3: '',
          TheCountryRejected4:'',
          RejectVisaType4:'',
          ReasonRejection4: '',
          TheCountryRejected5:'',
          RejectVisaType5:'',
          ReasonRejection5: '',
          TheCountryRejected6:'',
          RejectVisaType6:'',
          ReasonRejection6: '',
          proEmail: '',
          proPhone: '',
          proCallNumber:"",
          proPhone2: '',
          proCallNumber2:"",
          proAbout: '',
          firstTimeEdit:'',
          firstTimeEditJob:'',
          firstTimeEditEducational:'',
          firstTimeEditAddress:true,
          firstTimeEditPersonal:true,
          // ProPic: '',
          Post1: '',
          Workplace1: '',
          Post2: '',
          Workplace2: '',
          Post3: '',
          Workplace3: '',
          Post4: '',
          Workplace4: '',
          Post5: '',
          Workplace5: '',
          Post6: '',
          Workplace6: '',
          Address: '',
          ZipCode: '',
          Address2: '',
          ZipCode2: '',
          placeofBirth:'',
          proNo:'61',
          Level: '',
          FieldOfStudy: '',
          University: '',
          JobCountry: '',
          JobCity:'',
          Jobspecialty: '',
          PassportNumber: '',
          Name:'',
          Family:'',
          DateofCreation: '',
          ExpireDate:'',
          WorkExperience:'',
          TotalScoreDegreeTOEFL:'',
          TotalScoreDegreeOtherOne:'',
          TotalScoreDegreeOtherTwo:'',
          TotalScoreDegreeOtherThree:'',
          CountryOFPass:'',
          BirthDayOnPass:'',
          BirthCertificateNumber:'',
          PlaceIssuanceNationalCard:'',
          FatherBirthCertificateNumber:'',
          MothersBirthCertificateNumber:'',
          AliasName:'',
          MilitaryCardNumber:'',
          TypeMilitaryCard:'',
          PlaceMilitaryService:'',
          IELTSReadingScore:'',
          IELTSWritingScore:'',
          IELTSListeningScore:'',
          IELTSSpeackingScore:'',
          TOEFLReadingScore:'',
          TOEFLWritingScore:'',
          TOEFLListeningScore:'',
          TOEFLSpeackingScore:'',
          OtherOneReadingScore:'',
          OtherOneWritingScore:'',
          OtherOneListeningScore:'',
          OtherOneSpeackingScore:'',
          OtherTwoReadingScore:'',
          OtherTwoWritingScore:'',
          OtherTwoListeningScore:'',
          OtherTwoSpeackingScore:'',
          OtherThreeReadingScore:'',
          OtherThreeWritingScore:'',
          OtherThreeListeningScore:'',
          OtherThreeSpeackingScore:'',
          WorkplaceCallNumber2:'',
          FormTrackingNumber:'',
          TypeMarriage:'',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };
console.log(dataC);
        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


            const [image13 , setImage13] = useState(null);
            const   onImage13Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader13 = new FileReader();
                  reader13.onload = (e) => {
                    setImage13(e.target.result);
                    document.getElementById('target13').style.display ="block";
                    document.getElementById('MyGrayBox13').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader13.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage13 = () =>{
                document.getElementById('target13').style.display ="none";
                document.getElementById('MyGrayBox13').style.display= "flex";
              }


          const [image14 , setImage14] = useState(null);
        const   onImage14Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader14 = new FileReader();
              reader14.onload = (e) => {
                setImage14(e.target.result);
                document.getElementById('target14').style.display ="block";
                document.getElementById('MyGrayBox14').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader14.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage14 = () =>{
            document.getElementById('target14').style.display ="none";
            document.getElementById('MyGrayBox14').style.display= "flex";
          }
                
        const [DateFirstJob, setDateFirstJob] = useState()
        const [Date2Job, setDate2Job] = useState()
        const [Date3Job, setDate3Job] = useState()
        const [Date4Job, setDate4Job] = useState()
        const [Date5Job, setDate5Job] = useState()
        const [Date6Job, setDate6Job] = useState()
        const [ArrivalTravelDate1, setArrivalTravelDate1] = useState()
        const [DepartureTravelDate1, setDepartureTravelDate1] = useState()
        const [ArrivalTravelDate2, setArrivalTravelDate2] = useState()
        const [DepartureTravelDate2, setDepartureTravelDate2] = useState()
        const [ArrivalTravelDate3, setArrivalTravelDate3] = useState()
        const [DepartureTravelDate3, setDepartureTravelDate3] = useState()
        const [ArrivalTravelDate4, setArrivalTravelDate4] = useState()
        const [DepartureTravelDate4, setDepartureTravelDate4] = useState()
        const [SchengenIssuanceDate, setSchengenIssuanceDate] = useState()
        const [ExpiryDateResidenceDestination1, setExpiryDateResidenceDestination1] = useState()
        const [ExpiryDateResidenceDestination2, setExpiryDateResidenceDestination2] = useState()
        const [ExpiryDateResidenceDestination3, setExpiryDateResidenceDestination3] = useState()
        const [DateApplicationRejectedVisa1, setDateApplicationRejectedVisa1] = useState()
        const [DateApplicationRejectedVisa2, setDateApplicationRejectedVisa2] = useState()
        const [DateApplicationRejectedVisa3, setDateApplicationRejectedVisa3] = useState()
        const [DateApplicationRejectedVisa4, setDateApplicationRejectedVisa4] = useState()
        const [DateApplicationRejectedVisa5, setDateApplicationRejectedVisa5] = useState()
        const [DateApplicationRejectedVisa6, setDateApplicationRejectedVisa6] = useState()
        const [PriorRejectionDate2, setPriorRejectionDate2] = useState()
        const [PriorRejectionDate3, setPriorRejectionDate3] = useState()
        const [PriorRejectionDate4, setPriorRejectionDate4] = useState()
        const [PriorRejectionDate5, setPriorRejectionDate5] = useState()
        const [PriorRejectionDate6, setPriorRejectionDate6] = useState()
        const [PriorRejectionDate1, setPriorRejectionDate1] = useState()
        const [LicenseExpirationDate1, setLicenseExpirationDate1] = useState()
        const [LicenseExpirationDate2, setLicenseExpirationDate2] = useState()
        const [LicenseExpirationDate3, setLicenseExpirationDate3] = useState()
        const [LicenseExpirationDate4, setLicenseExpirationDate4] = useState()
        const [LicenseExpirationDate5, setLicenseExpirationDate5] = useState()
        const [LicenseExpirationDate6, setLicenseExpirationDate6] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 5')} :</p><p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant Travel Information')}{localStorage.getItem('whoIs')=='sp' && t('Applicant Sopuse Travel Information')}</p> <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Foreign Trips')}</p> </div>
          
          <div className="Cus2Inputs">

          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="NumberForeignTrips" 
                value={values.NumberForeignTrips}
                type="text"
                placeholder={t('Number of Foreign Trips')}
                onChange={handleChange('NumberForeignTrips')}
                required
                />
                <label htmlFor="NumberForeignTrips">{t('Enter the applicants Number of Foreign Trips')}<p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>
            
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Details of Foreign Trips')}</p> 
          
          </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ArrivalTravelDate1}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Arrival Travel Date (1)')}
              id="ArrivalTravelDate1"
              onChange={setArrivalTravelDate1} 
              className="col-12"
            />}
              <label htmlFor="ArrivalTravelDate1">{t('Enter the applicants Arrival Travel Date (1)')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DepartureTravelDate1}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Departure Travel Date (1)')}
              id="DepartureTravelDate1"
              onChange={setDepartureTravelDate1} 
              className="col-12"
            />}
              <label htmlFor="DepartureTravelDate1">{t('Enter the applicants Departure Travel Date (1)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TravelLocation1" 
                value={values.TravelLocation1}
                type="text"
                placeholder={t('Travel Location (1)')}
                onChange={handleChange('TravelLocation1')}
                />
                <label htmlFor="TravelLocation1">{t('Enter the applicants Travel Location (1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="StayingTime1" 
                value={values.StayingTime1}
                type="text"
                placeholder={t('Staying Time (1)')}
                onChange={handleChange('StayingTime1')}
                />
                <label htmlFor="StayingTime1">{t('Enter the applicants Staying Time (1)')}</label>
            </div>

            

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="VisitedCities1" 
                value={values.VisitedCities1}
                type="text"
                placeholder={t('Cities visited in the destination country (1)')}
                onChange={handleChange('VisitedCities1')}
                />
                <label htmlFor="VisitedCities1">{t('Enter the applicants Cities visited in the destination country (1)')}</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="ReasonTravel1" 
                value={values.ReasonTravel1}
                type="text"
                placeholder={t('Reason for Travel (1)')}
                onChange={handleChange('ReasonTravel1')}
                maxLength="20"
                />
                <label htmlFor="ReasonTravel1">{t('Enter the applicants Reason for Travel (1) ')}<p className="ReqiredCus">{t('- in 20 Character')}</p></label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ArrivalTravelDate2}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Arrival Travel Date (2)')}
              id="ArrivalTravelDate2"
              onChange={setArrivalTravelDate2} 
              className="col-12"
            />}
              <label htmlFor="ArrivalTravelDate2">{t('Enter the applicants Arrival Travel Date (2)')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DepartureTravelDate2}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Departure Travel Date (2)')}
              id="DepartureTravelDate2"
              onChange={setDepartureTravelDate2} 
              className="col-12"
            />}
              <label htmlFor="DepartureTravelDate2">{t('Enter the applicants Departure Travel Date (2)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TravelLocation2" 
                value={values.TravelLocation2}
                type="text"
                placeholder={t('Travel Location (2)')}
                onChange={handleChange('TravelLocation2')}
                />
                <label htmlFor="TravelLocation2">{t('Enter the applicants Travel Location (2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="StayingTime2" 
                value={values.StayingTime2}
                type="text"
                placeholder={t('Staying Time (2)')}
                onChange={handleChange('StayingTime2')}
                />
                <label htmlFor="StayingTime2">{t('Enter the applicants Staying Time (2)')}</label>
            </div>

            

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="VisitedCities2" 
                value={values.VisitedCities2}
                type="text"
                placeholder={t('Cities visited in the destination country (2)')}
                onChange={handleChange('VisitedCities2')}
                />
                <label htmlFor="VisitedCities2">{t('Enter the applicants Cities visited in the destination country (1)')}</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="ReasonTravel2" 
                value={values.ReasonTravel2}
                type="text"
                placeholder={t('Reason for Travel (2)')}
                onChange={handleChange('ReasonTravel2')}
                maxLength="20"
                />
                <label htmlFor="ReasonTravel2">{t('Enter the applicants Reason for Travel (2) ')}<p className="ReqiredCus">{t('- in 20 Character')}</p></label>
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ArrivalTravelDate3}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Arrival Travel Date (3)')}
              id="ArrivalTravelDate3"
              onChange={setArrivalTravelDate3} 
              className="col-12"
            />}
              <label htmlFor="ArrivalTravelDate3">{t('Enter the applicants Arrival Travel Date (3)')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DepartureTravelDate3}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Departure Travel Date (3)')}
              id="DepartureTravelDate3"
              onChange={setDepartureTravelDate3} 
              className="col-12"
            />}
              <label htmlFor="DepartureTravelDate3">{t('Enter the applicants Departure Travel Date (3)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TravelLocation3" 
                value={values.TravelLocation3}
                type="text"
                placeholder={t('Travel Location (3)')}
                onChange={handleChange('TravelLocation3')}
                />
                <label htmlFor="TravelLocation3">{t('Enter the applicants Travel Location (3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="StayingTime3" 
                value={values.StayingTime3}
                type="text"
                placeholder={t('Staying Time (3)')}
                onChange={handleChange('StayingTime3')}
                />
                <label htmlFor="StayingTime3">{t('Enter the applicants Staying Time (3)')}</label>
            </div>

            

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="VisitedCities3" 
                value={values.VisitedCities3}
                type="text"
                placeholder={t('Cities visited in the destination country (3)')}
                onChange={handleChange('VisitedCities3')}
                />
                <label htmlFor="VisitedCities3">{t('Enter the applicants Cities visited in the destination country (3)')}</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="ReasonTravel3" 
                value={values.ReasonTravel3}
                type="text"
                placeholder={t('Reason for Travel (3)')}
                onChange={handleChange('ReasonTravel3')}
                maxLength="20"
                />
                <label htmlFor="ReasonTravel3">{t('Enter the applicants Reason for Travel (3) ')}<p className="ReqiredCus">{t('- in 20 Character')}</p></label>
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ArrivalTravelDate4}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Arrival Travel Date (4)')}
              id="ArrivalTravelDate4"
              onChange={setArrivalTravelDate4} 
              className="col-12"
            />}
              <label htmlFor="ArrivalTravelDate4">{t('Enter the applicants Arrival Travel Date (4)')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DepartureTravelDate4}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Departure Travel Date (4)')}
              id="DepartureTravelDate4"
              onChange={setDepartureTravelDate4} 
              className="col-12"
            />}
              <label htmlFor="DepartureTravelDate4">{t('Enter the applicants Departure Travel Date (4)')}</label>
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TravelLocation4" 
                value={values.TravelLocation4}
                type="text"
                placeholder={t('Travel Location (4)')}
                onChange={handleChange('TravelLocation4')}
                />
                <label htmlFor="TravelLocation4">{t('Enter the applicants Travel Location (4)')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="StayingTime4" 
                value={values.StayingTime4}
                type="text"
                placeholder={t('Staying Time (4)')}
                onChange={handleChange('StayingTime4')}
                />
                <label htmlFor="StayingTime4">{t('Enter the applicants Staying Time (4)')}</label>
            </div>

            

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="VisitedCities4" 
                value={values.VisitedCities4}
                type="text"
                placeholder={t('Cities visited in the destination country (4)')}
                onChange={handleChange('VisitedCities4')}
                />
                <label htmlFor="VisitedCities4">{t('Enter the applicants Cities visited in the destination country (4)')}</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="ReasonTravel4" 
                value={values.ReasonTravel4}
                type="text"
                placeholder={t('Reason for Travel (4)')}
                onChange={handleChange('ReasonTravel4')}
                maxLength="20"
                />
                <label htmlFor="ReasonTravel4">{t('Enter the applicants Reason for Travel (4) ')}<p className="ReqiredCus">{t('- in 20 Character')}</p></label>
            </div>


            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Schengen')}</p> 
          
          </div>

          <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SchengenNumber" 
                value={values.SchengenNumber}
                type="text"
                placeholder={t('Schengen Number')}
                onChange={handleChange('SchengenNumber')}
                />
                <label htmlFor="SchengenNumber">{t('Enter the applicants Schengen Number')}</label>
            </div>

            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={SchengenIssuanceDate}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Schengen Issuance Date')}
              id="SchengenIssuanceDate"
              onChange={setSchengenIssuanceDate} 
              className="col-12"
            />}
              <label htmlFor="SchengenIssuanceDate">{t('Enter the applicants Schengen Issuance Date')}</label>
          </div>
          



          <div className="Cus2PersonalInfo col-12"> 
             <p>{t('Special Visa')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber1" 
                value={values.SpecialVisaNumber1}
                type="text"
                placeholder={t('Special Visa Number (1)')}
                onChange={handleChange('SpecialVisaNumber1')}
                />
                <label htmlFor="SpecialVisaNumber1">{t('Enter the applicants Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName1" 
                value={values.VisaName1}
                type="text"
                placeholder={t('Visa Name (1)')}
                onChange={handleChange('VisaName1')}
                />
                <label htmlFor="VisaName1">{t('Enter the applicants Visa Name')}</label>
            </div>



            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber2" 
                value={values.SpecialVisaNumber2}
                type="text"
                placeholder={t('Special Visa Number (2)')}
                onChange={handleChange('SpecialVisaNumber2')}
                />
                <label htmlFor="SpecialVisaNumber2">{t('Enter the applicants Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName2" 
                value={values.VisaName2}
                type="text"
                placeholder={t('Visa Name (2)')}
                onChange={handleChange('VisaName2')}
                />
                <label htmlFor="VisaName2">{t('Enter the applicants Visa Name')}</label>
            </div>



            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber3" 
                value={values.SpecialVisaNumber3}
                type="text"
                placeholder={t('Special Visa Number (3)')}
                onChange={handleChange('SpecialVisaNumber3')}
                />
                <label htmlFor="SpecialVisaNumber3">{t('Enter the applicants Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName3" 
                value={values.VisaName3}
                type="text"
                placeholder={t('Visa Name (3)')}
                onChange={handleChange('VisaName3')}
                />
                <label htmlFor="VisaName3">{t('Enter the applicants Visa Name')}</label>
            </div>


            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber4" 
                value={values.SpecialVisaNumber4}
                type="text"
                placeholder={t('Special Visa Number (4)')}
                onChange={handleChange('SpecialVisaNumber4')}
                />
                <label htmlFor="SpecialVisaNumber4">{t('Enter the applicants Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName4" 
                value={values.VisaName4}
                type="text"
                placeholder={t('Visa Name (4)')}
                onChange={handleChange('VisaName4')}
                />
                <label htmlFor="VisaName4">{t('Enter the applicants Visa Name')}</label>
            </div>



            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber5" 
                value={values.SpecialVisaNumber5}
                type="text"
                placeholder={t('Special Visa Number (5)')}
                onChange={handleChange('SpecialVisaNumber5')}
                />
                <label htmlFor="SpecialVisaNumber5">{t('Enter the applicants Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName5" 
                value={values.VisaName5}
                type="text"
                placeholder={t('Visa Name (5)')}
                onChange={handleChange('VisaName5')}
                />
                <label htmlFor="VisaName5">{t('Enter the applicants Visa Name')}</label>
            </div>



            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="SpecialVisaNumber6" 
                value={values.SpecialVisaNumber6}
                type="text"
                placeholder={t('Special Visa Number (6)')}
                onChange={handleChange('SpecialVisaNumber6')}
                />
                <label htmlFor="SpecialVisaNumber6">{t('Enter the applicants Special Visa Number')}</label>
            </div>
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="VisaName6" 
                value={values.VisaName6}
                type="text"
                placeholder={t('Visa Name (6)')}
                onChange={handleChange('VisaName6')}
                />
                <label htmlFor="VisaName6">{t('Enter the applicants Visa Name')}</label>
            </div>


            <div className="Cus2PersonalInfo col-12"> 
             <p>{t('Residence of The Destination Country')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ExpiryDateResidenceDestination1}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Expiry date of residence of the destination country (1)')}
              id="ExpiryDateResidenceDestination1"
              onChange={setExpiryDateResidenceDestination1} 
              className="col-12"
            />}
              <label htmlFor="ExpiryDateResidenceDestination1">{t('Enter the applicants Expiry date of residence of the destination country')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ExpiryDateResidenceDestination2}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Expiry date of residence of the destination country (2)')}
              id="ExpiryDateResidenceDestination2"
              onChange={setExpiryDateResidenceDestination2} 
              className="col-12"
            />}
              <label htmlFor="ExpiryDateResidenceDestination1">{t('Enter the applicants Expiry date of residence of the destination country')}</label>
          </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={ExpiryDateResidenceDestination3}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Expiry date of residence of the destination country (3)')}
              id="ExpiryDateResidenceDestination3"
              onChange={setExpiryDateResidenceDestination3} 
              className="col-12"
            />}
              <label htmlFor="ExpiryDateResidenceDestination3">{t('Enter the applicants Expiry date of residence of the destination country')}</label>
          </div>


          <div className="Cus2PersonalInfo col-12"> 
             <p>{t('Rejected Visa')}</p> 
          
          </div>

           
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate1}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (1)')}
              id="PriorRejectionDate1"
              onChange={setPriorRejectionDate1} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate1">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected1" 
                value={values.TheCountryRejected1}
                type="text"
                placeholder={t('The country you rejected (1)')}
                onChange={handleChange('TheCountryRejected1')}
                />
                <label htmlFor="TheCountryRejected1">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType1" 
                value={values.RejectVisaType1}
                type="text"
                placeholder={t('Rejected Visa Type (1)')}
                onChange={handleChange('RejectVisaType1')}
                />
                <label htmlFor="RejectVisaType1">{t('Enter the applicants Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa1}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (1)')}
              id="DateApplicationRejectedVisa1"
              onChange={setDateApplicationRejectedVisa1} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa1">{t('Enter the applicants Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection1" 
                value={values.ReasonRejection1}
                type="text"
                placeholder={t('Reason for Rejection (1)')}
                onChange={handleChange('ReasonRejection1')}
                />
                <label htmlFor="ReasonRejection1">{t('Enter the applicants Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate2}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (2)')}
              id="PriorRejectionDate2"
              onChange={setPriorRejectionDate2} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate2">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected2" 
                value={values.TheCountryRejected2}
                type="text"
                placeholder={t('The country you rejected (2)')}
                onChange={handleChange('TheCountryRejected2')}
                />
                <label htmlFor="TheCountryRejected2">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType2" 
                value={values.RejectVisaType2}
                type="text"
                placeholder={t('Rejected Visa Type (2)')}
                onChange={handleChange('RejectVisaType2')}
                />
                <label htmlFor="RejectVisaType2">{t('Enter the applicants Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa2}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (2)')}
              id="DateApplicationRejectedVisa2"
              onChange={setDateApplicationRejectedVisa2} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa2">{t('Enter the applicants Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection2" 
                value={values.ReasonRejection2}
                type="text"
                placeholder={t('Reason for Rejection (2)')}
                onChange={handleChange('ReasonRejection2')}
                />
                <label htmlFor="ReasonRejection2">{t('Enter the applicants Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate3}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (3)')}
              id="PriorRejectionDate3"
              onChange={setPriorRejectionDate3} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate3">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected3" 
                value={values.TheCountryRejected3}
                type="text"
                placeholder={t('The country you rejected (3)')}
                onChange={handleChange('TheCountryRejected3')}
                />
                <label htmlFor="TheCountryRejected3">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType3" 
                value={values.RejectVisaType3}
                type="text"
                placeholder={t('Rejected Visa Type (3)')}
                onChange={handleChange('RejectVisaType3')}
                />
                <label htmlFor="RejectVisaType3">{t('Enter the applicants Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa3}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (3)')}
              id="DateApplicationRejectedVisa3"
              onChange={setDateApplicationRejectedVisa3} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa3">{t('Enter the applicants Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection3" 
                value={values.ReasonRejection3}
                type="text"
                placeholder={t('Reason for Rejection (3)')}
                onChange={handleChange('ReasonRejection3')}
                />
                <label htmlFor="ReasonRejection3">{t('Enter the applicants Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate4}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (4)')}
              id="PriorRejectionDate4"
              onChange={setPriorRejectionDate4} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate4">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected4" 
                value={values.TheCountryRejected4}
                type="text"
                placeholder={t('The country you rejected (4)')}
                onChange={handleChange('TheCountryRejected4')}
                />
                <label htmlFor="TheCountryRejected4">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType4" 
                value={values.RejectVisaType4}
                type="text"
                placeholder={t('Rejected Visa Type (4)')}
                onChange={handleChange('RejectVisaType4')}
                />
                <label htmlFor="RejectVisaType4">{t('Enter the applicants Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa4}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (4)')}
              id="DateApplicationRejectedVisa4"
              onChange={setDateApplicationRejectedVisa4} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa4">{t('Enter the applicants Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection4" 
                value={values.ReasonRejection4}
                type="text"
                placeholder={t('Reason for Rejection (4)')}
                onChange={handleChange('ReasonRejection4')}
                />
                <label htmlFor="ReasonRejection4">{t('Enter the applicants Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>



            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate5}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (5)')}
              id="PriorRejectionDate5"
              onChange={setPriorRejectionDate5} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate5">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected5" 
                value={values.TheCountryRejected5}
                type="text"
                placeholder={t('The country you rejected (5)')}
                onChange={handleChange('TheCountryRejected5')}
                />
                <label htmlFor="TheCountryRejected5">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType5" 
                value={values.RejectVisaType5}
                type="text"
                placeholder={t('Rejected Visa Type (5)')}
                onChange={handleChange('RejectVisaType5')}
                />
                <label htmlFor="RejectVisaType5">{t('Enter the applicants Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa5}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (5)')}
              id="DateApplicationRejectedVisa5"
              onChange={setDateApplicationRejectedVisa5} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa5">{t('Enter the applicants Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection5" 
                value={values.ReasonRejection5}
                type="text"
                placeholder={t('Reason for Rejection (5)')}
                onChange={handleChange('ReasonRejection5')}
                />
                <label htmlFor="ReasonRejection5">{t('Enter the applicants Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={PriorRejectionDate6}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Prior Rejection Date (6)')}
              id="PriorRejectionDate6"
              onChange={setPriorRejectionDate6} 
              className="col-12"
            />}
              <label htmlFor="PriorRejectionDate6">{t('Enter the applicants Prior Rejection Date')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="TheCountryRejected6" 
                value={values.TheCountryRejected6}
                type="text"
                placeholder={t('The country you rejected (6)')}
                onChange={handleChange('TheCountryRejected6')}
                />
                <label htmlFor="TheCountryRejected6">{t('Enter the applicants The Country You Rejected')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="RejectVisaType6" 
                value={values.RejectVisaType6}
                type="text"
                placeholder={t('Rejected Visa Type (6)')}
                onChange={handleChange('RejectVisaType6')}
                />
                <label htmlFor="RejectVisaType6">{t('Enter the applicants Rejected Visa Type')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateApplicationRejectedVisa6}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Application for Rejected Visa (6)')}
              id="DateApplicationRejectedVisa6"
              onChange={setDateApplicationRejectedVisa6} 
              className="col-12"
            />}
              <label htmlFor="DateApplicationRejectedVisa6">{t('Enter the applicants Date of Application for Rejected Visa')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ReasonRejection6" 
                value={values.ReasonRejection6}
                type="text"
                placeholder={t('Reason for Rejection (6)')}
                onChange={handleChange('ReasonRejection6')}
                />
                <label htmlFor="ReasonRejection6">{t('Enter the applicants Reason for Rejection')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>

          </div>
          <div className="Cus2PersonalInfo col-12Midline"> 
          <p>{localStorage.getItem('whoIs')=='ap' && t('Applicants Travel Documents')}
          {localStorage.getItem('whoIs')=='sp' && t('Applicants Spouse Travel Documents')}</p> 
          
          </div>
          
          
   <div className="MyFileUploadRow">

   <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImageChange} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target" src={image} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Old Passport (1)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox2">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input
                 className="MyCusInputFileUpload"
                 type="file" 
                 onChange={onImage2Change} 
                 id="group_image"
                 accept="image/gif, image/jpeg, image/png"
                 />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Old Passport (2)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox3">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage3Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Old Passport (3)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox4">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage4Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Special or Revoked Visa')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox5">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage5Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('New Visa in The New Passport')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox6">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage6Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Residence documents of a specific country')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox7">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage7Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (1)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox8">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage8Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (2)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox9">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage9Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target9" src={image9} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage9} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (3)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox10">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage10Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target10" src={image10} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage10} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (4)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox11">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage11Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target11" src={image11} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage11} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (5)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox12">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage12Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target12" src={image12} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage12} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Documents related to the rejected file (6)')}</p> 
          </div>
          </div>


          {/* <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox13">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage13Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> 
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target13" src={image13} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage13} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Responsibility Warrant')}</p> 
          </div>
          </div> */}


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox14">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage14Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target14" src={image14} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage14} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Documents')}</p> 
          </div>
          </div>


          
         

   </div>


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {<button className="btn btn-dark">{t('Submit')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>

        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
        </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;