import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,CloseCircle} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const Profile2 = ({childToParentCus9,childToParentCus11}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    const forceUpdate = useForceUpdate();
    //****************submits**********************************/
    const [cusJobs , setCusJobs] = useState([
      { Numberofchildren: '5', Firstlastname: 'محسن یگانه', Dateofbirth: '1990/01/05', Passportnumber:'fd66321', Cityofbirth:'تبریز', Departuredate:'2022/08/01', key:'1'},
      {Numberofchildren: '5', Firstlastname: 'سمیرا محدث', Dateofbirth: '1990/01/05', Passportnumber:'fd66321', Cityofbirth:'تبریز', Departuredate:'2022/08/01', key:'2'},
      {Numberofchildren: '5', Firstlastname: 'رضا صادقی', Dateofbirth: '1990/01/05', Passportnumber:'fd66321', Cityofbirth:'تبریز', Departuredate:'2022/08/01', key:'3'},
      {Numberofchildren: '5', Firstlastname: 'ابراهیم محبی', Dateofbirth: '1990/01/05', Passportnumber:'fd66321', Cityofbirth:'تبریز', Departuredate:'2022/08/01', key:'4'},
    ]);
  const handleAddJob =(e) => {
    e.preventDefault();
    cusJobs.push({ 
      Numberofchildren: values.Numberofchildren,
      Firstlastname: values.Firstlastname,
      Dateofbirth: values.Dateofbirth,
      Passportnumber:values.Passportnumber,
      Cityofbirth:values.Cityofbirth,
      Departuredate:values.Departuredate,
      key:(cusJobs.length+1).toString(),
     },);
      forceUpdate();
      setCusJobs(cusJobs);
  }
  const handleRemoveCusJobs = (itemKey)=>{
    setCusJobs(cusJobs.filter(cusJobs => cusJobs.key != itemKey.toString()));
  }
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepOne' , {
        //   method: 'PUT',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ 
        //     // Email: localStorage.email3 ,
        //     // Password:localStorage.password3 ,
        //     FullName:"",
        //     Gender:"",
        //     DateOfBirth:"",
        //     AboutMe:""
        //   })
        //   }).then().then(history.push('/Dashboard'));
        // //     setIspending(false);
        // document.getElementById('personalCancel').style.display="none";
        // document.getElementById('personalSave').style.display="none";
        // document.getElementById('personPen').style.display="none";
        // document.getElementById('personPenBlue').style.display="none";
        // document.getElementById('proFirstName').setAttribute("disabled","disabled");
        // document.getElementById('proLastName').setAttribute("disabled","disabled");
        // document.getElementById('Gender').setAttribute("disabled","disabled");
        // //document.getElementById('birthDate').setAttribute("disabled","disabled");
     
        // document.getElementById('inputproAbout').setAttribute("disabled","disabled");
        // setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }

        const [values, setValues] = useState({
 
          Numberofchildren: '',
          Firstlastname: '',
          Dateofbirth: '',
          Passportnumber:'',
          Cityofbirth:'',
          Departuredate:'',
  
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });

        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus9 = true;
        const dataCus11 = true;
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <form onSubmit={handleSubmitPersonal} className="col-12">
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">Step 1 :</p><p className="Cus2Step1BlackP">Complete Basic Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div> <p className="Cus2Step1SmallP">Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy</p></div>
          <div className="Cus2PersonalInfo"> <p>Children Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div className="Cus2Inputs">
            <div className="inputAndlabel col-4">
            <input
                id="Numberofchildren" 
                value={values.Numberofchildren}
                type="text"
                placeholder="Number of children"
                onChange={handleChange('Numberofchildren')}
                />
                <label htmlFor="Numberofchildren">Enter the number of applicant children</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Numberofjobs" 
                value={values.Numberofjobs}
                type="text"
                placeholder="Number of jobs"
                onChange={handleChange('Numberofjobs')}
                hidden
                />
                <label hidden htmlFor="Numberofjobs">Enter the applicant's last degree</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Numberofjobs" 
                value={values.Numberofjobs}
                type="text"
                placeholder="Number of jobs"
                onChange={handleChange('Numberofjobs')}
                hidden
                />
                <label hidden htmlFor="Numberofjobs">Enter the applicant's last degree</label>
            </div>
            <div className="mainGrayListRows col-12">
              {cusJobs.map(item =>(
                      <div key={item.key}>
                        <div className="grayListRow col-12">
                          <div className="GrayListBox">
                            <p><b>{item.Firstlastname}:</b>  {item.Passportnumber }</p>
                            <CloseCircle onClick={()=>handleRemoveCusJobs(item.key)} size="28" color="#9B9B9B" variant="Bold"/>
                          </div>
                        </div>
                      </div>))}
            </div>
           
            <div className="inputAndlabel col-4">
              <input
                id="Firstlastname" 
                value={values.Firstlastname}
                type="text"
                placeholder="First and last name"
                onChange={handleChange('Firstlastname')}
                />
                <label htmlFor="Firstlastname">Enter the first and last name of the applicant child</label>
            </div>

            <div className="inputAndlabel col-4">
              <input
                id="Dateofbirth" 
                value={values.Dateofbirth}
                type="text"
                placeholder="Date of birth"
                onChange={handleChange('Dateofbirth')}
                />
                <label htmlFor="Dateofbirth">Enter the date of birth of the applicant child</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Passportnumber" 
                value={values.Passportnumber}
                type="text"
                placeholder="Passport number"
                onChange={handleChange('Passportnumber')}
                />
                <label htmlFor="Passportnumber">Enter the passport number of the applicant's child</label>
            </div>
            
            <div className="inputAndlabel col-4">
              <input
                id="Cityofbirth" 
                value={values.Cityofbirth}
                type="text"
                placeholder="City of birth"
                onChange={handleChange('Cityofbirth')}
                />
                <label htmlFor="Cityofbirth">Enter the start date of the applicant's job</label>
            </div>
            
            <div className="inputAndlabel col-4">
              <a className="inputAndlabelAddA" onClick={handleAddJob}>+ Add</a>
            </div>
         </div>
         
          <div className="cusS1SaveColP5 col-12">
            <div className="col-6">
               <button  onClick={(dataCus9) => {childToParentCus9(dataCus9);}} className="btn col-12 btn-outline-light ">{t('Previous')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              <button className="btn col-6 btn-outline-light ">
                <ArchiveTick size="32" color="#D3A281"/>{t('Save Draft')}
              </button >
              {<button onClick={(dataCus11) => {childToParentCus11(dataCus11);}}  className="btn btn-dark  col-6">{t('Next Step')}</button>}
            </div>
           
          </div>
        </div>
        </form>
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;