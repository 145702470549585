import { useState, useEffect,useRef} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,ArrowUp2,ArrowCircleLeft,Edit2,ArrowRight2,TickSquare} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"
import axios from 'axios';
function useForceUpdate(){
   
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render

}

const Profile2 = ({childToParentCus1,childToParentCus1e2,childToParentCus1e1}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
    const history = useHistory();
    const forceUpdate = useForceUpdate();
  const handleSubmitPersonal = (e) => {
        e.preventDefault();
    // localStorage.setItem('firstTimeCustomer',false);
    // console.log((localStorage.getItem('firstTimeCustomer')));
    childToParentCus1e2(dataCus1);
    console.log(values.EnglishCheckBox);
    if(!localStorage.getItem('EvFormUpdate')){ 
    fetch('https://telesiness.ir/api/CustomerDocuments/EvaluationForm' , {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({
//Evaluation Customer form
        //Evaluation Customer Form Personal Information
         Email: localStorage.getItem('email'),

         CustomerEvaluationFormFirstName: values.proFirstName,

         CustomerEvaluationFormLastName: values.proLastName,

         CustomerEvaluationFormGender: values.Gender.toString(),

         CustomerEvaluationFormPhoneNumber: values.proPhone,

         CustomerEvaluationFormLandlineNumber: values.proCallNumber,

         CustomerEvaluationFormEmailAddress: values.proEmail,

         CustomerEvaluationFormAddress: values.Address,

         CustomerEvaluationFormCity: values.City,

         CustomerEvaluationFormProvince: values.Country,

         CustomerEvaluationFormCountry: values.Country,

         CustomerEvaluationFormPlaceOfBirth: values.placeofBirth,

         CustomerEvaluationFormAge: parseInt (values.ApplicantAge),

         CustomerEvaluationFormMartialStatus: values.TypeMarriage,

         CustomerEvaluationFormMilitaryServiceStatus: values.militaryservicesituation,

        //Evaluation Customer Form Educational Information
         CustomerEvaluationFormEducationalDegrees: values.LastEducationalCertificate,

         CustomerEvaluationFormFieldOfStudy: values.FieldStudy,

         CustomerEvaluationFormCertificatesNonAcademicNumberOne: values.NonuniversityCertificate1,

         CustomerEvaluationFormCertificatesNonAcademicNumberTwo: values.NonuniversityCertificate2,

         CustomerEvaluationFormCertificatesNonAcademicNumberThree: values.NonuniversityCertificate3,

         CustomersEvaluationFormCertificatesAcknowledgmentNumberOne: values.ScientificDocumentsAwardsFestivals1,

         CustomersEvaluationFormCertificatesAcknowledgmentNumberTwo: values.ScientificDocumentsAwardsFestivals2,

         CustomersEvaluationFormCertificatesAcknowledgmentNumberThree: values.ScientificDocumentsAwardsFestivals3,

         CustomersEvaluationFormResearchNumberOne: values.ResearchandStudies1,

         CustomersEvaluationFormResearchNumberTwo: values.ResearchandStudies2,

         CustomersEvaluationFormResearchNumberThree: values.ResearchandStudies3,

         CustomersEvaluationFormEnglishLanguageCanSpeak: values.EnglishCheckBox,

         CustomerEvaluationFormFamiliarityWithTheEnglishLanguage: values.EnglishFamiliarity,

         CustomerEvaluationFormFinalEnglishTestScore: values.EnglishScore,

         CustomerEvaluationFormFrenchLanguageCanSpeak: values.FrenchCheckBox,

         CustomerEvaluationFormFamiliarityWithTheFrenchLanguage: values.FrenchFamiliarity,

         CustomerEvaluationFormFinalFrenchTestScore: values.FrenchScore,

         CustomerEvaluationFormGermanLanguageCanSpeak: values.GermanCheckBox,

         CustomerEvaluationFormFamiliarityWithTheGermanLanguage: values.GermanFamiliarity,

         CustomerEvaluationFormFinalGermanTestScore: values.GermanScore,

         CustomerEvaluationFormDanishLanguageCanSpeak: values.DanishCheckBox,

         CustomerEvaluationFormFamiliarityWithTheDanishLanguage: values.DanishFamiliarity,

         CustomerEvaluationFormFinalDanishTestScore: values.DanishScore,

         CustomerEvaluationFormArabicLanguageCanSpeak: values.ArabicCheckBox,

         CustomerEvaluationFormFamiliarityWithTheArabicLanguage: values.ArabicFamiliarity,

         CustomerEvaluationFormFinalArabicTestScore: values.ArabicScore,

         CustomerEvaluationFormSpanishLanguageCanSpeak: values.SpanishCheckBox,

         CustomerEvaluationFormFamiliarityWithTheSpanishLanguage: values.SpanishFamiliarity,

         CustomerEvaluationFormFinalSpanishTestScore: values.SpanishScore,

         CustomerEvaluationFormChineseLanguageCanSpeak: values.ChineseCheckBox,

         CustomerEvaluationFormFamiliarityWithTheChineseLanguage: values.ChineseFamiliarity,

         CustomerEvaluationFormFinalChineseTestScore: values.ChineseScore,

         CustomerEvaluationFormKoreanLanguageCanSpeak: values.KoreanCheckBox,

         CustomerEvaluationFormFamiliarityWithTheKoreanLanguage: values.KoreanFamiliarity,

         CustomerEvaluationFormFinalKoreanTestScore: values.KoreanScore,

        //Evaluation Customer Form Job tenure
         CustomerEvaluationFormInsuranceHistoryNumberOne: values.Insurancehistory1,

         CustomerEvaluationFormInsuranceHistoryNumberTwo: values.Insurancehistory2,

         CustomerEvaluationFormInsuranceHistoryNumberThree: values.Insurancehistory3,

         CustomerEvaluationFormCareerNumberOne: values.JobTitle1,

         CustomerEvaluationFormCareerNumberTwo: values.JobTitle2,

         CustomerEvaluationFormCareerNumberThree: values.JobTitle3,

         CustomerEvaluationFormJobPositionNumberOne: values.JobPost1,

         CustomerEvaluationFormJobPositionNumberTwo: values.JobPost2,

         CustomerEvaluationFormJobPositionNumberThree: values.JobPost3,

        //Evaluation Customer Form Financial Information
         CustomerEvaluationFormAnnualTurnover: values.TurnoverNumber,

         CustomerEvaluationFormFinancialLiquidity: values.Liquidity,

         CustomerEvaluationFormTotalAssets: values.TotalAssetsAmount,

        //Evaluation Customer Form Supplementary Information Section One
         CustomerEvaluationFormChildrenUnderFourYearsStatus: values.childunder4yearsold,

         CustomerEvaluationFormChildrenUnderFourYearsQuantity: values.childunder4yearsoldNum,

         CustomerEvaluationFormChildrenUnderFourYearsAge: values.childunder4yearsoldAge,

        //  CustomerEvaluationFormChildrenUnderFourYearsGender: values.child,

        //Children between 4 and 17 years

         CustomerEvaluationFormChildrenBetweenFourAndSeventeenStatus: values.childbetween4and17yearsold,

         CustomerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity: values.childbetween4and17yearsoldNum,

         CustomerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge: values.childbetween4and17yearsoldAge,

        //  CustomerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender: values,

        //Children Over 18 years old
         CustomerEvaluationFormChildrenOverEighteenStatus: values.childover18yearsold,

         CustomerEvaluationFormChildrenOverEighteenYearsQuantity: values.childover18yearsoldNum,

         CustomerEvaluationFormChildrenOverEighteenYearsAge: values.childover18yearsoldAge,

        //  CustomerEvaluationFormChildrenOverEighteenYearsGender: values,

        //Companion
         CustomerEvaluationFormCompanion: values.haveAcompanion,

        //Evaluation Customer Form Supplementary Information Section Two
         CustomerEvaluationFormCountryOfImmigration: values.CountryOFimmigration,

         CustomerEvaluationFormTypeOfVisaRequested: values.TypeofRequestedVisa,

         CustomerEvaluationFormPurposeOfMigration: values.Thepurposeofmigration,

         CustomerEvaluationFormTravelToSchengenAreaOtherPlace: values.TraveledSchengenareaCanada,

         CustomerEvaluationFormNamesOfCountryCustomerVisited: values.NamesofCountriesYouHaveVisited,

         CustomerEvaluationFormRejectionHistoryFromACountry: values.HistoryRejectionCountry,

         CustomerEvaluationFormNamesOfCountriesRejectedLastFiveYears: values.NamesCountriesRejected,

         CustomerEvaluationFormKinInChosenCountry: values.DoYouFirstSecondDegreeRelatives,

         CustomerEvaluationFormExplainKinInChosenCountry: values.Explain,

         CustomerEvaluationFormApplicantFinancialSupporter: values.DoesHaveFinancialSupporter,

         CustomerEvaluationFormExplainFinancialSupporter: values.ExplainFinancialSupporter,

        //Evaluation Customer Form Personal information of the applicant's spouse
         CustomerEvaluationFormSpouseFirstName: values.SpouseFirstName,

         CustomerEvaluationFormSpouseLastName: values.SpouseLastName,

        //Spouse Education
         CustomerEvaluationFormSpouseEducationalDegrees: values.LastEducationalCertificateS,

         CustomerEvaluationFormSpouseFieldOfStudy: values.FieldStudyS,

         CustomersEvaluationFormSpouseEnglishLanguageCanSpeak: EnglishCheckBox,

         CustomerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage: values.EnglishFamiliarityS,

         CustomerEvaluationFormSpouseFinalEnglishTestScore: values.EnglishScoreS,

         CustomerEvaluationFormSpouseFrenchLanguageCanSpeak: values.FrenchCheckBoxS,

         CustomerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage: values.FrenchFamiliarityS,

         CustomerEvaluationFormSpouseFinalFrenchTestScore: values.FrenchScoreS,

         CustomerEvaluationFormSpouseGermanLanguageCanSpeak: values.GermanCheckBoxS,

         CustomerEvaluationFormSpouseFamiliarityWithTheGermanLanguage: values.GermanFamiliarityS,

         CustomerEvaluationFormSpouseFinalGermanTestScore: values.GermanScoreS,

         CustomerEvaluationFormSpouseDanishLanguageCanSpeak: values.DanishCheckBoxS,

         CustomerEvaluationFormSpouseFamiliarityWithTheDanishLanguage: values.DanishFamiliarityS,

         CustomerEvaluationFormSpouseFinalDanishTestScore: values.DanishScoreS,

         CustomerEvaluationFormSpouseArabicLanguageCanSpeak: values.ArabicCheckBoxS,

         CustomerEvaluationFormSpouseFamiliarityWithTheArabicLanguage: values.ArabicFamiliarityS,

         CustomerEvaluationFormSpouseFinalArabicTestScore: values.ArabicScoreS,

         CustomerEvaluationFormSpouseSpanishLanguageCanSpeak: values.SpanishCheckBoxS,

         CustomerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage: values.SpanishFamiliarityS,

         CustomerEvaluationFormSpouseFinalSpanishTestScore: values.SpanishScoreS,

         CustomerEvaluationFormSpouseChineseLanguageCanSpeak: values.ChineseCheckBoxS,

         CustomerEvaluationFormSpouseFamiliarityWithTheChineseLanguage: values.ChineseFamiliarityS,

         CustomerEvaluationFormSpouseFinalChineseTestScore: values.ChineseScoreS,

         CustomerEvaluationFormSpouseKoreanLanguageCanSpeak: values.KoreanCheckBoxS,

         CustomerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage: values.KoreanFamiliarityS,

         CustomerEvaluationFormSpouseFinalKoreanTestScore: values.KoreanScoreS,

        //Spouse Job information
         CustomerEvaluationFormSpouseInsuranceHistory: values.Insurancehistory1S,

         CustomerEvaluationFormSpouseCareer: values.JobPost1S,

         CustomerEvaluationFormSpouseJobPosition: values.JobPost1S,

        //Spouse Companion
         CustomerEvaluationFormSpouseCompanion: values.AccompanyPost,

        //Another descriptions
         CustomerEvaluationFormAnotherDescriptions: values.Descriptions,

    })
   }).then(response => response.json()).then(response=>{

     if(response.statusCode==400 || response.statusCode==404)
     {
       console.log(response.statusMessage);
       console.log(values.EnglishCheckBox);
      //  setFormError(true);
      //  setHelpStatusMessage(response.statusMessage);
      //  setIspending(false);
     }
     else{
      //  setHelpStatusMessage("");
      //  setIspending(false);
      //  history.push('/regstep6');
     }
       // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
       //  // ReactSession.setStoreType("localStorage");
       //  localStorage.setItem('username', response.fullName);
 
       // }
     })
    }
    else 
    {
      fetch('https://telesiness.ir/api/Dashboards/EditCustomerDocument/1005' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
     //Evaluation Customer form
             //Evaluation Customer Form Personal Information
              Email: localStorage.getItem('email'),
              CustomerCode: localStorage.getItem('customerCode'),
              CustomerEvaluationFormFirstName: values.proFirstName,
     
              CustomerEvaluationFormLastName: values.proLastName,
     
              CustomerEvaluationFormGender: values.Gender.toString(),
     
              CustomerEvaluationFormPhoneNumber: values.proPhone,
     
              CustomerEvaluationFormLandlineNumber: values.proCallNumber,
     
              CustomerEvaluationFormEmailAddress: values.proEmail,
     
              CustomerEvaluationFormAddress: values.Address,
     
              CustomerEvaluationFormCity: values.City,
     
              CustomerEvaluationFormProvince: values.Country,
     
              CustomerEvaluationFormCountry: values.Country,
     
              CustomerEvaluationFormPlaceOfBirth: values.placeofBirth,
     
              CustomerEvaluationFormAge: values.ApplicantAge,
     
              CustomerEvaluationFormMartialStatus: values.TypeMarriage,
     
              CustomerEvaluationFormMilitaryServiceStatus: values.militaryservicesituation,
     
             //Evaluation Customer Form Educational Information
              CustomerEvaluationFormEducationalDegrees: values.LastEducationalCertificate,
     
              CustomerEvaluationFormFieldOfStudy: values.FieldStudy,
     
              CustomerEvaluationFormCertificatesNonAcademicNumberOne: values.NonuniversityCertificate1,
     
              CustomerEvaluationFormCertificatesNonAcademicNumberTwo: values.NonuniversityCertificate2,
     
              CustomerEvaluationFormCertificatesNonAcademicNumberThree: values.NonuniversityCertificate3,
     
              CustomersEvaluationFormCertificatesAcknowledgmentNumberOne: values.ScientificDocumentsAwardsFestivals1,
     
              CustomersEvaluationFormCertificatesAcknowledgmentNumberTwo: values.ScientificDocumentsAwardsFestivals2,
     
              CustomersEvaluationFormCertificatesAcknowledgmentNumberThree: values.ScientificDocumentsAwardsFestivals3,
     
              CustomersEvaluationFormResearchNumberOne: values.ResearchandStudies1,
     
              CustomersEvaluationFormResearchNumberTwo: values.ResearchandStudies2,
     
              CustomersEvaluationFormResearchNumberThree: values.ResearchandStudies3,
     
              CustomersEvaluationFormEnglishLanguageCanSpeak: values.EnglishCheckBox,
     
              CustomerEvaluationFormFamiliarityWithTheEnglishLanguage: values.EnglishFamiliarity,
     
              CustomerEvaluationFormFinalEnglishTestScore: values.EnglishScore,
     
              CustomerEvaluationFormFrenchLanguageCanSpeak: values.FrenchCheckBox,
     
              CustomerEvaluationFormFamiliarityWithTheFrenchLanguage: values.FrenchFamiliarity,
     
              CustomerEvaluationFormFinalFrenchTestScore: values.FrenchScore,
     
              CustomerEvaluationFormGermanLanguageCanSpeak: values.GermanCheckBox,
     
              CustomerEvaluationFormFamiliarityWithTheGermanLanguage: values.GermanFamiliarity,
     
              CustomerEvaluationFormFinalGermanTestScore: values.GermanScore,
     
              CustomerEvaluationFormDanishLanguageCanSpeak: values.DanishCheckBox,
     
              CustomerEvaluationFormFamiliarityWithTheDanishLanguage: values.DanishFamiliarity,
     
              CustomerEvaluationFormFinalDanishTestScore: values.DanishScore,
     
              CustomerEvaluationFormArabicLanguageCanSpeak: values.ArabicCheckBox,
     
              CustomerEvaluationFormFamiliarityWithTheArabicLanguage: values.ArabicFamiliarity,
     
              CustomerEvaluationFormFinalArabicTestScore: values.ArabicScore,
     
              CustomerEvaluationFormSpanishLanguageCanSpeak: values.SpanishCheckBox,
     
              CustomerEvaluationFormFamiliarityWithTheSpanishLanguage: values.SpanishFamiliarity,
     
              CustomerEvaluationFormFinalSpanishTestScore: values.SpanishScore,
     
              CustomerEvaluationFormChineseLanguageCanSpeak: values.ChineseCheckBox,
     
              CustomerEvaluationFormFamiliarityWithTheChineseLanguage: values.ChineseFamiliarity,
     
              CustomerEvaluationFormFinalChineseTestScore: values.ChineseScore,
     
              CustomerEvaluationFormKoreanLanguageCanSpeak: values.KoreanCheckBox,
     
              CustomerEvaluationFormFamiliarityWithTheKoreanLanguage: values.KoreanFamiliarity,
     
              CustomerEvaluationFormFinalKoreanTestScore: values.KoreanScore,
     
             //Evaluation Customer Form Job tenure
              CustomerEvaluationFormInsuranceHistoryNumberOne: values.Insurancehistory1,
     
              CustomerEvaluationFormInsuranceHistoryNumberTwo: values.Insurancehistory2,
     
              CustomerEvaluationFormInsuranceHistoryNumberThree: values.Insurancehistory3,
     
              CustomerEvaluationFormCareerNumberOne: values.JobTitle1,
     
              CustomerEvaluationFormCareerNumberTwo: values.JobTitle2,
     
              CustomerEvaluationFormCareerNumberThree: values.JobTitle3,
     
              CustomerEvaluationFormJobPositionNumberOne: values.JobPost1,
     
              CustomerEvaluationFormJobPositionNumberTwo: values.JobPost2,
     
              CustomerEvaluationFormJobPositionNumberThree: values.JobPost3,
     
             //Evaluation Customer Form Financial Information
              CustomerEvaluationFormAnnualTurnover: values.TurnoverNumber,
     
              CustomerEvaluationFormFinancialLiquidity: values.Liquidity,
     
              CustomerEvaluationFormTotalAssets: values.TotalAssetsAmount,
     
             //Evaluation Customer Form Supplementary Information Section One
              CustomerEvaluationFormChildrenUnderFourYearsStatus: values.childunder4yearsold,
     
              CustomerEvaluationFormChildrenUnderFourYearsQuantity: values.childunder4yearsoldNum,
     
              CustomerEvaluationFormChildrenUnderFourYearsAge: values.childunder4yearsoldAge,
     
             //  CustomerEvaluationFormChildrenUnderFourYearsGender: values.child,
     
             //Children between 4 and 17 years
     
              CustomerEvaluationFormChildrenBetweenFourAndSeventeenStatus: values.childbetween4and17yearsold,
     
              CustomerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity: values.childbetween4and17yearsoldNum,
     
              CustomerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge: values.childbetween4and17yearsoldAge,
     
             //  CustomerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender: values,
     
             //Children Over 18 years old
              CustomerEvaluationFormChildrenOverEighteenStatus: values.childover18yearsold,
     
              CustomerEvaluationFormChildrenOverEighteenYearsQuantity: values.childover18yearsoldNum,
     
              CustomerEvaluationFormChildrenOverEighteenYearsAge: values.childover18yearsoldAge,
     
             //  CustomerEvaluationFormChildrenOverEighteenYearsGender: values,
     
             //Companion
              CustomerEvaluationFormCompanion: values.haveAcompanion,
     
             //Evaluation Customer Form Supplementary Information Section Two
              CustomerEvaluationFormCountryOfImmigration: values.CountryOFimmigration,
     
              CustomerEvaluationFormTypeOfVisaRequested: values.TypeofRequestedVisa,
     
              CustomerEvaluationFormPurposeOfMigration: values.Thepurposeofmigration,
     
              CustomerEvaluationFormTravelToSchengenAreaOtherPlace: values.TraveledSchengenareaCanada,
     
              CustomerEvaluationFormNamesOfCountryCustomerVisited: values.NamesofCountriesYouHaveVisited,
     
              CustomerEvaluationFormRejectionHistoryFromACountry: values.HistoryRejectionCountry,
     
              CustomerEvaluationFormNamesOfCountriesRejectedLastFiveYears: values.NamesCountriesRejected,
     
              CustomerEvaluationFormKinInChosenCountry: values.DoYouFirstSecondDegreeRelatives,
     
              CustomerEvaluationFormExplainKinInChosenCountry: values.Explain,
     
              CustomerEvaluationFormApplicantFinancialSupporter: values.DoesHaveFinancialSupporter,
     
              CustomerEvaluationFormExplainFinancialSupporter: values.ExplainFinancialSupporter,
     
             //Evaluation Customer Form Personal information of the applicant's spouse
              CustomerEvaluationFormSpouseFirstName: values.SpouseFirstName,
     
              CustomerEvaluationFormSpouseLastName: values.SpouseLastName,
     
             //Spouse Education
              CustomerEvaluationFormSpouseEducationalDegrees: values.LastEducationalCertificateS,
     
              CustomerEvaluationFormSpouseFieldOfStudy: values.FieldStudyS,
     
              CustomersEvaluationFormSpouseEnglishLanguageCanSpeak: EnglishCheckBox,
     
              CustomerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage: values.EnglishFamiliarityS,
     
              CustomerEvaluationFormSpouseFinalEnglishTestScore: values.EnglishScoreS,
     
              CustomerEvaluationFormSpouseFrenchLanguageCanSpeak: values.FrenchCheckBoxS,
     
              CustomerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage: values.FrenchFamiliarityS,
     
              CustomerEvaluationFormSpouseFinalFrenchTestScore: values.FrenchScoreS,
     
              CustomerEvaluationFormSpouseGermanLanguageCanSpeak: values.GermanCheckBoxS,
     
              CustomerEvaluationFormSpouseFamiliarityWithTheGermanLanguage: values.GermanFamiliarityS,
     
              CustomerEvaluationFormSpouseFinalGermanTestScore: values.GermanScoreS,
     
              CustomerEvaluationFormSpouseDanishLanguageCanSpeak: values.DanishCheckBoxS,
     
              CustomerEvaluationFormSpouseFamiliarityWithTheDanishLanguage: values.DanishFamiliarityS,
     
              CustomerEvaluationFormSpouseFinalDanishTestScore: values.DanishScoreS,
     
              CustomerEvaluationFormSpouseArabicLanguageCanSpeak: values.ArabicCheckBoxS,
     
              CustomerEvaluationFormSpouseFamiliarityWithTheArabicLanguage: values.ArabicFamiliarityS,
     
              CustomerEvaluationFormSpouseFinalArabicTestScore: values.ArabicScoreS,
     
              CustomerEvaluationFormSpouseSpanishLanguageCanSpeak: values.SpanishCheckBoxS,
     
              CustomerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage: values.SpanishFamiliarityS,
     
              CustomerEvaluationFormSpouseFinalSpanishTestScore: values.SpanishScoreS,
     
              CustomerEvaluationFormSpouseChineseLanguageCanSpeak: values.ChineseCheckBoxS,
     
              CustomerEvaluationFormSpouseFamiliarityWithTheChineseLanguage: values.ChineseFamiliarityS,
     
              CustomerEvaluationFormSpouseFinalChineseTestScore: values.ChineseScoreS,
     
              CustomerEvaluationFormSpouseKoreanLanguageCanSpeak: values.KoreanCheckBoxS,
     
              CustomerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage: values.KoreanFamiliarityS,
     
              CustomerEvaluationFormSpouseFinalKoreanTestScore: values.KoreanScoreS,
     
             //Spouse Job information
              CustomerEvaluationFormSpouseInsuranceHistory: values.Insurancehistory1S,
     
              CustomerEvaluationFormSpouseCareer: values.JobPost1S,
     
              CustomerEvaluationFormSpouseJobPosition: values.JobPost1S,
     
             //Spouse Companion
              CustomerEvaluationFormSpouseCompanion: values.AccompanyPost,
     
             //Another descriptions
              CustomerEvaluationFormAnotherDescriptions: values.Descriptions,
     
         })
        }).then(response => response.json()).then(response=>{
     


          localStorage.setItem('customerEvaluationFormAnotherDescriptions',response.customerEvaluationFormAnotherDescriptions);
          localStorage.setItem('customerEvaluationFormSpouseCompanion',response.customerEvaluationFormSpouseCompanion);
          localStorage.setItem('customerEvaluationFormSpouseJobPosition',response.customerEvaluationFormSpouseJobPosition);
          localStorage.setItem('customerEvaluationFormSpouseCareer',response.customerEvaluationFormSpouseCareer);
          localStorage.setItem('lastEducationalCertificate',response.lastEducationalCertificate);
          localStorage.setItem('customerEvaluationFormSpouseInsuranceHistory',response.customerEvaluationFormSpouseInsuranceHistory);
          localStorage.setItem('customerEvaluationFormSpouseFinalKoreanTestScore',response.customerEvaluationFormSpouseFinalKoreanTestScore);
          localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage);
          localStorage.setItem('customerEvaluationFormSpouseKoreanLanguageCanSpeak',response.customerEvaluationFormSpouseKoreanLanguageCanSpeak);
          localStorage.setItem('specializedSectionInTheFieldOfImmigration',response.specializedSectionInTheFieldOfImmigration);
          localStorage.setItem('customerEvaluationFormSpouseFinalChineseTestScore',response.customerEvaluationFormSpouseFinalChineseTestScore);
          localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage);
          localStorage.setItem('customerEvaluationFormSpouseChineseLanguageCanSpeak',response.customerEvaluationFormSpouseChineseLanguageCanSpeak);
          localStorage.setItem('customerEvaluationFormSpouseFinalSpanishTestScore',response.customerEvaluationFormSpouseFinalSpanishTestScore);
          localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage);
          localStorage.setItem('customerEvaluationFormSpouseSpanishLanguageCanSpeak',response.customerEvaluationFormSpouseSpanishLanguageCanSpeak);
          localStorage.setItem('customerEvaluationFormSpouseFinalArabicTestScore',response.customerEvaluationFormSpouseFinalArabicTestScore);
          localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage);
          localStorage.setItem('customerEvaluationFormSpouseArabicLanguageCanSpeak',response.customerEvaluationFormSpouseArabicLanguageCanSpeak);
          localStorage.setItem('customerEvaluationFormSpouseFinalDanishTestScore',response.customerEvaluationFormSpouseFinalDanishTestScore);
          localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage);
          localStorage.setItem('customerEvaluationFormSpouseDanishLanguageCanSpeak',response.customerEvaluationFormSpouseDanishLanguageCanSpeak);
          localStorage.setItem('customerEvaluationFormSpouseFinalGermanTestScore',response.customerEvaluationFormSpouseFinalGermanTestScore);
          localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage);
          localStorage.setItem('customerEvaluationFormSpouseGermanLanguageCanSpeak',response.customerEvaluationFormSpouseGermanLanguageCanSpeak);
          localStorage.setItem('customerEvaluationFormSpouseFinalFrenchTestScore',response.customerEvaluationFormSpouseFinalFrenchTestScore);
          localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage);
          localStorage.setItem('customerEvaluationFormSpouseFrenchLanguageCanSpeak',response.customerEvaluationFormSpouseFrenchLanguageCanSpeak);
          localStorage.setItem('customerEvaluationFormSpouseFinalEnglishTestScore',response.customerEvaluationFormSpouseFinalEnglishTestScore);
          localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage);
          localStorage.setItem('customersEvaluationFormSpouseEnglishLanguageCanSpeak',response.customersEvaluationFormSpouseEnglishLanguageCanSpeak);
          localStorage.setItem('customerEvaluationFormSpouseEducationalDegrees',response.customerEvaluationFormSpouseEducationalDegrees);
          localStorage.setItem('customerEvaluationFormSpouseFieldOfStudy',response.customerEvaluationFormSpouseFieldOfStudy);
          localStorage.setItem('customerEvaluationFormSpouseLastName',response.customerEvaluationFormSpouseLastName);
          localStorage.setItem('customerEvaluationFormExplainFinancialSupporter',response.customerEvaluationFormExplainFinancialSupporter);  
          localStorage.setItem('customerEvaluationFormApplicantFinancialSupporter',response.customerEvaluationFormApplicantFinancialSupporter); 
          localStorage.setItem('customerEvaluationFormExplainKinInChosenCountry',response.customerEvaluationFormExplainKinInChosenCountry); 
          localStorage.setItem('customerEvaluationFormKinInChosenCountry',response.customerEvaluationFormKinInChosenCountry); 
          localStorage.setItem('customerEvaluationFormNamesOfCountriesRejectedLastFiveYears',response.customerEvaluationFormNamesOfCountriesRejectedLastFiveYears); 
          localStorage.setItem('customerEvaluationFormRejectionHistoryFromACountry',response.customerEvaluationFormRejectionHistoryFromACountry); 
          localStorage.setItem('customerEvaluationFormNamesOfCountryCustomerVisited',response.customerEvaluationFormNamesOfCountryCustomerVisited); 
          localStorage.setItem('customerEvaluationFormTravelToSchengenAreaOtherPlace',response.customerEvaluationFormTravelToSchengenAreaOtherPlace); 
          localStorage.setItem('customerEvaluationFormPurposeOfMigration',response.customerEvaluationFormPurposeOfMigration); 
          localStorage.setItem('customerEvaluationFormTypeOfVisaRequested',response.customerEvaluationFormTypeOfVisaRequested); 
          localStorage.setItem('customerEvaluationFormCountryOfImmigration',response.customerEvaluationFormCountryOfImmigration); 
          localStorage.setItem('customerEvaluationFormCompanion',response.customerEvaluationFormCompanion); 
          localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsGender',response.customerEvaluationFormChildrenOverEighteenYearsGender); 
          localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsAge',response.customerEvaluationFormChildrenOverEighteenYearsAge); 
          localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsQuantity',response.customerEvaluationFormChildrenOverEighteenYearsQuantity); 
          localStorage.setItem('customerEvaluationFormChildrenOverEighteenStatus',response.customerEvaluationFormChildrenOverEighteenStatus); 
          localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender); 
          localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge); 
          localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity); 
          localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenStatus',response.customerEvaluationFormChildrenBetweenFourAndSeventeenStatus); 
          localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsGender',response.customerEvaluationFormChildrenUnderFourYearsGender); 
          localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsAge',response.customerEvaluationFormChildrenUnderFourYearsAge); 
          localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsQuantity',response.customerEvaluationFormChildrenUnderFourYearsQuantity); 
          localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsStatus',response.customerEvaluationFormChildrenUnderFourYearsStatus); 
          localStorage.setItem('customerEvaluationFormTotalAssets',response.customerEvaluationFormTotalAssets); 
          localStorage.setItem('customerEvaluationFormFinancialLiquidity',response.customerEvaluationFormFinancialLiquidity); 
          localStorage.setItem('customerEvaluationFormAnnualTurnover',response.customerEvaluationFormAnnualTurnover); 
          localStorage.setItem('customerEvaluationFormJobPositionNumberThree',response.customerEvaluationFormJobPositionNumberThree); 
          localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberThree',response.customerEvaluationFormInsuranceHistoryNumberThree); 
          localStorage.setItem('customerEvaluationFormCareerNumberThree',response.customerEvaluationFormCareerNumberThree); 
          localStorage.setItem('customerEvaluationFormJobPositionNumberTwo',response.customerEvaluationFormJobPositionNumberTwo); 
          localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberTwo',response.customerEvaluationFormInsuranceHistoryNumberTwo); 
          localStorage.setItem('customerEvaluationFormCareerNumberTwo',response.customerEvaluationFormCareerNumberTwo); 
          localStorage.setItem('customerEvaluationFormJobPositionNumberOne',response.customerEvaluationFormJobPositionNumberOne); 
          localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberOne',response.customerEvaluationFormInsuranceHistoryNumberOne); 
          localStorage.setItem('customerEvaluationFormCareerNumberOne',response.customerEvaluationFormCareerNumberOne); 
          localStorage.setItem('customerEvaluationFormFamiliarityWithTheKoreanLanguage',response.customerEvaluationFormFamiliarityWithTheKoreanLanguage); 
          localStorage.setItem('customerEvaluationFormKoreanLanguageCanSpeak',response.customerEvaluationFormKoreanLanguageCanSpeak); 
          localStorage.setItem('customerEvaluationFormFinalChineseTestScore',response.customerEvaluationFormFinalChineseTestScore); 
          localStorage.setItem('customerEvaluationFormFamiliarityWithTheChineseLanguage',response.customerEvaluationFormFamiliarityWithTheChineseLanguage); 
          localStorage.setItem('customerEvaluationFormChineseLanguageCanSpeak',response.customerEvaluationFormChineseLanguageCanSpeak); 
          localStorage.setItem('customerEvaluationFormFinalSpanishTestScore',response.customerEvaluationFormFinalSpanishTestScore); 
          localStorage.setItem('customerEvaluationFormFamiliarityWithTheSpanishLanguage',response.customerEvaluationFormFamiliarityWithTheSpanishLanguage); 
          localStorage.setItem('customerEvaluationFormSpanishLanguageCanSpeak',response.customerEvaluationFormSpanishLanguageCanSpeak); 
          localStorage.setItem('customerEvaluationFormFinalArabicTestScore',response.customerEvaluationFormFinalArabicTestScore); 
          localStorage.setItem('customerEvaluationFormFamiliarityWithTheArabicLanguage',response.customerEvaluationFormFamiliarityWithTheArabicLanguage); 
          localStorage.setItem('customerEvaluationFormArabicLanguageCanSpeak',response.customerEvaluationFormArabicLanguageCanSpeak); 
          localStorage.setItem('customerEvaluationFormFinalDanishTestScore',response.customerEvaluationFormFinalDanishTestScore); 
          localStorage.setItem('customerEvaluationFormFamiliarityWithTheDanishLanguage',response.customerEvaluationFormFamiliarityWithTheDanishLanguage); 
          localStorage.setItem('customerEvaluationFormDanishLanguageCanSpeak',response.customerEvaluationFormDanishLanguageCanSpeak); 
          localStorage.setItem('customerEvaluationFormFinalGermanTestScore',response.customerEvaluationFormFinalGermanTestScore); 
          localStorage.setItem('customerEvaluationFormFamiliarityWithTheGermanLanguage',response.customerEvaluationFormFamiliarityWithTheGermanLanguage); 
          localStorage.setItem('customerEvaluationFormGermanLanguageCanSpeak',response.customerEvaluationFormGermanLanguageCanSpeak); 
          localStorage.setItem('customerEvaluationFormFinalFrenchTestScore',response.customerEvaluationFormFinalFrenchTestScore); 
          localStorage.setItem('customerEvaluationFormFamiliarityWithTheFrenchLanguage',response.customerEvaluationFormFamiliarityWithTheFrenchLanguage); 
          localStorage.setItem('customerEvaluationFormFrenchLanguageCanSpeak',response.customerEvaluationFormFrenchLanguageCanSpeak); 
          localStorage.setItem('customerEvaluationFormFinalEnglishTestScore',response.customerEvaluationFormFinalEnglishTestScore); 
          localStorage.setItem('customerEvaluationFormFamiliarityWithTheEnglishLanguage',response.customerEvaluationFormFamiliarityWithTheEnglishLanguage); 
          localStorage.setItem('customersEvaluationFormEnglishLanguageCanSpeak',response.customersEvaluationFormEnglishLanguageCanSpeak); 
          localStorage.setItem('customersEvaluationFormResearchNumberThree',response.customersEvaluationFormResearchNumberThree); 
          localStorage.setItem('customersEvaluationFormResearchNumberTwo',response.customersEvaluationFormResearchNumberTwo); 
          localStorage.setItem('customersEvaluationFormResearchNumberOne',response.customersEvaluationFormResearchNumberOne); 
          localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberThree',response.customersEvaluationFormCertificatesAcknowledgmentNumberThree); 
          localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberTwo',response.customersEvaluationFormCertificatesAcknowledgmentNumberTwo); 
          localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberOne',response.customersEvaluationFormCertificatesAcknowledgmentNumberOne); 
          localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberThree',response.customerEvaluationFormCertificatesNonAcademicNumberThree); 
          localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberTwo',response.customerEvaluationFormCertificatesNonAcademicNumberTwo); 
          localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberOne',response.customerEvaluationFormCertificatesNonAcademicNumberOne); 
          localStorage.setItem('customerEvaluationFormFieldOfStudy',response.customerEvaluationFormFieldOfStudy); 
          localStorage.setItem('customerEvaluationFormEducationalDegrees',response.customerEvaluationFormEducationalDegrees); 
          localStorage.setItem('customerEvaluationFormMartialStatus',response.customerEvaluationFormMartialStatus); 
          localStorage.setItem('customerEvaluationFormMilitaryServiceStatus',response.customerEvaluationFormMilitaryServiceStatus); 
          localStorage.setItem('customerEvaluationFormAge',response.customerEvaluationFormAge); 
          localStorage.setItem('customerEvaluationFormPlaceOfBirth',response.customerEvaluationFormPlaceOfBirth); 
          localStorage.setItem('customerEvaluationFormAddress',response.customerEvaluationFormAddress); 
          localStorage.setItem('customerEvaluationFormFirstName',response.customerEvaluationFormFirstName); 
          localStorage.setItem('customerEvaluationFormCity',response.customerEvaluationFormCity); 
          localStorage.setItem('customerEvaluationFormProvince',response.customerEvaluationFormProvince); 
          localStorage.setItem('customerEvaluationFormCountry',response.customerEvaluationFormCountry); 
          localStorage.setItem('customerEvaluationFormPhoneNumber',response.customerEvaluationFormPhoneNumber);     
          localStorage.setItem('customerEvaluationFormLandlineNumber',response.customerEvaluationFormLandlineNumber); 
          localStorage.setItem('customerEvaluationFormEmailAddress',response.customerEvaluationFormEmailAddress); 
          localStorage.setItem('customerEvaluationFormGender',response.customerEvaluationFormGender); 
          localStorage.setItem('customerEvaluationFormLastName',response.customerEvaluationFormLastName); 
      
          localStorage.setItem('EvFormUpdate',true);
          
          console.log(localStorage.getItem('EvFormUpdate'));
      

      
      values.proFirstName=localStorage.getItem('customerEvaluationFormFirstName') ;
      
      values.proLastName= localStorage.getItem('customerEvaluationFormLastName') ;
      
      values.Gender= parseInt(localStorage.getItem('customerEvaluationFormGender'))  ;
      
      values.proPhone= localStorage.getItem('customerEvaluationFormPhoneNumber') ;
      
      values.proCallNumber= localStorage.getItem('customerEvaluationFormLandlineNumber') ;
      
      values.proEmail= localStorage.getItem('customerEvaluationFormEmailAddress') ;
      
      values.Address= localStorage.getItem('customerEvaluationFormAddress') ;
      
      values.City= localStorage.getItem('customerEvaluationFormCity') ;
      
      values.Country= localStorage.getItem('customerEvaluationFormProvince') ;
      
      values.Country= localStorage.getItem('customerEvaluationFormCountry') ;
      
      values.placeofBirth= localStorage.getItem('customerEvaluationFormPlaceOfBirth');
      
      values.ApplicantAge= localStorage.getItem('customerEvaluationFormAge');
      
      values.TypeMarriage= localStorage.getItem('customerEvaluationFormMartialStatus');
      
      values.militaryservicesituation= localStorage.getItem('customerEvaluationFormMilitaryServiceStatus') ;
      
      //Evaluation customer Form Educational Information
      values.LastEducationalCertificate= localStorage.getItem('customerEvaluationFormEducationalDegrees') ;
      
      values.FieldStudy= localStorage.getItem('customerEvaluationFormFieldOfStudy') ;
      
      values.NonuniversityCertificate1= localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberOne') ;
      
      values.NonuniversityCertificate2= localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberTwo') ;
      
      values.NonuniversityCertificate3= localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberThree') ;
      
      values.ScientificDocumentsAwardsFestivals1= localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberOne') ;
      
      values.ScientificDocumentsAwardsFestivals2= localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberTwo') ;
      
      values.ScientificDocumentsAwardsFestivals3= localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberThree') ;
      
      values.ResearchandStudies1= localStorage.getItem('customersEvaluationFormResearchNumberOne') ;
      
      values.ResearchandStudies2= localStorage.getItem('customersEvaluationFormResearchNumberTwo') ;
      
      values.ResearchandStudies3= localStorage.getItem('customersEvaluationFormResearchNumberThree') ;
      
      values.EnglishCheckBox= localStorage.getItem('customersEvaluationFormEnglishLanguageCanSpeak') ;
      
      values.EnglishFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheEnglishLanguage') ;
      
      values.EnglishScore= localStorage.getItem('customerEvaluationFormFinalEnglishTestScore') ;
      
      values.FrenchCheckBox= localStorage.getItem('customerEvaluationFormFrenchLanguageCanSpeak') ;
      
      values.FrenchFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheFrenchLanguage') ;
      
      values.FrenchScore= localStorage.getItem('customerEvaluationFormFinalFrenchTestScore') ;
      
      values.GermanCheckBox= localStorage.getItem('customerEvaluationFormGermanLanguageCanSpeak') ;
      
      values.GermanFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheGermanLanguage') ;
      
      values.GermanScore= localStorage.getItem('customerEvaluationFormFinalGermanTestScore') ;
      
      values.DanishCheckBox= localStorage.getItem('customerEvaluationFormDanishLanguageCanSpeak') ;
      
      values.DanishFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheDanishLanguage') ;
      
      values.DanishScore= localStorage.getItem('customerEvaluationFormFinalDanishTestScore') ;
      
      values.ArabicCheckBox= localStorage.getItem('customerEvaluationFormArabicLanguageCanSpeak') ;
      
      values.ArabicFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheArabicLanguage') ;
      
      values.ArabicScore= localStorage.getItem('customerEvaluationFormFinalArabicTestScore') ;
      
      values.SpanishCheckBox= localStorage.getItem('customerEvaluationFormSpanishLanguageCanSpeak') ;
      
      values.SpanishFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheSpanishLanguage') ;
      
      values.SpanishScore= localStorage.getItem('customerEvaluationFormFinalSpanishTestScore') ;
      
      values.ChineseCheckBox= localStorage.getItem('customerEvaluationFormChineseLanguageCanSpeak') ;
      
      values.ChineseFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheChineseLanguage') ;
      
      values.ChineseScore= localStorage.getItem('customerEvaluationFormFinalChineseTestScore') ;
      
      values.KoreanCheckBox= localStorage.getItem('customerEvaluationFormKoreanLanguageCanSpeak') ;
      
      values.KoreanFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheKoreanLanguage') ;
      
      values.KoreanScore= localStorage.getItem('customerEvaluationFormFinalKoreanTestScore') ;
      
      //Evaluation customer Form Job tenure
      values.Insurancehistory1= localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberOne') ;
      
      values.Insurancehistory2= localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberTwo') ;
      
      values.Insurancehistory3= localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberThree') ;
      
      values.JobTitle1= localStorage.getItem('customerEvaluationFormCareerNumberOne') ;
      
      values.JobTitle2= localStorage.getItem('customerEvaluationFormCareerNumberTwo') ;
      
      values.JobTitle3= localStorage.getItem('customerEvaluationFormCareerNumberThree') ;
      
      values.JobPost1= localStorage.getItem('customerEvaluationFormJobPositionNumberOne') ;
      
      values.JobPost2= localStorage.getItem('customerEvaluationFormJobPositionNumberTwo') ;
      
      values.JobPost3= localStorage.getItem('customerEvaluationFormJobPositionNumberThree') ;
      
      //Evaluation customer Form Financial Information
      values.TurnoverNumber= localStorage.getItem('customerEvaluationFormAnnualTurnover') ;
      
      values.Liquidity= localStorage.getItem('customerEvaluationFormFinancialLiquidity') ;
      
      values.TotalAssetsAmount= localStorage.getItem('customerEvaluationFormTotalAssets') ;
      
      //Evaluation customer Form Supplementary Information Section One

      
      //Companion
      values.haveAcompanion= localStorage.getItem('customerEvaluationFormCompanion') ;
      
      //Evaluation customer Form Supplementary Information Section Two
      values.CountryOFimmigration= localStorage.getItem('customerEvaluationFormCountryOfImmigration') ;
      
      values.TypeofRequestedVisa= localStorage.getItem('customerEvaluationFormTypeOfVisaRequested') ;
      
      values.Thepurposeofmigration= localStorage.getItem('customerEvaluationFormPurposeOfMigration') ;
      
      values.TraveledSchengenareaCanada= localStorage.getItem('customerEvaluationFormTravelToSchengenAreaOtherPlace') ;
      
      values.NamesofCountriesYouHaveVisited= localStorage.getItem('customerEvaluationFormNamesOfCountryCustomerVisited') ;
      
      values.HistoryRejectionCountry= localStorage.getItem('customerEvaluationFormRejectionHistoryFromACountry') ;
      
      values.NamesCountriesRejected= localStorage.getItem('customerEvaluationFormNamesOfCountriesRejectedLastFiveYears') ;
      
      values.DoYouFirstSecondDegreeRelatives= localStorage.getItem('customerEvaluationFormKinInChosenCountry') ;
      
      values.Explain= localStorage.getItem('customerEvaluationFormExplainKinInChosenCountry') ;
      
      values.DoesHaveFinancialSupporter= localStorage.getItem('customerEvaluationFormApplicantFinancialSupporter') ;
      
      values.ExplainFinancialSupporter= localStorage.getItem('customerEvaluationFormExplainFinancialSupporter') ;
      
      //Evaluation customer Form Personal information of the applicant's spouse
      values.SpouseFirstName= localStorage.getItem('customerEvaluationFormSpouseFirstName') ;
      
      values.SpouseLastName= localStorage.getItem('customerEvaluationFormSpouseLastName') ;
      
      //Spouse Education
      values.LastEducationalCertificateS= localStorage.getItem('customerEvaluationFormSpouseEducationalDegrees') ;
      
      values.FieldStudyS= localStorage.getItem('customerEvaluationFormSpouseFieldOfStudy') ;
      
      values.EnglishCheckBoxS= localStorage.getItem('customersEvaluationFormSpouseEnglishLanguageCanSpeak') ;
      
      values.EnglishFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage') ;
      
      values.EnglishScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalEnglishTestScore') ;
      
      values.FrenchCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseFrenchLanguageCanSpeak') ;
      
      values.FrenchFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage') ;
      
      values.FrenchScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalFrenchTestScore') ;
      
      values.GermanCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseGermanLanguageCanSpeak') ;
      
      values.GermanFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage') ;
      
      values.GermanScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalGermanTestScore') ;
      
      values.DanishCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseDanishLanguageCanSpeak') ;
      
      values.DanishFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage') ;
      
      values.DanishScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalDanishTestScore') ;
      
      values.ArabicCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseArabicLanguageCanSpeak') ;
      
      values.ArabicFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage') ;
      
      values.ArabicScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalArabicTestScore') ;
      
      values.SpanishCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseSpanishLanguageCanSpeak') ;
      
      values.SpanishFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage') ;
      
      values.SpanishScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalSpanishTestScore') ;
      
      values.ChineseCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseChineseLanguageCanSpeak') ;
      
      values.ChineseFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage') ;
      
      values.ChineseScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalChineseTestScore') ;
      
      values.KoreanCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseKoreanLanguageCanSpeak') ;
      
      values.KoreanFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage') ;
      
      values.KoreanScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalKoreanTestScore') ;
      
      //Spouse Job information
      values.Insurancehistory1S= localStorage.getItem('customerEvaluationFormSpouseInsuranceHistory') ;
      
      values.JobPost1S= localStorage.getItem('customerEvaluationFormSpouseCareer') ;
      
      values.JobPost1S= localStorage.getItem('customerEvaluationFormSpouseJobPosition') ;
      
      //Spouse Companion
      values.AccompanyPost= localStorage.getItem('customerEvaluationFormSpouseCompanion') ;
      
      //Another descriptions
      values.Descriptions= localStorage.getItem('customerEvaluationFormAnotherDescriptions') ;
      
      




          if(response.statusCode==400 || response.statusCode==404)
          {
            console.log(response.statusMessage);
            console.log(values.EnglishCheckBox);
           //  setFormError(true);
           //  setHelpStatusMessage(response.statusMessage);
           //  setIspending(false);
          }
          else{
           //  setHelpStatusMessage("");
           //  setIspending(false);
           //  history.push('/regstep6');
          }
            // if(response.userType==2 && response.isAgentActive==true && response.isConfirmEmailActive== true){
            //  // ReactSession.setStoreType("localStorage");
            //  localStorage.setItem('username', response.fullName);
      
            // }
        })
    
    }
  }
    console.log(localStorage.getItem('customerEvaluationFormAge'));
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }
    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({
        
          files2:'',
          JobUpload:'',

         proFirstName:localStorage.getItem('customerEvaluationFormFirstName') ,

         proLastName: localStorage.getItem('customerEvaluationFormLastName') ,

         Gender: parseInt(localStorage.getItem('customerEvaluationFormGender'))  ,

         proPhone: localStorage.getItem('customerEvaluationFormPhoneNumber') ,

         proCallNumber: localStorage.getItem('customerEvaluationFormLandlineNumber') ,

         proEmail: localStorage.getItem('customerEvaluationFormEmailAddress') ,

         Address: localStorage.getItem('customerEvaluationFormAddress') ,

         City: localStorage.getItem('customerEvaluationFormCity') ,

         Country: localStorage.getItem('customerEvaluationFormProvince') ,

         Country: localStorage.getItem('customerEvaluationFormCountry') ,

         placeofBirth: localStorage.getItem('customerEvaluationFormPlaceOfBirth'),

         ApplicantAge: localStorage.getItem('customerEvaluationFormAge'),

         TypeMarriage: localStorage.getItem('customerEvaluationFormMartialStatus'),

         militaryservicesituation: localStorage.getItem('customerEvaluationFormMilitaryServiceStatus') ,

        //Evaluation customer Form Educational Information
         LastEducationalCertificate: localStorage.getItem('customerEvaluationFormEducationalDegrees') ,

         FieldStudy: localStorage.getItem('customerEvaluationFormFieldOfStudy') ,

         NonuniversityCertificate1: localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberOne') ,

         NonuniversityCertificate2: localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberTwo') ,

         NonuniversityCertificate3: localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberThree') ,

         ScientificDocumentsAwardsFestivals1: localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberOne') ,

         ScientificDocumentsAwardsFestivals2: localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberTwo') ,

         ScientificDocumentsAwardsFestivals3: localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberThree') ,

         ResearchandStudies1: localStorage.getItem('customersEvaluationFormResearchNumberOne') ,

         ResearchandStudies2: localStorage.getItem('customersEvaluationFormResearchNumberTwo') ,

         ResearchandStudies3: localStorage.getItem('customersEvaluationFormResearchNumberThree') ,

         EnglishCheckBox: localStorage.getItem('customersEvaluationFormEnglishLanguageCanSpeak') ,

         EnglishFamiliarity: localStorage.getItem('customerEvaluationFormFamiliarityWithTheEnglishLanguage') ,

         EnglishScore: localStorage.getItem('customerEvaluationFormFinalEnglishTestScore') ,

         FrenchCheckBox: localStorage.getItem('customerEvaluationFormFrenchLanguageCanSpeak') ,

         FrenchFamiliarity: localStorage.getItem('customerEvaluationFormFamiliarityWithTheFrenchLanguage') ,

         FrenchScore: localStorage.getItem('customerEvaluationFormFinalFrenchTestScore') ,

         GermanCheckBox: localStorage.getItem('customerEvaluationFormGermanLanguageCanSpeak') ,

         GermanFamiliarity: localStorage.getItem('customerEvaluationFormFamiliarityWithTheGermanLanguage') ,

         GermanScore: localStorage.getItem('customerEvaluationFormFinalGermanTestScore') ,

         DanishCheckBox: localStorage.getItem('customerEvaluationFormDanishLanguageCanSpeak') ,

         DanishFamiliarity: localStorage.getItem('customerEvaluationFormFamiliarityWithTheDanishLanguage') ,

         DanishScore: localStorage.getItem('customerEvaluationFormFinalDanishTestScore') ,

         ArabicCheckBox: localStorage.getItem('customerEvaluationFormArabicLanguageCanSpeak') ,

         ArabicFamiliarity: localStorage.getItem('customerEvaluationFormFamiliarityWithTheArabicLanguage') ,

         ArabicScore: localStorage.getItem('customerEvaluationFormFinalArabicTestScore') ,

         SpanishCheckBox: localStorage.getItem('customerEvaluationFormSpanishLanguageCanSpeak') ,

         SpanishFamiliarity: localStorage.getItem('customerEvaluationFormFamiliarityWithTheSpanishLanguage') ,

         SpanishScore: localStorage.getItem('customerEvaluationFormFinalSpanishTestScore') ,

         ChineseCheckBox: localStorage.getItem('customerEvaluationFormChineseLanguageCanSpeak') ,

         ChineseFamiliarity: localStorage.getItem('customerEvaluationFormFamiliarityWithTheChineseLanguage') ,

         ChineseScore: localStorage.getItem('customerEvaluationFormFinalChineseTestScore') ,

         KoreanCheckBox: localStorage.getItem('customerEvaluationFormKoreanLanguageCanSpeak') ,

         KoreanFamiliarity: localStorage.getItem('customerEvaluationFormFamiliarityWithTheKoreanLanguage') ,

         KoreanScore: localStorage.getItem('customerEvaluationFormFinalKoreanTestScore') ,

        //Evaluation customer Form Job tenure
         Insurancehistory1: localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberOne') ,

         Insurancehistory2: localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberTwo') ,

         Insurancehistory3: localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberThree') ,

         JobTitle1: localStorage.getItem('customerEvaluationFormCareerNumberOne') ,

         JobTitle2: localStorage.getItem('customerEvaluationFormCareerNumberTwo') ,

         JobTitle3: localStorage.getItem('customerEvaluationFormCareerNumberThree') ,

         JobPost1: localStorage.getItem('customerEvaluationFormJobPositionNumberOne') ,

         JobPost2: localStorage.getItem('customerEvaluationFormJobPositionNumberTwo') ,

         JobPost3: localStorage.getItem('customerEvaluationFormJobPositionNumberThree') ,

        //Evaluation customer Form Financial Information
         TurnoverNumber: localStorage.getItem('customerEvaluationFormAnnualTurnover') ,

         Liquidity: localStorage.getItem('customerEvaluationFormFinancialLiquidity') ,

         TotalAssetsAmount: localStorage.getItem('customerEvaluationFormTotalAssets') ,

        //Evaluation customer Form Supplementary Information Section One
         childunder4yearsold: localStorage.getItem('customerEvaluationFormChildrenUnderFourYearsStatus') ,

         childunder4yearsoldNum: localStorage.getItem('customerEvaluationFormChildrenUnderFourYearsQuantity') ,

         childunder4yearsoldAge: localStorage.getItem('customerEvaluationFormChildrenUnderFourYearsAge') ,

        //  child: localStorage.getItem('customerEvaluationFormChildrenUnderFourYearsGender') ,

        //Children between 4 and 17 years

         childbetween4and17yearsold: localStorage.getItem('customerEvaluationFormChildrenBetweenFourAndSeventeenStatus') ,

         childbetween4and17yearsoldNum: localStorage.getItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity') ,

         childbetween4and17yearsoldAge: localStorage.getItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge') ,

        // ,: localStorage.getItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender') 

        //Children Over 18 years old
         childover18yearsold: localStorage.getItem('customerEvaluationFormChildrenOverEighteenStatus') ,

         childover18yearsoldNum: localStorage.getItem('customerEvaluationFormChildrenOverEighteenYearsQuantity') ,

         childover18yearsoldAge: localStorage.getItem('customerEvaluationFormChildrenOverEighteenYearsAge') ,

        // ,: localStorage.getItem('customerEvaluationFormChildrenOverEighteenYearsGender') 

        //Companion
         haveAcompanion: localStorage.getItem('customerEvaluationFormCompanion') ,

        //Evaluation customer Form Supplementary Information Section Two
         CountryOFimmigration: localStorage.getItem('customerEvaluationFormCountryOfImmigration') ,

         TypeofRequestedVisa: localStorage.getItem('customerEvaluationFormTypeOfVisaRequested') ,

         Thepurposeofmigration: localStorage.getItem('customerEvaluationFormPurposeOfMigration') ,

         TraveledSchengenareaCanada: localStorage.getItem('customerEvaluationFormTravelToSchengenAreaOtherPlace') ,

         NamesofCountriesYouHaveVisited: localStorage.getItem('customerEvaluationFormNamesOfCountryCustomerVisited') ,

         HistoryRejectionCountry: localStorage.getItem('customerEvaluationFormRejectionHistoryFromACountry') ,

         NamesCountriesRejected: localStorage.getItem('customerEvaluationFormNamesOfCountriesRejectedLastFiveYears') ,

         DoYouFirstSecondDegreeRelatives: localStorage.getItem('customerEvaluationFormKinInChosenCountry') ,

         Explain: localStorage.getItem('customerEvaluationFormExplainKinInChosenCountry') ,

         DoesHaveFinancialSupporter: localStorage.getItem('customerEvaluationFormApplicantFinancialSupporter') ,

         ExplainFinancialSupporter: localStorage.getItem('customerEvaluationFormExplainFinancialSupporter') ,

        //Evaluation customer Form Personal information of the applicant's spouse
         SpouseFirstName: localStorage.getItem('customerEvaluationFormSpouseFirstName') ,

         SpouseLastName: localStorage.getItem('customerEvaluationFormSpouseLastName') ,

        //Spouse Education
         LastEducationalCertificateS: localStorage.getItem('customerEvaluationFormSpouseEducationalDegrees') ,

         FieldStudyS: localStorage.getItem('customerEvaluationFormSpouseFieldOfStudy') ,

         EnglishCheckBoxS: localStorage.getItem('customersEvaluationFormSpouseEnglishLanguageCanSpeak') ,

         EnglishFamiliarityS: localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage') ,

         EnglishScoreS: localStorage.getItem('customerEvaluationFormSpouseFinalEnglishTestScore') ,

         FrenchCheckBoxS: localStorage.getItem('customerEvaluationFormSpouseFrenchLanguageCanSpeak') ,

         FrenchFamiliarityS: localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage') ,

         FrenchScoreS: localStorage.getItem('customerEvaluationFormSpouseFinalFrenchTestScore') ,

         GermanCheckBoxS: localStorage.getItem('customerEvaluationFormSpouseGermanLanguageCanSpeak') ,

         GermanFamiliarityS: localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage') ,

         GermanScoreS: localStorage.getItem('customerEvaluationFormSpouseFinalGermanTestScore') ,

         DanishCheckBoxS: localStorage.getItem('customerEvaluationFormSpouseDanishLanguageCanSpeak') ,

         DanishFamiliarityS: localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage') ,

         DanishScoreS: localStorage.getItem('customerEvaluationFormSpouseFinalDanishTestScore') ,

         ArabicCheckBoxS: localStorage.getItem('customerEvaluationFormSpouseArabicLanguageCanSpeak') ,

         ArabicFamiliarityS: localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage') ,

         ArabicScoreS: localStorage.getItem('customerEvaluationFormSpouseFinalArabicTestScore') ,

         SpanishCheckBoxS: localStorage.getItem('customerEvaluationFormSpouseSpanishLanguageCanSpeak') ,

         SpanishFamiliarityS: localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage') ,

         SpanishScoreS: localStorage.getItem('customerEvaluationFormSpouseFinalSpanishTestScore') ,

         ChineseCheckBoxS: localStorage.getItem('customerEvaluationFormSpouseChineseLanguageCanSpeak') ,

         ChineseFamiliarityS: localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage') ,

         ChineseScoreS: localStorage.getItem('customerEvaluationFormSpouseFinalChineseTestScore') ,

         KoreanCheckBoxS: localStorage.getItem('customerEvaluationFormSpouseKoreanLanguageCanSpeak') ,

         KoreanFamiliarityS: localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage') ,

         KoreanScoreS: localStorage.getItem('customerEvaluationFormSpouseFinalKoreanTestScore') ,

        //Spouse Job information
         Insurancehistory1S: localStorage.getItem('customerEvaluationFormSpouseInsuranceHistory') ,

         JobPost1S: localStorage.getItem('customerEvaluationFormSpouseCareer') ,

         JobPost1S: localStorage.getItem('customerEvaluationFormSpouseJobPosition') ,

        //Spouse Companion
         AccompanyPost: localStorage.getItem('customerEvaluationFormSpouseCompanion') ,

        //Another descriptions
         Descriptions: localStorage.getItem('customerEvaluationFormAnotherDescriptions') ,


          // ProPic:'',
        
          // proFirstName: '',
          // proLastName: '',
          // SpouseFirstName:'',
          // SpouseLastName:'',
          // Gender: '',
          // IDNumber:'',
          // NationalCode:'',
          // birthDate: '',
          // proEmail: '',
          // proPhone: '',
          // proCallNumber:"",
          // proAbout: '',
          // firstTimeEdit:'',
          // firstTimeEditJob:'',
          // firstTimeEditEducational:'',
          // firstTimeEditAddress:true,
          // firstTimeEditPersonal:true,
          // // ProPic: '',
          // Country: '',
          // City: '',
          // State:'',
          // Address: '',
          // ApplicantAge:'',
          // ZipCode: '',
          // placeofBirth:'',
          // militaryservicesituation:'',
          // TypeMarriage:'',
          // LastEducationalCertificate:'',
          // FieldStudy:'',
          // LastEducationalCertificateS:'',
          // FieldStudyS:'',
          // NonuniversityCertificate1:'',
          // NonuniversityCertificate2:'',
          // NonuniversityCertificate3:'',
          // ScientificDocumentsAwardsFestivals1:'',
          // ScientificDocumentsAwardsFestivals2:'',
          // ScientificDocumentsAwardsFestivals3:'',
          // ResearchandStudies1:'',
          // ResearchandStudies2:'',
          // ResearchandStudies3:'',
          // EnglishCheckBox:false,
          // EnglishFamiliarity:'',
          // EnglishScore:'',
          // FrenchCheckBox:false,
          // FrenchFamiliarity:'',
          // FrenchScore:'',
          // GermanCheckBox:false,
          // GermanFamiliarity:'',
          // GermanScore:'',
          // DanishCheckBox:false,
          // DanishFamiliarity:'',
          // DanishScore:'',
          // ArabicCheckBox:false,
          // ArabicFamiliarity:'',
          // ArabicScore:'',
          // SpanishCheckBox:false,
          // SpanishFamiliarity:'',
          // SpanishScore:'',
          // ChineseCheckBox:false,
          // ChineseFamiliarity:'',
          // ChineseScore:'',
          // KoreanCheckBox:false,
          // KoreanFamiliarity:'',
          // KoreanScore:'',
          // EnglishCheckBoxS:false,
          // EnglishFamiliarityS:'',
          // EnglishScoreS:'',
          // FrenchCheckBoxS:false,
          // FrenchFamiliarityS:'',
          // FrenchScoreS:'',
          // GermanCheckBoxS:false,
          // GermanFamiliarityS:'',
          // GermanScoreS:'',
          // DanishCheckBoxS:false,
          // DanishFamiliarityS:'',
          // DanishScoreS:'',
          // ArabicCheckBoxS:false,
          // ArabicFamiliarityS:'',
          // ArabicScoreS:'',
          // SpanishCheckBoxS:false,
          // SpanishFamiliarityS:'',
          // SpanishScoreS:'',
          // ChineseCheckBoxS:false,
          // ChineseFamiliarityS:'',
          // ChineseScoreS:'',
          // KoreanCheckBoxS:false,
          // KoreanFamiliarityS:'',
          // KoreanScoreS:'',
          // JobTitle1:'',
          // Insurancehistory1:'',
          // JobPost1 :'',
          // JobTitle2:'',
          // Insurancehistory2:'',
          // JobPost2 :'',
          // JobTitle3:'',
          // Insurancehistory3:'',
          // JobPost3 :'',
          // JobTitle1S:'',
          // Insurancehistory1S:'',
          // JobPost1S:'',
          // Liquidity:'',
          // TurnoverNumber:'',
          // TotalAssetsAmount:'',
          // childunder4yearsold:'',
          // childunder4yearsoldNum:'',
          // childunder4yearsoldAge:0,
          // childbetween4and17yearsold:'',
          // childbetween4and17yearsoldNum:'',
          // childbetween4and17yearsoldAge:0,
          // childover18yearsold:'',
          // childover18yearsoldNum:'',
          // childover18yearsoldAge:0,
          // haveAcompanion:'',
          // TypeofRequestedVisa:'',
          // Thepurposeofmigration:'',
          // TraveledSchengenareaCanada:'',
          // NamesofCountriesYouHaveVisited:'',
          // HistoryRejectionCountry:'',
          // NamesCountriesRejected:'',
          // DoYouFirstSecondDegreeRelatives:'',
          // Explain:'',
          // DoesHaveFinancialSupporter:'',
          // ExplainFinancialSupporter:'',
          // AccompanyPost:'',
          // Descriptions:'',

          showPassword: false,
        });
        // console.log(values.ApplicantAge);
        
        const {t, i18n} = useTranslation(['Login']);  

        const [EnglishCheckBox, setEnglishCheckBox] = useState("false");
        const [FrenchCheckBox, setFrenchCheckBox] = useState(false);
        const [GermanCheckBox, setGermanCheckBox] = useState(false);
        const [DanishCheckBox, setDanishCheckBox] = useState(false);
        const [ArabicCheckBox, setArabicCheckBox] = useState(false);
        const [SpanishCheckBox, setSpanishCheckBox] = useState(false);
        const [ChineseCheckBox, setChineseCheckBox] = useState(false);
        const [KoreanCheckBox, setKoreanCheckBox] = useState(false);

        const [EnglishCheckBoxS, setEnglishCheckBoxS] = useState("false");
        const [FrenchCheckBoxS, setFrenchCheckBoxS] = useState(false);
        const [GermanCheckBoxS, setGermanCheckBoxS] = useState(false);
        const [DanishCheckBoxS, setDanishCheckBoxS] = useState(false);
        const [ArabicCheckBoxS, setArabicCheckBoxS] = useState(false);
        const [SpanishCheckBoxS, setSpanishCheckBoxS] = useState(false);
        const [ChineseCheckBoxS, setChineseCheckBoxS] = useState(false);
        const [KoreanCheckBoxS, setKoreanCheckBoxS] = useState(false);

        console.log(EnglishCheckBox);
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
         
        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;



const handleScrollUp = () =>{
  window.scroll({ behavior: 'smooth' }, 0)
  }


  fetch('https://telesiness.ir/api/CustomerDocuments/GetDataEvaluationForm' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ Email: localStorage.getItem('email') ,CustomerCode: localStorage.getItem('customerCode') })
    }).then(response => response.json()).then(response=>{

      // setIspending(false);
  //  setValues();
  
    localStorage.setItem('attachmentFile',response.attachmentFile);
    localStorage.setItem('customerEvaluationFormAnotherDescriptions',response.customerEvaluationFormAnotherDescriptions);
    localStorage.setItem('customerEvaluationFormSpouseCompanion',response.customerEvaluationFormSpouseCompanion);
    localStorage.setItem('customerEvaluationFormSpouseJobPosition',response.customerEvaluationFormSpouseJobPosition);
    localStorage.setItem('customerEvaluationFormSpouseCareer',response.customerEvaluationFormSpouseCareer);
    localStorage.setItem('lastEducationalCertificate',response.lastEducationalCertificate);
    localStorage.setItem('customerEvaluationFormSpouseInsuranceHistory',response.customerEvaluationFormSpouseInsuranceHistory);
    localStorage.setItem('customerEvaluationFormSpouseFinalKoreanTestScore',response.customerEvaluationFormSpouseFinalKoreanTestScore);
    localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage);
    localStorage.setItem('customerEvaluationFormSpouseKoreanLanguageCanSpeak',response.customerEvaluationFormSpouseKoreanLanguageCanSpeak);
    localStorage.setItem('specializedSectionInTheFieldOfImmigration',response.specializedSectionInTheFieldOfImmigration);
    localStorage.setItem('customerEvaluationFormSpouseFinalChineseTestScore',response.customerEvaluationFormSpouseFinalChineseTestScore);
    localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage);
    localStorage.setItem('customerEvaluationFormSpouseChineseLanguageCanSpeak',response.customerEvaluationFormSpouseChineseLanguageCanSpeak);
    localStorage.setItem('customerEvaluationFormSpouseFinalSpanishTestScore',response.customerEvaluationFormSpouseFinalSpanishTestScore);
    localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage);
    localStorage.setItem('customerEvaluationFormSpouseSpanishLanguageCanSpeak',response.customerEvaluationFormSpouseSpanishLanguageCanSpeak);
    localStorage.setItem('customerEvaluationFormSpouseFinalArabicTestScore',response.customerEvaluationFormSpouseFinalArabicTestScore);
    localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage);
    localStorage.setItem('customerEvaluationFormSpouseArabicLanguageCanSpeak',response.customerEvaluationFormSpouseArabicLanguageCanSpeak);
    localStorage.setItem('customerEvaluationFormSpouseFinalDanishTestScore',response.customerEvaluationFormSpouseFinalDanishTestScore);
    localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage);
    localStorage.setItem('customerEvaluationFormSpouseDanishLanguageCanSpeak',response.customerEvaluationFormSpouseDanishLanguageCanSpeak);
    localStorage.setItem('customerEvaluationFormSpouseFinalGermanTestScore',response.customerEvaluationFormSpouseFinalGermanTestScore);
    localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage);
    localStorage.setItem('customerEvaluationFormSpouseGermanLanguageCanSpeak',response.customerEvaluationFormSpouseGermanLanguageCanSpeak);
    localStorage.setItem('customerEvaluationFormSpouseFinalFrenchTestScore',response.customerEvaluationFormSpouseFinalFrenchTestScore);
    localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage);
    localStorage.setItem('customerEvaluationFormSpouseFrenchLanguageCanSpeak',response.customerEvaluationFormSpouseFrenchLanguageCanSpeak);
    localStorage.setItem('customerEvaluationFormSpouseFinalEnglishTestScore',response.customerEvaluationFormSpouseFinalEnglishTestScore);
    localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage);
    localStorage.setItem('customersEvaluationFormSpouseEnglishLanguageCanSpeak',response.customersEvaluationFormSpouseEnglishLanguageCanSpeak);
    localStorage.setItem('customerEvaluationFormSpouseEducationalDegrees',response.customerEvaluationFormSpouseEducationalDegrees);
    localStorage.setItem('customerEvaluationFormSpouseFieldOfStudy',response.customerEvaluationFormSpouseFieldOfStudy);
    localStorage.setItem('customerEvaluationFormSpouseLastName',response.customerEvaluationFormSpouseLastName);
    localStorage.setItem('customerEvaluationFormExplainFinancialSupporter',response.customerEvaluationFormExplainFinancialSupporter);  
    localStorage.setItem('customerEvaluationFormApplicantFinancialSupporter',response.customerEvaluationFormApplicantFinancialSupporter); 
    localStorage.setItem('customerEvaluationFormExplainKinInChosenCountry',response.customerEvaluationFormExplainKinInChosenCountry); 
    localStorage.setItem('customerEvaluationFormKinInChosenCountry',response.customerEvaluationFormKinInChosenCountry); 
    localStorage.setItem('customerEvaluationFormNamesOfCountriesRejectedLastFiveYears',response.customerEvaluationFormNamesOfCountriesRejectedLastFiveYears); 
    localStorage.setItem('customerEvaluationFormRejectionHistoryFromACountry',response.customerEvaluationFormRejectionHistoryFromACountry); 
    localStorage.setItem('customerEvaluationFormNamesOfCountryCustomerVisited',response.customerEvaluationFormNamesOfCountryCustomerVisited); 
    localStorage.setItem('customerEvaluationFormTravelToSchengenAreaOtherPlace',response.customerEvaluationFormTravelToSchengenAreaOtherPlace); 
    localStorage.setItem('customerEvaluationFormPurposeOfMigration',response.customerEvaluationFormPurposeOfMigration); 
    localStorage.setItem('customerEvaluationFormTypeOfVisaRequested',response.customerEvaluationFormTypeOfVisaRequested); 
    localStorage.setItem('customerEvaluationFormCountryOfImmigration',response.customerEvaluationFormCountryOfImmigration); 
    localStorage.setItem('customerEvaluationFormCompanion',response.customerEvaluationFormCompanion); 
    localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsGender',response.customerEvaluationFormChildrenOverEighteenYearsGender); 
    localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsAge',response.customerEvaluationFormChildrenOverEighteenYearsAge); 
    localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsQuantity',response.customerEvaluationFormChildrenOverEighteenYearsQuantity); 
    localStorage.setItem('customerEvaluationFormChildrenOverEighteenStatus',response.customerEvaluationFormChildrenOverEighteenStatus); 
    localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender); 
    localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge); 
    localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity); 
    localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenStatus',response.customerEvaluationFormChildrenBetweenFourAndSeventeenStatus); 
    localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsGender',response.customerEvaluationFormChildrenUnderFourYearsGender); 
    localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsAge',response.customerEvaluationFormChildrenUnderFourYearsAge); 
    localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsQuantity',response.customerEvaluationFormChildrenUnderFourYearsQuantity); 
    localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsStatus',response.customerEvaluationFormChildrenUnderFourYearsStatus); 
    localStorage.setItem('customerEvaluationFormTotalAssets',response.customerEvaluationFormTotalAssets); 
    localStorage.setItem('customerEvaluationFormFinancialLiquidity',response.customerEvaluationFormFinancialLiquidity); 
    localStorage.setItem('customerEvaluationFormAnnualTurnover',response.customerEvaluationFormAnnualTurnover); 
    localStorage.setItem('customerEvaluationFormJobPositionNumberThree',response.customerEvaluationFormJobPositionNumberThree); 
    localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberThree',response.customerEvaluationFormInsuranceHistoryNumberThree); 
    localStorage.setItem('customerEvaluationFormCareerNumberThree',response.customerEvaluationFormCareerNumberThree); 
    localStorage.setItem('customerEvaluationFormJobPositionNumberTwo',response.customerEvaluationFormJobPositionNumberTwo); 
    localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberTwo',response.customerEvaluationFormInsuranceHistoryNumberTwo); 
    localStorage.setItem('customerEvaluationFormCareerNumberTwo',response.customerEvaluationFormCareerNumberTwo); 
    localStorage.setItem('customerEvaluationFormJobPositionNumberOne',response.customerEvaluationFormJobPositionNumberOne); 
    localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberOne',response.customerEvaluationFormInsuranceHistoryNumberOne); 
    localStorage.setItem('customerEvaluationFormCareerNumberOne',response.customerEvaluationFormCareerNumberOne); 
    localStorage.setItem('customerEvaluationFormFamiliarityWithTheKoreanLanguage',response.customerEvaluationFormFamiliarityWithTheKoreanLanguage); 
    localStorage.setItem('customerEvaluationFormKoreanLanguageCanSpeak',response.customerEvaluationFormKoreanLanguageCanSpeak); 
    localStorage.setItem('customerEvaluationFormFinalChineseTestScore',response.customerEvaluationFormFinalChineseTestScore); 
    localStorage.setItem('customerEvaluationFormFamiliarityWithTheChineseLanguage',response.customerEvaluationFormFamiliarityWithTheChineseLanguage); 
    localStorage.setItem('customerEvaluationFormChineseLanguageCanSpeak',response.customerEvaluationFormChineseLanguageCanSpeak); 
    localStorage.setItem('customerEvaluationFormFinalSpanishTestScore',response.customerEvaluationFormFinalSpanishTestScore); 
    localStorage.setItem('customerEvaluationFormFamiliarityWithTheSpanishLanguage',response.customerEvaluationFormFamiliarityWithTheSpanishLanguage); 
    localStorage.setItem('customerEvaluationFormSpanishLanguageCanSpeak',response.customerEvaluationFormSpanishLanguageCanSpeak); 
    localStorage.setItem('customerEvaluationFormFinalArabicTestScore',response.customerEvaluationFormFinalArabicTestScore); 
    localStorage.setItem('customerEvaluationFormFamiliarityWithTheArabicLanguage',response.customerEvaluationFormFamiliarityWithTheArabicLanguage); 
    localStorage.setItem('customerEvaluationFormArabicLanguageCanSpeak',response.customerEvaluationFormArabicLanguageCanSpeak); 
    localStorage.setItem('customerEvaluationFormFinalDanishTestScore',response.customerEvaluationFormFinalDanishTestScore); 
    localStorage.setItem('customerEvaluationFormFamiliarityWithTheDanishLanguage',response.customerEvaluationFormFamiliarityWithTheDanishLanguage); 
    localStorage.setItem('customerEvaluationFormDanishLanguageCanSpeak',response.customerEvaluationFormDanishLanguageCanSpeak); 
    localStorage.setItem('customerEvaluationFormFinalGermanTestScore',response.customerEvaluationFormFinalGermanTestScore); 
    localStorage.setItem('customerEvaluationFormFamiliarityWithTheGermanLanguage',response.customerEvaluationFormFamiliarityWithTheGermanLanguage); 
    localStorage.setItem('customerEvaluationFormGermanLanguageCanSpeak',response.customerEvaluationFormGermanLanguageCanSpeak); 
    localStorage.setItem('customerEvaluationFormFinalFrenchTestScore',response.customerEvaluationFormFinalFrenchTestScore); 
    localStorage.setItem('customerEvaluationFormFamiliarityWithTheFrenchLanguage',response.customerEvaluationFormFamiliarityWithTheFrenchLanguage); 
    localStorage.setItem('customerEvaluationFormFrenchLanguageCanSpeak',response.customerEvaluationFormFrenchLanguageCanSpeak); 
    localStorage.setItem('customerEvaluationFormFinalEnglishTestScore',response.customerEvaluationFormFinalEnglishTestScore); 
    localStorage.setItem('customerEvaluationFormFamiliarityWithTheEnglishLanguage',response.customerEvaluationFormFamiliarityWithTheEnglishLanguage); 
    localStorage.setItem('customersEvaluationFormEnglishLanguageCanSpeak',response.customersEvaluationFormEnglishLanguageCanSpeak); 
    localStorage.setItem('customersEvaluationFormResearchNumberThree',response.customersEvaluationFormResearchNumberThree); 
    localStorage.setItem('customersEvaluationFormResearchNumberTwo',response.customersEvaluationFormResearchNumberTwo); 
    localStorage.setItem('customersEvaluationFormResearchNumberOne',response.customersEvaluationFormResearchNumberOne); 
    localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberThree',response.customersEvaluationFormCertificatesAcknowledgmentNumberThree); 
    localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberTwo',response.customersEvaluationFormCertificatesAcknowledgmentNumberTwo); 
    localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberOne',response.customersEvaluationFormCertificatesAcknowledgmentNumberOne); 
    localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberThree',response.customerEvaluationFormCertificatesNonAcademicNumberThree); 
    localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberTwo',response.customerEvaluationFormCertificatesNonAcademicNumberTwo); 
    localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberOne',response.customerEvaluationFormCertificatesNonAcademicNumberOne); 
    localStorage.setItem('customerEvaluationFormFieldOfStudy',response.customerEvaluationFormFieldOfStudy); 
    localStorage.setItem('customerEvaluationFormEducationalDegrees',response.customerEvaluationFormEducationalDegrees); 
    localStorage.setItem('customerEvaluationFormMartialStatus',response.customerEvaluationFormMartialStatus); 
    localStorage.setItem('customerEvaluationFormMilitaryServiceStatus',response.customerEvaluationFormMilitaryServiceStatus); 
    localStorage.setItem('customerEvaluationFormAge',response.customerEvaluationFormAge); 
    localStorage.setItem('customerEvaluationFormPlaceOfBirth',response.customerEvaluationFormPlaceOfBirth); 
    localStorage.setItem('customerEvaluationFormAddress',response.customerEvaluationFormAddress); 
    localStorage.setItem('customerEvaluationFormFirstName',response.customerEvaluationFormFirstName); 
    localStorage.setItem('customerEvaluationFormCity',response.customerEvaluationFormCity); 
    localStorage.setItem('customerEvaluationFormProvince',response.customerEvaluationFormProvince); 
    localStorage.setItem('customerEvaluationFormCountry',response.customerEvaluationFormCountry); 
    localStorage.setItem('customerEvaluationFormPhoneNumber',response.customerEvaluationFormPhoneNumber);     
    localStorage.setItem('customerEvaluationFormLandlineNumber',response.customerEvaluationFormLandlineNumber); 
    localStorage.setItem('customerEvaluationFormEmailAddress',response.customerEvaluationFormEmailAddress); 
    localStorage.setItem('customerEvaluationFormGender',response.customerEvaluationFormGender); 
    localStorage.setItem('customerEvaluationFormLastName',response.customerEvaluationFormLastName); 

    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));






    localStorage.setItem('EvFormUpdate',true);
    
    console.log(localStorage.getItem('EvFormUpdate'));

    }).catch(()=>{
      fetch('https://telesiness.ir/api/CustomerDocuments/GetDataEvaluationForm' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Email: localStorage.getItem('emailCus') ,CustomerCode: localStorage.getItem('customerCode') })
      }).then(response => response.json()).then(response=>{
  
        // setIspending(false);
     if(!(localStorage.getItem('emailCus')==null&&localStorage.getItem('CusCode')==null))
     {
      console.log(localStorage.getItem('emailCus'),localStorage.getItem('CusCode'));
      localStorage.setItem('attachmentFile',response.attachmentFile);
      localStorage.setItem('customerEvaluationFormAnotherDescriptions',response.customerEvaluationFormAnotherDescriptions);
      localStorage.setItem('customerEvaluationFormSpouseCompanion',response.customerEvaluationFormSpouseCompanion);
      localStorage.setItem('customerEvaluationFormSpouseJobPosition',response.customerEvaluationFormSpouseJobPosition);
      localStorage.setItem('customerEvaluationFormSpouseCareer',response.customerEvaluationFormSpouseCareer);
      localStorage.setItem('lastEducationalCertificate',response.lastEducationalCertificate);
      localStorage.setItem('customerEvaluationFormSpouseInsuranceHistory',response.customerEvaluationFormSpouseInsuranceHistory);
      localStorage.setItem('customerEvaluationFormSpouseFinalKoreanTestScore',response.customerEvaluationFormSpouseFinalKoreanTestScore);
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage);
      localStorage.setItem('customerEvaluationFormSpouseKoreanLanguageCanSpeak',response.customerEvaluationFormSpouseKoreanLanguageCanSpeak);
      localStorage.setItem('specializedSectionInTheFieldOfImmigration',response.specializedSectionInTheFieldOfImmigration);
      localStorage.setItem('customerEvaluationFormSpouseFinalChineseTestScore',response.customerEvaluationFormSpouseFinalChineseTestScore);
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage);
      localStorage.setItem('customerEvaluationFormSpouseChineseLanguageCanSpeak',response.customerEvaluationFormSpouseChineseLanguageCanSpeak);
      localStorage.setItem('customerEvaluationFormSpouseFinalSpanishTestScore',response.customerEvaluationFormSpouseFinalSpanishTestScore);
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage);
      localStorage.setItem('customerEvaluationFormSpouseSpanishLanguageCanSpeak',response.customerEvaluationFormSpouseSpanishLanguageCanSpeak);
      localStorage.setItem('customerEvaluationFormSpouseFinalArabicTestScore',response.customerEvaluationFormSpouseFinalArabicTestScore);
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage);
      localStorage.setItem('customerEvaluationFormSpouseArabicLanguageCanSpeak',response.customerEvaluationFormSpouseArabicLanguageCanSpeak);
      localStorage.setItem('customerEvaluationFormSpouseFinalDanishTestScore',response.customerEvaluationFormSpouseFinalDanishTestScore);
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage);
      localStorage.setItem('customerEvaluationFormSpouseDanishLanguageCanSpeak',response.customerEvaluationFormSpouseDanishLanguageCanSpeak);
      localStorage.setItem('customerEvaluationFormSpouseFinalGermanTestScore',response.customerEvaluationFormSpouseFinalGermanTestScore);
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage);
      localStorage.setItem('customerEvaluationFormSpouseGermanLanguageCanSpeak',response.customerEvaluationFormSpouseGermanLanguageCanSpeak);
      localStorage.setItem('customerEvaluationFormSpouseFinalFrenchTestScore',response.customerEvaluationFormSpouseFinalFrenchTestScore);
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage);
      localStorage.setItem('customerEvaluationFormSpouseFrenchLanguageCanSpeak',response.customerEvaluationFormSpouseFrenchLanguageCanSpeak);
      localStorage.setItem('customerEvaluationFormSpouseFinalEnglishTestScore',response.customerEvaluationFormSpouseFinalEnglishTestScore);
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage',response.customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage);
      localStorage.setItem('customersEvaluationFormSpouseEnglishLanguageCanSpeak',response.customersEvaluationFormSpouseEnglishLanguageCanSpeak);
      localStorage.setItem('customerEvaluationFormSpouseEducationalDegrees',response.customerEvaluationFormSpouseEducationalDegrees);
      localStorage.setItem('customerEvaluationFormSpouseFieldOfStudy',response.customerEvaluationFormSpouseFieldOfStudy);
      localStorage.setItem('customerEvaluationFormSpouseLastName',response.customerEvaluationFormSpouseLastName);
      localStorage.setItem('customerEvaluationFormExplainFinancialSupporter',response.customerEvaluationFormExplainFinancialSupporter);  
      localStorage.setItem('customerEvaluationFormApplicantFinancialSupporter',response.customerEvaluationFormApplicantFinancialSupporter); 
      localStorage.setItem('customerEvaluationFormExplainKinInChosenCountry',response.customerEvaluationFormExplainKinInChosenCountry); 
      localStorage.setItem('customerEvaluationFormKinInChosenCountry',response.customerEvaluationFormKinInChosenCountry); 
      localStorage.setItem('customerEvaluationFormNamesOfCountriesRejectedLastFiveYears',response.customerEvaluationFormNamesOfCountriesRejectedLastFiveYears); 
      localStorage.setItem('customerEvaluationFormRejectionHistoryFromACountry',response.customerEvaluationFormRejectionHistoryFromACountry); 
      localStorage.setItem('customerEvaluationFormNamesOfCountryCustomerVisited',response.customerEvaluationFormNamesOfCountryCustomerVisited); 
      localStorage.setItem('customerEvaluationFormTravelToSchengenAreaOtherPlace',response.customerEvaluationFormTravelToSchengenAreaOtherPlace); 
      localStorage.setItem('customerEvaluationFormPurposeOfMigration',response.customerEvaluationFormPurposeOfMigration); 
      localStorage.setItem('customerEvaluationFormTypeOfVisaRequested',response.customerEvaluationFormTypeOfVisaRequested); 
      localStorage.setItem('customerEvaluationFormCountryOfImmigration',response.customerEvaluationFormCountryOfImmigration); 
      localStorage.setItem('customerEvaluationFormCompanion',response.customerEvaluationFormCompanion); 
      localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsGender',response.customerEvaluationFormChildrenOverEighteenYearsGender); 
      localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsAge',response.customerEvaluationFormChildrenOverEighteenYearsAge); 
      localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsQuantity',response.customerEvaluationFormChildrenOverEighteenYearsQuantity); 
      localStorage.setItem('customerEvaluationFormChildrenOverEighteenStatus',response.customerEvaluationFormChildrenOverEighteenStatus); 
      localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender); 
      localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge); 
      localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity',response.customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity); 
      localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenStatus',response.customerEvaluationFormChildrenBetweenFourAndSeventeenStatus); 
      localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsGender',response.customerEvaluationFormChildrenUnderFourYearsGender); 
      localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsAge',response.customerEvaluationFormChildrenUnderFourYearsAge); 
      localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsQuantity',response.customerEvaluationFormChildrenUnderFourYearsQuantity); 
      localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsStatus',response.customerEvaluationFormChildrenUnderFourYearsStatus); 
      localStorage.setItem('customerEvaluationFormTotalAssets',response.customerEvaluationFormTotalAssets); 
      localStorage.setItem('customerEvaluationFormFinancialLiquidity',response.customerEvaluationFormFinancialLiquidity); 
      localStorage.setItem('customerEvaluationFormAnnualTurnover',response.customerEvaluationFormAnnualTurnover); 
      localStorage.setItem('customerEvaluationFormJobPositionNumberThree',response.customerEvaluationFormJobPositionNumberThree); 
      localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberThree',response.customerEvaluationFormInsuranceHistoryNumberThree); 
      localStorage.setItem('customerEvaluationFormCareerNumberThree',response.customerEvaluationFormCareerNumberThree); 
      localStorage.setItem('customerEvaluationFormJobPositionNumberTwo',response.customerEvaluationFormJobPositionNumberTwo); 
      localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberTwo',response.customerEvaluationFormInsuranceHistoryNumberTwo); 
      localStorage.setItem('customerEvaluationFormCareerNumberTwo',response.customerEvaluationFormCareerNumberTwo); 
      localStorage.setItem('customerEvaluationFormJobPositionNumberOne',response.customerEvaluationFormJobPositionNumberOne); 
      localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberOne',response.customerEvaluationFormInsuranceHistoryNumberOne); 
      localStorage.setItem('customerEvaluationFormCareerNumberOne',response.customerEvaluationFormCareerNumberOne); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheKoreanLanguage',response.customerEvaluationFormFamiliarityWithTheKoreanLanguage); 
      localStorage.setItem('customerEvaluationFormKoreanLanguageCanSpeak',response.customerEvaluationFormKoreanLanguageCanSpeak); 
      localStorage.setItem('customerEvaluationFormFinalChineseTestScore',response.customerEvaluationFormFinalChineseTestScore); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheChineseLanguage',response.customerEvaluationFormFamiliarityWithTheChineseLanguage); 
      localStorage.setItem('customerEvaluationFormChineseLanguageCanSpeak',response.customerEvaluationFormChineseLanguageCanSpeak); 
      localStorage.setItem('customerEvaluationFormFinalSpanishTestScore',response.customerEvaluationFormFinalSpanishTestScore); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheSpanishLanguage',response.customerEvaluationFormFamiliarityWithTheSpanishLanguage); 
      localStorage.setItem('customerEvaluationFormSpanishLanguageCanSpeak',response.customerEvaluationFormSpanishLanguageCanSpeak); 
      localStorage.setItem('customerEvaluationFormFinalArabicTestScore',response.customerEvaluationFormFinalArabicTestScore); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheArabicLanguage',response.customerEvaluationFormFamiliarityWithTheArabicLanguage); 
      localStorage.setItem('customerEvaluationFormArabicLanguageCanSpeak',response.customerEvaluationFormArabicLanguageCanSpeak); 
      localStorage.setItem('customerEvaluationFormFinalDanishTestScore',response.customerEvaluationFormFinalDanishTestScore); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheDanishLanguage',response.customerEvaluationFormFamiliarityWithTheDanishLanguage); 
      localStorage.setItem('customerEvaluationFormDanishLanguageCanSpeak',response.customerEvaluationFormDanishLanguageCanSpeak); 
      localStorage.setItem('customerEvaluationFormFinalGermanTestScore',response.customerEvaluationFormFinalGermanTestScore); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheGermanLanguage',response.customerEvaluationFormFamiliarityWithTheGermanLanguage); 
      localStorage.setItem('customerEvaluationFormGermanLanguageCanSpeak',response.customerEvaluationFormGermanLanguageCanSpeak); 
      localStorage.setItem('customerEvaluationFormFinalFrenchTestScore',response.customerEvaluationFormFinalFrenchTestScore); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheFrenchLanguage',response.customerEvaluationFormFamiliarityWithTheFrenchLanguage); 
      localStorage.setItem('customerEvaluationFormFrenchLanguageCanSpeak',response.customerEvaluationFormFrenchLanguageCanSpeak); 
      localStorage.setItem('customerEvaluationFormFinalEnglishTestScore',response.customerEvaluationFormFinalEnglishTestScore); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheEnglishLanguage',response.customerEvaluationFormFamiliarityWithTheEnglishLanguage); 
      localStorage.setItem('customersEvaluationFormEnglishLanguageCanSpeak',response.customersEvaluationFormEnglishLanguageCanSpeak); 
      localStorage.setItem('customersEvaluationFormResearchNumberThree',response.customersEvaluationFormResearchNumberThree); 
      localStorage.setItem('customersEvaluationFormResearchNumberTwo',response.customersEvaluationFormResearchNumberTwo); 
      localStorage.setItem('customersEvaluationFormResearchNumberOne',response.customersEvaluationFormResearchNumberOne); 
      localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberThree',response.customersEvaluationFormCertificatesAcknowledgmentNumberThree); 
      localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberTwo',response.customersEvaluationFormCertificatesAcknowledgmentNumberTwo); 
      localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberOne',response.customersEvaluationFormCertificatesAcknowledgmentNumberOne); 
      localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberThree',response.customerEvaluationFormCertificatesNonAcademicNumberThree); 
      localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberTwo',response.customerEvaluationFormCertificatesNonAcademicNumberTwo); 
      localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberOne',response.customerEvaluationFormCertificatesNonAcademicNumberOne); 
      localStorage.setItem('customerEvaluationFormFieldOfStudy',response.customerEvaluationFormFieldOfStudy); 
      localStorage.setItem('customerEvaluationFormEducationalDegrees',response.customerEvaluationFormEducationalDegrees); 
      localStorage.setItem('customerEvaluationFormMartialStatus',response.customerEvaluationFormMartialStatus); 
      localStorage.setItem('customerEvaluationFormMilitaryServiceStatus',response.customerEvaluationFormMilitaryServiceStatus); 
      localStorage.setItem('customerEvaluationFormAge',response.customerEvaluationFormAge); 
      localStorage.setItem('customerEvaluationFormPlaceOfBirth',response.customerEvaluationFormPlaceOfBirth); 
      localStorage.setItem('customerEvaluationFormAddress',response.customerEvaluationFormAddress); 
      localStorage.setItem('customerEvaluationFormFirstName',response.customerEvaluationFormFirstName); 
      localStorage.setItem('customerEvaluationFormCity',response.customerEvaluationFormCity); 
      localStorage.setItem('customerEvaluationFormProvince',response.customerEvaluationFormProvince); 
      localStorage.setItem('customerEvaluationFormCountry',response.customerEvaluationFormCountry); 
      localStorage.setItem('customerEvaluationFormPhoneNumber',response.customerEvaluationFormPhoneNumber);     
      localStorage.setItem('customerEvaluationFormLandlineNumber',response.customerEvaluationFormLandlineNumber); 
      localStorage.setItem('customerEvaluationFormEmailAddress',response.customerEvaluationFormEmailAddress); 
      localStorage.setItem('customerEvaluationFormGender',response.customerEvaluationFormGender); 
      localStorage.setItem('customerEvaluationFormLastName',response.customerEvaluationFormLastName); 
    }
     
    }).catch(()=>{
      localStorage.setItem('attachmentFile','');
      localStorage.setItem('customerEvaluationFormAnotherDescriptions','');
      localStorage.setItem('customerEvaluationFormSpouseCompanion','');
      localStorage.setItem('customerEvaluationFormSpouseJobPosition','');
      localStorage.setItem('customerEvaluationFormSpouseCareer','');
      localStorage.setItem('lastEducationalCertificate','');
      localStorage.setItem('customerEvaluationFormSpouseInsuranceHistory','');
      localStorage.setItem('customerEvaluationFormSpouseFinalKoreanTestScore','');
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage','');
      localStorage.setItem('customerEvaluationFormSpouseKoreanLanguageCanSpeak','');
      localStorage.setItem('specializedSectionInTheFieldOfImmigration','');
      localStorage.setItem('customerEvaluationFormSpouseFinalChineseTestScore','');
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage','');
      localStorage.setItem('customerEvaluationFormSpouseChineseLanguageCanSpeak','');
      localStorage.setItem('customerEvaluationFormSpouseFinalSpanishTestScore','');
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage','');
      localStorage.setItem('customerEvaluationFormSpouseSpanishLanguageCanSpeak','');
      localStorage.setItem('customerEvaluationFormSpouseFinalArabicTestScore','');
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage','');
      localStorage.setItem('customerEvaluationFormSpouseArabicLanguageCanSpeak','');
      localStorage.setItem('customerEvaluationFormSpouseFinalDanishTestScore','');
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage','');
      localStorage.setItem('customerEvaluationFormSpouseDanishLanguageCanSpeak','');
      localStorage.setItem('customerEvaluationFormSpouseFinalGermanTestScore','');
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage','');
      localStorage.setItem('customerEvaluationFormSpouseGermanLanguageCanSpeak','');
      localStorage.setItem('customerEvaluationFormSpouseFinalFrenchTestScore','');
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage','');
      localStorage.setItem('customerEvaluationFormSpouseFrenchLanguageCanSpeak','');
      localStorage.setItem('customerEvaluationFormSpouseFinalEnglishTestScore','');
      localStorage.setItem('customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage','');
      localStorage.setItem('customersEvaluationFormSpouseEnglishLanguageCanSpeak','');
      localStorage.setItem('customerEvaluationFormSpouseEducationalDegrees','');
      localStorage.setItem('customerEvaluationFormSpouseFieldOfStudy','');
      localStorage.setItem('customerEvaluationFormSpouseLastName','');
      localStorage.setItem('customerEvaluationFormExplainFinancialSupporter','');  
      localStorage.setItem('customerEvaluationFormApplicantFinancialSupporter',''); 
      localStorage.setItem('customerEvaluationFormExplainKinInChosenCountry',''); 
      localStorage.setItem('customerEvaluationFormKinInChosenCountry',''); 
      localStorage.setItem('customerEvaluationFormNamesOfCountriesRejectedLastFiveYears',''); 
      localStorage.setItem('customerEvaluationFormRejectionHistoryFromACountry',''); 
      localStorage.setItem('customerEvaluationFormNamesOfCountryCustomerVisited',''); 
      localStorage.setItem('customerEvaluationFormTravelToSchengenAreaOtherPlace',''); 
      localStorage.setItem('customerEvaluationFormPurposeOfMigration',''); 
      localStorage.setItem('customerEvaluationFormTypeOfVisaRequested',''); 
      localStorage.setItem('customerEvaluationFormCountryOfImmigration',''); 
      localStorage.setItem('customerEvaluationFormCompanion',''); 
      localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsGender',''); 
      localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsAge',''); 
      localStorage.setItem('customerEvaluationFormChildrenOverEighteenYearsQuantity',''); 
      localStorage.setItem('customerEvaluationFormChildrenOverEighteenStatus',''); 
      localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender',''); 
      localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge',''); 
      localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity',''); 
      localStorage.setItem('customerEvaluationFormChildrenBetweenFourAndSeventeenStatus',''); 
      localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsGender',''); 
      localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsAge',''); 
      localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsQuantity',''); 
      localStorage.setItem('customerEvaluationFormChildrenUnderFourYearsStatus',''); 
      localStorage.setItem('customerEvaluationFormTotalAssets',''); 
      localStorage.setItem('customerEvaluationFormFinancialLiquidity',''); 
      localStorage.setItem('customerEvaluationFormAnnualTurnover',''); 
      localStorage.setItem('customerEvaluationFormJobPositionNumberThree',''); 
      localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberThree',''); 
      localStorage.setItem('customerEvaluationFormCareerNumberThree',''); 
      localStorage.setItem('customerEvaluationFormJobPositionNumberTwo',''); 
      localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberTwo',''); 
      localStorage.setItem('customerEvaluationFormCareerNumberTwo',''); 
      localStorage.setItem('customerEvaluationFormJobPositionNumberOne',''); 
      localStorage.setItem('customerEvaluationFormInsuranceHistoryNumberOne',''); 
      localStorage.setItem('customerEvaluationFormCareerNumberOne',''); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheKoreanLanguage',''); 
      localStorage.setItem('customerEvaluationFormKoreanLanguageCanSpeak',''); 
      localStorage.setItem('customerEvaluationFormFinalChineseTestScore',''); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheChineseLanguage',''); 
      localStorage.setItem('customerEvaluationFormChineseLanguageCanSpeak',''); 
      localStorage.setItem('customerEvaluationFormFinalSpanishTestScore',''); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheSpanishLanguage',''); 
      localStorage.setItem('customerEvaluationFormSpanishLanguageCanSpeak',''); 
      localStorage.setItem('customerEvaluationFormFinalArabicTestScore',''); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheArabicLanguage',''); 
      localStorage.setItem('customerEvaluationFormArabicLanguageCanSpeak',''); 
      localStorage.setItem('customerEvaluationFormFinalDanishTestScore',''); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheDanishLanguage',''); 
      localStorage.setItem('customerEvaluationFormDanishLanguageCanSpeak',''); 
      localStorage.setItem('customerEvaluationFormFinalGermanTestScore',''); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheGermanLanguage',''); 
      localStorage.setItem('customerEvaluationFormGermanLanguageCanSpeak',''); 
      localStorage.setItem('customerEvaluationFormFinalFrenchTestScore',''); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheFrenchLanguage',''); 
      localStorage.setItem('customerEvaluationFormFrenchLanguageCanSpeak',''); 
      localStorage.setItem('customerEvaluationFormFinalEnglishTestScore',''); 
      localStorage.setItem('customerEvaluationFormFamiliarityWithTheEnglishLanguage',''); 
      localStorage.setItem('customersEvaluationFormEnglishLanguageCanSpeak',''); 
      localStorage.setItem('customersEvaluationFormResearchNumberThree',''); 
      localStorage.setItem('customersEvaluationFormResearchNumberTwo',''); 
      localStorage.setItem('customersEvaluationFormResearchNumberOne',''); 
      localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberThree',''); 
      localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberTwo',''); 
      localStorage.setItem('customersEvaluationFormCertificatesAcknowledgmentNumberOne',''); 
      localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberThree',''); 
      localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberTwo',''); 
      localStorage.setItem('customerEvaluationFormCertificatesNonAcademicNumberOne',''); 
      localStorage.setItem('customerEvaluationFormFieldOfStudy',''); 
      localStorage.setItem('customerEvaluationFormEducationalDegrees',''); 
      localStorage.setItem('customerEvaluationFormMartialStatus',''); 
      localStorage.setItem('customerEvaluationFormMilitaryServiceStatus',''); 
      localStorage.setItem('customerEvaluationFormAge',''); 
      localStorage.setItem('customerEvaluationFormPlaceOfBirth',''); 
      localStorage.setItem('customerEvaluationFormAddress',''); 
      localStorage.setItem('customerEvaluationFormFirstName',''); 
      localStorage.setItem('customerEvaluationFormCity',''); 
      localStorage.setItem('customerEvaluationFormProvince',''); 
      localStorage.setItem('customerEvaluationFormCountry',''); 
      localStorage.setItem('customerEvaluationFormPhoneNumber','');     
      localStorage.setItem('customerEvaluationFormLandlineNumber',''); 
      localStorage.setItem('customerEvaluationFormEmailAddress',''); 
      localStorage.setItem('customerEvaluationFormGender',''); 
      localStorage.setItem('customerEvaluationFormLastName',''); 
    })
    })
    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);

    useEffect(() => {
      if (counter.current < 4) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 3000);
        forceUpdate();



      if(localStorage.getItem('customerEvaluationFormFirstName')!='null')
      values.proFirstName=localStorage.getItem('customerEvaluationFormFirstName') ;
      else
      values.proFirstName='';
      
      if(localStorage.getItem('customerEvaluationFormLastName')!='null')
      values.proLastName= localStorage.getItem('customerEvaluationFormLastName') ;
      else
      values.proLastName = '';

      values.Gender= parseInt(localStorage.getItem('customerEvaluationFormGender'))  ;
      
      values.proPhone= localStorage.getItem('customerEvaluationFormPhoneNumber') ;
      
      values.proCallNumber= localStorage.getItem('customerEvaluationFormLandlineNumber') ;
      
      values.proEmail= localStorage.getItem('customerEvaluationFormEmailAddress') ;
      
      values.Address= localStorage.getItem('customerEvaluationFormAddress') ;
      
      values.City= localStorage.getItem('customerEvaluationFormCity') ;
      
      values.Country= localStorage.getItem('customerEvaluationFormProvince') ;
      
      values.Country= localStorage.getItem('customerEvaluationFormCountry') ;
      
      values.placeofBirth= localStorage.getItem('customerEvaluationFormPlaceOfBirth');
      
      values.ApplicantAge= localStorage.getItem('customerEvaluationFormAge');
      
      values.TypeMarriage= localStorage.getItem('customerEvaluationFormMartialStatus');
      
      values.militaryservicesituation= localStorage.getItem('customerEvaluationFormMilitaryServiceStatus') ;
      
      //Evaluation customer Form Educational Information
      values.LastEducationalCertificate= localStorage.getItem('customerEvaluationFormEducationalDegrees') ;
      
      values.FieldStudy= localStorage.getItem('customerEvaluationFormFieldOfStudy') ;
      
      values.NonuniversityCertificate1= localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberOne') ;
      
      values.NonuniversityCertificate2= localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberTwo') ;
      
      values.NonuniversityCertificate3= localStorage.getItem('customerEvaluationFormCertificatesNonAcademicNumberThree') ;
      
      values.ScientificDocumentsAwardsFestivals1= localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberOne') ;
      
      values.ScientificDocumentsAwardsFestivals2= localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberTwo') ;
      
      values.ScientificDocumentsAwardsFestivals3= localStorage.getItem('customersEvaluationFormCertificatesAcknowledgmentNumberThree') ;
      
      values.ResearchandStudies1= localStorage.getItem('customersEvaluationFormResearchNumberOne') ;
      
      values.ResearchandStudies2= localStorage.getItem('customersEvaluationFormResearchNumberTwo') ;
      
      values.ResearchandStudies3= localStorage.getItem('customersEvaluationFormResearchNumberThree') ;
      
      values.EnglishCheckBox= localStorage.getItem('customersEvaluationFormEnglishLanguageCanSpeak') ;
      
      values.EnglishFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheEnglishLanguage') ;
      
      values.EnglishScore= localStorage.getItem('customerEvaluationFormFinalEnglishTestScore') ;
      
      values.FrenchCheckBox= localStorage.getItem('customerEvaluationFormFrenchLanguageCanSpeak') ;
      
      values.FrenchFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheFrenchLanguage') ;
      
      values.FrenchScore= localStorage.getItem('customerEvaluationFormFinalFrenchTestScore') ;
      
      values.GermanCheckBox= localStorage.getItem('customerEvaluationFormGermanLanguageCanSpeak') ;
      
      values.GermanFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheGermanLanguage') ;
      
      values.GermanScore= localStorage.getItem('customerEvaluationFormFinalGermanTestScore') ;
      
      values.DanishCheckBox= localStorage.getItem('customerEvaluationFormDanishLanguageCanSpeak') ;
      
      values.DanishFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheDanishLanguage') ;
      
      values.DanishScore= localStorage.getItem('customerEvaluationFormFinalDanishTestScore') ;
      
      values.ArabicCheckBox= localStorage.getItem('customerEvaluationFormArabicLanguageCanSpeak') ;
      
      values.ArabicFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheArabicLanguage') ;
      
      values.ArabicScore= localStorage.getItem('customerEvaluationFormFinalArabicTestScore') ;
      
      values.SpanishCheckBox= localStorage.getItem('customerEvaluationFormSpanishLanguageCanSpeak') ;
      
      values.SpanishFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheSpanishLanguage') ;
      
      values.SpanishScore= localStorage.getItem('customerEvaluationFormFinalSpanishTestScore') ;
      
      values.ChineseCheckBox= localStorage.getItem('customerEvaluationFormChineseLanguageCanSpeak') ;
      
      values.ChineseFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheChineseLanguage') ;
      
      values.ChineseScore= localStorage.getItem('customerEvaluationFormFinalChineseTestScore') ;
      
      values.KoreanCheckBox= localStorage.getItem('customerEvaluationFormKoreanLanguageCanSpeak') ;
      
      values.KoreanFamiliarity= localStorage.getItem('customerEvaluationFormFamiliarityWithTheKoreanLanguage') ;
      
      values.KoreanScore= localStorage.getItem('customerEvaluationFormFinalKoreanTestScore') ;
      
      //Evaluation customer Form Job tenure
      values.Insurancehistory1= localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberOne') ;
      
      values.Insurancehistory2= localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberTwo') ;
      
      values.Insurancehistory3= localStorage.getItem('customerEvaluationFormInsuranceHistoryNumberThree') ;
      
      values.JobTitle1= localStorage.getItem('customerEvaluationFormCareerNumberOne') ;
      
      values.JobTitle2= localStorage.getItem('customerEvaluationFormCareerNumberTwo') ;
      
      values.JobTitle3= localStorage.getItem('customerEvaluationFormCareerNumberThree') ;
      
      values.JobPost1= localStorage.getItem('customerEvaluationFormJobPositionNumberOne') ;
      
      values.JobPost2= localStorage.getItem('customerEvaluationFormJobPositionNumberTwo') ;
      
      values.JobPost3= localStorage.getItem('customerEvaluationFormJobPositionNumberThree') ;
      
      //Evaluation customer Form Financial Information
      values.TurnoverNumber= localStorage.getItem('customerEvaluationFormAnnualTurnover') ;
      
      values.Liquidity= localStorage.getItem('customerEvaluationFormFinancialLiquidity') ;
      
      values.TotalAssetsAmount= localStorage.getItem('customerEvaluationFormTotalAssets') ;
      
      //Evaluation customer Form Supplementary Information Section One

      
      //Companion
      values.haveAcompanion= localStorage.getItem('customerEvaluationFormCompanion') ;
      
      //Evaluation customer Form Supplementary Information Section Two
      values.CountryOFimmigration= localStorage.getItem('customerEvaluationFormCountryOfImmigration') ;
      
      values.TypeofRequestedVisa= localStorage.getItem('customerEvaluationFormTypeOfVisaRequested') ;
      
      values.Thepurposeofmigration= localStorage.getItem('customerEvaluationFormPurposeOfMigration') ;
      
      values.TraveledSchengenareaCanada= localStorage.getItem('customerEvaluationFormTravelToSchengenAreaOtherPlace') ;
      
      values.NamesofCountriesYouHaveVisited= localStorage.getItem('customerEvaluationFormNamesOfCountryCustomerVisited') ;
      
      values.HistoryRejectionCountry= localStorage.getItem('customerEvaluationFormRejectionHistoryFromACountry') ;
      
      values.NamesCountriesRejected= localStorage.getItem('customerEvaluationFormNamesOfCountriesRejectedLastFiveYears') ;
      
      values.DoYouFirstSecondDegreeRelatives= localStorage.getItem('customerEvaluationFormKinInChosenCountry') ;
      
      values.Explain= localStorage.getItem('customerEvaluationFormExplainKinInChosenCountry') ;
      
      values.DoesHaveFinancialSupporter= localStorage.getItem('customerEvaluationFormApplicantFinancialSupporter') ;
      
      values.ExplainFinancialSupporter= localStorage.getItem('customerEvaluationFormExplainFinancialSupporter') ;
      
      //Evaluation customer Form Personal information of the applicant's spouse
      values.SpouseFirstName= localStorage.getItem('customerEvaluationFormSpouseFirstName') ;
      
      values.SpouseLastName= localStorage.getItem('customerEvaluationFormSpouseLastName') ;
      
      //Spouse Education
      values.LastEducationalCertificateS= localStorage.getItem('customerEvaluationFormSpouseEducationalDegrees') ;
      
      values.FieldStudyS= localStorage.getItem('customerEvaluationFormSpouseFieldOfStudy') ;
      
      values.EnglishCheckBoxS= localStorage.getItem('customersEvaluationFormSpouseEnglishLanguageCanSpeak') ;
      
      values.EnglishFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage') ;
      
      values.EnglishScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalEnglishTestScore') ;
      
      values.FrenchCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseFrenchLanguageCanSpeak') ;
      
      values.FrenchFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage') ;
      
      values.FrenchScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalFrenchTestScore') ;
      
      values.GermanCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseGermanLanguageCanSpeak') ;
      
      values.GermanFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage') ;
      
      values.GermanScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalGermanTestScore') ;
      
      values.DanishCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseDanishLanguageCanSpeak') ;
      
      values.DanishFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage') ;
      
      values.DanishScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalDanishTestScore') ;
      
      values.ArabicCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseArabicLanguageCanSpeak') ;
      
      values.ArabicFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage') ;
      
      values.ArabicScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalArabicTestScore') ;
      
      values.SpanishCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseSpanishLanguageCanSpeak') ;
      
      values.SpanishFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage') ;
      
      values.SpanishScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalSpanishTestScore') ;
      
      values.ChineseCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseChineseLanguageCanSpeak') ;
      
      values.ChineseFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage') ;
      
      values.ChineseScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalChineseTestScore') ;
      
      values.KoreanCheckBoxS= localStorage.getItem('customerEvaluationFormSpouseKoreanLanguageCanSpeak') ;
      
      values.KoreanFamiliarityS= localStorage.getItem('customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage') ;
      
      values.KoreanScoreS= localStorage.getItem('customerEvaluationFormSpouseFinalKoreanTestScore') ;
      
      //Spouse Job information
      values.Insurancehistory1S= localStorage.getItem('customerEvaluationFormSpouseInsuranceHistory') ;
      
      values.JobPost1S= localStorage.getItem('customerEvaluationFormSpouseCareer') ;
      
      values.JobPost1S= localStorage.getItem('customerEvaluationFormSpouseJobPosition') ;
      
      //Spouse Companion
      values.AccompanyPost= localStorage.getItem('customerEvaluationFormSpouseCompanion') ;
      
      //Another descriptions
      values.Descriptions= localStorage.getItem('customerEvaluationFormAnotherDescriptions') ;





        return () => clearTimeout(timer);
      }
    }, [state]);








const [selectedFile,setSelectedFile] = useState(null);
const uploadImage = (files) => {
console.log(files[0]);
setSelectedFile(files[0]);
const formData = new FormData();
formData.append('AttachmentFile',files[0]);
formData.append('CustomerCode',localStorage.getItem('customerCode'));
const headers = { 
  'Authorization': 'Bearer my-token',
  'My-Custom-Header': 'foobar'
};

if(localStorage.getItem('userType')==3)
if(files[0].size > 4000000){
  // document.getElementById('files2').innerHTML =  '<b>' + 'File Must be Lower than 4MB'+ '</b> ';
  values.files2 = 1;
  forceUpdate();
}else
  axios.post('https://telesiness.ir/api/CustomerDocuments/CustomerUploadCVOnEvaluationForm',formData,{
    onUploadProgress:progressEvent=> {console.log(progressEvent.loaded/progressEvent.total);}}) .then(res => {
      
       localStorage.setItem('userAvatar',res.data.attachmentFile);
       console.log(res.data.attachmentFile,res.data,res);

       if(localStorage.getItem('userType')==3)
       {
         var name = files[0]; 
         var totalFileSize = 0;
       
           //ACCESS THE SIZE PROPERTY OF THE ITEM OBJECT IN FILES COLLECTION. IN THIS WAY ALSO GET OTHER PROPERTIES LIKE FILENAME AND FILETYPE
           var fsize = name.size;
           totalFileSize = totalFileSize + fsize;
           document.getElementById('files').innerHTML =
           document.getElementById('files').innerHTML
           +
           '<b>' + name.name
           +
            '</b> ' + 'and Size is <b>' + Math.round((fsize / 1024)) //DEFAULT SIZE IS IN BYTES SO WE DIVIDING BY 1024 TO CONVERT IT IN KB
            +
            '</b> KB and File Type is <b>' + name.type + "</b>.";
            values.files2 = 0;
       
       }
      forceUpdate();
    })

  



// })
// }).then().then(history.push('/Dashboard'));
// console.log(files[0]);
}
   
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1' || localStorage.getItem('userType')=='2'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12" >
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 1')} :</p><p className="Cus2Step1BlackP">{t('Complete Basic Information')}</p><a className="back_pagelink" onClick={(dataCus1e1)=>{childToParentCus1e1(dataCus1e1)}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy')}</p></div>
          <details className="part_of_form" open>
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo">
                <p>{t('Personal Information')}</p>
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="proFirstName" 
                  value={values.proFirstName}
                  type="text"
                  placeholder={t('First Name')}
                  onChange={handleChange('proFirstName')}
                  />
                  <label htmlFor="proFirstName">{t('Enter Your First Name in Persian')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="proLastName" 
                  value={values.proLastName}
                  type="text"
                  placeholder={t('Last Name')}
                  onChange={handleChange('proLastName')}
                  />
                  <label htmlFor="proLastName">{t('Enter Your Last Name in Persian')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="Gender"
                    value={values.Gender}
                    label={t('Skin Color')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('Gender')}  
                    placeholder={t('Gender')}
                    
                    >
                      <option  value="">
                      {t('Gender')}
                    </option >
                    <option  value='1'>{t('Male')}</option >
                    <option  value='2'>{t('Female')}</option >
                  
                  </select >
                  <label htmlFor="Gender">{t('Enter the applicants Gender')}<p className="ReqiredCus">{t('(Required)')}</p></label>
              </div>
              {/* <div className="inputAndlabelRadio col-4">
                <p>{t('Gender')}</p>
                <input type="radio" id="mail" name="fav_language" value={'male'} onChange={handleChange('Gender')}/>
                <label htmlFor="mail">{t('Male')}</label>
                <input type="radio" id="female" name="fav_language" value={'female'} onChange={handleChange('Gender')}/>
                <label htmlFor="female">{t('Female')}</label>
              </div> */}
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="proEmail" 
                  value={values.proEmail}
                  type="text"
                  placeholder={t('E-mail')}
                  onChange={handleChange('proEmail')}
                  />
                  <label htmlFor="proEmail">{t('Enter the applicants Email address')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="proCallNumber" 
                  value={values.proCallNumber}
                  type="number"
                  placeholder={t('Call Number')}
                  onChange={handleChange('proCallNumber')}
                  />
                  <label htmlFor="proCallNumber">{t('Enter the applicants Call number')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="proPhone" 
                  value={values.proPhone}
                  type="number"
                  placeholder={t('Phone Number')}
                  onChange={handleChange('proPhone')}
                  />
                  <label htmlFor="proPhone">{t('Enter the applicants Phone number')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="Country"
                    value={values.Country}
                    label={t('Country')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('Country')}  
                    placeholder={t('Select Your Country')}
                    >
                      <option  value="">
                      {t('Select Your Country')}
                    </option >
                    {dataC &&
                      dataC.map((element) => (
                        <option  value={element.countery_ID}>{element.countery_Name}</option >
                    ))}
                    {/* <option  value="">
                    </option >
                    <option  value='1'>{t('Iran')}</option >
                    <option  value='2'>{t('England')}</option >
                    <option  value='3'>{t('Turkey')}</option >
                    <option  value='4'>{t('UAE')}</option >
                    <option  value='5'>{t('USA')}</option > */}
                  </select >
                  <label htmlFor="Country">{t('Enter the applicants Country')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="State" 
                  value={values.State}
                  type="text"
                  placeholder={t('State')}
                  onChange={handleChange('State')}
                  />
                  <label htmlFor="State">{t('Enter Your State')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="City" 
                  value={values.City}
                  type="text"
                  placeholder={t('City')}
                  onChange={handleChange('City')}
                  />
                  <label htmlFor="City">{t('Enter Your City')}</label>
              </div>
              <div className="inputAndlabel col-lg-8 col-12">
                <input
                  id="Address" 
                  value={values.Address}
                  type="text"
                  placeholder={t('Address')}
                  onChange={handleChange('Address')}
                  />
                  <label htmlFor="Address">{t('Enter the applicants address')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="placeofBirth" 
                  value={values.placeofBirth}
                  type="text"
                  placeholder={t('place of Birth')}
                  onChange={handleChange('placeofBirth')}
                  />
                  <label htmlFor="placeofBirth">{t('Enter the applicants place of birth')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="ApplicantAge" 
                  value={values.ApplicantAge}
                  type="number"
                  placeholder={t('Applicant Age')}
                  onChange={handleChange('ApplicantAge')}
                  
                  />
                  <label htmlFor="ApplicantAge">{t('Enter the Applicant Age')}</label>
              </div>
              {values.Gender == '1' &&<div className="inputAndlabel col-lg-4 col-12">
                <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="militaryservicesituation"
                    value={values.militaryservicesituation}
                    label={t('militaryservicesituation')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('militaryservicesituation')}  
                    placeholder={t('military service situation')}
                    >
                      <option  value="">
                      {t('military service situation')}
                    </option >
                    <option  value='1'>{t('Included')}</option >
                    <option  value='2'>{t('Served')}</option >
                    <option  value='3'>{t('Conscription Exempt')}</option >
                    <option  value='4'>{t('Purchased Service')}</option >
                  </select >
                  <label htmlFor="militaryservicesituation">{t('Enter the military service situation')}</label>
              </div>}
              <div className="inputAndlabel col-lg-4 col-12">
                <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="TypeMarriage"
                    value={values.TypeMarriage}
                    label={t('Type of Marriage')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('TypeMarriage')}  
                    placeholder={t('Type of Marriage')}
                    >
                      <option  value="">
                      {t('Type of Marriage')}
                    </option >
                    <option  value='1'>{t('Married')}</option >
                    <option  value='2'>{t('Single')}</option >
                    <option  value='3'>{t('Other')}</option >

                </select >
                <label htmlFor="TypeMarriage">{t('Enter the applicants Type of Marriage')}</label>
              </div>
            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Educational Information')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="inputAndlabel col-lg-4 col-12">
              <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="LastEducationalCertificate"
                    value={values.LastEducationalCertificate}
                    label={t('Type of Marriage')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('LastEducationalCertificate')}  
                    placeholder={t('Last Educational Certificate')}
                    >
                      <option  value="">
                      {t('Last Educational Certificate')}
                    </option >
                    <option  value='1'>{t('Diploma')}</option >
                    <option  value='2'>{t('Pre-university')}</option >
                    <option  value='3'>{t('Associate Degree')}</option >
                    <option  value='4'>{t('Bachelor Degree')}</option >
                    <option  value='5'>{t('Masters Degree')}</option >
                    <option  value='7'>{t('P.H.D Degree')}</option >
                    <option  value='8'>{t('Post-Doctoral Degree')}</option >

                  </select >
                  <label htmlFor="LastEducationalCertificate">{t('Enter the applicants Last Educational Certificate')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="FieldStudy" 
                  value={values.FieldStudy}
                  type="text"
                  placeholder={t('Field of Study')}
                  onChange={handleChange('FieldStudy')}
                  
                  />
                  <label htmlFor="FieldStudy">{t('Enter the applicants Field of Study')} <p className="ReqiredCus">{t('(Required)')}</p></label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="NonuniversityCertificate1" 
                  value={values.NonuniversityCertificate1}
                  type="text"
                  placeholder={t('Non-university Certificate Name(1)')}
                  onChange={handleChange('NonuniversityCertificate1')}
                  />
                  <label htmlFor="NonuniversityCertificate1">{t('Enter the applicants Non-university Certificate Name(1)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="NonuniversityCertificate2" 
                  value={values.NonuniversityCertificate2}
                  type="text"
                  placeholder={t('Non-university Certificate Name(2)')}
                  onChange={handleChange('NonuniversityCertificate2')}
                  />
                  <label htmlFor="NonuniversityCertificate2">{t('Enter the applicants Non-university Certificate Name(2)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="NonuniversityCertificate3" 
                  value={values.NonuniversityCertificate3}
                  type="text"
                  placeholder={t('Non-university Certificate Name(3)')}
                  onChange={handleChange('NonuniversityCertificate3')}
                  />
                  <label htmlFor="NonuniversityCertificate3">{t('Enter the applicants Non-university Certificate Name(3)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="ScientificDocumentsAwardsFestivals1" 
                  value={values.ScientificDocumentsAwardsFestivals1}
                  type="text"
                  placeholder={t('Scientific Documents of Awards or Festivals(1)')}
                  onChange={handleChange('ScientificDocumentsAwardsFestivals1')}
                  />
                  <label htmlFor="ScientificDocumentsAwardsFestivals1">{t('Enter the applicants Scientific Documents of Awards or Festivals(1)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="ScientificDocumentsAwardsFestivals2" 
                  value={values.ScientificDocumentsAwardsFestivals2}
                  type="text"
                  placeholder={t('Scientific Documents of Awards or Festivals(2)')}
                  onChange={handleChange('ScientificDocumentsAwardsFestivals2')}
                  />
                  <label htmlFor="ScientificDocumentsAwardsFestivals2">{t('Enter the applicants Scientific Documents of Awards or Festivals(2)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="ScientificDocumentsAwardsFestivals3" 
                  value={values.ScientificDocumentsAwardsFestivals3}
                  type="text"
                  placeholder={t('Scientific Documents of Awards or Festivals(3)')}
                  onChange={handleChange('ScientificDocumentsAwardsFestivals3')}
                  />
                  <label htmlFor="ScientificDocumentsAwardsFestivals3">{t('Enter the applicants Scientific Documents of Awards or Festivals(3)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="ResearchandStudies1" 
                  value={values.ResearchandStudies1}
                  type="text"
                  placeholder={t('Research and Studies(1)')}
                  onChange={handleChange('ResearchandStudies1')}
                  />
                  <label htmlFor="ResearchandStudies1">{t('Enter the applicants Research and Studies(1)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="ResearchandStudies2" 
                  value={values.ResearchandStudies2}
                  type="text"
                  placeholder={t('Research and Studies(2)')}
                  onChange={handleChange('ResearchandStudies2')}
                  />
                  <label htmlFor="ResearchandStudies2">{t('Enter the applicants Research and Studies(2)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="ResearchandStudies3" 
                  value={values.ResearchandStudies3}
                  type="text"
                  placeholder={t('Research and Studies(3)')}
                  onChange={handleChange('ResearchandStudies3')}
                  />
                  <label htmlFor="ResearchandStudies3">{t('Enter the applicants Research and Studies(3)')}</label>
              </div>
              <div className="languagesspeaks col-12">     
                  <p className="inputAndlabelP">{t('What languages can you speak?')}</p>  
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="EnglishCheckBox" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="EnglishCheckBox" 
                        value={EnglishCheckBox}
                        checked={EnglishCheckBox} 
                        onChange={() => setEnglishCheckBox(!EnglishCheckBox)}/>
                    <p htmlFor='EnglishCheckBox'>{t('English')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="EnglishFamiliarity"
                            value={values.EnglishFamiliarity}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('EnglishFamiliarity')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="EnglishFamiliarity">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="EnglishScore" 
                      value={values.EnglishScore}
                      type="text"
                      placeholder={t('English Score')}
                      onChange={handleChange('EnglishScore')}
                      />
                      <label htmlFor="EnglishScore">{t('Enter the applicants English Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="FrenchCheckBox" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="FrenchCheckBox" 
                        value={FrenchCheckBox}
                        checked={FrenchCheckBox} 
                        onChange={() => setFrenchCheckBox(!FrenchCheckBox)}/>
                    <p htmlFor='FrenchCheckBox'>{t('French')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="FrenchFamiliarity"
                            value={values.FrenchFamiliarity}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('FrenchFamiliarity')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="FrenchFamiliarity">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="FrenchScore" 
                      value={values.FrenchScore}
                      type="text"
                      placeholder={t('French Score')}
                      onChange={handleChange('FrenchScore')}
                      />
                      <label htmlFor="FrenchScore">{t('Enter the applicants French Score')}</label>
                  </div>
                  </div>  <br />   
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="GermanCheckBox" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="GermanCheckBox" 
                        value={GermanCheckBox}
                        checked={GermanCheckBox} 
                        onChange={() => setGermanCheckBox(!GermanCheckBox)}/>
                    <p htmlFor='GermanCheckBox'>{t('German')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="GermanFamiliarity"
                            value={values.GermanFamiliarity}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('GermanFamiliarity')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="GermanFamiliarity">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="GermanScore" 
                      value={values.GermanScore}
                      type="text"
                      placeholder={t('German Score')}
                      onChange={handleChange('GermanScore')}
                      />
                      <label htmlFor="GermanScore">{t('Enter the applicants German Score')}</label>
                  </div>
                  </div>  <br /> 
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="DanishCheckBox" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="DanishCheckBox" 
                        value={DanishCheckBox}
                        checked={DanishCheckBox} 
                        onChange={() => setDanishCheckBox(!DanishCheckBox)}/>
                    <p htmlFor='DanishCheckBox'>{t('Danish')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="DanishFamiliarity"
                            value={values.DanishFamiliarity}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('DanishFamiliarity')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="DanishFamiliarity">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="DanishScore" 
                      value={values.DanishScore}
                      type="text"
                      placeholder={t('Danish Score')}
                      onChange={handleChange('DanishScore')}
                      />
                      <label htmlFor="DanishScore">{t('Enter the applicants Danish Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="ArabicCheckBox" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="ArabicCheckBox" 
                        value={ArabicCheckBox}
                        checked={ArabicCheckBox} 
                        onChange={() => setArabicCheckBox(!ArabicCheckBox)}/>
                    <p htmlFor='ArabicCheckBox'>{t('Arabic')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="ArabicFamiliarity"
                            value={values.ArabicFamiliarity}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('ArabicFamiliarity')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="ArabicFamiliarity">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="ArabicScore" 
                      value={values.ArabicScore}
                      type="text"
                      placeholder={t('Arabic Score')}
                      onChange={handleChange('ArabicScore')}
                      />
                      <label htmlFor="ArabicScore">{t('Enter the applicants Arabic Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="SpanishCheckBox" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="SpanishCheckBox" 
                        value={SpanishCheckBox}
                        checked={SpanishCheckBox} 
                        onChange={() => setSpanishCheckBox(!SpanishCheckBox)}/>
                    <p htmlFor='SpanishCheckBox'>{t('Spanish')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="SpanishFamiliarity"
                            value={values.SpanishFamiliarity}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('SpanishFamiliarity')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="SpanishFamiliarity">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="SpanishScore" 
                      value={values.SpanishScore}
                      type="text"
                      placeholder={t('Spanish Score')}
                      onChange={handleChange('SpanishScore')}
                      />
                      <label htmlFor="SpanishScore">{t('Enter the applicants Spanish Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="ChineseCheckBox" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="ChineseCheckBox" 
                        value={ChineseCheckBox}
                        checked={ChineseCheckBox} 
                        onChange={() => setChineseCheckBox(!ChineseCheckBox)}/>
                    <p htmlFor='ChineseCheckBox'>{t('Chinese')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="ChineseFamiliarity"
                            value={values.ChineseFamiliarity}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('ChineseFamiliarity')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="ChineseFamiliarity">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="ChineseScore" 
                      value={values.ChineseScore}
                      type="text"
                      placeholder={t('Chinese Score')}
                      onChange={handleChange('ChineseScore')}
                      />
                      <label htmlFor="ChineseScore">{t('Enter the applicants Chinese Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="KoreanCheckBox" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="KoreanCheckBox" 
                        value={KoreanCheckBox}
                        checked={KoreanCheckBox} 
                        onChange={() => setKoreanCheckBox(!KoreanCheckBox)}/>
                    <p htmlFor='KoreanCheckBox'>{t('Korean')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="KoreanFamiliarity"
                            value={values.KoreanFamiliarity}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('KoreanFamiliarity')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="KoreanFamiliarity">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="KoreanScore" 
                      value={values.KoreanScore}
                      type="text"
                      placeholder={t('Korean Score')}
                      onChange={handleChange('KoreanScore')}
                      />
                      <label htmlFor="KoreanScore">{t('Enter the applicants Korean Score')}</label>
                  </div>
                  </div>  <br />   
              </div>


            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Applicant Job Information')}-{t('(In past 10 years)')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
                <div className="inputAndlabel col-lg-4 col-12">
                    <input
                          id="JobTitle1" 
                          value={values.JobTitle1}
                          type="text"
                          placeholder={t('Job Title (1)')}
                          onChange={handleChange('JobTitle1')}
                          />
                    <label htmlFor="JobTitle1">{t('Enter the Job Title (1)')}</label>
                </div>
                <div className="inputAndlabel col-lg-4 col-12">
                  <input
                        id="Insurancehistory1" 
                        value={values.Insurancehistory1}
                        type="text"
                        placeholder={t('Insurance history or duration of activity in your job (1)')}
                        onChange={handleChange('Insurancehistory1')}
                        />
                  <label htmlFor="Insurancehistory1">{t('Enter the Insurance history or duration of activity in your job (1)')}</label>
                </div>
                <div className="inputAndlabel col-lg-4 col-12">
                  <input
                        id="JobPost1S" 
                        value={values.JobPost1S}
                        type="text"
                        placeholder={t('Job Post (1)')}
                        onChange={handleChange('JobPost1S')}
                        />
                  <label htmlFor="JobPost1S">{t('Enter the applicants Job Post (1)')}</label>
                </div>
                <div className="inputAndlabel col-lg-4 col-12">
                  <input
                        id="JobTitle2" 
                        value={values.JobTitle2}
                        type="text"
                        placeholder={t('Job Title (2)')}
                        onChange={handleChange('JobTitle2')}
                        />
                  <label htmlFor="JobTitle2">{t('Enter the Job Title (2)')}</label>
                </div>
                <div className="inputAndlabel col-lg-4 col-12">
                  <input
                        id="Insurancehistory2" 
                        value={values.Insurancehistory2}
                        type="text"
                        placeholder={t('Insurance history or duration of activity in your job (2)')}
                        onChange={handleChange('Insurancehistory2')}
                        />
                  <label htmlFor="Insurancehistory2">{t('Enter the Insurance history or duration of activity in your job (2)')}</label>
                </div>
                <div className="inputAndlabel col-lg-4 col-12">
                  <input
                        id="JobPost2" 
                        value={values.JobPost2}
                        type="text"
                        placeholder={t('Job Post (2)')}
                        onChange={handleChange('JobPost2')}
                        />
                  <label htmlFor="JobPost">{t('Enter the applicants Job Post (2)')}</label>
                </div>
                <div className="inputAndlabel col-lg-4 col-12">
                  <input
                        id="JobTitle3" 
                        value={values.JobTitle3}
                        type="text"
                        placeholder={t('Job Title (3)')}
                        onChange={handleChange('JobTitle3')}
                        />
                  <label htmlFor="JobTitle3">{t('Enter the Job Title (3)')}</label>
                </div>
                <div className="inputAndlabel col-lg-4 col-12">
                  <input
                        id="Insurancehistory3" 
                        value={values.Insurancehistory3}
                        type="text"
                        placeholder={t('Insurance history or duration of activity in your job (3)')}
                        onChange={handleChange('Insurancehistory3')}
                        />
                  <label htmlFor="Insurancehistory3">{t('Enter the Insurance history or duration of activity in your job (3)')}</label>
                </div>
                <div className="inputAndlabel col-lg-4 col-12">
                  <input
                        id="JobPost3" 
                        value={values.JobPost3}
                        type="text"
                        placeholder={t('Job Post (3)')}
                        onChange={handleChange('JobPost3')}
                        />
                  <label htmlFor="JobPost">{t('Enter the applicants Job Post (3)')}</label>
                </div>
            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Applicant Financial Information')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="inputAndlabel col-lg-4 col-12">
                <select
                      aria-label="Default"
                      // labelId="demo-simple-select-Degree-helper-label"
                      id="TurnoverNumber"
                      value={values.TurnoverNumber}
                      label={t('Type of Marriage')}
                      // MenuProps={MenuProps}
                      onChange={handleChange('TurnoverNumber')}  
                      placeholder={t('Turnover Number')}
                      >
                        <option  value="">
                        {t('Turnover Number')}
                      </option >
                      <option  value='1'>{t('More than 25 thousand dollars')}</option >
                      <option  value='2'>{t('$ 20,000 to $ 25,000')}</option >
                      <option  value='3'>{t('$ 15,000 to $ 20,000')}</option >
                      <option  value='4'>{t('$ 10,000 to $ 15,000')}</option >
                      <option  value='5'>{t('$ 5,000 to $ 10,000')}</option >
                      <option  value='5'>{t('Less than $ 5,000')}</option >
                    </select >
                    <label htmlFor="TurnoverNumber">{t('Enter the applicants Turnover Number')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <select
                      aria-label="Default"
                      // labelId="demo-simple-select-Degree-helper-label"
                      id="Liquidity"
                      value={values.Liquidity}
                      label={t('Type of Marriage')}
                      // MenuProps={MenuProps}
                      onChange={handleChange('Liquidity')}  
                      placeholder={t('Liquidity')}
                      >
                        <option  value="">
                        {t('Liquidity')}
                      </option >
                      <option  value='1'>{t('More than 70 thousand dollars')}</option >
                      <option  value='2'>{'$ 60,000 ' + t('to') + ' $ 70,000'}</option >
                      <option  value='3'>{'$ 50,000 ' + t('to') + ' $ 60,000'}</option >
                      <option  value='4'>{'$ 40,000 ' + t('to') + ' $ 50,000'}</option >
                      <option  value='5'>{'$ 30,000 ' + t('to') + ' $ 40,000'}</option >
                      <option  value='5'>{t('Less than $ 30,000')}</option >
                    </select >
                    <label htmlFor="Liquidity">{t('Enter the applicants Liquidity')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <select
                      aria-label="Default"
                      // labelId="demo-simple-select-Degree-helper-label"
                      id="TotalAssetsAmount"
                      value={values.TotalAssetsAmount}
                      label={t('Type of Marriage')}
                      // MenuProps={MenuProps}
                      onChange={handleChange('TotalAssetsAmount')}  
                      placeholder={t('Total Assets Amount')}
                      >
                        <option  value="">
                        {t('Total Assets Amount')}
                      </option >
                      <option  value='1'>{t('More than 600 thousand dollars')}</option >
                      <option  value='2'>{'$ 300,000 ' + t('to') +' $ 600,000'}</option >
                      <option  value='3'>{'$ 150,000 ' + t('to') +' $ 300,000'}</option >
                      <option  value='4'>{'$ 100,000 ' + t('to') +' $ 150,000'}</option >
                      <option  value='5'>{'$ 70,000 ' + t('to') +' $ 100,000'}</option >
                      <option  value='5'>{'$ 50,000 ' + t('to') +' $ 70,000'}</option >
                      <option  value='5'>{t('Less than $ 50,000')}</option >
                    </select >
                    <label htmlFor="TotalAssetsAmount">{t('Enter the applicants Total Assets Amount')}</label>
              </div>
            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Further Information')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="single_lineinput col-12">
                <div className="inputAndlabel col-lg-6 col-12">
                    <select
                      aria-label="Default"
                      // labelId="demo-simple-select-Degree-helper-label"
                      id="childunder4yearsold"
                      value={values.childunder4yearsold}
                      label={t('Type of Marriage')}
                      // MenuProps={MenuProps}
                      onChange={handleChange('childunder4yearsold')}  
                      placeholder={t('Do you have a child under 4 years old?')}
                      >
                        <option  value="">
                        {t('Do you have a child under 4 years old?')}
                      </option >
                      <option  value='1'>{t('Yes')}</option >
                      <option  value='2'>{t('No')}</option >

                    </select >
                    <label htmlFor="childunder4yearsold">{t('Do you have a child under 4 years old?')}</label>
                </div>
              </div>
              {values.childunder4yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="childunder4yearsoldNum" 
                      value={values.childunder4yearsoldNum}
                      type="text"
                      placeholder={t('Number of Under 4 Years Old Childeren')}
                      onChange={handleChange('childunder4yearsoldNum')}
                      />
                <label htmlFor="childunder4yearsoldNum">{t('Enter the applicants Number of Under 4 Years Old Childeren')}</label>
              </div>}
              {values.childunder4yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="childunder4yearsoldAge" 
                      value={values.childunder4yearsoldAge}
                      type="text"
                      placeholder={t('Age of Under 4 Years Old Child')}
                      onChange={handleChange('childunder4yearsoldAge')}
                      />
                <label htmlFor="childunder4yearsoldAge">{t('Enter the applicants Age of Under 4 Years Old Child')}</label>
              </div>}
              {values.childunder4yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                    <input
                          id="childunder4yearsoldGender" 
                          value={values.childunder4yearsoldGender}
                          type="text"
                          placeholder={t('Gender of Under 4 Years Old Child')}
                          onChange={handleChange('childunder4yearsoldGender')}
                          />
                    <label htmlFor="childunder4yearsoldGender">{t('Enter the applicants Gender of Under 4 Years Old Child')}</label>
              </div> }
              <div className="single_lineinput col-12">
                <div className="inputAndlabel col-lg-6 col-12">
                    <select
                      aria-label="Default"
                      // labelId="demo-simple-select-Degree-helper-label"
                      id="childunder4yearsold"
                      value={values.childbetween4and17yearsold}
                      label={t('Type of Marriage')}
                      // MenuProps={MenuProps}
                      onChange={handleChange('childbetween4and17yearsold')}  
                      placeholder={t('Do you have children between 4 and 17 years old?')}
                      >
                        <option  value="">
                        {t('Do you have children between 4 and 17 years old?')}
                      </option >
                      <option  value='1'>{t('Yes')}</option >
                      <option  value='2'>{t('No')}</option >

                    </select >
                    <label htmlFor="childbetween4and17yearsold">{t('Do you have children between 4 and 17 years old?')}</label>
                </div>
              </div>
              {values.childbetween4and17yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="childbetween4and17yearsoldNum" 
                      value={values.childbetween4and17yearsoldNum}
                      type="text"
                      placeholder={t('Number of children between 4 and 17 Old Childeren')}
                      onChange={handleChange('childbetween4and17yearsoldNum')}
                      />
                <label htmlFor="childbetween4and17yearsoldNum">{t('Enter the applicants Number of children between 4 and 17 Old')}</label>
              </div>}
              {values.childbetween4and17yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="childbetween4and17yearsoldAge" 
                      value={values.childbetween4and17yearsoldAge}
                      type="text"
                      placeholder={t('Age of children between 4 and 17 Old Child')}
                      onChange={handleChange('childbetween4and17yearsoldAge')}
                      />
                <label htmlFor="childbetween4and17yearsoldAge">{t('Enter the applicants Age of children between 4 and 17 Old')}</label>
              </div>}
              {values.childbetween4and17yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                    <input
                          id="childbetween4and17yearsoldGender" 
                          value={values.childbetween4and17yearsoldGender}
                          type="text"
                          placeholder={t('Gender of children between 4 and 17 Old Child')}
                          onChange={handleChange('childbetween4and17yearsoldGender')}
                          />
                    <label htmlFor="childbetween4and17yearsoldGender">{t('Enter the applicants Gender of children between 4 and 17 Old')}</label>
              </div> }
              <div className="single_lineinput col-12">
                <div className="inputAndlabel col-lg-6 col-12">
                    <select
                      aria-label="Default"
                      // labelId="demo-simple-select-Degree-helper-label"
                      id="childunder4yearsold"
                      value={values.childover18yearsold}
                      label={t('Type of Marriage')}
                      // MenuProps={MenuProps}
                      onChange={handleChange('childover18yearsold')}  
                      placeholder={t('Do you have children over 18?')}
                      >
                        <option  value="">
                        {t('Do you have children over 18?')}
                      </option >
                      <option  value='1'>{t('Yes')}</option >
                      <option  value='2'>{t('No')}</option >

                    </select >
                    <label htmlFor="childover18yearsold">{t('Do you have children over 18?')}</label>
                </div>
              </div>
              {values.childover18yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="childover18yearsoldNum" 
                      value={values.childover18yearsoldNum}
                      type="text"
                      placeholder={t('Number of Over 18 Years Old Childeren')}
                      onChange={handleChange('childover18yearsoldNum')}
                      />
                <label htmlFor="childover18yearsoldNum">{t('Enter the applicants Number of Over 18 Years Old Childeren')}</label>
              </div>}
              {values.childover18yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="childover18yearsoldAge" 
                      value={values.childover18yearsoldAge}
                      type="text"
                      placeholder={t('Age of Over 18 Years Old Child')}
                      onChange={handleChange('childover18yearsoldAge')}
                      />
                <label htmlFor="childover18yearsoldAge">{t('Enter the applicants Age of Over 18 Years Old Child')}</label>
              </div>}
              {values.childover18yearsold=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                    <input
                          id="childover18yearsoldGender" 
                          value={values.childover18yearsoldGender}
                          type="text"
                          placeholder={t('Gender of Over 18 Years Old Child')}
                          onChange={handleChange('childover18yearsoldGender')}
                          />
                    <label htmlFor="childover18yearsoldGender">{t('Enter the applicants Gender of Over 18 Years Old Child')}</label>
              </div> }
              <div className="inputAndlabel col-lg-4 col-12">
                  <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="haveAcompanion"
                    value={values.haveAcompanion}
                    label={t('Type of Marriage')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('haveAcompanion')}  
                    placeholder={t('Do you have a companion? (Father - mother - grandfather - grandmother)')}
                    >
                      <option  value="">
                      {t('Do you have a companion? (Father - mother - grandfather - grandmother)')}
                    </option >
                    <option  value='1'>{t('Yes')}</option >
                    <option  value='2'>{t('No')}</option >

                  </select >
                  <label htmlFor="haveAcompanion">{t('Do you have a companion? (Father - mother - grandfather - grandmother)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                  <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="CountryOFimmigration"
                    value={values.CountryOFimmigration}
                    label={t('Type of Marriage')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('CountryOFimmigration')}  
                    placeholder={t('Country of immigration')}
                    >
                      <option  value="">
                      {t('Country of immigration')}
                    </option >
                    <option  value='1'>{t('Canada')}</option >
                    <option  value='2'>{t('Sweden')}</option >
                    <option  value='3'>{t('Germany')}</option >
                    <option  value='4'>{t('United Kingdom')}</option >
                    <option  value='5'>{t('Denmark')}</option >
                    <option  value='6'>{t('Austria')}</option >
                    <option  value='7'>{t('Spain')}</option >
                    <option  value='8'>{t('Australia')}</option >
                    <option  value='9'>{t('UAS')}</option >
                    <option  value='10'>{t('Ukraine')}</option >
                    <option  value='11'>{t('Oman')}</option >
                    <option  value='12'>{t('UAE')}</option >
                    <option  value='13'>{t('Schengen')}</option >

                  </select >
                  <label htmlFor="CountryOFimmigration">{t('Country of immigration')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                  <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="TypeofRequestedVisa"
                    value={values.TypeofRequestedVisa}
                    label={t('Type of Marriage')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('TypeofRequestedVisa')}  
                    placeholder={t('Type of Requested Visa')}
                    >
                      <option  value="">
                      {t('Type of Requested Visa')}
                    </option >
                    <option  value='1'>{t('Tourist')}</option >
                    <option  value='2'>{t('Educational')}</option >
                    <option  value='3'>{t('Student')}</option >
                    <option  value='4'>{t('Work')}</option >
                    <option  value='5'>{t('Business')}</option >
                    <option  value='6'>{t('Company Registration')}</option >
                    <option  value='7'>{t('Buy real estate and companies')}</option >
                    <option  value='8'>{t('Entrepreneurship')}</option >
                    <option  value='9'>{t('Startup')}</option >
                    <option  value='10'>{t('Express Entry')}</option >
                    <option  value='11'>{t('Provincial')}</option >
                    <option  value='12'>{t('Companion - Parents')}</option >
                    <option  value='13'>{t('Rejection - Court')}</option >
                    <option  value='14'>{t('Other')}</option >

                  </select >
                  <label htmlFor="TypeofRequestedVisa">{t('Type of Requested Visa')}</label>
              </div>
              <div className="inputAndlabel col-12">
                    <textarea
                          id="Thepurposeofmigration" 
                          value={values.Thepurposeofmigration}
                          type="text"
                          placeholder={t('The purpose of migration')}
                          onChange={handleChange('Thepurposeofmigration')}
                          maxLength="500"
                          />
                    <label htmlFor="Thepurposeofmigration">{t('Enter the applicants purpose of migration')}<p className="ReqiredCus">{t('- in 500 Character')}</p></label>
              </div>
              <div className="single_lineinput col-12">
                <div className="inputAndlabel col-lg-10 col-12">
                    <select
                      aria-label="Default"
                      // labelId="demo-simple-select-Degree-helper-label"
                      id="TraveledSchengenareaCanada"
                      value={values.TraveledSchengenareaCanada}
                      label={t('Type of Marriage')}
                      // MenuProps={MenuProps}
                      onChange={handleChange('TraveledSchengenareaCanada')}  
                      placeholder={t('Traveled to a Schengen area, Canada,...')}
                      >
                        <option  value="">
                        {t('Have you or your spouse traveled to a Schengen area, Canada, the United Kingdom, the United States or Australia?')}
                      </option >
                      <option  value='1'>{t('Yes')}</option >
                      <option  value='2'>{t('No')}</option >

                    </select >
                </div>
              </div>
              <div className="inputAndlabel col-12">
                    <textarea
                          id="NamesofCountriesYouHaveVisited" 
                          value={values.NamesofCountriesYouHaveVisited}
                          type="text"
                          placeholder={t('Names of countries you have visited')}
                          onChange={handleChange('NamesofCountriesYouHaveVisited')}
                          maxLength="500"
                          />
                    <label htmlFor="NamesofCountriesYouHaveVisited">{t('Enter the Names of countries you have visited')}<p className="ReqiredCus">{t('- in 500 Character')}</p></label>
              </div>
              <div className="single_lineinput col-12">
                <div className="inputAndlabel col-lg-7 col-12">
                    <select
                      aria-label="Default"
                      // labelId="demo-simple-select-Degree-helper-label"
                      id="HistoryRejectionCountry"
                      value={values.HistoryRejectionCountry}
                      label={t('Type of Marriage')}
                      // MenuProps={MenuProps}
                      onChange={handleChange('HistoryRejectionCountry')}  
                      placeholder={t('Has the applicant ever had a history of rejection from a country?')}
                      >
                        <option  value="">
                        {t('Has the applicant ever had a history of rejection from a country?')}
                      </option >
                      <option  value='1'>{t('Yes')}</option >
                      <option  value='2'>{t('No')}</option >

                    </select >
                </div>
              </div>
              {values.HistoryRejectionCountry=='1' &&<div className="inputAndlabel col-12">
                    <textarea
                          id="NamesCountriesRejected" 
                          value={values.NamesCountriesRejected}
                          type="text"
                          placeholder={t('Names of countries you have been rejected so far')}
                          onChange={handleChange('NamesCountriesRejected')}
                          maxLength="500"
                          />
                    <label htmlFor="NamesCountriesRejected">{t('Enter the Names of countries you have been rejected so far')}<p className="ReqiredCus">{t('- in 500 Character')}</p></label>
              </div>}
              {values.HistoryRejectionCountry=='1' &&<div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="proEmail2" 
                  
                  type="text"
                  placeholder={t('E-mail')}
                  
                  hidden
                  />
                <label htmlFor="proEmail2" hidden>{t('Enter the applicants Email address')}</label>
              </div>}
              <div className="single_lineinput col-12">
              <div className="inputAndlabel col-lg-7 col-12">
                  <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="DoYouFirstSecondDegreeRelatives"
                    value={values.DoYouFirstSecondDegreeRelatives}
                    label={t('Type of Marriage')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('DoYouFirstSecondDegreeRelatives')}  
                    placeholder={t('Do you have first and second degree relatives in your chosen country?')}
                    >
                      <option  value="">
                      {t('Do you have first and second degree relatives in your chosen country?')}
                    </option >
                    <option  value='1'>{t('Yes')}</option >
                    <option  value='2'>{t('No')}</option >

                  </select >
              </div>
              </div>
              {values.DoYouFirstSecondDegreeRelatives=='1'&&<div className="inputAndlabel col-8">
                    <textarea
                          id="Explain" 
                          value={values.Explain}
                          type="text"
                          placeholder={t('Explain...')}
                          onChange={handleChange('Explain')}
                          maxLength="500"
                          />
                    <label htmlFor="Explain">{t('Enter the applicants Explain')}<p className="ReqiredCus">{t('- in 500 Character')}</p></label>
              </div>}
              {values.DoYouFirstSecondDegreeRelatives=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="proEmail2" 
                  
                  type="text"
                  placeholder={t('E-mail')}
                  
                  hidden
                  />
                <label htmlFor="proEmail2" hidden>{t('Enter the applicants Email address')}</label>
              </div>}
              <div className="single_lineinput col-12">
              <div className="inputAndlabel col-lg-7 col-12">
                  <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="DoesHaveFinancialSupporter"
                    value={values.DoesHaveFinancialSupporter}
                    label={t('Type of Marriage')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('DoesHaveFinancialSupporter')}  
                    placeholder={t('Does the applicant have a financial supporter?')}
                    >
                      <option  value="">
                      {t('Does the applicant have a financial supporter?')}
                    </option >
                    <option  value='1'>{t('Yes')}</option >
                    <option  value='2'>{t('No')}</option >

                  </select >
              </div>
              </div>
              {values.DoesHaveFinancialSupporter=='1'&&<div className="inputAndlabel col-8">
                    <textarea
                          id="ExplainFinancialSupporter" 
                          value={values.ExplainFinancialSupporter}
                          type="text"
                          placeholder={t('Explain if you have a financial supporter')}
                          onChange={handleChange('ExplainFinancialSupporter')}
                          maxLength="500"
                          />
                    <label htmlFor="ExplainFinancialSupporter">{t('Enter Explain if you have a financial supporter')}<p className="ReqiredCus">{t('- in 500 Character')}</p></label>
              </div>}
              {values.DoesHaveFinancialSupporter=='1'&&<div className="inputAndlabel col-lg-4 col-12">
                <input
                  id="proEmail2" 
                  
                  type="text"
                  placeholder={t('E-mail')}
                  
                  hidden
                  />
                <label htmlFor="proEmail2" hidden>{t('Enter the applicants Email address')}</label>
              </div>}
            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Applicant Spouse Information')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="inputAndlabel col-lg-6 col-12">
                <input
                  id="SpouseFirstName" 
                  value={values.SpouseFirstName}
                  type="text"
                  placeholder={t('Spouse Name')}
                  onChange={handleChange('SpouseFirstName')}
                  />
                  <label htmlFor="SpouseFirstName">{t('Enter Your Spouse Name')}</label>
              </div>
              <div className="inputAndlabel col-lg-6 col-12">
                <input
                  id="SpouseLastName" 
                  value={values.SpouseLastName}
                  type="text"
                  placeholder={t('Spouse Last Name')}
                  onChange={handleChange('SpouseLastName')}
                  />
                  <label htmlFor="SpouseLastName">{t('Enter Your Spouse Last Name')}</label>
              </div>
            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Applicants Spouse Educational Information')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="inputAndlabel col-lg-6 col-12">
                  <input
                    id="FieldStudyS" 
                    value={values.FieldStudyS}
                    type="text"
                    placeholder={t('Field of Study')}
                    onChange={handleChange('FieldStudyS')}
                    
                    />
                    <label htmlFor="FieldStudyS">{t('Enter the applicants Field of Study')}</label>
              </div>
              <div className="inputAndlabel col-lg-6 col-12">
              <select
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="LastEducationalCertificateS"
                    value={values.LastEducationalCertificateS}
                    label={t('Type of Marriage')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('LastEducationalCertificateS')}  
                    placeholder={t('Last Educational Certificate')}
                    >
                      <option  value="">
                      {t('Last Educational Certificate')}
                    </option >
                    <option  value='1'>{t('Diploma')}</option >
                    <option  value='2'>{t('Pre-university')}</option >
                    <option  value='3'>{t('Associate Degree')}</option >
                    <option  value='4'>{t('Bachelor Degree')}</option >
                    <option  value='5'>{t('Masters Degree')}</option >
                    <option  value='7'>{t('P.H.D Degree')}</option >
                    <option  value='8'>{t('Post-Doctoral Degree')}</option >

                  </select >
                  <label htmlFor="LastEducationalCertificateS">{t('Enter the applicants Last Educational Certificate')}</label>
              </div>
              <div className="languagesspeaks col-12">     
                  <p className="inputAndlabelP">{t('What languages can you speak?')}</p>  
                  <div className="MyCheckBox1Div col-12">
                    <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="EnglishCheckBoxS" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="EnglishCheckBoxS" 
                        value={EnglishCheckBoxS}
                        checked={EnglishCheckBoxS} 
                        onChange={() => setEnglishCheckBoxS(!EnglishCheckBoxS)}/>
                    <p htmlFor='EnglishCheckBoxS'>{t('English')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="EnglishFamiliarityS"
                            value={values.EnglishFamiliarityS}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('EnglishFamiliarityS')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="EnglishFamiliarityS">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="EnglishScoreS" 
                      value={values.EnglishScoreS}
                      type="text"
                      placeholder={t('English Score')}
                      onChange={handleChange('EnglishScoreS')}
                      />
                      <label htmlFor="EnglishScoreS">{t('Enter the applicants English Score')}</label>
                  </div>
                  </div> <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="FrenchCheckBoxS" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="FrenchCheckBoxS" 
                        value={FrenchCheckBoxS}
                        checked={FrenchCheckBoxS} 
                        onChange={() => setFrenchCheckBoxS(!FrenchCheckBoxS)}/>
                    <p htmlFor='FrenchCheckBoxS'>{t('French')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="FrenchFamiliarityS"
                            value={values.FrenchFamiliarityS}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('FrenchFamiliarityS')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="FrenchFamiliarityS">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="FrenchScoreS" 
                      value={values.FrenchScoreS}
                      type="text"
                      placeholder={t('French Score')}
                      onChange={handleChange('FrenchScoreS')}
                      />
                      <label htmlFor="FrenchScoreS">{t('Enter the applicants French Score')}</label>
                  </div>
                  </div>  <br />   
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="GermanCheckBoxS" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="GermanCheckBoxS" 
                        value={GermanCheckBoxS}
                        checked={GermanCheckBoxS} 
                        onChange={() => setGermanCheckBoxS(!GermanCheckBoxS)}/>
                    <p htmlFor='GermanCheckBoxS'>{t('German')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="GermanFamiliarityS"
                            value={values.GermanFamiliarityS}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('GermanFamiliarityS')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="GermanFamiliarityS">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="GermanScoreS" 
                      value={values.GermanScoreS}
                      type="text"
                      placeholder={t('German Score')}
                      onChange={handleChange('GermanScoreS')}
                      />
                      <label htmlFor="GermanScoreS">{t('Enter the applicants German Score')}</label>
                  </div>
                  </div>  <br /> 
                  <div className="MyCheckBox1Div col-12">
                    <div className="checkspeak col-lg-2 col-12">
                      <input 
                          id="DanishCheckBoxS" 
                          className="MyCheckBox1" 
                          type="checkbox" 
                          name="DanishCheckBoxS" 
                          value={DanishCheckBoxS}
                          checked={DanishCheckBoxS} 
                          onChange={() => setDanishCheckBoxS(!DanishCheckBoxS)}/>
                      <p htmlFor='DanishCheckBoxS'>{t('Danish')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="DanishFamiliarityS"
                            value={values.DanishFamiliarityS}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('DanishFamiliarityS')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="DanishFamiliarityS">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="DanishScoreS" 
                      value={values.DanishScoreS}
                      type="text"
                      placeholder={t('Danish Score')}
                      onChange={handleChange('DanishScoreS')}
                      />
                      <label htmlFor="DanishScoreS">{t('Enter the applicants Danish Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="ArabicCheckBoxS" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="ArabicCheckBoxS" 
                        value={ArabicCheckBoxS}
                        checked={ArabicCheckBoxS} 
                        onChange={() => setArabicCheckBoxS(!ArabicCheckBoxS)}/>
                    <p htmlFor='ArabicCheckBoxS'>{t('Arabic')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="ArabicFamiliarityS"
                            value={values.ArabicFamiliarityS}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('ArabicFamiliarityS')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="ArabicFamiliarityS">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="ArabicScoreS" 
                      value={values.ArabicScoreS}
                      type="text"
                      placeholder={t('Arabic Score')}
                      onChange={handleChange('ArabicScoreS')}
                      />
                      <label htmlFor="ArabicScoreS">{t('Enter the applicants Arabic Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="SpanishCheckBoxS" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="SpanishCheckBoxS" 
                        value={SpanishCheckBoxS}
                        checked={SpanishCheckBoxS} 
                        onChange={() => setSpanishCheckBoxS(!SpanishCheckBoxS)}/>
                    <p htmlFor='SpanishCheckBoxS'>{t('Spanish')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="SpanishFamiliarityS"
                            value={values.SpanishFamiliarityS}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('SpanishFamiliarityS')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="SpanishFamiliarityS">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="SpanishScoreS" 
                      value={values.SpanishScoreS}
                      type="text"
                      placeholder={t('Spanish Score')}
                      onChange={handleChange('SpanishScoreS')}
                      />
                      <label htmlFor="SpanishScoreS">{t('Enter the applicants Spanish Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="ChineseCheckBoxS" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="ChineseCheckBoxS" 
                        value={ChineseCheckBoxS}
                        checked={ChineseCheckBoxS} 
                        onChange={() => setChineseCheckBoxS(!ChineseCheckBoxS)}/>
                    <p htmlFor='ChineseCheckBoxS'>{t('Chinese')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="ChineseFamiliarityS"
                            value={values.ChineseFamiliarityS}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('ChineseFamiliarityS')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="ChineseFamiliarityS">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="ChineseScoreS" 
                      value={values.ChineseScoreS}
                      type="text"
                      placeholder={t('Chinese Score')}
                      onChange={handleChange('ChineseScoreS')}
                      />
                      <label htmlFor="ChineseScoreS">{t('Enter the applicants Chinese Score')}</label>
                  </div>
                  </div>  <br />
                  <div className="MyCheckBox1Div col-12">
                  <div className="checkspeak col-lg-2 col-12">
                    <input 
                        id="KoreanCheckBoxS" 
                        className="MyCheckBox1" 
                        type="checkbox" 
                        name="KoreanCheckBoxS" 
                        value={KoreanCheckBoxS}
                        checked={KoreanCheckBoxS} 
                        onChange={() => setKoreanCheckBoxS(!KoreanCheckBoxS)}/>
                    <p htmlFor='KoreanCheckBoxS'>{t('Korean')}</p>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                      <select
                            aria-label="Default"
                            // labelId="demo-simple-select-Degree-helper-label"
                            id="KoreanFamiliarityS"
                            value={values.KoreanFamiliarityS}
                            label={t('Type of Marriage')}
                            // MenuProps={MenuProps}
                            onChange={handleChange('KoreanFamiliarityS')}  
                            placeholder={t('Familiarity with the chosen language')}
                            >
                              <option  value="">
                              {t('Familiarity with the chosen language')}
                            </option >
                            <option  value='1'>{t('Excellent')}</option >
                            <option  value='2'>{t('Good')}</option >
                            <option  value='3'>{t('Medium')}</option >
                            <option  value='4'>{t('Weak')}</option >
                            <option  value='5'>{t('Very weak')}</option >
                          </select >
                          <label htmlFor="KoreanFamiliarityS">{t('Enter the applicants Familiarity with the chosen language')}</label>
                    </div>
                    <div className="inputAndlabel col-lg-5 col-12">
                    <input
                      id="KoreanScoreS" 
                      value={values.KoreanScoreS}
                      type="text"
                      placeholder={t('Korean Score')}
                      onChange={handleChange('KoreanScoreS')}
                      />
                      <label htmlFor="KoreanScoreS">{t('Enter the applicants Korean Score')}</label>
                  </div>
                  </div>  <br />   
              </div>
            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Applicant Spouse Job Information')}-{t('(In past 10 years)')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="JobTitle1S" 
                      value={values.JobTitle1S}
                      type="text"
                      placeholder={t('Job Title (1)')}
                      onChange={handleChange('JobTitle1S')}
                      />
                <label htmlFor="JobTitle1S">{t('Enter the Job Title (1)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="Insurancehistory1S" 
                      value={values.Insurancehistory1S}
                      type="text"
                      placeholder={t('Insurance history or duration of activity in your job (1)')}
                      onChange={handleChange('Insurancehistory1S')}
                      />
                <label htmlFor="Insurancehistory1S">{t('Enter the Insurance history or duration of activity in your job (1)')}</label>
              </div>
              <div className="inputAndlabel col-lg-4 col-12">
                <input
                      id="JobPost1" 
                      value={values.JobPost1}
                      type="text"
                      placeholder={t('Job Post (1)')}
                      onChange={handleChange('JobPost1')}
                      />
                <label htmlFor="JobPost1">{t('Enter the applicants Job Post (1)')}</label>
              </div>
            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Applicant Accompany Information')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="inputAndlabel col-lg-4 col-12">
                    <input
                          id="AccompanyPost" 
                          value={values.AccompanyPost}
                          type="text"
                          placeholder={t('Accompany Post')}
                          onChange={handleChange('AccompanyPost')}
                          />
                    <label htmlFor="AccompanyPost">{t('Enter the applicants Accompany Post')}</label>
              </div>  
            </div>
          </details>
          <details className="part_of_form">
            <summary>
              <ArrowRight2 size="24" color="#313131" variant="Bold"/>
              <div className="Cus2PersonalInfo"> 
                <p>{t('Applicant Descriptions')}</p> 
              </div>
            </summary>
            <div className="Cus2Inputs">
              <div className="inputAndlabel col-12">
                    <textarea
                          id="Descriptions" 
                          value={values.Descriptions}
                          type="text"
                          placeholder={t('Descriptions')}
                          onChange={handleChange('Descriptions')}
                          maxLength='500'
                          />
                    <label htmlFor="Descriptions">{t('Enter Your Descriptions')}<p className="ReqiredCus">{t('- in 500 Character')}</p></label>
              </div>  
            </div>
          </details>

          {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
          <div className="botCusDraftCol cvUploadBasicInfo myUploadFileCusCenter col-12">
          <p className="col-12">{t('If you have any of the following, you can upload the file in this section. To upload more than one file, first merge them into a pdf file and then upload')}</p>
              <div className="imgup_brownBox2">
                {/* {<Edit2 htmlFor="imgupload" id="personPicPen" size="16" color="#313131"/>}  */}
                {<a htmlFor="imgupload" id="personPicPen" className="btn">{t('UpLoad your Cv/Sop/evaluation-form')}</a>}
                  <input
                    id="imgupload"
                    type="file"
                    accept=".pdf"
                    size={4000000}
                    onChange={(event) => {
                      uploadImage(event.target.files);
                    }}
                  />
              </div>
              <div className="input-placeholder-jobUpload col-lg-4 col-12">
                <label id="files">{localStorage.getItem('attachmentFile')!=''&&
                <p><a target="_blank" href={'https://telesiness.ir/CustomerEvaluationFormDocuments/'+ localStorage.getItem('attachmentFile')} className="UploadedFileBasicForm">{localStorage.getItem('attachmentFile')}</a></p>
                }</label>
                {/* <label id="jobUploadLabel"> {values.JobUpload}</label> */}

                <TickSquare  size="16" color="#2727ff"/>
                {values.files2 == 1 && <label id="files2">{t('File Must be Lower than 4MB')}</label>}
              </div>
            </div>
            <div className="col-4">
               <button  onClick={(dataCus1) => {childToParentCus1(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-4">
              {!localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Submit')}</button>}
              {localStorage.getItem('EvFormUpdate') &&<button className="btn btn-dark">{t('Update')}</button>}
              <button id="ScrollUp" onClick={handleScrollUp} className="ScrollUp d-lg-none"><ArrowUp2 size="20" color="#313131"/></button>
            </div>
          </div>}
       
        </div>
        </form>
        </fieldset>
        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e1(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
               <button id="ScrollUp" onClick={handleScrollUp} className="d-lg-none"><ArrowUp2 size="20" color="#313131"/></button>
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;