import logoDash from './images/tele.png';
import { Home2,People,Profile2User,
    PercentageSquare,MessageText1,Graph,Notepad2,
    Briefcase,ArrowLeft,ProfileAdd,Command,Profile,ProfileTick,CommandSquare,Star1,TickSquare,
     } from 'iconsax-react';
import { useState , useEffect } from 'react';
import Popup from './Popup';
import Popup2 from './Popup2';
import Popup3 from './Popup3';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // multi language package
import ReactSession from 'react-client-session';
import CreatableSelect from 'react-select';

const techCompanies = [
  
  { label: "English", value: 1 },
  { label: "Persian", value: 2 },
  { label: "Mandarin", value: 3 },
  { label: "Hindi", value: 4 },
  { label: "Spanish", value: 5 },
  { label: "French", value: 6 },
  { label: "Arabic", value: 7 },
  { label: "Bengali", value: 8 },
  { label: "Russian", value: 9 },
  { label: "Portuguese", value: 10 },
  { label: "Indonesian", value: 11 },
  { label: "Japanese", value: 12 },
  { label: "Turkish", value: 13 },
  { label: "Korean", value: 14 },
  { label: "Dutch", value: 15 },
  { label: "Italian", value: 16 },
  { label: "Romanian", value: 17 },
  { label: "Thai", value: 18 },
  { label: "Nepali", value: 19 },
  { label: "Gujarati", value: 20 },
];
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '2px dotted green',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#31313131' : 'white'
  }),
  control: (provided) => ({
    ...provided,
    marginTop: "5%",
    backgroundColor: 'transparent',
  }),
}
const LeftNavBar = ({userActive ,
                    childToParentCus,
                    childToParentCusA2 , 
                    childToParentCusADis ,
                    childToParentCusA2A ,
                    childToParentCus1 ,
                    childToParentAgents ,
                    childToParentPrices , 
                    childToParentLawyer,
                    childToParentAgentAgent,
                    childToParentAgentDis,
                    childToParentLawyerHome,
                    childToParentAgentHome,
                    childToParentCustomerHome,
                    childToParentLawyers,
                    childToParentMessage,
                    childToParentMarketerHome,
                    childToParentCusMark,
                    childToParentRefCustomers,
                    childToParentMarketerDist,
                    childToParentAgentPrice,
                    childToParentMarketerPrice,
                    childToParentDCCustomers,}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countProfile2User2,setCountProfile2User2] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countBriefcase,setCountBriefcase] = useState(false);
    const [countCommand,setCountCommand] = useState(false);
    const [countCommand2,setCountCommand2] = useState(false);
    const [countProfileTick,setCountProfileTick] = useState(false);
    const [countBriefcaseLaw,setCountBriefcaseLaw] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    const [countProfileAdd,setCountProfileAdd] = useState(false);
    const [countProfile,setCountProfile] = useState(false);
    
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState(userActive);
    const [values, setValues] = useState({
        Email: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        SelectLanguage:localStorage.getItem('langV'),
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
       
      };
    //*********************************** */
    const handleClickHome = (e) => {
        if((!count && localStorage.getItem('userType')==1) || 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')==1)){
            setCount(true);
            setCountPeople(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);

            document.getElementById('homeBtn').style.backgroundColor='white';
            document.getElementById('homeBtnP').style.color='black';

            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnPLaw').style.color='white';

            
        }else if(!count && localStorage.getItem('userType')==2){
            setCount(true);
            setCountPeople(false);
            setCountProfile2User(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfile(false);
            setCountProfileTick(false);

            document.getElementById('homeBtn').style.backgroundColor='white';
            document.getElementById('homeBtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
        }
        else if(!count && localStorage.getItem('userType')==3){
            setCount(true);
            setCountPeople(false);
            setCountProfile2User(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            
            document.getElementById('homeBtn').style.backgroundColor='white';
            document.getElementById('homeBtnP').style.color='black';
            document.getElementById('NotepadBtn').style.backgroundColor='transparent';
            document.getElementById('NotepadBtnP').style.color='white';
            // document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            // document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            // document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            
        }
        else if(!count && localStorage.getItem('userType')==4){
            setCount(true);
            setCountPeople(false);
            setCountProfile2User(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            document.getElementById('homeBtn').style.backgroundColor='white';
            document.getElementById('homeBtnP').style.color='black';

            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            // document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
        }
        else{
            // setCount(false)
            // document.getElementById('homeBtn').style.backgroundColor='transparent';
            // document.getElementById('homeBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }
    
    const handleClickNotepad = (e) => {
        if(!countNotepad){
            setCountNotePad(true);
            setCountPeople(false);
            setCount(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountProfile(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);

            document.getElementById('NotepadBtn').style.backgroundColor='white';
            document.getElementById('NotepadBtnP').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            // document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            // document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            // document.getElementById('ProfileBtnP').style.color='white';
            
        }
        else{
            // setCountPeople(false)
            // let element = document.getElementById('NotepadBtn').style.backgroundColor='transparent';
            // document.getElementById('NotepadBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }

    const handleClickPeople = (e) => {
        if((!countPeople && localStorage.getItem('userType')==1) || 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')==1)){
            setCountPeople(true)
            setCount(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);

            document.getElementById('PeopleBtn').style.backgroundColor='white';
            document.getElementById('PeopleBtnP').style.color='black';

            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnPLaw').style.color='white';
            localStorage.removeItem('turnOffMeLawCusMsg')
            
            localStorage.removeItem('MsgUserNewName');
            localStorage.removeItem('emailCus');
            localStorage.removeItem('CusCode');
            localStorage.removeItem('MsgUserNewType');
        }
        else if(!countPeople && localStorage.getItem('userType')==2|| 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')==2)){
            setCountPeople(true)
            setCount(false);
            setCountProfile2User(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfileTick(false);
            setCountProfile(false);

            document.getElementById('PeopleBtn').style.backgroundColor='white';
            document.getElementById('PeopleBtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';

            localStorage.removeItem('MsgUserNewName');
            localStorage.removeItem('emailCus');
            localStorage.removeItem('CusCode');
            localStorage.removeItem('MsgUserNewType');
        }
        else if(!countPeople && localStorage.getItem('userType')==4 || 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')==4)){
            setCountPeople(true)
            setCount(false);
            setCountProfile2User(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);

            document.getElementById('PeopleBtn').style.backgroundColor='white';
            document.getElementById('PeopleBtnP').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            // document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            // document.getElementById('BriefcaseBtnP').style.color='white';
            
        }
        else{
            // setCountPeople(false)
            // let element = document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            // document.getElementById('PeopleBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }

    const handleClickProfileAdd = (e) => {
        if(!countProfileAdd  && localStorage.getItem('userType')==1|| 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')==1)){
            setCountProfileAdd(true);
            setCountPeople(false);
            setCount(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);

            document.getElementById('ProfileAddBtn').style.backgroundColor='white';
            document.getElementById('ProfileAddBtnP').style.color='black';

            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnPLaw').style.color='white';

            
        }
     
        else{
            // setCountPeople(false)
            // let element = document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            // document.getElementById('PeopleBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }
    
    const handleClickProfile = (e) => {
       if(!countProfile && localStorage.getItem('userType')==2){
            setCountProfile(true);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfileTick(false);

            document.getElementById('ProfileBtn').style.backgroundColor='white';
            document.getElementById('ProfileBtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnP').style.color='white';
            
        }
        else{
            // setCountProfile2User(false)
            // let element = document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }

    const handleClickProfile2User = (e) => {
        if((!countProfile2User && localStorage.getItem('userType')==1)|| 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')==1)){
            setCountProfile2User(true);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);

            document.getElementById('Profile2UserBtn').style.backgroundColor='white';
            document.getElementById('Profile2UserBtnP').style.color='black';

            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';
            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnPLaw').style.color='white';
            
            localStorage.removeItem('turnOffMeLawCusMsg');
            localStorage.removeItem('MsgUserNewName');
            localStorage.removeItem('emailCus');
            localStorage.removeItem('CusCode');
            localStorage.removeItem('MsgUserNewType');
        }
        else  if(!countProfile2User && localStorage.getItem('userType')==2){
            setCountProfile2User(true);
            setCountPeople(false)
            setCount(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfile(false);
            setCountProfileTick(false);

            document.getElementById('Profile2UserBtn').style.backgroundColor='white';
            document.getElementById('Profile2UserBtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';
        }
        else{
            // setCountProfile2User(false)
            // let element = document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }

    const handleClickProfile2User2 = (e) => {
        if((!countProfile2User2 && localStorage.getItem('userType')==1)|| 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')==1)){
            setCountProfile2User2(true);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountPercentageSquare(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);

            document.getElementById('Profile2UserBtn2').style.backgroundColor='white';
            document.getElementById('Profile2UserBtnP2').style.color='black';

            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnPLaw').style.color='white';
            
            localStorage.removeItem('turnOffMeLawCusMsg');
            localStorage.removeItem('MsgUserNewName');
            localStorage.removeItem('emailCus');
            localStorage.removeItem('CusCode');
            localStorage.removeItem('MsgUserNewType');
        }
       
        else{
            // setCountProfile2User(false)
            // let element = document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }

    const handleClickPercentageSquare = (e) => {
        if(!countPercentageSquare && localStorage.getItem('userType')==1){
            setCountPercentageSquare(true);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);
            setCountProfile(false);

            document.getElementById('PercentageSquareBtn').style.backgroundColor='white';
            document.getElementById('PercentageSquareBtnP').style.color='black';

            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';

            // document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            // document.getElementById('ProfileBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnPLaw').style.color='white';
            
        }
        else if(!countPercentageSquare && localStorage.getItem('userType')==2) {
            setCountPercentageSquare(true);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);
            setCountProfile(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfileTick(false);

            document.getElementById('PercentageSquareBtn').style.backgroundColor='white';
            document.getElementById('PercentageSquareBtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            // document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            // document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnP').style.color='white';
            // setCountPercentageSquare(false)
            // let element = document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
        }
        else if(!countPercentageSquare && localStorage.getItem('userType')==4) {
            setCountPercentageSquare(true);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountMessageText1(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);
            setCountProfile(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfileTick(false);

            document.getElementById('PercentageSquareBtn').style.backgroundColor='white';
            document.getElementById('PercentageSquareBtnP').style.color='black';


            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';

        }
        localStorage.removeItem('msgLightBox');
    }
    const handleClickMessageText1 = (e) => {
        if(!countMessageText1 && localStorage.getItem('userType')=='1'){
            setCountMessageText1(true)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountGraph(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);

            document.getElementById('MessageText1Btn').style.backgroundColor='white';
            document.getElementById('MessageText1BtnP').style.color='black';

            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnPLaw').style.color='white';
            
        }
        else if(!countMessageText1 && localStorage.getItem('userType')=='2'){
            setCountMessageText1(true)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountGraph(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfile(false);
            setCountProfileTick(false);

            document.getElementById('MessageText1Btn').style.backgroundColor='white';
            document.getElementById('MessageText1BtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';
        }
        else if(!countMessageText1 && localStorage.getItem('userType')=='3'){
            setCountMessageText1(true)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountGraph(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            document.getElementById('MessageText1Btn').style.backgroundColor='white';
            document.getElementById('MessageText1BtnP').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('NotepadBtn').style.backgroundColor='transparent';
            document.getElementById('NotepadBtnP').style.color='white';
            // document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            // document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            // document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
        }
        else if(!countMessageText1 && localStorage.getItem('userType')=='4'){
            setCountMessageText1(true)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountGraph(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            document.getElementById('MessageText1Btn').style.backgroundColor='white';
            document.getElementById('MessageText1BtnP').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            // document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            // document.getElementById('BriefcaseBtnP').style.color='white';
        }
        else{
            // setCountMessageText1(false)
            // let element = document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            // document.getElementById('MessageText1BtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }
    const handleClickGraph = (e) => {
        if(!countGraph && localStorage.getItem('userType')=='1'){
            setCountGraph(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            setCountBriefcaseLaw(false);
            setCountProfileAdd(false);

            document.getElementById('GraphBtn').style.backgroundColor='white';
            document.getElementById('GraphBtnP').style.color='black';

            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            
            
        }
        else if(!countGraph && localStorage.getItem('userType')=='2'){
            setCountGraph(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfile(false);
            setCountProfileTick(false);

            document.getElementById('GraphBtn').style.backgroundColor='white';
            document.getElementById('GraphBtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';
        }
        else if(!countGraph && localStorage.getItem('userType')=='3'){
            setCountGraph(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            document.getElementById('GraphBtn').style.backgroundColor='white';
            document.getElementById('GraphBtnP').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('NotepadBtn').style.backgroundColor='transparent';
            document.getElementById('NotepadBtnP').style.color='white';
            // document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            // document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            // document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
        }
        else if(!countGraph && localStorage.getItem('userType')=='4'){
            setCountGraph(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountBriefcase(false);
            document.getElementById('GraphBtn').style.backgroundColor='white';
            document.getElementById('GraphBtnP').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            // document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            // document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            // document.getElementById('BriefcaseBtnP').style.color='white';
        }
        else{
            // setCountGraph(false)
            // let element = document.getElementById('GraphBtn').style.backgroundColor='transparent';
            // document.getElementById('GraphBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }
    // const handleClickGlobal = (e) => {
    //     if(!countGlobal){
    //         setCountGlobal(true)
    //         document.getElementById('GlobalBtn').style.backgroundColor='white';
    //        // document.getElementById('GlobalBtnP').style.color='black';
            
    //     }
    //     else{
    //         setCountGlobal(false)
    //         let element = document.getElementById('GlobalBtn').style.backgroundColor='transparent';
    //       //  document.getElementById('GlobalBtnP').style.color='white';
    //     }
   
    // }
    
    const handleClickBriefcase = (e) => {
        if(!countBriefcase && localStorage.getItem('userType')=='1'){
            setCountBriefcase(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountGraph(false);
            setCountProfileAdd(false);

            document.getElementById('BriefcaseBtn').style.backgroundColor='white';
            document.getElementById('BriefcaseBtnP').style.color='black';

            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            
        }
        else if(!countBriefcase && localStorage.getItem('userType')=='2' || 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')=='2')){
            setCountBriefcase(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountProfile(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountGraph(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfileTick(false);

            document.getElementById('BriefcaseBtn').style.backgroundColor='white';
            document.getElementById('BriefcaseBtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';

            localStorage.removeItem('turnOffMeLawCusMsg');
            localStorage.removeItem('MsgUserNewName');
            localStorage.removeItem('emailCus');
            localStorage.removeItem('CusCode');
            localStorage.removeItem('MsgUserNewType');
        }
        else if(!countBriefcase && localStorage.getItem('userType')=='3'){
            setCountBriefcase(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountGraph(false);
            document.getElementById('BriefcaseBtn').style.backgroundColor='white';
            document.getElementById('BriefcaseBtnP').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('NotepadBtn').style.backgroundColor='transparent';
            document.getElementById('NotepadBtnP').style.color='white';
            // document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            // document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            // document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
        }
        else{
            // setCountBriefcase(false)
            // let element = document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            // document.getElementById('BriefcaseBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }
    const handleClickCommand = (e) => {
      if(!countCommand && localStorage.getItem('userType')=='2'){
            setCountCommand(true);
            setCountCommand2(false);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountGraph(false);
            setCountBriefcase(false);
            setCountProfile(false);
            setCountProfileTick(false);

            document.getElementById('CommandBtn').style.backgroundColor='white';
            document.getElementById('CommandBtnP').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
            document.getElementById('BriefcaseBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';
        }
       
        else{
            // setCountCommand(false)
            // let element = document.getElementById('CommandBtn').style.backgroundColor='transparent';
            // document.getElementById('CommandBtnP').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }

    const handleClickCommand2 = (e) => {
        if(!countCommand2 && localStorage.getItem('userType')=='2'|| 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')=='2')){
              setCountCommand2(true);
              setCountCommand(false);
              setCountMessageText1(false)
              setCountPercentageSquare(false);
              setCountProfile2User(false);
              setCountProfile2User2(false);
              setCountPeople(false)
              setCount(false);
              setCountNotePad(false);
              setCountGraph(false);
              setCountBriefcase(false);
              setCountProfile(false);
              setCountProfileTick(false);
  
              document.getElementById('Command2Btn').style.backgroundColor='white';
              document.getElementById('Command2BtnP').style.color='black';

              document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
              document.getElementById('ProfileTickBtnP').style.color='white';
              document.getElementById('CommandBtn').style.backgroundColor='transparent';
              document.getElementById('CommandBtnP').style.color='white';
              document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
              document.getElementById('BriefcaseBtnP').style.color='white';
              document.getElementById('homeBtn').style.backgroundColor='transparent';
              document.getElementById('homeBtnP').style.color='white';
              document.getElementById('PeopleBtn').style.backgroundColor='transparent';
              document.getElementById('PeopleBtnP').style.color='white';
              document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
              document.getElementById('Profile2UserBtnP').style.color='white';
              document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
              document.getElementById('PercentageSquareBtnP').style.color='white';
              document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
              document.getElementById('MessageText1BtnP').style.color='white';
              document.getElementById('GraphBtn').style.backgroundColor='transparent';
              document.getElementById('GraphBtnP').style.color='white';
              document.getElementById('ProfileBtn').style.backgroundColor='transparent';
              document.getElementById('ProfileBtnP').style.color='white';

              localStorage.removeItem('MsgUserNewName');
              localStorage.removeItem('emailCus');
              localStorage.removeItem('CusCode');
              localStorage.removeItem('MsgUserNewType');
          }
         
          else{
              // setCountCommand(false)
              // let element = document.getElementById('CommandBtn').style.backgroundColor='transparent';
              // document.getElementById('CommandBtnP').style.color='white';
          }
          localStorage.removeItem('msgLightBox');
      }

    const handleClickBriefcaseLaw = (e) => {
        if(!countBriefcaseLaw && localStorage.getItem('userType')=='1'){
            setCountBriefcaseLaw(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountProfile2User2(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountGraph(false);
            setCountProfileAdd(false);

            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='white';
            document.getElementById('BriefcaseBtnPLaw').style.color='black';

            document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileAddBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('Profile2UserBtn2').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP2').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            
        }
        else if(!countBriefcaseLaw && localStorage.getItem('userType')=='2'){
            setCountBriefcaseLaw(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountGraph(false);
            setCountCommand(false);
            setCountCommand2(false);
            setCountProfile(false);
            setCountProfileTick(false);

            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='white';
            document.getElementById('BriefcaseBtnPLaw').style.color='black';

            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileTickBtnP').style.color='white';
            document.getElementById('Command2Btn').style.backgroundColor='transparent';
            document.getElementById('Command2BtnP').style.color='white';
            document.getElementById('CommandBtn').style.backgroundColor='transparent';
            document.getElementById('CommandBtnP').style.color='white';
            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            document.getElementById('Profile2UserBtnP').style.color='white';
            document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
            document.getElementById('ProfileBtn').style.backgroundColor='transparent';
            document.getElementById('ProfileBtnP').style.color='white';
        }
        else if(!countBriefcaseLaw && localStorage.getItem('userType')=='3'){
            setCountBriefcaseLaw(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountGraph(false);
            document.getElementById('BriefcaseBtnLaw').style.backgroundColor='white';
            document.getElementById('BriefcaseBtnPLaw').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('NotepadBtn').style.backgroundColor='transparent';
            document.getElementById('NotepadBtnP').style.color='white';
            // document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            // document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            // document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
        }
        else if(!countBriefcaseLaw && localStorage.getItem('userType')=='4'){
            setCountBriefcaseLaw(true);
            setCountMessageText1(false)
            setCountPercentageSquare(false);
            setCountProfile2User(false);
            setCountPeople(false)
            setCount(false);
            setCountNotePad(false);
            setCountGraph(false);
            // document.getElementById('BriefcaseBtnLaw').style.backgroundColor='white';
            // document.getElementById('BriefcaseBtnPLaw').style.color='black';

            document.getElementById('homeBtn').style.backgroundColor='transparent';
            document.getElementById('homeBtnP').style.color='white';
            document.getElementById('PeopleBtn').style.backgroundColor='transparent';
            document.getElementById('PeopleBtnP').style.color='white';
            // document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
            // document.getElementById('Profile2UserBtnP').style.color='white';
            // document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
            // document.getElementById('PercentageSquareBtnP').style.color='white';
            document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
            document.getElementById('MessageText1BtnP').style.color='white';
            document.getElementById('GraphBtn').style.backgroundColor='transparent';
            document.getElementById('GraphBtnP').style.color='white';
        }
        else{
            // setCountBriefcaseLaw(false)
            // let element = document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
            // document.getElementById('BriefcaseBtnPLaw').style.color='white';
        }
        localStorage.removeItem('msgLightBox');
    }

    const handleClickProfileTick = (e) => {
        if(!countProfileTick && localStorage.getItem('userType')=='2' || 
        (localStorage.getItem('turnOffMeLawCusMsg') && localStorage.getItem('userType')=='2')){
              setCountProfileTick(true);
              setCountCommand(false);
              setCountCommand2(false);
              setCountMessageText1(false)
              setCountPercentageSquare(false);
              setCountProfile2User(false);
              setCountPeople(false)
              setCount(false);
              setCountNotePad(false);
              setCountGraph(false);
              setCountBriefcase(false);
              setCountProfile(false);
  
              document.getElementById('ProfileTickBtn').style.backgroundColor='white';
              document.getElementById('ProfileTickBtnP').style.color='black';

              document.getElementById('Command2Btn').style.backgroundColor='transparent';
              document.getElementById('Command2BtnP').style.color='white';
              document.getElementById('CommandBtn').style.backgroundColor='transparent';
              document.getElementById('CommandBtnP').style.color='white';
              document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
              document.getElementById('BriefcaseBtnP').style.color='white';
              document.getElementById('homeBtn').style.backgroundColor='transparent';
              document.getElementById('homeBtnP').style.color='white';
              document.getElementById('PeopleBtn').style.backgroundColor='transparent';
              document.getElementById('PeopleBtnP').style.color='white';
              document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
              document.getElementById('Profile2UserBtnP').style.color='white';
              document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
              document.getElementById('PercentageSquareBtnP').style.color='white';
              document.getElementById('MessageText1Btn').style.backgroundColor='transparent';
              document.getElementById('MessageText1BtnP').style.color='white';
              document.getElementById('GraphBtn').style.backgroundColor='transparent';
              document.getElementById('GraphBtnP').style.color='white';
              document.getElementById('ProfileBtn').style.backgroundColor='transparent';
              document.getElementById('ProfileBtnP').style.color='white';

              localStorage.removeItem('MsgUserNewName');
              localStorage.removeItem('emailCus');
              localStorage.removeItem('CusCode');
              localStorage.removeItem('MsgUserNewType');
          }
         
          else{
              // setCountCommand(false)
              // let element = document.getElementById('CommandBtn').style.backgroundColor='transparent';
              // document.getElementById('CommandBtnP').style.color='white';
          }
          localStorage.removeItem('msgLightBox');
      }

    const handleClickGlobal = (e) => {
        if(!countGlobal){
            setCountGlobal(true)
            document.getElementById('GlobalBtn').style.backgroundColor='white';
           // document.getElementById('GlobalBtnP').style.color='black';
            
        }
        else{
            setCountGlobal(false)
            let element = document.getElementById('GlobalBtn').style.backgroundColor='transparent';
          //  document.getElementById('GlobalBtnP').style.color='white';
        }
   
    }
    const handleoutGlobal = (e) => {
        setCountGlobal(false);
        let element = document.getElementById('GlobalBtn').style.backgroundColor='transparent';
        document.getElementById('GlobalBtnP').style.color='white';

    }
    const {t, i18n} = useTranslation(['Login']);
    const  handelIR = (e) => {
        // e.preventDefault();
        i18n.changeLanguage('fa_IR');
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
        document.body.setAttribute('lang', 'fa');
}
    const  handelUS = (e) => {
        // e.preventDefault();
        i18n.changeLanguage('en_US');
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
        document.body.setAttribute('lang', 'en');
        // localStorage.getItem('userType');
}
if(localStorage.getItem('langL')){

}
else
{
  localStorage.setItem('langL' , "English");
  localStorage.setItem('langV' , 1);
}

const [selectValue , setSelectValue] = useState({ label: localStorage.getItem('langL'), value: localStorage.getItem('langV') });
useEffect(() => {
  if(values.SelectLanguage != 2)
  {
    handelUS();
    localStorage.setItem('langV' , 1);
    localStorage.setItem('langL' , "English");
  }
   
  else
  {
    handelIR()
    localStorage.setItem('langV' , 2);
    localStorage.setItem('langL' , "Persian");
  }
    
}, [values.SelectLanguage]);
const dataCus = true;
const dataCus1 = true;
const dataAgents = true;
const dataPrices = true;
const dataLawyer = true;
const dataAgentAgent = true;
const dataLawyerHome = true;
const dataAgentHome = true;
const dataCustomerHome = true;



useEffect(() => {
    if(localStorage.getItem('msgLightBox')==true)
    {
        handleClickMessageText1();
        console.log(123456);
    }
  }, [])

   
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  
  const [isOpen3, setIsOpen3] = useState(false);
  
  const togglePopup = () => {
    setIsOpen(!isOpen);
  } 

  const togglePopup2 = () => {
      setIsOpen2(!isOpen2);
  }
  
  const togglePopup3 = () => {
      setIsOpen3(!isOpen3);
  }

return ( 
    <> <div className="mainstyle col-12">
        
        <div className="box">
          
            <div className="box_logo_img col-md-12 col-4">
                <img src={logoDash} alt="" />
            </div>
            <div className="box_info_main col-md-12 col-8">
                {/* <h4>{ localStorage.getItem('username')}</h4> */}
                <h4>{localStorage.getItem('userType')=='1' && localStorage.getItem('lawyerCode')}</h4>
                <h4 className='myStarLeftNav'> {(localStorage.getItem('distributorPlusCode') && localStorage.getItem('distributorPlusCode')!='null' ) &&<Star1  className="star_dcplusm" size="18" color="#007eee" variant="Bold"/>}{localStorage.getItem('userType')=='2' && localStorage.getItem('agentCode')}</h4>
                <h4>{localStorage.getItem('userType')=='3' && localStorage.getItem('customerCode')}</h4>
                <h4>{localStorage.getItem('userType')=='4' && localStorage.getItem('marketerCode')}</h4>
            </div>
            <div className="iconsbox col-12">
                {activeuser && localStorage.getItem('userType')=='1' && <button onClick={(dataLawyerHome) => {childToParentLawyerHome(dataLawyerHome);handleClickHome();}} id='homeBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!count && <Home2  color="black" size="100"/>}
                    {count  && <Home2 color="black" variant="Bold" size="100"/>}
                    <p id="homeBtnP">{t('Home')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='1' &&<button  disabled id='homeBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!count && <Home2  color="black" size="100"/>}
                    {count  && <Home2 color="black" variant="Bold" size="100"/>}
                    <p id="homeBtnP">{t('Home')}</p>
                </button>}
                
                {activeuser && localStorage.getItem('userType')=='2' && <button onClick={(dataAgentHome) => {childToParentAgentHome(dataAgentHome);handleClickHome();}} id='homeBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!count && <Home2  color="black" size="100"/>}
                    {count  && <Home2 color="black" variant="Bold" size="100"/>}
                    <p id="homeBtnP">{t('Home')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='2' &&<button  disabled id='homeBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!count && <Home2  color="black" size="100"/>}
                    {count  && <Home2 color="black" variant="Bold" size="100"/>}
                    <p id="homeBtnP">{t('Home')}</p>
                </button>}

                {activeuser && localStorage.getItem('userType')=='3' && <button onClick={(dataCustomerHome) => {childToParentCustomerHome(dataCustomerHome);handleClickHome();}} id='homeBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!count && <Home2  color="black" size="100"/>}
                    {count  && <Home2 color="black" variant="Bold" size="100"/>}
                    <p id="homeBtnP">{t('Home')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='3' &&<button  disabled id='homeBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!count && <Home2  color="black" size="100"/>}
                    {count  && <Home2 color="black" variant="Bold" size="100"/>}
                    <p id="homeBtnP">{t('Home')}</p>
                </button>}

                {activeuser && localStorage.getItem('userType')=='4' && <button onClick={(dataCustomerHome) => {childToParentMarketerHome(dataCustomerHome);handleClickHome();}} id='homeBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!count && <Home2  color="black" size="100"/>}
                    {count  && <Home2 color="black" variant="Bold" size="100"/>}
                    <p id="homeBtnP">{t('Home')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='4' &&<button  disabled id='homeBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!count && <Home2  color="black" size="100"/>}
                    {count  && <Home2 color="black" variant="Bold" size="100"/>}
                    <p id="homeBtnP">{t('Home')}</p>
                </button>}

                {activeuser && localStorage.getItem('userType')=='3'&&<button onClick={(dataCus1) => {childToParentCus1(dataCus1);handleClickNotepad();}} id='NotepadBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countNotepad && <Notepad2  color="black" size="100"/>}
                    {countNotepad  && <Notepad2 color="black" variant="Bold" size="100"/>}
                    <p id="NotepadBtnP">{t('My Requests')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='3'&&<button  disabled id='NotepadBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countNotepad && <Notepad2  color="black" size="100"/>}
                    {countNotepad  && <Notepad2 color="black" variant="Bold" size="100"/>}
                    <p id="NotepadBtnP">{t('My Requests')}</p>
                </button>}
                
                {activeuser && localStorage.getItem('userType')=='1'&&<button onClick={(dataCus) => {childToParentCus(dataCus); handleClickPeople();}} id='PeopleBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPeople && <People color="black" size="100"/>}
                    {countPeople && <People color="black" variant="Bold" size="100"/>}
                    <p id="PeopleBtnP">{t('Customers')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='1'&&<button disabled  id='PeopleBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPeople && <People color="black" size="100"/>}
                    {countPeople && <People color="black" variant="Bold" size="100"/>}
                    <p id="PeopleBtnP">{t('Customers')}</p>
                </button>}
                {activeuser && localStorage.getItem('userType')=='2'&&<button onClick={(dataCusA2) => {childToParentCusA2(dataCusA2); handleClickPeople();}} id='PeopleBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPeople && <People color="black" size="100"/>}
                    {countPeople && <People color="black" variant="Bold" size="100"/>}
                    <p id="PeopleBtnP">{t('Customers')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='2'&&<button disabled  id='PeopleBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPeople && <People color="black" size="100"/>}
                    {countPeople && <People color="black" variant="Bold" size="100"/>}
                    <p id="PeopleBtnP">{t('Customers')}</p>
                </button>}
                {activeuser && localStorage.getItem('userType')=='4'&&<button onClick={(dataCusA2) => {childToParentCusMark(dataCusA2); handleClickPeople();}} id='PeopleBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPeople && <People color="black" size="100"/>}
                    {countPeople && <People color="black" variant="Bold" size="100"/>}
                    <p id="PeopleBtnP">{t('Customers')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='4'&&<button disabled  id='PeopleBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPeople && <People color="black" size="100"/>}
                    {countPeople && <People color="black" variant="Bold" size="100"/>}
                    <p id="PeopleBtnP">{t('Customers')}</p>
                </button>}


               

                {activeuser && localStorage.getItem('userType')=='1'&&<button onClick={(dataAgents) => {childToParentAgents(dataAgents); handleClickProfile2User();}} id='Profile2UserBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfile2User && <Profile2User color="black" size="100"/>}
                    {countProfile2User && <Profile2User color="black" variant="Bold" size="100"/>}
                    <p id="Profile2UserBtnP">{t('Distributers')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='1'&&<button disabled  id='Profile2UserBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfile2User && <Profile2User color="black" size="100"/>}
                    {countProfile2User && <Profile2User color="black" variant="Bold" size="100"/>}
                    <p id="Profile2UserBtnP">{t('Distributers')}</p>
                </button>}
                {activeuser && localStorage.getItem('userType')=='2'&&<button onClick={(dataLawyer) => {childToParentLawyer(dataLawyer); handleClickProfile2User();}} id='Profile2UserBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfile2User && <Profile2User color="black" size="100"/>}
                    {countProfile2User && <Profile2User color="black" variant="Bold" size="100"/>}
                    <p id="Profile2UserBtnP">{t('Lawyer')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='2'&&<button disabled  id='Profile2UserBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfile2User && <Profile2User color="black" size="100"/>}
                    {countProfile2User && <Profile2User color="black" variant="Bold" size="100"/>}
                    <p id="Profile2UserBtnP">{t('Lawyer')}</p>
                </button>}

                {activeuser &&  localStorage.getItem('userType')=='1'&&<button onClick={(dataAgentAgent) => {childToParentLawyers(dataAgentAgent); handleClickBriefcaseLaw();}} id='BriefcaseBtnLaw' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countBriefcaseLaw && <Briefcase color="black" size="100"/>}
                    {countBriefcaseLaw && <Briefcase color="black" variant="Bold" size="100"/>}
                    <p id="BriefcaseBtnPLaw">{t('Lawyers')}</p>
                </button>}
                {!activeuser &&  localStorage.getItem('userType')=='1'&&<button disabled onClick={handleClickBriefcaseLaw} id='BriefcaseBtnLaw' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countBriefcaseLaw && <Briefcase color="black" size="100"/>}
                    {countBriefcaseLaw && <Briefcase color="black" variant="Bold" size="100"/>}
                    <p id="BriefcaseBtnPLaw">{t('Lawyers')}</p>
                </button>}

                {localStorage.getItem('distributorPlusCode')!='null' &&  localStorage.getItem('userType')=='2'&&<button onClick={(dataAgentDis) => {childToParentAgentDis(dataAgentDis); handleClickProfile();}} id='ProfileBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfile && <Profile color="black" size="100"/>}
                    {countProfile && <Profile color="black" variant="Bold" size="100"/>}
                    <p id="ProfileBtnP">{t('Distributers')}</p>
                </button>}
                {localStorage.getItem('distributorPlusCode')=='null' &&  localStorage.getItem('userType')=='2' &&  <button onClick={togglePopup2} title='You Can Upgrade Your Account to Profesional Varsion, fro mor information see the help!' id='ProfileBtn' className="btn btnDash myProBtnDeActive ProDcDeactive"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfile && <Profile color="black" size="100"/>}
                    {countProfile && <Profile color="black" variant="Bold" size="100"/>}
                    <p id="ProfileBtnP">{t('Distributers')} <b  className='ProDcDeactive'>{' (Pro)'}</b></p>
                </button>}

                {activeuser &&  localStorage.getItem('userType')=='2'&&<button onClick={(dataAgentAgent) => {childToParentMarketerDist(dataAgentAgent); handleClickCommand();}} id='CommandBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countCommand && <Command color="black" size="100"/>}
                    {countCommand && <Command color="black" variant="Bold" size="100"/>}
                    <p id="CommandBtnP">{t('Agents')}</p>
                </button>}
                {!activeuser &&  localStorage.getItem('userType')=='2'&&<button disabled onClick={handleClickCommand} id='CommandBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countCommand && <Command color="black" size="100"/>}
                    {countCommand && <Command color="black" variant="Bold" size="100"/>}
                    <p id="CommandBtnP">{t('Agents')}</p>
                </button>}


                {activeuser &&  localStorage.getItem('userType')=='2'&&<button onClick={(dataAgentAgent) => {childToParentAgentAgent(dataAgentAgent); handleClickBriefcase();}} id='BriefcaseBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countBriefcase && <Briefcase color="black" size="100"/>}
                    {countBriefcase && <Briefcase color="black" variant="Bold" size="100"/>}
                    <p id="BriefcaseBtnP">{t('Related Distributers')}</p>
                </button>}
                {!activeuser &&  localStorage.getItem('userType')=='2'&&<button disabled onClick={handleClickBriefcase} id='BriefcaseBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countBriefcase && <Briefcase color="black" size="100"/>}
                    {countBriefcase && <Briefcase color="black" variant="Bold" size="100"/>}
                    <p id="BriefcaseBtnP">{t('Related Distributers')}</p>
                </button>}


                {activeuser && localStorage.getItem('userType')=='1'&&<button onClick={(dataPrices) => {childToParentPrices(dataPrices); handleClickPercentageSquare()}} id='PercentageSquareBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPercentageSquare && <PercentageSquare color="black" size="100"/>}
                    {countPercentageSquare && <PercentageSquare color="black" variant="Bold" size="100"/>}
                    <p id="PercentageSquareBtnP">{t('My Services')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='1'&&<button disabled  id='PercentageSquareBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPercentageSquare && <PercentageSquare color="black" size="100"/>}
                    {countPercentageSquare && <PercentageSquare color="black" variant="Bold" size="100"/>}
                    <p id="PercentageSquareBtnP">{t('My Services')}</p>
                </button>}
                
                {activeuser && localStorage.getItem('userType')=='1'&&<button onClick={(dataRefCustomers) => {childToParentRefCustomers(dataRefCustomers); handleClickProfileAdd();}} id='ProfileAddBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfileAdd && <ProfileAdd color="black" size="100"/>}
                    {countProfileAdd && <ProfileAdd color="black" variant="Bold" size="100"/>}
                    <p id="ProfileAddBtnP">{t('Referal Customers')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='1'&&<button disabled  id='ProfileAddBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfileAdd && <ProfileAdd color="black" size="100"/>}
                    {countProfileAdd && <ProfileAdd color="black" variant="Bold" size="100"/>}
                    <p id="ProfileAddBtnP">{t('Referal Customers')}</p>
                </button>}

                {activeuser && localStorage.getItem('userType')=='1'&&<button onClick={(dataAgents) => {childToParentDCCustomers(dataAgents); handleClickProfile2User2();}} id='Profile2UserBtn2' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfile2User && <Profile2User color="black" size="100"/>}
                    {countProfile2User && <Profile2User color="black" variant="Bold" size="100"/>}
                    <p id="Profile2UserBtnP2">{t('Distributers Customers')}</p>
                </button>}
                {!activeuser && localStorage.getItem('userType')=='1'&&<button disabled  id='Profile2UserBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfile2User && <Profile2User color="black" size="100"/>}
                    {countProfile2User && <Profile2User color="black" variant="Bold" size="100"/>}
                    <p id="Profile2UserBtnP2">{t('Distributers Customers')}</p>
                </button>}

                {activeuser && localStorage.getItem('userType')=='2'&&  <button onClick={(dataCusADis) => {childToParentCusADis(dataCusADis);handleClickProfileTick();}} id='ProfileTickBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfileTick && <ProfileTick color="black" size="100"/>}
                    {countProfileTick && <ProfileTick color="black" variant="Bold" size="100"/>}
                    <p id="ProfileTickBtnP">{t('DS Customers')}</p>
                </button>}
                {!activeuser &&  <button disabled onClick={handleClickProfileTick} id='ProfileTickBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countProfileTick && <ProfileTick color="black" size="100"/>}
                    {countProfileTick && <ProfileTick color="black" variant="Bold" size="100"/>}
                    <p id="ProfileTickBtnP">{t('DS Customers')}</p>
                </button>}

                {activeuser &&  localStorage.getItem('userType')=='2'&&<button onClick={(dataCusA2A) => {childToParentCusA2A(dataCusA2A);handleClickCommand2();}} id='Command2Btn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countCommand2 && <CommandSquare color="black" size="100"/>}
                    {countCommand2 && <CommandSquare color="black" variant="Bold" size="100"/>}
                    <p id="Command2BtnP">{t('Agent Cusomers')}</p>
                </button>}
                {!activeuser &&  <button disabled onClick={handleClickCommand2} id='Command2Btn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countCommand2 && <CommandSquare color="black" size="100"/>}
                    {countCommand2 && <CommandSquare color="black" variant="Bold" size="100"/>}
                    <p id="Command2BtnP">{t('Agents Cusomers')}</p>
                </button>}

                {activeuser &&  localStorage.getItem('userType')=='2'&&<button onClick={(dataAgentPrice) => {childToParentAgentPrice(dataAgentPrice);handleClickPercentageSquare();}} id='PercentageSquareBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPercentageSquare && <PercentageSquare color="black" size="100"/>}
                    {countPercentageSquare && <PercentageSquare color="black" variant="Bold" size="100"/>}
                    <p id="PercentageSquareBtnP">{t('My Services')}</p>
                </button>}
                {!activeuser &&  <button disabled onClick={handleClickPercentageSquare} id='PercentageSquareBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPercentageSquare && <PercentageSquare color="black" size="100"/>}
                    {countPercentageSquare && <PercentageSquare color="black" variant="Bold" size="100"/>}
                    <p id="PercentageSquareBtnP">{t('My Services')}</p>
                </button>}

                
                {activeuser &&  localStorage.getItem('userType')=='4'&&<button onClick={(dataAgentPrice) => {childToParentMarketerPrice(dataAgentPrice);handleClickPercentageSquare();}} id='PercentageSquareBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPercentageSquare && <PercentageSquare color="black" size="100"/>}
                    {countPercentageSquare && <PercentageSquare color="black" variant="Bold" size="100"/>}
                    <p id="PercentageSquareBtnP">{t('DS Services')}</p>
                </button>}
                {!activeuser &&  <button disabled onClick={handleClickPercentageSquare} id='PercentageSquareBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countPercentageSquare && <PercentageSquare color="black" size="100"/>}
                    {countPercentageSquare && <PercentageSquare color="black" variant="Bold" size="100"/>}
                    <p id="PercentageSquareBtnP">{t('DS Services')}</p>
                </button>}

                {activeuser && <button onClick={(dataPrices) => {childToParentMessage(dataPrices); handleClickMessageText1()}} id='MessageText1Btn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countMessageText1 && <MessageText1 color="black" size="100"/>}
                    {countMessageText1 && <MessageText1 color="black" variant="Bold" size="100"/>}
                    <p id="MessageText1BtnP">{t('Messages')}</p>
                </button>}
                {!activeuser && <button disabled  id='MessageText1Btn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countMessageText1 && <MessageText1 color="black" size="100"/>}
                    {countMessageText1 && <MessageText1 color="black" variant="Bold" size="100"/>}
                    <p id="MessageText1BtnP">{t('Messages')}</p>
                </button>}

                {activeuser &&  <button onClick={handleClickGraph} id='GraphBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countGraph && <Graph color="black" size="100"/>}
                    {countGraph && <Graph color="black" variant="Bold" size="100"/>}
                    <p id="GraphBtnP">{t('Services')}</p>
                </button>}
                {!activeuser &&  <button disabled onClick={handleClickGraph} id='GraphBtn' className="btn btnDash"  style={{backgroundColor:'transparent'}} value="test">
                    {!countGraph && <Graph color="black" size="100"/>}
                    {countGraph && <Graph color="black" variant="Bold" size="100"/>}
                    <p id="GraphBtnP">{t('Services')}</p>
                </button>}

               
            </div>
{/* start responsive*/}
            {localStorage.getItem('userType')== '1' &&<div className="CreatableSelectLeft col-12">
            {/* <label htmlFor="Service">{t('Service')}</label> */}
            <div className="inputAndlabel col-lg-12 col-12 d-none d-md-flex">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='5'>{t('Spanish')}</option > 
                  <option  value='6'>{t('French')}</option > 
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicants Skin Color')}</label> */}
            </div>
           </div>}
{/* start responsive*/}
           {localStorage.getItem('userType')== '2' &&<div className="CreatableSelectLeft2 col-12">
            {/* <label htmlFor="Service">{t('Service')}</label> */}
            <div className="inputAndlabel col-lg-12 col-12 d-none d-md-flex">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='5'>{t('Spanish')}</option > 
                  <option  value='6'>{t('French')}</option > 
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicants Skin Color')}</label> */}
            </div>
           </div>}
{/* start responsive*/}
           {localStorage.getItem('userType')== '3' &&<div className="CreatableSelectLeft3 col-12">
            {/* <label htmlFor="Service">{t('Service')}</label> */}
            <div className="inputAndlabel col-lg-12 col-12 d-none d-md-flex">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='5'>{t('Spanish')}</option > 
                  <option  value='6'>{t('French')}</option > 
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicants Skin Color')}</label> */}
            </div>
           </div>}

           {localStorage.getItem('userType')== '4' &&<div className="CreatableSelectLeft3 col-12">
            {/* <label htmlFor="Service">{t('Service')}</label> */}
            <div className="inputAndlabel col-lg-12 col-12 d-none d-md-flex">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='5'>{t('Spanish')}</option > 
                  <option  value='6'>{t('French')}</option > 
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicants Skin Color')}</label> */}
            </div>
           </div>}
           {/* start responsive*/}
           {localStorage.getItem('userType')== '4' &&<div className="CreatableSelectLeft3 col-12">
            {/* <label htmlFor="Service">{t('Service')}</label> */}
            <div className="inputAndlabel col-lg-12 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='5'>{t('Spanish')}</option > 
                  <option  value='6'>{t('French')}</option > 
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicants Skin Color')}</label> */}
            </div>
           </div>}
            {/* <div className="globalLang">
                    
              
                <div onMouseEnter={handleClickGlobal} onMouseLeave={handleoutGlobal} id='GlobalBtn' className="btn btn-sm globalBtnHover"  style={{backgroundColor:'transparent'}} value="test">
                    {!countGlobal && <Global color="white" size="100"/>}
                    {countGlobal && <Global color="black" size="60"/>} 
                    {countGlobal && 
                    <p id="GlobalBtnP" className="faEnP"> 
                    <a href="" onClick={handelIR} className="handleIR">fa</a> | 
                    <a href="" onClick={handelUS} className="handleUS">EN</a></p>}
                </div>

            </div> */}
        </div>
       
    </div>
 {isOpen2 && <Popup
    content={
          <><div className="PboxFirstRow">

            </div><div className="PboxSecondRow Pbox_headagentCus">
                    <h4>{t('How To Become A Premium DS?')}</h4>
                </div><div className="PboxThirdRow">
                    <p>{t('In Our Rules There Are some ways that You can Got Your Account DS+, After that you can Use special Services of This Application. for more Information please visit our help center.')}</p>
                </div><div className="PboxForthRowSendMail col-12 btnto_hc">
                    <label>{t('Our Help Desk')}</label>
                    <div className="input-group mb-3">
                        <button onClick={()=>{togglePopup2();togglePopup3();}} className="btn btn-dark login-btnStep6 col-4" type="button" id="button-addon2">{t('Help Center') + '!'}</button>
                    </div>
                </div></>
    }
    handleClose={togglePopup2}
  />}
  {isOpen3 && <Popup3
      content={<>
  
          <div className="mainRegisterheader col-xxl-10 col-12">
            <div className="subRegisterrightheader col-md-8 col-12">
              <label className="form-check-forgot font-face-gm">
                {/* <Link className="font-face-gm backtohome txt_backhome" to='/'> {t('< Back to Home page')}</Link> */}
              </label>
              <h4 className="card-title font-face-gm">{t('Help Center')}</h4>
            </div>
            

          </div>     
          <div className="progressRowM6 serv_page col-12">
            <div className="myshapes d-none d-sm-flex">
              <div className="mycircle">
                <h6>{t('Soon!')}</h6>
              </div>
              <div className="myProCirlce5"></div>
            </div>
            <TickSquare size="64" color="#ffffff" variant="Bold" className="d-block d-sm-none"/>
            <div className="mytext6">
              <h5 className="choosText d-flex d-sm-none">{t('Done!')}</h5>
              {/* <h5 className="step1 d-none d-sm-block">{t('You are almost there!')}</h5>
              <h6 className="choosText">{t('Check Your Email')}</h6> */}
            </div>
            <div className="LastStepRegText">
                
                
            </div>
            {localStorage.getItem('userType')=='1'&& <p  className="RegisterHeaderp txt_comsoonP d-none d-sm-block">{t('Your Cash is:')} <b> {t('1500$')}</b></p> }
            {localStorage.getItem('userType')=='2'&& <p  className="RegisterHeaderp d-none d-sm-block">{t('Your Cash is:')} <b> {t('1000$')}</b></p>}
            {localStorage.getItem('userType')=='4'&& <p  className="RegisterHeaderp d-none d-sm-block">{t('Your Cash is:')} <b> {t('500$')}</b></p> }
            <p className="RegisterHeaderp d-none d-sm-block">{t('Dear user, These Services will be Available Soon!')}</p>
                <p className="RegisterHeaderp d-none d-sm-block">{t('Our experts are working on it.')}</p>
                  <Link to="/Dashboard" onClick={togglePopup3} className="btn btn-dark login-btnStep6 col-4">{t('Go to main page')}</Link>
                  
              
          </div>
          <div className="progressStyle col-xxl-10 col-12">
          </div>
      
   
  </>
     }
      handleClose={togglePopup3}
    />}
</>
);
}
 
export default LeftNavBar;