import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import RegisterLogo from './images/LogoRegister.png'
import {InfoCircle, ArrowLeft2, Home2} from 'iconsax-react';
import Logo from './images/logo-tele.png';
//*******************end of UI Materials */

import { useTranslation } from 'react-i18next'; // multi language package
import ReactCountryFlag from "react-country-flag"; // flag of country
//******************************** */
const ResetPassword = () => {
    //********************************fetching API */
    const [formError , setFormError] = useState(false);
    const [helpEmail,setHelpEmail] = useState('');
    const [helpFieldOfStudy, setHelpFieldOfStudy] = useState();
    const [helpUniversity, setHelpUniversity] = useState();
    const [helpPhone, setHelpPhone] = useState();
    const [body, setBody] = useState();
    const [author, setAuthor] = useState('kafa');
    const [ispending, setIspending] = useState(false);
    const history = useHistory();
    const handleSubmit = (e) => {
         e.preventDefault();
         localStorage.setItem('FieldOfStudy' , values.FieldOfStudy);
         localStorage.setItem('University' , values.University);
         localStorage.setItem('Degree' , values.Degree);
            history.push('/regstep4');
    }
    //***************************************** */

   // ui materials functions...
        const [values, setValues] = useState({
          FieldOfStudy: localStorage.FieldOfStudy,
          University: localStorage.University,
          Degree: localStorage.Degree,
          Email: '',
          About: '',
          myDate: '',
          showPassword: false,
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          if(!(document.getElementById('inputDegree').value=="")){
            //  document.getElementById('errorLabelDegree').style.display="flex";
            document.getElementById('inputDegree').style.color="#313131";
             document.getElementById('MyDegreeplaceholder').style.color="transparent";
             document.getElementById('MyDegreeSapn').style.color="transparent";
            }
            // else{
            // //  document.getElementById('errorLabelDegree').style.display="none";
            //  document.getElementById('MyDegreeplaceholder').style.color="gray";
            //  document.getElementById('MyDegreeSapn').style.color="red";
            // }

            if(document.activeElement.id=='inputFieldOfStudy')
            {
              document.getElementById('MyFieldOfStudyplaceholder').style.color="transparent";
            }
             if(values.FieldOfStudy!="")
            {
              document.getElementById('inputFieldOfStudy').style.color="#313131";
            }

            
            if(document.activeElement.id=='inputUniversity')
            {
              document.getElementById('MyUniversityplaceholder').style.color="transparent";
            }
             if(values.University!="")
            {
              document.getElementById('inputUniversity').style.color="#313131";
            }
        };
      
        const handleClickShowPassword = () => {
          setValues({
            ...values,
            showPassword: !values.showPassword,
          });
        };
      
        const handleMouseDownPassword = (event) => {
          event.preventDefault();
        };
       

// end of ui material functions
const [progress, setProgress] = useState(20);

useEffect(() => {
  const timer = setInterval(() => {
    setProgress((prevProgress) => (prevProgress >= 20 ? 21 : prevProgress + 20));
  }, 10);

  return () => {
    clearInterval(timer);
  };
}, []); 

//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);
const  handelIR = (e) => {
    e.preventDefault();
    i18n.changeLanguage('fa_IR');
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.body.setAttribute('lang', 'fa');
    document.getElementById('main-row-2id').style.direction="rtl";
}
const  handelUS = (e) => {
    e.preventDefault();
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    // document.getElementsByClassName('main-row').style.direction="ltr";
    document.getElementById('main-row-2id').style.direction="ltr";
}



 
 const handleinputFieldOfStudyBlur = (e) =>{
   if(!(document.getElementById('inputFieldOfStudy').value=="")){
    document.getElementById('errorLabelFieldOfStudy').style.display="flex";
    document.getElementById('MyFieldOfStudyplaceholder').style.color="transparent";
    document.getElementById('MyFieldOfStudySapn').style.color="transparent";
   }
   else{
    document.getElementById('errorLabelFieldOfStudy').style.display="none";
    document.getElementById('MyFieldOfStudyplaceholder').style.color="gray";
    document.getElementById('MyFieldOfStudySapn').style.color="red";
   }
 }

 const handleinputUniversityBlur = (e) =>{
  if(!(document.getElementById('inputUniversity').value=="")){
   document.getElementById('errorLabelUniversity').style.display="flex";
   document.getElementById('MyUniversityplaceholder').style.color="transparent";
   document.getElementById('MyUniversitySapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelUniversity').style.display="none";
   document.getElementById('MyUniversityplaceholder').style.color="gray";
   document.getElementById('MyUniversitySapn').style.color="red";
  }
}

const handleinputDegreeBlur = (e) =>{
  if(!(document.getElementById('inputDegree').value=="")){
  //  document.getElementById('errorLabelDegree').style.display="flex";
   document.getElementById('MyDegreeplaceholder').style.color="transparent";
   document.getElementById('MyDegreeSapn').style.color="transparent";
  }
  else{
  //  document.getElementById('errorLabelDegree').style.display="none";
   document.getElementById('MyDegreeplaceholder').style.color="gray";
   document.getElementById('MyDegreeSapn').style.color="red";
  }
}

const hendaleMouseOverFieldOfStudy = (e) =>{
  if(document.getElementById('inputFieldOfStudy').value=="")
  document.getElementById('MyFieldOfStudyplaceholder').style.color="#313131";
  document.getElementById('inputFieldOfStudy').style.borderColor="#313131";
}

const hendaleMouseLeaveFieldOfStudy = (e) =>{
  if(document.getElementById('inputFieldOfStudy').value=="")
 document.getElementById('MyFieldOfStudyplaceholder').style.color="white";
 document.getElementById('inputFieldOfStudy').style.borderColor="#31313131";
}

const hendaleMouseOverDegree = (e) =>{
  if(document.getElementById('inputDegree').value=="")
  document.getElementById('MyDegreeplaceholder').style.color="#313131";
  document.getElementById('inputDegree').style.borderColor="#313131";
}

const hendaleMouseLeaveDegree = (e) =>{
  if(document.getElementById('inputDegree').value=="")
 document.getElementById('MyDegreeplaceholder').style.color="white";
 document.getElementById('inputDegree').style.borderColor="#31313131";
}

const hendaleMouseOverUniversity = (e) =>{
  if(document.getElementById('inputUniversity').value=="")
  document.getElementById('MyUniversityplaceholder').style.color="#313131";
  document.getElementById('inputUniversity').style.borderColor="#313131";
}

const hendaleMouseLeaveUniversity = (e) =>{
  if(document.getElementById('inputUniversity').value=="")
 document.getElementById('MyUniversityplaceholder').style.color="white";
 document.getElementById('inputUniversity').style.borderColor="#31313131";
}
//***************************************/
    return (
      <><div className="pagePic"></div>
        <div className="loginMainRow form_regisster">
          <div className="rectangle sectionsLogin col-xxl-8 col-xl-9 col-12" id="main-row-2id">
              <div className="mainRegisterheader col-xxl-12 col-12">
              <div className="NewHeaderWithHomeIcone col-xxl-12 col-12">
                  <div className="subRegisterrightheader col-md-8 col-12">
                  <Link className="MyHomeNewReg d-md-flex d-none" to='/'>
                        <Home2 color="white" size="32" />
                    </Link>
                    <br/>
                    <h4 className="card-title font-face-gm">{t('Sign Up To Telesiness')+ ' : ' + t('As Lawyer')}</h4>
                    <p className="RegisterHeaderp">{t('Access your specific features by selecting your user type.')}</p>
                  </div>
                  <div className="subRegisterleftheader col-md-4 col-12">
                    <img src={RegisterLogo} alt="" />
                    <Link className="font-face-gm backtohome ico_backhome" to='/'>
                        <Home2 color="white" size="24" />
                    </Link>
                  </div>
                  </div>
              </div>
              <div className="progressRow col-xxl-10 col-12">
                <div className="myshapes d-none d-sm-flex">
                  <div className="mycircle">
                    <h6>{t('3 of 5')}</h6> 
                  </div>
                  <div className="myProCirlce3"></div>
                </div>
                <div className="mytext">
                  <h5 className="step1">{t('Step 3')}</h5>
                  <h6 className="choosText">{t('Complete your Education information')}</h6>
                  {/* {document.getElementsByTagName('html')[0].getAttribute("dir")== "ltr" && <label className="d-flex d-sm-none" id="errorLabelRegs"><InfoCircle  size="18"  color="#ff0000" /> {t('Please enter Your Information in Persian only.')} </label>} */}
                </div>
              </div>
              <div className="progressStyle col-xxl-10 col-12">
              </div>
              <div className="myForm col-xxl-9 col-xl-10 col-lg-11 col-12">
                <form onSubmit={handleSubmit} noValidate>
                  <div className="input-placeholder col-md-6 col-12">
                      <input 
                        value = {values.FieldOfStudy}
                        type="text"
                        className="form-control"
                        id="inputFieldOfStudy"
                        onBlur={handleinputFieldOfStudyBlur}
                        onChange={handleChange('FieldOfStudy')}
                        onMouseOver={hendaleMouseOverFieldOfStudy}
                        onMouseLeave={hendaleMouseLeaveFieldOfStudy}
                        required
                        tabIndex={1}
                        // autocomplete="off"
                        />
                      {localStorage.FieldOfStudy==''&&<div id="MyFieldOfStudyplaceholder" className="placeholder">
                      {t('Field of Study')}<span id="MyFieldOfStudySapn"></span>
                      </div>}
                      {localStorage.FieldOfStudy!=''&&<div id="MyFieldOfStudyplaceholder" className="placeholder">
                      {t('')}<span id="MyFieldOfStudySapn"></span>
                      </div>}
                      <label id="errorLabelFieldOfStudy">{helpFieldOfStudy}</label>
                  </div>
                  <div className="input-placeholder col-md-6 col-12">
                    <input 
                      value = {values.University}
                      type="text"
                      className="form-control"
                      id="inputUniversity"
                      onBlur={handleinputUniversityBlur}
                      onChange={handleChange('University')}
                      onMouseOver={hendaleMouseOverUniversity}
                      onMouseLeave={hendaleMouseLeaveUniversity} 
                      required
                      tabIndex={2}
                      //autocomplete="off"
                      />
                    {localStorage.University==''&&<div id="MyUniversityplaceholder" className="placeholder">
                    {t('Institute/University')}<span id="MyUniversitySapn"></span>
                    </div>}
                    {localStorage.University!=''&&<div id="MyUniversityplaceholder" className="placeholder">
                    {t('')}<span id="MyUniversitySapn"></span>
                    </div>}
                    <label id="errorLabelUniversity">{}</label>
                  </div>
                  <div className="input-placeholder col-md-6 col-12">
                    <select
                          
                          className="form-select "
                          aria-label="Default"
                          // labelId="demo-simple-select-Degree-helper-label"
                          id="inputDegree"
                          value={values.Degree}
                          label={t('Level/Degree of education')}
                          // MenuProps={MenuProps}
                          onChange={handleChange('Degree')}  
                          onBlur={handleinputDegreeBlur}  
                          onMouseOver={hendaleMouseOverDegree}
                          onMouseLeave={hendaleMouseLeaveDegree} 
                          tabIndex={3}
                          >
                          <option  value="">
                          
                          </option >
                          <option  value='1'>{t('Less than a high school diploma')}</option >
                          <option  value='2'>{t('High school diploma')}</option >
                          <option  value='3'>{t('Some college, no degree')}</option >
                          <option  value='4'>{t('Associate degree')}</option >
                          <option  value='5'>{t('Bachelor degree')}</option >
                          <option  value='6'>{t('Master Degree')}</option >
                          <option  value='7'>{t('Profissional degree')}</option >
                          <option  value='8'>{t('Doctoral degree')}</option >
                          <option  value='9'>{t('Certificate')}</option >
                          <option  value='10'>{t('License')}</option >
                      </select >
                      {localStorage.Degree==''&&<div id="MyDegreeplaceholder" className="placeholder">
                      {t('Level/Degree of education')} <span id="MyDegreeSapn"></span>
                    </div>}
                    {localStorage.Degree!=''&&<div id="MyDegreeplaceholder" className="placeholder">
                      {t('')} <span id="MyDegreeSapn"></span>
                    </div>}
                  </div>
                  <div className="NextStep col-12">
                      <Link to="/regstep2" className="btn btn-outline-light signup-btn d-none d-sm-flex">{t('Back')}</Link>  
                      {!ispending && !formError && <button className="btn btn-dark login-btn">{t('Next Step')}</button>} 
                      {formError && <button className="btn btn-dark login-btn" disabled>{t('Next Step')}</button>} 
                      {ispending && <button className="btn btn-dark login-btn " disabled>{t('Being login...')}</button>} 
                  </div>
     
                </form>
              </div>
          </div>
          <div className="footerDasilav col-12">
            <div className="footer-copy">
              Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
            </div>
            <div className="footer-copy2">
              <br />
              Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
            </div>
          </div>
        </div>
      </>
    
           
    );
}
 
export default ResetPassword;