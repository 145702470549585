import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import Loginpic from './images/login2.png';// blured Background
import logotele from './images/logo-tele.png';// telesiness logo
import RegisterLogo from './images/LogoRegister.png'
import Logo from './images/logo-tele.png';
import {InfoCircle,Home2,ArrowLeft2} from 'iconsax-react';
//*******************end of UI Materials */
import { useTranslation } from 'react-i18next'; // multi language package
import ReactCountryFlag from "react-country-flag"; // flag of country

//******************************** */
const ResetPassword = () => {
    //********************************fetching API */
    const [formError , setFormError] = useState(false);
    const [helpEmail,setHelpEmail] = useState('');
    const [helpFirstName, setHelpFirstName] = useState();
    const [helpLastName, setHelpLastName] = useState();
    const [helpPhone, setHelpPhone] = useState();
    const [body, setBody] = useState();
    const [author, setAuthor] = useState('kafa');
    const [ispending, setIspending] = useState(false);
    const history = useHistory();
    var stringUrl = window.location.href;
    var stringCode = stringUrl.split("/regmstep2/")[1];

    if(stringCode!=undefined) {
    localStorage.setItem('Country','');
    localStorage.setItem('City','');
    localStorage.setItem('Job','');
    localStorage.setItem('iccrc','');
    localStorage.setItem('Certificate','');
    localStorage.setItem('Specialization','');
    localStorage.setItem('website','');
    localStorage.setItem('Upload','');
    localStorage.setItem('FieldOfStudy','');
    localStorage.setItem('University','');
    localStorage.setItem('Degree','');
    localStorage.setItem('FirstName','');
    localStorage.setItem('LastName','');
    localStorage.setItem('Phone','');
    localStorage.setItem('Email','');
    localStorage.setItem('About','');
    localStorage.setItem('myDate','');
    localStorage.setItem('userType','');
    localStorage.setItem('IDNumber','');
       
  }
    const handleSubmit = (e) => {
         e.preventDefault();
         if(document.getElementById('inputFirstName').value=="")
         {
           setHelpFirstName(t('please input your first name'));
           document.getElementById("errorLabelFirstName").style.display="flex";
         }
         else  if(document.getElementById('inputLastName').value=="")
         {
           setHelpLastName(t('please input your Last Name'));
           document.getElementById("errorLabelLastName").style.display="flex";
           document.getElementById("errorLabelFirstName").style.display="none";
           document.getElementById("errorLabelPhone").style.display="none";
          //  document.getElementById("errorLabelPhoneM").style.display="none";
           
         }
         else  if(document.getElementById('inputPhone').value=="" && window.screen.width>600)
         {
           setHelpPhone(t('please input your Phone number'));
           document.getElementById("errorLabelPhone").style.display="flex";
           document.getElementById("errorLabelFirstName").style.display="none";
          //  document.getElementById("errorLabelPhoneM").style.display="none";
           document.getElementById("errorLabelLastName").style.display="none";
           
         }
        //  else  if(document.getElementById('inputPhoneM').value=="" && window.screen.width<600)
        //  {
        //    setHelpPhone("please input your Phone number");
        //   //  document.getElementById("errorLabelPhoneM").style.display="flex";
        //    document.getElementById("errorLabelFirstName").style.display="none";
        //    document.getElementById("errorLabelLastName").style.display="none";
        //    document.getElementById("errorLabelPhone").style.display="none";
           
        //  }
         else  if(document.getElementById('inputEmail').value=="" )
         {
           setHelpEmail(t('please input your Email'));
           document.getElementById("errorLabelEmail").style.display="flex";
           document.getElementById("errorLabelFirstName").style.display="none";
           document.getElementById("errorLabelLastName").style.display="none";
          //  document.getElementById("errorLabelPhoneM").style.display="none";
           document.getElementById("errorLabelPhone").style.display="none";
           console.log(window.screen.width);
         }
         else{
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('http://localhost:8000/blogs' , {
        //     method: 'POST',
        //     headers: {"Content-type": "application/json"},
        //     body:JSON.stringify(blog)
        // }).then(()=>{
        //     console.log('new blog added');
        //     setIspending(false);
        localStorage.setItem('FirstName' , values.FirstName);
        localStorage.setItem('LastName' , values.LastName);
        localStorage.setItem('Phone' , values.Phone);
        localStorage.setItem('Email' , values.Email);
        localStorage.setItem('About' , values.About);
        var stringUrl = window.location.href;
        localStorage.setItem('ReferalCode',stringUrl.split("/regmstep2/")[1]);
        if(localStorage.getItem('ReferalCode')=='undefined')
           localStorage.setItem('ReferalCode','');
           console.log(window.location.href);
        console.log(localStorage.getItem('ReferalCode'));
            history.push('/regmstep3');
        // }
        // )
    }
  }
    //***************************************** */

   // ui materials functions...
        const [values, setValues] = useState({
          FirstName: localStorage.FirstName,
          LastName: localStorage.LastName,
          Phone: localStorage.Phone,
          Email: localStorage.Email,
          About: localStorage.About,
          myDate: localStorage.FirstName,
          showPassword: false,
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });

          if(document.activeElement.id=='inputFirstName')
          {
            document.getElementById('MyFirstNameplaceholder').style.color="transparent";
          }
           if(values.FirstName!="")
          {
            document.getElementById('inputFirstName').style.color="#313131";
          }
          
          if(document.activeElement.id=='inputPhone')
          {
            document.getElementById('MyPhoneplaceholder').style.color="transparent";
          }
           if(values.Phone!="")
          {
            document.getElementById('inputPhone').style.color="#313131";
          }
          
          // if(document.activeElement.id=='inputDate')
          // {
          //   document.getElementById('MyDatelaceholder').style.color="transparent";
          // }
          //  if(values.myDate!="")
          // {
          //   document.getElementById('inputDate').style.color="#313131";
          // }
          
          if(document.activeElement.id=='inputLastName')
          {
            document.getElementById('MyLastNameplaceholder').style.color="transparent";
          }
           if(values.LastName!="")
          {
            document.getElementById('inputLastName').style.color="#313131";
          }
          
          if(document.activeElement.id=='inputEmail')
          {
            document.getElementById('MyEmailplaceholder').style.color="transparent";
            localStorage.Email= document.getElementById('inputEmail').value;
          }
           if(values.Email!="")
          {
            document.getElementById('inputEmail').style.color="#313131";
          }
          
          if(document.activeElement.id=='inputAbout')
          {
            document.getElementById('MyAboutplaceholder').style.color="transparent";
          }
           if(values.About!="")
          {
            document.getElementById('inputAbout').style.color="#313131";
          }
        };
      
   
       

// end of ui material functions
const [progress, setProgress] = useState(20);

useEffect(() => {
  const timer = setInterval(() => {
    setProgress((prevProgress) => (prevProgress >= 20 ? 21 : prevProgress + 20));
  }, 10);

  return () => {
    clearInterval(timer);
  };
}, []); 

//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);
const  handelIR = (e) => {
    e.preventDefault();
    i18n.changeLanguage('fa_IR');
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.body.setAttribute('lang', 'fa');
    document.getElementById('main-row-2id').style.direction="rtl";
}
const  handelUS = (e) => {
    e.preventDefault();
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    // document.getElementsByClassName('main-row').style.direction="ltr";
    document.getElementById('main-row-2id').style.direction="ltr";
}

const handleinputEmailBlur = (e) =>{
  if((!values.Email.match("^([a-zA-Z0-9_\.\-]{2,})+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$"))&
  !(document.activeElement.id=='inputEmail')){
    setHelpEmail('Envalid Email Format');
    setFormError(true);
  }
    else
    {
    setHelpEmail('');
    setFormError(false);
    localStorage.Email = document.getElementById('inputEmail').value;
    }
   if(!(document.getElementById('inputEmail').value=="")){
    document.getElementById('errorLabelEmail').style.display="flex";
    document.getElementById('MyEmailplaceholder').style.color="transparent";
    document.getElementById('MyEmailSapn').style.color="transparent";
   }
   else{
    document.getElementById('errorLabelEmail').style.display="none";
    document.getElementById('MyEmailplaceholder').style.color="gray";
    document.getElementById('MyEmailSapn').style.color="red";
   }
 }

 
 const handleinputFirstNameBlur = (e) =>{
   if(!(document.getElementById('inputFirstName').value=="")){
    document.getElementById('errorLabelFirstName').style.display="flex";
    document.getElementById('MyFirstNameplaceholder').style.color="transparent";
    document.getElementById('MyFirstNameSapn').style.color="transparent";
   }
   else{
    document.getElementById('errorLabelFirstName').style.display="none";
    document.getElementById('MyFirstNameplaceholder').style.color="gray";
    document.getElementById('MyFirstNameSapn').style.color="red";
   }
 }

 const handleinputLastNameBlur = (e) =>{
  if(!(document.getElementById('inputLastName').value=="")){
   document.getElementById('errorLabelLastName').style.display="flex";
   document.getElementById('MyLastNameplaceholder').style.color="transparent";
   document.getElementById('MyLastNameSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelLastName').style.display="none";
   document.getElementById('MyLastNameplaceholder').style.color="gray";
   document.getElementById('MyLastNameSapn').style.color="red";
  }
}

const handleinputPhoneBlur = (e) =>{
  if((document.getElementById('inputPhone').value!="" && window.screen.width>600)){
    document.getElementById('errorLabelPhone').style.display="flex";
    document.getElementById('MyPhoneplaceholder').style.color="transparent";
    document.getElementById('MyPhoneSapn').style.color="transparent";
   }
   else{
    document.getElementById('errorLabelPhone').style.display="none";
    document.getElementById('MyPhoneplaceholder').style.color="gray";
    document.getElementById('MyPhoneSapn').style.color="red";
   }
  //  if((document.getElementById('inputPhoneM').value!="" && window.screen.width<600)){
  //    document.getElementById('errorLabelPhoneM').style.display="flex";
  //    document.getElementById('MyPhoneplaceholderM').style.color="transparent";
  //    document.getElementById('MyPhoneSapnM').style.color="transparent";
  //   }
  //   else{
  //    document.getElementById('errorLabelPhoneM').style.display="none";
  //    document.getElementById('MyPhoneplaceholderM').style.color="gray";
  //    document.getElementById('MyPhoneSapnM').style.color="red";
  //   }
}

const handleinputAboutBlur = (e) =>{
  if(!(document.getElementById('inputAbout').value=="")){
   document.getElementById('errorLabelAbout').style.display="flex";
   document.getElementById('MyAboutplaceholder').style.color="transparent";
   document.getElementById('MyAboutSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelAbout').style.display="none";
   document.getElementById('MyAboutplaceholder').style.color="gray";
   document.getElementById('MyAboutSapn').style.color="red";
  }
}

const hendaleMouseOverFirstName = (e) =>{
  if(document.getElementById('inputFirstName').value=="")
   document.getElementById('MyFirstNameplaceholder').style.color="#313131";
   document.getElementById('inputFirstName').style.borderColor="#313131";
}

const hendaleMouseLeaveFirstName = (e) =>{
  if(document.getElementById('inputFirstName').value=="")
  document.getElementById('MyFirstNameplaceholder').style.color="white";
  document.getElementById('inputFirstName').style.borderColor="#31313131";
}

const hendaleMouseOverPhone = (e) =>{
  if(document.getElementById('inputPhone').value=="")
  document.getElementById('MyPhoneplaceholder').style.color="#313131";
  document.getElementById('inputPhone').style.borderColor="#313131";
}

const hendaleMouseLeavePhone = (e) =>{
  if(document.getElementById('inputPhone').value=="")
 document.getElementById('MyPhoneplaceholder').style.color="white";
 document.getElementById('inputPhone').style.borderColor="#31313131";
}

const hendaleMouseOverAbout = (e) =>{
  if(document.getElementById('inputAbout').value=="")
  document.getElementById('MyAboutplaceholder').style.color="#313131";
  document.getElementById('inputAbout').style.borderColor="#313131";
}

const hendaleMouseLeaveAbout = (e) =>{
  if(document.getElementById('inputAbout').value=="")
 document.getElementById('MyAboutplaceholder').style.color="white";
 document.getElementById('inputAbout').style.borderColor="#31313131";
}

const hendaleMouseOverLastName = (e) =>{
  if(document.getElementById('inputLastName').value=="")
  document.getElementById('MyLastNameplaceholder').style.color="#313131";
  document.getElementById('inputLastName').style.borderColor="#313131";
}

const hendaleMouseLeaveLastName = (e) =>{
  if(document.getElementById('inputLastName').value=="")
 document.getElementById('MyLastNameplaceholder').style.color="white";
 document.getElementById('inputLastName').style.borderColor="#31313131";
}

const hendaleMouseOverEmail = (e) =>{
  if(document.getElementById('inputEmail').value=="")
  document.getElementById('MyEmailplaceholder').style.color="#313131";
  document.getElementById('inputEmail').style.borderColor="#313131";
}

const hendaleMouseLeaveEmail = (e) =>{
  if(document.getElementById('inputEmail').value=="")
 document.getElementById('MyEmailplaceholder').style.color="white";
 document.getElementById('inputEmail').style.borderColor="#31313131";
}
//***************************************/
    return (
      <><div className="pagePic"></div>
        <div className="loginMainRow form_regisster">
          <div className="rectangle sectionsLogin col-xxl-8 col-xl-9 col-12" id="main-row-2id">
            <div className="mainRegisterheader col-xxl-12 col-12">
            <div className="NewHeaderWithHomeIcone col-xxl-12 col-12">
                  <div className="subRegisterrightheader col-md-8 col-12">
                  <Link className="MyHomeNewReg d-md-flex d-none" to='/'>
                        <Home2 color="white" size="32" />
                    </Link>
                    <br/>
                    <h4 className="card-title font-face-gm">{t('Sign Up To Telesiness')+ ' : ' + t('As Agent')}</h4>
                    <p className="RegisterHeaderp">{t('Access your specific features by selecting your user type.')}</p>
                  </div>
                  <div className="subRegisterleftheader col-md-4 col-12">
                    <img src={RegisterLogo} alt="" />
                    <Link className="font-face-gm backtohome ico_backhome" to='/'>
                        <Home2 color="white" size="24" />
                    </Link>
                  </div>
                  </div>
            </div>
            <div className="progressRow col-xxl-10 col-12">
              <div className="myshapes d-none d-sm-flex">
                <div className="mycircle">
                  <h6>{t('2 of 3')}</h6> 
                </div>
                <div className="myProCirlce2"></div>
              </div>
              <div className="mytext">
                <h5 className="step1">{t('Step 2')}</h5>
                <h6 className="choosText">{t('Complete your Personal information')}</h6>
                {/* {document.getElementsByTagName('html')[0].getAttribute("dir")== "ltr" && <label className="d-flex d-sm-none" id="errorLabelRegs"><InfoCircle  size="18"  color="#ff0000" /> {t('Please enter Your Information in Persian only.')} </label>} */}
              </div>
            </div>
            <div className="myForm col-xxl-9 col-xl-10 col-lg-11 col-12">
              <form onSubmit={handleSubmit} className="col-12" noValidate>
                <div className="input-placeholder col-md-6 col-12">
                    <input 
                      value = {values.FirstName}
                      type="text"
                      className="form-control"
                      id="inputFirstName"
                      onBlur={handleinputFirstNameBlur}
                      onChange={handleChange('FirstName')}
                      onMouseOver={hendaleMouseOverFirstName}
                      onMouseLeave={hendaleMouseLeaveFirstName}
                      required 
                      tabIndex={1}
                      // autocomplete="off"
                      />
                    {localStorage.FirstName==''&&<div id="MyFirstNameplaceholder" className="placeholder">
                    {t('First Name')}<span id="MyFirstNameSapn"></span>
                    </div>}
                    {localStorage.FirstName!=''&&<div id="MyFirstNameplaceholder" className="placeholder">
                    {t('')}<span id="MyFirstNameSapn"></span>
                    </div>}
                    <label id="errorLabelFirstName">{helpFirstName}</label>
                </div>
                <div className="input-placeholder col-md-6 col-12">
                  <input 
                    value = {values.LastName}
                    type="text"
                    className="form-control"
                    id="inputLastName"
                    onBlur={handleinputLastNameBlur}
                    onChange={handleChange('LastName')}
                    onMouseOver={hendaleMouseOverLastName}
                    onMouseLeave={hendaleMouseLeaveLastName}
                    required 
                    tabIndex={2}
                    //autocomplete="off"
                    />
                  {localStorage.LastName==''&&<div id="MyLastNameplaceholder" className="placeholder">
                  {t('Last Name')}<span id="MyLastNameSapn"></span>
                  </div>}
                  {localStorage.LastName!=''&&<div id="MyLastNameplaceholder" className="placeholder">
                  {t('')}<span id="MyLastNameSapn"></span>
                  </div>}
                  <label id="errorLabelLastName">{helpLastName}</label>
                </div>
                <div className="input-placeholder col-md-6 col-12">
                    <input 
                      value = {values.Phone}
                      type="number"
                      className="form-control"
                      id="inputPhone"
                      onBlur={handleinputPhoneBlur}
                      onChange={handleChange('Phone')}
                      onMouseOver={hendaleMouseOverPhone}
                      onMouseLeave={hendaleMouseLeavePhone}
                      required 
                      tabIndex={3}
                      //autocomplete="off"
                      />
                    {localStorage.Phone==''&&<div id="MyPhoneplaceholder" className="placeholder">
                    {t('Phone Number')}<span id="MyPhoneSapn"></span>
                    </div>}
                    {localStorage.Phone!=''&&<div id="MyPhoneplaceholder" className="placeholder">
                    {t('')}<span id="MyPhoneSapn"></span>
                    </div>}
                    <label id="errorLabelPhone">{helpPhone}</label>
                </div>
                <div className="input-placeholder col-md-6 col-12">
                  <input 
                    value = {values.Email}
                    type="text"
                    className="form-control"
                    id="inputEmail"
                    onBlur={handleinputEmailBlur}
                    onChange={handleChange('Email')}
                    onMouseOver={hendaleMouseOverEmail}
                    onMouseLeave={hendaleMouseLeaveEmail}
                    required 
                    tabIndex={4}
                    style={{paddingInlineStart:20}}
                    // autocomplete="off"
                    />
                  {localStorage.Email==''&&<div id="MyEmailplaceholder" className="placeholder">
                  {t('Email')}<span id="MyEmailSapn"></span>
                  </div>}
                  {localStorage.Email!=''&&<div id="MyEmailplaceholder" className="placeholder">
                  {t('')}<span id="MyEmailSapn"></span>
                  </div>}
                  <label id="errorLabelEmail">{helpEmail}</label>
                </div>
                <div className="input-placeholder col-12">
                  <textarea 
                    value = {values.About}
                    type="text"
                    className="form-control"
                    id="inputAbout"
                    onBlur={handleinputAboutBlur}
                    onChange={handleChange('About')}
                    onMouseOver={hendaleMouseOverAbout}
                    onMouseLeave={hendaleMouseLeaveAbout}
                    tabIndex={5}
                    // autocomplete="off"
                    />
                  {localStorage.About==''&&<div id="MyAboutplaceholder" className="placeholder">
                  {t('About')}<span id="MyAboutSapn"></span>
                  </div>}
                  {localStorage.About!=''&&<div id="MyAboutplaceholder" className="placeholder">
                  {t('')}<span id="MyAboutSapn"></span>
                  </div>}
                  <label id="errorLabelAbout">{}</label>
                </div>
                <div className="NextStep col-12">
                  <Link to="/register" className="col-12 btn btn-outline-light signup-btn d-none d-sm-block">{t('Back')}</Link>  
                  {!ispending && !formError && <button className="btn btn-dark login-btn">{t('Next Step')}</button>} 
                  {formError && <button className="btn btn-dark login-btn" disabled>{t('Next Step')}</button>} 
                  {ispending && <button className="btn btn-dark login-btn " disabled>{t('Being login...')}</button>} 
                </div>
              </form>
            </div>
          </div>
            
        </div>
      </>   
    );
}
 
export default ResetPassword;