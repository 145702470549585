export default {
    fa_IR: {
      Login: {
        'Sign In to Telesiness': 'ورود به پنل کاربری تلزینس',
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.':'لورم ایپسوم متن سـاختگی با تولید سادگی نـامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        'Email':'پست الکترونیک',
        'Password':'رمز عبور',
        'ConfirmPassword':'تکرار رمز عبور جدید...',
        'Remember me':'مرا به خاطر بسپار',
        'Forgot Password':'رمز عبور خود را فراموش کردید؟',
        'Login':'ورود',
        'or':'یا',
        'Sing Up Now':'ثبت نام کنید',
        'Services':'خدمات',
        'About':'درباره ما',
        'Collaborations':'همکاری با ما',
        'Terms':'قوانین',
        'Help':'راهنما',
        'Contact Us':'تماس با ما',
        'Reset Your Password': 'بازیابی رمز عبور',
        'Send Link':'ارسال لینک',
        '< Back to Home page':' < بازگشت به صفحه اصلی',
        'Your messages: {{message_count}}': 'تعداد پیام‌های شما: {{message_count}}',
        'Submit':'ثبت تغییرات',
        'You are all set':'تغییرات ثبت گردید! ',
        'Login now':'وارد شوید',
        'New Password':'رمز عبور جدید',
        'Confirm New Password':'تکرار رمز عبور جدید',
        'Sign Up To Telesiness':'ثبت نام در پنل کاری تلزینس',
        'Step 1':'مرحله یک',
        'Step 2':'مرحله دوم',
        'Step 3':'مرحله سوم',
        'Step 4':'مرحله چهارم',
        'Step 5':'مرحله پنجم',
        'Choose Your Account Type':'نوع اکانت خود را انتخاب کنید',
        '1 of 5':'1 از 5',
        '2 of 5':'2 از 5',
        '3 of 5':'3 از 5',
        '4 of 5':'4 از 5',
        '5 of 5':'5 از 5',
        'Lawyer':'وکیل',
        'Agent':'نماینده',
        'Customer':'مشتری',
        'FirstName':'نام...',
        'LastName':'نام خانوادگی...',
        'Phone Number...':'تلفن همراه ...',
        'Date of Birth:':'تاریخ تولد:',
        'about':'درباره ما...',
        'Day':'روز',
        'Month':'ماه',
        'Year':'سال',
        'Next Step':'مرحله بعد',
        'Back':'برگشت',
        'January':'فروردین',
        'February':'اردیبشهت',
        'March':'خرداد',
        'April':'تیر',
        'May':'مرداد',
        'June':'شهریور',
        'July':'مهر',
        'August':'آبان',
        'September':'آذر',
        'October':'دی',
        'November':'بهمن',
        'December':'اسفند',
        'Field of Study':'رشته تحصیلی',
        'Institute/University':'آموزشگاه/دانشگاه',
        'Level/Degree of education':'مدرک تحصیلی',
        'Less than a high school diploma':'زیردیپلم',
        'High school diploma':'دیپلم متوسطه',
        'Some college, no degree':'تحصیلات دانشگاهی بدون مدرک',
        'Associate degree':'کاردانی/فوق دیپلم',
        'Bachelor degree':'لیسانس/کارشناسی',
        'Master Degree':'فوق لیسانس/کارشناسی ارشد',
        'Profissional degree':'درجه حرفه ای',
        'Doctoral degree':'دکتری',
        'Country':'کشور',
        'City':'شهر',
        'Iran':'ایران',
        'England':'انگلستان',
        'Turkey':'ترکیه',
        'UAE':'امارات متحد عربی',
        'USA':'آیالت متحده آمریکا',
        'Canada':'کانادا',
        'Paris':'پاریس',
        'Tehran':'تـــــهران',
        'California':'کالیفرنیا',
        'Toronto':'تورنتو',
        'Istanbul':'استانبول',
        'Job specialty...':'زمینه کاری...',
        'Specialization in immigration...':'زمینه ی تخصصی در امور مهاجرت...',
        'Law society number/ICCRC Code':'کد وکالت',
        'Personal or company website...':'آدرس وبسایت شخصی یا کاری...',
        'Uplod your Resume/CV':'رزومه ی خود را بارگذاری کنید',
        'Choose Your Password':'رمز عبور تعریف کنید',
        'Enter your username and password below to log in And have access to a world of immigration facilities':'نام کاربری و رمز عبور خود را در کادر زیر وارد کنید تا به تلزینس وارد شوید و به دنیایی از امکانات مهاجرت دسترسی داشته باشید',
        'Enter your email address below and we will send you a link to reset your password.':'آدرس پست الکترونیکی که با آن در تلزینس ثبت نام کردید را برای دریافت لینک بازیابی رمزعبور در کادر زیر وارد کنید',
        'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.':'رمز عبور باید حداقل 8 کاراکتر باشد. به یاد داشته باشید که استفاده مجدد از نام کاربری و رمز عبور برای خدمات و وب سایت های مختلف توصیه نمی شود',
        'Your password has been successfully changed. You can now log in with your new password.':'رمز عبور شما با موفقیت تغییر یافت. اکنون می توانید با رمز عبور جدید وارد شوید',
        'Access your specific features by selecting your user type.':'با انتخاب نوع کاربری صحیح به امکانات اختصاصی خود دسترسی داشته باشید',
        'Please help us get to know you better by completing your personal information.':'با تکمیل اطلاعات شخصی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Please help us get to know you better by completing your educational information.':'با تکمیل اطلاعات تحصیلی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Please help us get to know you better by completing your job information.':'با تکمیل اطلاعات شغلی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.':'برای ورود به پروفایل خود نام کاربری و رمز عبور مناسب انتخاب کنید تا بتوانید به راحتی به داشبورد تلزینس دسترسی داشته باشید',
        'Your registration was successful. You already have access to a variety of immigration opportunities.':'ثبت نام شما با موفقیت انجام شد. شما از الان به دنیایی از امکانات مهاجرتی دسترسی دارید',
        'Go to main page':'برو به صفحه اصلی',
        'Dear user, your information has been successfully registered in our database':'کاربر گرامی اطلاعات شما با موفقیت در بانک اطلاعاتی ما ثبت گردید',
        'Our experts will contact you as soon as possible after review.':'کارشناسان ما پس از بررسی در اسرع وقت با شما تماس خواهند گرفت',
        'Certificate':'گواهی نامه ',
      }
    },
    // add other language to the object
  };