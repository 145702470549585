import { useState } from "react";
import { useHistory } from "react-router";
import {TickSquare,Global} from 'iconsax-react';
import { Link } from "react-router-dom";
// import {BrowserRouter as Router , Route, Switch} from 'react-router-dom'
import Logingroup from './images/Banner02.png';// right city image
import { useTranslation } from 'react-i18next'; // multi language package
import ReactCountryFlag from "react-country-flag"; // flag of country

const ResetPassword = () => {
  const [username, setUsername] = useState();
  const [helpEmail,setHelpEmail] = useState('');
  const [body, setBody] = useState();
  const [author, setAuthor] = useState('kafa');
  const [ispending, setIspending] = useState(false);
  const history = useHistory();
  const handleSubmit = (e) => {
      e.preventDefault();
      setIspending(true);
      const blog = { username, body , author};
      fetch('http://localhost:8000/blogs' , {
          method: 'POST',
          headers: {"Content-type": "application/json"},
          body:JSON.stringify(blog)
      }).then(()=>{
          console.log('new blog added');
          setIspending(false);
          history.push('/');
      }
      )
  }
  //***************************************** */
  const [boldText,setBoldText] = useState('Please wait...');
  const [notBoldtext,setNotBoldtext] = useState('Your email is checking please wait for seconds...');
  var currentUrl = window.location.href;
  //var currentUrl = "https://telesiness.ir/confirmEmail/3ce3f67c46334ba18877771c5c1cd553";
  var codeActive = currentUrl.slice(35, currentUrl.length);
  console.log(codeActive);
  console.log(currentUrl);
  fetch('https://telesiness.ir/api/ConfirmUsersEmailAddress/ConfirmEmail/' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ActiveCode: codeActive,
     })
    }).then(response => response.json()).then(response=>{
        if(response.code==400)
        {
          setBoldText(t('Something Wrong'));
          setNotBoldtext(t('Your Email HAS NOT BEEN confirmed. Please check your email again.'));
        }
        else if(response.statusCode==200){
          // setBoldText(t('Has been confirmed'));
          // setNotBoldtext(t('Your Email has been successfully confirmed. You can now log in with your Email.'));
          // console.log(codeActive);
           history.push('/');
        }
      })
    
//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);
const  handelIR = (e) => {
  e.preventDefault();
  i18n.changeLanguage('fa_IR');
  document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
  document.body.setAttribute('lang', 'fa');
}
const  handelUS = (e) => {
  e.preventDefault();
  i18n.changeLanguage('en_US');
  document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
  document.body.setAttribute('lang', 'en');
}

const handleClickGlobal = (e) => {

  document.getElementById('GlobalBtn1').style.display="none";
  document.getElementById('GlobalBtn2').style.display="flex";
}
const handleMouseLeaveGlobal = (e) => {
document.getElementById('GlobalBtn1').style.display="flex";
document.getElementById('GlobalBtn2').style.display="none";

}
//***************************************/
    return (
      <><div className="pagePic"></div>
        <div className="loginMainRow form_regisster">
          <div className="rectangle sectionsLogin col-xxl-8 col-xl-8 col-12">
            <div className="section leftSection col-xl-6 col-12">
              <div className="leftSectionTopGrup col-xl-11 col-lg-7 col-md-9 col-12">
                <h4 className="myBoldFont">
                  <TickSquare size="32" color="#ffffff" variant="Bold"/>
                  {boldText}
                </h4>
                <p className="LoginSomeText">{notBoldtext}</p>
                <form onSubmit={handleSubmit}>
                
                <br />
                {boldText == 'Has been confirmed' && <Link to="/" className="btn btn-dark login-btn">{t('Login now')}</Link>}
              
                </form>
              </div>
            </div>
            <div className="section rightSection col-xl-6">
              <img className="col-12" src={Logingroup} alt="" />
            </div>
            <div className="rectangle_bottom col-12">
              <div className="about-us">
                <div className="LangBox" id="GlobalBtn2" onMouseLeave={handleMouseLeaveGlobal}>
                  <a href="" onClick={handelIR} id="fa" className="handleIR">Fa</a> <hr />
                  <a href="" onClick={handelUS} id="en" className="handleUS">En</a><Global variant="Bold" color="#313131" size="24"/>
                </div>
                <div className="spanAbout">
                  <p> 
                    <Link to="/services"> <span>{t('Services')}</span> <span>|</span> <span>{t('About')}</span> <span>|</span>
                    <span> {t('Collaborations')}</span> <span>|</span> <span>{t('Terms')}</span> <span>|</span>
                    <span> {t('Help')}</span> <span>|</span> <span>{t('Contact Us')}</span> </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footerDasilav col-12">
            <div className="footer-copy">
              Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
            </div>
            <div className="footer-copy2">
              <br />
              Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
            </div>
          </div>
        </div>
         </>
    );
}
 
export default ResetPassword;