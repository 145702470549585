import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,CloseCircle} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const Profile2 = ({childToParentCus6,childToParentCus8}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    const forceUpdate = useForceUpdate();
    //****************submits**********************************/
    const [cusJobs , setCusJobs] = useState([
      {Destinationcountry: 'Dubai', Entrydate: '2021/09/05', Departuredate: '2021/09/10', Purposeofforeigntravel:'fun', key:'1'},
      {Destinationcountry: 'Dubai', Entrydate: '2021/09/05', Departuredate: '2021/09/10', Purposeofforeigntravel:'fun', key:'2'},
      {Destinationcountry: 'Dubai', Entrydate: '2021/09/05', Departuredate: '2021/09/10', Purposeofforeigntravel:'fun', key:'3'},
      {Destinationcountry: 'Dubai', Entrydate: '2021/09/05', Departuredate: '2021/09/10', Purposeofforeigntravel:'fun', key:'4'},
    ]);
  const handleAddJob =(e) => {
    e.preventDefault();
    cusJobs.push({ 
      Destinationcountry: values.Destinationcountry,
      Entrydate: values.Entrydate,
      Departuredate: values.Departuredate,
      Purposeofforeigntravel:values.Purposeofforeigntravel,
      key:(cusJobs.length+1).toString(),
     },);
      forceUpdate();
      setCusJobs(cusJobs);
  }
  const handleRemoveCusJobs = (itemKey)=>{
    setCusJobs(cusJobs.filter(cusJobs => cusJobs.key != itemKey.toString()));
  }
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepOne' , {
        //   method: 'PUT',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ 
        //     // Email: localStorage.email3 ,
        //     // Password:localStorage.password3 ,
        //     FullName:"",
        //     Gender:"",
        //     DateOfBirth:"",
        //     AboutMe:""
        //   })
        //   }).then().then(history.push('/Dashboard'));
        // //     setIspending(false);
        // document.getElementById('personalCancel').style.display="none";
        // document.getElementById('personalSave').style.display="none";
        // document.getElementById('personPen').style.display="none";
        // document.getElementById('personPenBlue').style.display="none";
        // document.getElementById('proFirstName').setAttribute("disabled","disabled");
        // document.getElementById('proLastName').setAttribute("disabled","disabled");
        // document.getElementById('Gender').setAttribute("disabled","disabled");
        // //document.getElementById('birthDate').setAttribute("disabled","disabled");
     
        // document.getElementById('inputproAbout').setAttribute("disabled","disabled");
        // setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }

        const [values, setValues] = useState({
 
          Destinationcountry: '',
          Entrydate: '',
          Departuredate: '',
          Purposeofforeigntravel:'',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });

        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus8 = true;
        const dataCus6 = true;
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <form onSubmit={handleSubmitPersonal} className="col-12">
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">Step 1 :</p><p className="Cus2Step1BlackP">Complete Basic Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div> <p className="Cus2Step1SmallP">Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy</p></div>
          <div className="Cus2PersonalInfo"> <p>Foreign Travel Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div className="Cus2Inputs">
                        <div className="mainGrayListRows col-12">
              {cusJobs.map(item =>(
                      <div key={item.key}>
                        <div className="grayListRow col-12">
                          <div className="GrayListBox">
                            <p><b>{item.Destinationcountry}</b></p>
                            <CloseCircle onClick={()=>handleRemoveCusJobs(item.key)} size="28" color="#9B9B9B" variant="Bold"/>
                          </div>
                        </div>
                      </div>))}
            </div>
           
            <div className="inputAndlabel col-4">
            <input
                id="Destinationcountry" 
                value={values.Destinationcountry}
                type="text"
                placeholder="Destination country"
                onChange={handleChange('Destinationcountry')}
                />
                <label htmlFor="Destinationcountry">Enter destination country in last 8 years</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Entrydate" 
                value={values.Entrydate}
                type="text"
                placeholder="Entry date"
                onChange={handleChange('Entrydate')}
                
                />
                <label  htmlFor="Entrydate">The arrival date of foreign travel in the last 8 years</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Departuredate" 
                value={values.Departuredate}
                type="text"
                placeholder="Departure date"
                onChange={handleChange('Departuredate')}
                
                />
                <label  htmlFor="Departuredate">Date of departure of foreign travel in the last 8 years</label>
            </div>
            <div className="inputAndlabel col-8">
              <textarea
                id="Purposeofforeigntravel" 
                value={values.Purposeofforeigntravel}
                type="text"
                placeholder="Purpose of foreign travel"
                onChange={handleChange('Purposeofforeigntravel')}
                />
                <label htmlFor="Purposeofforeigntravel">Explain the purpose of the foreign trip</label>
            </div>
            <div className="inputAndlabel col-4">
              <a className="inputAndlabelAddA" onClick={handleAddJob}>+ Add</a>
            </div>

            

         </div>
         
          <div className="cusS1SaveColP7 col-12">
            <div className="col-6">
               <button  onClick={(dataCus6) => {childToParentCus6(dataCus6);}} className="btn col-12 btn-outline-light ">{t('Previous')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              <button className="btn col-6 btn-outline-light ">
                <ArchiveTick size="32" color="#D3A281"/>{t('Save Draft')}
              </button >
              {<button onClick={(dataCus8) => {childToParentCus8(dataCus8);}} className="btn btn-dark  col-6">{t('Next Step')}</button>}
            </div>
           
          </div>
        </div>
        </form>
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;