import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import Loginpic from './images/login2.png';// blured Background
import logotele from './images/logo-tele.png';// telesiness logo
import RegisterLogo from './images/LogoRegister.png'
import { ArrowLeft2, Home2, InfoCircle} from 'iconsax-react';
import Logo from './images/logo-tele.png';

//*******************end of UI Materials */

import { useTranslation } from 'react-i18next'; // multi language package
import ReactCountryFlag from "react-country-flag"; // flag of country

const ResetPassword = () => {
    //********************************fetching API */
    const [formError , setFormError] = useState(false);
    const [helpCity,setHelpCity] = useState('');
    const [helpJob, setHelpJob] = useState();
    const [helpCountry, setHelpCountry] = useState();
    const [helpiccrc, setHelpiccrc] = useState();
    const [body, setBody] = useState();
    const [Certificate, setCertificate] = useState();
    const [ispending, setIspending] = useState(false);
    const history = useHistory();
    const handleSubmit = (e) => {
        e.preventDefault();
      if(document.getElementById('inputCountry').value=="")
      {
        setHelpCountry(t('please select your Country'));
        document.getElementById("errorLabelCountry").style.display="flex";
        document.getElementById("errorLabeliccrc").style.display="none";
        // document.getElementById("errorLabelCity").style.display="none";
      }
      // else  if(document.getElementById('inputCity').value=="")
      // {
      //   setHelpCity("please select your City");
      //   document.getElementById("errorLabelCity").style.display="flex";
      //   document.getElementById("errorLabeliccrc").style.display="none";
      //   document.getElementById("errorLabelCountry").style.display="none";
      // }
      else  if(document.getElementById('inputiccrc').value=="")
      {
        setHelpiccrc(t('please input your ICCRC'));
        document.getElementById("errorLabeliccrc").style.display="flex";
        // document.getElementById("errorLabelCity").style.display="none";
        document.getElementById("errorLabelCountry").style.display="none";
      }
      else
      {
        localStorage.setItem('Country' , values.Country);
        localStorage.setItem('City' , values.City);
        localStorage.setItem('Job' , values.Job);
        localStorage.setItem('iccrc' , values.iccrc);
        localStorage.setItem('Certificate' , values.Certificate);
        localStorage.setItem('Specialization' , values.Specialization);
        localStorage.setItem('website' , values.website);
        localStorage.setItem('Upload' , values.Upload);
        history.push('/regstep5');
      }
        
            

    }
    //***************************************** */

   // ui materials functions...
        const [values, setValues] = useState({
          Country: localStorage.Country,
          City: localStorage.City,
          Job: localStorage.Job,
          iccrc: localStorage.iccrc,
          Certificate: localStorage.Certificate,
          Specialization:localStorage.Specialization,
          website:localStorage.website,
          Upload:localStorage.Upload,
          showPassword: false,
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          if(!(document.getElementById('inputCountry').value=="")){
            //  document.getElementById('errorLabelDegree').style.display="flex";
             document.getElementById('MyCountryplaceholder').style.color="transparent";
             document.getElementById('MyCountrySapn').style.color="transparent";
            }
            else{
            //  document.getElementById('errorLabelDegree').style.display="none";
             document.getElementById('MyCountryplaceholder').style.color="gray";
             document.getElementById('MyCountrySapn').style.color="red";
            }

            //City
            // if(!(document.getElementById('inputCity').value=="")){
            //   //  document.getElementById('errorLabelDegree').style.display="flex";
            //    document.getElementById('MyCityplaceholder').style.color="transparent";
            //    document.getElementById('MyCitySapn').style.color="transparent";
            //   }
            //   else{
            //   //  document.getElementById('errorLabelDegree').style.display="none";
            //    document.getElementById('MyCityplaceholder').style.color="gray";
            //    document.getElementById('MyCitySapn').style.color="red";
            //   }

              if(document.activeElement.id=='inputJob')
              {
                document.getElementById('MyJobplaceholder').style.color="transparent";
              }
               if(values.Job!="")
              {
                 document.getElementById('inputJob').style.color="#313131";
              }

              
              if(document.activeElement.id=='inputiccrc')
              {
                document.getElementById('Myiccrcplaceholder').style.color="transparent";
              }
               if(values.iccrc!="")
              {
                 document.getElementById('inputiccrc').style.color="#313131";
              }

              
              if(document.activeElement.id=='inputCertificate')
              {
                document.getElementById('MyCertificateplaceholder').style.color="transparent";
              }
               if(values.Certificate!="")
              {
                 document.getElementById('inputCertificate').style.color="#313131";
              }

              
              if(document.activeElement.id=='inputSpecialization')
              {
                document.getElementById('MySpecializationplaceholder').style.color="transparent";
              }
               if(values.Specialization!="")
              {
                 document.getElementById('inputSpecialization').style.color="#313131";
              }

              
              if(document.activeElement.id=='inputwebsite')
              {
                document.getElementById('Mywebsiteplaceholder').style.color="transparent";
              }
               if(values.website!="")
              {
                 document.getElementById('inputwebsite').style.color="#313131";
              }

              
              if(document.activeElement.id=='inputUpload')
              {
                // document.getElementById('MyUploadplaceholder').style.color="transparent";
              }
               if(values.Upload!="")
              {
                //  document.getElementById('inputUpload').style.color="#313131";
              }
        };
      
 
       

// end of ui material functions
const [progress, setProgress] = useState(20);

useEffect(() => {
  const timer = setInterval(() => {
    setProgress((prevProgress) => (prevProgress >= 20 ? 21 : prevProgress + 20));
  }, 10);

  return () => {
    clearInterval(timer);
  };
}, []); 

//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);
const  handelIR = (e) => {
    e.preventDefault();
    i18n.changeLanguage('fa_IR');
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.body.setAttribute('lang', 'fa');
    document.getElementById('main-row-2id').style.direction="rtl";
}
const  handelUS = (e) => {
    e.preventDefault();
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
    // document.getElementsByClassName('main-row').style.direction="ltr";
    document.getElementById('main-row-2id').style.direction="ltr";
}


 
 const handleinputCountryBlur = (e) =>{
   if(!(document.getElementById('inputCountry').value=="")){
    // document.getElementById('errorLabelCountry').style.display="flex";
    document.getElementById('MyCountryplaceholder').style.color="transparent";
    document.getElementById('MyCountrySapn').style.color="transparent";
   }
   else{
    // document.getElementById('errorLabelCountry').style.display="none";
    document.getElementById('MyCountryplaceholder').style.color="gray";
    document.getElementById('MyCountrySapn').style.color="red";
   }
 }

 const handleinputJobBlur = (e) =>{
  if(!(document.getElementById('inputJob').value=="")){
   document.getElementById('errorLabelJob').style.display="flex";
   document.getElementById('MyJobplaceholder').style.color="transparent";
   document.getElementById('MyJobSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelJob').style.display="none";
   document.getElementById('MyJobplaceholder').style.color="gray";
   document.getElementById('MyJobSapn').style.color="red";
  }
}

const handleinputCityBlur = (e) =>{
  if(!(document.getElementById('inputCity').value=="")){
  //  document.getElementById('errorLabelDegree').style.display="flex";
   document.getElementById('MyCityplaceholder').style.color="transparent";
   document.getElementById('MyCitySapn').style.color="transparent";
  }
  else{
  //  document.getElementById('errorLabelDegree').style.display="none";
   document.getElementById('MyCityplaceholder').style.color="gray";
   document.getElementById('MyCitySapn').style.color="red";
  }
}

const handleinputiccrcBlur = (e) =>{
  if(!(document.getElementById('inputiccrc').value=="")){
   document.getElementById('errorLabeliccrc').style.display="flex";
   document.getElementById('Myiccrcplaceholder').style.color="transparent";
   document.getElementById('MyiccrcSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabeliccrc').style.display="none";
   document.getElementById('Myiccrcplaceholder').style.color="gray";
   document.getElementById('MyiccrcSapn').style.color="red";
  }
}

//Certificate
const handleinputCertificateBlur = (e) =>{
  if(!(document.getElementById('inputCertificate').value=="")){
   document.getElementById('errorLabelCertificate').style.display="flex";
   document.getElementById('MyCertificateplaceholder').style.color="transparent";
   document.getElementById('MyCertificateSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelCertificate').style.display="none";
   document.getElementById('MyCertificateplaceholder').style.color="gray";
   document.getElementById('MyCertificateSapn').style.color="red";
  }
}
//Specialization
const handleinputSpecializationBlur = (e) =>{
  if(!(document.getElementById('inputSpecialization').value=="")){
   document.getElementById('errorLabelSpecialization').style.display="flex";
   document.getElementById('MySpecializationplaceholder').style.color="transparent";
   document.getElementById('MySpecializationSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelSpecialization').style.display="none";
   document.getElementById('MySpecializationplaceholder').style.color="gray";
   document.getElementById('MySpecializationSapn').style.color="red";
  }
}
//website
const handleinputwebsiteBlur = (e) =>{
  if(!(document.getElementById('inputwebsite').value=="")){
   document.getElementById('errorLabelwebsite').style.display="flex";
   document.getElementById('Mywebsiteplaceholder').style.color="transparent";
   document.getElementById('MywebsiteSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelwebsite').style.display="none";
   document.getElementById('Mywebsiteplaceholder').style.color="gray";
   document.getElementById('MywebsiteSapn').style.color="red";
  }
}
//Upload
const handleinputUploadBlur = (e) =>{
  if(!(document.getElementById('inputUpload').value=="")){
   document.getElementById('errorLabelUpload').style.display="flex";
  //  document.getElementById('MyUploadplaceholder').style.color="transparent";
   document.getElementById('MyUploadSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelUpload').style.display="none";
   document.getElementById('MyUploadplaceholder').style.color="gray";
   document.getElementById('MyUploadSapn').style.color="red";
  }
}

const hendaleMouseOverSpecialization = (e) =>{
  if(document.getElementById('inputSpecialization').value=="")
  document.getElementById('MySpecializationplaceholder').style.color="#313131";
  document.getElementById('inputSpecialization').style.borderColor="#313131";
}

const hendaleMouseLeaveSpecialization = (e) =>{
  if(document.getElementById('inputSpecialization').value=="")
 document.getElementById('MySpecializationplaceholder').style.color="white";
 document.getElementById('inputSpecialization').style.borderColor="#31313131";
}

const hendaleMouseOverCountry = (e) =>{
  if(document.getElementById('inputCountry').value=="")
  document.getElementById('MyCountryplaceholder').style.color="#313131";
  document.getElementById('inputCountry').style.borderColor="#313131";
}

const hendaleMouseLeaveCountry = (e) =>{
  if(document.getElementById('inputCountry').value=="")
 document.getElementById('MyCountryplaceholder').style.color="white";
 document.getElementById('inputCountry').style.borderColor="#31313131";
}

const hendaleMouseOverCity = (e) =>{
  if(document.getElementById('inputCity').value=="")
  document.getElementById('MyCityplaceholder').style.color="#313131";
  document.getElementById('inputCity').style.borderColor="#313131";
}

const hendaleMouseLeaveCity = (e) =>{
  if(document.getElementById('inputCity').value=="")
 document.getElementById('MyCityplaceholder').style.color="white";
 document.getElementById('inputCity').style.borderColor="#31313131";
}

const hendaleMouseOverJob = (e) =>{
  if(document.getElementById('inputJob').value=="")
  document.getElementById('MyJobplaceholder').style.color="#313131";
  document.getElementById('inputJob').style.borderColor="#313131";
}

const hendaleMouseLeaveJob = (e) =>{
  if(document.getElementById('inputJob').value=="")
 document.getElementById('MyJobplaceholder').style.color="white";
 document.getElementById('inputJob').style.borderColor="#31313131";
}

const hendaleMouseOvericcrc = (e) =>{
  if(document.getElementById('inputiccrc').value=="")
  document.getElementById('Myiccrcplaceholder').style.color="#313131";
  document.getElementById('inputiccrc').style.borderColor="#313131";
}

const hendaleMouseLeaveiccrc = (e) =>{
  if(document.getElementById('inputiccrc').value=="")
 document.getElementById('Myiccrcplaceholder').style.color="white";
 document.getElementById('inputiccrc').style.borderColor="#31313131";
}

const hendaleMouseOverCertificate = (e) =>{
  if(document.getElementById('inputCertificate').value=="")
  document.getElementById('MyCertificateplaceholder').style.color="#313131";
  document.getElementById('inputCertificate').style.borderColor="#313131";
}

const hendaleMouseLeaveCertificate = (e) =>{
  if(document.getElementById('inputCertificate').value=="")
 document.getElementById('MyCertificateplaceholder').style.color="white";
 document.getElementById('inputCertificate').style.borderColor="#31313131";
}

const hendaleMouseOverwebsite = (e) =>{
  if(document.getElementById('inputwebsite').value=="")
  document.getElementById('Mywebsiteplaceholder').style.color="#313131";
  document.getElementById('inputwebsite').style.borderColor="#313131";
}

const hendaleMouseLeavewebsite = (e) =>{
  if(document.getElementById('inputwebsite').value=="")
 document.getElementById('Mywebsiteplaceholder').style.color="white";
 document.getElementById('inputwebsite').style.borderColor="#31313131";
}

const hendaleMouseOverUpload = (e) =>{
  if(document.getElementById('inputUpload').value=="")
  document.getElementById('MyUploadplaceholder').style.color="#313131";
  document.getElementById('inputUpload').style.borderColor="#313131";
}

const hendaleMouseLeaveUpload = (e) =>{
  if(document.getElementById('inputUpload').value=="")
 document.getElementById('MyUploadplaceholder').style.color="white";
 document.getElementById('inputUpload').style.borderColor="#31313131";
}

const [data, setData] = useState([]);

const fetchData = () => {
  fetch("https://telesiness.ir/api/Countries/AllCountery")
    .then((res) => res.json())
    .then((result) => setData(result))
    .catch((err) => console.log("error"));
    
};

useEffect(() => {
  fetchData();
  // console.log(data[0].countery_Name);
}, []);
//***************************************/
    return (
      <><div className="pagePic"></div>
        <div className="loginMainRow form_regisster">
              <div className="rectangle sectionsLogin col-xxl-8 col-xl-9 col-12" id="main-row-2id">
                <div className="mainRegisterheader col-xxl-12 col-12">
                <div className="NewHeaderWithHomeIcone col-xxl-12 col-12">
                  <div className="subRegisterrightheader col-md-8 col-12">
                  <Link className="MyHomeNewReg d-md-flex d-none" to='/'>
                        <Home2 color="white" size="32" />
                    </Link>
                    <br/>
                    <h4 className="card-title font-face-gm">{t('Sign Up To Telesiness')+ ' : ' + t('As Lawyer')}</h4>
                    <p className="RegisterHeaderp">{t('Access your specific features by selecting your user type.')}</p>
                  </div>
                  <div className="subRegisterleftheader col-md-4 col-12">
                    <img src={RegisterLogo} alt="" />
                    <Link className="font-face-gm backtohome ico_backhome" to='/'>
                        <Home2 color="white" size="24" />
                    </Link>
                  </div>
                  </div>
                </div>
                <div className="progressRow col-xxl-10 col-12">
                  <div className="myshapes d-none d-sm-flex">
                    <div className="mycircle">
                      <h6>{t('4 of 5')}</h6> 
                    </div>
                    <div className="myProCirlce4"></div>
                  </div>
                  <div className="mytext">
                    <h5 className="step1">{t('Step 4')}</h5>
                    <h6 className="choosText">{t('Complete your Job information')}</h6>
                    {/* {document.getElementsByTagName('html')[0].getAttribute("dir")== "ltr" && <label className="d-flex d-sm-none" id="errorLabelRegs"><InfoCircle  size="18"  color="#ff0000" /> {t('Please enter Your Information in Persian only.')} </label>} */}
                  </div>
                </div>
                <div className="progressStyle col-xxl-10 col-12">
                </div>
                <div className="myForm col-xxl-9 col-xl-10 col-lg-11 col-12">
                  <form onSubmit={handleSubmit} noValidate>
                        <div className="input-placeholder col-md-6 col-12">
                            <select
                                  required
                                  className="form-select "
                                  aria-label="Default"
                                  // labelId="demo-simple-select-Degree-helper-label"
                                  id="inputCountry"
                                  value={values.Country}
                                  label={t('Country')}
                                  // MenuProps={MenuProps}
                                  onChange={handleChange('Country')}  
                                  onBlur={handleinputCountryBlur} 
                                  onMouseOver={hendaleMouseOverCountry}
                                  onMouseLeave={hendaleMouseLeaveCountry}  
                                  tabIndex={1}
                                  >
                                    <option  value="">
                                  </option >
                                  {data &&
                                    data.map((element) => (
                                      <option  value={element.countery_ID}>{element.countery_Name}</option >
                                  ))}
                                  {/* <option  value="">
                                  </option >
                                  <option  value='1'>{t('Iran')}</option >
                                  <option  value='2'>{t('England')}</option >
                                  <option  value='3'>{t('Turkey')}</option >
                                  <option  value='4'>{t('UAE')}</option >
                                  <option  value='5'>{t('USA')}</option > */}
                              </select >
                              {localStorage.Country==''&&<div id="MyCountryplaceholder" className="placeholder">
                              {t('Country')} <span id="MyCountrySapn"></span>
                            </div>}
                            {localStorage.Country!=''&&<div id="MyCountryplaceholder" className="placeholder">
                              {t('')} <span id="MyCountrySapn"></span>
                            </div>}
                            <label id="errorLabelCountry">{helpCountry}</label>
                        </div>
                        <div className="input-placeholder col-md-6 col-12">
                              <input 
                                value = {values.Job}
                                type="text"
                                className="form-control"
                                id="inputJob"
                                onBlur={handleinputJobBlur}
                                onChange={handleChange('Job')}
                                onMouseOver={hendaleMouseOverJob}
                                onMouseLeave={hendaleMouseLeaveJob}  
                                required
                                tabIndex={3}
                                // autocomplete="off"
                                />
                              {localStorage.Job==''&&<div id="MyJobplaceholder" className="placeholder">
                              {t('Job specialty')}<span id="MyJobSapn"></span>
                              </div>}
                              {localStorage.Job!=''&&<div id="MyJobplaceholder" className="placeholder">
                              {t('')}<span id="MyJobSapn"></span>
                              </div>}
                              <label id="errorLabelJob">{helpJob}</label>
                        </div>
                        <div className="input-placeholder col-md-6 col-12">
                            <input 
                              value = {values.iccrc}
                              type="text"
                              className="form-control"
                              id="inputiccrc"
                              onBlur={handleinputiccrcBlur}
                              onChange={handleChange('iccrc')}
                              onMouseOver={hendaleMouseOvericcrc}
                              onMouseLeave={hendaleMouseLeaveiccrc}  
                              required 
                              tabIndex={5}
                              // autocomplete="off"
                              />
                            {localStorage.iccrc==''&&<div id="Myiccrcplaceholder" className="placeholder">
                            {t('Law society number/ICCRC Code')}<span id="MyiccrcSapn"></span>
                            </div>}
                            {localStorage.iccrc!=''&&<div id="Myiccrcplaceholder" className="placeholder">
                            {t('')}<span id="MyiccrcSapn"></span>
                            </div>}
                            <label id="errorLabeliccrc">{helpiccrc}</label>
                        </div>
                        <div className="input-placeholder col-md-6 col-12">
                            <input 
                              value = {values.Certificate}
                              type="text"
                              className="form-control"
                              id="inputCertificate"
                              onBlur={handleinputCertificateBlur}
                              onChange={handleChange('Certificate')}
                              onMouseOver={hendaleMouseOverCertificate}
                              onMouseLeave={hendaleMouseLeaveCertificate}  
                              required
                              tabIndex={7}
                              // autocomplete="off"
                              />
                              {localStorage.Certificate==''&&<div id="MyCertificateplaceholder" className="placeholder">
                            {t('Certificate')}<span id="MyCertificateSapn"></span>
                            </div>}
                            {localStorage.Certificate!=''&&<div id="MyCertificateplaceholder" className="placeholder">
                            {t('')}<span id="MyCertificateSapn"></span>
                            </div>}
                            <label id="errorLabelCertificate">{helpJob}</label>
                        </div>
                        <div className="input-placeholder col-md-6 col-12">
                              <input 
                                value = {values.Specialization}
                                type="text"
                                className="form-control"
                                id="inputSpecialization"
                                onBlur={handleinputSpecializationBlur}
                                onChange={handleChange('Specialization')}
                                onMouseOver={hendaleMouseOverSpecialization}
                                onMouseLeave={hendaleMouseLeaveSpecialization}
                                required
                                tabIndex={4}
                                // autocomplete="off"
                                />
                                {localStorage.Specialization==''&&<div id="MySpecializationplaceholder" className="placeholder">
                              {t('Specialization in immigration')}<span id="MySpecializationSapn"></span>
                              </div>}
                              {localStorage.Specialization!=''&&<div id="MySpecializationplaceholder" className="placeholder">
                              {t('')}<span id="MySpecializationSapn"></span>
                              </div>}
                              <label id="errorLabelSpecialization">{helpJob}</label>
                        </div>
                        <div className="input-placeholder col-md-6 col-12">
                            <input 
                              value = {values.website}
                              type="text"
                              className="form-control"
                              id="inputwebsite"
                              onBlur={handleinputwebsiteBlur}
                              onChange={handleChange('website')}
                              onMouseOver={hendaleMouseOverwebsite}
                              onMouseLeave={hendaleMouseLeavewebsite}  
                              required
                              tabIndex={6}
                              // autocomplete="off"
                              />
                            {localStorage.website==''&&<div id="Mywebsiteplaceholder" className="placeholder">
                            {t('Personal or company website')}<span id="MywebsiteSapn"></span>
                            </div>}
                            {localStorage.website!=''&&<div id="Mywebsiteplaceholder" className="placeholder">
                            {t('')}<span id="MywebsiteSapn"></span>
                            </div>}
                            <label id="errorLabelwebsite">{helpJob}</label>
                        </div>
                        <div className="NextStep col-12">
                            <Link to="/regstep3" className="btn btn-outline-light signup-btn d-none d-sm-flex">{t('Back')}</Link>  
                            {!ispending && !formError && <button className="btn btn-dark login-btn">{t('Next Step')}</button>} 
                            {formError && <button className="btn btn-dark login-btn" disabled>{t('Next Step')}</button>} 
                            {ispending && <button className="btn btn-dark login-btn " disabled>{t('Being login...')}</button>} 
                        </div>
                  </form>
                </div>
              </div>
              <div className="footerDasilav col-12">
                <div className="footer-copy">
                  Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                </div>
                <div className="footer-copy2">
                  <br />
                  Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
                </div>
              </div> 
        </div>
      </>
   
           
    );
}
 
export default ResetPassword;