import {ArrowUp2,ArrowDown2,ProfileAdd,Filter,Warning2,MessageText1,TickCircle} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Agents = ({childToParentMessage}) => {
    const {t, i18n} = useTranslation(['Login']);

    const [people , setPeople] = useState([
        {AgentPic:'', AgentName:'',AgentPhone:'',AgentStatus:'',AgentClients:' ', Email:'',key:'',suggestedList:[{}],acceptedSuggestedService:[{}]},
       
      ]);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    const handleAccept2 = (a,b,c,d,e,f,h) =>
{
    localStorage.removeItem('a');
    localStorage.removeItem('b');
    localStorage.removeItem('c');
    localStorage.removeItem('d');
    localStorage.removeItem('e');
    localStorage.removeItem('f');
    localStorage.removeItem('h');

    localStorage.setItem('a',a);
    localStorage.setItem('b',b);
    localStorage.setItem('c',c);
    localStorage.setItem('d',d);
    localStorage.setItem('e',e);
    localStorage.setItem('f',f);
    localStorage.setItem('h',h);
togglePopup4();
}
const handleAccept = (a,b,c,d,e,f,h) =>
{
    
    fetch('https://telesiness.ir/api/Dashboards/AcceptOrRejectDistributorOffer/6' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
             Email: localStorage.getItem('email'),
             UserId: parseInt(localStorage.getItem('a')),
             SuggestedServiceOfferCode:localStorage.getItem('b'),
             DistributorServiceStatus:localStorage.getItem('e'),
             SuggestedServiceOfferId:parseInt(localStorage.getItem('h'))
            })
        }).then(response => response.json()).then(response=>{

            try
            {
               
                   // setData(response.distributorList)
            }

           catch{
               console.log('error');
           }
           // console.log(response[0]);
           
       });
       togglePopup4();
        
}
const handleReject2 = (a,b,c,d,e,f,h) =>
{
    localStorage.removeItem('a');
    localStorage.removeItem('b');
    localStorage.removeItem('c');
    localStorage.removeItem('d');
    localStorage.removeItem('e');
    localStorage.removeItem('f');
    localStorage.removeItem('h');

    localStorage.setItem('a',a);
    localStorage.setItem('b',b);
    localStorage.setItem('c',c);
    localStorage.setItem('d',d);
    localStorage.setItem('e',e);
    localStorage.setItem('f',f);
    localStorage.setItem('h',h);
    togglePopup5();
}
const handleReject = (a,b,c,d,e,f,h) =>
{
    fetch('https://telesiness.ir/api/Dashboards/AcceptOrRejectDistributorOffer/6' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            Email: localStorage.getItem('email'),
            UserId: parseInt(localStorage.getItem('a')),
            SuggestedServiceOfferCode:localStorage.getItem('b'),
            DistributorServiceStatus:localStorage.getItem('e'),
            SuggestedServiceOfferId:parseInt(localStorage.getItem('h'))
            })
        }).then(response => response.json()).then(response=>{


        })
        togglePopup5();
}
const handleToggleSubimt = () =>
{
    console.log('test');
    fetch('https://telesiness.ir/api/Dashboards/SubmitRelatedDistributorServiceOffer/5' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            Email: localStorage.getItem('email'),
            UserId:5,
            RelatedDistributorReferralPlusCode:localStorage.getItem('AdvocacyCode'),
            DistributorServiceName:localStorage.getItem('MyOfferToOtherDis'),
            DistributorOffer:localStorage.getItem('MyOffer'),
            DistributorServiceStatus: '0',
        })
        }).then(response => response.json()).then(response=>{
           
             try
             {
                
                    // setData(response.distributorList)
                    values.MyOfferToOtherDis = '';
                    values.MyOffer = '';
                    localStorage.removeItem('AdvocacyCode');
                    localStorage.removeItem('MyOfferToOtherDis');
                    localStorage.removeItem('MyOffer');
                    forceUpdate();
             }

            catch{
                console.log('error');
            }
            
            
        });
        togglePopup();
}

const handleSubmit = (Mcode,Sname,Offer) => {
    
    localStorage.setItem('AdvocacyCode',Mcode);
    localStorage.setItem('MyOfferToOtherDis',Sname);
    localStorage.setItem('MyOffer',Offer);
    togglePopup();
}

    const handleSendMsgReDirect = (CustomerName) => {
        localStorage.setItem('CusNameMsg',CustomerName);
        
    }
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
    const [values, setValues] = useState({
        
        MyOfferToOtherDis:'',
        EmailC:'',
        DCCODE:'',
      });
    
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
       
      };

     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].AgentStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].AgentStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName > b.AgentName) ? 1 : ((b.AgentName > a.AgentName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName < b.AgentName) ? 1 : ((b.AgentName < a.AgentName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (b.AgentClients - a.AgentClients)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.AgentClients-b.AgentClients)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    
    const handleFilterStatusBlur = () =>{ 
        // document.getElementById("FilterStatusBoxID").style.display="none";
        // setFilterBoxShowing(false);
    }

    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/Dashboards/AddRelatedDistributorByDistributorPlus' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                 Email:  localStorage.getItem('email'),
                 AgentCode:localStorage.getItem('agentCode'),
                 RelatedDistributorEmailAddress: values.EmailC,
                })
            }).then().then(setIsOpen2(!isOpen2));
        setIsOpen2(!isOpen2);
    }

    
    const handleAddUserSendRequest = (itemKey) =>{
        setIsOpen3(!isOpen3);
    }

    const [isOpenRow, setisOpenRow] = useState(true);

    const handleAgentRowClick = (itemKey) =>{
        if(isOpenRow){
            document.getElementById(itemKey).style.display="flex";
            setisOpenRow(!isOpenRow);
        }
        else{
            document.getElementById(itemKey).style.display="none";
            setisOpenRow(!isOpenRow);
        }
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.AgentStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.AgentStatus== 'Deactive').length)));
    const [checkedFilter , setCheckedFilter]= useState(false);
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
    }
    const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
    }
    const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    }
    const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
    }

      var Slist = [{}];  
      fetch('https://telesiness.ir/api/Dashboards/RelatedDistributorList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             var j =0;
             try
             {
                
                if(people.length<2)
                {
                    people.pop();
                    var myActDec = ''
                for (i in response.distributorsList){
                    // people[i].CustomerName = response.distributorsList[i].customerFirstName + " " + response.distributorsList[i].customerLastName;
                    // people[i].CustomerCreated = response.distributorsList[i].customerRegisterDate;
                    
                    if(response.distributorsList[i].distributorStatus == false)
                    myActDec = "Deactive";
                    else
                    myActDec = "Active";
                    people.push( {
                    AgentClients:response.distributorsList[i].customerVisaName,
                    AgentPic:'CusPic.png', 
                    AgentName:response.distributorsList[i].distributorFirstName 
                    + " " + 
                    response.distributorsList[i].distributorLastName,
                    AgentPhone:response.distributorsList[i].distributorPhoneNumber,
                    AgentStatus:myActDec,
                    AdvocacyCode:response.distributorsList[i].distributorPlusCode,
                    City:response.distributorsList[i].distributorCity,
                    Country:response.distributorsList[i].distributorCountry,
                    Address:response.distributorsList[i].distributorAddress,
                    Email:response.distributorsList[i].distributorEmailAddress,
                    Clients:response.distributorsList[i].distributorsClient,
                    suggestedList : response.distributorsList[i].suggestedDistributorService,
                    acceptedSuggestedService: response.distributorsList[i].acceptedSuggestedService,
                    userID: response.distributorsList[i].userId,
                    distributorPlusCode:response.distributorsList[i].distributorPlusCode,
                    
                    key:(parseInt(i)+1).toString(),},)
                    
                                 }
                 }
             }

            catch{

            }
            // console.log(response[0]);
            
            for(j in people)
                Slist[j] = people[j].suggestedList[0];
            // console.log(people);
            // console.log(Slist[0].distributorServiceName);
        });

const [data, setData] = useState([]);

const fetchData = () => {
    fetch('https://telesiness.ir/api/Dashboards/GetDistributorServiceList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
            
                try
                {
                
                    setData(response.distributorService)
                }

            catch{

            }
            // console.log(response[0]);
            
        });
};
console.log(localStorage.getItem('email'));
useEffect(() => {
    fetchData();
    // console.log(data[0].countery_Name);
  }, []);
const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
if (counter.current < 2) {
counter.current += 1;
const timer = setTimeout(() => setState({ num: state.num + 1 }), 3000);

return () => clearTimeout(timer);
}
}, [state]);

// const [values, setValues] = useState({

//     LawyerCode: '',
//     EmailC: '',

//   });

//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//   }

    return (  
        <><div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Related Distributers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('Related Distributers List')}</p>
                    </div>
                </div>
               {localStorage.getItem('isDistributorPlus')==1  && <button onClick={handleAddNewUserAgent} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Distributer')}<ProfileAdd size="24" color="#fff" /></button>}
            </div>
            <div className="TopMainRowTwo">
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Distributer')} <a onClick={handleUpArrowAgent}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowAgent}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-2"><p>{t('Phone Number')}</p></div>
                <div className="col-3"><p>{t('Email')}</p></div>
                {/* <div className="col-2 Col-2Status">
                    <p>{t('Status')} </p><div className="FilterIconAgent"><button className="FilterBtn" onBlur={handleFilterStatusBlur} onClick={handleFilterStatus}>{!checkedFilter && <Filter  size="16"  color="#313131"/>}{checkedFilter && <Filter variant="Bold" size="16"  color="#313131"/>}</button></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div> */}
                <div className="col-2"><p>{t('Clients')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div>
                <div className="col-1"><p>{t('Message')}</p></div>
            </div>
            <hr className="CustomerLine"/>
        </div>
        <div className="mainRowCus col-12">
                {people.map(item =>( 
                    <div className="itemBox2 col-12" key={item.key}>
                        <div className="agentCusRow col-12">
                            <a className="col-12 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                                <div className="col-1 id_agentCus"><p>{item.key}</p> </div>
                                <div className="CusPic2 col-lg-3 col-12">
                                    <div className='img_agentCus'>
                                        <img src={`${baseUrl + item.AgentPic}`} alt="" />
                                    </div>
                                    <div className='detail_agentCus'>
                                        <p>{item.AgentName}</p>
                                        <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                                    </div> 
                                </div>
                                <div className="col-2 phone_agentCus"><p>{item.AgentPhone}</p></div>
                                <div className="col-3 mail_agentCus"><p>{item.Email}</p></div>
                                {/* {item.AgentStatus=='Active' && <div className="col-lg-2 col-6 de_active_agentCus">
                                    <button  className="btn-sm active">
                                        {t('Active')}
                                    </button></div>}

                                {item.AgentStatus=='Deactive' && <div className="col-lg-2 col-6 de_active_agentCus">
                                    <button  className="btn-sm deactive">
                                        {t('Deactive')}
                                    </button>
                                    </div>} */}
                                <div className="col-2 count_agentCus"><p>{item.Clients}</p></div>
                                <div className="Send_Message">
                                    <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                                        document.getElementById('MessageText1Btn').style.backgroundColor='white';
                                        document.getElementById('MessageText1BtnP').style.color='black';
                                        document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
                                        document.getElementById('BriefcaseBtnP').style.color='white';
                                        localStorage.setItem('turnOffMeLawCusMsg',true);
                                        localStorage.setItem('MsgUserNewName',item.AgentName);
                                        localStorage.setItem('emailCus',item.Email);
                                        localStorage.setItem('CusCode',item.AdvocacyCode);
                                        localStorage.setItem('MsgUserNewType',2);
                                        childToParentMessage(dataMessage);
                                        handleSendMsgReDirect(item.CustomerName)}}/>
                                    <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                                        {/* <div className='btn btn-dark btn_addLawCus' >{t('Send Message')}</div> */}
                                </div> 
                            </a>
                        </div>  
                        {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                            <div className="hidenItemRow">
                                <hr className="linebetitem col-12"/>
                                <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                                        document.getElementById('MessageText1Btn').style.backgroundColor='white';
                                        document.getElementById('MessageText1BtnP').style.color='black';
                                        document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
                                        document.getElementById('BriefcaseBtnP').style.color='white';
                                        localStorage.setItem('turnOffMeLawCusMsg',true);
                                        localStorage.setItem('MsgUserNewName',item.AgentName);
                                        localStorage.setItem('emailCus',item.Email);
                                        localStorage.setItem('CusCode',item.AdvocacyCode);
                                        localStorage.setItem('MsgUserNewType',2);
                                        childToParentMessage(dataMessage);
                                        handleSendMsgReDirect(item.CustomerName)}}/>
                                <div className="d-lg-none col-12 count_agentCusm hidenItem"><span>{t('Clients')}:</span><p>{item.Clients}</p></div>
                                <div className="d-lg-none col-12 phone_agentCusm hidenItem"><span>{t('Phone Number')}:</span><p>{item.AgentPhone}</p></div>
                                <div className="d-lg-none col-12 mail_agentCusm hidenItem"><span>{t('Email')}:</span><p>{item.Email}</p></div>
                                <div className='SuggestedServices diffcssdiv hidenItem col-12'>
                                <div className="col-12 colm_contentlist">
                                    <span>{t('Suggested Services')}:</span> 
                                    <div className='DisDisService col-12'> 
                                        <div className='myDisDisServiceToAccept'>

                                        {item.suggestedList.map(element2 =>( <div className='myDisDisServiceToAccept col-12' key={element2.key}>
                                        
                                        {element2.distributorServiceStatus==0 &&<div className='txt_SugServices col-lg-6 col-12'>
                                                <span>{element2.distributorServiceName} + {t('offer you')} <b>{element2.distributorOffer}% </b></span>
                                            </div>}
                                            {element2.distributorServiceStatus==0 &&<div className='btns_SugServices col-lg-6 col-12'> 
                                                <button onClick={() => {
                                                    handleAccept2(
                                                        element2.userId,
                                                        element2.suggestedServiceOfferCode,
                                                        element2.distributorServiceName,
                                                        element2.distributorOffer,
                                                        
                                                        '1',
                                                        element2.distributorPlusCode,
                                                        element2.suggestedServiceOfferId,
                                                        element2.submitDate,
                                                    );}} className='btn btn-success myDisSubmitService'>{t('Accept')}</button> 
                                                <button onClick={() => {handleReject2(
                                                     element2.userId,
                                                     element2.suggestedServiceOfferCode,
                                                     element2.distributorServiceName,
                                                     element2.distributorOffer,
                                                     
                                                     '2',
                                                     element2.distributorPlusCode,
                                                     element2.suggestedServiceOfferId,
                                                )}} className="btn btn-danger myDisSubmitService">{t('Rejected')}</button>
                                            </div> }
                                            
                                        </div>))}
                                    
                                        </div>
                                        {item.acceptedSuggestedService.map(element2 =>( <div key={element2.key}>
                                        {element2.distributorServiceStatus==1 && <span>{element2.distributorServiceName} <TickCircle size="18" color="#37d67a" variant="Bold"/></span>}
                                       
                                        </div>))}
                                    </div>         
                                </div>
                                <div className="col-12 colm_contentlist">
                                    <span>{t('Assign a Services')}:</span> 
                                    <div className='DisDisService2 col-12'> 
                                        <div className="inputAndlabel col-lg-4 col-12">
                                            <select
                                                aria-label="Default"
                                                // labelId="demo-simple-select-Degree-helper-label"
                                                id="MyOfferToOtherDis"
                                                value={values.MyOfferToOtherDis}
                                                label={t('MyOfferToOtherDis')}
                                                // MenuProps={MenuProps}
                                                onChange={handleChange('MyOfferToOtherDis')}  
                                                placeholder={t('MyOfferToOtherDis')}
                                                required
                                                >
                                                    <option  value="">
                                                    {t('Service')}
                                                   
                                                </option >
                                                {data &&
                                                       data.map((element) => (
                                                <option  value={element.key}>{element.distributorServiceName + ' ' + element.distributorServiceCountry}</option >
                                               
                                                       ))}
                                            </select >
                                            {/* <label htmlFor="MyOfferToOtherDis">{t('Enter the applicants MyOfferToOtherDis')}<p className="ReqiredCus">{t('(Required)')}</p></label> */}
                                            </div>
                                            <div className="Cus2Inputs col-lg-4 col-12">
                                            {<div className="inputAndlabel porsant_sug col-12">
                                                <input
                                                    id="MyOffer" 
                                                    value={values.MyOffer}
                                                    type="text"
                                                    placeholder={t('Enter Your Offer')}
                                                    onChange={handleChange('MyOffer')}
                                                    
                                                    />
                                                    {/* <label htmlFor="MyOffer">{t('Enter Your Offer to This Service')}</label> */}
                                            </div>}
                                            {values.MyOfferToOtherDis=='200'&&<div className="inputAndlabel porsant_sug col-12">
                                                <input
                                                    id="MyOffer" 
                                                    value={values.MyOffer}
                                                    type="text"
                                                    placeholder={t('15%')}
                                                    disabled
                                                    onChange={handleChange('MyOffer')}
                                                    
                                                    />
                                                    {/* <label htmlFor="MyOffer">{t('Enter Your Offer to This Service')}</label> */}
                                            </div>}
                                            </div>
                                            {<div onClick={()=>{handleSubmit(item.AdvocacyCode,values.MyOfferToOtherDis,values.MyOffer)}
                                                } className='btn btn-dark col-lg-4 myDisSubmitService' >{t('Submit')}</div>}
                                            {values.MyOfferToOtherDis=='200'&&<div className='btn btn-light-outline col-lg-4 myDisSubmitService' >{t('You Assigned Before')}</div>}
                                        {/* <span>{t('Express Entry')} + {t('offer you')} <b>15% </b> <button className='col-3 btn btn-success'>{t('Accept')}</button> <button className="col-3 btn btn-danger">{t('Rejected')}</button>  </span> 
                                        <span>{t('Worker Visa')} + {t('offer you')} <b>15% </b> <button className='col-3 btn btn-success'>{t('Accept')}</button> <button className="col-3 btn btn-danger">{t('Rejected')}</button>  </span> 
                                        <span>{t('Startup')} <TickCircle size="18" color="#37d67a" variant="Bold"/></span>
                                        <span>{t('Educational Visa')} <TickCircle size="18" color="#37d67a" variant="Bold"/></span> */}
                                    </div>           
                                </div>
                                </div>
                                
                            </div>
                        </div>}
                        <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                    </div>
                
                        ))}
            </div>
            {isOpen && <Popup
      content={<>
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Are you sure about the set tariff?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please be sure of the rate set as commission. It is not possible to change the tariff after final approval')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleToggleSubimt} className="col-3 btn btn-dark">{t('OK')}</button>
            </div>
      </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
            <div className="PboxFirstRow"> 
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('Adding a new CO-DS')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Add a Distributer that want to be in rel for coporating in your work.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('ADD BY DS Code')}</label>
            <div className="input-group mb-3">
                <input value={values.DCCODE} onChange={handleChange('DCCODE')} type="email" id="email" name="email" className="form-control" placeholder={t('Type DS Code Here...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button  onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Add DS!!!')}</button>
            </div>
            <label>{t('ADD BY Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailC} onChange={handleChange('EmailC')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button  onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
      </>}
      handleClose={togglePopup2}
    />}

{isOpen3 && <Popup
      content={<>
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('Adding a new Distributer')}</h4>
            </div>
            <div className="PboxThirdRow">
            <p>{t('Please enter the email of the Distributer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Distributer Code')}</label>
                <div className="input-group mb-3">
                    <input type="email" id="email" name="email" className="form-control" placeholder={t('Type Distributer Code...')} aria-label="Type Distributer Code..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendRequest} className="btn btn-primary" type="button" id="button-addon2">{t('Send Request')+'!'}</button>
                </div>
            </div>
      </>}
      handleClose={togglePopup3}
    />}
     {isOpen4 && <Popup
      content={<>
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Are you sure about the set tariff?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please be sure of the rate set as commission. It is not possible to change the tariff after final approval')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleAccept} className="col-3 btn btn-dark">{t('OK')}</button>
            </div>
      </>}
      handleClose={togglePopup4}
    />}
     {isOpen5 && <Popup
      content={<>
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Are you sure about the set tariff?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please be sure of the rate set as commission. It is not possible to change the tariff after final approval')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleReject} className="col-3 btn btn-dark">{t('OK')}</button>
            </div>
      </>}
      handleClose={togglePopup5}
    />}
    </>
        
    );
}
 
export default Agents;