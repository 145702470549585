import chart1 from './images/Chart1.png';
import chart2 from './images/Chart2.png';
import chart3 from './images/Chart3.png';
import {AddSquare,ArrowDown2,Warning2,Calendar2,Briefcase,Star1,CardTick1,CardRemove1,CardEdit} from 'iconsax-react';
import Popup from './Popup';
import { useState , useEffect , useRef} from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const Home = () => {


    const {t, i18n} = useTranslation(['Login']);
    const forceUpdate = useForceUpdate();
    const [people , setPeople] = useState([
        {visa:'false',CustomerPic:'CusPic.png', CustomerName:'behzad',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'1'},
      
      ]);

      const [peopleA , setPeopleA] = useState([
        {AgentPic:'CusPic.png', AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
       
      ]);
      const [peopleD , setPeopleD] = useState([
        {visa:'false',CustomerPic:'CusPic.png', CustomerName:'behzad',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'1'},
      
      ]);

      const [peopleM , setPeopleM] = useState([
        {AgentPic:'CusPic.png', AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
       
      ]);
      fetch('https://telesiness.ir/api/Dashboards/DistributorLatestCustomers' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try
             {
                
                if(people.length<2)
                {
                    people.pop();
                    var myActDec = ''
                for (i in response){
                    // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                    // people[i].CustomerCreated = response[i].customerRegisterDate;
                    if(response[i].verifyCustomerByDistributor == 2)
                    myActDec = "Deactive";
                    else  if(response[i].verifyCustomerByDistributor == 1)
                    myActDec = "Active";
                    else
                    myActDec = "Pending";
                    people.push( {visa:'false',
                    CustomerPic:'CusPic.png', 
                    CustomerName:response[i].customerFirstName + " " + 
                    response[i].customerLastName,
                    CustomerPhone:response[i].customerPhoneNumber,
                    CustomerStatus:myActDec,
                    CustomerCreated:response[i].customerRegisterDate, 
                    CustomerAgent:'BaBaii',key:i},)
                                 }
                 }
             }

            catch{

            }
            // console.log(response[0]);
            
        });

    fetch('https://telesiness.ir/api/Dashboards/DistributorLatestLawyers' , {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
body: JSON.stringify({ Email: localStorage.getItem('email') })
}).then(response => response.json()).then(response=>{
     var i =0;
     try{ 

        if(peopleA.length<2)
        {
            peopleA.pop();
            for (i in response.distributorList){
                // console.log(response.distributorList[i].distributorFirstName);
                // peopleA[i].AgentName = response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName;
                // peopleA[i].AgentClients = response.distributorList[i].clientsCount;
                // peopleA[i].AgentPhone = response.distributorList[i].distributorCode;
                peopleA.push({AgentPic:'CusPic.png', AgentName:response.distributorList[i].lawyerFirstName + " " + response.distributorList[i].lawyerLastName,AgentPhone:response.distributorList[i].lawyerCode,AgentStatus:'Active',AgentClients:response.distributorList[i].distributorReferenceCode, Email:'kambiz.fakhr@yahoo.com',key:i},)
            }
        }
      
        
     }

    catch{}
    // console.log(response.distributorList[0].distributorFirstName);
    //   forceUpdate();
    // window.location.reload(false);
    
});

fetch('https://telesiness.ir/api/Dashboards/GetMarketersList' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ Email: localStorage.getItem('email') })
    }).then(response => response.json()).then(response=>{
         var i =0;
         try{ 
    
            if(peopleM.length<2)
            {
                peopleM.pop();
                for (i in response.marketerList){
                    // console.log(response.marketerList[i].distributorFirstName);
                    // peopleM[i].AgentName = response.marketerList[i].distributorFirstName + " " + response.marketerList[i].distributorLastName;
                    // peopleM[i].AgentClients = response.marketerList[i].clientsCount;
                    // peopleM[i].AgentPhone = response.marketerList[i].distributorCode;
                    peopleM.push({AgentPic:'CusPic.png', AgentName:response.marketerList[i].marketerFirstName + " " + response.marketerList[i].marketerLastName,AgentPhone:response.marketerList[i].marketerPhoneNumber,AgentStatus:response.marketerList[i].marketerStatus,AgentClients:response.marketerList[i].clientsCount, Email:'kambiz.fakhr@yahoo.com',key:response.marketerList[i].marketerCode},)
                }
            }
          
            
         }
    
        catch{}
        // console.log(response.marketerList[0].distributorFirstName);
        //   forceUpdate();
        // window.location.reload(false);
        
    });

    fetch('https://telesiness.ir/api/Dashboards/DistributorsList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try{ 
        
                if(peopleD.length<2)
                {
                    peopleD.pop();
                    for (i in response.distributorsList){
                        // console.log(response.distributorsList[i].distributorFirstName);
                        // peopleD[i].AgentName = response.distributorsList[i].distributorFirstName + " " + response.distributorsList[i].distributorLastName;
                        // peopleD[i].AgentClients = response.distributorsList[i].clientsCount;
                        // peopleD[i].AgentPhone = response.distributorsList[i].distributorCode;
                        peopleD.push({AgentPic:'CusPic.png', AgentName:response.distributorsList[i].distributorFirstName + " " + response.distributorsList[i].distributorLastName,AgentPhone:response.distributorsList[i].distributorPhoneNumber,AgentStatus:'Active',AgentClients:response.distributorsList[i].distributorsClient, Email:'kambiz.fakhr@yahoo.com',key:response.distributorsList[i].distributorCode},)
                    }
                }
              
                
             }
        
            catch{}
            // console.log(response.distributorsList[0].distributorFirstName);
            //   forceUpdate();
            // window.location.reload(false);
            
        });

const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
  if (counter.current < 2) {
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: state.num + 1 }), 3000);

    return () => clearTimeout(timer);
    forceUpdate();
  }
}, [state]);
      const [values, setValues] = useState({
        EmailL: '',
        EmailD: '',
        EmailC: '',
        EmailM: '',
        checked: '',
        refCode: '',
        showPassword: false,
      });
      const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    const handleDeactiveClick = (itemKey) =>{
        setIsOpen(!isOpen);
        for(var i in people){
            if(people[i].key==itemKey){
                people[i].CustomerStatus='Deactive';
                setVisaId(i);
            }
            
        }
        forceUpdate() ;
        setPeople(people);
    
    }
    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
   const [isOpen, setIsOpen] = useState(false);
   const [isOpen2, setIsOpen2] = useState(false);
   const [isOpen3, setIsOpen3] = useState(false);
   const [isOpen4, setIsOpen4] = useState(false);
   const [isOpen5, setIsOpen5] = useState(false);
   const [isOpen6, setIsOpen6] = useState(false);
   const [visaID,setVisaId]=useState(-1);
   const handleVisaPbox = (itemKey) =>{
    people[visaID].visa='Visa';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}

const handleRejectedPbox = (itemKey) =>{
    people[visaID].visa='Rejected';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}


const handleDeclinedPbox = (itemKey) =>{
    people[visaID].visa='Declined';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}
const togglePopup = () => {
    setIsOpen(!isOpen);
  }
const togglePopup2 = () => {
setIsOpen2(!isOpen2);
}
const togglePopup3 = () => {
setIsOpen3(!isOpen3);
}
const togglePopup4 = () => {
setIsOpen4(!isOpen4);
}
const togglePopup5 = () => {
setIsOpen5(!isOpen5);
}

const togglePopup6 = () => {
setIsOpen6(!isOpen6);
}
    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen3(!isOpen3);
    }
    const handleAddNewUserAgentAgent = (itemKey) =>{
        setIsOpen5(!isOpen5);
    }
    const handleAddNewUserMarketer = (itemKey) =>{
        setIsOpen6(!isOpen6);
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      }
      const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomersByAgent' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ CustomerEmailAddress: values.EmailC ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(setIsOpen2(!isOpen2));
        setIsOpen2(!isOpen2)
        
    }
    const handleAddUserAgentSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteLawyersByAgent' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ LawyerEmailAddress: values.EmailL ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(setIsOpen3(!isOpen3));
        setIsOpen3(!isOpen3)
        
    }
    const handleAddUserAgentAgentSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteDistributorByDistributor' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ InviteEmailAddress: values.EmailL ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(setIsOpen5(!isOpen5));
        setIsOpen5(!isOpen5)
        
    }
    
    const handleAddUserAgentMarketerSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteMarketersByDistributor' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ InviteEmailAddress: values.EmailL ,DistributorCode:localStorage.getItem('AgentCode') })
            }).then().then(setIsOpen6(!isOpen6));
        setIsOpen6(!isOpen6)
        
    }
    return ( 
        <div className="HomeMainRow home_agentandlawyer col-12">
           <div className="HomeRow1">
                <div className="total_summary col-12">
                <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                            <div className="homeCell1">
                            {/* <img src={chart1} alt="" /> */}
                            <CardTick1 size="64" color="#37d67a" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Completed Files')}</h4>
                                <p>+ 0 </p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart2} alt="" /> */}
                            <CardRemove1 size="64" color="#f47373" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Canceled files')}</h4>
                                <p>+ 0</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart3} alt="" /> */}
                            <CardEdit  size="64" color="#2ccce4" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('In progress files')}</h4>
                                <p>+ 0</p>
                            </div>
                        </div>
                    </div>
                </Carousel>
   
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                            <div className="homeCell1">
                            {/* <img src={chart1} alt="" /> */}
                            <CardTick1 size="64" color="#37d67a" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Completed Files')}</h4>
                                <p>+ 0 </p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart2} alt="" /> */}
                            <CardRemove1 size="64" color="#f47373" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Canceled files')}</h4>
                                <p>+ 0</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart3} alt="" /> */}
                            <CardEdit  size="64" color="#2ccce4" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('In progress files')}</h4>
                                <p>+ 0</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                                        <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
      
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Customers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserCustomer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {people.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>                                          
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.CustomerName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.CustomerStatus==true && <div className="col-3 btn_de_active">
                                            <button  onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                                {t('Active') }
                                                <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div>
                                            </button></div>}

                                        {item.CustomerStatus==false && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    
                                                    <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div>
                                                </button>
                                            </div>}
                                            {item.CustomerStatus=='Pending' && <div className="col-lg-3 col-6 de_active_agentCus">
                            <button  className="btn-sm Pending">
                                {t('Pending')}
                                {/* <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                </div> */}
                            </button>
                            
                        </div>}
                                        <div className="col-4 collist_item"><p>{item.CustomerCreated.split('T',1)}</p></div>
 {/* end responsive*/}                                   
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
{/* start responsive*/}
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Lawyers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserAgent} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2 lasted_laworage">
                                 <div className="mainRowCusHomeR2 col-12">
                                    {peopleA.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>                                    
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        { <div className="col-3 coun_clients">
                                            <div  className="btn-sm HomeGrayCilents ">
                                            
                                                <div id={item.key} className="ChoosBox2"> 
                                                    
                                                {/* <p>{item.AgentClients + t(' Client')}</p> */}
                                                <p>{item.AgentClients }</p>
                                                </div>
                                            </div></div>}                                  
                                        <div className="col-4 collist_item"><p>{item.key}</p></div>
{/* end responsive*/}                                     
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>   
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
{/* start responsive*/}
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Distributers')}</p>
                                {localStorage.getItem('isDistributorPlus')==1  && <AddSquare className='AddUserHome' onClick={handleAddNewUserAgentAgent} size="40" color="#000000" variant="Bold"/>}
                            </div>
                            <div className="HomeR2R2 lasted_laworage">
                                 <div className="mainRowCusHomeR2 col-12">
                                    {peopleD.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>                                    
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        { <div className="col-3 coun_clients">
                                            <div  className="btn-sm HomeGrayCilents ">
                                            
                                                <div id={item.key} className="ChoosBox2"> 
                                                    
                                                {/* <p>{item.AgentClients + t(' Client')}</p> */}
                                                <p>{item.key }</p>
                                                </div>
                                            </div></div>}                                  
                                        <div className="col-4 collist_item"><p>{item.AgentClients}</p></div>
{/* end responsive*/}                                     
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>   
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
{/* start responsive*/}
      
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Agents')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserMarketer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {peopleM.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>
 {/* add deactiveitem or activeitem classname for itemBox baseof active or deactive*/}                                          
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.AgentStatus==true && <div className="col-3 btn_de_active">
                                            <button  onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                                {t('Active') }
                                                <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div>
                                            </button></div>}

                                        {item.AgentStatus==false && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    
                                                    <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div>
                                                </button>
                                            </div>}
                                        <div className="col-4 collist_item"><p>{item.AgentPhone}</p></div>
 {/* end responsive*/}                                   
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>

            </Carousel>
            <div className="HomeRow2 home_dashpan col-12">
                <div className="total_HomeLightBoxRowTwo col-lg-10 col-12 d-none d-lg-flex">
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Customers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserCustomer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {people.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>  
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.CustomerName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.CustomerStatus==true && <div className="col-3 btn_de_active">
                                            <button  onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                                {t('Active') }
                                                <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div>
                                            </button></div>}

                                        {item.CustomerStatus==false && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    
                                                    <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div>
                                                </button>
                                            </div>}
                                            {item.CustomerStatus=='Pending' && <div className="col-lg-3 col-6 de_active_agentCus">
                            <button  className="btn-sm Pending">
                                {t('Pending')}
                                {/* <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                </div> */}
                            </button>
                            
                        </div>}
                                        <div className="col-4 collist_item"><p>{item.CustomerCreated.split('T',1)}</p></div>                               
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Lawyers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserAgent} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2 lasted_laworage">
                                 <div className="mainRowCusHomeR2 col-12">
                                    {peopleA.map(item =>(
                                        <div className="itemBox col-12"  key={item.key}>                                    
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        { <div className="col-3 coun_clients">
                                            <div  className="btn-sm HomeGrayCilents">
                                                <div id={item.key} className="ChoosBox2"> 
                                                {/* <p>{item.AgentClients + t(' Client')}</p> */}
                                                <p>{item.AgentClients }</p>
                                                </div>
                                            </div></div>}                                  
                                        <div className="col-4 collist_item"><p>{item.key}</p></div>                                    
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>   
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                {localStorage.getItem('isDistributorPlus')==1  &&<p>{t('Latest Distributers')}</p>}
                                {localStorage.getItem('isDistributorPlus')!=1  &&<p className="ProDCHomePTag_disable">{t('Latest Distributers')}</p>}
                                {localStorage.getItem('isDistributorPlus')==1  && <AddSquare className='AddUserHome' onClick={handleAddNewUserAgentAgent} size="40" color="#000000" variant="Bold"/>}
                            </div>
                            {localStorage.getItem('isDistributorPlus')==1  &&<div className="HomeR2R2 lasted_laworage">
                                 <div className="mainRowCusHomeR2 col-12">
                                    {peopleD.map(item =>(
                                        <div className="itemBox col-12"  key={item.key}>                                    
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        { <div className="col-3 coun_clients">
                                            <div  className="btn-sm HomeGrayCilents "> 
                                                <div id={item.key} className="ChoosBox2"> 
                                                {/* <p>{item.AgentClients + t(' Client')}</p> */}
                                                <p>{item.key }</p>
                                                </div>
                                            </div></div>}                                  
                                        <div className="col-4 collist_item"><p>{item.AgentClients}</p></div>                             
                                    </div>
                                            ))}
                                </div>
                            </div>}
                            {localStorage.getItem('isDistributorPlus')!=1  && <>
                            <p className='ProDCHomePTag'>{t('This Option Will Be Active After You improve Panel to Pro Version!')}</p>
                            </>}
                        </div>   
                    </div>

                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Agents')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserMarketer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {peopleM.map(item =>(
                                        <div className="itemBox col-12"  key={item.key}>                                         
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.AgentStatus==true && <div className="col-3 btn_de_active">
                                            <button  onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                                {t('Active') }
                                                <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div>
                                            </button></div>}
                                        {item.AgentStatus==false && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br/>
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div>
                                                </button>
                                            </div>}
                                        <div className="col-4 collist_item"><p>{item.AgentPhone}</p></div>                                 
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <div className="box_HomeLightBoxRowTwo col-lg-2 col-md-12 col-12">
                    <div className="HomeLightBoxRowTwo HomeCol3Row2">
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox"> 
                                <Calendar2 size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Registery Date')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('registerDate').split('T',1)}</p>
                        </div>
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox"> 
                                <Briefcase className='HomeBrifcasePad' size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Agency Code')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('agentCode')}</p>
                        </div>
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="Star svg_HomeLightBox" onClick={togglePopup4}>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131"/>
                                <Star1 size="24" color="#313131"/>
                            </div>
                            <p className='HomeCol3P1 Star' onClick={togglePopup4}>720</p>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Why You wanna De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-md-3 col-12 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-md-3 col-12 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-md-3 col-12 btn btn-light">{t('Declined')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
     {isOpen2 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.EmailC} onChange={handleChange('EmailC')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup2}
    />}
    {isOpen3 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Adding a New Lawyer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the lawyer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailL} onChange={handleChange('EmailL')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup3}
    />}

{isOpen5 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Distributer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Distributer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailD} onChange={handleChange('EmailD')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentAgentSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup5}
    />}

{isOpen6 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Agent')}</h4>
            </div>
            <div className="PboxThirdRow">
            <p>{t('Please enter the email of the Agent you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.EmailM} onChange={handleChange('EmailM')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentMarketerSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup6}
    />}


{isOpen4 && <Popup
      content={<>
      <div className="PboxMainRow">
      <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Your Score in Telesiness Shows Your Activity')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('You can Improve to 5Star Lawyer if You Can Catch 5 Star')}</p>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Your Score need 6 Successfull Visa to next Star')}</p> 
            </div>
            <div className="Star" onClick={togglePopup4}>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131"/>
                <Star1 size="24" color="#313131"/>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup4}
    />}
        </div>
                
     );
}
 
export default Home;