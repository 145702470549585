import {ArrowUp2,ArrowDown2,Filter,ProfileAdd,InfoCircle,ShieldSecurity,TickCircle,MessageText1} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Customers = ({childToParentMessage}) => {
    const {t, i18n} = useTranslation(['Login']);

    const [people , setPeople] = useState([
        {visa:'',CustomerPic:'CusPic.png', CustomerName:'',CustomerPhone:'+',CustomerStatus:'Active',CustomerCreated:'', CustomerAgent:'',key:'1'},
     
      ]);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].CustomerStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowCustomer = () =>{
        setPeople(people.sort((a,b) => (a.CustomerName > b.CustomerName) ? 1 : ((b.CustomerName > a.CustomerName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowCustomer = () =>{
        setPeople(people.sort((a,b) => (a.CustomerName < b.CustomerName) ? 1 : ((b.CustomerName < a.CustomerName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.CustomerCreated > b.CustomerCreated) ? 1 : ((b.CustomerCreated > a.CustomerCreated) ? -1 : 0)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.CustomerCreated < b.CustomerCreated) ? 1 : ((b.CustomerCreated < a.CustomerCreated) ? -1 : 0)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    
        }

      

    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomersByMarketer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ CustomerEmailAddress: values.EmailCus ,MarketerCode:localStorage.getItem('marketerCode') })
            }).then().then(setIsOpen2(!isOpen2));
        setIsOpen2(!isOpen2);
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.CustomerStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.CustomerStatus == 'Deactive').length)));
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.CustomerStatus == 'Active'));
              forceUpdate() ;
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.CustomerStatus == 'Deactive'));
              forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.CustomerStatus == 'Deactive'));
              forceUpdate() ;
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.CustomerStatus == 'Active'));
              forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }

// const [values, setValues] = useState({
//     lawyer: '',
//   });
//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//     if (values.lawyer=='Assign by Telesiness')
//     setIsOpen2(!isOpen2);
//     console.log(values.lawyer);
//   }
const [lawyer , setLawyer] = useState(0);
  const handleChange2 = (event) =>{
    setLawyer(event.target.value);
    if (event.target.value == 'Assign by Telesiness')
      setIsOpen(!isOpen2);

    console.log(event.target.value);
  }
  const [isOpenRow, setisOpenRow] = useState(true);

  const handleAgentRowClick = (itemKey) =>{
      if(isOpenRow){
          document.getElementById(itemKey).style.display="flex";
          setisOpenRow(!isOpenRow);
      }
      else{
          document.getElementById(itemKey).style.display="none";
          setisOpenRow(!isOpenRow);
      }
    }
    const handleClickSelect = (itemKey) =>{
         if(!isOpenRow){
          document.getElementById(itemKey).style.display="flex";
        //   setisOpenRow(!isOpenRow);
      }
      else{
          document.getElementById(itemKey).style.display="none";
        //   setisOpenRow(!isOpenRow);
      }
    }

    const [values, setValues] = useState({
        
        MyOfferToOtherDis:'',
        EmailCus: '',
      
      });
    


const handleSendMsgReDirect = (CustomerName) => {
localStorage.setItem('CusNameMsg',CustomerName);

}



fetch('https://telesiness.ir/api/Dashboards/MarketerCustomersList' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ Email: localStorage.getItem('email') })
    }).then(response => response.json()).then(response=>{
         var i =0;
         try{ 

            if(people.length<2)
            {
                people.pop();
                for (i in response){
                    // console.log(response.distributorList[i].distributorFirstName);
                    // peopleA[i].AgentName = response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName;
                    // peopleA[i].AgentClients = response.distributorList[i].clientsCount;
                    // peopleA[i].AgentPhone = response.distributorList[i].distributorCode;
                    people.push({
                        CustomerPic:'CusPic.png', 
                        CustomerName:response[i].customerFirstName + 
                        " " + 
                        response[i].customerLastName,
                        CustomerPhone:response[i].customerPhoneNumber,
                        CustomerStatus:response[i].customerDocumentStatus,
                        CustomerCreated:response[i].customerRegisterDate, 
                        Email:response[i].customerEmailAddress,
                        key:(parseInt(i)+1).toString(),
                        CustomerAgent:response[i].customerLawyerFirstName + ' ' + 
                          response[i].customerLawyerLastName,
                        customerCode:response[i].customerCode,
                        // customerCode:response[i].customerCode,
                        },)
                    
                }
            }
           console.log(response);
            
         }

        catch{}
        // console.log(response.distributorList[0].distributorFirstName);
        //   forceUpdate();
        // window.location.reload(false);
        
    });

    

    const [state, setState] = useState({ num: 0 });
    const counter = useRef(0);
    
    useEffect(() => {
      if (counter.current < 2) {
        counter.current += 1;
        const timer = setTimeout(() => setState({ num: state.num + 1 }), 3000);
  
        return () => clearTimeout(timer);
      }
    }, [state]);
    return (  
        <> <div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Customers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('Customers List')}</p>
                    </div>
                </div>
                <button onClick={handleAddNewUserCustomer} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Customer')}<ProfileAdd size="24" color="#fff" /></button>
            </div>
            <div className="TopMainRowTwo">
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-4"><p>{t('Customer')} <a onClick={handleUpArrowCustomer}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowCustomer}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Phone Number')}</p></div>
                <div className="col-2 Col-2Status">
                    <p>{t('Status')} </p><div className="FilterIcon" onClick={handleFilterStatus}><Filter  size="16"  color="#313131"/></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div>
                <div className="col-2"><p>{t('Created')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div>
            </div>
            <hr className="CustomerLine"/>
        </div>
        <div className="mainRowCus col-12">
            {people.map(item =>(       
            <div className="itemBox2 col-12"  key={item.key}>
                <div className="agentCusRow col-12">
                    <a className="col-lg-8 col-8 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                        <div className="col-2 id_agentCus"><p>{item.key}</p></div>
                        <div className="CusPic2 col-lg-6 col-12">
                            <div className='img_agentCus'><img src={`${baseUrl + item.CustomerPic}`} alt="" /></div>
                            <div className='detail_agentCus'>
                                <p>{item.CustomerName}</p>
                                <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                            </div>
                        </div>
                        <div className="col-4 phone_agentCus"><p>{item.CustomerPhone}</p></div>
                    </a>
                    {/* <div className="col-2 lawyer_agentCus">
                        <select
                        // value={lawyer}
                        className=''
                        id='AgCuSelect'
                        disabled
                        onChange={handleChange2}
                        onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                        >
                            <option value={item.CustomerAgent}>{item.CustomerAgent}</option>
                            <option value="kambiz fakhr">kambiz fakhr</option>
                            <option value="Hosein Khoshbayan">Hosein Khoshbayan</option>
                            <option value="Amir Setaki">Amir Setaki</option>
                            <option value="Assign by Telesiness">{t('Assign by Telesiness')}</option>
                        </select>
                        {item.CustomerAgent == 'Assign by Telesiness' &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                    </div> */}
                    <a className="col-lg-4 col-4 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                        {item.CustomerStatus==true && <div className="col-lg-6 col-12 de_active_agentCus">
                        <button className="active">
                            {t('Active') }
                            <div id={item.key} className="ChoosBox"> 
                                <p className='disabled'>{t('Active')}</p>  <br />
                                <p >{t('Deactive')}</p>
                            </div>
                        </button></div>}

                        {item.CustomerStatus==false && <div className="col-lg-6 col-12 de_active_agentCus">
                            <button  className="deactive">
                                {t('Deactive') }
                                <div id={item.key} className="ChoosBox"> 
                                    <p >{t('Active')}</p>  <br />
                                    <p className='disabled'>{t('Deactive')}</p>
                                </div>
                            </button>
                            {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                            </div>} 
                            <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div>
                        
                        </div>}
                        <div className="col-6 created_agentCus"><p>{item.CustomerCreated.split('T',1)}</p></div>
                        <div className="Send_Message">
                        <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" 
                            onClick={(dataMessage)=>{
                                document.getElementById('MessageText1Btn').style.backgroundColor='white';
                                document.getElementById('MessageText1BtnP').style.color='black';
                                document.getElementById('PeopleBtn').style.backgroundColor='transparent';
                                document.getElementById('PeopleBtnP').style.color='white';
                                localStorage.setItem('turnOffMeLawCusMsg',true);
                                localStorage.setItem('MsgUserNewName',item.CustomerName);
                                localStorage.setItem('emailCus',item.Email);
                                localStorage.setItem('CusCode',item.customerCode);
                                localStorage.setItem('MsgUserNewType',3);
                                childToParentMessage(dataMessage);
                                handleSendMsgReDirect(item.CustomerName)}}/>
                            <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                            {/* <div className='btn btn-dark btn_addLawCus' >{t('Send Message')}</div> */}
                        </div> 
                    </a>
                </div>
                {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                    <div className="hidenItemRow">
                        <hr className="linebetitem col-12"/>
                        <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" 
                            onClick={(dataMessage)=>{
                                document.getElementById('MessageText1Btn').style.backgroundColor='white';
                                document.getElementById('MessageText1BtnP').style.color='black';
                                document.getElementById('PeopleBtn').style.backgroundColor='transparent';
                                document.getElementById('PeopleBtnP').style.color='white';
                                localStorage.setItem('turnOffMeLawCusMsg',true);
                                localStorage.setItem('MsgUserNewName',item.CustomerName);
                                localStorage.setItem('emailCus',item.Email);
                                localStorage.setItem('CusCode',item.customerCode);
                                localStorage.setItem('MsgUserNewType',3);
                                childToParentMessage(dataMessage);
                                handleSendMsgReDirect(item.CustomerName)}}/>
                       {/* <div className="d-lg-none col-12 hidenItem lawyer_agentCusm">
                            <span>{t('Lawyer')}:</span>
                            <select
                            // value={lawyer}
                            className=''
                            disabled
                            id='AgCuSelect'
                            onChange={handleChange2}
                            onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                            >
                                <option value={item.CustomerAgent}>{item.CustomerAgent}</option>
                                <option value="kambiz fakhr">kambiz fakhr</option>
                                <option value="Hosein Khoshbayan">Hosein Khoshbayan</option>
                                <option value="Amir Setaki">Amir Setaki</option>
                                <option value="Assign by Telesiness">{t('Assign by Telesiness')}</option>
                            </select>
                            {item.CustomerAgent == 'Assign by Telesiness' &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                        </div>*/}
                        <div className="d-lg-none col-12 hidenItem "><span>{t('Created')}:</span><p>{item.CustomerCreated.split('T',1)}</p></div>
                        <div className="d-lg-none col-12 hidenItem phone_agentCusm"><span>{t('Phone Number')}:</span><p>{item.CustomerPhone}</p></div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Email')}:</span><p>{item.Email}</p> </div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Visa')}:</span><p>{item.visa}</p> </div>
                        <div className='col-12 SuggestedServices hidenItem'>
                            <div className="col-12 hidenItem">
                                <span className="col-lg-2 col-12">{t('Suggested Services')}:</span> 
                                <div className='DisDisService col-lg-10 col-12'> 
                                    {/* <div className='myDisDisServiceToAccept'>
                                        <span>{t('Express Entry')} + {t('offer you')} <b>15% </b> <button className='col-3 btn btn-success myDisSubmitService'>{t('Accept')}</button> <button className="col-3 btn btn-danger myDisSubmitService">{t('Rejected')}</button>  </span> 
                                        <span>{t('Worker Visa')} + {t('offer you')} <b>15% </b> <button className='col-3 btn btn-success myDisSubmitService'>{t('Accept')}</button> <button className="col-3 btn btn-danger myDisSubmitService">{t('Rejected')}</button>  </span> 
                                    </div> */}
                                    <span>{t('Startup')} <TickCircle size="18" color="#37d67a" variant="Bold"/></span>
                                    <span>{t('Educational Visa')} <TickCircle size="18" color="#37d67a" variant="Bold"/></span>
                                </div>           
                            </div>      
                        </div>
                    </div>
                </div>}
                <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
            </div>  
            ))}
        </div>
            {isOpen && <Popup
       content={<>
              <div className="PboxFirstRow">
                  <ShieldSecurity size="48" color="#313131" variant="Bold"/>
              </div>
              <div className="PboxSecondRowAgCus">
                <h2>{t('Are You Sure?')}</h2>
              <h4>{t('You choose the lawyer by Telesiness')}</h4>
              </div>
              <div className="PboxForthRowSendMail col-12"> 
                  <button onClick={togglePopup} className="btn btn-outline myBlackBtn">{t('Cancel')}</button>
                  <button onClick={togglePopup}  className="btn btn-dark">{t('Send Request')}</button>
              </div>
        </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
            <div className="PboxFirstRow">       
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailCus} onChange={handleChange('EmailCus')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button  onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
      </>}
      handleClose={togglePopup2}
    />}
    </>
        
    );
}
 
export default Customers;