import { useState , useEffect } from 'react';
import cus1Pic from './images/cus1.png';
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {TickCircle,Notepad2} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"
import ProgressBar from 'react-bootstrap/ProgressBar'

const Cus1 = ({childToParentCus1e1e1}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);  
    //*********************************** */
const dataCus2 = true;
const DocumentTextnow = 60;
const Profilenow = 80;
const weddingnow = 10;
const Profile2Usernow = 35;
const childnow = 68;
const Clipboardnow = 87;
var firstTimeCustomer = true;
firstTimeCustomer = localStorage.getItem('firstTimeCustomer');
console.log(localStorage.getItem('firstTimeCustomer'));
return ( 
         
    <div className="mainRowPro col-12">
      <form  className="">
      <div className="personalInfo col-12">
       
        <div className="Cus2Inputs2">
        <div className="CusStep2Tick">
            <TickCircle  size="130" color="#D3A281" variant="Bulk"/>
            <p className='CusStep2TickP'>{t('Your file has been successfully registered')}</p>
            <p className='Cus2Step1SmallPBrown2'>{t('Please be patient until Your information is reviewed and approved by our experts, and then proceed to complete your information.')}</p>
            <p className='CusStep2TickP3'>{t('Tracking Code')}:      78946987643</p>
            <button onClick={(dataCus1e1e1) => {childToParentCus1e1e1(dataCus1e1e1);}} className='btn btn-dark '> <Notepad2 className='notepad2Cus2Pad' size="20" color="#FFFFFF"/>{t('Go to request')}</button>
        </div>
        
        
         


        </div>
       
        {/* <div className="cusS1SaveCol col-12">
          <div className="col-6">
             <button   className="btn col-12 btn-outline-light ">{t('Previous')}</button >
          </div>
          <div className="botCusDraftCol col-6">
            <button className="btn col-6 btn-outline-light ">
              <ArchiveTick size="32" color="#D3A281"/>{t('Save Draft')}
            </button >
            {<button   className="btn btn-dark">{t('Next Step')}</button>}
          </div>
         
        </div> */}
      </div>
      </form>
      
     
{/*         
      {isOpen && <Popup
    content={<>
    <div className="PboxMainRow">
          <div className="PboxFirstRow">
              <ShieldSecurity size="48" color="#313131" variant="Bold"/>
          </div>
          <div className="PboxSecondRow">
          <h4>{t('Are you sure?')}</h4>
          </div>
          <div className="PboxThirdRow">
              <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
          </div>
          <div className="PboxForthRowSendMail col-12">
              
              <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
              <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
          </div>
      
      </div>
    </>}
    handleClose={togglePopup}
  />}
*/}

   </div>
    );
}
 
export default Cus1;