
import {BrowserRouter as Router , Route, Switch} from 'react-router-dom';
import Login from './components/login/Login';
import ResetPassword from './components/login/ResetPasword/ResetPasword';
import ResetPassword2 from './components/login/ResetPasswordS2/ResetPaswordS2';
import ResetPassword3 from './components/login/ResetPasswordS3/ResetPasword3';
import Register from './components/Register/Register';
import ChooseService from './components/Register/regChooseService/ChooseService';
import ChooseDistributer from './components/Register/regChooseDistributer/regChooseDis';
import RegStep2 from './components/Register/regStep2/Register';
import RegStep3 from './components/Register/regStep3/Register';
import RegStep4 from './components/Register/regStep4/Register';
import RegStep5 from './components/Register/regStep5/Register';
import RegStep6 from './components/Register/regStep6/Register';
import RegStep62 from './components/Register/regStep62/Register';
import RegaStep2 from './components/Register/regaStep2/Register';
import RegaStep3 from './components/Register/regaStep3/Register';
import RegcStep2 from './components/Register/regcStep2/Register';
import RegcStep3 from './components/Register/regcStep3/Register';
import RegmStep2 from './components/Register/regmStep2/Register';
import RegmStep3 from './components/Register/regmStep3/Register';
import Dashboard from './Dashboard/Dashboard';
import Profile from './Dashboard/TopMenu/profile/profile';
import ConfirmEmail from './confirmEmail/confirmEmail';
import Services from './components/login/servicesLogin/ServicesLogin';
import CollaborationsLogin from './components/login/CollaborationsLogin/CollaborationsLogin';
import ContactUsLogin from './components/login/ContactUsLogin/ContactUsLogin';
import HelpLogin from './components/login/HelpLogin/HelpLogin';
import TermsLogin from './components/login/TermsLogin/TermsLogin';
import AboutusLogin from './components/login/AboutusLogin/AboutusLogin';
import My404 from './404/404';
import ComingSoon from './comingSoon/comingSoon';
import './local.js';
function App() {
   document.body.setAttribute('lang', 'en');
   document.getElementsByTagName('html')[0].setAttribute("dir","ltr");
  return (
    <Router>
      <div className="App">
        
      <div className="content">
        <Switch>
          <Route exact path="/">
             <Login />
          </Route>
          <Route path="/confirmEmail">
             <ConfirmEmail />
          </Route>
          <Route path="/services">
             <Services />
          </Route>
          <Route path="/CollaborationsLogin">
             <CollaborationsLogin />
          </Route>
          <Route path="/ContactUsLogin">
             <ContactUsLogin />
          </Route>
          <Route path="/HelpLogin">
             <HelpLogin />
          </Route>
          <Route path="/TermsLogin">
             <TermsLogin />
          </Route>
          <Route path="/AboutusLogin">
             <AboutusLogin />
          </Route>
          <Route path="/Dashboard">
             {/* localStorage.getItem('userType')===1 && */}
            { <Dashboard />} 
          </Route>
          <Route path="/resetpass">
             <ResetPassword />
          </Route>
          <Route path="/resetpass2">
             <ResetPassword2 />
          </Route>
          <Route path="/resetpass3">
             <ResetPassword3 />
          </Route>
          <Route path="/register">
             <Register/>
          </Route>
          <Route path="/chooseservice">
             <ChooseService/>
          </Route>
          <Route path="/chooseDis">
             <ChooseDistributer/>
          </Route>
          <Route path="/regstep2">
             <RegStep2/>
          </Route>
          <Route path="/regstep3">
             <RegStep3/>
          </Route>
          <Route path="/regstep4">
             <RegStep4/>
          </Route>
          <Route path="/regstep5">
             <RegStep5/>
          </Route>
          <Route path="/regstep6">
             <RegStep6/>
          </Route>
          <Route path="/regstep62">
             <RegStep62/>
          </Route>
          <Route path="/regastep2">
             <RegaStep2/>
          </Route>
          <Route path="/regastep3">
             <RegaStep3/>
          </Route>
          <Route path="/regcstep2">
             <RegcStep2/>
          </Route>
          <Route path="/regcstep3">
             <RegcStep3/>
          </Route>
          <Route path="/regmstep2">
             <RegmStep2/>
          </Route>
          <Route path="/regmstep3">
             <RegmStep3/>
          </Route>
          <Route path="/profile">
             <Profile/>
          </Route>
          <Route path="/blogs/:id">
             
          </Route>
          <Route path="/comingSoon">
             <ComingSoon />
             </Route>
          <Route>
             <My404/>
             </Route>

        </Switch>
        
      </div>
      </div>
    </Router>
    
  );
}

export default App;
