import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,CloseCircle} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const Profile2 = ({childToParentCus11}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    const forceUpdate = useForceUpdate();
    //****************submits**********************************/
    const [cusJobs , setCusJobs] = useState([
      {Firstlastname:'ramin samaie', Dateofbirth:'1992/07/24', Jobtitle:'', Dateofdeath:'', siblingaddress:'shiraz', key:'1'},
      {Firstlastname:'zahra navab', Dateofbirth:'1992/07/24', Jobtitle:'', Dateofdeath:'', siblingaddress:'tehran', key:'2'},
      {Firstlastname:'naser naseri', Dateofbirth:'1992/07/24', Jobtitle:'', Dateofdeath:'', siblingaddress:'yazd', key:'3'},
      {Firstlastname:'rita alipour', Dateofbirth:'1992/07/24', Jobtitle:'', Dateofdeath:'', siblingaddress:'kerman', key:'4'},
    ]);
  const handleAddJob =(e) => {
    e.preventDefault();
    cusJobs.push({ 
      Numberofchildren: values.Numberofchildren,
      Firstlastname: values.Firstlastname,
      Dateofbirth: values.Dateofbirth,
      Jobtitle:values.Jobtitle,
      Dateofdeath:values.Dateofdeath,
      siblingaddress:values.siblingaddress,
      key:(cusJobs.length+1).toString(),
     },);
      forceUpdate();
      setCusJobs(cusJobs);
  }
  const handleRemoveCusJobs = (itemKey)=>{
    setCusJobs(cusJobs.filter(cusJobs => cusJobs.key != itemKey.toString()));
  }
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepOne' , {
        //   method: 'PUT',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ 
        //     // Email: localStorage.email3 ,
        //     // Password:localStorage.password3 ,
        //     FullName:"",
        //     Gender:"",
        //     DateOfBirth:"",
        //     AboutMe:""
        //   })
        //   }).then().then(history.push('/Dashboard'));
        // //     setIspending(false);
        // document.getElementById('personalCancel').style.display="none";
        // document.getElementById('personalSave').style.display="none";
        // document.getElementById('personPen').style.display="none";
        // document.getElementById('personPenBlue').style.display="none";
        // document.getElementById('proFirstName').setAttribute("disabled","disabled");
        // document.getElementById('proLastName').setAttribute("disabled","disabled");
        // document.getElementById('Gender').setAttribute("disabled","disabled");
        // //document.getElementById('birthDate').setAttribute("disabled","disabled");
     
        // document.getElementById('inputproAbout').setAttribute("disabled","disabled");
        // setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }

        const [values, setValues] = useState({
 
          Fathername: '',
          Fatherbirthdate: '',
          Fatherjob: '',
          Fatherdatedeath:'',
          Fatheraddress:'',
          Mothername: '',
          Motherbirthdate: '',
          Motherjob: '',
          Motherdatedeath:'',
          Motheraddress:'',
          Firstlasnamestepparentdetails:'',
          Numberofsiblings:'',
          Firstlastname:'',
          Dateofbirth:'',
          Jobtitle:'',
          Dateofdeath:'',
          siblingaddress:'',
  
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });

        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus10 = true;
        const dataCus12 = true;
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <form onSubmit={handleSubmitPersonal} className="col-12">
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">Step 1 :</p><p className="Cus2Step1BlackP">Complete Basic Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div> <p className="Cus2Step1SmallP">Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy</p></div>
          <div className="Cus2PersonalInfo"> <p>Family Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div className="Cus2Inputs">
            <div className="inputAndlabel col-4">
            <input
                id="Fathername" 
                value={values.Fathername}
                type="text"
                placeholder="Father's first and last name"
                onChange={handleChange('Fathername')}
                />
                <label htmlFor="Fathername">Enter the applicant's Father's first and last name</label>
            </div>
            <div className="inputAndlabel col-4">
            <input
                id="Fatherbirthdate" 
                value={values.Fatherbirthdate}
                type="text"
                placeholder="Father's date of birth"
                onChange={handleChange('Fatherbirthdate')}
                />
                <label htmlFor="Fatherbirthdate">Enter the applicant's Father's date of birth</label>
            </div>
            <div className="inputAndlabel col-4">
            <input
                id="Fatherjob" 
                value={values.Fatherjob}
                type="text"
                placeholder="Father's job"
                onChange={handleChange('Fatherjob')}
                />
                <label htmlFor="Fatherjob">Enter the applicant's Father's job</label>
            </div>
            <div className="inputAndlabel col-4">
            <input
                id="Fatherdatedeath" 
                value={values.Fatherdatedeath}
                type="text"
                placeholder="Father's date of death"
                onChange={handleChange('Fatherdatedeath')}
                />
                <label htmlFor="Fatherdatedeath">Enter the applicant's Father's date of death</label>
            </div>
            <div className="inputAndlabel col-8">
            <input
                id="Fatheraddress" 
                value={values.Fatheraddress}
                type="text"
                placeholder="Father's address and Zip code"
                onChange={handleChange('Fatheraddress')}
                />
                <label htmlFor="Fatheraddress">Enter the applicant's Father's address and Zip code</label>
            </div>
            <div className="inputAndlabel col-4">
            <input
                id="Mothername" 
                value={values.Mothername}
                type="text"
                placeholder="Mother's first and last name"
                onChange={handleChange('Mothername')}
                />
                <label htmlFor="Mothername">Enter the applicant's Mother's first and last name</label>
            </div>
            <div className="inputAndlabel col-4">
            <input
                id="Motherbirthdate" 
                value={values.Motherbirthdate}
                type="text"
                placeholder="Mother's date of birth"
                onChange={handleChange('Motherbirthdate')}
                />
                <label htmlFor="Motherbirthdate">Enter the applicant's Mother's date of birth</label>
            </div>
            <div className="inputAndlabel col-4">
            <input
                id="Motherjob" 
                value={values.Motherjob}
                type="text"
                placeholder="Mother's job"
                onChange={handleChange('Motherjob')}
                />
                <label htmlFor="Motherjob">Enter the applicant's Mother's job</label>
            </div>
            <div className="inputAndlabel col-4">
            <input
                id="Motherdatedeath" 
                value={values.Motherdatedeath}
                type="text"
                placeholder="Mother's date of death"
                onChange={handleChange('Motherdatedeath')}
                />
                <label htmlFor="Motherdatedeath">Enter the applicant's Mother's date of death</label>
            </div>
            <div className="inputAndlabel col-8">
            <input
                id="Motheraddress" 
                value={values.Motheraddress}
                type="text"
                placeholder="Mother's address and Zip code"
                onChange={handleChange('Motheraddress')}
                />
                <label htmlFor="Motheraddress">Enter the applicant's Mother's address and Zip code</label>
            </div>
            <div className="inputAndlabel col-12">
            <textarea
                id="Firstlasnamestepparentdetails" 
                value={values.Firstlasnamestepparentdetails}
                type="text"
                placeholder="First and last name of step-parents and their details"
                onChange={handleChange('Firstlasnamestepparentdetails')}
                />
                <label htmlFor="Firstlasnamestepparentdetails">Enter the applicant's first and last name of step-parents and their details</label>
            </div>
          </div>
          <div className="Cus2PersonalInfo"> <p>Siblings Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div className="Cus2Inputs">
            <div className="inputAndlabel col-4">
            <input
                id="Numberofsiblings" 
                value={values.Numberofsiblings}
                type="text"
                placeholder="Number of siblings"
                onChange={handleChange('Numberofsiblings')}
                />
                <label htmlFor="Numberofsiblings">Enter the number of applicant siblings</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Numberofjobs" 
                value={values.Numberofjobs}
                type="text"
                placeholder="Number of jobs"
                onChange={handleChange('Numberofjobs')}
                hidden
                />
                <label hidden htmlFor="Numberofjobs">Enter the applicant's last degree</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Numberofjobs" 
                value={values.Numberofjobs}
                type="text"
                placeholder="Number of jobs"
                onChange={handleChange('Numberofjobs')}
                hidden
                />
                <label hidden htmlFor="Numberofjobs">Enter the applicant's last degree</label>
            </div>
            <div className="mainGrayListRows col-12">
              {cusJobs.map(item =>(
                      <div key={item.key}>
                        <div className="grayListRow col-12">
                          <div className="GrayListBox">
                            <p><b>{item.Firstlastname}:</b>  {item.siblingaddress}</p>
                            <CloseCircle onClick={()=>handleRemoveCusJobs(item.key)} size="28" color="#9B9B9B" variant="Bold"/>
                          </div>
                        </div>
                      </div>))}
            </div>
           
            <div className="inputAndlabel col-4">
              <input
                id="Firstlastname" 
                value={values.Firstlastname}
                type="text"
                placeholder="First and last name"
                onChange={handleChange('Firstlastname')}
                />
                <label htmlFor="Firstlastname">Enter the first and last name of the applicant sibling</label>
            </div>

            <div className="inputAndlabel col-4">
              <input
                id="Dateofbirth" 
                value={values.Dateofbirth}
                type="text"
                placeholder="Date of birth"
                onChange={handleChange('Dateofbirth')}
                />
                <label htmlFor="Dateofbirth">Enter the date of birth of the applicant sibling</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Jobtitle" 
                value={values.Jobtitle}
                type="text"
                placeholder="Job title"
                onChange={handleChange('Jobtitle')}
                />
                <label htmlFor="Jobtitle">Enter the job title of the applicant's sibling</label>
            </div>
            
            <div className="inputAndlabel col-4">
              <input
                id="Dateofdeath" 
                value={values.Dateofdeath}
                type="text"
                placeholder="Date of death"
                onChange={handleChange('Dateofdeath')}
                />
                <label htmlFor="Dateofdeath">Enter the applicant's sibling date of death</label>
            </div>
            <div className="inputAndlabel col-6">
            <input
                id="siblingaddress" 
                value={values.siblingaddress}
                type="text"
                placeholder="sibling's address and Zip code"
                onChange={handleChange('siblingaddress')}
                />
                <label htmlFor="siblingaddress">Enter the applicant's sibling's address and Zip code</label>
            </div>
            <div className="inputAndlabel col-2">
              <a className="inputAndlabelAddA" onClick={handleAddJob}>+ Add</a>
            </div>
         </div>
         
          <div className="cusS1SaveColP5 col-12">
            <div className="col-6">
               <button  onClick={(dataCus11) => {childToParentCus11(dataCus11);}} className="btn col-12 btn-outline-light ">{t('Previous')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              <button className="btn col-6 btn-outline-light ">
                <ArchiveTick size="32" color="#D3A281"/>{t('Save Draft')}
              </button >
              {<button onClick={(dataCus11) => {childToParentCus11(dataCus11);}}  className="btn btn-dark  col-6">{t('Next Step')}</button>}
            </div>
           
          </div>
        </div>
        </form>
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;