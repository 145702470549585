import {ArrowUp2,ArrowDown2,ProfileAdd,Filter,Warning2,MessageText1,Star1} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Agents = ({childToParentMessage}) => {
    const {t, i18n} = useTranslation(['Login']);

    const handleSendMsgReDirect = (CustomerName) => {
        localStorage.setItem('CusNameMsg',CustomerName);
        
    }

    const [people , setPeople] = useState([
        {AgentPic:'CusPic.png', 
        AgentName:'behzad',
        AgentPhone:'+989143158460',
        AgentStatus:'Active',
        AgentClients:'24 ',
        AgentsCount:'4', 
        Email:'kambiz.fakhr@yahoo.com',
        isDistributorPlus:'',
        peopleA:[''],
        key:'129121'},
        
       
      ]);
    // const [peopleA , setPeopleA] = useState([
    // {AgentPic:'CusPic.png', AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ',AgentsCount:'4', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
    
    // ]);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].AgentStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].AgentStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName > b.AgentName) ? 1 : ((b.AgentName > a.AgentName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName < b.AgentName) ? 1 : ((b.AgentName < a.AgentName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (b.AgentClients - a.AgentClients)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.AgentClients-b.AgentClients)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    
    const handleFilterStatusBlur = () =>{ 
        // document.getElementById("FilterStatusBoxID").style.display="none";
        // setFilterBoxShowing(false);
    }
    const [values, setValues] = useState({
        Email: '',
        password: '',
        checked: '',
        refCode: '',
        showPassword: false,
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      }
    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteDistributorsByLawyer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ DistributorEmailAddress: values.Email ,LawyerCode:localStorage.getItem('lawyerCode') })
            }).then().then(setIsOpen2(!isOpen2));
    }
    const [isOpenRow, setisOpenRow] = useState(true);

    const handleAgentRowClick = (itemKey) =>{
        if(isOpenRow){
            document.getElementById(itemKey).style.display="flex";
            setisOpenRow(!isOpenRow);
        }
        else{
            document.getElementById(itemKey).style.display="none";
            setisOpenRow(!isOpenRow);
        }
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.AgentStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.AgentStatus== 'Deactive').length)));
    const [checkedFilter , setCheckedFilter]= useState(false);
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }

      fetch('https://telesiness.ir/api/Dashboards/LawyerDashboardDistributorsList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try{ 

                if(people.length<2)
                {
                    people.pop();
                    var MyStatus
                    if(response.distributorList[i].distributorStatus==true)
                     MyStatus = 'Active';
                     else
                     MyStatus = 'Deactive';

                    for (i in response.distributorList){
                        // console.log(response.distributorList[i].distributorFirstName);
                        // peopleA[i].AgentName = response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName;
                        // peopleA[i].AgentClients = response.distributorList[i].clientsCount;
                        // peopleA[i].AgentPhone = response.distributorList[i].distributorCode;
                        people.push({AgentPic:response.distributorList[i].distributorAvatar, 
                        AgentName:response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName,
                        AgentPhone:response.distributorList[i].distributorPhoneNumber,
                        AgentStatus:MyStatus,
                        AgentClients:response.distributorList[i].clientsCount, 
                        AgentsCount:response.distributorList[i].clientsCount,
                        Email:response.distributorList[i].email,
                        key:parseInt(i)+1,
                        AgentCity:response.distributorList[i].distributorCity,
                        AgentCountry:response.distributorList[i].distributorCountry,
                        isDistributorPlus:response.distributorList[i].isDistributorPlus,
                        peopleA: response.distributorList[i].clientsName,
                    },)
                    }
                }
                // if(peopleA.length<2)
                // {
                //     peopleA.pop();
                //     for (i in response.distributorList.clientsName){
     
                //         peopleA.push({
                //         AgentName:response.distributorList.clientsName[i].customerFirstName + " " + response.distributorList.clientsName[i].customerLastName,
                //         Email:response.distributorList.clientsName[i].email,
                //         key:response.distributorList.clientsName[i].customerCode,
                //         userId:response.distributorList.clientsName[i].userId,
                //        },)
                //     }
                // }
              
                
             }
  
            catch{}
            // console.log(response.distributorList[0].distributorFirstName);
            //   forceUpdate();
            // window.location.reload(false);
            
        });

        

        const [state, setState] = useState({ num: 0 });
        const counter = useRef(0);
        
        useEffect(() => {
          if (counter.current < 4) {
            counter.current += 1;
            const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
      
            return () => clearTimeout(timer);
          }
        }, [state]);
      
    return (  
        <><div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Distributers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                        <p className="pricesInfo">{t('Distributers List')}</p>
                    </div>
                </div>
                <button onClick={handleAddNewUserAgent} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Distributer')}<ProfileAdd size="24" color="#fff" /></button>
            </div>
            <div className="TopMainRowTwo">
                {/* <div className="col-1"><p>
                <input type="checkbox"></input>
                    </p> </div> */}
                <div className="col-2"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Distributer')} <a onClick={handleUpArrowAgent}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowAgent}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Phone Number')}</p></div>
                <div className="col-2"><p>{t('Email')}</p></div>
                <div className="col-2"><p>{t('Send Message')}</p></div>
               
                {/* <div className="col-2 Col-2Status">
                    <p>{t('Status')} </p><div className="FilterIconAgent"><button className="FilterBtn" onBlur={handleFilterStatusBlur} onClick={handleFilterStatus}>{!checkedFilter && <Filter  size="16"  color="#313131"/>}{checkedFilter && <Filter variant="Bold" size="16"  color="#313131"/>}</button></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div>
                <div className="col-1"><p>{t('Clients')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div> */}
                </div>
            <hr className="CustomerLine"/>
        </div><div className="mainRowCus col-12">
                {people.map(item =>(
                    <div className="itemBox2 col-12" key={item.key}>
                        <div className="agentCusRow col-12">
                        <a className="col-12 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                            <div className="col-2 id_agentCus"><p>{item.key}</p> </div>
                            <div className="CusPic2 col-lg-3 col-12">
                                <div className='img_agentCus'>
                                    {item.isDistributorPlus=='1' &&<Star1 className='star_ofdcpluslist' size="20" color="#007eee" variant="Bold"/>}
                                    {item.AgentPic!='' &&<img src={'https://telesiness.ir/AgentsAvatar/'+ item.AgentPic} alt="Avatar" />}
                                    {item.AgentPic==''&&<img src={`${baseUrl + 'CusPic.png'}`} alt="" />}
                                </div>
                                <div className='detail_agentCus'>
                                    <p>
                                        {item.AgentName} 
                                    </p>
                                    <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                                </div>
                            </div>
                            <div className="col-3 phone_agentCus"><p>{item.AgentPhone}</p></div>
                            <div className="col-2 mail_agentCus"><p>{item.Email}</p></div>
                            <div className="Send_Message">
                                <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                                document.getElementById('MessageText1Btn').style.backgroundColor='white';
                                document.getElementById('MessageText1BtnP').style.color='black';
                                document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
                                document.getElementById('Profile2UserBtnP').style.color='white';
                                localStorage.setItem('turnOffMeLawCusMsg',true);
                                localStorage.setItem('MsgUserNewName',item.AgentName);
                                localStorage.setItem('emailCus',item.Email);
                                localStorage.setItem('CusCode',item.key);
                                localStorage.setItem('MsgUserNewType',2);
                                childToParentMessage(dataMessage);
                                handleSendMsgReDirect(item.AgentName)}}/>
                                <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                            </div>
                            {/* {item.AgentStatus=='Active' && <div className="col-lg-2 col-6 de_active_agentCus">
                                <button  className="btn-sm active">
                                    {t('Active')}
                                </button></div>}

                            {item.AgentStatus=='Deactive' && <div className="col-lg-2 col-6 de_active_agentCus">
                                <button  className="btn-sm deactive">
                                    {t('Deactive')}
                                </button>                              
                                </div>}
                            <div className="col-1 count_agentCus"><p>{item.AgentClients}</p></div> */}
                        </a>
                    </div>  
                   {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                        <div className="hidenItemRow">
                            <hr className="linebetitem col-12"/>
                            <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                                document.getElementById('MessageText1Btn').style.backgroundColor='white';
                                document.getElementById('MessageText1BtnP').style.color='black';
                                document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
                                document.getElementById('Profile2UserBtnP').style.color='white';
                                localStorage.setItem('turnOffMeLawCusMsg',true);
                                localStorage.setItem('MsgUserNewName',item.AgentName);
                                localStorage.setItem('emailCus',item.Email);
                                localStorage.setItem('CusCode',item.key);
                                localStorage.setItem('MsgUserNewType',2);
                                childToParentMessage(dataMessage);
                                handleSendMsgReDirect(item.AgentName)}}/>
                            <div className="col-lg-4 col-12 hidenItem">
                                    <span>{t('Clients')}:</span>
                                    <p>{item.AgentClients}
                                    </p>
                                </div>
                            <div className="col-lg-4 col-12 hidenItem">
                                <select
                                    aria-label="Default"
                                    // labelId="demo-simple-select-Degree-helper-label"
                                    id="ChineseFamiliarityS"
                                    value={values.ChineseFamiliarityS}
                                    label={t('Type of Marriage')}
                                    // MenuProps={MenuProps}
                                    onChange={handleChange('ChineseFamiliarityS')}  
                                    placeholder={t('Familiarity with the chosen language')}
                                    >
                                        <option  value="">
                                        {t('Customers Name')}
                                    </option >
                                    {item.peopleA.map(item2 =>(
                                         <option value={item2.customerCode}>{item2.customerFirstName + " " + item2.customerLastName}</option >
                                    ))}

                                </select >
                            </div>
                            <div className="d-lg-none col-12 phone_agentCusm hidenItem"><span>{t('Phone Number')}:</span><p>{item.AgentPhone}</p></div>
                            <div className="d-lg-none col-12 mail_agentCusm hidenItem"><span>{t('Email')}:</span><p>{item.Email}</p></div>
                            <div className="col-lg-4 col-12 hidenItem"><span>{t('Country')}:</span> <p>{item.AgentCity}</p> </div>
                            <div className="col-lg-4 col-12 hidenItem"><span>{t('City')}:</span> <p>{item.AgentCountry}</p> </div>
                            {/* <div className="col-lg-4 col-12 hidenItem"><span>{t('Agents')}:</span> <p>{item.AgentsCount}</p> </div> */}
                        </div>
                    </div>}
                    <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                </div>
                        ))}
            </div>
            {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Why You wanna De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-md-3 col-12 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-md-3 col-12 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-md-3 col-12 btn btn-light">{t('Declined')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Distributer')}</h4>
            </div>
            <div className="PboxThirdRow">
            <p>{t('Please enter the email of the Distributer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.Email} onChange={handleChange('Email')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup2}
    />}
    </>
        
    );
}
 
export default Agents;