import {ArrowUp2,ArrowDown2,ProfileAdd,Filter,Warning2} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Agents = () => {
    const {t, i18n} = useTranslation(['Login']);

    const [people , setPeople] = useState([
        {AgentPic:'CusPic.png', AgentName:'',AgentPhone:'+',AgentStatus:'Active',AgentClients:' ', Email:'',key:'129121'},
      
      ]);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].AgentStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].AgentStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName > b.AgentName) ? 1 : ((b.AgentName > a.AgentName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName < b.AgentName) ? 1 : ((b.AgentName < a.AgentName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (b.AgentClients - a.AgentClients)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.AgentClients-b.AgentClients)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    
    const handleFilterStatusBlur = () =>{ 
        // document.getElementById("FilterStatusBoxID").style.display="none";
        // setFilterBoxShowing(false);
    }

    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteDistributorByDistributor' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ InviteEmailAddress: values.EmailC ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(setIsOpen2(!isOpen2));
        setIsOpen2(!isOpen2);
    }
    const [isOpenRow, setisOpenRow] = useState(true);

    const handleAgentRowClick = (itemKey) =>{
        if(isOpenRow){
            document.getElementById(itemKey).style.display="flex";
            setisOpenRow(!isOpenRow);
        }
        else{
            document.getElementById(itemKey).style.display="none";
            setisOpenRow(!isOpenRow);
        }
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.AgentStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.AgentStatus== 'Deactive').length)));
    const [checkedFilter , setCheckedFilter]= useState(false);
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }
      fetch('https://telesiness.ir/api/Dashboards/DistributorsList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try
             {
                
                if(people.length<2)
                {
                    people.pop();
                    var myActDec = ''
                for (i in response.distributorsList){
                    // people[i].CustomerName = response.distributorsList[i].customerFirstName + " " + response.distributorsList[i].customerLastName;
                    // people[i].CustomerCreated = response.distributorsList[i].customerRegisterDate;
                    
                    if(response.distributorsList[i].distributorStatus == false)
                    myActDec = "Deactive";
                    else
                    myActDec = "Active";
                    people.push( {
                    AgentClients:response.distributorsList[i].customerVisaName,
                    AgentPic:'CusPic.png', 
                    AgentName:response.distributorsList[i].distributorFirstName 
                    + " " + 
                    response.distributorsList[i].distributorLastName,
                    AgentPhone:response.distributorsList[i].distributorPhoneNumber,
                    AgentStatus:myActDec,
                    AdvocacyCode:response.distributorsList[i].distributorCode,
                    City:response.distributorsList[i].distributorCity,
                    Country:response.distributorsList[i].distributorCountry,
                    Address:response.distributorsList[i].distributorAddress,
                    Email:response.distributorsList[i].distributorEmailAddress,
                    Clients:response.distributorsList[i].distributorsClient,
                   
                    key:(parseInt(i)+1).toString(),},)
                    
                                 }
                 }
             }

            catch{

            }
            // console.log(response[0]);
            
        });

const [data, setData] = useState([]);

const fetchData = () => {
    fetch('https://telesiness.ir/api/Dashboards/DistributorLatestLawyers' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
           
             try
             {
                
                    setData(response.distributorList)
             }

            catch{

            }
            // console.log(response[0]);
            
        });
};
console.log(localStorage.getItem('email'));
useEffect(() => {
    fetchData();
    // console.log(data[0].countery_Name);
  }, []);
const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
if (counter.current < 4) {
counter.current += 1;
const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);

return () => clearTimeout(timer);
}
}, [state]);

const [values, setValues] = useState({

    LawyerCode: '',
    EmailC: '',

  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }
    return (  
        <><div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Distributers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('Distributers List')}</p>
                    </div>
                </div>
                <button onClick={handleAddNewUserAgent} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Distributer')}<ProfileAdd size="24" color="#fff" /></button>
            </div>
            <div className="TopMainRowTwo">
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('DS')} <a onClick={handleUpArrowAgent}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowAgent}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-2"><p>{t('Phone Number')}</p></div>
                <div className="col-3"><p>{t('Email')}</p></div>
                <div className="col-2 Col-2Status">
                    <p>{t('Status')} </p><div className="FilterIconAgent"><button className="FilterBtn" onBlur={handleFilterStatusBlur} onClick={handleFilterStatus}>{!checkedFilter && <Filter  size="16"  color="#313131"/>}{checkedFilter && <Filter variant="Bold" size="16"  color="#313131"/>}</button></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div>
                <div className="col-1"><p>{t('Clients')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div>
            </div>
            <hr className="CustomerLine"/>
        </div>
        <div className="mainRowCus col-12">
                {people.map(item =>( 
                    <div className="itemBox2 col-12" key={item.key}>
                        <div className="agentCusRow col-12">
                            <a className="col-12 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                                <div className="col-1 id_agentCus"><p>{item.key}</p> </div>
                                <div className="CusPic2 col-lg-3 col-8">
                                <div className='img_agentCus'>
                                        <img src={`${baseUrl + item.AgentPic}`} alt="" />
                                    </div> 
                                    <div className='detail_agentCus'>
                                        <p>{item.AgentName}</p>
                                        <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                                    </div>
                                </div>
                                <div className="col-2 phone_agentCus"><p>{item.AgentPhone}</p></div>
                                <div className="col-3 mail_agentCus"><p>{item.Email}</p></div>
                                {item.AgentStatus=='Active' && <div className="col-lg-2 col-4 de_active_agentCus">
                                    <button  className="btn-sm active">
                                        {t('Active')}
                                    </button></div>}

                                {item.AgentStatus=='Deactive' && <div className="col-lg-2 col-4 de_active_agentCus">
                                    <button  className="btn-sm deactive">
                                        {t('Deactive')}
                                    </button>
                                    </div>}
                                <div className="col-1 count_agentCus"><p>{item.Clients}</p></div>
                            </a>
                        </div>  
                        {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                            <div className="hidenItemRow">
                                <hr className="linebetitem col-12"/>
                                <div className="d-lg-none col-12 count_agentCusm hidenItem"><span>{t('Clients')}:</span><p>{item.Clients}</p></div>
                                <div className="d-lg-none col-12 phone_agentCusm hidenItem"><span>{t('Phone Number')}:</span><p>{item.AgentPhone}</p></div>
                                <div className="d-lg-none col-12 mail_agentCusm hidenItem"><span>{t('Email')}:</span><p>{item.Email}</p></div>
                                <div className="col-lg-4 col-12 hidenItem"><span>{t('Country')}:</span> <p>{item.Country}</p> </div>
                                <div className="col-lg-4 col-12 hidenItem"><span>{t('City')}:</span> <p>{item.City}</p> </div>
                                <div className="col-lg-4 col-12 hidenItem"><span>{t('Code')}:</span> <p>{item.AdvocacyCode}</p> </div>
                                <div className="col-12 hidenItem"><span>{t('Address')}:</span> <p>{item.Address}</p> </div>
                            </div>
                        </div>}
                        <ArrowDown2 className="arrowshow_list" size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                    </div>
                
                        ))}
            </div>
            {isOpen && <Popup
      content={<>
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Why You wanna De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-3 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-3 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-3 btn btn-light">{t('Declined')}</button>
            </div>
        
      </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
            <div className="PboxFirstRow"> 
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('Adding a new Distributer')}</h4>
            </div>
            <div className="PboxThirdRow">
            <p>{t('Please enter the email of the Distributer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.EmailC} onChange={handleChange('EmailC')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
      </>}
      handleClose={togglePopup2}
    />}
    </>
        
    );
}
 
export default Agents;