import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"


const Profile2 = ({childToParentCus3, childToParentCus5}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepOne' , {
        //   method: 'PUT',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ 
        //     // Email: localStorage.email3 ,
        //     // Password:localStorage.password3 ,
        //     FullName:"",
        //     Gender:"",
        //     DateOfBirth:"",
        //     AboutMe:""
        //   })
        //   }).then().then(history.push('/Dashboard'));
        // //     setIspending(false);
        // document.getElementById('personalCancel').style.display="none";
        // document.getElementById('personalSave').style.display="none";
        // document.getElementById('personPen').style.display="none";
        // document.getElementById('personPenBlue').style.display="none";
        // document.getElementById('proFirstName').setAttribute("disabled","disabled");
        // document.getElementById('proLastName').setAttribute("disabled","disabled");
        // document.getElementById('Gender').setAttribute("disabled","disabled");
        // //document.getElementById('birthDate').setAttribute("disabled","disabled");
     
        // document.getElementById('inputproAbout').setAttribute("disabled","disabled");
        // setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }

        const [values, setValues] = useState({
 
          LevelDegree: '',
          Fieldofstudy: '',
          Dateofdiplomagraduation: '',
          Entrydate:'',
          Dateofgraduation:'',
          More:'',
  
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });

        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus5 = true;
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <form onSubmit={handleSubmitPersonal} className="col-12">
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">Step 1 :</p><p className="Cus2Step1BlackP">Complete Basic Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div> <p className="Cus2Step1SmallP">Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy</p></div>
          <div className="Cus2PersonalInfo"> <p>Educational Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div className="Cus2Inputs">
            <div className="inputAndlabel col-4">
              <input
                id="LevelDegree" 
                value={values.LevelDegree}
                type="text"
                placeholder="Level/Degree"
                onChange={handleChange('LevelDegree')}
                />
                <label htmlFor="LevelDegree">Enter the applicant's last degree</label>
            </div>
            
            <div className="inputAndlabel col-4">
              <input
                id="Fieldofstudy" 
                value={values.Fieldofstudy}
                type="text"
                placeholder={t('Field of Study')}
                onChange={handleChange('Fieldofstudy')}
                />
                <label htmlFor="Fieldofstudy">{t("Enter the applicant's Field of Study")}</label>
            </div>

            <div className="inputAndlabel col-4">
              <input
                id="Dateofdiplomagraduation" 
                value={values.Dateofdiplomagraduation}
                type="text"
                placeholder="Date of diploma graduation"
                onChange={handleChange('Dateofdiplomagraduation')}
                />
                <label htmlFor="Dateofdiplomagraduation">Enter the date of diploma graduation of the applicant</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Entrydate" 
                value={values.Entrydate}
                type="text"
                placeholder="Entry date"
                onChange={handleChange('Entrydate')}
                />
                <label htmlFor="Entrydate">Enter the entry date of the applicant's university</label>
            </div>
            
            <div className="inputAndlabel col-4">
              <input
                id="Dateofgraduation" 
                value={values.Dateofgraduation}
                type="text"
                placeholder="Date of graduation"
                onChange={handleChange('Dateofgraduation')}
                />
                <label htmlFor="Dateofgraduation">Enter the date of graduation of the applicant</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Dateofgraduation2" 
                value={values.Dateofgraduation}
                type="text"
                placeholder="Date of graduation"
                onChange={handleChange('Dateofgraduation')}
                hidden
                />
                <label hidden htmlFor="Dateofgraduation">Enter the date of graduation of the applicant</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="More" 
                value={values.More}
                type="text"
                placeholder="More..."
                onChange={handleChange('More')}
                />
                <label htmlFor="More">Enter more details about the applicant's educational information if needed</label>
            </div>
         </div>
         
          <div className="cusS1SaveColP4 col-12">
            <div className="col-6">
               <button  onClick={(dataCus3) => {childToParentCus3(dataCus3);}} className="btn col-12 btn-outline-light ">{t('Previous')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              <button className="btn col-6 btn-outline-light ">
                <ArchiveTick size="32" color="#D3A281"/>{t('Save Draft')}
              </button >
              {<button onClick={(dataCus5) => {childToParentCus5(dataCus5);}} className="btn btn-dark  col-6">{t('Next Step')}</button>}
            </div>
           
          </div>
        </div>
        </form>
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;