import chart1 from './images/Chart1.png';
import chart2 from './images/Chart2.png';
import chart3 from './images/Chart3.png';
import onePic from './images/one.png';
import twoPic from './images/two.png';
import {AddSquare,Star1,Warning2,Calendar2,Briefcase,CardTick1,CardRemove1,CardEdit} from 'iconsax-react';
import Popup from './Popup';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const Home = () => {


    const {t, i18n} = useTranslation(['Login']);
    const forceUpdate = useForceUpdate();
    const [people , setPeople] = useState([
        {visa:'false',CustomerPic:'CusPic.png', CustomerName:'',CustomerPhone:'+',CustomerStatus:'Active',CustomerCreated:'', CustomerAgent:'',key:'1000'},
       
      ]);

      const [peopleA , setPeopleA] = useState([
        {AgentPic:'CusPic.png', AgentName:'',AgentPhone:'+',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
      
      ]);

      const [ServicesPrice , setServicesPrice] = useState([
        {pen:"true",tick:"false",SName:'Business Visa', SPrice:'450',SFee:'4',SOther:'false',SOtherDesc:'24 ', key:'1000'},
      ]);


      const [values, setValues] = useState({
        Email: '',
        EmailA: localStorage.getItem('email'),
        LEmail:'',
        Lname:'',
        Lphone:'',
        Lcode:'',
        checked: '',
        MarketerCode: localStorage.getItem('marketerCode'),
        showPassword: false,
      });
      const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    const handleDeactiveClick = (itemKey) =>{
        setIsOpen(!isOpen);
        for(var i in people){
            if(people[i].key==itemKey){
                people[i].CustomerStatus='Deactive';
                setVisaId(i);
            }
            
        }
        forceUpdate() ;
        setPeople(people);
    
    }
    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
   const [isOpen, setIsOpen] = useState(false);
   const [isOpen2, setIsOpen2] = useState(false);
   const [isOpen3, setIsOpen3] = useState(false);
   const [visaID,setVisaId]=useState(-1);
   const handleVisaPbox = (itemKey) =>{
    people[visaID].visa='Visa';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}

const handleRejectedPbox = (itemKey) =>{
    people[visaID].visa='Rejected';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}


const handleDeclinedPbox = (itemKey) =>{
    people[visaID].visa='Declined';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}
const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const togglePopup2 = () => {
      setIsOpen2(!isOpen2);
    }
    const togglePopup3 = () => {
        setIsOpen3(!isOpen3);
      }
    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen3(!isOpen3);
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      }
      const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomersByMarketer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ CustomerEmailAddress: values.EmailA ,MarketerCode:values.MarketerCode })
            }).then().then(setIsOpen2(!isOpen2));
        setIsOpen2(!isOpen2)
        
    }
    const handleAddUserAgentSendEmail = (itemKey) =>{
        // fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomersByMarketer' , {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ CustomerEmailAddress: values.EmailA ,MarketerCode:values.MarketerCode })
        //     }).then().then(setIsOpen2(!isOpen3));
        // setIsOpen3(!isOpen3)
        
    }


    fetch('https://telesiness.ir/api/Dashboards/MarketerDistributor' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({Email: localStorage.getItem('email')})
        }).then(response => response.json()).then(response=>{
             var i =0;
             
               values.Lname = response.customerDistributor.distributorFirstName + ' ' + response.customerDistributor.distributorLastName;
               values.Lphone = response.customerDistributor.distributorPhoneNumber;
               values.LEmail = response.customerDistributor.email;
               values.Lcode = response.customerDistributor.distributorCode;
               
                
            // console.log(response[0]);
            
        });

        fetch('https://telesiness.ir/api/Dashboards/ListOfDistributorService' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: localStorage.getItem('email'),})
            }).then(response => response.json()).then(response=>{
                 var i =0;
                 try
                 {
                     
                    if(ServicesPrice.length<2)
                    {
                        ServicesPrice.pop();
                        var myActDec = ''
                    for (i in response.distributorService){
                       
                        ServicesPrice.push( {pen:"true",tick:"false",
                        SName:response.distributorService[i].serviceName, 
                        SPrice:response.distributorService[i].servicePrice,
                        SFee:response.distributorService[i].serviceOffer,
                        distributorCode:response.distributorService[i].distributorCode,
                        SOtherDesc:response.distributorService[i].requiredDocuments, 
                        publicDistributorOffer:response.distributorService[i].publicDistributorOffer,
                        key:(parseInt(i)+1).toString()},)
                                     }
                     }
                 }
    
                catch{
    
                }
                // console.log(response[0]);
                
            });
            fetch('https://telesiness.ir/api/Dashboards/MarketerCustomersListOnHome' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: localStorage.getItem('email') })
                }).then(response => response.json()).then(response=>{
                        var i =0;
                        try
                        {
                            console.log(people.length);
                        if(people.length<2)
                        {
                            people.pop();
                            var myActDec = ''
                        for (i in response.customersListViewModels){
                            // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                            // people[i].CustomerCreated = response[i].customerRegisterDate;
                            if(response.customersListViewModels[i].verifyCustomerByDistributor == 2)
                            myActDec = "Deactive";
                            else  if(response.customersListViewModels[i].verifyCustomerByDistributor == 1)
                            myActDec = "Active";
                            else
                            myActDec = "Pending";
                            people.push( {
                                visa:'false',
                                CustomerPic:'CusPic.png', 
                                CustomerName:response.customersListViewModels[i].customerFirstName + " " + response.customersListViewModels[i].customerLastName,
                                CustomerPhone:'',
                                CustomerStatus:myActDec,
                                CustomerCreated:response.customersListViewModels[i].customerRegisterDate, 
                                CustomerAgent:'',
                                key:(parseInt(i)+1).toString()},)
                                            }
                            }
                        }
        
                    catch{
        
                    }
                    // console.log(response[0]);
                    
                });

        const [state, setState] = useState({ num: 0 });
        const counter = useRef(0);
        
        useEffect(() => {
          if (counter.current < 4) {
            counter.current += 1;
            const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
      
            return () => clearTimeout(timer);
          }
        }, [state]);





    return ( 
//strat responsive + delete class of p in homeCell2
        <div className="HomeMainRow home_agentandlawyer col-12">
            <div className="HomeRow1">
                <div className="total_summary col-12">
                <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                            <div className="homeCell1">
                            {/* <img src={chart1} alt="" /> */}
                            <CardTick1 size="64" color="#37d67a" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Completed Files')}</h4>
                                <p>+0</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart2} alt="" /> */}
                            <CardRemove1 size="64" color="#f47373" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Canceled files')}</h4>
                                <p>+0</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart3} alt="" /> */}
                            <CardEdit  size="64" color="#2ccce4" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('In progress files')}</h4>
                                <p>+0</p>
                            </div>
                        </div>
                    </div>
                </Carousel>
                <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                            <div className="homeCell1">
                            {/* <img src={chart1} alt="" /> */}
                            <CardTick1 size="64" color="#37d67a" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Completed Files')}</h4>
                                <p>+0</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart2} alt="" /> */}
                            <CardRemove1 size="64" color="#f47373" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Canceled files')}</h4>
                                <p>+0</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart3} alt="" /> */}
                            <CardEdit  size="64" color="#2ccce4" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('In progress files')}</h4>
                                <p>+0</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="HomeRow2 home_dashpan col-12">
                <div className="total_HomeLightBoxRowTwo col-lg-10 col-12">
                <div className="total_HomeLightBoxRowTwo col-lg-12 col-12">
                <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeR2R1 outer_box">
                            {/* <p>{t('DS Information')}</p> */}
                        </div>
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('DS Information')}</p>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    <div className="img_CusHomeR2">
                                        <img src={onePic} alt="" />
                                    </div>
                                    <p className='CusHomeP'><b>{values.Lname}</b></p>
                                    <p className='CusHomePGray'>{values.Lphone}</p>
                                    <p className='CusHomeP'>{t('Email Address')} :{values.LEmail}</p>
                                    <p className='CusHomeP'>{t('Advocacy Code')} :{values.Lcode}</p>
                                </div>
                            </div>  
                        </div>  
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('My Services')}</p>
                                {/* <AddSquare className='AddUserHome' onClick={childToParentPrices} size="40" color="#000000" variant="Bold"/> */}
                            </div>                             
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                {ServicesPrice.map(item =>(
                    <div  key={item.key}>
                        
                       <div className="item_tariff col-12">
                          <div className="agentCusRow col-12">
                           {<div className="col-1"><span>{t('ID')}</span><p>{item.key}</p> </div>}
                           {item.pen == "true" &&<div className="col-3"><span>{t('Services')}</span><p>{item.SName}</p>
                            {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a><InfoCircle size="18" color="#313131"/></a>
                                </div>}  */}
                                <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div>
                            </div>}
                            {item.pen == "true" &&<div className="col-3"><span>{t('Price')}</span><p>{item.SPrice}</p></div>}
                            {item.pen == "true" && <div className="col-lg-2 col-2"><span>{t('Offer')}</span><p>{"% " +item.SFee}</p></div>}
                            {item.pen == "true" && <div className="col-lg-2 col-3"><span>{t('Public Offer')}</span><p className='oldOfferPriceMarketer'>{"% " + item.publicDistributorOffer}</p></div>}
                            {/* {item.tick == "true" &&<div className="CusPic col-3"><input className="col-12" onChange={handleChange('Service')} type="text" value={ values.Service}/></div>}
                            {item.tick == "true" &&<div className="col-2 "><input className="col-12" onChange={handleChange('proLastName')} type="text" value={ values.proLastName}/></div>}
                            {item.tick == "true" && <div className="col-4"><input className="col-12" onChange={handleChange('Fee')} type="text" value={ values.Fee}/></div>}   */}
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                <a >
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                </a>
                            </div>}  */}
                            {/* <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                            {/* <div className="col-2 PenAndTrash">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>handlePenClick(item.key)} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                            {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key)} size="18" variant="Bold" color="#21D350" className="PriceListIcons"/>}
                            <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/>
                            </div> */}
                           </div>   
                       </div>
                    </div>
                   
                   
                        ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                    </Carousel> 
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12 d-none d-lg-block">
                        <div className="HomeR2R1 outer_box">
                            <p>{t('DS Information')}</p>
                        </div>
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('DS Information')}</p>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                <div className="img_CusHomeR2">
                                        <img src={onePic} alt="" />
                                    </div>
                                    <p className='CusHomeP'><b>{values.Lname}</b></p>
                                    <p className='CusHomePGray'>{values.Lphone}</p>
                                    <p className='CusHomeP'>{t('Email Address')} :{values.LEmail}</p>
                                    <p className='CusHomeP'>{t('Advocacy Code')} :{values.Lcode}</p>
                                </div>
                            </div>  
                        </div>  
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('My Services')}</p>
                                {/* <AddSquare className='AddUserHome' onClick={childToParentPrices} size="40" color="#000000" variant="Bold"/> */}
                            </div>                             
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                <div>
                                    <div className="item_tariff head_rowserv col-12">
                                    <div className="agentCusRow col-12">
                                        {<div className="col-1"><p>{t('ID')}</p> </div>}
                                        {<div className="col-3"><p>{t('Services')}</p>
                                        {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                                <a><InfoCircle size="18" color="#313131"/></a>
                                            </div>}  */}
                                            {/* <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div> */}
                                        </div>}
                                        {<div className="col-3"><p>{t('Price')}</p></div>}
                                        { <div className="col-lg-2 col-2"><p>{t('Offer')}</p></div>}
                                        { <div className="col-lg-2 col-3"><p>{t('Public Offer')}</p></div>}
                                        </div>   
                                    </div>
                                </div>
                                {ServicesPrice.map(item =>(
                    <div  key={item.key}>
                        
                       <div className="item_tariff col-12">
                          <div className="agentCusRow col-12">
                           {<div className="col-1 id_tariff"><p>{item.key}</p> </div>}
                           {item.pen == "true" &&<div className="col-lg-3"><p>{item.SName}</p>
                            {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a><InfoCircle size="18" color="#313131"/></a>
                                </div>}  */}
                                <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div>
                            </div>}
                            {item.pen == "true" &&<div className="col-lg-3 price_tariff"><p>{item.SPrice}</p></div>}
                            {item.pen == "true" && <div className="col-lg-2"><p>{"%" +item.SFee}</p></div>}
                            {item.pen == "true" && <div className="col-lg-2"><p className='oldOfferPriceMarketer'>{"%" + item.publicDistributorOffer}</p></div>}
                            {/* {item.tick == "true" &&<div className="CusPic col-3"><input className="col-12" onChange={handleChange('Service')} type="text" value={ values.Service}/></div>}
                            {item.tick == "true" &&<div className="col-2 "><input className="col-12" onChange={handleChange('proLastName')} type="text" value={ values.proLastName}/></div>}
                            {item.tick == "true" && <div className="col-4"><input className="col-12" onChange={handleChange('Fee')} type="text" value={ values.Fee}/></div>}   */}
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                <a >
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                </a>
                            </div>}  */}
                            {/* <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                            {/* <div className="col-2 PenAndTrash">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>handlePenClick(item.key)} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                            {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key)} size="18" variant="Bold" color="#21D350" className="PriceListIcons"/>}
                            <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/>
                            </div> */}
                           </div>   
                       </div>
                    </div>
                   
                   
                        ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-12 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Customers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserCustomer} size="40" color="#000000" variant="Bold"/>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    {people.map(item =>(
                                        
                                        <div className="itemBox col-12"  key={item.key}>
                                        {/* <div className="col-1"><p>
                                        <input type="checkbox"></input>
                                            </p> </div> */}
                                        {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                        <div className="CusPic2 col-5"><p>{item.CustomerName}</p></div>
                                        {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                        {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                        {item.CustomerStatus=='Active' && <div className="col-3 btn_de_active">
                                            <button   className="btn-sm active">
                                                {t('Active') }
                                                {/* <div id={item.key} className="ChoosBox"> 
                                                    <a className='disabled'>{t('Active')}</a>  <br />
                                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                                </div> */}
                                            </button></div>}

                                        {item.CustomerStatus=='Deactive' && <div className="col-3 btn_de_active">
                                                <button  className="btn-sm deactive">
                                                    {t('Deactive')}
                                                    
                                                    {/* <div id={item.key} className="ChoosBox"> 
                                                        <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                                        <a className='disabled'>{t('Deactive')}</a>
                                                    </div> */}
                                                </button>
                                            </div>}
                                            {item.CustomerStatus=='Pending' && <div className="col-lg-3 col-6 de_active_agentCus">
                            <button  className="btn-sm Pending">
                                {t('Pending')}
                                {/* <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                </div> */}
                            </button>
                            
                        </div>}
                                        <div className="col-4 collist_item"><p>{item.CustomerCreated.split('T',1)}</p></div>                
                                    </div>
                                            ))}
                                </div>
                            </div>
                        </div>    
                    </div>
                   
                </div>
                <div className="box_HomeLightBoxRowTwo col-lg-2 col-md-12 col-12">
                    <div className="HomeLightBoxRowTwo HomeCol3Row2">
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox"> 
                                <Calendar2 size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Registery Date')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('registerDate').split('T',1)}</p>
                        </div>
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox"> 
                                <Briefcase className='HomeBrifcasePad' size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Agency Code')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('marketerCode')}</p>
                        </div>
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="Star svg_HomeLightBox" onClick={togglePopup}>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131"/>
                                <Star1 size="24" color="#313131"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Your Score in Telesiness Shows Your Activity')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('You can Improve to Distributer if You Can Catch 5 Star')}</p>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Your Score need 6 invitation to next Star')}</p>
                
            </div>
            <div className="Star" onClick={togglePopup}>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131"/>
                <Star1 size="24" color="#313131"/>
            </div>
            <p className='HomeCol3P1 Star' onClick={togglePopup}>720</p>
            {/* <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-3 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-3 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-3 btn btn-light">{t('Declined')}</button>
            </div> */}
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
     {isOpen2 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.Email} onChange={handleChange('Email')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup2}
    />}
    {isOpen3 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">    
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Adding a New Lawyer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the lawyer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailA} onChange={handleChange('EmailA')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup3}
    />}
        </div>
                
     );
}
 
export default Home;