import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"


const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
        e.preventDefault();
    localStorage.setItem('firstTimeCustomer',false);
    console.log((localStorage.getItem('firstTimeCustomer')));
    childToParentCus1e3(dataCus1);
    }
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({
          // proFirstName: 'kambiz',
          // proLastName: 'fakhr',
          // Gender: 'male',
          // birthDate: new Date(),
          // proEmail: 'kambiz.fakhr@yahoo.com',
          // proPhone:'3352258774',
          // proAbout:'I am Sofware Engineer',
          // firstTimeEdit:true,
          // firstTimeEditJob:true,
          // firstTimeEditEducational:true,
          // firstTimeEditAddress:true,
          // firstTimeEditPersonal:true,
          ProPic:'',
          // Country:'Iran',
          // City:'Tehran',
          // Address:'Iran - Tehran - Vanak - Zafar ',
          // ZipCode:'5174875988',
          // proNo:'61',
          // Level:'',
          // FieldOfStudy:'',
          // University:'',
          // JobCountry:'USA',
          // JobCity:'',
          // Jobspecialty:'',
          JobUpload:'',
          // iccrc:'',
          // website:'',
          // Specialization:'',
          // Certificate:'',
          // userNameLast:'setin',
          // showPassword: false,
          proFirstName: '',
          proLastName: '',
          Gender: '',
          IDNumber:'',
          NationalCode:'',
          birthDate: '',
          proEmail: '',
          proPhone: '',
          proCallNumber:"",
          proPhone2: '',
          proCallNumber2:"",
          proAbout: '',
          firstTimeEdit:'',
          firstTimeEditJob:'',
          firstTimeEditEducational:'',
          firstTimeEditAddress:true,
          firstTimeEditPersonal:true,
          // ProPic: '',
          Country: '',
          City: '',
          Address: '',
          ZipCode: '',
          Address2: '',
          ZipCode2: '',
          placeofBirth:'',
          proNo:'61',
          Level: '',
          FieldOfStudy: '',
          University: '',
          JobCountry: '',
          JobCity:'',
          Jobspecialty: '',
          PassportNumber: '',
          Name:'',
          Family:'',
          DateofCreation: '',
          ExpireDate:'',
          TotalScoreDegreeIELTS:'',
          TotalScoreDegreeTOEFL:'',
          TotalScoreDegreeOtherOne:'',
          TotalScoreDegreeOtherTwo:'',
          TotalScoreDegreeOtherThree:'',
          CountryOFPass:'',
          BirthDayOnPass:'',
          BirthCertificateNumber:'',
          PlaceIssuanceNationalCard:'',
          FatherBirthCertificateNumber:'',
          MothersBirthCertificateNumber:'',
          AliasName:'',
          MilitaryCardNumber:'',
          TypeMilitaryCard:'',
          PlaceMilitaryService:'',
          IELTSReadingScore:'',
          IELTSWritingScore:'',
          IELTSListeningScore:'',
          IELTSSpeackingScore:'',
          TOEFLReadingScore:'',
          TOEFLWritingScore:'',
          TOEFLListeningScore:'',
          TOEFLSpeackingScore:'',
          OtherOneReadingScore:'',
          OtherOneWritingScore:'',
          OtherOneListeningScore:'',
          OtherOneSpeackingScore:'',
          OtherTwoReadingScore:'',
          OtherTwoWritingScore:'',
          OtherTwoListeningScore:'',
          OtherTwoSpeackingScore:'',
          OtherThreeReadingScore:'',
          OtherThreeWritingScore:'',
          OtherThreeListeningScore:'',
          OtherThreeSpeackingScore:'',
          WorkplaceCallNumber2:'',
          FormTrackingNumber:'',
          TypeMarriage:'',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };
console.log(dataC);
        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


              const [image13 , setImage13] = useState(null);
              const   onImage13Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader13 = new FileReader();
                    reader13.onload = (e) => {
                      setImage13(e.target.result);
                      document.getElementById('target13').style.display ="block";
                      document.getElementById('MyGrayBox13').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader13.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage13 = () =>{
                  document.getElementById('target13').style.display ="none";
                  document.getElementById('MyGrayBox13').style.display= "flex";
                }

        const [DateofIELTSdegreevalue, setDateofIELTSdegreeValue] = useState()
        const [DateofTOEFLdegreevalue, setDateofTOEFLdegreeValue] = useState()
        const [DateofOther1degreevalue, setDateofOther1degreeValue] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 2-1')} :</p><p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant Foreign Language Information')}{localStorage.getItem('whoIs')=='sp' && t('Applicant Spouse Foreign Language Information')}</p> <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Language Certifications')}</p> </div>
          
          <div className="Cus2Inputs">

            <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="EnglishLanguageStatus"
                  value={values.EnglishLanguageStatus}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('EnglishLanguageStatus')}  
                  placeholder={t('English Language Status')}
                  required
                  >
                    <option  value="">
                    {t('English Language Status')}
                  </option >
                  <option  value='1'>{t('I Have')}</option >
                  <option  value='2'>{t('I do not have')}</option >
                  <option  value='3'>{t('I am reading')}</option >
                  <option  value='4'>{t('I will be able to get a degree in the next 6 months')}</option >
                  <option  value='5'>{t('I will not be able to get a degree for the next 8 months')}</option > 
                </select >
                <label htmlFor="EnglishLanguageStatus">{t('Enter the applicants English Language Status')}<p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('IELTS')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofIELTSdegreevalue}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of IELTS degree')}
              id="DateofIELTSdegree"
              onChange={setDateofIELTSdegreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofIELTSdegree">{t('Enter the applicants Date of IELTS degree')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeIELTS" 
                value={values.TotalScoreDegreeIELTS}
                type="text"
                placeholder={t('The total score of the degree')}
                onChange={handleChange('TotalScoreDegreeIELTS')}
                />
                <label htmlFor="TotalScoreDegreeIELTS">{t('Enter the applicants total score of the degree')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="IELTSReadingScore" 
                value={values.IELTSReadingScore}
                type="text"
                placeholder={t('IELTS Reading Score')}
                onChange={handleChange('IELTSReadingScore')}
                />
                <label htmlFor="IELTSReadingScore">{t('Enter the applicants IELTS Reading Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="IELTSWritingScore" 
                value={values.IELTSWritingScore}
                type="text"
                placeholder={t('IELTS Writing Score')}
                onChange={handleChange('IELTSWritingScore')}
                />
                <label htmlFor="IELTSWritingScore">{t('Enter the applicants IELTS Writing Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="IELTSListeningScore" 
                value={values.IELTSListeningScore}
                type="text"
                placeholder={t('IELTS Listening Score')}
                onChange={handleChange('IELTSListeningScore')}
                />
                <label htmlFor="IELTSListeningScore">{t('Enter the applicants IELTS Listening Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="IELTSSpeackingScore" 
                value={values.IELTSSpeackingScore}
                type="text"
                placeholder={t('IELTS Speacking Score')}
                onChange={handleChange('IELTSSpeackingScore')}
                />
                <label htmlFor="IELTSSpeackingScore">{t('Enter the applicants IELTS Speacking Score')}</label>
            </div>

            

           
  
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('TOEFL')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofTOEFLdegreevalue}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of TOEFL degree')}
              id="DateofTOEFLdegree"
              onChange={setDateofTOEFLdegreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofTOEFLdegree">{t('Enter the applicants Date of TOEFL degree')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeTOEFL" 
                value={values.TotalScoreDegreeTOEFL}
                type="text"
                placeholder={t('The total score of the degree')}
                onChange={handleChange('TotalScoreDegreeTOEFL')}
                />
                <label htmlFor="TotalScoreDegreeTOEFL">{t('Enter the applicants total score of the degree')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="TOEFLReadingScore" 
                value={values.TOEFLReadingScore}
                type="text"
                placeholder={t('TOEFL Reading Score')}
                onChange={handleChange('TOEFLReadingScore')}
                />
                <label htmlFor="TOEFLReadingScore">{t('Enter the applicants TOEFL Reading Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="TOEFLWritingScore" 
                value={values.TOEFLWritingScore}
                type="text"
                placeholder={t('TOEFL Writing Score')}
                onChange={handleChange('TOEFLWritingScore')}
                />
                <label htmlFor="TOEFLWritingScore">{t('Enter the applicants TOEFL Writing Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="TOEFLListeningScore" 
                value={values.TOEFLListeningScore}
                type="text"
                placeholder={t('TOEFL Listening Score')}
                onChange={handleChange('TOEFLListeningScore')}
                />
                <label htmlFor="TOEFLListeningScore">{t('Enter the applicants TOEFL Listening Score')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="TOEFLSpeackingScore" 
                value={values.TOEFLSpeackingScore}
                type="text"
                placeholder={t('TOEFL Speacking Score')}
                onChange={handleChange('TOEFLSpeackingScore')}
                />
                <label htmlFor="TOEFLSpeackingScore">{t('Enter the applicants TOEFL Speacking Score')}</label>
            </div>

            

          <div className="Cus2PersonalInfo col-12"> 
            <p>{t('Other Language (1)')}</p> 
          </div>
            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofOther1degreevalue}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Other Language degree (1)')}
              id="DateofOther1degree"
              onChange={setDateofOther1degreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofOther1degree">{t('Enter the applicants Date of Other Language degree') + '(1)'}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeOtherOne" 
                value={values.TotalScoreDegreeOtherOne}
                type="text"
                placeholder={t('The total score of the Other Language degree(1)')}
                onChange={handleChange('TotalScoreDegreeOtherOne')}
                />
                <label htmlFor="TotalScoreDegreeOtherOne">{t('Enter the applicants total score of the Other Language degree(1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherOneReadingScore" 
                value={values.OtherOneReadingScore}
                type="text"
                placeholder={t('Other Language Reading Score(1)')}
                onChange={handleChange('OtherOneReadingScore')}
                />
                <label htmlFor="OtherOneReadingScore">{t('Enter the applicants Other Language Reading Score(1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherOneWritingScore" 
                value={values.OtherOneWritingScore}
                type="text"
                placeholder={t('Other Language Writing Score(1)')}
                onChange={handleChange('OtherOneWritingScore')}
                />
                <label htmlFor="OtherOneWritingScore">{t('Enter the applicants Other Language Writing Score(1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherOneListeningScore" 
                value={values.OtherOneListeningScore}
                type="text"
                placeholder={t('Other Language Listening Score(1)')}
                onChange={handleChange('OtherOneListeningScore')}
                />
                <label htmlFor="OtherOneListeningScore">{t('Enter the applicants Other Language Listening Score(1)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherOneSpeackingScore" 
                value={values.OtherOneSpeackingScore}
                type="text"
                placeholder={t('Other Language Speacking Score(1)')}
                onChange={handleChange('OtherOneSpeackingScore')}
                />
                <label htmlFor="OtherOneSpeackingScore">{t('Enter the applicants Other Language Speacking Score(1)')}</label>
            </div>
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Other Language (2)')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofOther2degreevalue}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Other Language degree (2)')}
              id="DateofOther2degree"
              onChange={setDateofOther2degreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofOther2degree">{t('Enter the applicants Date of Other Language degree') + '(2)'}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeOtherTwo" 
                value={values.TotalScoreDegreeOtherTwo}
                type="text"
                placeholder={t('The total score of the Other Language degree(2)')}
                onChange={handleChange('TotalScoreDegreeOtherTwo')}
                />
                <label htmlFor="TotalScoreDegreeOtherTwo">{t('Enter the applicants total score of the Other Language degree(2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherTwoReadingScore" 
                value={values.OtherTwoReadingScore}
                type="text"
                placeholder={t('Other Language Reading Score(2)')}
                onChange={handleChange('OtherTwoReadingScore')}
                />
                <label htmlFor="OtherTwoReadingScore">{t('Enter the applicants Other Language Reading Score(2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherTwoWritingScore" 
                value={values.OtherTwoWritingScore}
                type="text"
                placeholder={t('Other Language Writing Score(2)')}
                onChange={handleChange('OtherTwoWritingScore')}
                />
                <label htmlFor="OtherTwoWritingScore">{t('Enter the applicants Other Language Writing Score(2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherTwoListeningScore" 
                value={values.OtherTwoListeningScore}
                type="text"
                placeholder={t('Other Language Listening Score(2)')}
                onChange={handleChange('OtherTwoListeningScore')}
                />
                <label htmlFor="OtherTwoListeningScore">{t('Enter the applicants Other Language Listening Score(2)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherTwoSpeackingScore" 
                value={values.OtherTwoSpeackingScore}
                type="text"
                placeholder={t('Other Language Speacking Score(2)')}
                onChange={handleChange('OtherTwoSpeackingScore')}
                />
                <label htmlFor="OtherTwoSpeackingScore">{t('Enter the applicants Other Language Speacking Score(2)')}</label>
            </div>

            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Other Language (3)')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-6 col-12">
            {  <DatePicker 
              value={DateofOther3degreevalue}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Other Language degree (3)')}
              id="DateofOther3degree"
              onChange={setDateofOther3degreeValue} 
              className="col-12"
            />}
              <label htmlFor="DateofOther3degree">{t('Enter the applicants Date of Other Language degree') + '(3)'}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="TotalScoreDegreeOtherThree" 
                value={values.TotalScoreDegreeOtherThree}
                type="text"
                placeholder={t('The total score of the Other Language degree(3)')}
                onChange={handleChange('TotalScoreDegreeOtherThree')}
                />
                <label htmlFor="TotalScoreDegreeOtherThree">{t('Enter the applicants total score of the Other Language degree(3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherThreeReadingScore" 
                value={values.OtherThreeReadingScore}
                type="text"
                placeholder={t('Other Language Reading Score(3)')}
                onChange={handleChange('OtherThreeReadingScore')}
                />
                <label htmlFor="OtherThreeReadingScore">{t('Enter the applicants Other Language Reading Score(3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherThreeWritingScore" 
                value={values.OtherThreeWritingScore}
                type="text"
                placeholder={t('Other Language Writing Score(3)')}
                onChange={handleChange('OtherThreeWritingScore')}
                />
                <label htmlFor="OtherThreeWritingScore">{t('Enter the applicants Other Language Writing Score(3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherThreeListeningScore" 
                value={values.OtherThreeListeningScore}
                type="text"
                placeholder={t('Other Language Listening Score(3)')}
                onChange={handleChange('OtherThreeListeningScore')}
                />
                <label htmlFor="OtherThreeListeningScore">{t('Enter the applicants Other Language Listening Score(3)')}</label>
            </div>

            <div className="inputAndlabel col-lg-3 col-md-6 col-12">
              <input
                id="OtherThreeSpeackingScore" 
                value={values.OtherThreeSpeackingScore}
                type="text"
                placeholder={t('Other Language Speacking Score(3)')}
                onChange={handleChange('OtherThreeSpeackingScore')}
                />
                <label htmlFor="OtherThreeSpeackingScore">{t('Enter the applicants Other Language Speacking Score(3)')}</label>
            </div>




          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Foreign Language Documents')}</p> 
          
          </div>
          
          
   <div className="MyFileUploadRow">
          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
              <div className="MyCusUploadGray" id="MyGrayBox">
                  <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                  <input 
                  className="MyCusInputFileUpload"
                  type="file" 
                  onChange={onImageChange} 
                  id="group_image"
                  accept="image/gif, image/jpeg, image/png"
                  />
                  {/* <input 
                  type="file" 
                  className="MyCusInputFileUpload"
                  onChange={}
                  /> */}
              </div>
              <div className="MyClickAbleRectForHide">
              <img id="target" src={image} className="MyCusImageUploadPreview"/>
              <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
                <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
                <ExportSquare size="24" color="#ffffff" />
                </div>
              </div>
              <p>{t('IELTS')}</p> 
            </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
              <div className="MyCusUploadGray" id="MyGrayBox2">
                  <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                  <input
                  className="MyCusInputFileUpload"
                  type="file" 
                  onChange={onImage2Change} 
                  id="group_image"
                  accept="image/gif, image/jpeg, image/png"
                  />
                  {/* <input 
                  type="file" 
                  className="MyCusInputFileUpload"
                  onChange={}
                  /> */}
              </div>
              <div className="MyClickAbleRectForHide">
                <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
                <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
                  <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
                  <ExportSquare size="24" color="#ffffff" />
                </div>
              </div>
              <p>{t('TOEFL')}</p> 
            </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox3">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage3Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
            <p>{t('Other Language(1)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox4">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage4Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Language(2)')}</p> 
          </div>
          </div>


          <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
            <div className="MyCusUploadGray" id="MyGrayBox5">
                <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
                <input 
                className="MyCusInputFileUpload" 
                type="file" 
                onChange={onImage5Change} 
                id="group_image"
                accept="image/gif, image/jpeg, image/png"
                />
                {/* <input 
                type="file" 
                className="MyCusInputFileUpload"
                onChange={}
                /> */}
            </div>
            <div className="MyClickAbleRectForHide">
            <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
            <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
               <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
               <ExportSquare size="24" color="#ffffff" />
              </div>
            </div>
           
            <p>{t('Other Language(3)')}</p> 
          </div>
          </div>

         

   </div>


   {localStorage.getItem('userType')=='3' && <div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {<button className="btn btn-dark">{t('Submit')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>
        
        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;