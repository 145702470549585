import { Hidden } from "@mui/material";
import React from "react";
import { Visibility } from "semantic-ui-react";
 
const Popup = props => {
  return (
    <div className="popup-box" id='leftNavMobileMenu'>
      <div className="menu_mobile">
        {/* <div className="close-icon" onClick={props.handleClose}><span>&times;</span></div> */}
        {props.content}
      </div>
    </div>
  );
};
 
export default Popup;