import {ArrowUp2,ArrowDown2,ProfileAdd,Filter,Warning2} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Agents = () => {
    const {t, i18n} = useTranslation(['Login']);

    const [people , setPeople] = useState([
        {AgentPic:'CusPic.png', 
        AgentName:'',
        AgentPhone:'',
        AgentStatus:'Active',
        AgentClients:'24 ', 
        Email:'',
        key:'129121', 
        AdvocacyCode:'',
        City:'',
        Country:'',
        Address:'',
        Clients:'',
        lawyerTariffsList:[{}],
    },
       
      ]);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].AgentStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].AgentStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName > b.AgentName) ? 1 : ((b.AgentName > a.AgentName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowAgent = () =>{
        setPeople(people.sort((a,b) => (a.AgentName < b.AgentName) ? 1 : ((b.AgentName < a.AgentName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (b.AgentClients - a.AgentClients)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.AgentClients-b.AgentClients)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    
    const handleFilterStatusBlur = () =>{ 
        // document.getElementById("FilterStatusBoxID").style.display="none";
        // setFilterBoxShowing(false);
    }

    const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteLawyersByAgent' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ LawyerEmailAddress: values.EmailC ,AgentCode:localStorage.getItem('AgentCode') })
            }).then().then(setIsOpen2(!isOpen2));
        setIsOpen2(!isOpen2);
    }
    const [lawyerTariffsList, setlawyerTariffsList] = useState([]);
    const handleListOfTerrafies = (lawyerTariffsList) =>{
       
        
        setlawyerTariffsList(lawyerTariffsList);
        setIsOpen3(!isOpen3);
        
    }


    const [isOpenRow, setisOpenRow] = useState(true);

    const handleAgentRowClick = (itemKey) =>{
        if(isOpenRow){
            document.getElementById(itemKey).style.display="flex";
            setisOpenRow(!isOpenRow);
        }
        else{
            document.getElementById(itemKey).style.display="none";
            setisOpenRow(!isOpenRow);
        }
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.AgentStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.AgentStatus== 'Deactive').length)));
    const [checkedFilter , setCheckedFilter]= useState(false);
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.AgentStatus == 'Deactive'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.AgentStatus == 'Active'));
              forceUpdate() ;
              setCheckedFilter(true);
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(false);
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setCheckedFilter(true);
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }

    const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
    }
    
      fetch('https://telesiness.ir/api/Dashboards/DistributorLawyersList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try
             {
                
                if(people.length<2)
                {
                    people.pop();
                    var myActDec = ''
                for (i in response.distributorList){
                    // people[i].CustomerName = response.distributorList[i].customerFirstName + " " + response.distributorList[i].customerLastName;
                    // people[i].CustomerCreated = response.distributorList[i].customerRegisterDate;
                    
                    if(response.distributorList[i].lawyerStatus == false)
                    myActDec = "Deactive";
                    else
                    myActDec = "Active";
                    people.push( {
                    AgentClients:response.distributorList[i].customerVisaName,
                    AgentPic:'CusPic.png', 
                    AgentName:response.distributorList[i].lawyerFirstName 
                    + " " + 
                    response.distributorList[i].lawyerLastName,
                    AgentPhone:response.distributorList[i].lawyerPhoneNumber,
                    AgentStatus:myActDec,
                    AdvocacyCode:response.distributorList[i].lawyerCode,
                    City:response.distributorList[i].lawyerCity,
                    Country:response.distributorList[i].lawyerCountry,
                    Address:response.distributorList[i].lawyerAddress,
                    Email:response.distributorList[i].lawyerEmailAddress,
                    Clients:response.distributorList[i].clientsCount,
                    lawyerTariffsList:response.distributorList[i].lawyerServicesList,
                    key:(parseInt(i)+1).toString(),},)
                    
                                 }
                 }
             }

            catch{

            }
            // console.log(response[0]);
            
        });

const [data, setData] = useState([]);

const fetchData = () => {
    fetch('https://telesiness.ir/api/Dashboards/DistributorLatestLawyers' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
           
             try
             {
                
                    setData(response.distributorList)
             }

            catch{

            }
            // console.log(response[0]);
            
        });
};
console.log(localStorage.getItem('email'));
useEffect(() => {
    fetchData();
    // console.log(data[0].countery_Name);
  }, []);
const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
if (counter.current < 4) {
counter.current += 1;
const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);

return () => clearTimeout(timer);
}
}, [state]);

const [values, setValues] = useState({

    LawyerCode: '',
    EmailC: '',

  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }
    return (  
        <><div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('Lawyers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('Lawyers List')}</p>
                    </div>
                </div>
                <button onClick={handleAddNewUserAgent} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Lawyer')}<ProfileAdd size="24" color="#fff" /></button>
            </div>
            <div className="TopMainRowTwo">
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Lawyer')} <a onClick={handleUpArrowAgent}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowAgent}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-2"><p>{t('Advocacy Code')}</p></div>
                <div className="col-3"><p>{t('Email')}</p></div>
                <div className="col-2 Col-2Status">
                    <p>{t('Status')} </p><div className="FilterIconAgent"><button className="FilterBtn" onBlur={handleFilterStatusBlur} onClick={handleFilterStatus}>{!checkedFilter && <Filter  size="16"  color="#313131"/>}{checkedFilter && <Filter variant="Bold" size="16"  color="#313131"/>}</button></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div>
                <div className="col-1"><p>{t('Clients')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div>
            </div>
            <hr className="CustomerLine"/>
        </div>
        <div className="mainRowCus col-12">
            {people.map(item =>(
                <div className="itemBox2 col-12" key={item.key}>
                    <div className="agentCusRow col-12">
                        <a className="col-12 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                            <div className="col-1 id_agentCus"><p>{item.key}</p> </div>
                            <div className="CusPic2 col-lg-3 col-8">
                                <div className='img_agentCus'>
                                    <img src={`${baseUrl + item.AgentPic}`} alt="" /> 
                                </div>
                                <div className='detail_agentCus'>
                                    <p>{item.AgentName}</p>
                                    <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                                </div>
                            </div>
                            <div className="col-2 phone_agentCus"><p>{item.AdvocacyCode}</p></div>
                            <div className="col-3 mail_agentCus"><p>{item.Email}</p></div>
                            {item.AgentStatus=='Active' && <div className="col-lg-2 col-4 de_active_agentCus">
                                <button  className="btn-sm active">
                                    {t('Active')}
                                </button></div>}
                            {item.AgentStatus=='Deactive' && <div className="col-lg-2 col-4 de_active_agentCus">
                                <button  className="btn-sm deactive">
                                    {t('Deactive')}
                                </button>
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                    <a >
                                        <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                    </a>
                                </div>}  */}
                                {/* <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                                </div>}
                            <div className="col-1 count_agentCus"><p>{item.Clients}</p></div>
                        </a>
                    </div>   
                    {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                        <div className="hidenItemRow">
                            <hr className="linebetitem col-12"/>
                            <div className="d-lg-none col-12 count_agentCusm hidenItem"><span>{t('Clients')}:</span><p>{item.Clients}</p></div>
                            <div className="d-lg-none col-12 phone_agentCusm hidenItem"><span>{t('Advocacy Code')}:</span><p>{item.AdvocacyCode}</p></div>
                            <div className="d-lg-none col-12 mail_agentCusm hidenItem"><span>{t('Email')}:</span><p>{item.Email}</p></div>
                            <div className="col-lg-4 col-12 hidenItem"><span>{t('Country')}:</span><p>{item.Country}</p> </div>
                            <div className="col-lg-4 col-12 hidenItem"><span>{t('City')}:</span><p>{item.City}</p> </div>
                            <div className="col-lg-4 col-12 hidenItem"><span>{t('Phone')}:</span><p>{item.AgentPhone}</p> </div>
                            <div className="col-lg-8 col-12 hidenItem"><span>{t('Address')}:</span><p>{item.Address}</p> </div>
                            <div className="col-lg-4 col-12 all_btnslawcus">
                                <button onClick={() => {localStorage.setItem('DCLawyerTariffsListLawCode',item.AdvocacyCode); handleListOfTerrafies(item.lawyerTariffsList);}} className="btn btn-dark btn-sm btn_infoLawCus">{t('View Services')}</button>
                            </div>
                        </div>
                    </div>}
                    <ArrowDown2 className="arrowshow_list" size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                </div>
            
            ))}
        </div>
            {isOpen && <Popup
      content={<>
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Why You wanna De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-md-3 col-12 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-md-3 col-12 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-md-3 col-12 btn btn-light">{t('Declined')}</button>
            </div>
        
      </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
                <h4>{t('Adding a New Lawyer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the lawyer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailC} onChange={handleChange('EmailC')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
      </>}
      handleClose={togglePopup2}
    />}

{isOpen3 && <Popup
      content={<>
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
            <h4>{t('List of  Tariffs For Lawyer:') + ' ' + localStorage.getItem('DCLawyerTariffsListLawCode')}</h4>
            </div>
           
            <div className="box_HomeLightBoxRowTwo col-lg-12 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowTwo col-12">
                            {/* <div className="HomeR2R1">
                                <p>{t('My Services')}</p>
                                <AddSquare className='AddUserHome' onClick={childToParentPrices} size="40" color="#000000" variant="Bold"/>
                            </div>                              */}
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                {lawyerTariffsList.map(item =>(
                    <div  key={item.key}>
                        
                       <div className="item_tariff col-12">
                          <div className="agentCusRow col-12">
                           {<div className="col-1 id_tariff"><p>{item.userId}</p> </div>}
                           {<div className="price_tariff col-lg-4 col-11"><p>{item.nameOfVisa}</p>
                            {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a><InfoCircle size="18" color="#313131"/></a>
                                </div>}  */}
                                {/* <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div> */}
                            </div>}
                            {<div className="col-lg-3 col-4"><span>{t('Price')}</span><p>{"$ " +item.wageFigure}</p></div>}
                            { <div className="col-lg-2 col-4"><span>{t('Percent')}</span><p>{"% " +item.percentageOfCommission}</p></div>}
                            { <div className="col-lg-2 col-4"><span>{t('Lawyer Tariffs Code')}</span><p>{"# " +item.lawyerTariffsCode}</p></div>}
                            {/* {item.tick == "true" &&<div className="CusPic col-3"><input className="col-12" onChange={handleChange('Service')} type="text" value={ values.Service}/></div>}
                            {item.tick == "true" &&<div className="col-2 "><input className="col-12" onChange={handleChange('proLastName')} type="text" value={ values.proLastName}/></div>}
                            {item.tick == "true" && <div className="col-4"><input className="col-12" onChange={handleChange('Fee')} type="text" value={ values.Fee}/></div>}   */}
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                <a >
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                </a>
                            </div>}  */}
                            {/* <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                            {/* <div className="col-2 PenAndTrash">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>handlePenClick(item.key)} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                            {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key)} size="18" variant="Bold" color="#21D350" className="PriceListIcons"/>}
                            <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/>
                            </div> */}
                           </div>   
                       </div>
                    </div>
                   
                   
                        ))}
                                </div>
                            </div>
                        </div>   
                    </div>
        
      </>}
      handleClose={togglePopup3}
    />}

    </>
        
    );
}
 
export default Agents;