import {ArrowUp2,ArrowDown2,Filter,ProfileAdd,InfoCircle,ShieldSecurity,TickCircle,MessageText1} from 'iconsax-react';
import { useState,useEffect,useRef } from 'react';
import Popup from './Popup';
import { useTranslation } from 'react-i18next';

const baseUrl = "../../images/";
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
const Customers = ({childToParentCus1e1,childToParentMessage}) => {
    const {t, i18n} = useTranslation(['Login']);

    const [people , setPeople] = useState([
        {visa:'',CustomerPic:'CusPic.png', CustomerName:'',CustomerPhone:'+',CustomerStatus:'Active',CustomerCreated:'', CustomerAgent:'',key:'1'},
      
      ]);

const handleSendMsgReDirect = (CustomerName) => {
localStorage.setItem('CusNameMsg',CustomerName);

}
localStorage.setItem('firstTimeCustomer',true);
    const [itemKey, setItemKey] = useState(1);
    const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    
    const handleStatusClickOut = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
    }
     const forceUpdate = useForceUpdate();

    const [IDArrowUp, setIDArrowUp]=useState(false);
    const [visaID,setVisaId]=useState(-1);

    
    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const handleDeactiveClick = (itemKey) =>{
    setIsOpen(!isOpen);
    for(var i in people){
        if(people[i].key==itemKey){
            people[i].CustomerStatus='Deactive';
            setVisaId(i);
        }
        
    }
    forceUpdate() ;
    setPeople(people);

}

    const handleVisaPbox = (itemKey) =>{
        people[visaID].visa='Visa';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }
    
    const handleRejectedPbox = (itemKey) =>{
        people[visaID].visa='Rejected';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    
    const handleDeclinedPbox = (itemKey) =>{
        people[visaID].visa='Declined';
        forceUpdate() ;
        setPeople(people);
        setIsOpen(!isOpen);
    }

    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
    useEffect(()=>{
        setPeople(people);
    })
    
    const handleUpArrowID = () =>{
        setPeople(people.sort((a, b) => b.key - a.key));
        document.getElementById("UpArrowID").style.display="none";
        document.getElementById("DownArrowID").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowID = () =>{
        setPeople(people.sort((a, b) => a.key - b.key ));
        document.getElementById("DownArrowID").style.display="none";
        document.getElementById("UpArrowID").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }
    
    const handleUpArrowCustomer = () =>{
        setPeople(people.sort((a,b) => (a.CustomerName > b.CustomerName) ? 1 : ((b.CustomerName > a.CustomerName) ? -1 : 0)));
        document.getElementById("UpArrowCustomer").style.display="none";
        document.getElementById("DownArrowCustomer").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }
    
    const handleDownArrowCustomer = () =>{
        setPeople(people.sort((a,b) => (a.CustomerName < b.CustomerName) ? 1 : ((b.CustomerName < a.CustomerName) ? -1 : 0)));
        document.getElementById("DownArrowCustomer").style.display="none";
        document.getElementById("UpArrowCustomer").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    
    const handleUpArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.CustomerCreated > b.CustomerCreated) ? 1 : ((b.CustomerCreated > a.CustomerCreated) ? -1 : 0)));
        document.getElementById("UpArrowCreated").style.display="none";
        document.getElementById("DownArrowCreated").style.display="inherit";
        setIDArrowUp(true);
        forceUpdate() ;
    }

    
    const handleDownArrowCreated = () =>{
        setPeople(people.sort((a,b) => (a.CustomerCreated < b.CustomerCreated) ? 1 : ((b.CustomerCreated < a.CustomerCreated) ? -1 : 0)));
        document.getElementById("DownArrowCreated").style.display="none";
        document.getElementById("UpArrowCreated").style.display="inherit";
        setIDArrowUp(false);
        forceUpdate() ;
    }

    const handleInfoCircleMouseOver = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="flex";
    }
    const handleInfoCircleMouseLeave = (itemKeyCir) =>{ 
        document.getElementById(itemKeyCir).style.display="none";
    }
    const [filterBoxShowing , setFilterBoxShowing] = useState(false);
    const handleFilterStatus = () =>{ 
        if(!filterBoxShowing){
            document.getElementById("FilterStatusBoxID").style.display="flex";
            setFilterBoxShowing(true);
        }
        else{
            document.getElementById("FilterStatusBoxID").style.display="none";
            setFilterBoxShowing(false);
        }
        
    }
    
    const handleAddUserSendEmail = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }

    const [activeUserCount,setActiveUserCount]= useState((people.filter(people => people.CustomerStatus == 'Active').length));
    const [deactiveUserCount,setDeactiveUserCount]= useState(((people.filter(people => people.CustomerStatus == 'Deactive').length)));
    const handleActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people.filter(people => people.CustomerStatus == 'Active'));
              forceUpdate() ;
        }
        else  if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.CustomerStatus == 'Deactive'));
              forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
        }
    }
    
    const handleDeActiveUserCheck = () =>{
        if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people.filter(people => people.CustomerStatus == 'Deactive'));
              forceUpdate() ;
        }
        else  if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
            setPeople(people.filter(people => people.CustomerStatus == 'Active'));
              forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == false && document.getElementById('DeActiveUsers').checked == false){
            setPeople(people);
            forceUpdate() ;
        }
        else if(document.getElementById('ActiveUsers').checked == true && document.getElementById('DeActiveUsers').checked == true){
            setPeople(people);
            forceUpdate() ;
        }
    }
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
    const togglePopup2 = () => {
        setIsOpen2(!isOpen2);
      }

// const [values, setValues] = useState({
//     lawyer: '',
//   });
//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//     if (values.lawyer=='Assign by Telesiness')
//     setIsOpen2(!isOpen2);
//     console.log(values.lawyer);
//   }
const [lawyer , setLawyer] = useState(0);
  const handleChange2 = (a,b,c) =>{
   console.log(a,b,c);
   
    try {
        console.log(a,b,c);
        fetch('https://telesiness.ir/api/Dashboards/AddLawyerToCustomerDocumentByDistributor/1009' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                Email: localStorage.getItem('email'),
                UserId:parseInt(c),
                CustomerCode:a,
                LawyerCode:b})
            }).then(response => response.json()).then(response=>{
               
                 try
                 {
                   
                    
                 }
    
                catch{
                    console.log('error');
                }
                // console.log(response[0]);
                
            });
    } catch (error) {
        
    }

    togglePopup();
  }
  const [isOpenRow, setisOpenRow] = useState(true);

  const handleAgentRowClick = (itemKey) =>{
      if(isOpenRow){
          document.getElementById(itemKey).style.display="flex";
          setisOpenRow(!isOpenRow);
      }
      else{
          document.getElementById(itemKey).style.display="none";
          setisOpenRow(!isOpenRow);
      }
    }
    const handleClickSelect = (itemKey) =>{
         if(!isOpenRow){
          document.getElementById(itemKey).style.display="flex";
        //   setisOpenRow(!isOpenRow);
      }
      else{
          document.getElementById(itemKey).style.display="none";
        //   setisOpenRow(!isOpenRow);
      }
    }

    fetch('https://telesiness.ir/api/Dashboards/DistributorSharedCustomersList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try
             {
                
                if(people.length<2)
                {
                    people.pop();
                    var myActDec = ''
                for (i in response.customerSharedDistributorList){
                    // people[i].CustomerName = response.customerSharedDistributorList[i].customerFirstName + " " + response.customerSharedDistributorList[i].customerLastName;
                    // people[i].CustomerCreated = response.customerSharedDistributorList[i].customerRegisterDate;
                    if(response.customerSharedDistributorList[i].customerDocumentStatus == false)
                    myActDec = "Deactive";
                    else
                    myActDec = "Active";
                    people.push( {
                    visa:response.customerSharedDistributorList[i].customerVisaName,
                    CustomerPic:'CusPic.png', 
                    CustomerName:response.customerSharedDistributorList[i].customerFirstName 
                    + " " + 
                    response.customerSharedDistributorList[i].customerLastName,
                    CustomerPhone:response.customerSharedDistributorList[i].customerPhoneNumber,
                    CustomerStatus:myActDec,
                    CustomerCreated:response.customerSharedDistributorList[i].submitDocumentDate,
                    CustomerAgent:response.customerSharedDistributorList[i].lawyerFirstName 
                    + " " + 
                    response.customerSharedDistributorList[i].lawyerLastName,
                    Email:response.customerSharedDistributorList[i].customerEmailAddress,
                    Destination:response.customerSharedDistributorList[i].customerVisaDescriptions,
                    CustomerCode:response.customerSharedDistributorList[i].customerCode,
                    key:(parseInt(i)+1).toString(),},)
                    
                                 }
                 }
             }

            catch{

            }
            // console.log(response[0]);
            
        });

const [data, setData] = useState([]);

const fetchData = () => {
    fetch('https://telesiness.ir/api/Dashboards/DistributorLatestLawyers' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
           
             try
             {
                
                    setData(response.distributorList)
             }

            catch{

            }
            // console.log(response[0]);
            
        });
};
console.log(localStorage.getItem('email'));
useEffect(() => {
    fetchData();
    // console.log(data[0].countery_Name);
  }, []);
const [state, setState] = useState({ num: 0 });
const counter = useRef(0);

useEffect(() => {
if (counter.current < 4) {
counter.current += 1;
const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);

return () => clearTimeout(timer);
}
}, [state]);

const [values, setValues] = useState({

    LawyerCode: '',
    EmailC: '',

  });
  const [SLawyerCode,setSLawyerCode] = useState([]);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

    return (  
        <> <div className="topMain top_listcuslaw col-12">
            <div className="TopMainRowOne list_cuslaw">
                <strong className='d-lg-none'>{t('DS Shared Customers List')}</strong>
                <div className="priceInfoRowOne col-10 d-none d-lg-block">
                    <div className="penAndPerson">
                    <p className="pricesInfo">{t('DS Shared Customers List')}</p>
                    </div>
                </div>
                {/* <button onClick={handleAddNewUserCustomer} className="btn btn-dark btn-sm AddNew_Cus">{t('+ Add New Customer')}<ProfileAdd size="24" color="#fff" /></button> */}
            </div>
            <div className="TopMainRowTwo">
                <div className="col-1"><p>{t('ID')} <a onClick={handleUpArrowID}> <ArrowUp2 id="UpArrowID"  size="16" color="#313131"/></a><a onClick={handleDownArrowID}> <ArrowDown2 id="DownArrowID"  size="16" color="#313131"/></a></p> </div>
                <div className="col-3"><p>{t('Customer')} <a onClick={handleUpArrowCustomer}><ArrowUp2 id="UpArrowCustomer" size="16" color="#313131"/></a><a onClick={handleDownArrowCustomer}> <ArrowDown2 id="DownArrowCustomer"  size="16" color="#313131"/></a></p> </div>
                <div className="col-2"><p>{t('Lawyer')}</p></div>
                <div className="col-2 Col-2Status">
                    <p>{t('Status')} </p><div className="FilterIcon" onClick={handleFilterStatus}><Filter  size="16"  color="#313131"/></div> 
                    <div id="FilterStatusBoxID" className="FilterStatusBox">
                        <div className="checkBoxAndLabel">
                            <input onChange={handleActiveUserCheck} type="checkbox" id="ActiveUsers" name="ActiveUsers" value="ActiveUsers"></input>
                            <label htmlFor="ActiveUsers">{t('Active Users')} <div className="activeCount">({activeUserCount})</div></label>
                        </div>
                        <div className="checkBoxAndLabel">
                            <input onChange={handleDeActiveUserCheck} type="checkbox" id="DeActiveUsers" name="DeActiveUsers" value="DeActiveUsers"></input>
                            <label htmlFor="DeActiveUsers">{t('Deactive Users')}<div className="deactiveCount">({deactiveUserCount})</div></label>
                        </div>
                    </div>
                </div>
                <div className="col-2"><p>{t('Created')} <a onClick={handleUpArrowCreated}><ArrowUp2 id="UpArrowCreated" size="16" color="#313131"/></a><a onClick={handleDownArrowCreated}> <ArrowDown2 id="DownArrowCreated"  size="16" color="#313131"/></a></p></div>
                <div className="col-2"><p>{t('View Information')} </p></div>
            </div>
            <hr className="CustomerLine"/>
        </div>
        <div className="mainRowCus col-12">
            {people.map(item =>(       
            <div className="itemBox2 col-12"  key={item.key}>
                <div className="agentCusRow col-12">
                    <a className="col-lg-4 col-8 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                        <div className="col-3 id_agentCus"><p>{item.key}</p></div>
                        <div className="CusPic2 col-lg-9 col-12">
                        <div className='img_agentCus'><img src={`${baseUrl + item.CustomerPic}`} alt="" /></div>
                            <div className='detail_agentCus'>
                                <p>{item.CustomerName}</p>
                                <p className="id_agentCusm d-lg-none col-12">{item.key}</p>
                            </div>
                        </div>
                    </a>
                    <div className="col-2 lawyer_agentCus">
                        <select
                        // value={lawyer}
                        className=''
                        id={'AgCuSelect'+ item.key}
                        onChange={() => {
                            handleChange(values.LawyerCode[item.key]);
                            localStorage.setItem('CusCustomerCode',item.CustomerCode);
                            localStorage.setItem('CusLawCode', document.getElementById('AgCuSelect' + item.key).value);
                            localStorage.setItem('CusLawName', document.getElementById('AgCuSelect' + item.key).value);
                            console.log(values.LawyerCode[item.key]);
                            localStorage.setItem('UserID' , item.key)
                            togglePopup();

                            }}
                        onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                        >
                           <option  value={item.CustomerAgent}>{item.CustomerAgent}</option>
                            {data &&
                                data.map((element) => (
                          <option  value={element.lawyerCode}>{element.lawyerFirstName + ' ' + element.lawyerLastName}</option >
                          ))}
                        </select>
                        {item.CustomerAgent == 'Assign by Telesiness' &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                    </div>
                    <a className="col-lg-4 col-4 MyAgentRowClick" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}>
                        {item.CustomerStatus=='Active' && <div className="col-lg-6 col-12 de_active_agentCus">
                        <button className="active">
                            {t('Active') }
                            <div id={item.key} className="ChoosBox"> 
                                <p className='disabled'>{t('Active')}</p>  <br />
                                <p >{t('Deactive')}</p>
                            </div>
                        </button></div>}
                        {item.CustomerStatus=='Deactive' && <div className="col-lg-6 col-12 de_active_agentCus">
                            <button  className="deactive">
                                {t('Deactive') }
                                <div id={item.key} className="ChoosBox"> 
                                    <p >{t('Active')}</p>  <br />
                                    <p className='disabled'>{t('Deactive')}</p>
                                </div>
                            </button>
                            {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                            </div>} 
                            <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div>
                        </div>}
                        <div className="col-6 created_agentCus"><p>{item.CustomerCreated.split('T',1)}</p></div>
                    </a>
                    <div className="col-2 view_infocus">
                        <div onClick={(dataCustomerHome) => {
                             localStorage.setItem('customerCode',item.CustomerCode);
                             localStorage.setItem('emailCus',item.Email);
                            childToParentCus1e1(dataCustomerHome);}} className="btn btn-dark col-6 btn_infoLawCus ">{t('View')}</div>  
                    </div>
                    <div className="Send_Message">
                        <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                            document.getElementById('MessageText1Btn').style.backgroundColor='white';
                            document.getElementById('MessageText1BtnP').style.color='black';
                            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
                            document.getElementById('ProfileTickBtnP').style.color='white';
                            localStorage.setItem('turnOffMeLawCusMsg',true);
                            localStorage.setItem('MsgUserNewName',item.CustomerName);
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('CusCode',item.CustomerCode);
                            localStorage.setItem('MsgUserNewType',3);
                            childToParentMessage(dataMessage);
                            handleSendMsgReDirect(item.CustomerName)}}/>
                        <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
                    </div> 
                </div>
                {<div id={item.key + 'hidenRowAgentRow'} className="col-12 hidenRowAgent">
                    <div className="hidenItemRow">
                        <hr className="linebetitem col-12"/>
                        <MessageText1 className='MessageText1Icon' size="24" color="#555555" variant="Bulk" onClick={(dataMessage)=>{
                            document.getElementById('MessageText1Btn').style.backgroundColor='white';
                            document.getElementById('MessageText1BtnP').style.color='black';
                            document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
                            document.getElementById('ProfileTickBtnP').style.color='white';
                            localStorage.setItem('turnOffMeLawCusMsg',true);
                            localStorage.setItem('MsgUserNewName',item.CustomerName);
                            localStorage.setItem('emailCus',item.Email);
                            localStorage.setItem('CusCode',item.CustomerCode);
                            localStorage.setItem('MsgUserNewType',3);
                            childToParentMessage(dataMessage);
                            handleSendMsgReDirect(item.CustomerName)}}/>
                        <div className="col-2 lawyer_agentCus">
                        <select
                        // value={lawyer}
                        className=''
                        id={'AgCuSelect'+ item.key}
                        onChange={() => {
                            handleChange(values.LawyerCode[item.key]);
                            localStorage.setItem('CusCustomerCode',item.CustomerCode);
                            localStorage.setItem('CusLawCode', document.getElementById('AgCuSelect' + item.key).value);
                            localStorage.setItem('CusLawName', document.getElementById('AgCuSelect' + item.key).value);
                            console.log(values.LawyerCode[item.key]);
                            localStorage.setItem('UserID' , item.key)
                            togglePopup();

                            }}
                        onClick={() => handleClickSelect(item.key + 'hidenRowAgentRow')}
                        >
                           <option  value={item.CustomerAgent}>{item.CustomerAgent}</option>
                            {data &&
                                data.map((element) => (
                          <option  value={element.lawyerCode}>{element.lawyerFirstName + ' ' + element.lawyerLastName}</option >
                          ))}
                        </select>
                        {item.CustomerAgent == 'Assign by Telesiness' &&<TickCircle size="18" color="#37d67a" variant="Bold"/>} 
                    </div>
                        <div className="d-lg-none col-12 hidenItem "><span>{t('Created')}:</span><p>{item.CustomerCreated.split('T',1)}</p></div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Phone Number')}:</span><p>{item.CustomerPhone}</p></div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Email')}:</span><p>{item.key}</p> </div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Visa')}:</span><p>{item.key}</p> </div>
                        <div className="col-lg-6 col-12 hidenItem"><span>{t('Destination')}:</span><p>{item.key}</p> </div>
                        <div className="d-lg-none col-12 hidenItem all_btnslawcus">
                            <div onClick={(dataCustomerHome) => {
                                 localStorage.setItem('customerCode',item.CustomerCode);
                                 localStorage.setItem('emailCus',item.Email);
                                childToParentCus1e1(dataCustomerHome);}} className="btn btn-dark col-6 btn_infoLawCus ">{t('View Information')}</div>
                        </div>
                    </div>
                </div>}
                <ArrowDown2 size="18" color="#000000" onClick={()=>handleAgentRowClick(item.key + 'hidenRowAgentRow')}/>
            </div>  
            ))}
        </div>
        {isOpen && <Popup
       content={<>
              <div className="PboxFirstRow">
                  <ShieldSecurity size="48" color="#313131" variant="Bold"/>
              </div>
              <div className="PboxSecondRowAgCus">
                <h2>{t('Are You Sure?')}</h2>
              <h4>{t('You choose the lawyer by ') + ' '} <b>{localStorage.getItem('CusLawName')} </b></h4>
              {(localStorage.getItem('CusLawCode') == 'Assign by Telesiness' || localStorage.getItem('CusLawCode') == 'اختصاص وکیل توسط تلزینس' ) && 
              <h4>{t('Pay attention Telesinees has no responsibility for the lawyer')}</h4>}
              </div>
              <div className="PboxForthRowSendMail col-12">
                  <button onClick={togglePopup} className="col-md-3 col-6 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                  <button onClick={() => {handleChange2(localStorage.getItem('CusCustomerCode')
                                                        ,localStorage.getItem('CusLawCode'),
                                                        localStorage.getItem('UserID'))}} 
                                                         className="col-md-3 col-6 btn btn-dark">{t('Send Request')}</button>
              </div>
        </>}
      handleClose={togglePopup}
    />}
    {isOpen2 && <Popup
      content={<>
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow Pbox_headagentCus">
                <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email adress...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
      </>}
      handleClose={togglePopup2}
    />}
    </>
        
    );
}
 
export default Customers;