import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"


const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
        e.preventDefault();
    localStorage.setItem('firstTimeCustomer',false);
    console.log((localStorage.getItem('firstTimeCustomer')));
    childToParentCus1e3(dataCus1);
    }
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({

          FatherName: '',
          FatherFamily: '',
          FatherJob:'',
          FathersResidenceAddress:'',
          FatherPostalCode: '',
          FatherPhoneCall: '',
          FatherEducation: '',
          FatherFinancialAbility:"",
          FathersForeignTrip1: '',
          FathersForeignTrip2: '',
          FathersForeignTrip3: '',
          FathersForeignTrip4: '',
          FathersForeignTrip5: '',
          FathersForeignTrip6: '',
          FathersForeignTrip7: '',
          FathersForeignTrip8: '',
          FathersForeignTrip9: '',

          MotherName: '',
          MotherFamily: '',
          MotherJob:'',
          MothersResidenceAddress:'',
          MotherPostalCode: '',
          MotherPhoneCall: '',
          MotherEducation: '',
          MotherFinancialAbility:"",
          MothersForeignTrip1: '',
          MothersForeignTrip2: '',
          MothersForeignTrip3: '',
          MothersForeignTrip4: '',
          MothersForeignTrip5: '',
          MothersForeignTrip6: '',
          MothersForeignTrip7: '',
          MothersForeignTrip8: '',
          MothersForeignTrip9: '',

          Brother1Name: '',
          Brother1Family: '',
          Brother1Job:'',
          Brother1sResidenceAddress:'',
          Brother1PostalCode: '',
          Brother1PhoneCall: '',
          Brother1Education: '',
          Brother1FinancialAbility:"",
          Brother1sForeignTrip1: '',
          Brother1sForeignTrip2: '',
          Brother1sForeignTrip3: '',
          Brother1sForeignTrip4: '',
          Brother1sForeignTrip5: '',
          Brother1sForeignTrip6: '',
          Brother1sForeignTrip7: '',
          Brother1sForeignTrip8: '',
          Brother1sForeignTrip9: '',

          Brother2Name: '',
          Brother2Family: '',
          Brother2Job:'',
          Brother2sResidenceAddress:'',
          Brother2PostalCode: '',
          Brother2PhoneCall: '',
          Brother2Education: '',
          Brother2FinancialAbility:"",
          Brother2sForeignTrip1: '',
          Brother2sForeignTrip2: '',
          Brother2sForeignTrip3: '',
          Brother2sForeignTrip4: '',
          Brother2sForeignTrip5: '',
          Brother2sForeignTrip6: '',
          Brother2sForeignTrip7: '',
          Brother2sForeignTrip8: '',
          Brother2sForeignTrip9: '',

          Brother3Name: '',
          Brother3Family: '',
          Brother3Job:'',
          Brother3sResidenceAddress:'',
          Brother3PostalCode: '',
          Brother3PhoneCall: '',
          Brother3Education: '',
          Brother3FinancialAbility:"",
          Brother3sForeignTrip1: '',
          Brother3sForeignTrip2: '',
          Brother3sForeignTrip3: '',
          Brother3sForeignTrip4: '',
          Brother3sForeignTrip5: '',
          Brother3sForeignTrip6: '',
          Brother3sForeignTrip7: '',
          Brother3sForeignTrip8: '',
          Brother3sForeignTrip9: '',

          Sister1Name: '',
          Sister1Family: '',
          Sister1Job:'',
          Sister1sResidenceAddress:'',
          Sister1PostalCode: '',
          Sister1PhoneCall: '',
          Sister1Education: '',
          Sister1FinancialAbility:"",
          Sister1sForeignTrip1: '',
          Sister1sForeignTrip2: '',
          Sister1sForeignTrip3: '',
          Sister1sForeignTrip4: '',
          Sister1sForeignTrip5: '',
          Sister1sForeignTrip6: '',
          Sister1sForeignTrip7: '',
          Sister1sForeignTrip8: '',
          Sister1sForeignTrip9: '',

          Sister2Name: '',
          Sister2Family: '',
          Sister2Job:'',
          Sister2sResidenceAddress:'',
          Sister2PostalCode: '',
          Sister2PhoneCall: '',
          Sister2Education: '',
          Sister2FinancialAbility:"",
          Sister2sForeignTrip1: '',
          Sister2sForeignTrip2: '',
          Sister2sForeignTrip3: '',
          Sister2sForeignTrip4: '',
          Sister2sForeignTrip5: '',
          Sister2sForeignTrip6: '',
          Sister2sForeignTrip7: '',
          Sister2sForeignTrip8: '',
          Sister2sForeignTrip9: '',

          Sister3Name: '',
          Sister3Family: '',
          Sister3Job:'',
          Sister3sResidenceAddress:'',
          Sister3PostalCode: '',
          Sister3PhoneCall: '',
          Sister3Education: '',
          Sister3FinancialAbility:"",
          Sister3sForeignTrip1: '',
          Sister3sForeignTrip2: '',
          Sister3sForeignTrip3: '',
          Sister3sForeignTrip4: '',
          Sister3sForeignTrip5: '',
          Sister3sForeignTrip6: '',
          Sister3sForeignTrip7: '',
          Sister3sForeignTrip8: '',
          Sister3sForeignTrip9: '',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


          const [image13 , setImage13] = useState(null);
          const   onImage13Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader13 = new FileReader();
                reader13.onload = (e) => {
                  setImage13(e.target.result);
                  document.getElementById('target13').style.display ="block";
                  document.getElementById('MyGrayBox13').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader13.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage13 = () =>{
              document.getElementById('target13').style.display ="none";
              document.getElementById('MyGrayBox13').style.display= "flex";
            }


          const [image14 , setImage14] = useState(null);
          const   onImage14Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader14 = new FileReader();
                reader14.onload = (e) => {
                  setImage14(e.target.result);
                  document.getElementById('target14').style.display ="block";
                  document.getElementById('MyGrayBox14').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader14.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage14 = () =>{
              document.getElementById('target14').style.display ="none";
              document.getElementById('MyGrayBox14').style.display= "flex";
            }


            const [image15 , setImage15] = useState(null);
            const   onImage15Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader15 = new FileReader();
                  reader15.onload = (e) => {
                    setImage15(e.target.result);
                    document.getElementById('target15').style.display ="block";
                    document.getElementById('MyGrayBox15').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader15.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage15 = () =>{
                document.getElementById('target15').style.display ="none";
                document.getElementById('MyGrayBox15').style.display= "flex";
              }


            const [image16 , setImage16] = useState(null);
            const   onImage16Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader16 = new FileReader();
                  reader16.onload = (e) => {
                    setImage16(e.target.result);
                    document.getElementById('target16').style.display ="block";
                    document.getElementById('MyGrayBox16').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader16.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage16 = () =>{
                document.getElementById('target16').style.display ="none";
                document.getElementById('MyGrayBox16').style.display= "flex";
              }


            const [image17 , setImage17] = useState(null);
            const   onImage17Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader17 = new FileReader();
                  reader17.onload = (e) => {
                    setImage17(e.target.result);
                    document.getElementById('target17').style.display ="block";
                    document.getElementById('MyGrayBox17').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader17.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage17 = () =>{
                document.getElementById('target17').style.display ="none";
                document.getElementById('MyGrayBox17').style.display= "flex";
              }


              const [image18 , setImage18] = useState(null);
              const   onImage18Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader18 = new FileReader();
                    reader18.onload = (e) => {
                      setImage18(e.target.result);
                      document.getElementById('target18').style.display ="block";
                      document.getElementById('MyGrayBox18').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader18.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage18 = () =>{
                  document.getElementById('target18').style.display ="none";
                  document.getElementById('MyGrayBox18').style.display= "flex";
                }


              const [image19 , setImage19] = useState(null);
              const   onImage19Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader19 = new FileReader();
                    reader19.onload = (e) => {
                      setImage19(e.target.result);
                      document.getElementById('target19').style.display ="block";
                      document.getElementById('MyGrayBox19').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader19.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage19 = () =>{
                  document.getElementById('target19').style.display ="none";
                  document.getElementById('MyGrayBox19').style.display= "flex";
                }


              const [image20 , setImage20] = useState(null);
              const   onImage20Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader20 = new FileReader();
                    reader20.onload = (e) => {
                      setImage20(e.target.result);
                      document.getElementById('target20').style.display ="block";
                      document.getElementById('MyGrayBox20').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader20.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage20 = () =>{
                  document.getElementById('target20').style.display ="none";
                  document.getElementById('MyGrayBox20').style.display= "flex";
                }


            const [image21 , setImage21] = useState(null);
            const   onImage21Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader21 = new FileReader();
                  reader21.onload = (e) => {
                    setImage21(e.target.result);
                    document.getElementById('target21').style.display ="block";
                    document.getElementById('MyGrayBox21').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader21.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage21 = () =>{
                document.getElementById('target21').style.display ="none";
                document.getElementById('MyGrayBox21').style.display= "flex";
              }


            const [image22 , setImage22] = useState(null);
            const   onImage22Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader22 = new FileReader();
                  reader22.onload = (e) => {
                    setImage22(e.target.result);
                    document.getElementById('target22').style.display ="block";
                    document.getElementById('MyGrayBox22').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader22.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage22 = () =>{
                document.getElementById('target22').style.display ="none";
                document.getElementById('MyGrayBox22').style.display= "flex";
              }


            const [image23 , setImage23] = useState(null);
            const   onImage23Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader23 = new FileReader();
                  reader23.onload = (e) => {
                    setImage23(e.target.result);
                    document.getElementById('target23').style.display ="block";
                    document.getElementById('MyGrayBox23').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader23.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage23 = () =>{
                document.getElementById('target23').style.display ="none";
                document.getElementById('MyGrayBox23').style.display= "flex";
              }


          const [image24 , setImage24] = useState(null);
          const   onImage24Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader24 = new FileReader();
                reader24.onload = (e) => {
                  setImage24(e.target.result);
                  document.getElementById('target24').style.display ="block";
                  document.getElementById('MyGrayBox24').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader24.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage24 = () =>{
              document.getElementById('target24').style.display ="none";
              document.getElementById('MyGrayBox24').style.display= "flex";
            }

          const [image25 , setImage25] = useState(null);
          const   onImage25Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader25 = new FileReader();
                reader25.onload = (e) => {
                  setImage25(e.target.result);
                  document.getElementById('target25').style.display ="block";
                  document.getElementById('MyGrayBox25').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader25.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage25 = () =>{
              document.getElementById('target25').style.display ="none";
              document.getElementById('MyGrayBox25').style.display= "flex";
            }

          const [image26 , setImage26] = useState(null);
          const   onImage26Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader26 = new FileReader();
                reader26.onload = (e) => {
                  setImage26(e.target.result);
                  document.getElementById('target26').style.display ="block";
                  document.getElementById('MyGrayBox26').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader26.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage26 = () =>{
              document.getElementById('target26').style.display ="none";
              document.getElementById('MyGrayBox26').style.display= "flex";
            }

          const [image27 , setImage27] = useState(null);
          const   onImage27Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader27 = new FileReader();
                reader27.onload = (e) => {
                  setImage27(e.target.result);
                  document.getElementById('target27').style.display ="block";
                  document.getElementById('MyGrayBox27').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader27.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage27 = () =>{
              document.getElementById('target27').style.display ="none";
              document.getElementById('MyGrayBox27').style.display= "flex";
            }

          const [image28 , setImage28] = useState(null);
          const   onImage28Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader28 = new FileReader();
                reader28.onload = (e) => {
                  setImage28(e.target.result);
                  document.getElementById('target28').style.display ="block";
                  document.getElementById('MyGrayBox28').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader28.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage28 = () =>{
              document.getElementById('target28').style.display ="none";
              document.getElementById('MyGrayBox28').style.display= "flex";
            }

          const [image29 , setImage29] = useState(null);
          const   onImage29Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader29 = new FileReader();
                reader29.onload = (e) => {
                  setImage29(e.target.result);
                  document.getElementById('target29').style.display ="block";
                  document.getElementById('MyGrayBox29').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader29.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage29 = () =>{
              document.getElementById('target29').style.display ="none";
              document.getElementById('MyGrayBox29').style.display= "flex";
            }
            
        const [FatherBirthDay, setFatherBirthDay] = useState();
        const [FatherDateofDivorce, setFatherDateofDivorce] = useState();
        const [FatherDateofDeath, setFatherDateofDeath] = useState();

        const [MotherBirthDay, setMotherBirthDay] = useState();
        const [MotherDateofDivorce, setMotherDateofDivorce] = useState();
        const [MotherDateofDeath, setMotherDateofDeath] = useState();

        const [Brother1BirthDay, setBrother1BirthDay] = useState();
        const [Brother1DateofDivorce, setBrother1DateofDivorce] = useState();
        const [Brother1DateofDeath, setBrother1DateofDeath] = useState();

        const [Brother2BirthDay, setBrother2BirthDay] = useState();
        const [Brother2DateofDivorce, setBrother2DateofDivorce] = useState();
        const [Brother2DateofDeath, setBrother2DateofDeath] = useState();

        const [Brother3BirthDay, setBrother3BirthDay] = useState();
        const [Brother3DateofDivorce, setBrother3DateofDivorce] = useState();
        const [Brother3DateofDeath, setBrother3DateofDeath] = useState();

        const [Sister1BirthDay, setSister1BirthDay] = useState();
        const [Sister1DateofDivorce, setSister1DateofDivorce] = useState();
        const [Sister1DateofDeath, setSister1DateofDeath] = useState();

        const [Sister2BirthDay, setSister2BirthDay] = useState();
        const [Sister2DateofDivorce, setSister2DateofDivorce] = useState();
        const [Sister2DateofDeath, setSister2DateofDeath] = useState();

        const [Sister3BirthDay, setSister3BirthDay] = useState();
        const [Sister3DateofDivorce, setSister3DateofDivorce] = useState();
        const [Sister3DateofDeath, setSister3DateofDeath] = useState();

        const [DateofEnteringUniversityFogDiplom , setDateofEnteringUniversityFogDiplom ] = useState()
        const [GraduationDateFogDiplom , setGraduationDateFogDiplom ] = useState()
        const [DateofEnteringUniversityBachelor , setDateofEnteringUniversityBachelor ] = useState()
        const [GraduationDateBachelor , setGraduationDateBachelor ] = useState()
        const [DateofEnteringUniversityMasters , setDateofEnteringUniversityMasters ] = useState()
        const [GraduationDateMasters , setGraduationDateMasters ] = useState()
        const [DateofEnteringUniversityPHD , setDateofEnteringUniversityPHD ] = useState()
        const [GraduationDatePHD , setGraduationDatePHD ] = useState()
        const [DateofEnteringUniversityPostDoctoral , setDateofEnteringUniversityPostDoctoral ] = useState()
        const [GraduationDatePostDoctoral , setGraduationDatePostDoctoral ] = useState()
        const [DateofOther1degreevalue, setDateofOther1degreeValue] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1'}>
        <form onSubmit={handleSubmitPersonal}>
        <div className="personalInfo col-12">
          <div className="Step1"><p className="Cus2Step1GoldP">{t('Step 6')} :</p><p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant Family Information')}{localStorage.getItem('whoIs')=='sp' && t('Applicants Spouse Family Information')}</p>  <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Prime Family Information')}</p> </div>
          
          <div className="Cus2Inputs">

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="EnglishLanguageStatus"
                  value={values.EnglishLanguageStatus}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('EnglishLanguageStatus')}  
                  placeholder={t('English Language Status')}
                  >
                    <option  value="">
                    {t('English Language Status')}
                  </option >
                  <option  value='1'>{t('I Have')}</option >
                  <option  value='2'>{t('I do not have')}</option >
                  <option  value='3'>{t('I am reading')}</option >
                  <option  value='4'>{t('I will be able to get a degree in the next 6 months')}</option >
                  <option  value='5'>{t('I will not be able to get a degree for the next 8 months')}</option > 
                </select >
                <label htmlFor="EnglishLanguageStatus">{t('Enter the applicants English Language Status')}</label>
            </div> */}

           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Father')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherName" 
                value={values.FatherName}
                type="text"
                placeholder={t('Father`s Name')}
                onChange={handleChange('FatherName')}
                />
                <label htmlFor="FatherName">{t('Enter the applicants Father Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherFamily" 
                value={values.FatherFamily}
                type="text"
                placeholder={t('Father`s Family')}
                onChange={handleChange('FatherFamily')}
                />
                <label htmlFor="FatherFamily">{t('Enter the applicants Father Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherJob" 
                value={values.FatherJob}
                type="text"
                placeholder={t('Father`s Job')}
                onChange={handleChange('FatherJob')}
                />
                <label htmlFor="FatherJob">{t('Enter the applicants Father Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={FatherBirthDay}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Father`s Birth Day')}
              id="FatherBirthDay"
              onChange={setFatherBirthDay} 
              className="col-12"
            />}
              <label htmlFor="FatherBirthDay">{t('Enter the applicants Father Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="FathersResidenceAddress" 
                value={values.FathersResidenceAddress}
                type="text"
                placeholder={t('Father`s Residence Address')}
                onChange={handleChange('FathersResidenceAddress')}
                />
                <label htmlFor="FathersResidenceAddress">{t('Enter the applicants Fathers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherPostalCode" 
                value={values.FatherPostalCode}
                type="text"
                placeholder={t('Father`s Postal Code')}
                onChange={handleChange('FatherPostalCode')}
                />
                <label htmlFor="FatherPostalCode">{t('Enter the applicants Father Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherPhoneCall" 
                value={values.FatherPhoneCall}
                type="text"
                placeholder={t('Father`s Phone Call')}
                onChange={handleChange('FatherPhoneCall')}
                />
                <label htmlFor="FatherPhoneCall">{t('Enter the applicants Father Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherEducation" 
                value={values.FatherEducation}
                type="text"
                placeholder={t('Father`s Education')}
                onChange={handleChange('FatherEducation')}
                />
                <label htmlFor="FatherEducation">{t('Enter the applicants Father`s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FatherFinancialAbility" 
                value={values.FatherFinancialAbility}
                type="text"
                placeholder={t('Father`s Financial Ability')}
                onChange={handleChange('FatherFinancialAbility')}
                />
                <label htmlFor="FatherFinancialAbility">{t('Enter the applicants Father`s Financial Ability')}</label>
            </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={FatherDateofDivorce}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Father`s Date of Divorce')}
              id="FatherDateofDivorce"
              onChange={setFatherDateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="FatherDateofDivorce">{t('Enter the applicants Father`s Date of Divorce')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={FatherDateofDeath}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Father`s Date of Death')}
              id="FatherDateofDeath"
              onChange={setFatherDateofDeath} 
              className="col-12"
            />}
              <label htmlFor="FatherDateofDeath">{t('Enter the applicants Father`s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip1" 
                value={values.FathersForeignTrip1}
                type="text"
                placeholder={t('Father`s Foreign Trip (1)')}
                onChange={handleChange('FathersForeignTrip1')}
                />
                <label htmlFor="FathersForeignTrip1">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip2" 
                value={values.FathersForeignTrip2}
                type="text"
                placeholder={t('Father`s Foreign Trip (2)')}
                onChange={handleChange('FathersForeignTrip2')}
                />
                <label htmlFor="FathersForeignTrip2">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip3" 
                value={values.FathersForeignTrip3}
                type="text"
                placeholder={t('Father`s Foreign Trip (3)')}
                onChange={handleChange('FathersForeignTrip3')}
                />
                <label htmlFor="FathersForeignTrip3">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip4" 
                value={values.FathersForeignTrip4}
                type="text"
                placeholder={t('Father`s Foreign Trip (4)')}
                onChange={handleChange('FathersForeignTrip4')}
                />
                <label htmlFor="FathersForeignTrip4">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip5" 
                value={values.FathersForeignTrip5}
                type="text"
                placeholder={t('Father`s Foreign Trip (5)')}
                onChange={handleChange('FathersForeignTrip5')}
                />
                <label htmlFor="FathersForeignTrip5">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip6" 
                value={values.FathersForeignTrip6}
                type="text"
                placeholder={t('Father`s Foreign Trip (6)')}
                onChange={handleChange('FathersForeignTrip6')}
                />
                <label htmlFor="FathersForeignTrip6">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip7" 
                value={values.FathersForeignTrip7}
                type="text"
                placeholder={t('Father`s Foreign Trip (7)')}
                onChange={handleChange('FathersForeignTrip7')}
                />
                <label htmlFor="FathersForeignTrip7">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip8" 
                value={values.FathersForeignTrip8}
                type="text"
                placeholder={t('Father`s Foreign Trip (8)')}
                onChange={handleChange('FathersForeignTrip8')}
                />
                <label htmlFor="FathersForeignTrip8">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FathersForeignTrip9" 
                value={values.FathersForeignTrip9}
                type="text"
                placeholder={t('Father`s Foreign Trip (9)')}
                onChange={handleChange('FathersForeignTrip9')}
                />
                <label htmlFor="FathersForeignTrip9">{t('Enter the applicants Father`s Foreign Trip')}</label>
            </div>

            

            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Mother')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherName" 
                value={values.MotherName}
                type="text"
                placeholder={t('Mother`s Name')}
                onChange={handleChange('MotherName')}
                />
                <label htmlFor="MotherName">{t('Enter the applicants Mother Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherFamily" 
                value={values.MotherFamily}
                type="text"
                placeholder={t('Mother`s Family')}
                onChange={handleChange('MotherFamily')}
                />
                <label htmlFor="MotherFamily">{t('Enter the applicants Mother Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherJob" 
                value={values.MotherJob}
                type="text"
                placeholder={t('Mother`s Job')}
                onChange={handleChange('MotherJob')}
                />
                <label htmlFor="MotherJob">{t('Enter the applicants Mother Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={MotherBirthDay}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Mother`s Birth Day')}
              id="MotherBirthDay"
              onChange={setMotherBirthDay} 
              className="col-12"
            />}
              <label htmlFor="MotherBirthDay">{t('Enter the applicants Mother Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="MothersResidenceAddress" 
                value={values.MothersResidenceAddress}
                type="text"
                placeholder={t('Mother`s Residence Address')}
                onChange={handleChange('MothersResidenceAddress')}
                />
                <label htmlFor="MothersResidenceAddress">{t('Enter the applicants Mothers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherPostalCode" 
                value={values.MotherPostalCode}
                type="text"
                placeholder={t('Mother`s Postal Code')}
                onChange={handleChange('MotherPostalCode')}
                />
                <label htmlFor="MotherPostalCode">{t('Enter the applicants Mother Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherPhoneCall" 
                value={values.MotherPhoneCall}
                type="text"
                placeholder={t('Mother`s Phone Call')}
                onChange={handleChange('MotherPhoneCall')}
                />
                <label htmlFor="MotherPhoneCall">{t('Enter the applicants Mother Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherEducation" 
                value={values.MotherEducation}
                type="text"
                placeholder={t('Mother`s Education')}
                onChange={handleChange('MotherEducation')}
                />
                <label htmlFor="MotherEducation">{t('Enter the applicants Mother`s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MotherFinancialAbility" 
                value={values.MotherFinancialAbility}
                type="text"
                placeholder={t('Mother`s Financial Ability')}
                onChange={handleChange('MotherFinancialAbility')}
                />
                <label htmlFor="MotherFinancialAbility">{t('Enter the applicants Mother`s Financial Ability')}</label>
            </div>

          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={MotherDateofDivorce}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Mother`s Date of Divorce')}
              id="MotherDateofDivorce"
              onChange={setMotherDateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="MotherDateofDivorce">{t('Enter the applicants Mother`s Date of Divorce')}</label>
          </div>


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={MotherDateofDeath}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Mother`s Date of Death')}
              id="MotherDateofDeath"
              onChange={setMotherDateofDeath} 
              className="col-12"
            />}
              <label htmlFor="MotherDateofDeath">{t('Enter the applicants Mother`s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip1" 
                value={values.MothersForeignTrip1}
                type="text"
                placeholder={t('Mother`s Foreign Trip (1)')}
                onChange={handleChange('MothersForeignTrip1')}
                />
                <label htmlFor="MothersForeignTrip1">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip2" 
                value={values.MothersForeignTrip2}
                type="text"
                placeholder={t('Mother`s Foreign Trip (2)')}
                onChange={handleChange('MothersForeignTrip2')}
                />
                <label htmlFor="MothersForeignTrip2">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip3" 
                value={values.MothersForeignTrip3}
                type="text"
                placeholder={t('Mother`s Foreign Trip (3)')}
                onChange={handleChange('MothersForeignTrip3')}
                />
                <label htmlFor="MothersForeignTrip3">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip4" 
                value={values.MothersForeignTrip4}
                type="text"
                placeholder={t('Mother`s Foreign Trip (4)')}
                onChange={handleChange('MothersForeignTrip4')}
                />
                <label htmlFor="MothersForeignTrip4">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip5" 
                value={values.MothersForeignTrip5}
                type="text"
                placeholder={t('Mother`s Foreign Trip (5)')}
                onChange={handleChange('MothersForeignTrip5')}
                />
                <label htmlFor="MothersForeignTrip5">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip6" 
                value={values.MothersForeignTrip6}
                type="text"
                placeholder={t('Mother`s Foreign Trip (6)')}
                onChange={handleChange('MothersForeignTrip6')}
                />
                <label htmlFor="MothersForeignTrip6">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip7" 
                value={values.MothersForeignTrip7}
                type="text"
                placeholder={t('Mother`s Foreign Trip (7)')}
                onChange={handleChange('MothersForeignTrip7')}
                />
                <label htmlFor="MothersForeignTrip7">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip8" 
                value={values.MothersForeignTrip8}
                type="text"
                placeholder={t('Mother`s Foreign Trip (8)')}
                onChange={handleChange('MothersForeignTrip8')}
                />
                <label htmlFor="MothersForeignTrip8">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="MothersForeignTrip9" 
                value={values.MothersForeignTrip9}
                type="text"
                placeholder={t('Mother`s Foreign Trip (9)')}
                onChange={handleChange('MothersForeignTrip9')}
                />
                <label htmlFor="MothersForeignTrip9">{t('Enter the applicants Mother`s Foreign Trip')}</label>
            </div>






            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Brother (1)')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1Name" 
                value={values.Brother1Name}
                type="text"
                placeholder={t('1th Brother`s Name') }
                onChange={handleChange('Brother1Name')}
                />
                <label htmlFor="Brother1Name">{t('Enter the applicants 1th Brother Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1Family" 
                value={values.Brother1Family}
                type="text"
                placeholder={t('1th Brother`s Family')}
                onChange={handleChange('Brother1Family')}
                />
                <label htmlFor="Brother1Family">{t('Enter the applicants 1th Brother Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1Job" 
                value={values.Brother1Job}
                type="text"
                placeholder={t('1th Brother`s Job')} 
                onChange={handleChange('Brother1Job')}
                />
                <label htmlFor="Brother1Job">{t('Enter the applicants 1th Brother Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother1BirthDay}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('1th Brother`s Birth  Day')}
              id="Brother1BirthDay"
              onChange={setBrother1BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Brother1BirthDay">{t('Enter the applicants  1th Brother Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Brother1sResidenceAddress" 
                value={values.Brother1sResidenceAddress}
                type="text"
                placeholder={t('1th Brother`s Reside nce Address')}
                onChange={handleChange('Brother1sResidenceAddress')}
                />
                <label htmlFor="Brother1sResidenceAddress">{t('Enter the applicants  1th Brothers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1PostalCode" 
                value={values.Brother1PostalCode}
                type="text"
                placeholder={t('1th Brother`s Postal  Code')}
                onChange={handleChange('Brother1PostalCode')}
                />
                <label htmlFor="Brother1PostalCode">{t('Enter the applicants  1th Brother Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1PhoneCall" 
                value={values.Brother1PhoneCall}
                type="text"
                placeholder={t('1th Brother`s Phone  Call')}
                onChange={handleChange('Brother1PhoneCall')}
                />
                <label htmlFor="Brother1PhoneCall">{t('Enter the applicants  1th Brother Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1Education" 
                value={values.Brother1Education}
                type="text"
                placeholder={t('1th Brother`s Education')}
                onChange={handleChange('Brother1Education')}
                />
                <label htmlFor="Brother1Education">{t('Enter the applicants 1th Brother`s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1FinancialAbility" 
                value={values.Brother1FinancialAbility}
                type="text"
                placeholder={t('1th Brother`s Financial Ability')}
                onChange={handleChange('Brother1FinancialAbility')}
                />
                <label htmlFor="Brother1FinancialAbility">{t('Enter the applicants 1th Brother`s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother1DateofDivorce}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('1th Brother`s Date o f Divorce')}
              id="Brother1DateofDivorce"
              onChange={setBrother1DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Brother1DateofDivorce">{t('Enter the applicants 1th Brother`s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother1DateofDeath}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('1th Brother`s Date of Death')}
              id="Brother1DateofDeath"
              onChange={setBrother1DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Brother1DateofDeath">{t('Enter the applicants 1th Brother`s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip1" 
                value={values.Brother1sForeignTrip1}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (1)')}
                onChange={handleChange('Brother1sForeignTrip1')}
                />
                <label htmlFor="Brother1sForeignTrip1">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip2" 
                value={values.Brother1sForeignTrip2}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (2)')}
                onChange={handleChange('Brother1sForeignTrip2')}
                />
                <label htmlFor="Brother1sForeignTrip2">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip3" 
                value={values.Brother1sForeignTrip3}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (3)')}
                onChange={handleChange('Brother1sForeignTrip3')}
                />
                <label htmlFor="Brother1sForeignTrip3">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip4" 
                value={values.Brother1sForeignTrip4}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (4)')}
                onChange={handleChange('Brother1sForeignTrip4')}
                />
                <label htmlFor="Brother1sForeignTrip4">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip5" 
                value={values.Brother1sForeignTrip5}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (5)')}
                onChange={handleChange('Brother1sForeignTrip5')}
                />
                <label htmlFor="Brother1sForeignTrip5">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip6" 
                value={values.Brother1sForeignTrip6}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (6)')}
                onChange={handleChange('Brother1sForeignTrip6')}
                />
                <label htmlFor="Brother1sForeignTrip6">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip7" 
                value={values.Brother1sForeignTrip7}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (7)')}
                onChange={handleChange('Brother1sForeignTrip7')}
                />
                <label htmlFor="Brother1sForeignTrip7">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip8" 
                value={values.Brother1sForeignTrip8}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (8)')}
                onChange={handleChange('Brother1sForeignTrip8')}
                />
                <label htmlFor="Brother1sForeignTrip8">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother1sForeignTrip9" 
                value={values.Brother1sForeignTrip9}
                type="text"
                placeholder={t('1th Brother`s Foreign Trip (9)')}
                onChange={handleChange('Brother1sForeignTrip9')}
                />
                <label htmlFor="Brother1sForeignTrip9">{t('Enter the applicants 1th Brother`s Foreign Trip')}</label>
            </div>




            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Brother (2)')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2Name" 
                value={values.Brother2Name}
                type="text"
                placeholder={t('2nd Brother`s Name') }
                onChange={handleChange('Brother2Name')}
                />
                <label htmlFor="Brother2Name">{t('Enter the applicants 2nd Brother Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2Family" 
                value={values.Brother2Family}
                type="text"
                placeholder={t('2nd Brother`s Family')}
                onChange={handleChange('Brother2Family')}
                />
                <label htmlFor="Brother2Family">{t('Enter the applicants 2nd Brother Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2Job" 
                value={values.Brother2Job}
                type="text"
                placeholder={t('2nd Brother`s Job')} 
                onChange={handleChange('Brother2Job')}
                />
                <label htmlFor="Brother2Job">{t('Enter the applicants 2nd Brother Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother2BirthDay}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('2nd Brother`s Birth  Day')}
              id="Brother2BirthDay"
              onChange={setBrother2BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Brother2BirthDay">{t('Enter the applicants  2nd Brother Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Brother2sResidenceAddress" 
                value={values.Brother2sResidenceAddress}
                type="text"
                placeholder={t('2nd Brother`s Reside nce Address')}
                onChange={handleChange('Brother2sResidenceAddress')}
                />
                <label htmlFor="Brother2sResidenceAddress">{t('Enter the applicants  2nd Brothers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2PostalCode" 
                value={values.Brother2PostalCode}
                type="text"
                placeholder={t('2nd Brother`s Postal  Code')}
                onChange={handleChange('Brother2PostalCode')}
                />
                <label htmlFor="Brother2PostalCode">{t('Enter the applicants  2nd Brother Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2PhoneCall" 
                value={values.Brother2PhoneCall}
                type="text"
                placeholder={t('2nd Brother`s Phone  Call')}
                onChange={handleChange('Brother2PhoneCall')}
                />
                <label htmlFor="Brother2PhoneCall">{t('Enter the applicants  2nd Brother Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2Education" 
                value={values.Brother2Education}
                type="text"
                placeholder={t('2nd Brother`s Education')}
                onChange={handleChange('Brother2Education')}
                />
                <label htmlFor="Brother2Education">{t('Enter the applicants 2nd Brother`s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2FinancialAbility" 
                value={values.Brother2FinancialAbility}
                type="text"
                placeholder={t('2nd Brother`s Financial Ability')}
                onChange={handleChange('Brother2FinancialAbility')}
                />
                <label htmlFor="Brother2FinancialAbility">{t('Enter the applicants 2nd Brother`s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother2DateofDivorce}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('2nd Brother`s Date o f Divorce')}
              id="Brother2DateofDivorce"
              onChange={setBrother2DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Brother2DateofDivorce">{t('Enter the applicants 2nd Brother`s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother2DateofDeath}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('2nd Brother`s Date of Death')}
              id="Brother2DateofDeath"
              onChange={setBrother2DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Brother2DateofDeath">{t('Enter the applicants 2nd Brother`s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip1" 
                value={values.Brother2sForeignTrip1}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (1)')}
                onChange={handleChange('Brother2sForeignTrip1')}
                />
                <label htmlFor="Brother2sForeignTrip1">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip2" 
                value={values.Brother2sForeignTrip2}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (2)')}
                onChange={handleChange('Brother2sForeignTrip2')}
                />
                <label htmlFor="Brother2sForeignTrip2">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip3" 
                value={values.Brother2sForeignTrip3}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (3)')}
                onChange={handleChange('Brother2sForeignTrip3')}
                />
                <label htmlFor="Brother2sForeignTrip3">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip4" 
                value={values.Brother2sForeignTrip4}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (4)')}
                onChange={handleChange('Brother2sForeignTrip4')}
                />
                <label htmlFor="Brother2sForeignTrip4">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip5" 
                value={values.Brother2sForeignTrip5}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (5)')}
                onChange={handleChange('Brother2sForeignTrip5')}
                />
                <label htmlFor="Brother2sForeignTrip5">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip6" 
                value={values.Brother2sForeignTrip6}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (6)')}
                onChange={handleChange('Brother2sForeignTrip6')}
                />
                <label htmlFor="Brother2sForeignTrip6">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip7" 
                value={values.Brother2sForeignTrip7}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (7)')}
                onChange={handleChange('Brother2sForeignTrip7')}
                />
                <label htmlFor="Brother2sForeignTrip7">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip8" 
                value={values.Brother2sForeignTrip8}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (8)')}
                onChange={handleChange('Brother2sForeignTrip8')}
                />
                <label htmlFor="Brother2sForeignTrip8">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother2sForeignTrip9" 
                value={values.Brother2sForeignTrip9}
                type="text"
                placeholder={t('2nd Brother`s Foreign Trip (9)')}
                onChange={handleChange('Brother2sForeignTrip9')}
                />
                <label htmlFor="Brother2sForeignTrip9">{t('Enter the applicants 2nd Brother`s Foreign Trip')}</label>
            </div>












            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Brother (3)')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3Name" 
                value={values.Brother3Name}
                type="text"
                placeholder={t('3rd Brother`s Name') }
                onChange={handleChange('Brother3Name')}
                />
                <label htmlFor="Brother3Name">{t('Enter the applicants 3rd Brother Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3Family" 
                value={values.Brother3Family}
                type="text"
                placeholder={t('3rd Brother`s Family')}
                onChange={handleChange('Brother3Family')}
                />
                <label htmlFor="Brother3Family">{t('Enter the applicants 3rd Brother Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3Job" 
                value={values.Brother3Job}
                type="text"
                placeholder={t('3rd Brother`s Job')} 
                onChange={handleChange('Brother3Job')}
                />
                <label htmlFor="Brother3Job">{t('Enter the applicants 3rd Brother Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother3BirthDay}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('3rd Brother`s Birth  Day')}
              id="Brother3BirthDay"
              onChange={setBrother3BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Brother3BirthDay">{t('Enter the applicants  3rd Brother Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Brother3sResidenceAddress" 
                value={values.Brother3sResidenceAddress}
                type="text"
                placeholder={t('3rd Brother`s Reside nce Address')}
                onChange={handleChange('Brother3sResidenceAddress')}
                />
                <label htmlFor="Brother3sResidenceAddress">{t('Enter the applicants  3rd Brothers Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3PostalCode" 
                value={values.Brother3PostalCode}
                type="text"
                placeholder={t('3rd Brother`s Postal  Code')}
                onChange={handleChange('Brother3PostalCode')}
                />
                <label htmlFor="Brother3PostalCode">{t('Enter the applicants  3rd Brother Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3PhoneCall" 
                value={values.Brother3PhoneCall}
                type="text"
                placeholder={t('3rd Brother`s Phone  Call')}
                onChange={handleChange('Brother3PhoneCall')}
                />
                <label htmlFor="Brother3PhoneCall">{t('Enter the applicants  3rd Brother Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3Education" 
                value={values.Brother3Education}
                type="text"
                placeholder={t('3rd Brother`s Education')}
                onChange={handleChange('Brother3Education')}
                />
                <label htmlFor="Brother3Education">{t('Enter the applicants 3rd Brother`s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3FinancialAbility" 
                value={values.Brother3FinancialAbility}
                type="text"
                placeholder={t('3rd Brother`s Financial Ability')}
                onChange={handleChange('Brother3FinancialAbility')}
                />
                <label htmlFor="Brother3FinancialAbility">{t('Enter the applicants 3rd Brother`s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother3DateofDivorce}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('3rd Brother`s Date o f Divorce')}
              id="Brother3DateofDivorce"
              onChange={setBrother3DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Brother3DateofDivorce">{t('Enter the applicants 3rd Brother`s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Brother3DateofDeath}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('3rd Brother`s Date of Death')}
              id="Brother3DateofDeath"
              onChange={setBrother3DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Brother3DateofDeath">{t('Enter the applicants 3rd Brother`s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip1" 
                value={values.Brother3sForeignTrip1}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (1)')}
                onChange={handleChange('Brother3sForeignTrip1')}
                />
                <label htmlFor="Brother3sForeignTrip1">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip2" 
                value={values.Brother3sForeignTrip2}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (2)')}
                onChange={handleChange('Brother3sForeignTrip2')}
                />
                <label htmlFor="Brother3sForeignTrip2">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip3" 
                value={values.Brother3sForeignTrip3}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (3)')}
                onChange={handleChange('Brother3sForeignTrip3')}
                />
                <label htmlFor="Brother3sForeignTrip3">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip4" 
                value={values.Brother3sForeignTrip4}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (4)')}
                onChange={handleChange('Brother3sForeignTrip4')}
                />
                <label htmlFor="Brother3sForeignTrip4">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip5" 
                value={values.Brother3sForeignTrip5}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (5)')}
                onChange={handleChange('Brother3sForeignTrip5')}
                />
                <label htmlFor="Brother3sForeignTrip5">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip6" 
                value={values.Brother3sForeignTrip6}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (6)')}
                onChange={handleChange('Brother3sForeignTrip6')}
                />
                <label htmlFor="Brother3sForeignTrip6">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip7" 
                value={values.Brother3sForeignTrip7}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (7)')}
                onChange={handleChange('Brother3sForeignTrip7')}
                />
                <label htmlFor="Brother3sForeignTrip7">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip8" 
                value={values.Brother3sForeignTrip8}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (8)')}
                onChange={handleChange('Brother3sForeignTrip8')}
                />
                <label htmlFor="Brother3sForeignTrip8">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Brother3sForeignTrip9" 
                value={values.Brother3sForeignTrip9}
                type="text"
                placeholder={t('3rd Brother`s Foreign Trip (9)')}
                onChange={handleChange('Brother3sForeignTrip9')}
                />
                <label htmlFor="Brother3sForeignTrip9">{t('Enter the applicants 3rd Brother`s Foreign Trip')}</label>
            </div>




           
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Sister (1)')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1Name" 
                value={values.Sister1Name}
                type="text"
                placeholder={t('1th Sister`s Name') }
                onChange={handleChange('Sister1Name')}
                />
                <label htmlFor="Sister1Name">{t('Enter the applicants 1th Sister Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1Family" 
                value={values.Sister1Family}
                type="text"
                placeholder={t('1th Sister`s Family')}
                onChange={handleChange('Sister1Family')}
                />
                <label htmlFor="Sister1Family">{t('Enter the applicants 1th Sister Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1Job" 
                value={values.Sister1Job}
                type="text"
                placeholder={t('1th Sister`s Job')} 
                onChange={handleChange('Sister1Job')}
                />
                <label htmlFor="Sister1Job">{t('Enter the applicants 1th Sister Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister1BirthDay}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('1th Sister`s Birth  Day')}
              id="Sister1BirthDay"
              onChange={setSister1BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Sister1BirthDay">{t('Enter the applicants  1th Sister Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Sister1sResidenceAddress" 
                value={values.Sister1sResidenceAddress}
                type="text"
                placeholder={t('1th Sister`s Reside nce Address')}
                onChange={handleChange('Sister1sResidenceAddress')}
                />
                <label htmlFor="Sister1sResidenceAddress">{t('Enter the applicants  1th Sisters Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1PostalCode" 
                value={values.Sister1PostalCode}
                type="text"
                placeholder={t('1th Sister`s Postal  Code')}
                onChange={handleChange('Sister1PostalCode')}
                />
                <label htmlFor="Sister1PostalCode">{t('Enter the applicants  1th Sister Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1PhoneCall" 
                value={values.Sister1PhoneCall}
                type="text"
                placeholder={t('1th Sister`s Phone  Call')}
                onChange={handleChange('Sister1PhoneCall')}
                />
                <label htmlFor="Sister1PhoneCall">{t('Enter the applicants  1th Sister Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1Education" 
                value={values.Sister1Education}
                type="text"
                placeholder={t('1th Sister`s Education')}
                onChange={handleChange('Sister1Education')}
                />
                <label htmlFor="Sister1Education">{t('Enter the applicants 1th Sister`s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1FinancialAbility" 
                value={values.Sister1FinancialAbility}
                type="text"
                placeholder={t('1th Sister`s Financial Ability')}
                onChange={handleChange('Sister1FinancialAbility')}
                />
                <label htmlFor="Sister1FinancialAbility">{t('Enter the applicants 1th Sister`s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister1DateofDivorce}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('1th Sister`s Date o f Divorce')}
              id="Sister1DateofDivorce"
              onChange={setSister1DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Sister1DateofDivorce">{t('Enter the applicants 1th Sister`s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister1DateofDeath}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('1th Sister`s Date of Death')}
              id="Sister1DateofDeath"
              onChange={setSister1DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Sister1DateofDeath">{t('Enter the applicants 1th Sister`s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip1" 
                value={values.Sister1sForeignTrip1}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (1)')}
                onChange={handleChange('Sister1sForeignTrip1')}
                />
                <label htmlFor="Sister1sForeignTrip1">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip2" 
                value={values.Sister1sForeignTrip2}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (2)')}
                onChange={handleChange('Sister1sForeignTrip2')}
                />
                <label htmlFor="Sister1sForeignTrip2">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip3" 
                value={values.Sister1sForeignTrip3}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (3)')}
                onChange={handleChange('Sister1sForeignTrip3')}
                />
                <label htmlFor="Sister1sForeignTrip3">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip4" 
                value={values.Sister1sForeignTrip4}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (4)')}
                onChange={handleChange('Sister1sForeignTrip4')}
                />
                <label htmlFor="Sister1sForeignTrip4">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip5" 
                value={values.Sister1sForeignTrip5}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (5)')}
                onChange={handleChange('Sister1sForeignTrip5')}
                />
                <label htmlFor="Sister1sForeignTrip5">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip6" 
                value={values.Sister1sForeignTrip6}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (6)')}
                onChange={handleChange('Sister1sForeignTrip6')}
                />
                <label htmlFor="Sister1sForeignTrip6">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip7" 
                value={values.Sister1sForeignTrip7}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (7)')}
                onChange={handleChange('Sister1sForeignTrip7')}
                />
                <label htmlFor="Sister1sForeignTrip7">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip8" 
                value={values.Sister1sForeignTrip8}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (8)')}
                onChange={handleChange('Sister1sForeignTrip8')}
                />
                <label htmlFor="Sister1sForeignTrip8">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister1sForeignTrip9" 
                value={values.Sister1sForeignTrip9}
                type="text"
                placeholder={t('1th Sister`s Foreign Trip (9)')}
                onChange={handleChange('Sister1sForeignTrip9')}
                />
                <label htmlFor="Sister1sForeignTrip9">{t('Enter the applicants 1th Sister`s Foreign Trip')}</label>
            </div>




            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Sister (2)')}</p> 
          
          </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2Name" 
                value={values.Sister2Name}
                type="text"
                placeholder={t('2nd Sister`s Name') }
                onChange={handleChange('Sister2Name')}
                />
                <label htmlFor="Sister2Name">{t('Enter the applicants 2nd Sister Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2Family" 
                value={values.Sister2Family}
                type="text"
                placeholder={t('2nd Sister`s Family')}
                onChange={handleChange('Sister2Family')}
                />
                <label htmlFor="Sister2Family">{t('Enter the applicants 2nd Sister Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2Job" 
                value={values.Sister2Job}
                type="text"
                placeholder={t('2nd Sister`s Job')} 
                onChange={handleChange('Sister2Job')}
                />
                <label htmlFor="Sister2Job">{t('Enter the applicants 2nd Sister Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister2BirthDay}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('2nd Sister`s Birth  Day')}
              id="Sister2BirthDay"
              onChange={setSister2BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Sister2BirthDay">{t('Enter the applicants  2nd Sister Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Sister2sResidenceAddress" 
                value={values.Sister2sResidenceAddress}
                type="text"
                placeholder={t('2nd Sister`s Reside nce Address')}
                onChange={handleChange('Sister2sResidenceAddress')}
                />
                <label htmlFor="Sister2sResidenceAddress">{t('Enter the applicants  2nd Sisters Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2PostalCode" 
                value={values.Sister2PostalCode}
                type="text"
                placeholder={t('2nd Sister`s Postal  Code')}
                onChange={handleChange('Sister2PostalCode')}
                />
                <label htmlFor="Sister2PostalCode">{t('Enter the applicants  2nd Sister Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2PhoneCall" 
                value={values.Sister2PhoneCall}
                type="text"
                placeholder={t('2nd Sister`s Phone  Call')}
                onChange={handleChange('Sister2PhoneCall')}
                />
                <label htmlFor="Sister2PhoneCall">{t('Enter the applicants  2nd Sister Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2Education" 
                value={values.Sister2Education}
                type="text"
                placeholder={t('2nd Sister`s Education')}
                onChange={handleChange('Sister2Education')}
                />
                <label htmlFor="Sister2Education">{t('Enter the applicants 2nd Sister`s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2FinancialAbility" 
                value={values.Sister2FinancialAbility}
                type="text"
                placeholder={t('2nd Sister`s Financial Ability')}
                onChange={handleChange('Sister2FinancialAbility')}
                />
                <label htmlFor="Sister2FinancialAbility">{t('Enter the applicants 2nd Sister`s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister2DateofDivorce}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('2nd Sister`s Date o f Divorce')}
              id="Sister2DateofDivorce"
              onChange={setSister2DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Sister2DateofDivorce">{t('Enter the applicants 2nd Sister`s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister2DateofDeath}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('2nd Sister`s Date of Death')}
              id="Sister2DateofDeath"
              onChange={setSister2DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Sister2DateofDeath">{t('Enter the applicants 2nd Sister`s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip1" 
                value={values.Sister2sForeignTrip1}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (1)')}
                onChange={handleChange('Sister2sForeignTrip1')}
                />
                <label htmlFor="Sister2sForeignTrip1">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip2" 
                value={values.Sister2sForeignTrip2}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (2)')}
                onChange={handleChange('Sister2sForeignTrip2')}
                />
                <label htmlFor="Sister2sForeignTrip2">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip3" 
                value={values.Sister2sForeignTrip3}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (3)')}
                onChange={handleChange('Sister2sForeignTrip3')}
                />
                <label htmlFor="Sister2sForeignTrip3">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip4" 
                value={values.Sister2sForeignTrip4}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (4)')}
                onChange={handleChange('Sister2sForeignTrip4')}
                />
                <label htmlFor="Sister2sForeignTrip4">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip5" 
                value={values.Sister2sForeignTrip5}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (5)')}
                onChange={handleChange('Sister2sForeignTrip5')}
                />
                <label htmlFor="Sister2sForeignTrip5">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip6" 
                value={values.Sister2sForeignTrip6}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (6)')}
                onChange={handleChange('Sister2sForeignTrip6')}
                />
                <label htmlFor="Sister2sForeignTrip6">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip7" 
                value={values.Sister2sForeignTrip7}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (7)')}
                onChange={handleChange('Sister2sForeignTrip7')}
                />
                <label htmlFor="Sister2sForeignTrip7">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip8" 
                value={values.Sister2sForeignTrip8}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (8)')}
                onChange={handleChange('Sister2sForeignTrip8')}
                />
                <label htmlFor="Sister2sForeignTrip8">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister2sForeignTrip9" 
                value={values.Sister2sForeignTrip9}
                type="text"
                placeholder={t('2nd Sister`s Foreign Trip (9)')}
                onChange={handleChange('Sister2sForeignTrip9')}
                />
                <label htmlFor="Sister2sForeignTrip9">{t('Enter the applicants 2nd Sister`s Foreign Trip')}</label>
            </div>












            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Sister (3)')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3Name" 
                value={values.Sister3Name}
                type="text"
                placeholder={t('3rd Sister`s Name') }
                onChange={handleChange('Sister3Name')}
                />
                <label htmlFor="Sister3Name">{t('Enter the applicants 3rd Sister Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3Family" 
                value={values.Sister3Family}
                type="text"
                placeholder={t('3rd Sister`s Family')}
                onChange={handleChange('Sister3Family')}
                />
                <label htmlFor="Sister3Family">{t('Enter the applicants 3rd Sister Family')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3Job" 
                value={values.Sister3Job}
                type="text"
                placeholder={t('3rd Sister`s Job')} 
                onChange={handleChange('Sister3Job')}
                />
                <label htmlFor="Sister3Job">{t('Enter the applicants 3rd Sister Job')}</label>
            </div>


            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister3BirthDay}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('3rd Sister`s Birth  Day')}
              id="Sister3BirthDay"
              onChange={setSister3BirthDay} 
              className="col-12"
            />}
              <label htmlFor="Sister3BirthDay">{t('Enter the applicants  3rd Sister Birth Day')}</label>
          </div>

          <div className="inputAndlabel col-lg-8 col-12">
              <input
                id="Sister3sResidenceAddress" 
                value={values.Sister3sResidenceAddress}
                type="text"
                placeholder={t('3rd Sister`s Reside nce Address')}
                onChange={handleChange('Sister3sResidenceAddress')}
                />
                <label htmlFor="Sister3sResidenceAddress">{t('Enter the applicants  3rd Sisters Residence Address')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3PostalCode" 
                value={values.Sister3PostalCode}
                type="text"
                placeholder={t('3rd Sister`s Postal  Code')}
                onChange={handleChange('Sister3PostalCode')}
                />
                <label htmlFor="Sister3PostalCode">{t('Enter the applicants  3rd Sister Postal Code')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3PhoneCall" 
                value={values.Sister3PhoneCall}
                type="text"
                placeholder={t('3rd Sister`s Phone  Call')}
                onChange={handleChange('Sister3PhoneCall')}
                />
                <label htmlFor="Sister3PhoneCall">{t('Enter the applicants  3rd Sister Phone Call')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3Education" 
                value={values.Sister3Education}
                type="text"
                placeholder={t('3rd Sister`s Education')}
                onChange={handleChange('Sister3Education')}
                />
                <label htmlFor="Sister3Education">{t('Enter the applicants 3rd Sister`s Education')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3FinancialAbility" 
                value={values.Sister3FinancialAbility}
                type="text"
                placeholder={t('3rd Sister`s Financial Ability')}
                onChange={handleChange('Sister3FinancialAbility')}
                />
                <label htmlFor="Sister3FinancialAbility">{t('Enter the applicants 3rd Sister`s Financial Ability')}</label>
            </div>

          {/* <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister3DateofDivorce}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('3rd Sister`s Date o f Divorce')}
              id="Sister3DateofDivorce"
              onChange={setSister3DateofDivorce} 
              className="col-12"
            />}
              <label htmlFor="Sister3DateofDivorce">{t('Enter the applicants 3rd Sister`s Date o f Divorce')}</label>
          </div> */}


          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={Sister3DateofDeath}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('3rd Sister`s Date of Death')}
              id="Sister3DateofDeath"
              onChange={setSister3DateofDeath} 
              className="col-12"
            />}
              <label htmlFor="Sister3DateofDeath">{t('Enter the applicants 3rd Sister`s Date of Death')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
             <div className="inputAndlabel col-lg-4 d-none d-lg-block">
              
            </div>
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip1" 
                value={values.Sister3sForeignTrip1}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (1)')}
                onChange={handleChange('Sister3sForeignTrip1')}
                />
                <label htmlFor="Sister3sForeignTrip1">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip2" 
                value={values.Sister3sForeignTrip2}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (2)')}
                onChange={handleChange('Sister3sForeignTrip2')}
                />
                <label htmlFor="Sister3sForeignTrip2">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip3" 
                value={values.Sister3sForeignTrip3}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (3)')}
                onChange={handleChange('Sister3sForeignTrip3')}
                />
                <label htmlFor="Sister3sForeignTrip3">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip4" 
                value={values.Sister3sForeignTrip4}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (4)')}
                onChange={handleChange('Sister3sForeignTrip4')}
                />
                <label htmlFor="Sister3sForeignTrip4">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip5" 
                value={values.Sister3sForeignTrip5}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (5)')}
                onChange={handleChange('Sister3sForeignTrip5')}
                />
                <label htmlFor="Sister3sForeignTrip5">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip6" 
                value={values.Sister3sForeignTrip6}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (6)')}
                onChange={handleChange('Sister3sForeignTrip6')}
                />
                <label htmlFor="Sister3sForeignTrip6">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip7" 
                value={values.Sister3sForeignTrip7}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (7)')}
                onChange={handleChange('Sister3sForeignTrip7')}
                />
                <label htmlFor="Sister3sForeignTrip7">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip8" 
                value={values.Sister3sForeignTrip8}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (8)')}
                onChange={handleChange('Sister3sForeignTrip8')}
                />
                <label htmlFor="Sister3sForeignTrip8">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="Sister3sForeignTrip9" 
                value={values.Sister3sForeignTrip9}
                type="text"
                placeholder={t('3rd Sister`s Foreign Trip (9)')}
                onChange={handleChange('Sister3sForeignTrip9')}
                />
                <label htmlFor="Sister3sForeignTrip9">{t('Enter the applicants 3rd Sister`s Foreign Trip')}</label>
            </div>



          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Prime Family Documents')}</p> 
          
          </div>
          
          
  <div className="MyFileUploadRow">

   <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImageChange} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target" src={image} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (1)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox2">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage2Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (2)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox3">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage3Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (3)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox4">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage4Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (4)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox5">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage5Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (5)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox6">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage6Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (6)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox7">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage7Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (7)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox8">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage8Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Passport (8)')}</p> 
    </div>
    </div>

 


   </div>


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {<button className="btn btn-dark">{t('Submit')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>

        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;