import {SearchNormal,Notification,
    ArrowDown2,HambergerMenu,ArrowLeft,Wallet1,
    Facebook,Whatsapp,CallCalling,Instagram,Youtube
    ,Home2,TickSquare,I24Support,Star1} from 'iconsax-react';
import { useState , useEffect , useRef} from 'react';
import avatarPicture from './images/Avatar.png';
import { useTranslation } from 'react-i18next'; // multi language package
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import LeftNavBar from '../LeftNavBar/LeftNavBar';
import Popup from './Popup';
import Popup2 from './Popup2';
import Popup3 from './Popup3';
import mainPic from './404.png';
import teleLogo from './teleLogo.png';
import RegisterLogo from './teleLogo.png';
import HelpIcon from '@mui/icons-material/Help';
import { blue, grey } from '@mui/material/colors';


function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
  }


const TopMenu = ({userActive , childToParent ,
        childToParentCus,
        childToParentCusA2 , 
        childToParentCusADis , 
        childToParentCusA2A , 
        childToParentCus1 ,
        childToParentAgents ,
        childToParentPrices , 
        childToParentLawyer,
        childToParentLawyers,
        childToParentAgentAgent,
        childToParentLawyerHome,
        childToParentAgentHome,
        childToParentCustomerHome,
        childToParentMarketerHome,
        childToParentCusMark,
        childToParentMessage,
        childToParentRefCustomers,
        childToParentMarketerDist ,
        childToParentAgentDis,
        childToParentAgentPrice,
        childToParentMarketerPrice,
        childToParentDCCustomers,
}) => {
    const forceUpdate = useForceUpdate();
    const [countNotification,setCountNotification] = useState(true);
    const [countProfile,setCountProfile] = useState(true);
    const [values, setValues] = useState({
        Email: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        SelectLanguage:localStorage.getItem('langV'),
      });
    
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
       
      };
      const {t, i18n} = useTranslation(['Login']);
      const  handelIR = (e) => {
          // e.preventDefault();
          i18n.changeLanguage('fa_IR');
          document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
          document.body.setAttribute('lang', 'fa');
  }
      const  handelUS = (e) => {
          // e.preventDefault();
          i18n.changeLanguage('en_US');
          document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
          document.body.setAttribute('lang', 'en');
          // localStorage.getItem('userType');
  }
  if(localStorage.getItem('langL')){
  
  }
  else
  {
    localStorage.setItem('langL' , "English");
    localStorage.setItem('langV' , 1);
  }
  
  const [selectValue , setSelectValue] = useState({ label: localStorage.getItem('langL'), value: localStorage.getItem('langV') });
  useEffect(() => {
    if(values.SelectLanguage != 2)
    {
      handelUS();
      localStorage.setItem('langV' , 1);
      localStorage.setItem('langL' , "English");
    }
     
    else
    {
      handelIR()
      localStorage.setItem('langV' , 2);
      localStorage.setItem('langL' , "Persian");
    }
      
  }, [values.SelectLanguage]);
    const history = useHistory();
    const handleClickNotification = (e) => {
        if(!countNotification){
            setCountNotification(true);
            document.getElementById('messageBox1').style.display='none';
        }
        else{
            setCountNotification(false);
            document.getElementById('messageBox1').style.display='flex';
        }
    }
    const handleClickNotification2 = (e) => {
            document.getElementById('messageBox1').style.display='none';
            setCountNotification(true);
    }
    
    const handlemessageBox2Blur = (e) => {
       
            document.getElementById('messageBox2').style.display='none';
       
    }
    const handleClickProfile = (e) => {
        if(!countProfile){
            setCountProfile(true);
            document.getElementById('messageBox2').style.display='none';
        }
        else{
            setCountProfile(false);
            document.getElementById('messageBox2').style.display='block';
        }
    }
    const handleClickProfile2 = (e) => {
            document.getElementById('messageBox2').style.display='none';
            setCountProfile(true);
    }
    const handleClickSerachBox = (e) => {
        document.getElementById('SerachBox').style.display='flex';
}
const handleClickSerachBoxBlur = (e) => {
    document.getElementById('SerachBox').style.display='none';
}
// const handleLftMenuM = (e) => {
//     document.getElementById('SerachBox').style.display='none';
// }
// sign out
const handleSingOut = (e) => {
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('userType');
    localStorage.removeItem('userId');
    localStorage.removeItem('isAgentActive'); 
    localStorage.removeItem('token');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('distributorPlusCode');
    localStorage.removeItem('messageNotificationName1');
    localStorage.removeItem('messageNotificationName2');
    localStorage.removeItem('messageNotificationName3');
    localStorage.removeItem('messageNotificationName4');
    localStorage.removeItem('messageNotificationName5');
    localStorage.removeItem('messageNotificationCount');
    fetch('https://telesiness.ir/api/LogOut' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ Email: localStorage.email3 ,Password:localStorage.password3 })
    }).then().then(history.push('/'));
    // history.push('/');
}

// *********** popUp Left Menu Mobile ****
const [isOpen, setIsOpen] = useState(false);
const [isOpen2, setIsOpen2] = useState(false);

const [isOpen3, setIsOpen3] = useState(false);


useEffect(() => {

    if(((document.getElementById('MobileLeftNavPro') || document.getElementById('MobileLeftNavHelpCenter')) && window.innerWidth<750)){
        document.getElementById('leftNavMobileMenu').style.removeProperty('visibility');
        document.getElementById('leftNavMobileMenu').style.visibility="visible";
        setIsOpen(!isOpen);
        
      }


  }, [document.getElementById('MobileLeftNavPro')]);
const [state, setState] = useState({ num: 0 });
const counter = useRef(0);
useEffect(() => {
    if (counter.current < 2000) {
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
    


    return () => clearTimeout(timer);
    }
    }, [state]);



const togglePopup11 = () => {
  
  if(!isOpen && !(document.getElementById('MobileLeftNavPro') || document.getElementById('MobileLeftNavHelpCenter'))){
    document.getElementById('leftNavMobileMenu').style.visibility="hidden";
    setIsOpen(!isOpen);
  }
  else if(window.innerWidth<750){

    document.getElementById('leftNavMobileMenu').style.removeProperty('visibility');
    document.getElementById('leftNavMobileMenu').style.visibility="visible";
    setIsOpen(!isOpen);
  }
  forceUpdate();
  //console.log(isOpen);

} 
const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
}

const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
}

// forceUpdate();
//********** to dashboard */
const data = true;

//*********** */
return (  
    <div className="col-12 myRowDashMob">
        <div className="tpMenu col-lg-8 col-md-7 col-12">
            <div className="form-group has-success has-feedback col-12" id="searchbox">
                <input 
                autoComplete='none'
                onBlur={handleClickSerachBoxBlur} 
                onClick={handleClickSerachBox} 
                placeholder={t('Comming Soon')}
                disabled
                type="text" 
                className="form-control" 
                id="inputSuccess2"/>
                {/* <SearchNormal className="d-none d-md-block" size="18" variant="Bold" color="#000000"/> */}
                <HambergerMenu onClick={togglePopup11} className="d-flex d-md-none" size="24"  color="#000000"/>
            </div>
        </div>
        <div className="SerachBox" id="SerachBox">
            <a href="#" className="aNonDecor col-12">
            Mohammad Ahmadi <hr className="LineSearch"/> Customers
            </a>
            <a href="#" className="aNonDecor col-12">
            Mohammad Ahmadi <hr className="LineSearch"/> Agents
            </a>
            <a href="#" className="aNonDecor col-12">
            Mohammad Ahmadi <hr className="LineSearch"/> Customers
            </a>
        </div>  
        <div className="tpHelp col-lg-4 col-md-5 col-3">
        <Link className="help_center" onClick={togglePopup3} to='/Dashboard'><HelpIcon size="29" sx={{ fontSize: 29 , color: grey[900] }}/> </Link>
            {/* <a className="help_center col-xl-4 col-md-5 aNonDecorM APADING disabled" href="#">{t('Help Center')}</a> */}
            <div className="notification col-xl-3 col-6">
                <a className="notif_center" onClick={handleClickNotification} href="#">
                    <Notification size="29" variant="Bold" color="#000000"/>
                    {((parseInt(localStorage.getItem('messageNotificationCount'))!=0)&&localStorage.getItem('messageNotificationCount')) &&<span className="button__badge"><b>{ localStorage.getItem('messageNotificationCount')}</b></span>}
                    {parseInt(localStorage.getItem('messageNotificationCount'))==0 &&<span><b>{ localStorage.getItem('messageNotificationCount')}</b></span>}
                </a>
                <Link onClick={togglePopup2} to='/Dashboard'><Wallet1 size="29" variant="Bold" color="#000000"/> </Link> 
                <div className="messageBox" id="messageBox1">
                    {parseInt(localStorage.getItem('messageNotificationCount'))>0 &&<p className="aNonDecor">
                    {t('You have 1 new messages from')}
                    <a href="#" className="aDecor finalAdecor">{localStorage.getItem('messageNotificationName1')}</a> 
                    </p>}
                    {parseInt(localStorage.getItem('messageNotificationCount'))>1 &&<p className="aNonDecor">
                    {t('You have 1 new messages from')}
                    <a href="" className="aDecor finalAdecor">{localStorage.getItem('messageNotificationName2')}</a> 
                    </p>}
                    {parseInt(localStorage.getItem('messageNotificationCount'))>2 &&<p className="aNonDecor">
                    {t('You have 1 new messages from')}
                    <a href="" className="aDecor finalAdecor">{localStorage.getItem('messageNotificationName3')}</a> 
                    </p>}
                    <br />
                    <p className="aNonDecor">
                    <a href="#" onClick={(data)=>{
                            document.getElementById('MessageText1Btn').style.backgroundColor='white';
                            document.getElementById('MessageText1BtnP').style.color='black';
                            try{
                                document.getElementById('homeBtn').style.backgroundColor='transparent';
                                document.getElementById('homeBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('PeopleBtn').style.backgroundColor='transparent';
                                document.getElementById('PeopleBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('Profile2UserBtn').style.backgroundColor='transparent';
                                document.getElementById('Profile2UserBtnP').style.color='white';
                            }
                            catch{}
                            
                            try{
                                document.getElementById('PercentageSquareBtn').style.backgroundColor='transparent';
                                document.getElementById('PercentageSquareBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('PercentageSquareUserBtn').style.backgroundColor='transparent';
                                document.getElementById('PercentageSquareUserBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('GraphBtn').style.backgroundColor='transparent';
                                document.getElementById('GraphBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('Notepad2Btn').style.backgroundColor='transparent';
                                document.getElementById('Notepad2BtnP').style.color='white';
                            }
                            catch{}
                            
                            try{
                                document.getElementById('BriefcaseBtnLaw').style.backgroundColor='transparent';
                                document.getElementById('BriefcaseBtnPLaw').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('BriefcaseBtn').style.backgroundColor='transparent';
                                document.getElementById('BriefcaseBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('ProfileAddBtn').style.backgroundColor='transparent';
                                document.getElementById('ProfileAddBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('CommandBtn').style.backgroundColor='transparent';
                                document.getElementById('CommandBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('ProfileBtn').style.backgroundColor='transparent';
                                document.getElementById('ProfileBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('ProfileTickBtn').style.backgroundColor='transparent';
                                document.getElementById('ProfileTickBtnP').style.color='white';
                            }
                            catch{}
                            try{
                                document.getElementById('CommandSquareBtn').style.backgroundColor='transparent';
                                document.getElementById('CommandSquareBtnP').style.color='white';
                            }
                            catch{}
                            localStorage.setItem('turnOffMeLawCusMsg',true);
                            childToParentMessage(data);
                            localStorage.setItem('messageNotificationCount',0);
                            handleClickNotification2();}} className="aDecor">{t('See more')}</a>
                    </p>
                </div>
            </div>
            
            <div className="avatarStyle col-md-4 col-6" >
                <div className="img_avatarStyle" onClick={handleClickProfile}>
                    
                    {localStorage.getItem('userAvatar')=='UserAvatar.png' &&<img src={avatarPicture} alt="Avatar" />}
                {localStorage.getItem('userType')==2 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/AgentsAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==1 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/LawyersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==3 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/CustomersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==4 && localStorage.getItem('userAvatar')!='UserAvatar.png' && localStorage.getItem('userAvatar')!='null' && localStorage.getItem('userAvatar')!='undefined' &&<img src={'https://telesiness.ir/MarketersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                </div>
               
                {/* <div className="arrow_avatarStyle">
                    <div onClick={handleClickProfile} href="">
                    <ArrowDown2 size="18" color="#000000"/>
                    </div>
                </div> */}
                <div className="msg2Box">
                    <div className="messageBox2" id="messageBox2" onClick={handleClickProfile}>
                        {/* {!userActive && 
                        <button 
                        disabled 
                        href="#" 
                        className="DisabledaNonDecor">
                        {t('View Profile')} 
                        </button>} */}
                        {userActive && <button onClick={() => childToParent(data)} className="aNonDecor">
                        {t('View Profile')} 
                        </button>}
                        <a href="#" className="aNonDecor">
                        {t('Terms')}
                        </a>
                        <a href="#" className="aNonDecor">
                        {t('Settings')}
                        </a>
                        <a href="#" className="aNonDecor" onClick={handleSingOut}>
                        {t('Sign Out')}
                        </a>
                    </div>
                </div>
            </div>
            
        </div>
        {<Popup
  content={<>
  <div className="PboxMainRow">
  <ArrowLeft onClick={togglePopup11} className="close_navbar" size="20" color="#313131"/>
        <div className="PboxFirstRow">
            {/* <ShieldSecurity size="48" color="#313131" variant="Bold"/> */}
        </div>
        {/* <div className="PboxSecondRow">
        <h4>{t('Are you sure?')}</h4>
        </div> */}
        <div onClick={togglePopup11}>
        <LeftNavBar 
           childToParentPrices={childToParentPrices} 
           childToParentAgents={childToParentAgents} 
           childToParentAgentAgent = {childToParentAgentAgent}
           childToParentLawyers = {childToParentLawyers}
           childToParentLawyer={childToParentLawyer} 
           childToParentCus={childToParentCus} 
           childToParentCusA2={childToParentCusA2}
           childToParentCus1={childToParentCus1} 
           childToParentLawyerHome = {childToParentLawyerHome}
           childToParentAgentHome = {childToParentAgentHome}
           childToParentCustomerHome = {childToParentCustomerHome}
           childToParentMarketerHome = {childToParentMarketerHome}
           childToParentCusMark = {childToParentCusMark}
           childToParentMessage = {childToParentMessage}
           childToParentRefCustomers ={childToParentRefCustomers}
           childToParentDCCustomers={childToParentDCCustomers}
           childToParentMarketerDist  ={childToParentMarketerDist }
           childToParentCusADis={childToParentCusADis}
           childToParentCusA2A={childToParentCusA2A}
           childToParentAgentDis = {childToParentAgentDis}
           childToParentAgentPrice = {childToParentAgentPrice}
           childToParentMarketerPrice = {childToParentMarketerPrice}
           userActive={userActive}

           
            />
           
        </div>
        <div className="inputAndlabel col-lg-6 col-6">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='5'>{t('Spanish')}</option > 
                  <option  value='6'>{t('French')}</option > 
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicants Skin Color')}</label> */}
            </div>
        {/* <div className="PboxThirdRow">
            <p>{t('Menu Item Will Be Active After Reviewing Your Profile By Our Experts')}</p>
        </div>
        <div className="PboxForthRowSendMail col-12">
            
            <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
            <button onClick={togglePopup} className="col-3 btn btn-dark">{t('I Got it')}</button>
        </div> */}
    
    </div>
  </>}
  handleClose={togglePopup11}
/>}


{isOpen2 && <Popup2
      content={<>
            {/* <div className="teleLogocom col-12">
                <img src={teleLogo} alt="" />
            </div>
            <div className="box_comsoon">          
                <div className='maintxt_comsoon col-12'>
                    <img src={mainPic} alt="" />
                </div>
            </div>
            {localStorage.getItem('userType')=='1'&&<div className="txt_comsoon"> <p>{t('Your Cash is:')} <b> {t('1500$')}</b></p> </div>}
            {localStorage.getItem('userType')=='2'&&<div className="txt_comsoon"> <p>{t('Your Cash is:')} <b> {t('1000$')}</b></p> </div>}
            {localStorage.getItem('userType')=='4'&&<div className="txt_comsoon"> <p>{t('Your Cash is:')} <b> {t('500$')}</b></p> </div>}
            <div className="txt_comsoon"> <p>{t('This Page is Under Construction, It will be availabe as soon as posible.')}</p> </div>
            <div className="setupNew btn_comsoon">
                <Link className='btn btn-dark' to='/Dashboard' onClick={togglePopup2}>{t('Return to Homepage')}</Link>
            </div> */}
            {/* <div className="Social_comsoon">
                <Facebook className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
                <Whatsapp className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
                <Youtube className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
                <Instagram className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
                <CallCalling className='SocialIcon404' size="20" color="#000000" variant="Bold"/>
            </div> */}
            {/* <div className="leftCircle404-01"></div>
            <div className="leftCircle404-02"></div>
            <div className="leftCircle404-03"></div> */}
              <>
    
      
          <div className="mainRegisterheader col-xxl-10 col-12">
            <div className="subRegisterrightheader col-md-8 col-12">
              <label className="form-check-forgot font-face-gm">
                {/* <Link className="font-face-gm backtohome txt_backhome" to='/'> {t('< Back to Home page')}</Link> */}
              </label>
              <h4 className="card-title font-face-gm">{t('Coming Soon')}</h4>
            </div>
            

          </div>     
          <div className="progressRowM6 serv_page col-12">
            <div className="myshapes d-none d-sm-flex">
              <div className="mycircle">
                <h6>{t('Soon!')}</h6>
              </div>
              <div className="myProCirlce5"></div>
            </div>
            <TickSquare size="64" color="#ffffff" variant="Bold" className="d-block d-sm-none"/>
            <div className="mytext6">
              <h5 className="choosText d-flex d-sm-none">{t('Done!')}</h5>
              {/* <h5 className="step1 d-none d-sm-block">{t('You are almost there!')}</h5>
              <h6 className="choosText">{t('Check Your Email')}</h6> */}
            </div>
            <div className="LastStepRegText">
                
                
            </div>
            {localStorage.getItem('userType')=='1'&& <p  className="RegisterHeaderp txt_comsoonP d-none d-sm-block">{t('Your Cash is:')} <b> {t('1500$')}</b></p> }
            {localStorage.getItem('userType')=='2'&& <p  className="RegisterHeaderp d-none d-sm-block">{t('Your Cash is:')} <b> {t('1000$')}</b></p>}
            {localStorage.getItem('userType')=='4'&& <p  className="RegisterHeaderp d-none d-sm-block">{t('Your Cash is:')} <b> {t('500$')}</b></p> }
            <p className="RegisterHeaderp d-none d-sm-block">{t('Dear user, These Services will be Available Soon!')}</p>
                <p className="RegisterHeaderp d-none d-sm-block">{t('Our experts are working on it.')}</p>
                  <Link to="/Dashboard" onClick={togglePopup2} className="btn btn-dark login-btnStep6 col-4">{t('Go to main page')}</Link>
                  
              
          </div>
          <div className="progressStyle col-xxl-10 col-12">
          </div>
      
   
  </>
      </>}
      handleClose={togglePopup2}
    />}

{isOpen3 && <Popup3
      content={<>
  
        <div className='body_homecenter col-12'>
            <div className="head_helpcenter col-12">
                <h4 className="font-face-gm">{t('Help Center')}</h4>
            </div>
            <div className='total_services col-12'>
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('What service do you want?')}</h3>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('What service do you want?')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('What service do you want? What service do you want? What service do you want?')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('What service do you want?')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('What service do you want? What service do you want? What service do you want?')}</p>
                        </div>
                    </div>
                </div>
                <div className='head_help col-12'>
                    <h3 className="font-face-gm">{t('What service do you want?')}</h3>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('What service do you want?')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('What service do you want? What service do you want? What service do you want?')}</p>
                        </div>
                    </div>
                </div>
                <div className='box_servlog col-6'>
                    <div className='servlog'>
                        <div className='title_servlog'>
                            <h5 className="font-face-gm">{t('What service do you want?')}</h5>
                        </div>
                        <div className='txt_servlog'>
                            <p className="font-face-gm">{t('What service do you want? What service do you want? What service do you want?')}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Link to="/Dashboard" onClick={togglePopup3} className="btn btn-dark login-btnStep6">{t('Go to main page')}</Link> */}
        </div>
  </>
     }
      handleClose={togglePopup3}
    />}
    </div>
    
);
}
 
export default TopMenu;