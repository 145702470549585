import { useState , useEffect } from 'react';
import cus1Pic from './images/cus1.png';
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,DocumentText,Profile,Profile2User,Clipboard,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"
import ProgressBar from 'react-bootstrap/ProgressBar'

const Cus1 = ({childToParentCus2,
    childToParentCus1e3,
    childToParentCouples ,
    childToParentFarzandan,
    childToParentOtherCus,
    childToParentCus1e1e1}) => {
    const [count,setCount] = useState(false);
    const [countPeople,setCountPeople] = useState(false);
    const [countNotepad,setCountNotePad] = useState(false);
    const [countProfile2User,setCountProfile2User] = useState(false);
    const [countPercentageSquare,setCountPercentageSquare] = useState(false);
    const [countMessageText1,setCountMessageText1] = useState(false);
    const [countGraph,setCountGraph] = useState(false);
    const [countGlobal,setCountGlobal] = useState(false);
    //*********************************** User Type Came From API */
    const [activeuser,setActiveuser] = useState();
    const {t, i18n} = useTranslation(['Login']);  
    //*********************************** */
const dataCus2 = true;
const DocumentTextnow = 60;
const Profilenow = 80;
const weddingnow = 10;
const Profile2Usernow = 35;
const childnow = 68;
const Clipboardnow = 87;

const handle1e3Applicatnt = () =>{
    childToParentCus1e3(dataCus2);
    var firstTimeCustomer = true;
    firstTimeCustomer = localStorage.getItem('firstTimeCustomer');
    localStorage.setItem('whoIs','ap');
    localStorage.setItem('BasicInfo',11);
    localStorage.setItem('Passport',5);
    localStorage.setItem('Educational',0);
    localStorage.setItem('Job',12);
    localStorage.setItem('Financial',2);
    localStorage.setItem('Foregin',0);
    localStorage.setItem('Military',12);
    localStorage.setItem('Marriage',6);
    localStorage.setItem('Children',3);
    localStorage.setItem('Family',5);
    localStorage.setItem('Visa',12);
}



const handle1e3Sopus = () =>{
    childToParentCus1e3(dataCus2);
    var firstTimeCustomer = true;
    firstTimeCustomer = localStorage.getItem('firstTimeCustomer');
    localStorage.setItem('whoIs','sp');
    localStorage.setItem('BasicInfo',49);
    localStorage.setItem('Passport',101);
    localStorage.setItem('Educational',56);
    localStorage.setItem('Job',51);
    localStorage.setItem('Financial',2);
    localStorage.setItem('Foregin',0);
    localStorage.setItem('Military',12);
    localStorage.setItem('Marriage',6);
    localStorage.setItem('Children',3);
    localStorage.setItem('Family',5);
    localStorage.setItem('Visa',12);
}
// var firstTimeCustomer = true;
// firstTimeCustomer = localStorage.getItem('firstTimeCustomer');
// localStorage.setItem('BasicInfo',11);
// localStorage.setItem('Passport',5);
// localStorage.setItem('Educational',0);
// localStorage.setItem('Job',12);
// localStorage.setItem('Financial',2);
// localStorage.setItem('Foregin',0);
// localStorage.setItem('Military',12);
// localStorage.setItem('Marriage',6);
// localStorage.setItem('Children',3);
// localStorage.setItem('Family',5);
// localStorage.setItem('Visa',12);
console.log(localStorage.getItem('verifyCustomerByDistributor'));
return ( 
         
    <div className="mainRowPro col-12">
      <form  className="">
      <div className="personalInfo col-12">
      {localStorage.getItem('userType')==3 &&<div className="Step1e1"><p className="Cus2Step1BlackP">{t('Submit a request')}</p><a className="back_pagelink" onClick={(dataCus1e1e1) => {childToParentCus1e1e1(dataCus1e1e1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a> </div>}
      {localStorage.getItem('userType')==1 || localStorage.getItem('userType')==2 &&<div className="Step1e1"><p className="Cus2Step1BlackP">{t('Applicant Informations')}</p><a onClick={(dataCus1e1e1) => {childToParentCus1e1e1(dataCus1e1e1);}}>{t('')}</a> </div>}
        <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy')}</p></div>
        <div ><p className="Cus2Step1SmallPBrown">{t('In the first step to complete your file, carefully complete the basic information, then after confirming the information by our experts, proceed to complete your file.')}</p></div>
        <div className="Cus2Inputs2 col-12">
            <div className="reactangleCus col-lg-4 col-6" onClick={(dataCus1e3) => {childToParentCus2(dataCus1e3);}}>
                <div className='box_reactangleCus'>
                    <div className='CusReqCircle'>
                    <DocumentText  variant="Bold" size="40" color="#FFFFFF"/>
                    </div>
                <p>{t('Basic Information')}</p> 
                <ProgressBar className='col-12 cusProgress' now={DocumentTextnow} label={`${DocumentTextnow}% `+ t('Completed')} />
                </div>
            </div>

            {((localStorage.getItem('verifyCustomerByDistributor') !='1')|| localStorage.getItem('userType')!=3 )  && <div className="reactangleCus col-lg-4 col-6" >
                <div className='box_reactangleCus'>
                    <div className='CusReqCircleG'>
                    <Profile  variant="Bold" size="40" color="#FFFFFF"/>
                    </div>
                    <p>{t('Applicant Information')}</p> 
                    <ProgressBar className='col-12 cusProgress' now={Profilenow} label={`${Profilenow}% `+ t('Completed')}/>
               </div>
            </div>}
            {localStorage.getItem('verifyCustomerByDistributor')=='1' && 
            localStorage.getItem('userType')==3 && <div onClick={handle1e3Applicatnt} className="reactangleCus col-lg-4 col-6">
                <div className='box_reactangleCus'>
                    <div className='CusReqCircle'>
                    <Profile  variant="Bold" size="40" color="#FFFFFF"/>
                    </div>
                    <p>{t('Applicant Information')}</p> 
                    <ProgressBar className='col-12 cusProgress' now={Profilenow} label={`${Profilenow}% `+ t('Completed')} />
               </div>
            </div>}

            {((localStorage.getItem('verifyCustomerByDistributor')!='1')|| localStorage.getItem('userType')!=3 )  && <div className="reactangleCus col-lg-4 col-6">
                <div className='box_reactangleCus'>
                    <div className='CusReqCircleG'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 38.137 25.136">
                            <g id="noun-wedding-rings-1208836" transform="translate(-103.6 -117.6)">
                                <path id="Path_74" data-name="Path 74" d="M116.249,117.6a12.568,12.568,0,1,0,6.406,23.4,12.741,12.741,0,0,0,6.447,1.733,12.568,12.568,0,1,0-6.419-23.389,12.7,12.7,0,0,0-6.433-1.747Zm0,1.91a10.683,10.683,0,0,1,4.686,1.07,12.736,12.736,0,0,0-1.395,1.368,9.036,9.036,0,0,0-3.291-.623,8.857,8.857,0,1,0,8.9,8.843,8.708,8.708,0,0,0-1.273-4.577h0l-.014-.027a6.986,6.986,0,0,1,1.381-1.192,10.473,10.473,0,0,1,1.733,5.8,10.726,10.726,0,1,1-10.726-10.659Zm12.852,0a10.665,10.665,0,1,1,0,21.331,10.792,10.792,0,0,1-4.7-1.07,12.83,12.83,0,0,0,1.395-1.382,8.748,8.748,0,0,0,3.3.65,8.857,8.857,0,1,0-8.925-8.87,8.721,8.721,0,0,0,1.3,4.6,6.957,6.957,0,0,1-1.395,1.205,10.554,10.554,0,0,1-1.72-5.81A10.679,10.679,0,0,1,129.1,119.51Z" fill="#fff" fillRule="evenodd"/>
                            </g>
                        </svg>
                    </div>
                <p>{t('Spouse Information')}</p> 
                <ProgressBar className='col-12 cusProgress' now={weddingnow} label={`${weddingnow}% `+ t('Completed')} />
               </div>
            </div>}
            {localStorage.getItem('verifyCustomerByDistributor')=='1' && 
            localStorage.getItem('userType')==3 && <div className="reactangleCus col-lg-4 col-6" onClick={handle1e3Sopus}>
                <div className='box_reactangleCus'>
                    <div className='CusReqCircle'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 38.137 25.136">
                            <g id="noun-wedding-rings-1208836" transform="translate(-103.6 -117.6)">
                                <path id="Path_74" data-name="Path 74" d="M116.249,117.6a12.568,12.568,0,1,0,6.406,23.4,12.741,12.741,0,0,0,6.447,1.733,12.568,12.568,0,1,0-6.419-23.389,12.7,12.7,0,0,0-6.433-1.747Zm0,1.91a10.683,10.683,0,0,1,4.686,1.07,12.736,12.736,0,0,0-1.395,1.368,9.036,9.036,0,0,0-3.291-.623,8.857,8.857,0,1,0,8.9,8.843,8.708,8.708,0,0,0-1.273-4.577h0l-.014-.027a6.986,6.986,0,0,1,1.381-1.192,10.473,10.473,0,0,1,1.733,5.8,10.726,10.726,0,1,1-10.726-10.659Zm12.852,0a10.665,10.665,0,1,1,0,21.331,10.792,10.792,0,0,1-4.7-1.07,12.83,12.83,0,0,0,1.395-1.382,8.748,8.748,0,0,0,3.3.65,8.857,8.857,0,1,0-8.925-8.87,8.721,8.721,0,0,0,1.3,4.6,6.957,6.957,0,0,1-1.395,1.205,10.554,10.554,0,0,1-1.72-5.81A10.679,10.679,0,0,1,129.1,119.51Z" fill="#fff" fillRule="evenodd"/>
                            </g>
                        </svg>
                    </div>
                <p>{t('Spouse Information')}</p> 
                <ProgressBar className='col-12 cusProgress' now={weddingnow} label={`${weddingnow}% `+ t('Completed')} />
               </div>
            </div>}
            
            {((localStorage.getItem('verifyCustomerByDistributor')!='1')|| localStorage.getItem('userType')!=3 )  && <div className="reactangleCus col-lg-4 col-6">
                <div className='box_reactangleCus'>
                    <div className='CusReqCircleG'>
                    <Profile2User  variant="Bold" size="40" color="#FFFFFF"/>
                    </div>
                    <p>{t('Couples Information')}</p> 
                    <ProgressBar className='col-12 cusProgress' now={Profile2Usernow} label={`${Profile2Usernow}% `+ t('Completed')} />
               </div>
            </div>}
            {localStorage.getItem('verifyCustomerByDistributor')=='1' && 
            localStorage.getItem('userType')==3 && <div className="reactangleCus col-lg-4 col-6" onClick={(dataCouples) => {childToParentCouples(dataCouples);}}>
                <div className='box_reactangleCus'>
                    <div className='CusReqCircle'>
                    <Profile2User  variant="Bold" size="40" color="#FFFFFF"/>
                    </div>
                    <p>{t('Couples Information')}</p> 
                    <ProgressBar className='col-12 cusProgress' now={Profile2Usernow} label={`${Profile2Usernow}% `+ t('Completed')} />
               </div>
            </div>}

            {((((localStorage.getItem('verifyCustomerByDistributor')!='1'))) || localStorage.getItem('userType')!=3 ) && <div className="reactangleCus col-lg-4 col-6">
                <div className='box_reactangleCus'>
                    <div className='CusReqCircleG'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50.269 27.556">
                            <g id="noun-children-31489" transform="translate(-70.001 -117.123)">
                                <path id="Path_70" data-name="Path 70" d="M95.746,225.1a9.769,9.769,0,0,0-18.534-1.079l-6.377-1.546a.674.674,0,0,0-.349,1.3l.161.039,12.928,3.134L94.92,229.7a.636.636,0,0,0,.53-.089.605.605,0,0,0,.493-.474,10.038,10.038,0,0,0-.2-4.038Z" transform="translate(0 -91.705)" fill="#fff"/>
                                <path id="Path_71" data-name="Path 71" d="M151.653,310.17a8.754,8.754,0,0,0,16.984,4.118l-10.7-2.594-6.287-1.524Zm4.481,6.345c-.373,0-.674-.585-.674-1.307s.3-1.307.674-1.307.673.585.673,1.307S156.507,316.515,156.135,316.515Zm6.614-2.615c.372,0,.673.585.673,1.308s-.3,1.307-.673,1.307-.674-.585-.674-1.307S162.377,313.9,162.749,313.9Zm-1.158,4.106c.34-.224.655.32.318.543a3.8,3.8,0,0,1-4.585-.049c-.305-.268.141-.711.445-.445a3.236,3.236,0,0,0,3.823-.049Z" transform="translate(-74.228 -175.783)" fill="#fff"/>
                                <path id="Path_72" data-name="Path 72" d="M383.166,117.465c-5.663-1.7-7.035,3.437-7.212,4.236a8.965,8.965,0,0,0-12.88,6.408c3.329-.138,10.9-.83,13.275-4.231l.3-.431.174.5c.017.046,1.43,3.973,3.957,4.571a8.954,8.954,0,0,0-3.478-5.966l.066.036c3.9-4.524,4.748,5.631,4.748,5.631l5.016-4.6s-.374-5.07-3.968-6.148Z" transform="translate(-266.865 0)" fill="#fff"/>
                                <path id="Path_73" data-name="Path 73" d="M376.383,202.74a11.888,11.888,0,0,1-1.3-2.323c-1.474,1.743-4.37,2.962-8.62,3.624a43.3,43.3,0,0,1-4.879.458,8.963,8.963,0,1,0,17.865,1.04c0-.208-.008-.414-.021-.618a5.008,5.008,0,0,1-3.04-2.182Zm-9.178,6.027c-.372,0-.673-.586-.673-1.308s.3-1.308.673-1.308.675.585.675,1.308S367.577,208.767,367.2,208.767Zm5.934,1.8a3.346,3.346,0,0,1-3.145.794,4.017,4.017,0,0,1-1.5-.794c-.306-.267.14-.709.445-.445a2.845,2.845,0,0,0,3.76,0C373,209.861,373.448,210.3,373.139,210.567Zm.682-1.8c-.373,0-.675-.585-.675-1.308s.3-1.308.675-1.308.673.585.673,1.308S374.193,208.767,373.82,208.767Z" transform="translate(-265.449 -75.845)" fill="#fff"/>
                            </g>
                        </svg>
                    </div>
                    <p>{t('Children Information')}</p> 
                    <ProgressBar className='col-12 cusProgress' now={childnow} label={`${childnow}% `+ t('Completed')} />
               </div>
            </div>}
            {localStorage.getItem('verifyCustomerByDistributor')=='1' && 
            localStorage.getItem('userType')==3 && <div className="reactangleCus col-lg-4 col-6" onClick={(dataFarzandan) => {childToParentFarzandan(dataFarzandan);}}>
                <div className='box_reactangleCus'>
                    <div className='CusReqCircle'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50.269 27.556">
                            <g id="noun-children-31489" transform="translate(-70.001 -117.123)">
                                <path id="Path_70" data-name="Path 70" d="M95.746,225.1a9.769,9.769,0,0,0-18.534-1.079l-6.377-1.546a.674.674,0,0,0-.349,1.3l.161.039,12.928,3.134L94.92,229.7a.636.636,0,0,0,.53-.089.605.605,0,0,0,.493-.474,10.038,10.038,0,0,0-.2-4.038Z" transform="translate(0 -91.705)" fill="#fff"/>
                                <path id="Path_71" data-name="Path 71" d="M151.653,310.17a8.754,8.754,0,0,0,16.984,4.118l-10.7-2.594-6.287-1.524Zm4.481,6.345c-.373,0-.674-.585-.674-1.307s.3-1.307.674-1.307.673.585.673,1.307S156.507,316.515,156.135,316.515Zm6.614-2.615c.372,0,.673.585.673,1.308s-.3,1.307-.673,1.307-.674-.585-.674-1.307S162.377,313.9,162.749,313.9Zm-1.158,4.106c.34-.224.655.32.318.543a3.8,3.8,0,0,1-4.585-.049c-.305-.268.141-.711.445-.445a3.236,3.236,0,0,0,3.823-.049Z" transform="translate(-74.228 -175.783)" fill="#fff"/>
                                <path id="Path_72" data-name="Path 72" d="M383.166,117.465c-5.663-1.7-7.035,3.437-7.212,4.236a8.965,8.965,0,0,0-12.88,6.408c3.329-.138,10.9-.83,13.275-4.231l.3-.431.174.5c.017.046,1.43,3.973,3.957,4.571a8.954,8.954,0,0,0-3.478-5.966l.066.036c3.9-4.524,4.748,5.631,4.748,5.631l5.016-4.6s-.374-5.07-3.968-6.148Z" transform="translate(-266.865 0)" fill="#fff"/>
                                <path id="Path_73" data-name="Path 73" d="M376.383,202.74a11.888,11.888,0,0,1-1.3-2.323c-1.474,1.743-4.37,2.962-8.62,3.624a43.3,43.3,0,0,1-4.879.458,8.963,8.963,0,1,0,17.865,1.04c0-.208-.008-.414-.021-.618a5.008,5.008,0,0,1-3.04-2.182Zm-9.178,6.027c-.372,0-.673-.586-.673-1.308s.3-1.308.673-1.308.675.585.675,1.308S367.577,208.767,367.2,208.767Zm5.934,1.8a3.346,3.346,0,0,1-3.145.794,4.017,4.017,0,0,1-1.5-.794c-.306-.267.14-.709.445-.445a2.845,2.845,0,0,0,3.76,0C373,209.861,373.448,210.3,373.139,210.567Zm.682-1.8c-.373,0-.675-.585-.675-1.308s.3-1.308.675-1.308.673.585.673,1.308S374.193,208.767,373.82,208.767Z" transform="translate(-265.449 -75.845)" fill="#fff"/>
                            </g>
                        </svg>
                    </div>
                    <p>{t('Children Information')}</p> 
                    <ProgressBar className='col-12 cusProgress' now={childnow} label={`${childnow}% `+ t('Completed')} />
               </div>
            </div>}

            {((localStorage.getItem('verifyCustomerByDistributor')!='1')|| localStorage.getItem('userType')!=3 )  &&<div className="reactangleCus col-lg-4 col-6">
                <div className='box_reactangleCus'>
                    <div className='CusReqCircleG'>
                    <Clipboard  variant="Bold" size="40" color="#FFFFFF"/>
                    </div>
                    <p>{t('Other')}</p> 
                    <ProgressBar className='col-12 cusProgress' now={Clipboardnow} label={`${Clipboardnow}% `+ t('Completed')} />
               </div>
            </div>}
            {localStorage.getItem('verifyCustomerByDistributor')=='1' && 
            localStorage.getItem('userType')==3 &&<div className="reactangleCus col-lg-4 col-6" onClick={(dataOtherCus) => {childToParentOtherCus(dataOtherCus);}}>
                <div className='box_reactangleCus'> 
                    <div className='CusReqCircle'>
                    <Clipboard  variant="Bold" size="40" color="#FFFFFF"/>
                    </div>
                    <p>{t('Other')}</p> 
                    <ProgressBar className='col-12 cusProgress' now={Clipboardnow} label={`${Clipboardnow}% `+ t('Completed')} />
               </div>
            </div>}
         


        </div>
       
        {/* <div className="cusS1SaveCol col-12">
          <div className="col-6">
             <button   className="btn col-12 btn-outline-light ">{t('Previous')}</button >
          </div>
          <div className="botCusDraftCol col-6">
            <button className="btn col-6 btn-outline-light ">
              <ArchiveTick size="32" color="#D3A281"/>{t('Save Draft')}
            </button >
            {<button   className="btn btn-dark">{t('Next Step')}</button>}
          </div>
         
        </div> */}
      </div>
      </form>
      
     
{/*         
      {isOpen && <Popup
    content={<>
    <div className="PboxMainRow">
          <div className="PboxFirstRow">
              <ShieldSecurity size="48" color="#313131" variant="Bold"/>
          </div>
          <div className="PboxSecondRow">
          <h4>{t('Are you sure?')}</h4>
          </div>
          <div className="PboxThirdRow">
              <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
          </div>
          <div className="PboxForthRowSendMail col-12">
              
              <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
              <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
          </div>
      
      </div>
    </>}
    handleClose={togglePopup}
  />}
*/}

   </div>
    );
}
 
export default Cus1;