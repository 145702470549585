import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LoginLocale from './Login.local';

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources: {
      ...LoginLocale // merge Home locale to i18n resource
      // add other translation to the array
      
    },
    Login: {
      'Sign In to Telesiness': 'Sign In to Telesiness',
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.':'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      'Email':'Email',
      'Remember me':'Remember me',
      'Forgot Password':'Forgot Password',
      'Login':'Login',
      'or':'or',
      'Sing Up Now':'Sing Up Now',
      'Services':'Services',
      'About':'About',
      'About us':'About us',
      'Collaborations':'Collaborations',
      'Terms':'Terms',
      'Help':'Help',
      'Contact Us':'Contact Us',
      'Reset Your Password': 'Reset Your Password',
      'Send Link':'Send Link',
      '< Back to Home page':'< Back to Home page',
      'Submit':'Submit',
      'Update':'Update',
      'You are all set':'You are all set',
      'Login now':'Login now',
      'New Password':'New Password',
      'Confirm New Password':'Confirm New Password',
      'Sign Up To Telesiness':'Sign Up To Telesiness',
      'Step 1':'Step 1',
      'Step 2':'Step 2',
      'Step 3':'Step 3',
      'Step 4':'Step 4',
      'Step 5':'Step 5',
      'Choose Your Account Type':'Choose Your Account Type',
      'Complete your Personal information':'Complete your Personal information',
      'Complete your Education information':'Complete your Education information',
      'Complete your Job information':'Complete your Job information',
      'Choose your Password':'Choose your Password',
      '1 of 5':'1 of 5',
      '2 of 5':'2 of 5',
      '3 of 5':'3 of 5',
      '4 of 5':'4 of 5',
      '5 of 5':'5 of 5',
      'Lawyer':'Lawyer',
      'Agent':'Agent',
      'Customer':'Customer',
      'First Name':'First Name',
      'Last Name':'Last Name',
      'Phone Number':'Phone Number',
      'Date of Birth:':'Date of Birth:',
      'about':'about',
      'Day':'Day',
      'Month':'Month',
      'Year':'Year',
      'Next Step':'Next Step',
      'Back':'Back',
      'January':'January',
      'February':'February',
      'March':'March',
      'April':'April',
      'May':'May',
      'June':'June',
      'July':'July',
      'August':'August',
      'September':'September',
      'October':'October',
      'November':'November',
      'December':'December',
      'Field of Study':'Field of Study',
      'Institute/University':'Institute/University',
      'Level/Degree of education':'Level/Degree of education',
      'Less than a high school diploma':'Less than a high school diploma',
      'High school diploma':'High school diploma',
      'Diploma':'Diploma',
      'Pre-university':'Pre-university',
      'Some college, no degree':'Some college, no degree',
      'Associate degree':'Associate degree',
      'Bachelor degree':'Bachelor degree',
      'Master Degree':'Master Degree',
      'Profissional degree':'Profissional degree',
      'Doctoral degree':'Doctoral degree',
      'Country':'Country',
      'City':'City',
      'Iran':'Iran',
      'England':'England',
      'Turkey':'Turkey',
      'UAE':'UAE',
      'USA':'USA',
      'Canada':'Canada',
      'Paris':'Paris',
      'Tehran':'Tehran',
      'California':'California',
      'Toronto':'Toronto',
      'Istanbul':'Istanbul',
      'Job specialty':'Job specialty',
      'Specialization in immigration':'Specialization in immigration',
      'Law society number/ICCRC Code':'Law society number/ICCRC Code',
      'Personal or company website':'Personal or company website',
      'Certificate':'Certificate',
      'Upload your Resume/CV':'Upload your Resume/CV',
      'Choose Your Password':'Choose Your Password',
      'Welcome':'Welcome',
      'Setin Set':'Setin Set',
      'Home':'Home',
      'Customers':'Customers',
      'Agents':'Agents',
      'My Prices':'My Prices',
      'Messages':'Messages',
      'Services':'Services',
      'Serach here':'Serach here',
      'Personal Information':'Personal Information',
      'Gender':'Gender',
      'Birthday':'Birthday',
      'Phone':'Phone',
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit':'Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
      'Address Information':'Address Information',
      'Save':'Save',
      'Cancel':'Cancel',
      'No':'No',
      'Address':'Address',
      'Zip Code':'Zip Code',
      'Educational Information':'Educational Information',
      'Level/Degree':'Level/Degree',
      'Job Information':'Job Information',
      'Upload Resume/CV':'Upload Resume/CV',
      'Website Address':'Website Address',
      'Profile Information':'Profile Information',
      'User Name':'User Name',
      'Change Password':'Change Password',
      'You Changed these fields before ... ':'You Changed these fields before ... ',
      'Serach here':'Serach here',
      'Help Center':'Help Center',
      'ID':'ID',
      'Phone Number':'Phone Number',
      'Status':'Status',
      'Created':'Created',
      '+ Add New Customer':'+ Add New Customer',
      'View Profile':'View Profile',
      'Comming Soon':'Comming Soon',
      'FAQs':'FAQs',
      'Settings':'Settings',
      'Sign Out':'Sign Out',
      'Deactive':'Deactive',
      'Active':'Active',
      'Active Users':'Active Users',
      'Deactive Users':'Deactive Users',
      'Why You wanna De-active this case?':'Why You wanna De-active this case?',
      'Please select the reason for deactivating this case.':'Please select the reason for deactivating this case.',
      'Visa':'Visa',
      'Rejected':'Rejected',
      'Declined':'Declined',
      'Lawyer List':'Lawyer List',
      'Customers Name':'Customers Name',
      'Clients Name':'Clients Name',
      'Are you sure?':'Are you sure?',
      'Certificate':'Certificate',
      'Dear User Pay Attention!':'Dear User Pay Attention!',
      'You Can edit your information JUST ONE TIME!!!':'You Can edit your information JUST ONE TIME!!!',
      'We will send a link to ':'We will send a link to ',
      ' for reset your password':' for reset your password',
      'Send Link!':'Send Link!',
      'User Name':'User Name',
      'Clients':'Clients',
      '+ Add New Agent':'+ Add New Agent',
      'Prices Information':'Prices Information',
      'Service':'Service',
      'Offer':'Offer',
      'Public Offer':'Public Offer',
      'Price':'Price',
      'Type a price here...':'Type a price here...',
      'Fee...':'Fee...',
      '+ Add Service':'+ Add Service',
      'Type about your service...':'Type about your service...',
      '$':'$',
      'Password':'Password',
      'type your password here':'type your password here',
      'type your email':'type your email',
      'You are almost there!':'You are almost there!',
      'Check Your Email':'Check Your Email',
      'Done!':'Done!',
      '2 of 3':'2 of 3',
      'Passport No / National Number / ID Num':'Passport No / National Number / ID Num',
      '3 of 3':'3 of 3',
      'Enter your username and password below to log in And have access to a world of immigration facilities':'Enter your username and password below to log in And have access to a world of immigration facilities',
      'Enter your email address below and we will send you a link to reset your password.':'Enter your email address below and we will send you a link to reset your password.',
      'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.':'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.',
      'Your password has been successfully changed. You can now log in with your new password.':'Your password has been successfully changed. You can now log in with your new password.',
      'Access your specific features by selecting your user type.':'Access your specific features by selecting your user type.',
      'Please help us get to know you better by completing your personal information.':'Please help us get to know you better by completing your personal information.',
      'Please help us get to know you better by completing your educational information.':'Please help us get to know you better by completing your educational information.',
      'Please help us get to know you better by completing your job information.':'Please help us get to know you better by completing your job information.',
      'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.':'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.',
      'Your registration was successful. You already have access to a variety of immigration opportunities.':'Your registration was successful. You already have access to a variety of immigration opportunities.',
      'Go to main page':'Go to main page',
      'Dear user, your information has been successfully registered in our database':'Dear user, your information has been successfully registered in our database',
      'Our experts will contact you as soon as possible after review.':'Our experts will contact you as soon as possible after review.',
      'Certificate':'Certificate',
      'License':'License',
      'Distributor':'Distributor',
      'Your Code is Valid.':'Your Code is Valid.',
      'Your Code is UnValid,  It is not registered in our database.':'Your Code is UnValid,  It is not registered in our database.',
      'Full name is: ':'Full name is: ',
      'Position is: ':'Position is: ',
      'I got it.':'I got it.',
      'Search by Telesiness Code':'Search by Telesiness Code',
      'Find':'Find',
      'Inquiry':'Inquiry',
      'Lawyer or Distributor':'Lawyer or Distributor',
      'I Got it':'I Got it',
      'Menu Item Will Be Active After Reviewing Your Profile By Our Experts':'Menu Item Will Be Active After Reviewing Your Profile By Our Experts',
      'My Requests':'My Requests',
      'Are You Sure?':'Are You Sure?',
      'You choose the lawyer by Telesiness':'You choose the lawyer by Telesiness',
      'You choose the lawyer by ':'You choose the lawyer by ',
      'Send Request':'Send Request',
      'Advocacy Code':'Advocacy Code',
      'Agent Fee':'Agent Fee',
      'Distributer Fee':'Distributer Fee',
      'Agent Percent':'Agent Percent',
      'Percent':'Percent',
      'See more':'See more',
      'Please enter the email of the lawyer you want to cooperate with.':'Please enter the email of the lawyer you want to cooperate with.',
      'Please enter the email of the Distributer you want to cooperate with.':'Please enter the email of the Distributer you want to cooperate with.',
      'Please enter the email of the Agent you want to cooperate with.':'Please enter the email of the Agent you want to cooperate with.',
      'Please enter the email of the Customer you want to cooperate with.':'Please enter the email of the Customer you want to cooperate with.',
      'Please Select Your Co-Lawyers to Your Customer.':'Please Select Your Co-Lawyers to Your Customer.',
      'ADD BY Email Address':'ADD BY Email Address',
      'Lawyers Name':'Lawyers Name',
      'Adding a Lawyer to Customer':'Adding a Lawyer to Customer',
      'Enter the applicants Lawyers':'Enter the applicants Lawyers',
      'Enter the applicants Lawyers Num 1':'Enter the applicants Lawyers Num 1',
      'Enter the applicants Lawyers Num 2':'Enter the applicants Lawyers Num 2',
      'Enter the applicants Lawyers Num 3':'Enter the applicants Lawyers Num 3',
      'Enter the applicants Lawyers Num 4':'Enter the applicants Lawyers 4',
      'Enter the applicants Lawyers Num 5':'Enter the applicants Lawyers 5',
      'Enter the applicants Lawyers Num 6':'Enter the applicants Lawyers 6',
      '+ Add New Distributer':'+ Add New Distributer',
      '+ Lawyer':'+ Lawyer',
      'Add Lawyer':'Add Lawyer',
      'Lawyer Name':'Lawyer Name',
      'Lawyer 1 Name...':'Lawyer 1 Name...',
      'Lawyer 2 Name...':'Lawyer 2 Name...',
      'Lawyer 3 Name...':'Lawyer 3 Name...',
      'Lawyer 4 Name...':'Lawyer 4 Name...',
      'Lawyer 5 Name...':'Lawyer 5 Name...',
      'Lawyer 6 Name...':'Lawyer 6 Name...',
      'Download Form':'Download Form',
      'Customer Code':'Customer Code',
      'All Done!':'All Done!',
      'Basic Informations':'Basic Informations',
      'Applicant Personal Information':'Applicant Personal Information',
      'Applicant Language Information':'Applicant Language Information',
      'Email Address':'Email Address',
      'Type an Email address...':'Type an Email address...',
      '+ Add New Lawyer':'+ Add New Lawyer',
      'Related Agents':'Related Agents',
      'Completed Files':'Completed Files',
      'Canceled files':'Canceled files',
      'In progress files':'In progress files',
      'Latest Customers':'Latest Customers',
      'Latest Agents':'Latest Agents',
      'Adding a new Customer':'Adding a new Customer',
      'Adding a new Agent':'Adding a new Agent',
      'Agency Code':'Agency Code',
      'Latest Lawyers':'Latest Lawyers',
      'Adding a New Lawyer':'Adding a New Lawyer',
      'Registery Date':'Registery Date',
      'Lawyer Information':'Lawyer Information',
      'Agent Information':'Agent Information',
      'There is no information to display':'There is no information to display',
      'My Request':'My Request',
      'You dont have any request yet':'You dont have any request yet',
      '+ Setup new Request':'+ Setup new Request',
      'Submit a request':'Submit a request',
      'Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy':'Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy',
      'In the first step to complete your file, carefully complete the basic information, then after confirming the information by our experts, proceed to complete your file.':'In the first step to complete your file, carefully complete the basic information, then after confirming the information by our experts, proceed to complete your file.',
      'Basic Information':'Basic Information',
      'Applicant Information':'Applicant Information',
      'Spouse Information':'Spouse Information',
      'Couples Information':'Couples Information',
      'Children Information':'Children Information',
      'Children':'Children',
      'Other':'Other',
      'back to My Requests':'back to My Requests',
      'Male':'Male',
      'Female':'Female',
      'Complete Basic Information':'Complete Basic Information',
      'Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy':'Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy',
      'Personal Information':'Personal Information',
      'Enter Your First Name in Persian':'Enter Your First Name in Persian',
      'Enter Your Last Name in Persian':'Enter Your Last Name in Persian',
      'ID Number':'ID Number',
      'Enter the applicants ID Number':'Enter the applicants ID Number',
      'National Code':'National Code',
      'Enter the applicants national code':'Enter the applicants national code',
      'E-mail':'E-mail',
      'Enter the applicants Email address':'Enter the applicants Email address',
      'Call Number':'Call Number',
      'Enter the applicants Call number':'Enter the applicants Call number',
      'Enter the applicants Phone number':'Enter the applicants Phone number',
      'Select Your Country':'Select Your Country',
      'Enter the applicants Country':'Enter the applicants Country',
      'Enter the applicants address':'Enter the applicants address',
      'ZipCode':'ZipCode',
      'Enter the applicants ZipCode':'Enter the applicants ZipCode',
      'place of Birth':'place of Birth',
      'Enter the applicants place of birth':'Enter the applicants place of birth',
      'Enter the applicants date of birth':'Enter the applicants date of birth',
      'Your file has been successfully registered':'Your file has been successfully registered',
      'Please be patient until Your information is reviewed and approved by our experts, and then proceed to complete your information.':'Please be patient until Your information is reviewed and approved by our experts, and then proceed to complete your information.',
      'Tracking Code':'Tracking Code',
      'Go to request':'Go to request',
      'Visa Type':'Visa Type',
      'Situation':'Situation',
      'Destination':'Destination',
      'Code':'Code',
      'Creation Date':'Creation Date',
      'Applicant information':'Applicant information',
      'Back to Submitting a request':'Back to Submitting a request',
      'Needs to be completed':'Needs to be completed',
      'Completed':'Completed',
      'In progress':'In progress',
      'Passport Information':'Passport Information',
      'Financial Information':'Financial Information',
      'Foreign travel Information':'Foreign travel Information',
      'Military Information':'Military Information',
      'Marriage Information':'Marriage Information',
      'Family Information':'Family Information',
      'Requested Visa Information':'Requested Visa Information',
      'Identical Information':'Identical Information',
      'National Cart Image(Front)':'National Cart Image(Front)',
      'National Cart Image(Behind)':'National Cart Image(Behind)',
      'National Note Image(First Page)':'National Note Image(First Page)',
      'National Note Image(Second Page)':'National Note Image(Second Page)',
      'National Note Image(Last Page)':'National Note Image(Last Page)',
      'Complete Passport Information':'Complete Passport Information',
      'Pasport Information':'Pasport Information',
      'Passport Number':'Passport Number',
      'Enter the applicants Passport Number':'Enter the applicants Passport Number',
      'Name':'Name',
      'Enter the applicants Name in the Passport':'Enter the applicants Name in the Passport',
      'Family':'Family',
      'Enter the applicants Family in the Passport':'Enter the applicants Family in the Passport',
      'Enter the applicants Passport Creation Date':'Enter the applicants Passport Creation Date',
      'Expiration Date':'Expiration Date',
      'Enter the applicants Passport Expiration Date':'Enter the applicants Passport Expiration Date',
      'Passport Creation Place':'Passport Creation Place',
      'Enter the applicants Passport Creation Place':'Enter the applicants Passport Creation Place',
      'Country of Residence':'Country of Residence',
      'Country of residence stated in the passport':'Country of residence stated in the passport',
      'Enter the applicants Birth Day On Passport':'Enter the applicants Birth Day On Passport',
      'Passport Documentaions':'Passport Documentaions',
      'Passport Image':'Passport Image',
      'Personal Picture in Pass':'Personal Picture in Pass',
      'Sample Passport Signature':'Sample Passport Signature',
      'Place of Issuance of National Card':'Place of Issuance of National Card',
      'Enter the applicants Place of Issuance of National Card':'Enter the applicants Place of Issuance of National Card',
      'Birth Certificate Number':'Birth Certificate Number',
      'Enter the applicants Birth Certificate Number':'Enter the applicants Birth Certificate Number',
      'Fathers Name on the Birth Certificate':'Fathers Name on the Birth Certificate',
      'Enter the applicants Fathers Name on the Birth Certificate':'Enter the applicants Fathers Name on the Birth Certificate',
      'Mothers name on the Birth Certificate':'Mothers name on the Birth Certificate',
      'Enter the applicants Mothers name on the Birth Certificate':'Enter the applicants Mothers name on the Birth Certificate',
      'Alias Name or Surname or changed in the identity card':'Alias Name or Surname or changed in the identity card',
      'Applicants Alias Name,Surname or changed in the identity card':'Applicants Alias Name,Surname or changed in the identity card',
      'Military Card Number':'Military Card Number',
      'Enter the applicants Military Card Number':'Enter the applicants Military Card Number',
      'Date of Issuance of Military Card':'Date of Issuance of Military Card',
      'Enter the applicants Passport Creation Date':'Enter the applicants Passport Creation Date',
      'Type of Military Card':'Type of Military Card',
      'Enter the applicants Type of Military Card':'Enter the applicants Type of Military Card',
      'Place of Military Service':'Place of Military Service',
      'Enter the applicants Place of Military Service':'Enter the applicants Place of Military Service',
      'Height':'Height',
      'Enter the applicants Height':'Enter the applicants Height',
      'Skin Color':'Skin Color',
      'Enter the applicants Skin Color':'Enter the applicants Skin Color',
      'Eye Color':'Eye Color',
      'Enter the applicants Eye Color':'Enter the applicants Eye Color',
      'ZipCode One':'ZipCode One' ,
      'Enter the applicants ZipCode (Place One - Required)':'Enter the applicants ZipCode (Place One - Required)',
      'Address One':'Address One',
      'Enter the applicants address (Place One - Required)':'Enter the applicants address (Place One - Required)',
      'Address Two':'Address Two',
      'Enter the applicants address (Place Two)':'Enter the applicants address (Place Two)',
      'ZipCode Two':'ZipCode Two',
      'Enter the applicants ZipCode (Place Two)':'Enter the applicants ZipCode (Place Two)',
      'Workplace Call Number':'Workplace Call Number',
      'Enter the applicants Workplace Call Number':'Enter the applicants Workplace Call Number',
      'Type of Marriage':'Type of Marriage',
      'Enter the applicants Type of Marriage':'Enter the applicants Type of Marriage',
      'Evaluation Form Tracking Number':'Evaluation Form Tracking Number',
      'Enter the applicants Evaluation Form Tracking Number':'Enter the applicants Evaluation Form Tracking Number',
      'Call Number (Two)':'Call Number (Two)',
      'Enter the applicants Call number (Second Number Optional)':'Enter the applicants Call number (Second Number Optional)',
      'Phone Number (Two)':'Phone Number (Two)',
      'Enter the applicants Phone number (Second Number Optional)':'Enter the applicants Phone number (Second Number Optional)',
      'Workplace Call Number (Two)':'Workplace Call Number (Two)',
      'Enter the applicants Workplace Call Number (Optional)':'Enter the applicants Workplace Call Number (Optional)',
      'Yellow':'Yellow',
      'White':'White',
      'Red':'Red',
      'Brunette':'Brunette',
      'Tan':'Tan',
      'Black':'Black',
      'Green':'Green',
      'Gray':'Gray',
      'Blue':'Blue',
      'Brown':'Brown',
      'Amber':'Amber',
      'Honey-Colored':'Honey-Colored',
      'Black':'Black',
      'Married':'Married',
      'Single':'Single',
      'Identical Documents':'Identical Documents',
      'Military service card':'Military service card',
      'Lack of background':'Lack of background',
      'Certificate or any other ID Card':'Certificate or any other ID Card',
      'Evaluation Form':'Evaluation Form',
      'Other Documents':'Other Documents',
      'Step 2-1':'Step 2-1',
      'Applicant Foreign Language Information':'Applicant Foreign Language Information',
      'Language Certifications':'Language Certifications',
      'English Language Status':'English Language Status',
      'I Have':'I Have',
      'I do not have':'I do not have',
      'I am reading':'I am reading',
      'I will be able to get a degree in the next 6 months':'I will be able to get a degree in the next 6 months',
      'I will not be able to get a degree for the next 8 months':'I will not be able to get a degree for the next 8 months',
      'Enter the applicants English Language Status':'Enter the applicants English Language Status',
      'IELTS':'IELTS',
      'Date of IELTS degree':'Date of IELTS degree',
      'Enter the applicants Date of IELTS degree':'Enter the applicants Date of IELTS degree',
      'The total score of the degree':'The total score of the degree',
      'Enter the applicants total score of the degree':'Enter the applicants total score of the degree',
      'IELTS Reading Score':'IELTS Reading Score',
      'Enter the applicants IELTS Reading Score':'Enter the applicants IELTS Reading Score',
      'IELTS Writing Score':'IELTS Writing Score',
      'Enter the applicants IELTS Writing Score':'Enter the applicants IELTS Writing Score',
      'IELTS Listening Score':'IELTS Listening Score',
      'Enter the applicants IELTS Listening Score':'Enter the applicants IELTS Listening Score',
      'IELTS Speacking Score':'IELTS Speacking Score',
      'Enter the applicants IELTS Speacking Score':'Enter the applicants IELTS Speacking Score',
      'TOEFL':'TOEFL',
      'Enter the applicants Date of Other Language degree':'Enter the applicants Date of Other Language degree',
      'Date of TOEFL degree':'Date of TOEFL degree',
      "Enter the applicant's Field of Study":"Enter the applicant's Field of Study",
      'Enter the applicants Date of TOEFL degree':'Enter the applicants Date of TOEFL degree',
      'TOEFL Reading Score':'TOEFL Reading Score',
      'Enter the applicants TOEFL Reading Score':'Enter the applicants TOEFL Reading Score',
      'TOEFL Writing Score':'TOEFL Writing Score',
      'Enter the applicants TOEFL Writing Score':'Enter the applicants TOEFL Writing Score',
      'TOEFL Listening Score':'TOEFL Listening Score',
      'Enter the applicants TOEFL Listening Score':'Enter the applicants TOEFL Listening Score',
      'TOEFL Speacking Score':'TOEFL Speacking Score',
      'Enter the applicants TOEFL Speacking Score':'Enter the applicants TOEFL Speacking Score',
      'Other Language (1)':'Other Language (1)',
      'The total score of the Other Language degree(1)':'The total score of the Other Language degree(1)',
      'Enter the applicants total score of the Other Language degree(1)':'Enter the applicants total score of the Other Language degree(1)',
      'Other Language Reading Score(1)':'Other Language Reading Score(1)',
      'Enter the applicants Other Language Reading Score(1)':'Enter the applicants Other Language Reading Score(1)',
      'Other Language Writing Score(1)':'Other Language Writing Score(1)',
      'Enter the applicants Other Language Writing Score(1)':'Enter the applicants Other Language Writing Score(1)',
      'Other Language Listening Score(1)':'Other Language Listening Score(1)',
      'Enter the applicants Other Language Listening Score(1)':'Enter the applicants Other Language Listening Score(1)',
      'Other Language Speacking Score(1)':'Other Language Speacking Score(1)',
      'Enter the applicants Other Language Speacking Score(1)':'Enter the applicants Other Language Speacking Score(1)',
      'Other Language (2)':'Other Language (2)',
      'The total score of the Other Language degree(2)':'The total score of the Other Language degree(2)',
      'Enter the applicants total score of the Other Language degree(2)':'Enter the applicants total score of the Other Language degree(2)',
      'Other Language Reading Score(2)':'Other Language Reading Score(2)',
      'Enter the applicants Other Language Reading Score(2)':'Enter the applicants Other Language Reading Score(2)',
      'Other Language Writing Score(2)':'Other Language Writing Score(2)',
      'Enter the applicants Other Language Writing Score(2)':'Enter the applicants Other Language Writing Score(2)',
      'Other Language Listening Score(2)':'Other Language Listening Score(2)',
      'Enter the applicants Other Language Listening Score(2)':'Enter the applicants Other Language Listening Score(2)',
      'Other Language Speacking Score(2)':'Other Language Speacking Score(2)',
      'Enter the applicants Other Language Speacking Score(2)':'Enter the applicants Other Language Speacking Score(2)',
      'Other Language (3)':'Other Language (3)',
      'The total score of the Other Language degree(3)':'The total score of the Other Language degree(3)',
      'Enter the applicants total score of the Other Language degree(3)':'Enter the applicants total score of the Other Language degree(3)',
      'Other Language Reading Score(3)':'Other Language Reading Score(3)',
      'Enter the applicants Other Language Reading Score(3)':'Enter the applicants Other Language Reading Score(3)',
      'Other Language Writing Score(3)':'Other Language Writing Score(3)',
      'Enter the applicants Other Language Writing Score(3)':'Enter the applicants Other Language Writing Score(3)',
      'Other Language Listening Score(3)':'Other Language Listening Score(3)',
      'Enter the applicants Other Language Listening Score(3)':'Enter the applicants Other Language Listening Score(3)',
      'Other Language Speacking Score(3)':'Other Language Speacking Score(3)',
      'Enter the applicants Other Language Speacking Score(3)':'Enter the applicants Other Language Speacking Score(3)',
      'Date of Other Language degree (1)':'Date of Other Language degree (1)',
      'Date of Other Language degree (2)':'Date of Other Language degree (2)',
      'Date of Other Language degree (3)':'Date of Other Language degree (3)',
      'Foreign Language Documents':'Foreign Language Documents',
      'Other Language(1)':'Other Language(1)',
      'Other Language(2)':'Other Language(2)',
      'Other Language(3)':'Other Language(3)',
      'Language Certificates':'Language Certificates',
      'Back to Applicant information':'Back to Applicant information',
      'Step 2-2':'Step 2-2',
      'Applicant Educational Information':'Applicant Educational Information',
      'Last Degree and Field of Study':'Last Degree and Field of Study',
      'Last Educational Certificate':'Last Educational Certificate',
      'Enter the applicants Last Educational Certificate':'Enter the applicants Last Educational Certificate',
      '(Required)':'(Required)',
      'Enter the applicants Field of Study':'Enter the applicants Field of Study',
      'High School':'High School',
      'Date of Entering High School':'Date of Entering High School',
      'Enter the applicants Date of Entering High School':'Enter the applicants Date of Entering High School',
      'Graduation Date':'Graduation Date',
      'Enter the applicants Graduation Date':'Enter the applicants Graduation Date',
      'Field of Study in Diploma':'Field of Study in Diploma',
      'Enter the applicants Field of Study in Diploma':'Enter the applicants Field of Study in Diploma',
      'Associate Degree':'Associate Degree',
      'Date of Entering University':'Date of Entering University',
      'Enter the applicants Date of Entering University':'Enter the applicants Date of Entering University',
      'University Name':'University Name',
      'Enter the applicants University Name':'Enter the applicants University Name',
      'University Plcae':'University Plcae',
      'Enter the applicants University Plcae':'Enter the applicants University Plcae',
      'Bachelor Degree':'Bachelor Degree',
      'Masters Degree':'Masters Degree',
      'P.H.D Degree':'P.H.D Degree',
      'Post-Doctoral Degree':'Post-Doctoral Degree',
      'Non-university Certificate':'Non-university Certificate',
      'Non-university Certificate Name(1)':'Non-university Certificate Name(1)',
      'Enter the applicants Non-university Certificate Name(1)':'Enter the applicants Non-university Certificate Name(1)',
      'Non-university Certificate Name(2)':'Non-university Certificate Name(2)',
      'Enter the applicants Non-university Certificate Name(2)':'Enter the applicants Non-university Certificate Name(2)',
      'Non-university Certificate Name(3)':'Non-university Certificate Name(3)',
      'Enter the applicants Non-university Certificate Name(3)':'Enter the applicants Non-university Certificate Name(3)',
      'Non-university Certificate Name(4)':'Non-university Certificate Name(4)',
      'Enter the applicants Non-university Certificate Name(4)':'Enter the applicants Non-university Certificate Name(4)',
      'Non-university Certificate Name(5)':'Non-university Certificate Name(5)',
      'Enter the applicants Non-university Certificate Name(5)':'Enter the applicants Non-university Certificate Name(5)',
      'Non-university Certificate Name(6)':'Non-university Certificate Name(6)',
      'Enter the applicants Non-university Certificate Name(6)':'Enter the applicants Non-university Certificate Name(6)',
      'Educational Documents':'Educational Documents',
      'Pre-High School (1)':'Pre-High School (1)',
      'Pre-High School (2)':'Pre-High School (2)',
      'Pre-High School (3)':'Pre-High School (3)',
      'High School(transcripts) 1th':'High School(transcripts) 1th',
      'High School(transcripts) 2th':'High School(transcripts) 2th',
      'High School(transcripts) 3th':'High School(transcripts) 3th',
      'High School(transcripts) 4th':'High School(transcripts) 4th',
      'Diploma Certificate':'Diploma Certificate',
      'Associate Diploma Transcripts':'Associate Diploma Transcripts',
      'Associate Cerificate':'Associate Cerificate',
      'Bachlor Transcripts':'Bachlor Transcripts',
      'Bachlor Cerificate':'Bachlor Cerificate',
      'Masters Transcripts':'Masters Transcripts',
      'Masters Cerificate':'Masters Cerificate',
      'P.H.D Transcripts':'P.H.D Transcripts',
      'P.H.D Cerificate':'P.H.D Cerificate',
      'Post-Doctoral Transcripts':'Post-Doctoral Transcripts',
      'Post-Doctoral Cerificate':'Post-Doctoral Cerificate',
      'ISI Papers':'ISI Papers',
      'CV':'CV',
      'Motivation Letter':'Motivation Letter',
      'Non-University Degrees (1)':'Non-University Degrees (1)',
      'Non-University Degrees (2)':'Non-University Degrees (2)',
      'Non-University Degrees (3)':'Non-University Degrees (3)',
      'Non-University Degrees (4)':'Non-University Degrees (4)',
      'Non-University Degrees (5)':'Non-University Degrees (5)',
      'Non-University Degrees (6)':'Non-University Degrees (6)',
      'Scientific Documents of Awards or Festivals':'Scientific Documents of Awards or Festivals',
      'Other Documents':'Other Documents',
      'Applicant Job Information':'Applicant Job Information',
      'Work Experience':'Work Experience',
      'Work Experience by year':'Work Experience by year',
      'Enter the applicants Work experience by year':'Enter the applicants Work experience by year',
      'Job':'Job',
      'Date of First Job':'Date of First Job',
      'Enter the applicants Date of First Job':'Enter the applicants Date of First Job',
      'Date of 2nd Job':'Date of 2nd Job',
      'Enter the applicants Date of 2nd Job':'Enter the applicants Date of 2nd Job',
      'Date of 3rd Job':'Date of 3rd Job',
      'Enter the applicants Date of 3rd Job':'Enter the applicants Date of 3rd Job',
      'Date of 4th Job':'Date of 4th Job',
      'Enter the applicants Date of 4th Job':'Enter the applicants Date of 4th Job',
      'Date of 5th Job':'Date of 5th Job',
      'Enter the applicants Date of 5th Job':'Enter the applicants Date of 5th Job',
      'Date of 6th Job':'Date of 6th Job',
      'Enter the applicants Date of 6th Job':'Enter the applicants Date of 6th Job',
      'Post (1)':'Post (1)',
      'Enter the applicants Post in Job (1)':'Enter the applicants Post in Job (1)', 
      'Post (2)':'Post (2)',
      'Enter the applicants Post in Job (2)':'Enter the applicants Post in Job (2)', 
      'Post (3)':'Post (3)',
      'Enter the applicants Post in Job (3)':'Enter the applicants Post in Job (3)', 
      'Post (4)':'Post (4)',
      'Enter the applicants Post in Job (4)':'Enter the applicants Post in Job (4)', 
      'Post (5)':'Post (5)',
      'Enter the applicants Post in Job (5)':'Enter the applicants Post in Job (5)', 
      'Post (6)':'Post (6)',
      'Enter the applicants Post in Job (6)':'Enter the applicants Post in Job (6)', 
      'Workplace (1)':'Workplace (1)',
      'Enter the applicants Workplace (1)':'Enter the applicants Workplace (1)',
      'Workplace (2)':'Workplace (2)',
      'Enter the applicants Workplace (2)':'Enter the applicants Workplace (2)',
      'Workplace (3)':'Workplace (3)',
      'Enter the applicants Workplace (3)':'Enter the applicants Workplace (3)',
      'Workplace (4)':'Workplace (4)',
      'Enter the applicants Workplace (4)':'Enter the applicants Workplace (4)',
      'Workplace (5)':'Workplace (5)',
      'Enter the applicants Workplace (5)':'Enter the applicants Workplace (5)',
      'Workplace (6)':'Workplace (6)',
      'Enter the applicants Workplace (6)':'Enter the applicants Workplace (6)',
      'Permissions':'Permissions',
      'Date of Business License (1)':'Date of Business License (1)',
      'Enter the applicants Date of business license (1)':'Enter the applicants Date of business license (1)',
      'Date of Business License (2)':'Date of Business License (2)',
      'Enter the applicants Date of business license (2)':'Enter the applicants Date of business license (2)',
      'Date of Business License (3)':'Date of Business License (3)',
      'Enter the applicants Date of business license (3)':'Enter the applicants Date of business license (3)',
      'Date of Business License (4)':'Date of Business License (4)',
      'Enter the applicants Date of business license (4)':'Enter the applicants Date of business license (4)',
      'Date of Business License (5)':'Date of Business License (5)',
      'Enter the applicants Date of business license (5)':'Enter the applicants Date of business license (5)',
      'Date of Business License (6)':'Date of Business License (6)',
      'Enter the applicants Date of business license (6)':'Enter the applicants Date of business license (6)',
      'Type and Name of License (1)':'Type and Name of License (1)',
      'Enter the applicants Type and Name of License (1)':'Enter the applicants Type and Name of License (1)',
      'Type and Name of License (2)':'Type and Name of License (2)',
      'Enter the applicants Type and Name of License (2)':'Enter the applicants Type and Name of License (2)',
      'Type and Name of License (3)':'Type and Name of License (3)',
      'Enter the applicants Type and Name of License (3)':'Enter the applicants Type and Name of License (3)',
      'Type and Name of License (4)':'Type and Name of License (4)',
      'Enter the applicants Type and Name of License (4)':'Enter the applicants Type and Name of License (4)',
      'Type and Name of License (5)':'Type and Name of License (5)',
      'Enter the applicants Type and Name of License (5)':'Enter the applicants Type and Name of License (5)',
      'Type and Name of License (6)':'Type and Name of License (6)',
      'Enter the applicants Type and Name of License (6)':'Enter the applicants Type and Name of License (6)',
      'License Expiration Date (1)':'License Expiration Date (1)',
      'Enter the applicants License Expiration Date (1)':'Enter the applicants License Expiration Date (1)',
      'License Expiration Date (2)':'License Expiration Date (2)',
      'Enter the applicants License Expiration Date (2)':'Enter the applicants License Expiration Date (2)',
      'License Expiration Date (3)':'License Expiration Date (3)',
      'Enter the applicants License Expiration Date (3)':'Enter the applicants License Expiration Date (3)',
      'License Expiration Date (4)':'License Expiration Date (4)',
      'Enter the applicants License Expiration Date (4)':'Enter the applicants License Expiration Date (4)',
      'License Expiration Date (5)':'License Expiration Date (5)',
      'Enter the applicants License Expiration Date (5)':'Enter the applicants License Expiration Date (5)',
      'License Expiration Date (6)':'License Expiration Date (6)',
      'Enter the applicants License Expiration Date (6)':'Enter the applicants License Expiration Date (6)',
      'Job qualifications':'Job qualifications',
      'Certificate of Employment':'Certificate of Employment',
      'Insurance':'Insurance',
      'Business License':'Business License',
      'Other Permissions':'Other Permissions',
      'Business Cards':'Business Cards',
      'Company Documents':'Company Documents',
      'Factory Documents':'Factory Documents',
      'CV':'CV',
      'Tax Returns':'Tax Returns',
      'Payroll':'Payroll',
      'Employment Warrant':'Employment Warrant',
      'Mission Warrant':'Mission Warrant',
      'Responsibility Warrant':'Responsibility Warrant',
      'Other Documents':'Other Documents',
      'Applicant Financial Information':'Applicant Financial Information',
      'Turnover Number':'Turnover Number',
      'Turnover':'Turnover',
      'Enter the applicants Turnover':'Enter the applicants Turnover',
      'Start Date of Turnover':'Start Date of Turnover',
      'Enter the applicants Start Date of Turnover':'Enter the applicants Start Date of Turnover',
      'End Date of Turnover':'End Date of Turnover',
      'Enter the applicants End Date of Turnover':'Enter the applicants End Date of Turnover',
      'Financial Ability':'Financial Ability',
      'Enter the applicants Financial Ability':'Enter the applicants Financial Ability',
      'Date of Financial':'Date of Financial',
      'Enter the applicants Date of Financial':'Enter the applicants Date of Financial',
      'Financial Currency Unit':'Financial Currency Unit',
      'Enter the applicants Financial Currency Unit':'Enter the applicants Financial Currency Unit',
      'Total Assets Amount':'Total Assets Amount',
      'Total Assets Amount in Number':'Total Assets Amount in Number',
      'Enter the applicants Total Assets Amount in Number':'Enter the applicants Total Assets Amount in Number',
      'Calculated Currency':'Calculated Currency',
      'Enter the applicants Calculated Currency':'Enter the applicants Calculated Currency',
      'Financial Documents':'Financial Documents',
      'Turnover (1)':'Turnover (1)',
      'Turnover (2)':'Turnover (2)',
      'Financial Ability (1)':'Financial Ability (1)',
      'Financial Ability (2)':'Financial Ability (2)',
      'Property Document (1)':'Property Document (1)',
      'Property Document (2)':'Property Document (2)',
      'Property Document (3)':'Property Document (3)',
      'Factory Document':'Factory Document',
      'Shop Document':'Shop Document',
      'Certificate of Stock Exchange':'Certificate of Stock Exchange',
      'Lease':'Lease',
      'Financial Supporter Letter (1)':'Financial Supporter Letter (1)',
      'Financial Supporter Letter (2)':'Financial Supporter Letter (2)',
      'Applicant Spouse Information':'Applicant Spouse Information',
      'Applicant Spouse Job Information':'Applicant Spouse Job Information',
      'Applicants Spouse Financial Information':'Applicants Spouse Financial Information',
      'Applicants Spouse Educational Information':'Applicants Spouse Educational Information',
      'Applicant Spouse Foreign Language Information':'Applicant Spouse Foreign Language Information',
      'Marriage Certificate Number':'Marriage Certificate Number',
      'Enter the applicants Marriage Certificate Number':'Enter the applicants Marriage Certificate Number',
      'Date of Marriage':'Date of Marriage',
      'Enter the applicants Date of Marriage':'Enter the applicants Date of Marriage',
      'Date of Divorce or Death':'Date of Divorce or Death',
      'Enter the applicants Date of Divorce or Death':'Enter the applicants Date of Divorce or Death',
      'Couples Documents':'Couples Documents',
      'Marriage Certificate':'Marriage Certificate',
      'Exit Permission':'Exit Permission',
      'Divorce or Death Letter':'Divorce or Death Letter',
      'Applicant Travel Information':'Applicant Travel Information',
      'Foreign Trips':'Foreign Trips',
      'Number of Foreign Trips':'Number of Foreign Trips',
      'Enter the applicants Number of Foreign Trips':'Enter the applicants Number of Foreign Trips',
      'Step 6':'Step 6',
      'Applicant Family Information':'Applicant Family Information',
      'Applicants Spouse Family Information':'Applicants Spouse Family Information',
      'Prime Family Information':'Prime Family Information',
      'Father':'Father',
      'Father`s Name':'Father`s Name',
      'Enter the applicants Father Name':'Enter the applicants Father Name',
      'Father`s Family':'Father`s Family',
      'Enter the applicants Father Family':'Enter the applicants Father Family',
      'Father`s Job':'Father`s Job',
      'Enter the applicants Father Job':'Enter the applicants Father Job',
      'Father`s Birth Day':'Father`s Birth Day',
      'Enter the applicants Father Birth Day':'Enter the applicants Father Birth Day',
      'Father`s Residence Address':'Father`s Residence Address',
      'Enter the applicants Fathers Residence Address':'Enter the applicants Fathers Residence Address',
      'Father`s Postal Code':'Father`s Postal Code',
      'Enter the applicants Father Postal Code':'Enter the applicants Father Postal Code',
      'Father`s Phone Call':'Father`s Phone Call',
      'Enter the applicants Father Phone Call':'Enter the applicants Father Phone Call',
      'Father`s Education':'Father`s Education',
      'Enter the applicants Father`s Education':'Enter the applicants Father`s Education',
      'Father`s Financial Ability':'Father`s Financial Ability',
      'Enter the applicants Father`s Financial Ability':'Enter the applicants Father`s Financial Ability',
      'Father`s Date of Divorce':'Father`s Date of Divorce',
      'Enter the applicants Father`s Date of Divorce':'Enter the applicants Father`s Date of Divorce',
      'Father`s Date of Death':'Father`s Date of Death',
      'Enter the applicants Father`s Date of Death':'Enter the applicants Father`s Date of Death',
      'Father`s Foreign Trip (1)':'Father`s Foreign Trip (1)',
      'Father`s Foreign Trip (2)':'Father`s Foreign Trip (2)',
      'Father`s Foreign Trip (3)':'Father`s Foreign Trip (3)',
      'Father`s Foreign Trip (4)':'Father`s Foreign Trip (4)',
      'Father`s Foreign Trip (5)':'Father`s Foreign Trip (5)',
      'Father`s Foreign Trip (6)':'Father`s Foreign Trip (6)',
      'Enter the applicants Father`s Foreign Trip':'Enter the applicants Father`s Foreign Trip',

      'Mother':'Mother',
      'Mother`s Name':'Mother`s Name',
      'Enter the applicants Mother Name':'Enter the applicants Mother Name',
      'Mother`s Family':'Mother`s Family',
      'Enter the applicants Mother Family':'Enter the applicants Mother Family',
      'Mother`s Job':'Mother`s Job',
      'Enter the applicants Mother Job':'Enter the applicants Mother Job',
      'Mother`s Birth Day':'Mother`s Birth Day',
      'Enter the applicants Mother Birth Day':'Enter the applicants Mother Birth Day',
      'Mother`s Residence Address':'Mother`s Residence Address',
      'Enter the applicants Mothers Residence Address':'Enter the applicants Mothers Residence Address',
      'Mother`s Postal Code':'Mother`s Postal Code',
      'Enter the applicants Mother Postal Code':'Enter the applicants Mother Postal Code',
      'Mother`s Phone Call':'Mother`s Phone Call',
      'Enter the applicants Mother Phone Call':'Enter the applicants Mother Phone Call',
      'Mother`s Education':'Mother`s Education',
      'Enter the applicants Mother`s Education':'Enter the applicants Mother`s Education',
      'Mother`s Financial Ability':'Mother`s Financial Ability',
      'Enter the applicants Mother`s Financial Ability':'Enter the applicants Mother`s Financial Ability',
      'Mother`s Date of Divorce':'Mother`s Date of Divorce',
      'Enter the applicants Mother`s Date of Divorce':'Enter the applicants Mother`s Date of Divorce',
      'Mother`s Date of Death':'Mother`s Date of Death',
      'Enter the applicants Mother`s Date of Death':'Enter the applicants Mother`s Date of Death',
      'Mother`s Foreign Trip (1)':'Mother`s Foreign Trip (1)',
      'Mother`s Foreign Trip (2)':'Mother`s Foreign Trip (2)',
      'Mother`s Foreign Trip (3)':'Mother`s Foreign Trip (3)',
      'Mother`s Foreign Trip (4)':'Mother`s Foreign Trip (4)',
      'Mother`s Foreign Trip (5)':'Mother`s Foreign Trip (5)',
      'Mother`s Foreign Trip (6)':'Mother`s Foreign Trip (6)',
      'Enter the applicants Mother`s Foreign Trip':'Enter the applicants Mother`s Foreign Trip',

      'Brother (1)':'Brother (1)',
      '1th Brother`s Name':'1th Brother`s Name',
      'Enter the applicants 1th Brother Name':'Enter the applicants 1th Brother Name',
      '1th Brother`s Family':'1th Brother`s Family',
      'Enter the applicants 1th Brother Family':'Enter the applicants 1th Brother Family',
      '1th Brother`s Job':'1th Brother`s Job',
      'Enter the applicants 1th Brother Job':'Enter the applicants 1th Brother Job',
      '1th Brother`s Birth  Day':'1th Brother`s Birth  Day',
      'Enter the applicants  1th Brother Birth Day':'Enter the applicants  1th Brother Birth Day',
      '1th Brother`s Reside nce Address':'1th Brother`s Reside nce Address',
      'Enter the applicants  1th Brothers Residence Address':'Enter the applicants  1th Brothers Residence Address',
      '1th Brother`s Postal  Code':'1th Brother`s Postal  Code',
      'Enter the applicants  1th Brother Postal Code':'Enter the applicants  1th Brother Postal Code',
      '1th Brother`s Phone  Call':'1th Brother`s Phone  Call',
      'Enter the applicants  1th Brother Phone Call':'Enter the applicants  1th Brother Phone Call',
      '1th Brother`s Education':'1th Brother`s Education',
      'Enter the applicants 1th Brother`s Education':'Enter the applicants 1th Brother`s Education',
      '1th Brother`s Financial Ability':'1th Brother`s Financial Ability',
      'Enter the applicants 1th Brother`s Financial Ability':'Enter the applicants 1th Brother`s Financial Ability',
      '1th Brother`s Date of Divorce':'1th Brother`s Date of Divorce',
      'Enter the applicants 1th Brother`s Date of Divorce':'Enter the applicants 1th Brother`s Date of Divorce',
      '1th Brother`s Date of Death':'1th Brother`s Date of Death',
      'Enter the applicants 1th Brother`s Date of Death':'Enter the applicants 1th Brother`s Date of Death',
      '1th Brother`s Foreign Trip (1)':'1th Brother`s Foreign Trip (1)',
      '1th Brother`s Foreign Trip (2)':'1th Brother`s Foreign Trip (2)',
      '1th Brother`s Foreign Trip (3)':'1th Brother`s Foreign Trip (3)',
      '1th Brother`s Foreign Trip (4)':'1th Brother`s Foreign Trip (4)',
      '1th Brother`s Foreign Trip (5)':'1th Brother`s Foreign Trip (5)',
      '1th Brother`s Foreign Trip (6)':'1th Brother`s Foreign Trip (6)',
      'Enter the applicants 1th Brother`s Foreign Trip':'Enter the applicants 1th Brother`s Foreign Trip',

      'Brother (2)':'Brother (2)',
      '2nd Brother`s Name':'2nd Brother`s Name',
      'Enter the applicants 2nd Brother Name':'Enter the applicants 2nd Brother Name',
      '2nd Brother`s Family':'2nd Brother`s Family',
      'Enter the applicants 2nd Brother Family':'Enter the applicants 2nd Brother Family',
      '2nd Brother`s Job':'2nd Brother`s Job',
      'Enter the applicants 2nd Brother Job':'Enter the applicants 2nd Brother Job',
      '2nd Brother`s Birth  Day':'2nd Brother`s Birth  Day',
      'Enter the applicants  2nd Brother Birth Day':'Enter the applicants  2nd Brother Birth Day',
      '2nd Brother`s Reside nce Address':'2nd Brother`s Reside nce Address',
      'Enter the applicants  2nd Brothers Residence Address':'Enter the applicants  2nd Brothers Residence Address',
      '2nd Brother`s Postal  Code':'2nd Brother`s Postal  Code',
      'Enter the applicants  2nd Brother Postal Code':'Enter the applicants  2nd Brother Postal Code',
      '2nd Brother`s Phone  Call':'2nd Brother`s Phone  Call',
      'Enter the applicants  2nd Brother Phone Call':'Enter the applicants  2nd Brother Phone Call',
      '2nd Brother`s Education':'2nd Brother`s Education',
      'Enter the applicants 2nd Brother`s Education':'Enter the applicants 2nd Brother`s Education',
      '2nd Brother`s Financial Ability':'2nd Brother`s Financial Ability',
      'Enter the applicants 2nd Brother`s Financial Ability':'Enter the applicants 2nd Brother`s Financial Ability',
      '2nd Brother`s Date of Divorce':'2nd Brother`s Date of Divorce',
      'Enter the applicants 2nd Brother`s Date of Divorce':'Enter the applicants 2nd Brother`s Date of Divorce',
      '2nd Brother`s Date of Death':'2nd Brother`s Date of Death',
      'Enter the applicants 2nd Brother`s Date of Death':'Enter the applicants 2nd Brother`s Date of Death',
      '2nd Brother`s Foreign Trip (1)':'2nd Brother`s Foreign Trip (1)',
      '2nd Brother`s Foreign Trip (2)':'2nd Brother`s Foreign Trip (2)',
      '2nd Brother`s Foreign Trip (3)':'2nd Brother`s Foreign Trip (3)',
      '2nd Brother`s Foreign Trip (4)':'2nd Brother`s Foreign Trip (4)',
      '2nd Brother`s Foreign Trip (5)':'2nd Brother`s Foreign Trip (5)',
      '2nd Brother`s Foreign Trip (6)':'2nd Brother`s Foreign Trip (6)',
      'Enter the applicants 2nd Brother`s Foreign Trip':'Enter the applicants 2nd Brother`s Foreign Trip',

      'Brother (3)':'Brother (3)',
      '3rd Brother`s Name':'3rd Brother`s Name',
      'Enter the applicants 3rd Brother Name':'Enter the applicants 3rd Brother Name',
      '3rd Brother`s Family':'3rd Brother`s Family',
      'Enter the applicants 3rd Brother Family':'Enter the applicants 3rd Brother Family',
      '3rd Brother`s Job':'3rd Brother`s Job',
      'Enter the applicants 3rd Brother Job':'Enter the applicants 3rd Brother Job',
      '3rd Brother`s Birth  Day':'3rd Brother`s Birth  Day',
      'Enter the applicants  3rd Brother Birth Day':'Enter the applicants  3rd Brother Birth Day',
      '3rd Brother`s Reside nce Address':'3rd Brother`s Reside nce Address',
      'Enter the applicants  3rd Brothers Residence Address':'Enter the applicants  3rd Brothers Residence Address',
      '3rd Brother`s Postal  Code':'3rd Brother`s Postal  Code',
      'Enter the applicants  3rd Brother Postal Code':'Enter the applicants  3rd Brother Postal Code',
      '3rd Brother`s Phone  Call':'3rd Brother`s Phone  Call',
      'Enter the applicants  3rd Brother Phone Call':'Enter the applicants  3rd Brother Phone Call',
      '3rd Brother`s Education':'3rd Brother`s Education',
      'Enter the applicants 3rd Brother`s Education':'Enter the applicants 3rd Brother`s Education',
      '3rd Brother`s Financial Ability':'3rd Brother`s Financial Ability',
      'Enter the applicants 3rd Brother`s Financial Ability':'Enter the applicants 3rd Brother`s Financial Ability',
      '3rd Brother`s Date of Divorce':'3rd Brother`s Date of Divorce',
      'Enter the applicants 3rd Brother`s Date of Divorce':'Enter the applicants 3rd Brother`s Date of Divorce',
      '3rd Brother`s Date of Death':'3rd Brother`s Date of Death',
      'Enter the applicants 3rd Brother`s Date of Death':'Enter the applicants 3rd Brother`s Date of Death',
      '3rd Brother`s Foreign Trip (1)':'3rd Brother`s Foreign Trip (1)',
      '3rd Brother`s Foreign Trip (2)':'3rd Brother`s Foreign Trip (2)',
      '3rd Brother`s Foreign Trip (3)':'3rd Brother`s Foreign Trip (3)',
      '3rd Brother`s Foreign Trip (4)':'3rd Brother`s Foreign Trip (4)',
      '3rd Brother`s Foreign Trip (5)':'3rd Brother`s Foreign Trip (5)',
      '3rd Brother`s Foreign Trip (6)':'3rd Brother`s Foreign Trip (6)',
      'Enter the applicants 3rd Brother`s Foreign Trip':'Enter the applicants 3rd Brother`s Foreign Trip',


      'Sister (1)':'Sister (1)',
      '1th Sister`s Name':'1th Sister`s Name',
      'Enter the applicants 1th Sister Name':'Enter the applicants 1th Sister Name',
      '1th Sister`s Family':'1th Sister`s Family',
      'Enter the applicants 1th Sister Family':'Enter the applicants 1th Sister Family',
      '1th Sister`s Job':'1th Sister`s Job',
      'Enter the applicants 1th Sister Job':'Enter the applicants 1th Sister Job',
      '1th Sister`s Birth  Day':'1th Sister`s Birth  Day',
      'Enter the applicants  1th Sister Birth Day':'Enter the applicants  1th Sister Birth Day',
      '1th Sister`s Reside nce Address':'1th Sister`s Reside nce Address',
      'Enter the applicants  1th Sisters Residence Address':'Enter the applicants  1th Sisters Residence Address',
      '1th Sister`s Postal  Code':'1th Sister`s Postal  Code',
      'Enter the applicants  1th Sister Postal Code':'Enter the applicants  1th Sister Postal Code',
      '1th Sister`s Phone  Call':'1th Sister`s Phone  Call',
      'Enter the applicants  1th Sister Phone Call':'Enter the applicants  1th Sister Phone Call',
      '1th Sister`s Education':'1th Sister`s Education',
      'Enter the applicants 1th Sister`s Education':'Enter the applicants 1th Sister`s Education',
      '1th Sister`s Financial Ability':'1th Sister`s Financial Ability',
      'Enter the applicants 1th Sister`s Financial Ability':'Enter the applicants 1th Sister`s Financial Ability',
      '1th Sister`s Date of Divorce':'1th Sister`s Date of Divorce',
      'Enter the applicants 1th Sister`s Date of Divorce':'Enter the applicants 1th Sister`s Date of Divorce',
      '1th Sister`s Date of Death':'1th Sister`s Date of Death',
      'Enter the applicants 1th Sister`s Date of Death':'Enter the applicants 1th Sister`s Date of Death',
      '1th Sister`s Foreign Trip (1)':'1th Sister`s Foreign Trip (1)',
      '1th Sister`s Foreign Trip (2)':'1th Sister`s Foreign Trip (2)',
      '1th Sister`s Foreign Trip (3)':'1th Sister`s Foreign Trip (3)',
      '1th Sister`s Foreign Trip (4)':'1th Sister`s Foreign Trip (4)',
      '1th Sister`s Foreign Trip (5)':'1th Sister`s Foreign Trip (5)',
      '1th Sister`s Foreign Trip (6)':'1th Sister`s Foreign Trip (6)',
      'Enter the applicants 1th Sister`s Foreign Trip':'Enter the applicants 1th Sister`s Foreign Trip',

      'Sister (2)':'Sister (2)',
      '2nd Sister`s Name':'2nd Sister`s Name',
      'Enter the applicants 2nd Sister Name':'Enter the applicants 2nd Sister Name',
      '2nd Sister`s Family':'2nd Sister`s Family',
      'Enter the applicants 2nd Sister Family':'Enter the applicants 2nd Sister Family',
      '2nd Sister`s Job':'2nd Sister`s Job',
      'Enter the applicants 2nd Sister Job':'Enter the applicants 2nd Sister Job',
      '2nd Sister`s Birth  Day':'2nd Sister`s Birth  Day',
      'Enter the applicants  2nd Sister Birth Day':'Enter the applicants  2nd Sister Birth Day',
      '2nd Sister`s Reside nce Address':'2nd Sister`s Reside nce Address',
      'Enter the applicants  2nd Sisters Residence Address':'Enter the applicants  2nd Sisters Residence Address',
      '2nd Sister`s Postal  Code':'2nd Sister`s Postal  Code',
      'Enter the applicants  2nd Sister Postal Code':'Enter the applicants  2nd Sister Postal Code',
      '2nd Sister`s Phone  Call':'2nd Sister`s Phone  Call',
      'Enter the applicants  2nd Sister Phone Call':'Enter the applicants  2nd Sister Phone Call',
      '2nd Sister`s Education':'2nd Sister`s Education',
      'Enter the applicants 2nd Sister`s Education':'Enter the applicants 2nd Sister`s Education',
      '2nd Sister`s Financial Ability':'2nd Sister`s Financial Ability',
      'Enter the applicants 2nd Sister`s Financial Ability':'Enter the applicants 2nd Sister`s Financial Ability',
      '2nd Sister`s Date of Divorce':'2nd Sister`s Date of Divorce',
      'Enter the applicants 2nd Sister`s Date of Divorce':'Enter the applicants 2nd Sister`s Date of Divorce',
      '2nd Sister`s Date of Death':'2nd Sister`s Date of Death',
      'Enter the applicants 2nd Sister`s Date of Death':'Enter the applicants 2nd Sister`s Date of Death',
      '2nd Sister`s Foreign Trip (1)':'2nd Sister`s Foreign Trip (1)',
      '2nd Sister`s Foreign Trip (2)':'2nd Sister`s Foreign Trip (2)',
      '2nd Sister`s Foreign Trip (3)':'2nd Sister`s Foreign Trip (3)',
      '2nd Sister`s Foreign Trip (4)':'2nd Sister`s Foreign Trip (4)',
      '2nd Sister`s Foreign Trip (5)':'2nd Sister`s Foreign Trip (5)',
      '2nd Sister`s Foreign Trip (6)':'2nd Sister`s Foreign Trip (6)',
      'Enter the applicants 2nd Sister`s Foreign Trip':'Enter the applicants 2nd Sister`s Foreign Trip',

      'Sister (3)':'Sister (3)',
      '3rd Sister`s Name':'3rd Sister`s Name',
      'Enter the applicants 3rd Sister Name':'Enter the applicants 3rd Sister Name',
      '3rd Sister`s Family':'3rd Sister`s Family',
      'Enter the applicants 3rd Sister Family':'Enter the applicants 3rd Sister Family',
      '3rd Sister`s Job':'3rd Sister`s Job',
      'Enter the applicants 3rd Sister Job':'Enter the applicants 3rd Sister Job',
      '3rd Sister`s Birth  Day':'3rd Sister`s Birth  Day',
      'Enter the applicants  3rd Sister Birth Day':'Enter the applicants  3rd Sister Birth Day',
      '3rd Sister`s Reside nce Address':'3rd Sister`s Reside nce Address',
      'Enter the applicants  3rd Sisters Residence Address':'Enter the applicants  3rd Sisters Residence Address',
      '3rd Sister`s Postal  Code':'3rd Sister`s Postal  Code',
      'Enter the applicants  3rd Sister Postal Code':'Enter the applicants  3rd Sister Postal Code',
      '3rd Sister`s Phone  Call':'3rd Sister`s Phone  Call',
      'Enter the applicants  3rd Sister Phone Call':'Enter the applicants  3rd Sister Phone Call',
      '3rd Sister`s Education':'3rd Sister`s Education',
      'Enter the applicants 3rd Sister`s Education':'Enter the applicants 3rd Sister`s Education',
      '3rd Sister`s Financial Ability':'3rd Sister`s Financial Ability',
      'Enter the applicants 3rd Sister`s Financial Ability':'Enter the applicants 3rd Sister`s Financial Ability',
      '3rd Sister`s Date of Divorce':'3rd Sister`s Date of Divorce',
      'Enter the applicants 3rd Sister`s Date of Divorce':'Enter the applicants 3rd Sister`s Date of Divorce',
      '3rd Sister`s Date of Death':'3rd Sister`s Date of Death',
      'Enter the applicants 3rd Sister`s Date of Death':'Enter the applicants 3rd Sister`s Date of Death',
      '3rd Sister`s Foreign Trip (1)':'3rd Sister`s Foreign Trip (1)',
      '3rd Sister`s Foreign Trip (2)':'3rd Sister`s Foreign Trip (2)',
      '3rd Sister`s Foreign Trip (3)':'3rd Sister`s Foreign Trip (3)',
      '3rd Sister`s Foreign Trip (4)':'3rd Sister`s Foreign Trip (4)',
      '3rd Sister`s Foreign Trip (5)':'3rd Sister`s Foreign Trip (5)',
      '3rd Sister`s Foreign Trip (6)':'3rd Sister`s Foreign Trip (6)',
      'Enter the applicants 3rd Sister`s Foreign Trip':'Enter the applicants 3rd Sister`s Foreign Trip',
      'Prime Family Documents':'Prime Family Documents',
      'Passport (1)':'Passport (1)',
      'Passport (2)':'Passport (2)',
      'Passport (3)':'Passport (3)',
      'Passport (4)':'Passport (4)',
      'Passport (5)':'Passport (5)',
      'Passport (6)':'Passport (6)',
      'Passport (7)':'Passport (7)',
      'Passport (8)':'Passport (8)',
      'Applicant Travel Information':'Applicant Travel Information',
      'Applicant Sopuse Travel Information':'Applicant Sopuse Travel Information',
      'Foreign Trips':'Foreign Trips',
      'Number of Foreign Trips':'Number of Foreign Trips',
      'Enter the applicants Number of Foreign Trips':'Enter the applicants Number of Foreign Trips',
      'Arrival Travel Date (1)':'Arrival Travel Date (1)',
      'Enter the applicants Arrival Travel Date (1)':'Enter the applicants Arrival Travel Date (1)',
      'Departure Travel Date (1)':'Departure Travel Date (1)',
      'Enter the applicants Departure Travel Date (1)':'Enter the applicants Departure Travel Date (1)',
      'Travel Location (1)':'Travel Location (1)',
      'Enter the applicants Travel Location (1)':'Enter the applicants Travel Location (1)',
      'Staying Time (1)':'Staying Time (1)',
      'Enter the applicants Staying Time (1)':'Enter the applicants Staying Time (1)',
      'Reason for Travel (1)':'Reason for Travel (1)',
      'Enter the applicants Reason for Travel (1) ':'Enter the applicants Reason for Travel (1) ',
      'Cities visited in the destination country (1)':'Cities visited in the destination country (1)',
      'Enter the applicants Cities visited in the destination country (1)':'Enter the applicants Cities visited in the destination country (1)',

      'Arrival Travel Date (2)':'Arrival Travel Date (2)',
      'Enter the applicants Arrival Travel Date (2)':'Enter the applicants Arrival Travel Date (2)',
      'Departure Travel Date (2)':'Departure Travel Date (2)',
      'Enter the applicants Departure Travel Date (2)':'Enter the applicants Departure Travel Date (2)',
      'Travel Location (2)':'Travel Location (2)',
      'Enter the applicants Travel Location (2)':'Enter the applicants Travel Location (2)',
      'Staying Time (2)':'Staying Time (2)',
      'Enter the applicants Staying Time (2)':'Enter the applicants Staying Time (2)',
      'Reason for Travel (2)':'Reason for Travel (2)',
      'Enter the applicants Reason for Travel (2) ':'Enter the applicants Reason for Travel (2) ',
      'Cities visited in the destination country (2)':'Cities visited in the destination country (2)',
      'Enter the applicants Cities visited in the destination country (2)':'Enter the applicants Cities visited in the destination country (2)',

      'Arrival Travel Date (3)':'Arrival Travel Date (3)',
      'Enter the applicants Arrival Travel Date (3)':'Enter the applicants Arrival Travel Date (3)',
      'Departure Travel Date (3)':'Departure Travel Date (3)',
      'Enter the applicants Departure Travel Date (3)':'Enter the applicants Departure Travel Date (3)',
      'Travel Location (3)':'Travel Location (3)',
      'Enter the applicants Travel Location (3)':'Enter the applicants Travel Location (3)',
      'Staying Time (3)':'Staying Time (3)',
      'Enter the applicants Staying Time (3)':'Enter the applicants Staying Time (3)',
      'Reason for Travel (3)':'Reason for Travel (3)',
      'Enter the applicants Reason for Travel (3) ':'Enter the applicants Reason for Travel (3) ',
      'Cities visited in the destination country (3)':'Cities visited in the destination country (3)',
      'Enter the applicants Cities visited in the destination country (3)':'Enter the applicants Cities visited in the destination country (3)',

      'Arrival Travel Date (4)':'Arrival Travel Date (4)',
      'Enter the applicants Arrival Travel Date (4)':'Enter the applicants Arrival Travel Date (4)',
      'Departure Travel Date (4)':'Departure Travel Date (4)',
      'Enter the applicants Departure Travel Date (4)':'Enter the applicants Departure Travel Date (4)',
      'Travel Location (4)':'Travel Location (4)',
      'Enter the applicants Travel Location (4)':'Enter the applicants Travel Location (4)',
      'Staying Time (4)':'Staying Time (4)',
      'Enter the applicants Staying Time (4)':'Enter the applicants Staying Time (4)',
      'Reason for Travel (4)':'Reason for Travel (4)',
      'Enter the applicants Reason for Travel (4) ':'Enter the applicants Reason for Travel (4) ',
      'Cities visited in the destination country (4)':'Cities visited in the destination country (4)',
      'Enter the applicants Cities visited in the destination country (4)':'Enter the applicants Cities visited in the destination country (4)',
      'Schengen':'Schengen',
      'Schengen Number':'Schengen Number',
      'Enter the applicants Schengen Number':'Enter the applicants Schengen Number',
      'Schengen Issuance Date':'Schengen Issuance Date',
      'Enter the applicants Schengen Issuance Date':'Enter the applicants Schengen Issuance Date',

      'Special Visa':'Special Visa',
      'Special Visa Number (1)':'Special Visa Number (1)',
      'Enter the applicants Special Visa Number':'Enter the applicants Special Visa Number',
      'Visa Name (1)':'Visa Name (1)',
      'Enter the applicants Visa Name':'Enter the applicants Visa Name',

      'Special Visa Number (2)':'Special Visa Number (2)',
      'Visa Name (2)':'Visa Name (2)',
      'Special Visa Number (3)':'Special Visa Number (3)',
      'Visa Name (3)':'Visa Name (3)',
      'Special Visa Number (4)':'Special Visa Number (4)',
      'Visa Name (4)':'Visa Name (4)',
      'Special Visa Number (5)':'Special Visa Number (5)',
      'Visa Name (5)':'Visa Name (5)',
      'Special Visa Number (6)':'Special Visa Number (6)',
      'Visa Name (6)':'Visa Name (6)',

      '- in 20 Character':'- in 20 Character',

      'Residence of The Destination Country':'Residence of The Destination Country',
      'Expiry date of residence of the destination country (1)':'Expiry date of residence of the destination country (1)',
      'Enter the applicants Expiry date of residence of the destination country':'Enter the applicants Expiry date of residence of the destination country',
      'Expiry date of residence of the destination country (2)':'Expiry date of residence of the destination country (2)',
      'Expiry date of residence of the destination country (3)':'Expiry date of residence of the destination country (3)',
      
      'Rejected Visa':'Rejected Visa',
      'Prior Rejection Date (1)':'Prior Rejection Date (1)',
      'Enter the applicants Prior Rejection Date':'Enter the applicants Prior Rejection Date',
      'The country you rejected (1)':'The country you rejected (1)',
      'Enter the applicants The Country You Rejected':'Enter the applicants The Country You Rejected',
      'Rejected Visa Type (1)':'Rejected Visa Type (1)',
      'Enter the applicants Rejected Visa Type':'Enter the applicants Rejected Visa Type',
      'Date of Application for Rejected Visa (1)':'Date of Application for Rejected Visa (1)',
      'Enter the applicants Date of Application for Rejected Visa':'Enter the applicants Date of Application for Rejected Visa',
      'Reason for Rejection (1)':'Reason for Rejection (1)',
      'Enter the applicants Reason for Rejection':'Enter the applicants Reason for Rejection',

      'Prior Rejection Date (2)':'Prior Rejection Date (2)',
      'The country you rejected (2)':'The country you rejected (2)',
      'Rejected Visa Type (2)':'Rejected Visa Type (2)',
      'Date of Application for Rejected Visa (2)':'Date of Application for Rejected Visa (2)',
      'Reason for Rejection (2)':'Reason for Rejection (2)',

      'Prior Rejection Date (3)':'Prior Rejection Date (3)',
      'The country you rejected (3)':'The country you rejected (3)',
      'Rejected Visa Type (3)':'Rejected Visa Type (3)',
      'Date of Application for Rejected Visa (3)':'Date of Application for Rejected Visa (3)',
      'Reason for Rejection (3)':'Reason for Rejection (3)',

      'Prior Rejection Date (4)':'Prior Rejection Date (4)',
      'The country you rejected (4)':'The country you rejected (4)',
      'Rejected Visa Type (4)':'Rejected Visa Type (4)',
      'Date of Application for Rejected Visa (4)':'Date of Application for Rejected Visa (4)',
      'Reason for Rejection (4)':'Reason for Rejection (4)',

      'Prior Rejection Date (5)':'Prior Rejection Date (5)',
      'The country you rejected (5)':'The country you rejected (5)',
      'Rejected Visa Type (5)':'Rejected Visa Type (5)',
      'Date of Application for Rejected Visa (5)':'Date of Application for Rejected Visa (5)',
      'Reason for Rejection (5)':'Reason for Rejection (5)',

      'Prior Rejection Date (6)':'Prior Rejection Date (6)',
      'The country you rejected (6)':'The country you rejected (6)',
      'Rejected Visa Type (6)':'Rejected Visa Type (6)',
      'Date of Application for Rejected Visa (6)':'Date of Application for Rejected Visa (6)',
      'Reason for Rejection (6)':'Reason for Rejection (6)',

      'Applicants Travel Documents':'Applicants Travel Documents',
      'Applicants Spouse Travel Documents':'Applicants Spouse Travel Documents',
      'Old Passport (1)':'Old Passport (1)',
      'Old Passport (2)':'Old Passport (2)',
      'Old Passport (3)':'Old Passport (3)',

      'Special or Revoked Visa':'Special or Revoked Visa',
      'New Visa in The New Passport':'New Visa in The New Passport',
      'Residence documents of a specific country':'Residence documents of a specific country',
      'Documents related to the rejected file (1)':'Documents related to the rejected file (1)',
      'Documents related to the rejected file (2)':'Documents related to the rejected file (2)',
      'Documents related to the rejected file (3)':'Documents related to the rejected file (3)',
      'Documents related to the rejected file (4)':'Documents related to the rejected file (4)',
      'Documents related to the rejected file (5)':'Documents related to the rejected file (5)',
      'Documents related to the rejected file (6)':'Documents related to the rejected file (6)',

      'Details of Foreign Trips':'Details of Foreign Trips',

      'Other Information':'Other Information',
      'Your Information`s Title (1)':'Your Information`s Title (1)',
      'Your Information (1)':'Your Information (1)',
      'Your Information`s Title (2)':'Your Information`s Title (2)',
      'Your Information (2)':'Your Information (2)',
      'Your Information`s Title (3)':'Your Information`s Title (3)',
      'Your Information (3)':'Your Information (3)',
      'Enter the applicants Your Information`s Title':'Enter the applicants Your Information`s Title',
      'Enter the applicants Your Information':'Enter the applicants Your Information',

      'Other Documents of the Applicant, Spouse and Companion':'Other Documents of the Applicant, Spouse and Companion',
      'Patent':'Patent',
      'Ideas Submition':'Ideas Submition',
      'Other Qualifications':'Other Qualifications',
      'Other Job Qualifications':'Other Job Qualifications',
      'Other Financial Documents':'Other Financial Documents',
      'Motivation Letter':'Motivation Letter',
      'Other Documents (1)':'Other Documents (1)',

      'Applicant Children Information':'Applicant Children Information',
      'Applicants Spouse Children Information':'Applicants Spouse Children Information',
      'Number of Children':'Number of Children',
      'Enter the applicants Number of Children':'Enter the applicants Number of Children',
      'Profile of Children':'Profile of Children',
      'Boy':'Boy',
      'Girl':'Girl',

      'Child Name (1)':'Child Name (1)',
      'Child Family (1)':'Child Family (1)',
      'Child Age (1)':'Child Age (1)',

      'Child Name (2)':'Child Name (2)',
      'Child Family (2)':'Child Family (2)',
      'Child Age (2)':'Child Age (2)',

      'Child Name (3)':'Child Name (3)',
      'Child Family (3)':'Child Family (3)',
      'Child Age (3)':'Child Age (3)',

      'Child Name (4)':'Child Name (4)',
      'Child Family (4)':'Child Family (4)',
      'Child Age (4)':'Child Age (4)',

      'Child Name (5)':'Child Name (5)',
      'Child Family (5)':'Child Family (5)',
      'Child Age (5)':'Child Age (5)',

      'Child Name (6)':'Child Name (6)',
      'Child Family (6)':'Child Family (6)',
      'Child Age (6)':'Child Age (6)',

      'Child Name (7)':'Child Name (7)',
      'Child Family (7)':'Child Family (7)',
      'Child Age (7)':'Child Age (7)',

      'Child Name (8)':'Child Name (8)',
      'Child Family (8)':'Child Family (8)',
      'Child Age (8)':'Child Age (8)',

      'Child Name (9)':'Child Name (9)',
      'Child Family (9)':'Child Family (9)',
      'Child Age (9)':'Child Age (9)',

      'Child Name (10)':'Child Name (10)',
      'Child Family (10)':'Child Family (10)',
      'Child Age (10)':'Child Age (10)',

      'Child Name (11)':'Child Name (11)',
      'Child Family (11)':'Child Family (11)',
      'Child Age (11)':'Child Age (11)',

      'Child Name (12)':'Child Name (12)',
      'Child Family (12)':'Child Family (12)',
      'Child Age (12)':'Child Age (12)',

      'Enter the applicants Child Name':'Enter the applicants Child Name',
      'Enter the applicants Child Family':'Enter the applicants Child Family',
      'Child Gender':'Child Gender',
      'Enter the applicants Child`s Gender':'Enter the applicants Child`s Gender',
      'Enter the applicants Child Age':'Enter the applicants Child Age',

      'Children Documents':'Children Documents',
      '1th Child Passport':'1th Child Passport',
      '1th Child National Card':'1th Child National Card',
      '1th Child Birth Certificate':'1th Child Birth Certificate',
      '1th Child Exit Permission':'1th Child Exit Permission',
      '1th Child Financial Ability':'1th Child Financial Ability',
      '1th Child Certificate of Education':'1th Child Certificate of Education',
      '1th Child Other Documents':'1th Child Other Documents',

      'Visa Information':'Visa Information',
      'Why did you choose the destination country?':'Why did you choose the destination country?',
      'Input Your Answer Here...':'Input Your Answer Here...',
      'Why did you choose this type of visa?':'Why did you choose this type of visa?',
      'Why did you choose the desired university?':'Why did you choose the desired university?',
      'Why did you choose the desired field?':'Why did you choose the desired field?',
      'What work and study did you do in your country before the visa?':'What work and study did you do in your country before the visa?',
      'What is your goal in the destination country after the visa?':'What is your goal in the destination country after the visa?',
      'How do you cover the costs of the destination country?':'How do you cover the costs of the destination country?',
      'What is your goal after returning to your home country?':'What is your goal after returning to your home country?',
      'Do you have relatives or acquaintances or friends in the destination country?':'Do you have relatives or acquaintances or friends in the destination country?',
      'What is the idea for a startup visa and who designed it?':'What is the idea for a startup visa and who designed it?',
      'What are the sources of funding for a startup visa?':'What are the sources of funding for a startup visa?',
      'What is your goal in choosing a startup visa or business visa?':'What is your goal in choosing a startup visa or business visa?',
      'Are you seeking asylum?':'Are you seeking asylum?',
      'Do you have any refugee relatives in your chosen country?':'Do you have any refugee relatives in your chosen country?',
      'Do you already have investments in the destination country?':'Do you already have investments in the destination country?',
      'Have you traveled to your chosen country before?':'Have you traveled to your chosen country before?',
      'What was your goal in obtaining a Schengen visa?':'What was your goal in obtaining a Schengen visa?',

      'Step 2-7':'Step 2-7',
      '- in 500 Character':'- in 500 Character',

      'State':'State',
      'Enter Your State':'Enter Your State',
      'military service situation':'military service situation',
      'Enter the military service situation':'Enter the military service situation',
      'Applicant Age':'Applicant Age',
      'Enter the Applicant Age':'Enter the Applicant Age',
      'Scientific Documents of Awards or Festivals(1)':'Scientific Documents of Awards or Festivals(1)',
      'Enter the applicants Scientific Documents of Awards or Festivals(1)':'Enter the applicants Scientific Documents of Awards or Festivals(1)',
      'Research and Studies(1)':'Research and Studies(1)',
      'Enter the applicants Research and Studies(1)':'Enter the applicants Research and Studies(1)',
      'Scientific Documents of Awards or Festivals(2)':'Scientific Documents of Awards or Festivals(2)',
      'Enter the applicants Scientific Documents of Awards or Festivals(2)':'Enter the applicants Scientific Documents of Awards or Festivals(2)',
      'Research and Studies(2)':'Research and Studies(2)',
      'Enter the applicants Research and Studies(2)':'Enter the applicants Research and Studies(2)',
      'Scientific Documents of Awards or Festivals(3)':'Scientific Documents of Awards or Festivals(3)',
      'Enter the applicants Scientific Documents of Awards or Festivals(3)':'Enter the applicants Scientific Documents of Awards or Festivals(3)',
      'Research and Studies(3)':'Research and Studies(3)',
      'Enter the applicants Research and Studies(3)':'Enter the applicants Research and Studies(3)',
      'What languages can you speak?':'What languages can you speak?',
      'English':'English',
      'Persian':'Persian',
      'Familiarity with the chosen language':'Familiarity with the chosen language',
      'Excellent':'Excellent',
      'Good':'Good',
      'Medium':'Medium',
      'Weak':'Weak',
      'Very weak':'Very weak',
      'Enter the applicants Familiarity with the chosen language':'Enter the applicants Familiarity with the chosen language',
      'English Score':'English Score',
      'Enter the applicants English Score':'Enter the applicants English Score',
      'French':'French',
      'French Score':'French Score',
      'Enter the applicants French Score':'Enter the applicants French Score',
      'German':'German',
      'German Score':'German Score',
      'Enter the applicants German Score':'Enter the applicants German Score',
      'Danish':'Danish',
      'Danish Score':'Danish Score',
      'Enter the applicants Danish Score':'Enter the applicants Danish Score',
      'Arabic':'Arabic',
      'Arabic Score':'Arabic Score',
      'Enter the applicants Arabic Score':'Enter the applicants Arabic Score',
      'Spanish':'Spanish',
      'Spanish Score':'Spanish Score',
      'Enter the applicants Spanish Score':'Enter the applicants Spanish Score',
      'Chinese':'Chinese',
      'Chinese Score':'Chinese Score',
      'Enter the applicants Chinese Score':'Enter the applicants Chinese Score',
      'Korean':'Korean',
      'Korean Score':'Korean Score',
      'Enter the applicants Korean Score':'Enter the applicants Korean Score',
      'Turnover Number':'Turnover Number',
      'Enter the applicants Turnover Number':'Enter the applicants Turnover Number',
      '(In past 10 years)':'(In past 10 years)',
      'Job Title (1)':'Job Title (1)',
      'Enter the Job Title (1)':'Enter the Job Title (1)',
      'Insurance history or duration of activity in your job (1)':'Insurance history or duration of activity in your job (1)',
      'Enter the Insurance history or duration of activity in your job (1)':'Enter the Insurance history or duration of activity in your job (1)',
      'Job Post (1)':'Job Post (1)',
      'Enter the applicants Job Post (1)':'Enter the applicants Job Post (1)',

      'Job Title (2)':'Job Title (2)',
      'Enter the Job Title (2)':'Enter the Job Title (2)',
      'Insurance history or duration of activity in your job (2)':'Insurance history or duration of activity in your job (2)',
      'Enter the Insurance history or duration of activity in your job (2)':'Enter the Insurance history or duration of activity in your job (2)',
      'Job Post (2)':'Job Post (2)',
      'Enter the applicants Job Post (2)':'Enter the applicants Job Post (2)',

      'Job Title (3)':'Job Title (3)',
      'Enter the Job Title (3)':'Enter the Job Title (3)',
      'Insurance history or duration of activity in your job (3)':'Insurance history or duration of activity in your job (3)',
      'Enter the Insurance history or duration of activity in your job (3)':'Enter the Insurance history or duration of activity in your job (3)',
      'Job Post (3)':'Job Post (3)',
      'Enter the applicants Job Post (3)':'Enter the applicants Job Post (3)',

      'Liquidity':'Liquidity',
      'More than 70 thousand dollars':'More than 70 thousand dollars',
      'to':'to',
      'Less than $ 30,000':'Less than $ 30,000',
      'Enter the applicants Liquidity':'Enter the applicants Liquidity',
      'Enter the applicants Total Assets Amount':'Enter the applicants Total Assets Amount',
      'More than 600 thousand dollars':'More than 600 thousand dollars',
      'Less than $ 50,000':'Less than $ 50,000',
      'More than 25 thousand dollars':'More than 25 thousand dollars',
      'Less than $ 5,000':'Less than $ 5,000',
      'Further Information':'Further Information',

      'Do you have a child under 4 years old?':'Do you have a child under 4 years old?',
      'Yes':'Yes',
      'No':'No',
      'Number of Under 4 Years Old Childeren':'Number of Under 4 Years Old Childeren',
      'Enter the applicants Number of Under 4 Years Old Childeren':'Enter the applicants Number of Under 4 Years Old Childeren',
      'Age of Under 4 Years Old Child':'Age of Under 4 Years Old Child',
      'Enter the applicants Age of Under 4 Years Old Child':'Enter the applicants Age of Under 4 Years Old Child',
      'Gender of Under 4 Years Old Child':'Gender of Under 4 Years Old Child',
      'Enter the applicants Gender of Under 4 Years Old Child':'Enter the applicants Gender of Under 4 Years Old Child',
      'Do you have children between 4 and 17 years old?':'Do you have children between 4 and 17 years old?',
      'Number of children between 4 and 17 Old Childeren':'Number of children between 4 and 17 Old Childeren',
      'Enter the applicants Number of children between 4 and 17 Old':'Enter the applicants Number of children between 4 and 17 Old',
      'Age of children between 4 and 17 Old Child':'Age of children between 4 and 17 Old Child',
      'Enter the applicants Age of children between 4 and 17 Old':'Enter the applicants Age of children between 4 and 17 Old',
      'Gender of children between 4 and 17 Old Child':'Gender of children between 4 and 17 Old Child',
      'Enter the applicants Gender of children between 4 and 17 Old':'Enter the applicants Gender of children between 4 and 17 Old',
      'Do you have children over 18?':'Do you have children over 18?',
      'Number of Over 18 Years Old Childeren':'Number of Over 18 Years Old Childeren',
      'Enter the applicants Number of Over 18 Years Old Childeren':'Enter the applicants Number of Over 18 Years Old Childeren',
      'Age of Over 18 Years Old Child':'Age of Over 18 Years Old Child',
      'Enter the applicants Age of Over 18 Years Old Child':'Enter the applicants Age of Over 18 Years Old Child',
      'Gender of Over 18 Years Old Child':'Gender of Over 18 Years Old Child',
      'Enter the applicants Gender of Over 18 Years Old Child':'Enter the applicants Gender of Over 18 Years Old Child',

      'Do you have a companion? (Father - mother - grandfather - grandmother)':'Do you have a companion? (Father - mother - grandfather - grandmother)',
      'Country of immigration':'Country of immigration',
      'Sweden':'Sweden',
      'Germany':'Germany',
      'United Kingdom':'United Kingdom',
      'Denmark':'Denmark',
      'Austria':'Austria',
      'Spain':'Spain',
      'Australia':'Australia',
      'UAS':'UAS',
      'Ukraine':'Ukraine',
      'Oman':'Oman',
      'Type of Requested Visa':'Type of Requested Visa',
      'Tourist':'Tourist',
      'Educational':'Educational',
      'Student':'Student',
      'Work':'Work',
      'Business':'Business',
      'Company Registration':'Company Registration',
      'Buy real estate and companies':'Buy real estate and companies',
      'Entrepreneurship':'Entrepreneurship',
      'Startup':'Startup',
      'Express Entry':'Express Entry',
      'Provincial':'Provincial',
      'Companion - Parents':'Companion - Parents',
      'Rejection - Court':'Rejection - Court',

      'The purpose of migration':'The purpose of migration',
      'Enter the applicants purpose of migration':'Enter the applicants purpose of migration',
      'Traveled to a Schengen area, Canada,...':'Traveled to a Schengen area, Canada,...',
      'Have you or your spouse traveled to a Schengen area, Canada, the United Kingdom, the United States or Australia?':'Have you or your spouse traveled to a Schengen area, Canada, the United Kingdom, the United States or Australia?',
      'Names of countries you have visited':'Names of countries you have visited',
      'Enter the Names of countries you have visited':'Enter the Names of countries you have visited',
      'Has the applicant ever had a history of rejection from a country?':'Has the applicant ever had a history of rejection from a country?',
      'Names of countries you have been rejected so far':'Names of countries you have been rejected so far',
      'Enter the Names of countries you have been rejected so far':'Enter the Names of countries you have been rejected so far',
      'Do you have first and second degree relatives in your chosen country?':'Do you have first and second degree relatives in your chosen country?',
      'Explain...':'Explain...',
      'Enter the applicants Explain':'Enter the applicants Explain',

      'Does the applicant have a financial supporter?':'Does the applicant have a financial supporter?',
      'Explain if you have a financial supporter':'Explain if you have a financial supporter',
      'Enter Explain if you have a financial supporter':'Enter Explain if you have a financial supporter',
      'Spouse Name':'Spouse Name',
      'Enter Your Spouse Name':'Enter Your Spouse Name',
      'Spouse Last Name':'Spouse Last Name',
      'Enter Your Spouse Last Name':'Enter Your Spouse Last Name',

      'Applicant Accompany Information':'Applicant Accompany Information',
      'Accompany Post':'Accompany Post',
      'Enter the applicants Accompany Post':'Enter the applicants Accompany Post',
      'Applicant Descriptions':'Applicant Descriptions',
      'Descriptions':'Descriptions',
      'Enter Your Descriptions':'Enter Your Descriptions',

      'Included':'Included',
      'Served':'Served',
      'Conscription Exempt':'Conscription Exempt',
      'Purchased Service':'Purchased Service',

      'Applicant Informations':'Applicant Informations',


      'SORRY, PAGE NOT FOUND':'SORRY, PAGE NOT FOUND',
      'Return to Homepage':'Return to Homepage',

      'Marketer':'Marketer',

      'Your Cash is:':'Your Cash is:',
      '1500$':'1500$',
      '1000$':'1000$',
      '500$':'500$',
      'This Page is Under Construction, It will be availabe as soon as posible.':'This Page is Under Construction, It will be availabe as soon as posible.',

      'Lawyers':'Lawyers',
      'Pending':'Pending',
      '+ Services':'+ Services',
      'Enter the applicants Service Name':'Enter the applicants Service Name',
      'Messages':'Messages',
      'Service Name...':'Service Name...',
      'Messaging System is Online tools for communication between our users, also you can send message to anybody.':'Messaging System is Online tools for communication between our users, also you can send message to anybody.',
      'To':'To',
      'Subject':'Subject',
      'Type here...':'Type here...',
      'Enter Your message in the box.':'Enter Your message in the box.',
      'Send Message':'Send Message',
      'Type here your message':'Type here your message',

      'Type Your Desired Name for Service and Then click (Create)':'Type Your Desired Name for Service and Then click (Create)',
      
      'Select Your User Type':'Select Your User Type',

      'My Services':'My Services',
       
      'Lawyers List':'Lawyers List',
      'Agents List':'Agents List',
      'Customers List':'Customers List',

      'Service Owner':'Service Owner',
      'Choose Your Business Service':'Choose Your Business Service',
      'selecting your user Service type.':'selecting your user Service type.',
      '< Back to Account page':'< Back to Account page',
      'Distributers':'Distributers',

      'Dear user, welcome to your registration section':'Dear user, welcome to your registration section',
      'Select the appropriate option to register depending on the following conditions:':'Select the appropriate option to register depending on the following conditions:',
      '1- If you have an invitation code, select the option to register with the code':'1- If you have an invitation code, select the option to register with the code',
      '2- If you intend to cooperate and you think you have the conditions to obtain the code, contact us and select the option to contact us.':'2- If you intend to cooperate and you think you have the conditions to obtain the code, contact us and select the option to contact us.',
      '3- If you do not have the conditions to obtain a code, but you want to cooperate, you can start your registration by selecting the Marker option, and after obtaining the necessary points, you can reach the stage of obtaining a code.':'3- If you do not have the conditions to obtain a code, but you want to cooperate, you can start your registration by selecting the Marker option, and after obtaining the necessary points, you can reach the stage of obtaining a code.',
      '4- If none of the conditions are suitable, return to the previous page to display more options.':'4- If none of the conditions are suitable, return to the previous page to display more options.',

      'Choose Your Registration Type':'Choose Your Registration Type',
      'Registration with Code':'Registration with Code',
      'Contact us':'Contact us',
      'Registered as a Agent':'Registered as a Agent',
      

      'Doctor':'Doctor',
      'Car Service':'Car Service',
      'Real Estate':'Real Estate',
      'Trade':'Trade',
      'Degital Devices':'Degital Devices',
      'Beauty Services':'Beauty Services',
      'Gold and Jewelry':'Gold and Jewelry',
      
      'Latest Distributers':'Latest Distributers',

      'Message':'Message',
      'View':'View',
      'View Information':'View Information',
      '+ Add New Marketer':'+ Add New Marketer',
      'Related Distributers':'Related Distributers',
      'Related Distributers List':'Related Distributers List',
      'DS Customers':'DS Customers',
      'DS Shared Customers List':'DS Shared Customers List',
      'Agent Cusomers':'Agent Cusomers',
      'Agent Cusomers List':'Agent Cusomers List',
      'Distributer ':'Distributer ',
      'Distributers List':'Distributers List',

      'Choose Your Service':'Choose Your Service',
      'other':'other',
      'Type Service Name here':'Type Service Name here',
      'Service Name':'Service Name',
      'Service Price':'Service Price',
      'DS Commission':'DS Commission',
      'DC Commission':'DC Commission',
      'DC Fee':'DC Fee',
      'Distributer Commission':'Distributer Commission',
      'Agent Commission':'Agent Commission',
      'About Service':'About Service',
      'Enter the Description About Your Serivce':'Enter the Description About Your Serivce',
      'Accept':'Accept',
      'Educational Visa':'Educational Visa',
      'Enter Your Offer':'Enter Your Offer',
      'You Assigned Before':'You Assigned Before',
      'Coming Soon':'Coming Soon',
      'Soon!':'Soon!',
      'Our Help Desk':'Our Help Desk',
      'Dear Lawyer Pay Attention, Your Service in NOT Changeable, After You Submit It, You Never Access to Change Fees or Comissions!':'Dear Lawyer Pay Attention, Your Service in NOT Changeable, After You Submit It, You Never Access to Change Fees or Comissions!',
      'Services Never Changes After Adding to Your List':'Services Never Changes After Adding to Your List',
      'How To Become A Premium DS?':'How To Become A Premium DS?',
      'In Our Rules There Are some ways that You can Got Your Account DS+, After that you can Use special Services of This Application. for more Information please visit our help center.':'In Our Rules There Are some ways that You can Got Your Account DS+, After that you can Use special Services of This Application. for more Information please visit our help center.',
      'Dear user, These Services will be Available Soon!':'Dear user, These Services will be Available Soon!',
      'Our experts are working on it.':'Our experts are working on it.',
      'If your power of attorney code is not verified, your account will be deleted.':'If your power of attorney code is not verified, your account will be deleted.',
      'As Lawyer':'As Lawyer',
      'Date of birth':'Date of birth',
      'User Name Start with Alphabet and continue with Alphanumeric Char in Min lenght of 5':'User Name Start with Alphabet and continue with Alphanumeric Char in Min lenght of 5',
      'password':'password',
      'Confirm password':'Confirm password',
      'As Distributor':'As Distributor',
      'As Customer':'As Customer',
      'As Agent':'As Agent',
      'What service do you want?':'What service do you want?',
      'Your host Telens support team':'Your host Telens support team',
      'Way to communicate with us':'Way to communicate with us',
      'To contact us, just send us an email from the address below':'To contact us, just send us an email from the address below',
      'Follow us on social media':'Follow us on social media',
      'Our story':'Our story',
      'Can we help you?':'Can we help you?',
      'Joining the Telsins family':'Joining the Telsins family',
      'The name of the service is here':'The name of the service is here',
      'Referal Code':'Referal Code',
      'Select Your User Type':'Select Your User Type',
      'Select Your User':'Select Your User',
      'Telesiness Support':'Telesiness Support',
      'Select Service Name':'Select Service Name',
      'Required Documents':'Required Documents',
      'Enter the Customers Required Documents for This Service':'Enter the Customers Required Documents for This Service',
      'Visa Process':'Visa Process',
      'Enter the Process of This Service':'Enter the Process of This Service',
      'Referal Customers':'Referal Customers',
      'Referal Customer List':'Referal Customer List',
      'Customers List':'Customers List',
      'Being login...':'Being login...',
      'Service Country':'Service Country',
      '+ Add New Distributer':'+ Add New Distributer',
      'Hindi':'Hindi',
      'mandarin':'mandarin',
      'Dutch':'Dutch',
      'French':'French',
      'Bengali':'Bengali',
      'Russian':'Russian',
      'Portuguese':'Portuguese',
      'Indonesian':'Indonesian',
      'Japanese':'Japanese',
      'Turkish':'Turkish',
      'Italian':'Italian',
      'Romanian':'Romanian',
      'Thai':'Thai',
      'Nepali':'Nepali',
      'Gujarati':'Gujarati',
      'Your Customer Activated':'Your Customer Activated',
      'Your Customer Activated Successfully.':'Your Customer Activated Successfully.',
      'OK ,  I Got It':'OK ,  I Got It',
      'View Services':'View Services',
      'DS':'DS',
      'Assign a Services':'Assign a Services',
      'Your Assigned Services to Agent':'Your Assigned Services to Agent',
      'Agent Code':'Agent Code',
      'Your Assigned offer':'Your Assigned offer',
      'offer you':'offer you',
      'Suggested Services':'Suggested Services',
      'Save changes':'Save changes',
      'Assign by Telesiness':'Assign by Telesiness',
      'Adding a new Distributer':'Adding a new Distributer',
      'Type Distributer Code...':'Type Distributer Code ...',
      'Add DS!!!':'Add DS!!!',
      'Type DS Code Here...':'Type DS Code Here...',
      'ADD BY DS Code':'ADD BY DS Code',
      'DS Code':'DS Code',
      'Distributer Code':'Distributer Code',
      'List of  Tariffs For Lawyer:':'List of  Tariffs For Lawyer:',
      'Adding a new CO-DS':'Adding a new CO-DS',
      'Add a Distributer that want to be in rel for coporating in your work.':'Add a Distributer that want to be in rel for coporating in your work.',
      'Adding a Service to Customer':'Adding a Service to Customer',
      'Please Select a Service to customer.':'Please Select a Service to customer.',
      'Type an Email adress...':'Type an Email adress....',
      'DS Information':'DS Information',
      'Enter the applicant Gender':'Enter the applicant Gender',
      'Enter Your City':'Enter Your City',
      'please input your first name':'please input your first name',
      'please input your Last Name':'please input your Last Name',
      'please input your Phone number':'please input your Phone number',
      'please input your Email':'please input your Email',
      'please select your Country':'please select your Country',
      'please input your ICCRC':'please input your ICCRC',
      'UnEqual Password...':'UnEqual Password...',
      'DS Services':'DS Services',
      'This Option Will Be Active After You improve Panel to Pro Version!':'This Option Will Be Active After You improve Panel to Pro Version!',
      'Are you sure about the set tariff?':'Are you sure about the set tariff?',
      'Please be sure of the rate set as commission. It is not possible to change the tariff after final approval':'Please be sure of the rate set as commission. It is not possible to change the tariff after final approval',
      'please input your ID Number':'please input your ID Number',
      'You Can Upgrade Your Account to Profesional Varsion, fro mor information see the help!':'You Can Upgrade Your Account to Profesional Varsion, fro mor information see the help!',
      'UpLoad your Cv/Sop/evaluation-form':'UpLoad your Cv/Sop/evaluation-form',
      'If you have any of the following, you can upload the file in this section. To upload more than one file, first merge them into a pdf file and then upload':'If you have any of the following, you can upload the file in this section. To upload more than one file, first merge them into a pdf file and then upload',
      'You Have Not Code OR UnEqual Password...':'You Have Not Code OR UnEqual Password...',
      'Pay attention You can Aprove Customer Account once, and after that you cant change status!':'Pay attention You can Aprove Customer Account once, and after that you cant change status!',
    },
    lng: 'en_US', // default language
    fallbackLng: 'en_US',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });
export default i18n;