import chart1 from './images/Chart1.png';
import chart2 from './images/Chart2.png';
import chart3 from './images/Chart3.png';
import {AddSquare,ArrowDown2,Warning2,Calendar2,Briefcase, Star1,CardTick1,CardRemove1,CardEdit} from 'iconsax-react';
import Popup from './Popup';
import { useState,useEffect,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';


function useForceUpdate(){
   
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render

}

const Home = ({childToParentPrices}) => {


    const {t, i18n} = useTranslation(['Login']);
    const forceUpdate = useForceUpdate();
    const [people , setPeople] = useState([
        {visa:'false',CustomerPic:'CusPic.png', CustomerName:'',CustomerPhone:'+989143158460',CustomerStatus:'',CustomerCreated:'', CustomerAgent:'BaBaii',key:'1'},
      ]);
  
      const [ServicesPrice , setServicesPrice] = useState([
        {pen:"true",tick:"false",SName:'Business Visa', SPrice:'450',SFee:'4',SOther:'false',SOtherDesc:'24 ', key:'1'},
      ]);

    // const [people , setPeople] = useState([
    //     {visa:'false',CustomerPic:'CusPic.png', CustomerName:'behzad',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'1'},
    //     {visa:'false',CustomerPic:'CusPic.png',CustomerName:'nariman',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Nov 30 ,2021 ', CustomerAgent:'BaBaii',key:'2'},
    //     {visa:'true',CustomerPic:'alireza.png',CustomerName:'fatemeh',CustomerPhone:'+989143158460',CustomerStatus:'Deactive',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'3'},
    //     {visa:'false',CustomerPic:'alireza.png',CustomerName:'parisa',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'4'},
    //     {visa:'false',CustomerPic:'CusPic.png',CustomerName:'behzad',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'5'},
    //     {visa:'false',CustomerPic:'CusPic.png',CustomerName:'nariman',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'6'},
    //     {visa:'false',CustomerPic:'alireza.png',CustomerName:'fatemeh',CustomerPhone:'+989143158460',CustomerStatus:'Deactive',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'7'},
    //     {visa:'false',CustomerPic:'CusPic.png',CustomerName:'parisa',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2020 ', CustomerAgent:'BaBaii',key:'8'},
    //   ]);

    const [peopleA , setPeopleA] = useState([
        {AgentPic:'CusPic.png', AgentName:'',AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
      ]);

    const [peopleL , setPeopleL] = useState([
    {AgentPic:'CusPic.png', AgentName:'',AgentPhone:'',AgentCreated:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
    ]);


    fetch('https://telesiness.ir/api/Dashboards/GetLawyerTariffs' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email'), Password:'123' })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try
             {
                 console.log(people.length);
                if(ServicesPrice.length<2)
                {
                    ServicesPrice.pop();
                    var myActDec = ''
                for (i in response){
                   
                    ServicesPrice.push( {pen:"true",tick:"false",
                    SName:response[i].nameOfVisa, SPrice:response[i].wageFigure,
                    SFee:response[i].percentageOfCommission,
                    SOther:response[i].aboutService,
                    SOtherDesc:response[i].requiredDocuments, key:response[i].userId},)
                                 }
                 }
             }

            catch{

            }
            // console.log(response[0]);
            
        });


    fetch('https://telesiness.ir/api/Dashboards/LawyerDashboardSelfCustomer' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
                var i =0;
                try
                {
                    console.log(people.length);
                if(people.length<2)
                {
                    people.pop();
                    var myActDec = ''
                for (i in response.customersListView){
                    // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                    // people[i].CustomerCreated = response[i].customerRegisterDate;
                    if(response.customersListView[i].verifyCustomerByDistributor == 2)
                    myActDec = "Deactive";
                    else  if(response.customersListView[i].verifyCustomerByDistributor == 1)
                    myActDec = "Active";
                    else
                    myActDec = "Pending";
                    people.push( {visa:'false',
                    CustomerPic:'CusPic.png', 
                    CustomerName:response.customersListView[i].firstName + " " + 
                    response.customersListView[i].lastName,
                    CustomerPhone:'+989143158460',
                    CustomerStatus:myActDec,
                    CustomerCreated:response.customersListView[i].registerDate, 
                    CustomerAgent:'BaBaii',key:'1'},)
                                    }
                    }
                }

            catch{

            }
            // console.log(response[0]);
            
        });

        fetch('https://telesiness.ir/api/Dashboards/LawyerDashboardDistributorsList' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try{ 

                if(peopleA.length<2)
                {
                    peopleA.pop();
                    for (i in response.distributorList){
                        // console.log(response.distributorList[i].distributorFirstName);
                        // peopleA[i].AgentName = response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName;
                        // peopleA[i].AgentClients = response.distributorList[i].clientsCount;
                        // peopleA[i].AgentPhone = response.distributorList[i].distributorCode;
                        peopleA.push({AgentPic:'CusPic.png', AgentName:response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName,AgentPhone:response.distributorList[i].distributorCode,AgentStatus:'Active',AgentClients:response.distributorList[i].clientsCount, Email:'kambiz.fakhr@yahoo.com',key:'129121'},)
                    }
                }
              
                
             }
  
            catch{}
            // console.log(response.distributorList[0].distributorFirstName);
            //   forceUpdate();
            // window.location.reload(false);
            
        });
        const [data2, setData2] = useState([]);

     
           fetch('https://telesiness.ir/api/Dashboards/LawyersInvitedByLawyersList' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              Email:localStorage.getItem('email')})
            }).then(response => response.json()).then(response=>{
              try{
              var i;
              if(peopleL.length<2)
              {
                  peopleL.pop();
                  
          for(i in response.lawyerList){
              peopleL.push({ 
            AgentPic:'CusPic.png',
            AgentName: response.lawyerList[i].lawyerFirstName + ' '+ response.lawyerList[i].lawyerLastName,
            AgentPhone: response.lawyerList[i].lawyerPhoneNumber, 
            AgentCreated: response.lawyerList[i].lawyerPhoneNumber, 
            AgentStatus: response.lawyerList[i].lawyerCode,
            AgentClients:response.lawyerList[i].aboutService,
            Email:response.lawyerList[i].lawyerWorkMail,
            clients: '24',
            city: 'Toronto',
            country:'Canada',
            key: (i).toString(),},);
            
            console.log(response[0][0]);
          }} }catch{}
            })
            
         
        
        


        

        const [state, setState] = useState({ num: 0 });
        const counter = useRef(0);
        
        useEffect(() => {
          if (counter.current < 2) {
            counter.current += 1;
            const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
      
            return () => clearTimeout(timer);
            forceUpdate();
          }
        }, [state]);
        //  
    //   const [peopleA , setPeopleA] = useState([
    //     {AgentPic:'CusPic.png', AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
    //     {AgentPic:'CusPic.png',AgentName:'nariman',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'8 ', Email:'kambiz.fakhr@yahoo.com',key:'129122'},
    //     {AgentPic:'alireza.png',AgentName:'fatemeh',AgentPhone:'+989143158460',AgentStatus:'Deactive',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129123'},
    //     {AgentPic:'alireza.png',AgentName:'parisa',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129124'},
    //     {AgentPic:'CusPic.png',AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129125'},
    //     {AgentPic:'CusPic.png',AgentName:'nariman',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129126'},
    //     {AgentPic:'alireza.png',AgentName:'fatemeh',AgentPhone:'+989143158460',AgentStatus:'Deactive',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129127'},
    //     {AgentPic:'CusPic.png',AgentName:'parisa',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'31 ', Email:'kambiz.fakhr@yahoo.com',key:'129128'},
    //   ]);
    console.log(peopleA);
      const [values, setValues] = useState({
        Email: '',
        L: '',
        EmailL:'',
        checked: '',
        refCode: '',
        showPassword: false,
      });
      const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    const handleDeactiveClick = (itemKey) =>{
        setIsOpen(!isOpen);
        for(var i in people){
            if(people[i].key==itemKey){
                people[i].CustomerStatus='Deactive';
                setVisaId(i);
            }
            
        }
        forceUpdate() ;
        setPeople(people);
    
    }
    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
   const [isOpen, setIsOpen] = useState(false);
   const [isOpen2, setIsOpen2] = useState(false);
   const [isOpen3, setIsOpen3] = useState(false);
   const [isOpen4, setIsOpen4] = useState(false);
   const [isOpen6, setIsOpen6] = useState(false);
   const [visaID,setVisaId]=useState(-1);
   const handleVisaPbox = (itemKey) =>{
    people[visaID].visa='Visa';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}

const handleRejectedPbox = (itemKey) =>{
    people[visaID].visa='Rejected';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}


const handleDeclinedPbox = (itemKey) =>{
    people[visaID].visa='Declined';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}
const togglePopup = () => {
    setIsOpen(!isOpen);
  }
const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
}
const togglePopup3 = () => {
setIsOpen3(!isOpen3);
}
const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    }

const togglePopup6 = () => {
setIsOpen6(!isOpen6);
}

    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen3(!isOpen3);
    }
    const handleAddNewUserLawyer = (itemKey) =>{
        setIsOpen6(!isOpen6);
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      }
      const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomerByLawyer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ CustomerEmailAddress: values.Email ,LawyerCode:localStorage.getItem('lawyerCode') })
            }).then().then(setIsOpen2(!isOpen2));
        
    }
    const handleAddUserAgentSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteDistributorsByLawyer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ DistributorEmailAddress: values.EmailA ,LawyerCode:localStorage.getItem('lawyerCode') })
            }).then().then(setIsOpen3(!isOpen3));
        
    }

    const handleAddUserLawyerSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteLawyerByLawyer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ LawyerEmailAddress: values.EmailL ,LawyerCode:localStorage.getItem('lawyerCode') })
            }).then().then(setIsOpen6(!isOpen6));
        
    }

    localStorage.removeItem('CusNameMsg');
    return ( 
        <div className="HomeMainRow home_agentandlawyer col-12">
            <div className="HomeRow1">
                <div className="total_summary col-12">
                <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                            <div className="homeCell1">
                            {/* <img src={chart1} alt="" /> */}
                            <CardTick1 size="64" color="#37d67a" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Completed Files')}</h4>
                                <p>+36</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart2} alt="" /> */}
                            <CardRemove1 size="64" color="#f47373" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Canceled files')}</h4>
                                <p>+3</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart3} alt="" /> */}
                            <CardEdit  size="64" color="#2ccce4" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('In progress files')}</h4>
                                <p>+67</p>
                            </div>
                        </div>
                    </div>
                </Carousel>
   
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                            <div className="homeCell1">
                            {/* <img src={chart1} alt="" /> */}
                            <CardTick1 size="64" color="#37d67a" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Completed Files')}</h4>
                                <p>+36</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart2} alt="" /> */}
                            <CardRemove1 size="64" color="#f47373" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('Canceled files')}</h4>
                                <p>+3</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_summary col-lg-4 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowOne">
                        <div className="homeCell1">
                            {/* <img src={chart3} alt="" /> */}
                            <CardEdit  size="64" color="#2ccce4" variant="Bold"/>
                            </div>
                            <div className="homeCell2">
                                <h4 className='pt-3'>{t('In progress files')}</h4>
                                <p>+67</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="HomeRow2 home_dashpan col-12">
                <div className="total_HomeLightBoxRowTwo col-lg-10 col-12">
                <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                                <div className="HomeR2R1">
                                    <p>{t('Latest Customers')}</p>
                                    <AddSquare className='AddUserHome' onClick={handleAddNewUserCustomer} size="40" color="#000000" variant="Bold"/>
                                </div>
                                <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                            {people.map(item =>(
                            
                                <div className="itemBox col-12"  key={item.key}>                                   
                                {/* <div className="col-1"><p>
                                <input type="checkbox"></input>
                                    </p> </div> */}

                                {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                <div className="CusPic2 col-5"><p>{item.CustomerName}</p></div>
                                {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                {item.CustomerStatus=='Active' && <div className="col-3 btn_de_active">
                                    <button onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                        {t('Active')}
                                        <div id={item.key} className="ChoosBox"> 
                                            <a className='disabled'>{t('Active')}</a>  <br />
                                            <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                        </div>
                                    </button></div>}

                                {item.CustomerStatus=='Deactive' && <div className="col-3 btn_de_active">
                                    <button onClick={()=>handleStatusClick(item.key)} className="btn-sm deactive">
                                        {t('Deactive')}
                                        <div id={item.key} className="ChoosBox"> 
                                            <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                            <a className='disabled'>{t('Deactive')}</a>
                                        </div>
                                    </button>
                                    </div>}
                                <div className="col-4 collist_item"><p>{item.CustomerCreated.split('T',1)}</p></div>                    
                            </div>
                                    ))}
                        </div>
                        </div>
                        </div>    
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12"> 
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Distributers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserAgent} size="40" color="#000000" variant="Bold"/>
                            </div>                             
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                    {peopleA.map(item =>(                          
                                        <div className="itemBox col-12"  key={item.key}>
                                            {/* <div className="col-1"><p>
                                            <input type="checkbox"></input>
                                                </p> </div> */}
                                            {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                            <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                            {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                            {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                            { <div className="col-3 coun_clients">
                                                <div  className="btn-sm HomeGrayCilents">
                                                
                                                    <div id={item.key} className="ChoosBox2"> 
                                                        
                                                    <p>{item.AgentClients + t(' Client')}</p>
                                                    </div>
                                                </div>
                                            </div>}

                                        
                                            <div className="col-4 collist_item"><p>{item.AgentPhone}</p></div>                               
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Lawyers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserLawyer} size="40" color="#000000" variant="Bold"/>
                            </div>                             
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                    {peopleL.map(item =>(                          
                                        <div className="itemBox col-12"  key={item.key}>
                                            {/* <div className="col-1"><p>
                                            <input type="checkbox"></input>
                                                </p> </div> */}
                                            {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                            <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                            {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                            {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                            { <div className="col-3 coun_clients">
                                                <div  className="btn-sm HomeGrayCilents">
                                                
                                                    <div id={item.key} className="ChoosBox2"> 
                                                        
                                                    <p>{item.AgentStatus}</p>
                                                    </div>
                                                </div>
                                            </div>}

                                        
                                            <div className="col-4 collist_item"><p>{item.AgentCreated.split('T',1)}</p></div>                     
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('My Services')}</p>
                                <AddSquare className='AddUserHome' onClick={(dataMessage)=>{
                                     document.getElementById('PercentageSquareBtn').style.backgroundColor='white';
                                     document.getElementById('PercentageSquareBtnP').style.color='black';
                                     document.getElementById('homeBtn').style.backgroundColor='transparent';
                                     document.getElementById('homeBtnP').style.color='white';
                                     localStorage.setItem('turnOffMeLawCusMsg',true);
                                    childToParentPrices(dataMessage);
                                    }} size="40" color="#000000" variant="Bold"/>
                            </div>                             
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                {ServicesPrice.map(item =>(
                    <div  key={item.key}>
                        
                       <div className="item_tariff col-12">
                          <div className="agentCusRow col-12">
                           {<div className="col-1"><span>{t('ID')}</span><p>{item.key}</p> </div>}
                           {item.pen == "true" &&<div className="col-5"><span>{t('Services')}</span><p>{item.SName}</p>
                            {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a><InfoCircle size="18" color="#313131"/></a>
                                </div>}  */}
                                <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div>
                            </div>}
                            {item.pen == "true" &&<div className="col-3"><span>{t('Price')}</span><p>{item.SPrice}</p></div>}
                            {item.pen == "true" && <div className="col-3"><span>{t('DC Commission')}</span><p>{"%" +item.SFee}</p></div>}
                            {/* {item.tick == "true" &&<div className="CusPic col-3"><input className="col-12" onChange={handleChange('Service')} type="text" value={ values.Service}/></div>}
                            {item.tick == "true" &&<div className="col-2 "><input className="col-12" onChange={handleChange('proLastName')} type="text" value={ values.proLastName}/></div>}
                            {item.tick == "true" && <div className="col-4"><input className="col-12" onChange={handleChange('Fee')} type="text" value={ values.Fee}/></div>}   */}
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                <a >
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                </a>
                            </div>}  */}
                            {/* <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                            {/* <div className="col-2 PenAndTrash">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>handlePenClick(item.key)} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                            {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key)} size="18" variant="Bold" color="#21D350" className="PriceListIcons"/>}
                            <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/>
                            </div> */}
                           </div>   
                       </div>
                    </div>
                   
                   
                        ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                </Carousel>

                <div className="box_HomeLightBoxRowTwo col-lg-6 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowTwo">
                                <div className="HomeR2R1">
                                    <p>{t('Latest Customers')}</p>
                                    <AddSquare className='AddUserHome' onClick={handleAddNewUserCustomer} size="40" color="#000000" variant="Bold"/>
                                </div>
                                <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                            {people.map(item =>(
                                <div className="itemBox col-12" key={item.key}>                                   
                                {/* <div className="col-1"><p>
                                <input type="checkbox"></input>
                                    </p> </div> */}
                                {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                <div className="CusPic2 col-5"><p>{item.CustomerName}</p></div>
                                {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                {item.CustomerStatus=='Active' && <div className="col-3 btn_de_active">
                                    <button  onClick={()=>handleStatusClick(item.key)} className="btn-sm active">
                                        {t('Active')}
                                        <div id={item.key} className="ChoosBox"> 
                                            <a className='disabled'>{t('Active')}</a>  <br />
                                            <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                        </div>
                                    </button></div>}

                                {item.CustomerStatus=='Deactive' && <div className="col-3 btn_de_active">
                                    <button onClick={()=>handleStatusClick(item.key)} className="btn-sm deactive">
                                        {t('Deactive')}
                                        <div id={item.key} className="ChoosBox"> 
                                            <a onClick={()=>handleActiveClick(item.key)}>{t('Active')}</a>  <br />
                                            <a className='disabled'>{t('Deactive')}</a>
                                        </div>
                                    </button>
                                    </div>}
                                    {item.CustomerStatus=='Pending' && <div className="col-lg-3 col-6 de_active_agentCus">
                            <button  className="btn-sm Pending">
                                {t('Pending')}
                                {/* <div id={item.key} className="ChoosBox"> 
                                    <a className='disabled'>{t('Active')}</a>  <br />
                                    <a onClick={()=>handleDeactiveClick(item.key)}>{t('Deactive')}</a>
                                </div> */}
                            </button>
                            
                        </div>}
                                <div className="col-4 collist_item"><p>{item.CustomerCreated.split('T',1)}</p></div>           
                            </div>
                                    ))}
                        </div>
                        </div>
                        </div>    
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Distributers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserAgent} size="40" color="#000000" variant="Bold"/>
                            </div>                             
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                    {peopleA.map(item =>(                          
                                        <div className="itemBox col-12"  key={item.key}>
                                            {/* <div className="col-1"><p>
                                            <input type="checkbox"></input>
                                                </p> </div> */}
                                            {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                            <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                            {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                            {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                            { <div className="col-3 coun_clients">
                                                <div  className="btn-sm HomeGrayCilents">
                                                
                                                    <div id={item.key} className="ChoosBox2"> 
                                                        
                                                    <p>{item.AgentClients + t(' Client')}</p>
                                                    </div>
                                                </div>
                                            </div>}

                                        
                                            <div className="col-4 collist_item"><p>{item.AgentPhone}</p></div>           
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Latest Lawyers')}</p>
                                <AddSquare className='AddUserHome' onClick={handleAddNewUserLawyer} size="40" color="#000000" variant="Bold"/>
                            </div>                             
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                    {peopleL.map(item =>(                          
                                        <div className="itemBox col-12"  key={item.key}>
                                            {/* <div className="col-1"><p>
                                            <input type="checkbox"></input>
                                                </p> </div> */}
                                            {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                            <div className="CusPic2 col-5"><p>{item.AgentName}</p></div>
                                            {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                            {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                            { <div className="col-3 coun_clients">
                                                <div  className="btn-sm HomeGrayCilents">
                                                
                                                    <div id={item.key} className="ChoosBox2" title="Lawyer Customers Code"> 
                                                        
                                                    <p>{t('') + item.AgentStatus}</p>
                                                    </div>
                                                </div>
                                            </div>}

                                        
                                            <div className="col-4 collist_item"><p>{item.AgentCreated.split('T',1)}</p></div>        
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12 d-none d-lg-block">
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('My Services')}</p>
                                <AddSquare className='AddUserHome' onClick={(dataMessage)=>{
                                     document.getElementById('PercentageSquareBtn').style.backgroundColor='white';
                                     document.getElementById('PercentageSquareBtnP').style.color='black';
                                     document.getElementById('homeBtn').style.backgroundColor='transparent';
                                     document.getElementById('homeBtnP').style.color='white';
                                     localStorage.setItem('turnOffMeLawCusMsg',true);
                                    childToParentPrices(dataMessage);
                                    }} size="40" color="#000000" variant="Bold"/>
                            </div>                             
                            <div className="HomeR2R2 lasted_laworage">
                                <div className="mainRowCusHomeR2 col-12">
                                <div>
                                    <div className="item_tariff head_rowserv col-12">
                                    <div className="agentCusRow col-12">
                                        {<div className="col-1"><p>{t('ID')}</p> </div>}
                                        {<div className="col-5"><p>{t('Services')}</p>
                                        {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                                <a><InfoCircle size="18" color="#313131"/></a>
                                            </div>}  */}
                                            {/* <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div> */}
                                        </div>}
                                        {<div className="col-3"><p>{t('Price')}</p></div>}
                                        {<div className="col-3"><p>{t('DC Commission')}</p></div>}
                                        </div>   
                                    </div>
                                </div>
                                {ServicesPrice.map(item =>(
                    <div  key={item.key}>
                        
                       <div className="item_tariff col-12">
                          <div className="agentCusRow col-12">
                           {<div className="col-1 id_tariff"><p>{item.key}</p> </div>}
                           {item.pen == "true" &&<div className="col-lg-5"><p>{item.SName}</p>
                            {/* {item.SOther== "true" && <div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="PriceInfoCircle">
                                    <a><InfoCircle size="18" color="#313131"/></a>
                                </div>}  */}
                                <div id={item.key + 'VisaBox'} className="otherBox">{item.SOtherDesc}</div>
                            </div>}
                            {item.pen == "true" &&<div className="col-lg-3"><p>{item.SPrice}</p></div>}
                            {item.pen == "true" && <div className="col-lg-3"><p>{"%" +item.SFee}</p></div>}
                            {/* {item.tick == "true" &&<div className="CusPic col-3"><input className="col-12" onChange={handleChange('Service')} type="text" value={ values.Service}/></div>}
                            {item.tick == "true" &&<div className="col-2 "><input className="col-12" onChange={handleChange('proLastName')} type="text" value={ values.proLastName}/></div>}
                            {item.tick == "true" && <div className="col-4"><input className="col-12" onChange={handleChange('Fee')} type="text" value={ values.Fee}/></div>}   */}
                            {/* {<div onMouseLeave={()=>handleInfoCircleMouseLeave(item.key + 'VisaBox')} onMouseOver={()=>handleInfoCircleMouseOver(item.key + 'VisaBox')} className="DeactiveInfoCircle">
                                <a >
                                    <InfoCircle size="18" variant="Bold" color="#E95060"/>
                                </a>
                            </div>}  */}
                            {/* <div id={item.key + 'VisaBox'} className="VisaBox">{item.visa}</div> */}
                            {/* <div className="col-2 PenAndTrash">
                            {item.pen == "true" && <Edit2 id={item.key} onClick= {()=>handlePenClick(item.key)} size="18" variant="Bold" color="#4A90E2" className="PriceListIcons"/>}
                            {item.tick == "true" && <TickCircle id={item.key} onClick= {()=>handleTickClick(item.key)} size="18" variant="Bold" color="#21D350" className="PriceListIcons"/>}
                            <Trash id={item.key + 'Trash'} onClick= {()=>handleTrashClick(item.key)} size="18" variant="Bold" color="#D0021B" className="PriceListIcons"/>
                            </div> */}
                           </div>   
                       </div>
                    </div>
                   
                   
                        ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
                <div className="box_HomeLightBoxRowTwo col-lg-2 col-md-12 col-12">
                    <div className="HomeLightBoxRowTwo HomeCol3Row2">
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox">
                                <Calendar2 size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Registery Date')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('registerDate').split('T',1)}</p>
                        </div>
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox">
                                <Briefcase className='HomeBrifcasePad' size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Advocacy Code')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('lawyerCode')}</p>
                        </div>
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="Star svg_HomeLightBox" onClick={togglePopup4}>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131" variant="Bold"/>
                                <Star1 size="24" color="#313131"/>
                                <Star1 size="24" color="#313131"/>
                            </div>
                            <p className='HomeCol3P1 Star' onClick={togglePopup4}>720</p>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Why You wanna De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-3 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-3 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-3 btn btn-light">{t('Declined')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}

{isOpen4 && <Popup
      content={<>
      <div className="PboxMainRow">
      <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Your Score in Telesiness Shows Your Activity')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('You can Improve to 5Star Lawyer if You Can Catch 5 Star')}</p>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Your Score need 6 Successfull Visa to next Star')}</p>
                
            </div>
            <div className="Star" onClick={togglePopup4}>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131" variant="Bold"/>
                <Star1 size="24" color="#313131"/>
                <Star1 size="24" color="#313131"/>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup4}
    />}

     {isOpen2 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.Email} onChange={handleChange('Email')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup2}
    />}
    {isOpen3 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Distributer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Distributer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.L} onChange={handleChange('L')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup3}
    />}


{isOpen6 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Adding a New Lawyer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the lawyer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailL} onChange={handleChange('EmailL')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserLawyerSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup6}
    />}
        </div>
                
     );
}
 
export default Home;