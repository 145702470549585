export default {
    fa_IR: {
      Login: {
        'Sign In to Telesiness': 'ورود به پنل کاربری تلزینس',
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.':'لورم ایپسوم متن سـاختگی با تولید سادگی نـامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است',
        'Email':'پست الکترونیک',
        'Remember me':'مرا به خاطر بسپار',
        'Forgot Password':'رمز عبور خود را فراموش کردید؟',
        'Login':'ورود',
        'or':'یا',
        'Sing Up Now':'ثبت نام کنید',
        'Services':'خدمات',
        'About':'درباره ما',
        'About us':'درباره ما',
        'Collaborations':'همکاری با ما',
        'Terms':'قوانین',
        'Help':'راهنما',
        'Contact Us':'تماس با ما',
        'Reset Your Password': 'بازیابی رمز عبور',
        'Send Link':'ارسال لینک',
        '< Back to Home page':' < بازگشت به صفحه اصلی',
        'Your messages: {{message_count}}': 'تعداد پیام‌های شما: {{message_count}}',
        'Submit':'ثبت تغییرات',
        'Update':'بروزرسانی',
        'You are all set':'تغییرات ثبت گردید! ',
        'Login now':'وارد شوید',
        'New Password':'رمز عبور جدید',
        'Confirm New Password':'تکرار رمز عبور جدید',
        'Sign Up To Telesiness':'ثبت نام در پنل کاری تلزینس',
        'Step 1':'مرحله یک',
        'Step 2':'مرحله دوم',
        'Step 3':'مرحله سوم',
        'Step 4':'مرحله چهارم',
        'Step 5':'مرحله پنجم',
        'Choose Your Account Type':'نوع اکانت خود را انتخاب کنید',
        'Complete your Personal information':'اطلاعات شخصی خود را کامل کنید',
        'Complete your Education information':'اطلاعات تحصیلی خود را کامل کنید',
        'Complete your Job information':'اطلاعات شغلی خود را کامل کنید',
        'Choose your Password':'برای حساب کاربری خود رمز عبور تعریف کنید',
        '1 of 5':'1 از 5',
        '2 of 5':'2 از 5',
        '3 of 5':'3 از 5',
        '4 of 5':'4 از 5',
        '5 of 5':'5 از 5',
        'Lawyer':'وکیل',
        'Agent':'نماینده',
        'Customer':'مشتری',
        'First Name':'نام',
        'Last Name':'نام خانوادگی',
        'Phone Number':'تلفن همراه ',
        'Date of Birth:':'تاریخ تولد:',
        'about':'درباره ما',
        'Day':'روز',
        'Month':'ماه',
        'Year':'سال',
        'Next Step':'مرحله بعد',
        'Back':'برگشت',
        'January':'فروردین',
        'February':'اردیبشهت',
        'March':'خرداد',
        'April':'تیر',
        'May':'مرداد',
        'June':'شهریور',
        'July':'مهر',
        'August':'آبان',
        'September':'آذر',
        'October':'دی',
        'November':'بهمن',
        'December':'اسفند',
        'Field of Study':'رشته تحصیلی',
        'Institute/University':'آموزشگاه/دانشگاه',
        'Level/Degree of education':'مدرک تحصیلی',
        'Less than a high school diploma':'زیردیپلم',
        'Diploma':'دیپلم',
        'Pre-university':'پیش دانشگاهی',
        'High school diploma':'دیپلم متوسطه',
        'Some college, no degree':'تحصیلات دانشگاهی بدون مدرک',
        'Associate degree':'کاردانی/فوق دیپلم',
        'Bachelor degree':'لیسانس/کارشناسی',
        'Master Degree':'فوق لیسانس/کارشناسی ارشد',
        'Profissional degree':'درجه حرفه ای',
        'Doctoral degree':'دکتری',
        'Country':'کشور',
        'City':'شهر',
        'Iran':'ایران',
        'England':'انگلستان',
        'Turkey':'ترکیه',
        'UAE':'امارات متحد عربی',
        'USA':'آیالت متحده آمریکا',
        'Canada':'کانادا',
        'Paris':'پاریس',
        'Tehran':'تـــــهران',
        'California':'کالیفرنیا',
        'Toronto':'تورنتو',
        'Istanbul':'استانبول',
        'Job specialty':'زمینه کاری',
        'Specialization in immigration':'زمینه ی تخصصی در امور مهاجرت',
        'Law society number/ICCRC Code':'کد وکالت',
        'Personal or company website':'آدرس وبسایت شخصی یا کاری',
        'Certificate':'دوره و گواهینامه',
        'Certificate...':'دوره و گواهینامه...',
        'Upload your Resume/CV':'رزومه ی خود را بارگذاری کنید',
        'Choose Your Password':'رمز عبور تعریف کنید',
        'Welcome':'خوش آمدید',
        'Setin Set':'علی معتمدی',
        'Home':'صفحه اصلی',
        'Customers':'مشتریان',
        'Agents':'نمایندگان',
        'My Prices':'تعرفه های من',
        'Messages':'پیام ها',
        'Services':'خدمات',
        'Personal Information':'اطلاعات شخصی',
        'Gender':'جنسیت',
        'Birthday':'تاریخ تولد',
        'Phone':'شماره تلفن',
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit':'لورم ایپسوم متن ساختگی تولید نامفهوم',
        'Address Information':'اطلاعات محل سکونت',
        'Save':'ذخیره تغییرات',
        'Cancel':'لغو',
        'No':'پلاک',
        'Address':'آدرس',
        'Zip Code':'کدپستی',
        'Educational Information':'اطلاعات تحصیلی',
        'Level/Degree':'آخرین مدرک تحصیلی',
        'Job Information':'اطلاعات شغلی',
        'Upload Resume/CV':'بارگذاری رزومه',
        'Website Address':'آدرس وب سایت',
        'Profile Information':'اطلاعات حساب کاربری',
        'User Name':'نام کاربری',
        'Change Password':'تغییر رمز عبور',
        'You Changed these fields before ... ':'شما قبلا یکبار این فیلدهارا عوض کرده اید',
        'Serach here':'جستجو...',
        'Help Center':'مرکز پشیبانی',
        'ID':'کد',
        'Phone Number':'شماره تماس',
        'Status':'وضعیت',
        'Created':'ایجاد شده در',
        '+ Add New Customer':'+  افزودن مشتری',
        'View Profile':'مشاهده پروفایل',
        'Comming Soon':'به زودی',
        'FAQs':'سوالات متداول',
        'Settings':'تنظیمات',
        'Sign Out':'خروج',
        'Deactive':'غیرفعال',
        'Active':'فعال',
        'Active Users':'کاربران فعال',
        'Deactive Users':'کاربران غیرفعال',
        'Why You wanna De-active this case?':'به چه علت قصد غیرفعال کردن این پرونده را دارید؟',
        'Please select the reason for deactivating this case.':'لطفا دلیل غیرفعالسازی این پرونده را انتخاب نمایید.',
        'Visa':'ویزا',
        'Rejected':'رد شده',
        'Declined':'انصراف از پرونده',
        'Lawyer List':'لیست وکلا',
        'Customers Name':'نام مشتری',
        'Clients Name':'نام مشتری',
        'Are you sure?':'آیا اطمینان دارید؟',
        'Dear User Pay Attention!':'کاربر عزیز توجه کنید!',
        'You Can edit your information JUST ONE TIME!!!':'شما فقط یک بار می توانید اطلاعات خود را ویرایش نمایید!!!',
        'We will send a link to ':' ما یک ایمیل برای تغییر رمز عبور به ',
        ' for reset your password':' ارسال میکنیم ',
        'User Name':'نام کاربری',
        'Clients':'مشتری',
        '+ Add New Agent':'+  افزودن نماینده',
        'Prices Information':'اطلاعات تعرفه',
        'Service':'نوع خدمات حقوقی',
        'Offer':'درصد',
        'Public Offer':'درصد عمومی',
        'Price':'تعرفه',
        'Type a price here...':'...تعرفه را وارد کنید',
        'Fee...':'سهم تلزینس',
        '+ Add Service':'افزودن +',
        'Type about your service...':'...توضیحات خدمات خود را وارد کنید',
        '$':'ریال',
        'Password':'رمز عبور...',
        'type your password here...':'رمز عبور...',
        'type your email...':'ایمیل خود را وارد کنید...',
        'You are almost there!':'فرآیند ثبت نام تقریبا به اتمام رسید',
        'Check Your Email':'!صندوق پست الکترونیکی خود را بررسی کنید',
        'Done!':'!تکمیل شد',
        '2 of 3':'2 از 3',
        'Passport No / National Number / ID Num':'شماره پاسپورت/ کد ملی / شماره ایدی',
        '3 of 3':'3 از 3',
        'Enter your username and password below to log in And have access to a world of immigration facilities':'نام کاربری و رمز عبور خود را در کادر زیر وارد کنید تا به تلزینس وارد شوید و به دنیایی از امکانات مهاجرت دسترسی داشته باشید',
        'Enter your email address below and we will send you a link to reset your password.':'آدرس پست الکترونیکی که با آن در تلزینس ثبت نام کردید را برای دریافت لینک بازیابی رمزعبور در کادر زیر وارد کنید',
        'Passwords must be at least 8 characters long. Remember that it is not recommended that you reuse usernames and passwords for different services and websites.':'رمز عبور باید حداقل 8 کاراکتر باشد. به یاد داشته باشید که استفاده مجدد از نام کاربری و رمز عبور برای خدمات و وب سایت های مختلف توصیه نمی شود',
        'Your password has been successfully changed. You can now log in with your new password.':'رمز عبور شما با موفقیت تغییر یافت. اکنون می توانید با رمز عبور جدید وارد شوید',
        'Access your specific features by selecting your user type.':'با انتخاب نوع کاربری صحیح به امکانات اختصاصی خود دسترسی داشته باشید',
        'Please help us get to know you better by completing your personal information.':'با تکمیل اطلاعات شخصی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Please help us get to know you better by completing your educational information.':'با تکمیل اطلاعات تحصیلی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Please help us get to know you better by completing your job information.':'با تکمیل اطلاعات شغلی خود به ما کمک کنید تا شما را بهتر بشناسیم',
        'Choose the appropriate username and password to log in to your profile so that you can easily access the Telesiness dashboard.':'برای ورود به پروفایل خود نام کاربری و رمز عبور مناسب انتخاب کنید تا بتوانید به راحتی به داشبورد تلزینس دسترسی داشته باشید',
        'Your registration was successful. You already have access to a variety of immigration opportunities.':'ثبت نام شما با موفقیت انجام شد. شما از الان به دنیایی از امکانات مهاجرتی دسترسی دارید',
        'Go to main page':'برو به صفحه اصلی',
        'Dear user, your information has been successfully registered in our database':'کاربر گرامی اطلاعات شما با موفقیت در بانک اطلاعاتی ما ثبت گردید',
        'Our experts will contact you as soon as possible after review.':'کارشناسان ما پس از بررسی در اسرع وقت با شما تماس خواهند گرفت',
        'Certificate':'گواهی نامه ',
        'License':'مجوز کار ',
        'Distributor':'توسعه دهنده',
        'Your Code is Valid.':'اطلاعات در سامانه ثبت گردیده است',
        'Your Code is UnValid,  It is not registered in our database.':'کد نا معتبر است. اطلاعات در سامانه ثبت نگردیده است',
        'Full name is: ':'نام کامل : ',
        'Position is: ':'سمت : ',
        'I got it.':'متوجه شدم',
        'Search by Telesiness Code':'استعلام کد تلزینس',
        'Find':'جستجو',
        'Inquiry':'استعلام',
        'Lawyer or Distributor':'وکیل یا توسعه دهنده',
        'I Got it':'متوجه شدم',
        'Menu Item Will Be Active After Reviewing Your Profile By Our Experts':'گزینه های منو بعد از فعال شدن اکانت شما نمایش داده خواهند شد',
        'My Requests':'درخواستهای من',
        'Are You Sure?':'آیا اطمینان دارید؟',
        'You choose the lawyer by Telesiness':'شما انتخاب وکیل توسط تلزینس را برگزیدید ',
        'You choose the lawyer by ':'وکیل شما ',
        'Send Request':'ارسال درخواست',
        'Advocacy Code':'کد وکالت',
        'Agent Fee':'سهم نماینده',
        'Distributer Fee':'سهم توسعه دهنده',
        'See more':'اعلان های بیشتر',
        'Agent Percent':'درصد نماینده',
        'Percent':'درصد',
        'Please enter the email of the lawyer you want to cooperate with.':'لطفا ایمیل وکیل موردنظر را جهت همکاری وارد نمایید.',
        'Please enter the email of the Distributer you want to cooperate with.':'لطفا ایمیل توسعه دهنده موردنظر را جهت همکاری وارد نمایید.',
        'Please enter the email of the Agent you want to cooperate with.':'لطفا ایمیل نماینده موردنظر را جهت همکاری وارد نمایید.',
        'Please enter the email of the Customer you want to cooperate with.':'لطفا ایمیل مشتری موردنظر را جهت همکاری وارد نمایید.',
        'Please Select Your Co-Lawyers to Your Customer.':'لطفاً وکلای همکار خود را برای مشتری خود انتخاب کنید.',
        'ADD BY Email Address':'افزودن با آدرس ایمیل',
        'Lawyers Name':'نام وکلا',
        'Adding a Lawyer to Customer':'افزودن وکیل برای مشتری',
        'Enter the applicants Lawyers':'وکلا متقاضی را وارد کنید',
        'Enter the applicants Lawyers Num 1':'متقاضیان وکلای شماره 1 را وارد کنید',
        'Enter the applicants Lawyers Num 2':'متقاضیان وکلای شماره 2 را وارد کنید',
        'Enter the applicants Lawyers Num 3':'متقاضیان وکلای شماره 3 را وارد کنید',
        'Enter the applicants Lawyers Num 4':'متقاضیان وکلای شماره 4 را وارد کنید',
        'Enter the applicants Lawyers Num 5':'متقاضیان وکلای شماره 5 را وارد کنید',
        'Enter the applicants Lawyers Num 6':'متقاضیان وکلای شماره 6 را وارد کنید',
        '+ Add New Distributer':'+ افزودن توسعه دهنده جدید',
        '+ Lawyer':'+ وکیل',
        'Add Lawyer':'افزودن وکیل',
        'Lawyer Name':'نام وکیل',
        'Lawyer 1 Name...':'نام وکیل شماره 1...',
        'Lawyer 2 Name...':'نام وکیل شماره 2...',
        'Lawyer 3 Name...':'نام وکیل شماره 3...',
        'Lawyer 4 Name...':'نام وکیل شماره 4...',
        'Lawyer 5 Name...':'نام وکیل شماره 5...',
        'Lawyer 6 Name...':'نام وکیل شماره 6...',
        'Download Form':'فرم دانلود',
        'Customer Code':'کد مشتری',
        'All Done!':'همه انجام شد!',
        'Basic Informations':'اطلاعات پایه',
        'Applicant Personal Information':'اطلاعات شخصی متقاضی',
        'Applicant Language Information':'اطلاعات زبان متقاضی',
        'Email Address':'آدرس ایمیل',
        'Type an Email address...':'ایمیل آدرس تان را بنویسید...',
        '+ Add New Lawyer':'+ افزودن وکیل جدید',
        'Related Agents':'نمایندگان مرتبط',
        'Completed Files':'پرونده های انجام شده',
        'Canceled files':'پرونده های لغو شده',
        'In progress files':'پرونده های در حال انجام',
        'Latest Customers':'مشتریان اخیر',
        'Latest Agents':'نمایندگان اخیر',
        'Adding a new Customer':'افزودن مشتری جدید',
        'Adding a new Agent':'افزودن نماینده جدید',
        'Send Link!':'ارسال لینک',
        'Agency Code':'کد نمایندگی',
        'Latest Lawyers':'وکلا اخیر',
        'Adding a New Lawyer':'افزودن وکیل جدید',
        'Registery Date':'تاریخ عضویت',
        'Lawyer Information':'اطلاعات وکیل',
        'Agent Information':'اطلاعات نماینده',
        'There is no information to display':'اطلاعاتی برای نمایش وجود ندارد!',
        'My Request':'درخواستهای من',
        'You dont have any request yet':'شما هنوز درخواستی ندارید',
        '+ Setup new Request':'+ ثبت درخواست جدید',
        'Submit a request':'تشکیل پرونده',
        'Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy':'برای پیگیری بهتر روند درخواست خود، اطلاعات زیر را با دقت تکمیل کنید',
        'In the first step to complete your file, carefully complete the basic information, then after confirming the information by our experts, proceed to complete your file.':'در قدم اول برای تشکیل پرونده ی خود، اطلاعات اولیه را با دقت تکمیل نمایید، سپس بعد از تایید اطلاعات توسط کارشناسان ما برای تکمیل پرونده ی خود اقدام نمایید',
        'Basic Information':'اطلاعات اولیه',
        'Applicant Information':'اطلاعات متقاضی',
        'Spouse Information':'اطلاعات همســــــــــر',
        'Couples Information':'اطلاعات زوجین',
        'Children Information':'اطلاعات فرزندان',
        'Children':'فرزندان',
        'Other':'اطلاعات دیگر',
        'back to My Requests':'بازگشت به لیست درخواست ها',
        'Male':'مرد',
        'Female':'زن',
        'Complete Basic Information':'تکمیل اطلاعات اولیه',
        'Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy':'تمامی اطلاعات خواسته شده را با دقت پر کنید و آن را بارها چک بفرمایید. تمامی اطلاعات وارد شده عینا در سفارت استفاده خواهد شد',
        'Personal Information':'اطلاعات شخصی',
        'Enter Your First Name in Persian':'نام خود را به فارسی وارد کنید',
        'Enter Your Last Name in Persian':'نام خانوادگی خود را به فارسی وارد کنید',
        'ID Number':'کدملی',
        'Enter the applicants ID Number':'کد ملی متقاضی را وارد کنید',
        'National Code':'شماره شناسنامه',
        'Enter the applicants national code':'شماره شناسنامه متقاضی را وارد کنید',
        'E-mail':'ایمیل',
        'Enter the applicants Email address':'ایمیل خود را وارد کنید',
        'Call Number':'شماره ثابت',
        'Enter the applicants Call number':'شماره تلفن ثابت خود را وارد نمایید',
        'Enter the applicants Phone number':'شماره همراه خود را وارد نمایید',
        'Select Your Country':'کشور خود را انتخاب نمایید',
        'Enter the applicants Country':'کشور خود را انتخاب نمایید',
        'Enter the applicants address':'آدرس محل سکونت خود را وارد کنید',
        'ZipCode':'کدپستی',
        'Enter the applicants ZipCode':'کدپستی محل سکونت را وارد کنید',
        'place of Birth':'محل تولد',
        'Enter the applicants place of birth':'محل تولد متقاضی را وارد کنید',
        'Enter the applicants date of birth':'تاریخ تولد خود را به هجری شمسی وارد کنید',
        'Your file has been successfully registered':'پرونده ی شما با موفقیت ثبت شد',
        'Please be patient until Your information is reviewed and approved by our experts, and then proceed to complete your information.':'لطفا تا زمان بررسی و تایید اطلاعات خود توسط کارشناسان ما صبور باشید و پس از آن نسبت به تکمیل اطلاعات خود اقدام نمایید',
        'Tracking Code':'کدپیگیری',
        'Go to request':'برو به درخواست ها',
        'Visa Type':'نوع ویزا',
        'Situation':'وضعیت',
        'Destination':'مقصد',
        'Code':'کد پیگیری',
        'Creation Date':'تاریخ ایجاد',
        'Applicant information':'اطلاعات متقاضی',
        'Back to Submitting a request':'بازگشت به تشکیل پرونده',
        'Needs to be completed':'نیازمند تکمیل',
        'Completed':'تکمیل شده',
        'In progress':'در حال تکمیل',
        'Passport Information':'اطلاعات پاسپورت',
        'Financial Information':'اطلاعات مالی',
        'Foreign travel Information':'اطلاعات سفر های خارجی',
        'Military Information':'اطلاعات پایان خدمت',
        'Marriage Information':'اطلاعات ازدواج',
        'Family Information':'اطلاعات خانواده',
        'Requested Visa Information':'اطلاعات ویزای درخواستی',
        'Identical Information':'مدارک هویتی',
        'National Cart Image(Front)':'تصویر روی کارت ملی',
        'National Cart Image(Behind)':'تصویر پشت کارت ملی',
        'National Note Image(First Page)':'صفحه اول شناسنامه',
        'National Note Image(Second Page)':'صفحه دوم شناسنامه',
        'National Note Image(Last Page)':'صفحه آخر شناسنامه',
        'Complete Passport Information':'اطلاعات پاسپورت را تکمیل نمایید',
        'Pasport Information':'اطلاعات پاسپورت',
        'Passport Number':'شماره پاسپورت',
        'Enter the applicants Passport Number':'شماره پاسپورت خود را وارد نمایید',
        'Name':'نام',
        'Enter the applicants Name in the Passport':'نام درج شده در پاسپورت را به انگلیسی وارد نمایید',
        'Family':'نام خانوادگی',
        'Enter the applicants Family in the Passport':'نام خانوادگی درج شده در پاسپورت را به انگلیسی وارد نمایید',
        'Enter the applicants Passport Creation Date':'تاریخ میلادی صدور پاپورت را وارد نمایید',
        'Expiration Date':'تاریخ انقضا',
        'Enter the applicants Passport Expiration Date':'تاریخ میلادی خاتمه پاسپورت خود را وارد نمایید',
        'Passport Creation Place':'محل صدور پاسپورت',
        'Enter the applicants Passport Creation Place':'محل صدور پاسپورت خود را وارد نمایید',
        'Country of Residence':'کشور اقامت',
        'Country of residence stated in the passport':'کشوری که در پاسپورت محل اقامت شما است',
        'Enter the applicants Birth Day On Passport':'تاریخ تولد میلادی قید شده در پاسپورت',
        'Passport Documentaions':'مدارک پاسپورت',
        'Passport Image':'تصویر پاسپورت',
        'Personal Picture in Pass':'عکس پرسنلی داخل پاسپورت',
        'Sample Passport Signature':'نمونه امضا پاسپورت',
        'Place of Issuance of National Card':'محل صدور کارت ملی',
        'Enter the applicants Place of Issuance of National Card':'محل صدور کرات ملی را وارد نمایید',
        'Birth Certificate Number':'شماره شناسنامه',
        'Enter the applicants Birth Certificate Number':'شماره شناسنامه خود را وارد نمایید',
        'Fathers Name on the Birth Certificate':'نام پدر در شناسنامه',
        'Enter the applicants Fathers Name on the Birth Certificate':'نام پدر خود را مطابق با شناسنامه وارد نمایید',
        'Mothers name on the Birth Certificate':'نام مادر در شناسنامه',
        'Enter the applicants Mothers name on the Birth Certificate':'نام مادر خود را مطابق با شناسنامه وارد نمایید',
        'Alias Name or Surname or changed in the identity card':'نام یا نام خانوادگی مستعار یا تغییر داده شده در شناسنامه',
        'Applicants Alias Name,Surname or changed in the identity card':'نام یا نام خانوادگی مستعار یا تغییر داده شده در شناسنامه را وارد نمایید',
        'Military Card Number':'شماره کارت پایان خدمت',
        'Enter the applicants Military Card Number':'شماره کارت پایان خدمت خود را وارد نمایید',
        'Date of Issuance of Military Card':'تاریخ صدور پایان خدمت',
        'Enter the applicants Passport Creation Date':'تاریخ صدور پایان خدمت خود را انتخاب نمایید.',
        'Type of Military Card':'نوع پایان خدمت',
        'Enter the applicants Type of Military Card':'نوع پایان خدمت خود را وارد نمایید.',
        'Place of Military Service':'محل خدمت',
        'Enter the applicants Place of Military Service':'محل خدمت خود را وارد نمایید',
        'Height':'قد',
        'Enter the applicants Height':'قد خود را به سانتی متر وارد نمایید.',
        'Skin Color':'رنگ پوست',
        'Enter the applicants Skin Color':'رنگ پوست خود را انتخاب نمایید',
        'Eye Color':'رنگ چشم',
        'Enter the applicants Eye Color':'رنگ چشم خود را انتخاب نمایید',
        'ZipCode One':'کدپستی (1)' ,
        'Enter the applicants ZipCode (Place One - Required)':'کدپستی اصلی خود را وارد نمایید.',
        'Address One':'آدرس (1)',
        'Enter the applicants address (Place One - Required)':'آدرس اصلی خود را وارد نمایید (1)',
        'Address Two':'آدرس (2)',
        'Enter the applicants address (Place Two)':'آدرس خود را وارد نمایید(2)',
        'ZipCode Two':'کدپستی (2)',
        'Enter the applicants ZipCode (Place Two)':'کدپستی خود را وارد نمایید (2)',
        'Workplace Call Number':'شماره تلفن محل کار',
        'Enter the applicants Workplace Call Number':'شماره تلفن محل کار خود را وارد نمایید',
        'Type of Marriage':'وضعیت تاهل',
        'Enter the applicants Type of Marriage':'نوع تاهل خود را انتخاب نمایید',
        'Evaluation Form Tracking Number':'شماره رهگیری فرم ارزیابی',
        'Enter the applicants Evaluation Form Tracking Number':'کدرهگیری موجود در فرم ارزیابی را وارد نمایید',
        'Call Number (Two)':'شماره تماس تلفن ثابت (2)',
        'Enter the applicants Call number (Second Number Optional)':'شماره تماس دیگری از خود در صورت وجود وارد نمایید',
        'Phone Number (Two)':'شماره تلفن همراه (2)',
        'Enter the applicants Phone number (Second Number Optional)':'در صورت وجود شماره همراه دوم آنرا وارد نمایید.',
        'Workplace Call Number (Two)':'شماره تماس محل کار (2)',
        'Enter the applicants Workplace Call Number (Optional)':'شماره دیگری از محل کار خود در صورت وجود وارد نمایید.',
        'Yellow':'زرد',
        'White':'سفید',
        'Red':'قرمز',
        'Brunette':'سبزه',
        'Tan':'برنزه',
        'Black':'سیاه',
        'Green':'سبز',
        'Gray':'خاکستری',
        'Blue':'آبی',
        'Brown':'قهوه ای',
        'Amber':'کهربایی',
        'Honey-Colored':'عسلی',
        'Married':'متاهل',
        'Single':'مجرد',
        'Identical Documents':'اسناد هویتی',
        'Military service card':'کارت پایان خدمت',
        'Lack of background':'فرم عدم سوء پیشینه',
        'Certificate or any other ID Card':'گواهینامه یا هر مدرک شناسایی دیگر',
        'Evaluation Form':'فرم ارزیابی',
        'Other Documents':'دیگر اسناد',
        'Step 2-1':'مرحله 1 از 2',
        'Applicant Foreign Language Information':'اطلاعات زبان خارجه متقاضی',
        'Language Certifications':'مدارک زبان اخذ شده',
        'English Language Status':'وضعیت زبان انگلیسی',
        'I Have':'دارم',
        'I do not have':'ندارم',
        'I am reading':'در حال خواندن هستم',
        'I will be able to get a degree in the next 6 months':'توانایی اخذ مدرک تا ۶ ماه آینده را دارم',
        'I will not be able to get a degree for the next 8 months':'توانایی اخذ مدرک تا ۸ ماه آینده را ندارم',
        'Enter the applicants English Language Status':'وضعیت زبان خود را انتخاب نمایید',
        'IELTS':'آیلتس',
        'Date of IELTS degree':'تاریخ اخذ آیلتس',
        'Enter the applicants Date of IELTS degree':'تاریخی که مدرک آیلتس گرفته اید را وارد نمایید.',
        'The total score of the degree':'نمره کل اخذ شده',
        'Enter the applicants total score of the degree':'نمره کل اخذ شده در آزمون را وارد نمایید',
        'IELTS Reading Score':'نمره ریدینگ آیلتس',
        'Enter the applicants IELTS Reading Score':'نمره ریدینگ آیلتس را وارد نمایید',
        'IELTS Writing Score':'نمره رایتینگ آیلتس',
        'Enter the applicants IELTS Writing Score':'نمره رایتینگ آیلتس را وارد نمایید',
        'IELTS Listening Score':'نمره لیسنینگ آیلتس',
        'Enter the applicants IELTS Listening Score':'نمره لیسنینگ آیلتس را وارد نمایید.',
        'IELTS Speacking Score':'نمره اسپیکینگ',
        'Enter the applicants IELTS Speacking Score':'نمره اسپیکینگ آیلتس را وارد نمایید',
        'TOEFL':'تافل',
        'Enter the applicants Date of Other Language degree':'تاریخ اخذ مدرک را وارد نمایید',
        'Date of TOEFL degree':'تاریخ اخذ مدرک تافل',
        "Enter the applicant's Field of Study":"رشته تحصیلی خود را وارد کنید",
        'Enter the applicants Date of TOEFL degree':'تاریخی که مدرک تافل گرفته اید را وارد نمایید.',
        'The total score of the degree':'نمره کل اخذ شده',
        'Enter the applicants total score of the degree':'نمره کل اخذ شده در آزمون را وارد نمایید',
        'TOEFL Reading Score':'نمره ریدینگ تافل',
        'Enter the applicants TOEFL Reading Score':'نمره ریدینگ تافل را وارد نمایید',
        'TOEFL Writing Score':'نمره رایتینگ تافل',
        'Enter the applicants TOEFL Writing Score':'نمره رایتینگ تافل را وارد نمایید',
        'TOEFL Listening Score':'نمره لیسنینگ تافل',
        'Enter the applicants TOEFL Listening Score':'نمره لیسنینگ تافل را وارد نمایید.',
        'TOEFL Speacking Score':'نمره اسپیکینگ',
        'Enter the applicants TOEFL Speacking Score':'نمره اسپیکینگ تافل را وارد نمایید',
        'Other Language (1)':'مدرک دیگر (1)',
        'The total score of the Other Language degree(1)':'نمره کل مدرک زبان دیگر (1)',
        'Enter the applicants total score of the Other Language degree(1)':'نمره کل مدرک زبان خود را وارد نمایید (1)',
        'Other Language Reading Score(1)':'نمره ریدینگ زبان دیگر (1)',
        'Enter the applicants Other Language Reading Score(1)':'نمره ریدینگ زبان خود را وارد نمایید (1)',
        'Other Language Writing Score(1)':' نمره رایتینگ زبان دیگر (1)',
        'Enter the applicants Other Language Writing Score(1)':'نمره رایتینگ زبان خود را وارد نمایید (1)',
        'Other Language Listening Score(1)':'نمره لیسینینگ زبان دیگر (1)',
        'Enter the applicants Other Language Listening Score(1)':'نمره لیسینینگ زبان خود را وارد نمایید (1)',
        'Other Language Speacking Score(1)':'نمره اسپیکینگ زبان دیگر (1)',
        'Enter the applicants Other Language Speacking Score(1)':'نمره اسپیکینگ زبان خود را وارد نمایید (1)',
        'Other Language (2)':'مدرک دیگر (2)',
        'The total score of the Other Language degree(2)':'نمره کل مدرک زبان دیگر (2)',
        'Enter the applicants total score of the Other Language degree(2)':'نمره کل مدرک زبان خود را وارد نمایید (2)',
        'Other Language Reading Score(2)':'نمره ریدینگ زبان دیگر (2)',
        'Enter the applicants Other Language Reading Score(2)':'نمره ریدینگ زبان خود را وارد نمایید (2)',
        'Other Language Writing Score(2)':' نمره رایتینگ زبان دیگر (2)',
        'Enter the applicants Other Language Writing Score(2)':'نمره رایتینگ زبان خود را وارد نمایید (2)',
        'Other Language Listening Score(2)':'نمره لیسینینگ زبان دیگر (2)',
        'Enter the applicants Other Language Listening Score(2)':'نمره لیسینینگ زبان خود را وارد نمایید (2)',
        'Other Language Speacking Score(2)':'نمره اسپیکینگ زبان دیگر (2)',
        'Enter the applicants Other Language Speacking Score(2)':'نمره اسپیکینگ زبان خود را وارد نمایید (2)',
        'Other Language (3)':'مدرک دیگر (3)',
        'The total score of the Other Language degree(3)':'نمره کل مدرک زبان دیگر (3)',
        'Enter the applicants total score of the Other Language degree(3)':'نمره کل مدرک زبان خود را وارد نمایید (3)',
        'Other Language Reading Score(3)':'نمره ریدینگ زبان دیگر (3)',
        'Enter the applicants Other Language Reading Score(3)':'نمره ریدینگ زبان خود را وارد نمایید (3)',
        'Other Language Writing Score(3)':' نمره رایتینگ زبان دیگر (3)',
        'Enter the applicants Other Language Writing Score(3)':'نمره رایتینگ زبان خود را وارد نمایید (3)',
        'Other Language Listening Score(3)':'نمره لیسینینگ زبان دیگر (3)',
        'Enter the applicants Other Language Listening Score(3)':'نمره لیسینینگ زبان خود را وارد نمایید (3)',
        'Other Language Speacking Score(3)':'نمره اسپیکینگ زبان دیگر (3)',
        'Enter the applicants Other Language Speacking Score(3)':'نمره اسپیکینگ زبان خود را وارد نمایید (3)',
        'Date of Other Language degree (1)':'تاریخ اخذ مدرک زبان (1)',
        'Date of Other Language degree (2)':'تاریخ اخذ مدرک زبان (2)',
        'Date of Other Language degree (3)':'تاریخ اخذ مدرک زبان (3)',
        'Foreign Language Documents':'مدارک زبان خارجه',
        'Other Language(1)':'مدرک دیگر زبان (1)',
        'Other Language(2)':'مدرک دیگر زبان (2)',
        'Other Language(3)':'مدرک دیگر زبان (3)',
        'Language Certificates':'مدارک زبان خارجه',
        'Back to Applicant information':'برگشت به قسمت اطلاعات متقاضی',
        'Step 2-2':'مرحله 2 از 2',
        'Applicant Educational Information':'اطلاعات تحصیلی متقاضی',
        'Last Degree and Field of Study':'آخرین مدرک و رشته تحصیلی',
        'Last Educational Certificate':'آخرین مدرک تحصیلی',
        'Enter the applicants Last Educational Certificate':'آخرین مدرک تحصیلی خود را وارد نمایید',
        '(Required)':'(اجباری)',
        'Enter the applicants Field of Study':'رشته تحصیلی خود را وارد نمایید.',
        'High School':'دبیرستان',
        'Date of Entering High School':'تاریخ ورود به دبیرستان',
        'Enter the applicants Date of Entering High School':'تاریخ ورود خود به دبیرستان را وارد نمایید.',
        'Graduation Date':'تاریخ فارغ التحصیلی',
        'Enter the applicants Graduation Date':'تاریخ فارغ التحصیلی را وارد نمایید',
        'Field of Study in Diploma':'رشته تحصیلی دیپلم',
        'Enter the applicants Field of Study in Diploma':'رشته تحصیلی دیپلم خود را وارد نمایید.',
        'Associate Degree':'فوق دیپلم',
        'Date of Entering University':'تاریخ ورود به دانشگاه',
        'Enter the applicants Date of Entering University':'تاریخ ورود به دانشگاه را وارد نمایید',
        'University Name':'نام دانشگاه',
        'Enter the applicants University Name':'نام دانشگاه خود را وارد نمایید',
        'University Plcae':'محل دانشگاه',
        'Enter the applicants University Plcae':'محل دانشگاهخود را وارد نمایید',
        'Bachelor Degree':'لیسانس',
        'Masters Degree':'فوق لیسانس',
        'P.H.D Degree':'دکتری',
        'Post-Doctoral Degree':'فوق دکتری',
        'Non-university Certificate':'مدارک غیر دانشگاهی',
        'Non-university Certificate Name(1)':'نام مدرک غیر دانشگاهی (1)',
        'Enter the applicants Non-university Certificate Name(1)':'نام مدرک غیردانشگاهی خود را وارد نمایید (1)',
        'Non-university Certificate Name(2)':'نام مدرک غیر دانشگاهی (2)',
        'Enter the applicants Non-university Certificate Name(2)':'نام مدرک غیردانشگاهی خود را وارد نمایید (2)',
        'Non-university Certificate Name(3)':'نام مدرک غیر دانشگاهی (3)',
        'Enter the applicants Non-university Certificate Name(3)':'نام مدرک غیردانشگاهی خود را وارد نمایید (3)',
        'Non-university Certificate Name(4)':'نام مدرک غیر دانشگاهی (4)',
        'Enter the applicants Non-university Certificate Name(4)':'نام مدرک غیردانشگاهی خود را وارد نمایید (4)',
        'Non-university Certificate Name(5)':'نام مدرک غیر دانشگاهی (5)',
        'Enter the applicants Non-university Certificate Name(5)':'نام مدرک غیردانشگاهی خود را وارد نمایید (5)',
        'Non-university Certificate Name(6)':'نام مدرک غیر دانشگاهی (6)',
        'Enter the applicants Non-university Certificate Name(6)':'نام مدرک غیردانشگاهی خود را وارد نمایید (6)',
        'Educational Documents':'مدارک تحصیلی - آموزشی',
        'Pre-High School (1)':'مدارک قبل از دبیرستان (1)',
        'Pre-High School (2)':'مدارک قبل از دبیرستان (2)',
        'Pre-High School (3)':'مدارک قبل از دبیرستان (3)',
        'High School(transcripts) 1th':'ریز نمرات سال اول دبیرستان',
        'High School(transcripts) 2th':'ریز نمرات سال دوم دبیرستان',
        'High School(transcripts) 3th':'ریز نمرات سال سوم دبیرستان',
        'High School(transcripts) 4th':'ریز نمرات سال چهارم دبیرستان',
        'Diploma Certificate':'دیپلم',
        'Associate Diploma Transcripts':'ریز نمرات فوق دیپلم',
        'Associate Cerificate':'فوق دیپلم',
        'Bachlor Transcripts':'ریز نمرات لیسانس',
        'Bachlor Cerificate':'لیسانس',
        'Masters Transcripts':'ریز نمرات کاشناسی ارشد',
        'Masters Cerificate':'فوق لیسانس',
        'P.H.D Transcripts':'ریز نمرات دکتری',
        'P.H.D Cerificate':'دکتری',
        'Post-Doctoral Transcripts':'ریز نمرات فوق دکتری',
        'Post-Doctoral Cerificate':'فوق دکتری',
        'ISI Papers':'مقاله یا ISI',
        'CV':'رزومه یا cv',
        'Motivation Letter':'انگیزش نامه یا sop',
        'Non-University Degrees (1)':'مدرک غیر دانشگاهی (1)',
        'Non-University Degrees (2)':'مدرک غیر دانشگاهی (2)',
        'Non-University Degrees (3)':'مدرک غیر دانشگاهی (3)',
        'Non-University Degrees (4)':'مدرک غیر دانشگاهی (4)',
        'Non-University Degrees (5)':'مدرک غیر دانشگاهی (5)',
        'Non-University Degrees (6)':'مدرک غیر دانشگاهی (6)',
        'Scientific Documents of Awards or Festivals':'مدارک علمی جوایز یا جشنواره ',
        'Other Documents':'مدارک دیگر',
        'Applicant Job Information':'اطلاعات شغلی متقاضی',
        'Work Experience':'سابقه شغلی',
        'Work Experience by year':'سابقه شغلی بر حسب سال',
        'Enter the applicants Work experience by year':'سابقه شغلی خود را بر حسب سال وارد نمایید',
        'Job':'شغل',
        'Date of First Job':'تاریخ اولین شغل',
        'Enter the applicants Date of First Job':'تاریخ اولین شغل خود را وارد نمایید',
        'Date of 2nd Job':'تاریخ دومین شغل',
        'Enter the applicants Date of 2nd Job':'تاریخ دومین شغل خود را وارد نمایید',
        'Date of 3rd Job':'تاریخ سومین شغل',
        'Enter the applicants Date of 3rd Job':'تاریخ سومین شغل خود را وارد نمایید',
        'Date of 4th Job':'تاریخ چهارمین شغل',
        'Enter the applicants Date of 4th Job':'تاریخ چهارمین شغل خود را وارد نمایید',
        'Date of 5th Job':'تاریخ پنجمین شغل',
        'Enter the applicants Date of 5th Job':'تاریخ پنجمین شغل را وارد نمایید',
        'Date of 6th Job':'تاریخ ششمین شغل',
        'Enter the applicants Date of 6th Job':'تاریخ ششمین شغل خود را وارد نمایید',
        'Post (1)':'سمت در شغل (1)',
        'Enter the applicants Post in Job (1)':'سمت خود در شغل را وارد نمایید. (1)', 
        'Post (2)':'سمت در شغل (2)',
        'Enter the applicants Post in Job (2)':'سمت خود در شغل را وارد نمایید. (2)', 
        'Post (3)':'سمت در شغل (3)',
        'Enter the applicants Post in Job (3)':'سمت خود در شغل را وارد نمایید. (3)', 
        'Post (4)':'سمت در شغل (4)',
        'Enter the applicants Post in Job (4)':'سمت خود در شغل را وارد نمایید. (4)', 
        'Post (5)':'سمت در شغل (5)',
        'Enter the applicants Post in Job (5)':'سمت خود در شغل را وارد نمایید. (5)', 
        'Post (6)':'سمت در شغل (6)',
        'Enter the applicants Post in Job (6)':'سمت خود در شغل را وارد نمایید. (6)', 
        'Workplace (1)':'محل کار (1)',
        'Enter the applicants Workplace (1)':'محل کار خود را وارد نمایید (1)',
        'Workplace (2)':'محل کار (2)',
        'Enter the applicants Workplace (2)':'محل کار خود را وارد نمایید (2)',
        'Workplace (3)':'محل کار (3)',
        'Enter the applicants Workplace (3)':'محل کار خود را وارد نمایید (3)',
        'Workplace (4)':'محل کار (4)',
        'Enter the applicants Workplace (4)':'محل کار خود را وارد نمایید (4)',
        'Workplace (5)':'محل کار (5)',
        'Enter the applicants Workplace (5)':'محل کار خود را وارد نمایید (5)',
        'Workplace (6)':'محل کار (6)',
        'Enter the applicants Workplace (6)':'محل کار خود را وارد نمایید (6)',
        'Permissions':'مجوزها',
        'Date of Business License (1)':'تاریخ صدور جواز کسب(1)',
        'Enter the applicants Date of business license (1)':'تاریخ صدور مجوز کسب خود را وارد نمایید (1)',
        'Date of Business License (2)':'تاریخ صدور جواز کسب(2)',
        'Enter the applicants Date of business license (2)':'تاریخ صدور مجوز کسب خود را وارد نمایید (2)',
        'Date of Business License (3)':'تاریخ صدور جواز کسب(3)',
        'Enter the applicants Date of business license (3)':'تاریخ صدور مجوز کسب خود را وارد نمایید (3)',
        'Date of Business License (4)':'تاریخ صدور جواز کسب(4)',
        'Enter the applicants Date of business license (4)':'تاریخ صدور مجوز کسب خود را وارد نمایید (4)',
        'Date of Business License (5)':'تاریخ صدور جواز کسب(5)',
        'Enter the applicants Date of business license (5)':'تاریخ صدور مجوز کسب خود را وارد نمایید (5)',
        'Date of Business License (6)':'تاریخ صدور جواز کسب(6)',
        'Enter the applicants Date of business license (6)':'تاریخ صدور مجوز کسب خود را وارد نمایید (6)',
        'Type and Name of License (1)':'نوع و نام مجوز کسب (1)',
        'Enter the applicants Type and Name of License (1)':'نوع مجوز و نام آنرا وارد نمایید (1)',
        'Type and Name of License (2)':'نوع و نام مجوز کسب (2)',
        'Enter the applicants Type and Name of License (2)':'نوع مجوز و نام آنرا وارد نمایید (2)',
        'Type and Name of License (3)':'نوع و نام مجوز کسب (3)',
        'Enter the applicants Type and Name of License (3)':'نوع مجوز و نام آنرا وارد نمایید (3)',
        'Type and Name of License (4)':'نوع و نام مجوز کسب (4)',
        'Enter the applicants Type and Name of License (4)':'نوع مجوز و نام آنرا وارد نمایید (4)',
        'Type and Name of License (5)':'نوع و نام مجوز کسب (5)',
        'Enter the applicants Type and Name of License (5)':'نوع مجوز و نام آنرا وارد نمایید (5)',
        'Type and Name of License (6)':'نوع و نام مجوز کسب (6)',
        'Enter the applicants Type and Name of License (6)':'نوع مجوز و نام آنرا وارد نمایید (6)',
        'License Expiration Date (1)':'تاریخ اتمام مجوز (1)',
        'Enter the applicants License Expiration Date (1)':'تاریخ اتمام مجوز خود را وارد نمایید (1)',
        'License Expiration Date (2)':'تاریخ اتمام مجوز (2)',
        'Enter the applicants License Expiration Date (2)':'تاریخ اتمام مجوز خود را وارد نمایید (2)',
        'License Expiration Date (3)':'تاریخ اتمام مجوز (3)',
        'Enter the applicants License Expiration Date (3)':'تاریخ اتمام مجوز خود را وارد نمایید (3)',
        'License Expiration Date (4)':'تاریخ اتمام مجوز (4)',
        'Enter the applicants License Expiration Date (4)':'تاریخ اتمام مجوز خود را وارد نمایید (4)',
        'License Expiration Date (5)':'تاریخ اتمام مجوز (5)',
        'Enter the applicants License Expiration Date (5)':'تاریخ اتمام مجوز خود را وارد نمایید (5)',
        'License Expiration Date (6)':'تاریخ اتمام مجوز (6)',
        'Enter the applicants License Expiration Date (6)':'تاریخ اتمام مجوز خود را وارد نمایید (6)',
        'Job qualifications':'مدارک شغلی',
        'Certificate of Employment':'گواهی اشتغال به کار',
        'Insurance':'بیمه',
        'Business License':'جواز کسب',
        'Other Permissions':'مجوزهای دیگر',
        'Business Cards':'کارت بازرگانی',
        'Company Documents':'مدارک شرکت',
        'Factory Documents':'مدارک تولیدی یا کارخانه',
        'CV':'رزومه یا cv',
        'Tax Returns':'اظهارنامه مالیاتی',
        'Payroll':'فیش حقوقی',
        'Employment Warrant':'حکم کارگزینی',
        'Mission Warrant':'حکم ماموریتی',
        'Responsibility Warrant':'حکم مسئولیتی',
        'Other Documents':'مدارک دیگر',
        'Applicant Financial Information':'اطلاعات مالی فرد متقاضی',
        'Turnover Number':'عدد گردش مالی',
        'Turnover':'گردش مالی',
        'Enter the applicants Turnover':'گردش مالی خود را وارد نمایید',
        'Start Date of Turnover':'تاریخ ابتدای گردش مالی',
        'Enter the applicants Start Date of Turnover':'تاریخ ابتدای گردش مالی خود را وارد نمایید.',
        'End Date of Turnover':'تاریخ انتهای گردش مالی',
        'Enter the applicants End Date of Turnover':'تاریخ انتهای گردش مالی خود را وارد نمایید.',
        'Financial Ability':'تمکن مالی',
        'Enter the applicants Financial Ability':'تمکن مالی خود را وارد نمایید.',
        'Date of Financial':'تاریخ تمکن',
        'Enter the applicants Date of Financial':'تاریخ تمکن مالی خود را وارد نمایید.',
        'Financial Currency Unit':'واحد پولی تمکن',
        'Enter the applicants Financial Currency Unit':'واحد پولی تمکن مالی خود راوارد نمایید',
        'Total Assets Amount':' مجموع کل دارایی',
        'Total Assets Amount in Number':'حدود مجموع دارایی به عدد',
        'Enter the applicants Total Assets Amount in Number':'حدود مجموع دارایی را به عدد وارد نمایید',
        'Calculated Currency':'واحد پولی محاسبه شده',
        'Enter the applicants Calculated Currency':'واحد پولی محاسبه شده را وارد نمایید',
        'Financial Documents':'اسناد مالی',
        'Turnover (1)':'گردش حساب (1)',
        'Turnover (2)':'گردش حساب (2)',
        'Financial Ability (1)':'تمکن مالی (1)',
        'Financial Ability (2)':'تمکن مالی (2)',
        'Property Document (1)':'سند ملکی (1)',
        'Property Document (2)':'سند ملکی (2)',
        'Property Document (3)':'سند ملکی (3)',
        'Factory Document':'سند کارخانه',
        'Shop Document':'سند مغازه',
        'Certificate of Stock Exchange':'گواهینامه یا سند بورسی',
        'Lease':'اجاره نامه',
        'Financial Supporter Letter (1)':'نامه ساپورتر مالی (1)',
        'Financial Supporter Letter (2)':'نامه ساپورتر مالی (2)',
        'Applicant Spouse Information':'اطلاعات همســـــــــــر متقاضی',
        'Applicant Spouse Job Information':'اطلاعات شغلی همســــــر متقاضی',
        'Applicants Spouse Financial Information':'اطلاعات مالی همســـــــر متقاضی',
        'Applicants Spouse Educational Information':'اطلاعات تحصیلی همســـــر متقاضی',
        'Applicant Spouse Foreign Language Information':'اطلاعات زبان خارجه همســـــر متقاضی',
        'Marriage Certificate Number':'شماره سند ازدواج',
        'Enter the applicants Marriage Certificate Number':'شماره سند ازدواج خود را وارد نمایید',
        'Date of Marriage':'تاریخ ازدواج',
        'Enter the applicants Date of Marriage':'تاریخ ازدواج خود را وارد نمایید',
        'Date of Divorce or Death':'تاریخ طلاق یا فوت همسر',
        'Enter the applicants Date of Divorce or Death':'تاریخ طلاق یا فوت همسر خ.د را وارد نمایید',
        'Couples Documents':'مدارک زوجین',
        'Marriage Certificate':'سند ازدواج',
        'Exit Permission':'اجازه خروج',
        'Divorce or Death Letter':'نامه یا سند فوت یا طلاق',
        'Step 6':'مرحله ششم',
        'Applicant Family Information':'اطلاعات خانواده متقاضی',
        'Applicants Spouse Family Information':'اطلاعات خانواده همسر متقاضی',
        'Prime Family Information':'اطلاعات خانواده درجه یک',
        'Father':'پدر',
        'Father`s Name':'نام پدر',
        'Enter the applicants Father Name':'نام پدر خود راوارد نمایید',
        'Father`s Family':'نام خانوادگی پدر',
        'Enter the applicants Father Family':'نام حانوادگی پدر خود را وارد نمایید',
        'Father`s Job':'شغل پدر',
        'Enter the applicants Father Job':'شغل پدر خود را وارد نمایید',
        'Father`s Birth Day':'تاریخ تولد پدر',
        'Enter the applicants Father Birth Day':'تاریخ تولد پدر خود را وارد نمایید',
        'Father`s Residence Address':'آدرس محل سکونت پدر',
        'Enter the applicants Fathers Residence Address':'آدرس محل سکونت پدر خود را وارد نمایید',
        'Father`s Postal Code':'کد پستی پدر',
        'Enter the applicants Father Postal Code':'کد پستی پدر خود را وارد نمایید',
        'Father`s Phone Call':'تلفن تماس پدر',
        'Enter the applicants Father Phone Call':'تلفن تماس پدر خود را وارد نمایید',
        'Father`s Education':'تحصیلات پدر',
        'Enter the applicants Father`s Education':'تحصیلات پدر خود را وارد نمایید',
        'Father`s Financial Ability':'تمکن مالی پدر',
        'Enter the applicants Father`s Financial Ability':'تمکن مالی پدر خود را وارد نمایید',
        'Father`s Date of Divorce':'تاریخ طلاق پدر',
        'Enter the applicants Father`s Date of Divorce':'تاریخ طلاق پدر خود را وارد نمایید',
        'Father`s Date of Death':'تاریخ فوت پدر',
        'Enter the applicants Father`s Date of Death':'تاریخ فوت پدر خود را وارد نمایید',
        'Father`s Foreign Trip (1)':'سفر خارجه پدر (1)',
        'Father`s Foreign Trip (2)':'سفر خارجه پدر (2)',
        'Father`s Foreign Trip (3)':'سفر خارجه پدر (3)',
        'Father`s Foreign Trip (4)':'سفر خارجه پدر (4)',
        'Father`s Foreign Trip (5)':'سفر خارجه پدر (5)',
        'Father`s Foreign Trip (6)':'سفر خارجه پدر (6)',
        'Father`s Foreign Trip (7)':'سفر خارجه پدر (7)',
        'Father`s Foreign Trip (8)':'سفر خارجه پدر (8)',
        'Father`s Foreign Trip (9)':'سفر خارجه پدر (9)',
        'Enter the applicants Father`s Foreign Trip':'صفرهای خارجه پدر خود را وارد نمایید',

        'Mother':'مادر',
        'Mother`s Name':'نام مادر',
        'Enter the applicants Mother Name':'نام مادر خود راوارد نمایید',
        'Mother`s Family':'نام خانوادگی مادر',
        'Enter the applicants Mother Family':'نام حانوادگی مادر خود را وارد نمایید',
        'Mother`s Job':'شغل مادر',
        'Enter the applicants Mother Job':'شغل مادر خود را وارد نمایید',
        'Mother`s Birth Day':'تاریخ تولد مادر',
        'Enter the applicants Mother Birth Day':'تاریخ تولد مادر خود را وارد نمایید',
        'Mother`s Residence Address':'آدرس محل سکونت مادر',
        'Enter the applicants Mothers Residence Address':'آدرس محل سکونت مادر خود را وارد نمایید',
        'Mother`s Postal Code':'کد پستی مادر',
        'Enter the applicants Mother Postal Code':'کد پستی مادر خود را وارد نمایید',
        'Mother`s Phone Call':'تلفن تماس مادر',
        'Enter the applicants Mother Phone Call':'تلفن تماس مادر خود را وارد نمایید',
        'Mother`s Education':'تحصیلات مادر',
        'Enter the applicants Mother`s Education':'تحصیلات مادر خود را وارد نمایید',
        'Mother`s Financial Ability':'تمکن مالی مادر',
        'Enter the applicants Mother`s Financial Ability':'تمکن مالی مادر خود را وارد نمایید',
        'Mother`s Date of Divorce':'تاریخ طلاق مادر',
        'Enter the applicants Mother`s Date of Divorce':'تاریخ طلاق مادر خود را وارد نمایید',
        'Mother`s Date of Death':'تاریخ فوت مادر',
        'Enter the applicants Mother`s Date of Death':'تاریخ فوت مادر خود را وارد نمایید',
        'Mother`s Foreign Trip (1)':'سفر خارجه مادر (1)',
        'Mother`s Foreign Trip (2)':'سفر خارجه مادر (2)',
        'Mother`s Foreign Trip (3)':'سفر خارجه مادر (3)',
        'Mother`s Foreign Trip (4)':'سفر خارجه مادر (4)',
        'Mother`s Foreign Trip (5)':'سفر خارجه مادر (5)',
        'Mother`s Foreign Trip (6)':'سفر خارجه مادر (6)',
        'Mother`s Foreign Trip (7)':'سفر خارجه مادر (7)',
        'Mother`s Foreign Trip (8)':'سفر خارجه مادر (8)',
        'Mother`s Foreign Trip (9)':'سفر خارجه مادر (9)',
        'Enter the applicants Mother`s Foreign Trip':'صفرهای خارجه مادر خود را وارد نمایید',

        'Brother (1)':'برادر (1)',
        '1th Brother`s Name':'نام اولین برادر',
        'Enter the applicants 1th Brother Name':'نام اولین برادر خود راوارد نمایید',
        '1th Brother`s Family':'نام خانوادگی اولین برادر',
        'Enter the applicants 1th Brother Family':'نام حانوادگی اولین برادر خود را وارد نمایید',
        '1th Brother`s Job':'شغل اولین برادر',
        'Enter the applicants 1th Brother Job':'شغل اولین برادر خود را وارد نمایید',
        '1th Brother`s Birth  Day':'تاریخ تولد اولین برادر',
        'Enter the applicants  1th Brother Birth Day':'تاریخ تولد اولین برادر خود را وارد نمایید',
        '1th Brother`s Reside nce Address':'آدرس محل سکونت اولین برادر',
        'Enter the applicants  1th Brothers Residence Address':'آدرس محل سکونت اولین برادر خود را وارد نمایید',
        '1th Brother`s Postal  Code':'کد پستی اولین برادر',
        'Enter the applicants  1th Brother Postal Code':'کد پستی اولین برادر خود را وارد نمایید',
        '1th Brother`s Phone  Call':'تلفن تماس اولین برادر',
        'Enter the applicants  1th Brother Phone Call':'تلفن تماس اولین برادر خود را وارد نمایید',
        '1th Brother`s Education':'تحصیلات اولین برادر',
        'Enter the applicants 1th Brother`s Education':'تحصیلات اولین برادر خود را وارد نمایید',
        '1th Brother`s Financial Ability':'تمکن مالی اولین برادر',
        'Enter the applicants 1th Brother`s Financial Ability':'تمکن مالی اولین برادر خود را وارد نمایید',
        '1th Brother`s Date of Divorce':'تاریخ طلاق اولین برادر',
        'Enter the applicants 1th Brother`s Date of Divorce':'تاریخ طلاق اولین برادر خود را وارد نمایید',
        '1th Brother`s Date of Death':'تاریخ فوت اولین برادر',
        'Enter the applicants 1th Brother`s Date of Death':'تاریخ فوت اولین برادر خود را وارد نمایید',
        '1th Brother`s Foreign Trip (1)':'سفر خارجه اولین برادر (1)',
        '1th Brother`s Foreign Trip (2)':'سفر خارجه اولین برادر (2)',
        '1th Brother`s Foreign Trip (3)':'سفر خارجه اولین برادر (3)',
        '1th Brother`s Foreign Trip (4)':'سفر خارجه اولین برادر (4)',
        '1th Brother`s Foreign Trip (5)':'سفر خارجه اولین برادر (5)',
        '1th Brother`s Foreign Trip (6)':'سفر خارجه اولین برادر (6)',
        '1th Brother`s Foreign Trip (7)':'سفر خارجه اولین برادر (7)',
        '1th Brother`s Foreign Trip (8)':'سفر خارجه اولین برادر (8)',
        '1th Brother`s Foreign Trip (9)':'سفر خارجه اولین برادر (9)',
        'Enter the applicants 1th Brother`s Foreign Trip':'صفرهای خارجه اولین برادر خود را وارد نمایید',

        'Brother (2)':'برادر (2)',
        '2nd Brother`s Name':'نام دومین برادر',
        'Enter the applicants 2nd Brother Name':'نام دومین برادر خود راوارد نمایید',
        '2nd Brother`s Family':'نام خانوادگی دومین برادر',
        'Enter the applicants 2nd Brother Family':'نام حانوادگی دومین برادر خود را وارد نمایید',
        '2nd Brother`s Job':'شغل دومین برادر',
        'Enter the applicants 2nd Brother Job':'شغل دومین برادر خود را وارد نمایید',
        '2nd Brother`s Birth  Day':'تاریخ تولد دومین برادر',
        'Enter the applicants  2nd Brother Birth Day':'تاریخ تولد دومین برادر خود را وارد نمایید',
        '2nd Brother`s Reside nce Address':'آدرس محل سکونت دومین برادر',
        'Enter the applicants  2nd Brothers Residence Address':'آدرس محل سکونت دومین برادر خود را وارد نمایید',
        '2nd Brother`s Postal  Code':'کد پستی دومین برادر',
        'Enter the applicants  2nd Brother Postal Code':'کد پستی دومین برادر خود را وارد نمایید',
        '2nd Brother`s Phone  Call':'تلفن تماس دومین برادر',
        'Enter the applicants  2nd Brother Phone Call':'تلفن تماس دومین برادر خود را وارد نمایید',
        '2nd Brother`s Education':'تحصیلات دومین برادر',
        'Enter the applicants 2nd Brother`s Education':'تحصیلات دومین برادر خود را وارد نمایید',
        '2nd Brother`s Financial Ability':'تمکن مالی دومین برادر',
        'Enter the applicants 2nd Brother`s Financial Ability':'تمکن مالی دومین برادر خود را وارد نمایید',
        '2nd Brother`s Date of Divorce':'تاریخ طلاق دومین برادر',
        'Enter the applicants 2nd Brother`s Date of Divorce':'تاریخ طلاق دومین برادر خود را وارد نمایید',
        '2nd Brother`s Date of Death':'تاریخ فوت دومین برادر',
        'Enter the applicants 2nd Brother`s Date of Death':'تاریخ فوت دومین برادر خود را وارد نمایید',
        '2nd Brother`s Foreign Trip (1)':'سفر خارجه دومین برادر (1)',
        '2nd Brother`s Foreign Trip (2)':'سفر خارجه دومین برادر (2)',
        '2nd Brother`s Foreign Trip (3)':'سفر خارجه دومین برادر (3)',
        '2nd Brother`s Foreign Trip (4)':'سفر خارجه دومین برادر (4)',
        '2nd Brother`s Foreign Trip (5)':'سفر خارجه دومین برادر (5)',
        '2nd Brother`s Foreign Trip (6)':'سفر خارجه دومین برادر (6)',
        '2nd Brother`s Foreign Trip (7)':'سفر خارجه دومین برادر (7)',
        '2nd Brother`s Foreign Trip (8)':'سفر خارجه دومین برادر (8)',
        '2nd Brother`s Foreign Trip (9)':'سفر خارجه دومین برادر (9)',
        'Enter the applicants 2nd Brother`s Foreign Trip':'صفرهای خارجه دومین برادر خود را وارد نمایید',

        'Brother (3)':'برادر (3)',
        '3rd Brother`s Name':'نام سومین برادر',
        'Enter the applicants 3rd Brother Name':'نام سومین برادر خود راوارد نمایید',
        '3rd Brother`s Family':'نام خانوادگی سومین برادر',
        'Enter the applicants 3rd Brother Family':'نام حانوادگی سومین برادر خود را وارد نمایید',
        '3rd Brother`s Job':'شغل سومین برادر',
        'Enter the applicants 3rd Brother Job':'شغل سومین برادر خود را وارد نمایید',
        '3rd Brother`s Birth  Day':'تاریخ تولد سومین برادر',
        'Enter the applicants  3rd Brother Birth Day':'تاریخ تولد سومین برادر خود را وارد نمایید',
        '3rd Brother`s Reside nce Address':'آدرس محل سکونت سومین برادر',
        'Enter the applicants  3rd Brothers Residence Address':'آدرس محل سکونت سومین برادر خود را وارد نمایید',
        '3rd Brother`s Postal  Code':'کد پستی سومین برادر',
        'Enter the applicants  3rd Brother Postal Code':'کد پستی سومین برادر خود را وارد نمایید',
        '3rd Brother`s Phone  Call':'تلفن تماس سومین برادر',
        'Enter the applicants  3rd Brother Phone Call':'تلفن تماس سومین برادر خود را وارد نمایید',
        '3rd Brother`s Education':'تحصیلات سومین برادر',
        'Enter the applicants 3rd Brother`s Education':'تحصیلات سومین برادر خود را وارد نمایید',
        '3rd Brother`s Financial Ability':'تمکن مالی سومین برادر',
        'Enter the applicants 3rd Brother`s Financial Ability':'تمکن مالی سومین برادر خود را وارد نمایید',
        '3rd Brother`s Date of Divorce':'تاریخ طلاق سومین برادر',
        'Enter the applicants 3rd Brother`s Date of Divorce':'تاریخ طلاق سومین برادر خود را وارد نمایید',
        '3rd Brother`s Date of Death':'تاریخ فوت سومین برادر',
        'Enter the applicants 3rd Brother`s Date of Death':'تاریخ فوت سومین برادر خود را وارد نمایید',
        '3rd Brother`s Foreign Trip (1)':'سفر خارجه سومین برادر (1)',
        '3rd Brother`s Foreign Trip (2)':'سفر خارجه سومین برادر (2)',
        '3rd Brother`s Foreign Trip (3)':'سفر خارجه سومین برادر (3)',
        '3rd Brother`s Foreign Trip (4)':'سفر خارجه سومین برادر (4)',
        '3rd Brother`s Foreign Trip (5)':'سفر خارجه سومین برادر (5)',
        '3rd Brother`s Foreign Trip (6)':'سفر خارجه سومین برادر (6)',
        '3rd Brother`s Foreign Trip (7)':'سفر خارجه سومین برادر (7)',
        '3rd Brother`s Foreign Trip (8)':'سفر خارجه سومین برادر (8)',
        '3rd Brother`s Foreign Trip (9)':'سفر خارجه سومین برادر (9)',
        'Enter the applicants 3rd Brother`s Foreign Trip':'صفرهای خارجه سومین برادر خود را وارد نمایید',


        'Sister (1)':'خواهر (1)',
        '1th Sister`s Name':'نام اولین خواهر',
        'Enter the applicants 1th Sister Name':'نام اولین خواهر خود راوارد نمایید',
        '1th Sister`s Family':'نام خانوادگی اولین خواهر',
        'Enter the applicants 1th Sister Family':'نام حانوادگی اولین خواهر خود را وارد نمایید',
        '1th Sister`s Job':'شغل اولین خواهر',
        'Enter the applicants 1th Sister Job':'شغل اولین خواهر خود را وارد نمایید',
        '1th Sister`s Birth  Day':'تاریخ تولد اولین خواهر',
        'Enter the applicants  1th Sister Birth Day':'تاریخ تولد اولین خواهر خود را وارد نمایید',
        '1th Sister`s Reside nce Address':'آدرس محل سکونت اولین خواهر',
        'Enter the applicants  1th Sisters Residence Address':'آدرس محل سکونت اولین خواهر خود را وارد نمایید',
        '1th Sister`s Postal  Code':'کد پستی اولین خواهر',
        'Enter the applicants  1th Sister Postal Code':'کد پستی اولین خواهر خود را وارد نمایید',
        '1th Sister`s Phone  Call':'تلفن تماس اولین خواهر',
        'Enter the applicants  1th Sister Phone Call':'تلفن تماس اولین خواهر خود را وارد نمایید',
        '1th Sister`s Education':'تحصیلات اولین خواهر',
        'Enter the applicants 1th Sister`s Education':'تحصیلات اولین خواهر خود را وارد نمایید',
        '1th Sister`s Financial Ability':'تمکن مالی اولین خواهر',
        'Enter the applicants 1th Sister`s Financial Ability':'تمکن مالی اولین خواهر خود را وارد نمایید',
        '1th Sister`s Date of Divorce':'تاریخ طلاق اولین خواهر',
        'Enter the applicants 1th Sister`s Date of Divorce':'تاریخ طلاق اولین خواهر خود را وارد نمایید',
        '1th Sister`s Date of Death':'تاریخ فوت اولین خواهر',
        'Enter the applicants 1th Sister`s Date of Death':'تاریخ فوت اولین خواهر خود را وارد نمایید',
        '1th Sister`s Foreign Trip (1)':'سفر خارجه اولین خواهر (1)',
        '1th Sister`s Foreign Trip (2)':'سفر خارجه اولین خواهر (2)',
        '1th Sister`s Foreign Trip (3)':'سفر خارجه اولین خواهر (3)',
        '1th Sister`s Foreign Trip (4)':'سفر خارجه اولین خواهر (4)',
        '1th Sister`s Foreign Trip (5)':'سفر خارجه اولین خواهر (5)',
        '1th Sister`s Foreign Trip (6)':'سفر خارجه اولین خواهر (6)',
        '1th Sister`s Foreign Trip (7)':'سفر خارجه اولین خواهر (7)',
        '1th Sister`s Foreign Trip (8)':'سفر خارجه اولین خواهر (8)',
        '1th Sister`s Foreign Trip (9)':'سفر خارجه اولین خواهر (9)',
        'Enter the applicants 1th Sister`s Foreign Trip':'صفرهای خارجه اولین خواهر خود را وارد نمایید',

        'Sister (2)':'خواهر (2)',
        '2nd Sister`s Name':'نام دومین خواهر',
        'Enter the applicants 2nd Sister Name':'نام دومین خواهر خود راوارد نمایید',
        '2nd Sister`s Family':'نام خانوادگی دومین خواهر',
        'Enter the applicants 2nd Sister Family':'نام حانوادگی دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Job':'شغل دومین خواهر',
        'Enter the applicants 2nd Sister Job':'شغل دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Birth  Day':'تاریخ تولد دومین خواهر',
        'Enter the applicants  2nd Sister Birth Day':'تاریخ تولد دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Reside nce Address':'آدرس محل سکونت دومین خواهر',
        'Enter the applicants  2nd Sisters Residence Address':'آدرس محل سکونت دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Postal  Code':'کد پستی دومین خواهر',
        'Enter the applicants  2nd Sister Postal Code':'کد پستی دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Phone  Call':'تلفن تماس دومین خواهر',
        'Enter the applicants  2nd Sister Phone Call':'تلفن تماس دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Education':'تحصیلات دومین خواهر',
        'Enter the applicants 2nd Sister`s Education':'تحصیلات دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Financial Ability':'تمکن مالی دومین خواهر',
        'Enter the applicants 2nd Sister`s Financial Ability':'تمکن مالی دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Date of Divorce':'تاریخ طلاق دومین خواهر',
        'Enter the applicants 2nd Sister`s Date of Divorce':'تاریخ طلاق دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Date of Death':'تاریخ فوت دومین خواهر',
        'Enter the applicants 2nd Sister`s Date of Death':'تاریخ فوت دومین خواهر خود را وارد نمایید',
        '2nd Sister`s Foreign Trip (1)':'سفر خارجه دومین خواهر (1)',
        '2nd Sister`s Foreign Trip (2)':'سفر خارجه دومین خواهر (2)',
        '2nd Sister`s Foreign Trip (3)':'سفر خارجه دومین خواهر (3)',
        '2nd Sister`s Foreign Trip (4)':'سفر خارجه دومین خواهر (4)',
        '2nd Sister`s Foreign Trip (5)':'سفر خارجه دومین خواهر (5)',
        '2nd Sister`s Foreign Trip (6)':'سفر خارجه دومین خواهر (6)',
        '2nd Sister`s Foreign Trip (7)':'سفر خارجه دومین خواهر (7)',
        '2nd Sister`s Foreign Trip (8)':'سفر خارجه دومین خواهر (8)',
        '2nd Sister`s Foreign Trip (9)':'سفر خارجه دومین خواهر (9)',
        'Enter the applicants 2nd Sister`s Foreign Trip':'صفرهای خارجه دومین خواهر خود را وارد نمایید',

        'Sister (3)':'خواهر (3)',
        '3rd Sister`s Name':'نام سومین خواهر',
        'Enter the applicants 3rd Sister Name':'نام سومین خواهر خود راوارد نمایید',
        '3rd Sister`s Family':'نام خانوادگی سومین خواهر',
        'Enter the applicants 3rd Sister Family':'نام حانوادگی سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Job':'شغل سومین خواهر',
        'Enter the applicants 3rd Sister Job':'شغل سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Birth  Day':'تاریخ تولد سومین خواهر',
        'Enter the applicants  3rd Sister Birth Day':'تاریخ تولد سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Reside nce Address':'آدرس محل سکونت سومین خواهر',
        'Enter the applicants  3rd Sisters Residence Address':'آدرس محل سکونت سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Postal  Code':'کد پستی سومین خواهر',
        'Enter the applicants  3rd Sister Postal Code':'کد پستی سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Phone  Call':'تلفن تماس سومین خواهر',
        'Enter the applicants  3rd Sister Phone Call':'تلفن تماس سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Education':'تحصیلات سومین خواهر',
        'Enter the applicants 3rd Sister`s Education':'تحصیلات سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Financial Ability':'تمکن مالی سومین خواهر',
        'Enter the applicants 3rd Sister`s Financial Ability':'تمکن مالی سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Date of Divorce':'تاریخ طلاق سومین خواهر',
        'Enter the applicants 3rd Sister`s Date of Divorce':'تاریخ طلاق سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Date of Death':'تاریخ فوت سومین خواهر',
        'Enter the applicants 3rd Sister`s Date of Death':'تاریخ فوت سومین خواهر خود را وارد نمایید',
        '3rd Sister`s Foreign Trip (1)':'سفر خارجه سومین خواهر (1)',
        '3rd Sister`s Foreign Trip (2)':'سفر خارجه سومین خواهر (2)',
        '3rd Sister`s Foreign Trip (3)':'سفر خارجه سومین خواهر (3)',
        '3rd Sister`s Foreign Trip (4)':'سفر خارجه سومین خواهر (4)',
        '3rd Sister`s Foreign Trip (5)':'سفر خارجه سومین خواهر (5)',
        '3rd Sister`s Foreign Trip (6)':'سفر خارجه سومین خواهر (6)',
        '3rd Sister`s Foreign Trip (7)':'سفر خارجه سومین خواهر (7)',
        '3rd Sister`s Foreign Trip (8)':'سفر خارجه سومین خواهر (8)',
        '3rd Sister`s Foreign Trip (9)':'سفر خارجه سومین خواهر (9)',
        'Enter the applicants 3rd Sister`s Foreign Trip':'صفرهای خارجه سومین خواهر خود را وارد نمایید',
        'Prime Family Documents':'مدارک خانواده درجه یک',
        'Passport (1)':'پاسپورت (1)',
        'Passport (2)':'پاسپورت (2)',
        'Passport (3)':'پاسپورت (3)',
        'Passport (4)':'پاسپورت (4)',
        'Passport (5)':'پاسپورت (5)',
        'Passport (6)':'پاسپورت (6)',
        'Passport (7)':'پاسپورت (7)',
        'Passport (8)':'پاسپورت (8)',
        'Applicant Travel Information':'اطلاعات سفر متقاضی',
        'Applicant Sopuse Travel Information':'اطلاعات صفر همســــــر متقاضی',
        'Foreign Trips':'سفرهای خارجه',
        'Number of Foreign Trips':'تعداد مسافرت های خارجه',
        'Enter the applicants Number of Foreign Trips':'تعداد سفرهای خارج از کشور خود را وارد نمایید',
        'Arrival Travel Date (1)':'تاریخ ورود (1)',
        'Enter the applicants Arrival Travel Date (1)':'تاریخ ورود خود را وارد نمایید (1)',
        'Departure Travel Date (1)':'تاریخ خروج (1)',
        'Enter the applicants Departure Travel Date (1)':'تاریخ خروج خود را وارد نمایید (1)',
        'Travel Location (1)':'کشور مقصد (1)',
        'Enter the applicants Travel Location (1)':'کشور مقصد خود را وارد نمایید (1)',
        'Staying Time (1)':'مدت اقامت (1)',
        'Enter the applicants Staying Time (1)':'مدت اقامت خود را وارد نمایید (1)',
        'Reason for Travel (1)':'دلیل مسافرت (1)',
        'Enter the applicants Reason for Travel (1) ':'دلیل مسافرت خود را وارد نمایید (1)',
        'Cities visited in the destination country (1)':'شهرهایی ملاقات شده در کشور مقصد (1)',
        'Enter the applicants Cities visited in the destination country (1)':'شهرهایی که در کشور مقصد ملاقات نموده اید را وارد نمایید (1)',

        'Arrival Travel Date (2)':'تاریخ ورود (2)',
        'Enter the applicants Arrival Travel Date (2)':'تاریخ ورود خود را وارد نمایید (2)',
        'Departure Travel Date (2)':'تاریخ خروج (2)',
        'Enter the applicants Departure Travel Date (2)':'تاریخ خروج خود را وارد نمایید (2)',
        'Travel Location (2)':'کشور مقصد (2)',
        'Enter the applicants Travel Location (2)':'کشور مقصد خود را وارد نمایید (2)',
        'Staying Time (2)':'مدت اقامت (2)',
        'Enter the applicants Staying Time (2)':'مدت اقامت خود را وارد نمایید (2)',
        'Reason for Travel (2)':'دلیل مسافرت (2)',
        'Enter the applicants Reason for Travel (2) ':'دلیل مسافرت خود را وارد نمایید (2)',
        'Cities visited in the destination country (2)':'شهرهایی ملاقات شده در کشور مقصد (2)',
        'Enter the applicants Cities visited in the destination country (2)':'شهرهایی که در کشور مقصد ملاقات نموده اید را وارد نمایید (2)',

        'Arrival Travel Date (3)':'تاریخ ورود (3)',
        'Enter the applicants Arrival Travel Date (3)':'تاریخ ورود خود را وارد نمایید (3)',
        'Departure Travel Date (3)':'تاریخ خروج (3)',
        'Enter the applicants Departure Travel Date (3)':'تاریخ خروج خود را وارد نمایید (3)',
        'Travel Location (3)':'کشور مقصد (3)',
        'Enter the applicants Travel Location (3)':'کشور مقصد خود را وارد نمایید (3)',
        'Staying Time (3)':'مدت اقامت (3)',
        'Enter the applicants Staying Time (3)':'مدت اقامت خود را وارد نمایید (3)',
        'Reason for Travel (3)':'دلیل مسافرت (3)',
        'Enter the applicants Reason for Travel (3) ':'دلیل مسافرت خود را وارد نمایید (3)',
        'Cities visited in the destination country (3)':'شهرهایی ملاقات شده در کشور مقصد (3)',
        'Enter the applicants Cities visited in the destination country (3)':'شهرهایی که در کشور مقصد ملاقات نموده اید را وارد نمایید (3)',

        'Arrival Travel Date (4)':'تاریخ ورود (4)',
        'Enter the applicants Arrival Travel Date (4)':'تاریخ ورود خود را وارد نمایید (4)',
        'Departure Travel Date (4)':'تاریخ خروج (4)',
        'Enter the applicants Departure Travel Date (4)':'تاریخ خروج خود را وارد نمایید (4)',
        'Travel Location (4)':'کشور مقصد (4)',
        'Enter the applicants Travel Location (4)':'کشور مقصد خود را وارد نمایید (4)',
        'Staying Time (4)':'مدت اقامت (4)',
        'Enter the applicants Staying Time (4)':'مدت اقامت خود را وارد نمایید (4)',
        'Reason for Travel (4)':'دلیل مسافرت (4)',
        'Enter the applicants Reason for Travel (4) ':'دلیل مسافرت خود را وارد نمایید (4)',
        'Cities visited in the destination country (4)':'شهرهایی ملاقات شده در کشور مقصد (4)',
        'Enter the applicants Cities visited in the destination country (4)':'شهرهایی که در کشور مقصد ملاقات نموده اید را وارد نمایید (4)',

        'Schengen':'شنگن',
        'Schengen Number':'شماره شنگن',
        'Enter the applicants Schengen Number':'شماره شنگن خود را وارد نمایید',
        'Schengen Issuance Date':'تاریخ صدور شنگن',
        'Enter the applicants Schengen Issuance Date':'تاریخ صدور شنگن خود را وارد نمایید',

        'Special Visa':'ویزای خاص',
        'Special Visa Number (1)':'شماره ویزای خاص (1)',
        'Enter the applicants Special Visa Number':'شماره ویزای خاص را وارد نمایید',
        'Visa Name (1)':'نام ویزا (1)',
        'Enter the applicants Visa Name':'نام ویزای خود را وارد نمایید',

        'Special Visa Number (2)':'شماره ویزای خاص (2)',
        'Visa Name (2)':'نام ویزا (2)',
        'Special Visa Number (3)':'شماره ویزای خاص (3)',
        'Visa Name (3)':'نام ویزا (3)',
        'Special Visa Number (4)':'شماره ویزای خاص (4)',
        'Visa Name (4)':'نام ویزا (4)',
        'Special Visa Number (5)':'شماره ویزای خاص (5)',
        'Visa Name (5)':'نام ویزا (5)',
        'Special Visa Number (6)':'شماره ویزای خاص (6)',
        'Visa Name (6)':'نام ویزا (6)',

        '- in 20 Character':'- حداکثر در 20 کاراکتر',

        'Residence of The Destination Country':'اقامت در کشور مقصد',
        'Expiry date of residence of the destination country (1)':'تاریخ انقضا اقامت در کشور مقصد (1)',
        'Enter the applicants Expiry date of residence of the destination country':'تاریخ انقضا اقامت در کشور مقصد را وارد نمایید',
        'Expiry date of residence of the destination country (2)':'تاریخ انقضا اقامت در کشور مقصد (2)',
        'Expiry date of residence of the destination country (3)':'تاریخ انقضا اقامت در کشور مقصد (3)',
        
        'Rejected Visa':'ویزای ریجکت شده',
        'Prior Rejection Date (1)':'تاریخ ریجکتی قبل (1)',
        'Enter the applicants Prior Rejection Date':'تاریخ ریجکتی قبل را وارد نمایید',
        'The country you rejected (1)':'کشوری که ریجکت شدید (1)',
        'Enter the applicants The Country You Rejected':'کشوری که ریجکت شدید را وارد نمایید',
        'Rejected Visa Type (1)':'نوع ویزای ریجکتی (1)',
        'Enter the applicants Rejected Visa Type':'نوع ویزای ریجکت شده را وارد نمایید',
        'Date of Application for Rejected Visa (1)':'تاریخ اقدام به ویزای ریجکت شده (1)',
        'Enter the applicants Date of Application for Rejected Visa':'تاریخ اقدام به ویزای ریجکت شده را وارد نمایید',
        'Reason for Rejection (1)':'دلیل ریجکتی (1)',
        'Enter the applicants Reason for Rejection':'دلیل ریجکتی را وارد نمایید',

        'Prior Rejection Date (2)':'تاریخ ریجکتی قبل (2)',
        'The country you rejected (2)':'کشوری که ریجکت شدید (2)',
        'Rejected Visa Type (2)':'نوع ویزای ریجکتی (2)',
        'Date of Application for Rejected Visa (2)':'تاریخ اقدام به ویزای ریجکت شده (2)',
        'Reason for Rejection (2)':'دلیل ریجکتی (2)',

        'Prior Rejection Date (3)':'تاریخ ریجکتی قبل (3)',
        'The country you rejected (3)':'کشوری که ریجکت شدید (3)',
        'Rejected Visa Type (3)':'نوع ویزای ریجکتی (3)',
        'Date of Application for Rejected Visa (3)':'تاریخ اقدام به ویزای ریجکت شده (3)',
        'Reason for Rejection (3)':'دلیل ریجکتی (3)',

        'Prior Rejection Date (4)':'تاریخ ریجکتی قبل (4)',
        'The country you rejected (4)':'کشوری که ریجکت شدید (4)',
        'Rejected Visa Type (4)':'نوع ویزای ریجکتی (4)',
        'Date of Application for Rejected Visa (4)':'تاریخ اقدام به ویزای ریجکت شده (4)',
        'Reason for Rejection (4)':'دلیل ریجکتی (4)',

        'Prior Rejection Date (5)':'تاریخ ریجکتی قبل (5)',
        'The country you rejected (5)':'کشوری که ریجکت شدید (5)',
        'Rejected Visa Type (5)':'نوع ویزای ریجکتی (5)',
        'Date of Application for Rejected Visa (5)':'تاریخ اقدام به ویزای ریجکت شده (5)',
        'Reason for Rejection (5)':'دلیل ریجکتی (5)',

        'Prior Rejection Date (6)':'تاریخ ریجکتی قبل (6)',
        'The country you rejected (6)':'کشوری که ریجکت شدید (6)',
        'Rejected Visa Type (6)':'نوع ویزای ریجکتی (6)',
        'Date of Application for Rejected Visa (6)':'تاریخ اقدام به ویزای ریجکت شده (6)',
        'Reason for Rejection (6)':'دلیل ریجکتی (6)',

        'Applicants Travel Documents':'مدارک مسافرتی متقاضی',
        'Applicants Spouse Travel Documents':'مدارک مسافرتی همســـــــر متقاضی',
        'Old Passport (1)':'پاسپورت قدیمی (1)',
        'Old Passport (2)':'پاسپورت قدیمی (2)',
        'Old Passport (3)':'پاسپورت قدیمی (3)',

        'Special or Revoked Visa':'ویزای خاص یا باطل شده',
        'New Visa in The New Passport':'ویزای جدید در پاسپورت جدید',
        'Residence documents of a specific country':'مدارک اقامتی کشور خاص',
        'Documents related to the rejected file (1)':'مدارک پرونده ریجکت شده (1)',
        'Documents related to the rejected file (2)':'مدارک پرونده ریجکت شده (2)',
        'Documents related to the rejected file (3)':'مدارک پرونده ریجکت شده (3)',
        'Documents related to the rejected file (4)':'مدارک پرونده ریجکت شده (4)',
        'Documents related to the rejected file (5)':'مدارک پرونده ریجکت شده (5)',
        'Documents related to the rejected file (6)':'مدارک پرونده ریجکت شده (6)',
        
        'Details of Foreign Trips':'جزئیات مسافرت های خارجه',

        'Other Information':'اطلاعات دیگر',
        'Your Information`s Title (1)':'عنوان اطلاعات شما (1)',
        'Your Information (1)':'اطلاعات شما (1)',
        'Your Information`s Title (2)':'عنوان اطلاعات شما (2)',
        'Your Information (2)':'اطلاعات شما (2)',
        'Your Information`s Title (3)':'عنوان اطلاعات شما (3)',
        'Your Information (3)':'اطلاعات شما (3)',
        'Enter the applicants Your Information`s Title':'عنوان اطلاعات خود را وارد نمایید',
        'Enter the applicants Your Information':'اطلاعات را وارد نمایید',
        'Other Documents of the Applicant, Spouse and Companion':'مدارک دیگر متقاضی و همسر و همراه ',
        'Patent':'ثبت اختراع',
        'Ideas Submition':'ثبت ایده',
        'Other Qualifications':'مدارک تحصیلی دیگر',
        'Other Job Qualifications':'مدارک شغلی دیگر',
        'Other Financial Documents':'مدارک مالی دیگر',
        'Motivation Letter':'انگیزش نامه یا sop جهت اخذ ویزا و ارائه به سفارت',
        'Other Documents (1)':'مدارک دیگر (1)',
        'Other Documents (2)':'مدارک دیگر (2)',
        'Other Documents (3)':'مدارک دیگر (3)',

        'Applicant Children Information':'اطلاعات فرزندان متقاضی',
        'Applicants Spouse Children Information':'اطلاعات فرزندان همسر متقاضی',
        'Number of Children':'تعداد فرزندان',
        'Enter the applicants Number of Children':'تعداد فرزندان خود را وارد نمایید',
        'Profile of Children':'اطلاعات فرزندان',
        'Boy':'پسر',
        'Girl':'دختر',

        'Child Name (1)':'نام فرزند (1)',
        'Child Family (1)':'نام خانوادگی فرزند (1)',
        'Child Age (1)':'سن فرزند (1)',

        'Child Name (2)':'نام فرزند (2)',
        'Child Family (2)':'نام خانوادگی فرزند (2)',
        'Child Age (2)':'سن فرزند (2)',

        'Child Name (3)':'نام فرزند (3)',
        'Child Family (3)':'نام خانوادگی فرزند (3)',
        'Child Age (3)':'سن فرزند (3)',

        'Child Name (4)':'نام فرزند (4)',
        'Child Family (4)':'نام خانوادگی فرزند (4)',
        'Child Age (4)':'سن فرزند (4)',

        'Child Name (5)':'نام فرزند (5)',
        'Child Family (5)':'نام خانوادگی فرزند (5)',
        'Child Age (5)':'سن فرزند (5)',

        'Child Name (6)':'نام فرزند (6)',
        'Child Family (6)':'نام خانوادگی فرزند (6)',
        'Child Age (6)':'سن فرزند (6)',

        'Child Name (7)':'نام فرزند (7)',
        'Child Family (7)':'نام خانوادگی فرزند (7)',
        'Child Age (7)':'سن فرزند (7)',

        'Child Name (8)':'نام فرزند (8)',
        'Child Family (8)':'نام خانوادگی فرزند (8)',
        'Child Age (8)':'سن فرزند (8)',

        'Child Name (9)':'نام فرزند (9)',
        'Child Family (9)':'نام خانوادگی فرزند (9)',
        'Child Age (9)':'سن فرزند (9)',

        'Child Name (10)':'نام فرزند (10)',
        'Child Family (10)':'نام خانوادگی فرزند (10)',
        'Child Age (10)':'سن فرزند (10)',

        'Child Name (11)':'نام فرزند (11)',
        'Child Family (11)':'نام خانوادگی فرزند (11)',
        'Child Age (11)':'سن فرزند (11)',

        'Child Name (12)':'نام فرزند (12)',
        'Child Family (12)':'نام خانوادگی فرزند (12)',
        'Child Age (12)':'سن فرزند (12)',
  
        'Enter the applicants Child Name':'نام فرزند خود را وارد نمایید',
        'Enter the applicants Child Family':'نام خانوادگی فرزند ود را وارد نمایید',
        'Child Gender':'جنسیت فرزند',
        'Enter the applicants Child`s Gender':'جنسیت فرزند خود را وارد نمایید',
        'Enter the applicants Child Age':'سن فرزند خود را وارد نمایید',

        'Children Documents':'مدارک فرزندان',
        '1th Child Passport':'پاسپورت فرزند اول',
        '1th Child National Card':'کارت ملی فرزند اول',
        '1th Child Birth Certificate':'شناسنامه فرزند اول',
        '1th Child Exit Permission':'اجازه خروج فرزند اول',
        '1th Child Financial Ability':'تمکن مالی فرزند اول',
        '1th Child Certificate of Education':'مدارک تحصیلی فرزند اول',
        '1th Child Other Documents':'مدارک دیگر فرزند اول',

        '2nd Child Passport':'پاسپورت فرزند دوم',
        '2nd Child National Card':'کارت ملی فرزند دوم',
        '2nd Child Birth Certificate':'شناسنامه فرزند دوم',
        '2nd Child Exit Permission':'اجازه خروج فرزند دوم',
        '2nd Child Financial Ability':'تمکن مالی فرزند دوم',
        '2nd Child Certificate of Education':'مدارک تحصیلی فرزند دوم',
        '2nd Child Other Documents':'مدارک دیگر فرزند دوم',

        '3rd Child Passport':'پاسپورت فرزند سوم',
        '3rd Child National Card':'کارت ملی فرزند سوم',
        '3rd Child Birth Certificate':'شناسنامه فرزند سوم',
        '3rd Child Exit Permission':'اجازه خروج فرزند سوم',
        '3rd Child Financial Ability':'تمکن مالی فرزند سوم',
        '3rd Child Certificate of Education':'مدارک تحصیلی فرزند سوم',
        '3rd Child Other Documents':'مدارک دیگر فرزند سوم',

        '4th Child Passport':'پاسپورت فرزند چهارم',
        '4th Child National Card':'کارت ملی فرزند چهارم',
        '4th Child Birth Certificate':'شناسنامه فرزند چهارم',
        '4th Child Exit Permission':'اجازه خروج فرزند چهارم',
        '4th Child Financial Ability':'تمکن مالی فرزند چهارم',
        '4th Child Certificate of Education':'مدارک تحصیلی فرزند چهارم',
        '4th Child Other Documents':'مدارک دیگر فرزند چهارم',

        'Visa Information':'اطلاعات ویزا',
        'Why did you choose the destination country?':'چرا کشور مقصد مورد نظر را انتخاب کردی ؟',
        'Input Your Answer Here...':'پاسخ خود را اینجا وارد نمایید...',
        'Why did you choose this type of visa?':'چرا این نوع ویزا را انتخاب کردی ؟',
        'Why did you choose the desired university?':'چرا دانشگاه مورد نظر را انتخاب کردی ؟',
        'Why did you choose the desired field?':'چرا رشته مورد نظر را انتخاب کردی ؟',
        'What work and study did you do in your country before the visa?':'قبل ویزا در کشور خود مشغول به چه کار و تحصیلی بوده اید ؟',
        'What is your goal in the destination country after the visa?':'بعد از ویزا چه هدفی در کشور مقصد دارید ؟',
        'How do you cover the costs of the destination country?':'چگونه هزینه های کشور مقصد را فراهم میکنید ؟',
        'What is your goal after returning to your home country?':'پس از بازگشتتان به کشور خودتان ، چه هدفی دارید ؟',
        'Do you have relatives or acquaintances or friends in the destination country?':'آیا در کشور مقصد اقوام یا اشنا یا دوستانی را دارید ؟',
        'What is the idea for a startup visa and who designed it?':'ایده مورد نظر جهت ویزای استارت اپ چه موردی است و چه کسی ان را طراحی کرده است ؟',
        'What are the sources of funding for a startup visa?':'تامین بودجه جهت ویزای استارت اپ از چه منابعی است ؟',
        'What is your goal in choosing a startup visa or business visa?':'هدف شما از انتخاب ویزای استارت اپ یا ویزای بیزینسی چی است ؟',
        'Are you seeking asylum?':'آیا هدف پناهندگی دارید ؟',
        'Do you have any refugee relatives in your chosen country?':'آیا اقوام پناهنده شده در کشور انتخابی دارید ؟',
        'Do you already have investments in the destination country?':'آیا سرمایه گذاری از قبل در کشور مقصد دارید ؟',
        'Have you traveled to your chosen country before?':'آیا از قبل به کشور انتخابی سفر کرده اید ؟',
        'What was your goal in obtaining a Schengen visa?':'هدفتان از اخذ ویزای شنگن چه بوده است ؟',

        'Step 2-7':'مرحله هفتم از قسمت دوم',
        '- in 500 Character':'- حداکثر در 500 کاراکتر',
        

        'State':'استان',
        'Enter Your State':'استان خود را وارد نمایید',
        'military service situation':'وضعیت نظام وظیفه',
        'Enter the military service situation':'وضعیت نظام وظیفه خود را مشخص کنید',
        'Applicant Age':'سن متقاضی',
        'Enter the Applicant Age':'سن خود را وارد نمایید',
        'Scientific Documents of Awards or Festivals(1)':'گواهی و تقدیرنامه و مقام هایی که اخذ شده(1)',
        'Enter the applicants Scientific Documents of Awards or Festivals(1)':'نام گواهی و تقدیرنامه و مقام هایی که اخذ شده را وارد نمایید(1)',
        'Research and Studies(1)':'تحقیقات و پژوهش های انجام شده (1)',
        'Enter the applicants Research and Studies(1)':'تحقیقات و پژوهش های انجام شده راوارد نمایید (1)',
        'What languages can you speak?':'به چه زبان هایی قادر به مکالمه هستید؟',
        'Scientific Documents of Awards or Festivals(2)':'گواهی و تقدیرنامه و مقام هایی که اخذ شده(2)',
        'Enter the applicants Scientific Documents of Awards or Festivals(2)':'نام گواهی و تقدیرنامه و مقام هایی که اخذ شده را وارد نمایید(2)',
        'Research and Studies(2)':'تحقیقات و پژوهش های انجام شده (2)',
        'Enter the applicants Research and Studies(2)':'تحقیقات و پژوهش های انجام شده راوارد نمایید (2)',
        'Scientific Documents of Awards or Festivals(3)':'گواهی و تقدیرنامه و مقام هایی که اخذ شده(3)',
        'Enter the applicants Scientific Documents of Awards or Festivals(3)':'نام گواهی و تقدیرنامه و مقام هایی که اخذ شده را وارد نمایید(3)',
        'Research and Studies(3)':'تحقیقات و پژوهش های انجام شده (3)',
        'Enter the applicants Research and Studies(3)':'تحقیقات و پژوهش های انجام شده راوارد نمایید (3)',
        'English':'انگلیسی',
        'Persian':'فارسی',
        'Familiarity with the chosen language':'میزان آشنایی با زبان انتخاب شده',
        'Excellent':'عالی',
        'Good':'خوب',
        'Medium':'متوسط',
        'Weak':'ضعیف',
        'Very weak':'بسیار ضعیف',
        'Enter the applicants Familiarity with the chosen language':'میزان آشنایی خود را وارد نمایید',
        'English Score':'نمره انگلیسی',
        'Enter the applicants English Score':'نمره انگلیسی خود را وارد نمایید',
        'French':'فرانسه',
        'French Score':'نمره فرانسه',
        'Enter the applicants French Score':'نمره فرانسه خود را وارد نمایید',
        'German':'آلمانی',
        'German Score':'نمره آلمانی',
        'Enter the applicants German Score':'نمره آلمانی خود را وارد نمایید',
        'Danish':'دانمارکی',
        'Danish Score':'نمره دانمارکی',
        'Enter the applicants Danish Score':'نمره دانمارکی خود را وارد نمایید',
        'Arabic':'عربی',
        'Arabic Score':'نمره عربی',
        'Enter the applicants Arabic Score':'نمره عربی خود را وارد نمایید',
        'Spanish':'اسپانیایی',
        'Spanish Score':'نمره اسپانیایی',
        'Enter the applicants Spanish Score':'نمره اسپانیایی خود را وارد نمایید',
        'Chinese':'چینی',
        'Chinese Score':'نمره چینی',
        'Enter the applicants Chinese Score':'نمره چینی خود را وارد نمایید',
        'Korean':'کره ای',
        'Korean Score':'نمره کره ای',
        'Enter the applicants Korean Score':'نمره کره ای خود را وارد نمایید',
        'Turnover Number':'میزان گردش مالی',
        'Enter the applicants Turnover Number':'عدد گردش مالی خود را وارد نمایید',
        '(In past 10 years)':'(در 10 سال گذشته)',
        'Job Title (1)':'عنوان شغلی (1)',
        'Enter the Job Title (1)':'عنوان شغلی خود را وارد نمایید (1)',
        'Insurance history or duration of activity in your job (1)':'سابقه بیمه یا مدت زمان فعالیت در شغل خود (1)',
        'Enter the Insurance history or duration of activity in your job (1)':'سابقه بیمه یا مدت زمان فعالیت در شغل خود را وارد نمایید (1)',
        'Job Post (1)':'سمت در شغل (1)',
        'Enter the applicants Job Post (1)':'سمت در شغل خود را وارد نمایید (1)',

        'Job Title (2)':'عنوان شغلی (2)',
        'Enter the Job Title (2)':'عنوان شغلی خود را وارد نمایید (2)',
        'Insurance history or duration of activity in your job (2)':'سابقه بیمه یا مدت زمان فعالیت در شغل خود (2)',
        'Enter the Insurance history or duration of activity in your job (2)':'سابقه بیمه یا مدت زمان فعالیت در شغل خود را وارد نمایید (2)',
        'Job Post (2)':'سمت در شغل (2)',
        'Enter the applicants Job Post (2)':'سمت در شغل خود را وارد نمایید (2)',

        'Job Title (3)':'عنوان شغلی (3)',
        'Enter the Job Title (3)':'عنوان شغلی خود را وارد نمایید (3)',
        'Insurance history or duration of activity in your job (3)':'سابقه بیمه یا مدت زمان فعالیت در شغل خود (3)',
        'Enter the Insurance history or duration of activity in your job (3)':'سابقه بیمه یا مدت زمان فعالیت در شغل خود را وارد نمایید (3)',
        'Job Post (3)':'سمت در شغل (3)',
        'Enter the applicants Job Post (3)':'سمت در شغل خود را وارد نمایید (3)',

        'Liquidity':'نقدینگی',
        'More than 70 thousand dollars':'بیشتر از 70,000 دلار',
        'to':'الی',
        'Less than $ 30,000':'کمتر از 30,000 دلار',
        'Enter the applicants Liquidity':'میزان نقدینگی خود را وارد نمایید',
        'Enter the applicants Total Assets Amount':'مجموع دارایی خود را وارد نمایید',
        'More than 600 thousand dollars':'بیشتر از 600,000 دلار',
        'Less than $ 50,000':'کمتر از 50,000 دلار',
        'More than 25 thousand dollars':'بیشتر از 25 هزار دلار',
        'Less than $ 5,000':'کمتر از 5 هزار دلار',
        'Further Information':'اطلاعات تکمیلی',

        'Do you have a child under 4 years old?':'آیا فرزند زیر 4 سال دارید؟',
        'Yes':'بله',
        'No':'خیر',
        'Number of Under 4 Years Old Childeren':'تعداد فرزندان زیر 4 سال',
        'Enter the applicants Number of Under 4 Years Old Childeren':'تعداد فرزندان زیر 4 سال خود را وارد نمایید',
        'Age of Under 4 Years Old Child':'سن فرزندان زیر 4 سال',
        'Enter the applicants Age of Under 4 Years Old Child':'سن فرزندان زیر 4 سال خود را وارد نمایید',
        'Gender of Under 4 Years Old Child':'جنسیت فرزندان زیر 4 سال',
        'Enter the applicants Gender of Under 4 Years Old Child':'جنسیت فرزندان زیر 4 سال خود را وارد نمایید',
        'Do you have children between 4 and 17 years old?':'آیا فرزند بین 4 تا 17 سال دارید؟',
        'Number of children between 4 and 17 Old Childeren':'تعداد فرزند بین 4 تا 17 سال',
        'Enter the applicants Number of children between 4 and 17 Old':'تعداد فرزند بین 4 تا 17 سال خود را وارد نمایید',
        'Age of children between 4 and 17 Old Child':'سن فرزند بین 4 تا 17 سال',
        'Enter the applicants Age of children between 4 and 17 Old':'سن فرزند بین 4 تا 17 سال خود را وارد نمایید',
        'Gender of children between 4 and 17 Old Child':'جنسیت فرزند بین 4 تا 17 سال',
        'Enter the applicants Gender of children between 4 and 17 Old':'جنسیت فرزند بین 4 تا 17 سال خود را وارد نمایید',
        'Do you have children over 18?':'آیا فرزند بالای 18 سال دارید؟',
        'Number of Over 18 Years Old Childeren':'تعداد فرزندان بالای 18 سال',
        'Enter the applicants Number of Over 18 Years Old Childeren':'تعداد فرزندان بالای 18 سال خود را وارد نمایید',
        'Age of Over 18 Years Old Child':'سن فرزندان بالای 18 سال',
        'Enter the applicants Age of Over 18 Years Old Child':'سن فرزندان بالای 18 سال خود را وارد نمایید',
        'Gender of Over 18 Years Old Child':'جنسیت فرزندان بالای 18 سال',
        'Enter the applicants Gender of Over 18 Years Old Child':'جنسیت فرزندان بالای 18 سال خود را وارد نمایید',
        
        'Do you have a companion? (Father - mother - grandfather - grandmother)':'آیا همراه دارید؟ (پدر - مادر - پدربزرگ - مادربزرگ)',
        'Country of immigration':'کشور مورد نظر جهت مهاجرت ',
        'Sweden':'سوئد',
        'Germany':'آلمان',
        'United Kingdom':'انگلستان',
        'Denmark':'دانمارک',
        'Austria':'اتریش',
        'Spain':'اسپانیا',
        'Australia':'استرالیا',
        'UAS':'آمریکا',
        'Ukraine':'اکراین',
        'Oman':'عمان',
        'Type of Requested Visa':'نوع ویزای درخواستی',
        'Tourist':'توریستی ',
        'Educational':'تحصیلی',
        'Student':'دانش آموزی',
        'Work':'کاری',
        'Business':'بیزینسی',
        'Company Registration':'ثبت شرکت',
        'Buy real estate and companies':'خرید املاک و شرکت ها',
        'Entrepreneurship':'کارآفرینی',
        'Startup':'استارت آپ',
        'Express Entry':'اکسپرس اینتری',
        'Provincial':'استانی',
        'Companion - Parents':'همراه – والدین',
        'Rejection - Court':'ریجکتی – دادگاهی',

        'The purpose of migration':'هدف از مهاجرت',
        'Enter the applicants purpose of migration':'هدف از مهاجرت خود را شرح دهید',
        'Traveled to a Schengen area, Canada,...':'مسافرت به یکی از کشورهای حوزه شنگن ، کانادا ، انگلستان ، آمریکا یا استرالیا',
        'Have you or your spouse traveled to a Schengen area, Canada, the United Kingdom, the United States or Australia?':'آیا خودتان یا همسرتان به یکی از کشورهای حوزه شنگن ، کانادا ، انگلستان ، آمریکا یا استرالیا سفر کرده اید ؟',
        'Names of countries you have visited':'نام کشورهایی که به آنجا سفر کرده اید',
        'Enter the Names of countries you have visited':'نام کشورهایی که به آنجا سفر کرده اید را وارد نمایید',
        'Has the applicant ever had a history of rejection from a country?':'آیا تاکنون متقاضی سابقه ریجکتی از کشوری داشته ؟',
        'Names of countries you have been rejected so far':'نام کشورهایی که تا کنون ریجکت شده اید',
        'Enter the Names of countries you have been rejected so far':'در صورت ریجکتی در 5سال گذشته ، نام کشور را ذکر نمایید',
        'Do you have first and second degree relatives in your chosen country?':'آیا در کشور منتخبتان اقوام درجه یک و دو دارید؟ ',
        'Explain...':'توضیح دهید',
        'Enter the applicants Explain':'توضیحات خودرا وارد نمایید',

        'Does the applicant have a financial supporter?':'آیا متقاضی ساپورتر مالی دارد؟ ',
        'Explain if you have a financial supporter':'در صورت داشتن ساپورتر مالی توضیح دهید ',
        'Enter Explain if you have a financial supporter':'در صورت داشتن ساپورتر مالی توضیح دهید ',
        'Spouse Name':'نام همسر',
        'Enter Your Spouse Name':'نام همسر را وارد نمایید',
        'Spouse Last Name':'نام خانوادگی همسر',
        'Enter Your Spouse Last Name':'نام خانوادگی همسر را وارد نمایید',
  
        'Applicant Accompany Information':'اطلاعات همراه متقاضی',
        'Accompany Post':'سمت همراه',
        'Enter the applicants Accompany Post':'سمت همراه متقاضی را وارد نمایید',
        'Applicant Descriptions':'توضیحات متقاضی',
        'Descriptions':'توضیحات',
        'Enter Your Descriptions':'توضیحات مهم دیگر خود را در این قسمت وارد نمایید',

        'Included':'مشمول',
        'Served':'پایان خدمت ',
        'Conscription Exempt':'معاف از خدمت',
        'Purchased Service':'خرید خدمت',


        'Applicant Informations':'اطلاعات متقاضی',


        'SORRY, PAGE NOT FOUND':'متاسفانه صفحه مورد نظر یافت نشد',
        'Return to Homepage':'برگشت به صفحه اول',

        'Marketer':'نماینده',

        'Your Cash is:':'موجودی کیف پول شما:',
        '1500$':'1500 دلار',
        '1000$':'1000 دلار',
        '500$':'500 دلار',
        'This Page is Under Construction, It will be availabe as soon as posible.':'به زودی این صفحه کامل خواهد گردید',
      
        'Lawyers':'وکلا',
        'Pending':'در انتظار',
        '+ Services':'+ خدمات',
        'Enter the applicants Service Name':'نام خدمات متقاضی را وارد کنید',
        'Messages':'پیام ها',
        'Service Name...':'نام خدمات ...',
        'Messaging System is Online tools for communication between our users, also you can send message to anybody.':'سیستم پیام رسان، یک ابزار برای ارتباط بین کاربران است. همچنین شما میتوانید از طریق این ابزار با کاربران مرتبط با خود در ارتباط یاشید.',
        'To':'به',
        'Subject':'موضوع',
        'Type here...':'تایپ نمایید...',
        'Enter Your message in the box.':'متن پیام خود را در باکس بالا تایپ نمایید.',
        'Send Message':'ارسال پیام',
        'Type here your message':'پیام خود را اینجا تایپ کنید',

        'Type Your Desired Name for Service and Then click (Create)':'برای ایجاد خدمات جدید نام دلخواه خدمات را تایپ نموده و create را بزنید',

        'Select Your User Type':'نوع کاربر خود را تعیین نمایید',

        'My Services':'خدمات من',

        'Lawyers List':'لیست وکلا',
        'Agents List':'لیست نمایندگان',
        'Customers List':'لیست مشتریان',

        'Service Owner':'صاحب خدمات',
        'Choose Your Business Service':'نوع خدمات بیزینس خود را انتخاب نمایید',
        'selecting your user Service type.':'selecting your user Service type.',
        '< Back to Account page':'< برگشت به صفحه انتخاب نوع اکانت',
        'Distributers':'توسعه دهندگان',

        'Dear user, welcome to your registration section':'کاربر گرامی به بخش ثبت نام خودتان خوش آمدید',
        'Select the appropriate option to register depending on the following conditions:':'برای ثبت نام بسته به شرایط زیر گزینه مناسب را انتخاب نمایید:',
        '1- If you have an invitation code, select the option to register with the code':'۱- اگر کد دعوت نامه دارید گزینه ثبت نام با کد را انتخاب نمایید',
        '2- If you intend to cooperate and you think you have the conditions to obtain the code, contact us and select the option to contact us.':'۲- اگرقصد همکاری دارید و فکر میکنید شرایط اخذ کد دارید با ما ارتباط داشته باشید و گزینه ارتباط با ما را انتخاب نمایید',
        '3- If you do not have the conditions to obtain a code, but you want to cooperate, you can start your registration by selecting the Marker option, and after obtaining the necessary points, you can reach the stage of obtaining a code.':'۳- اگر شرایط اخذ کد ندارید اما میخواهید همکاری کنید میتوایند ابتدا با انتخاب گزینه نماینده ثبت نام خود را شروع نمایید و پس از اخذ امتیازات لازم به مرحله اخذ کد برسید',
        '4- If none of the conditions are suitable, return to the previous page to display more options.':'۴- اگر هیچ یک از شرایط مناسب نیست به صفحه قبل برگردید تا گزینه های بیشتری نمایش داده شود.',

        'Choose Your Registration Type':'نوع ثبت نام خود راانتخاب نمایید',
        'Registration with Code':'ثبت نام با کد',
        'Contact us':'ارتباط با ما',
        'Registered as a Agent':'ثبت به عنوان نماینده',

        'Doctor':'پزشک',
        'Car Service':'خودرو',
        'Real Estate':'مشاور املاک',
        'Trade':'بورس و ارز دیجیتال',
        'Degital Devices':'خدمات دیجیتالی',
        'Beauty Services':'آرایشی بهداشتی',
        'Gold and Jewelry':'طلا و جواهرات',
        'Latest Distributers':'توسعه دهندگان اخیر',
        'Message':'پیام',
        'View Information':'مشاهده اطلاعات',
        'View':'مشاهده',
        '+ Add New Marketer':'+ افزودن نماینده',
        'Related Distributers':'توسعه دهندگان همکار',
        'Related Distributers List':'لیست توسعه دهندگان همکار',
        'DS Customers':'مشتریان توسعه دهنده',
        'DS Shared Customers List':'لیست مشتریان توسعه دهنده',
        'Agent Cusomers':'مشتریان نماینده',
        'Agent Cusomers List':'لیست مشتریان نماینده',
        'Distributer':'توسعه دهنده',
        'Distributers List':'لیست توسعه دهندگان',
        'Choose Your Service':'خدمات خود را انتخاب نمایید',
        'other':'دیگر',
        'Type Service Name here':'نام خدمات را اینجا تایپ نمایید',
        'Service Name':'نام خدمات',
        'Service Price':'مبلغ خدمات',
        'DS Commission':'درصد توسعه دهنده',
        'DC Commission':'درصد توسعه دهنده',
        'DC Fee':'سهم توسعه دهنده',
        'Distributer Commission':'درصد توسعه دهنده',
        'Agent Commission':'درصد نماینده',
        'About Service':'درباره خدمات',
        'Enter the Description About Your Serivce':'شرح خدمات خود را در کادر بالا بنویسید',
        'Accept':'تایید',
        'Educational Visa':'ویزای تحصیلی',
        'Enter Your Offer':'پیشنهاد خود را وارد نمایید',
        'You Assigned Before':'قبلاثبت نموده اید',
        'Coming Soon':'به زودی فعال می شود',
        'Soon!':'به زودی!',
        'Our Help Desk':'مرکز پشتیبانی ما',
        'Dear Lawyer Pay Attention, Your Service in NOT Changeable, After You Submit It, You Never Access to Change Fees or Comissions!':'وکیل عزیز توجه کنید، خدمات شما قابل تغییر نیست، پس از ارسال، هرگز به هزینه ها یا کمیسیون های تغییر دسترسی نخواهید داشت!',
        'Services Never Changes After Adding to Your List':'خدمات پس از افزودن به لیست شما هرگز تغییر نمی کند',
        'How To Become A Premium DS?':'چگونه به یک DS پلاس تبدیل شویم؟',
        'In Our Rules There Are some ways that You can Got Your Account DS+, After that you can Use special Services of This Application. for more Information please visit our help center.':'در قوانین ما راه هایی وجود دارد که می توانید حساب خود را DS+ دریافت کنید، پس از آن می توانید از خدمات ویژه این برنامه استفاده کنید. برای اطلاعات بیشتر لطفاً از مرکز راهنمایی ما دیدن کنید.',
        'Dear user, These Services will be Available Soon!':'کاربر گرامی، خدمات انتخابی به زودی فعال می گردد!',
        'Our experts are working on it.':'در صورت احراز نشدن کد وکالت شما اکانت شما حذف خواهد شد.',
        'If your power of attorney code is not verified, your account will be deleted.':'کارشناسان ما در حال ارتقا آن هستند.',
        'As Lawyer':'در نقش وکیل',
        'Date of birth':'تاریخ تولد',
        'User Name Start with Alphabet and continue with Alphanumeric Char in Min lenght of 5':'نام کاربری باید با حرف شروع شود و طول آن حداقل 5 است',
        'password':'کلمه عبور',
        'Confirm password':'تایید کلمه عبور',
        'As Distributor':'در نقش توسعه دهنده',
        'As Customer':'در نقش مشتری',
        'As Agent':'در نقش نماینده',
        'What service do you want?':'خدمات مورد نظر شما کدام است؟',
        'Your host Telens support team':'تیم پشتیبانی تلزینس میزبان شما',
        'Way to communicate with us':'راه ارتباطی با ما',
        'To contact us, just send us an email from the address below':'برای ارتباط با ما کافیست از طریق آدرس روبرو برای ما ایمیل ارسال کنید',
        'Follow us on social media':'ما را در شبکه های اجتماعی دنبال کنید',
        'Our story':'داستان ما',
        'Can we help you?':'می توانیم به شما کمک نماییم؟',
        'Joining the Telsins family':'پیوستن به خانواده ی تلزینس',
        'The name of the service is here':'نام خدمات در اینجا قرار میگیرد',
        'Referal Code':'کد معرف',
        'Select Your User Type':'نوع کاربر را انتخاب نمایید',
        'Select Your User':'نام کاربری',
        'Telesiness Support':'پشتیبانی تلزینس',
        'Select Service Name':'نام خدمات را انتخاب نمایید',
        'Required Documents':'مدارک مورد نیاز',
        'Enter the Customers Required Documents for This Service':'مدارکی که برای خدمات نیاز است را وارد نمایید',
        'Visa Process':'فرآیند ویزا',
        'Enter the Process of This Service':'فرآیند اخذ ویزا برای خدمات خود را وارد نمایید',
        'Referal Customers':'مشتریان ارجاعی',
        'Referal Customer List':'لیست مشتریان ارجاعی',
        'Customers List':'لیست مشتریان',
        'Being login...':'در حال ورود...',
        'Service Country':'کشور خدمات',
        '+ Add New Distributer':'+ افزودن توسعه دهنده جدید',
        'Hindi':'هندی',
        'Mandarin':'ماندارین',
        'Dutch':'هلندی',
        'French':'فرانسوی',
        'Bengali':'بنگالی',
        'Russian':'روسی',
        'Portuguese':'پرتغالی',
        'Indonesian':'اندونزیایی',
        'Japanese':'ژاپنی',
        'Turkish':'ترکی',
        'Italian':'ایتالیایی',
        'Romanian':'رومانیایی',
        'Thai':'تایلندی',
        'Nepali':'نپالی',
        'Gujarati':'گجراتی',
        'Your Customer Activated':'مشتری فعال شد',
        'Your Customer Activated Successfully.':'مشتری شما با موفقیت فعال شد.',
        'OK ,  I Got It':'باشه فهمیدم',
        'View Services':'مشاهده خدمات',
        'DS':'توسعه دهنده',
        'Assign a Services':'اختصاص یک سرویس',
        'Your Assigned Services to Agent':'خدمات اختصاص داده شده شما به نماینده',
        'Agent Code':'کد نماینده',
        'Your Assigned offer':'پیشنهاد تعیین شده شما',
        'offer you':'پیشنهاد شما',
        'Suggested Services':'خدمات پیشنهادی',
        'Save changes':'ذخیره تغییرات',
        'Assign by Telesiness':'تخصیص توسط تلزینس',
        'Adding a new Distributer':'افزودن توسعه دهنده جدید',
        'Type Distributer Code...':'کد توزیع کننده را تایپ کنید...',
        'Add DS!!!':'افزودن توسعه دهنده!!!',
        'Type DS Code Here...':'کد توسعه دهنده را در اینجا تایپ کنید...',
        'ADD BY DS Code':'افزودن با کد توسعه دهنده',
        'DS Code':'کد توسعه دهنده',
        'Distributer Code':'کد توسعه دهنده',
        'List of  Tariffs For Lawyer:':'لیستی از تعرفه های وکیل:',
        'Adding a new CO-DS':'افزودن توسعه دهنده همکار',
        'Add a Distributer that want to be in rel for coporating in your work.':'یک توسعه دهنده را برای همکاری اضافه نمایید.',
        'Adding a Service to Customer':'افزودن خدمات به مشتری',
        'Please Select a Service to customer.':'لطفاً خدماتی را برای مشتری انتخاب کنید.',
        'Type an Email adress...':'یک آدرس ایمیل تایپ کنید...',
        'DS Information':'اطلاعات توسعه دهنده',
        'Enter the applicants Gender':'جنسیت متقاضی را وارد کنید',
        'Enter Your City':'شهر خود را وارد کنید',
        'please input your first name':'لطفا نام خود را وارد کنید',
        'please input your Last Name':'لطفا نام خانوادگی خود را وارد نمایید',
        'please input your Phone number':'لطفا شماره تلفن را وارد نمایید',
        'please input your Email':'لطفا ایمیل خود را وارد نمایید',
        'please select your Country':'لطفا کشور خود را انتخاب نمایید',
        'please input your ICCRC':'لطفا کد وکالت خود را وارد نمایید',
        'UnEqual Password...':'تکرار پسورد صحیح نیست...',
        'DS Services':'خدمات توسعه دهنده',
        'This Option Will Be Active After You improve Panel to Pro Version!':'این گزینه پس از ارتقاء پنل به نسخه حرفه ای فعال خواهد شد!',
        'Are you sure about the set tariff?':'آیا از تعرفه تعیین شده مطمئن هستید ؟',
        'Please be sure of the rate set as commission. It is not possible to change the tariff after final approval':'لطفا از نرخ تعیین شده به عنوان درصد مطمئن شوید. پس از تایید نهایی امکان تغییر تعرفه وجود ندارد',
        'please input your ID Number':'لطفا کد ملی خود را وارد نمایید',
        'You Can Upgrade Your Account to Profesional Varsion, fro mor information see the help!':'می توانید حساب خود را به نسخه حرفه ای ارتقا دهید، برای اطلاعات بیشتر به راهنما مراجعه کنید!',
        'UpLoad your Cv/Sop/evaluation-form':'آپلود رزومه/انگیزش نامه/فرم ارزیابی',
        'If you have any of the following, you can upload the file in this section. To upload more than one file, first merge them into a pdf file and then upload':'در صورت داشتن هر کدام از موارد زیر، می توانید فایل آن را در این قسمت آپلود نمایید. برای آپلود بیش از یک فایل، ابتدا آنها را به صورت یک فایل pdf ادغام نموده، سپس بارگذاری نمایید',
        'You Have Not Code OR UnEqual Password...':'کد معرف یا تکرار رمز اشتباه است',
        'Pay attention You can Aprove Customer Account once, and after that you cant change status!':'توجه نمایید شما فقط یک بار می توانید حساب مشتری را تایید کنید و پس از آن دیگر قادر به تغییر آن نخواهید بود!',
      }
      
    },
    // add other language to the object
  };