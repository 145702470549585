
import {ArrowLeft2} from 'iconsax-react';
import Popup from './Popup';
import { useState,useEffect,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MessageIcon from '@mui/icons-material/Message';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { Item } from 'semantic-ui-react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

var names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];
if(localStorage.getItem('CusNameMsg')!='')
    names.push(localStorage.getItem('CusNameMsg'));
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}




  
const baseUrl = "../../images/";
function useForceUpdate(){
   
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render

}

const Home = () => {

    const theme = useTheme();
    const [personName, setPersonName] = useState([]);
    const [values, setValues] = useState({
        UserMessage: '',
        To: '',
        UserMessage: '',
        SelectYourUser:'',
        SelectYourUser2:'',
        refCode: '',
        SendMsg:'',
        showPassword: false,
      });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        localStorage.removeItem('CusNameMsg')
       
        if (values.SelectYourUser2!='') {
            localStorage.setItem('telesinessAudienceCode',event.target.value);
        }
        if (values.SelectYourUser!='') {
            localStorage.setItem('telesinessAudienceType',event.target.value);
        }
       console.log(values.SelectYourUser2);
       console.log(values.SelectYourUser);
        // if(values.SelectYourUser=='5')
        // {
        //     var i;
        //     for(i in names)
        //     names.pop();
        //     names.push('Telesiness');
        //     forceUpdate();
        // }

        

      }

    const  handleSubmit = (e) => {
        e.preventDefault();
        var UserSenderCodeSubmit ='';
        if(localStorage.getItem('userType')==1)
         UserSenderCodeSubmit = localStorage.getItem('lawyerCode');

        else if(localStorage.getItem('userType')==2)
        UserSenderCodeSubmit = localStorage.getItem('agentCode');

        else if(localStorage.getItem('userType')==3)
        UserSenderCodeSubmit = localStorage.getItem('customerCode');

        else if(localStorage.getItem('userType')==4)
        UserSenderCodeSubmit = localStorage.getItem('marketerCode');



    var UserGetCodeSubmit= '';
        if(localStorage.getItem('CusCode')!='' && localStorage.getItem('CusCode')!=null)
            UserGetCodeSubmit = localStorage.getItem('CusCode');
        else
            UserGetCodeSubmit = values.SelectYourUser2;

    var UserTypeCodeSubmit = '';
        if(localStorage.getItem('MsgUserNewType')!=''  && localStorage.getItem('CusCode')!=null)
            UserTypeCodeSubmit = localStorage.getItem('MsgUserNewType')
        else
            UserTypeCodeSubmit = localStorage.getItem('telesinessAudienceType');

        fetch('https://telesiness.ir/api/Dashboards/SendMessage' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                Email: localStorage.getItem('email'),
                UserSenderCode:UserSenderCodeSubmit,
                UserTypeGetMessage:UserTypeCodeSubmit,
                TelesinessUserAudience:UserGetCodeSubmit,
                Subject:values.Subject,
                UserMessage:values.UserMessage,
            })
            }).then(response => response.json()).then(response=>{
                
                 console.log(response);
                
            });

            UserTypeCodeSubmit = localStorage.removeItem('telesinessAudienceType');
    }

    const {t, i18n} = useTranslation(['Login']);
    const forceUpdate = useForceUpdate();
    const [people , setPeople] = useState([
        {visa:'false',CustomerPic:'CusPic.png', CustomerName:'',CustomerPhone:'+989143158460',CustomerStatus:'',CustomerCreated:'', CustomerAgent:'BaBaii',key:'1'},
      ]);
  
    // const [people , setPeople] = useState([
    //     {visa:'false',CustomerPic:'CusPic.png', CustomerName:'behzad',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'1'},
    //     {visa:'false',CustomerPic:'CusPic.png',CustomerName:'nariman',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Nov 30 ,2021 ', CustomerAgent:'BaBaii',key:'2'},
    //     {visa:'true',CustomerPic:'alireza.png',CustomerName:'fatemeh',CustomerPhone:'+989143158460',CustomerStatus:'Deactive',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'3'},
    //     {visa:'false',CustomerPic:'alireza.png',CustomerName:'parisa',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'4'},
    //     {visa:'false',CustomerPic:'CusPic.png',CustomerName:'behzad',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'5'},
    //     {visa:'false',CustomerPic:'CusPic.png',CustomerName:'nariman',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'6'},
    //     {visa:'false',CustomerPic:'alireza.png',CustomerName:'fatemeh',CustomerPhone:'+989143158460',CustomerStatus:'Deactive',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'7'},
    //     {visa:'false',CustomerPic:'CusPic.png',CustomerName:'parisa',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2020 ', CustomerAgent:'BaBaii',key:'8'},
    //   ]);

    const [peopleA , setPeopleA] = useState([
        { AgentPic:'', 
            AgentName:'',
            messageStatus:'',
            subject:'',
            submitDate:'',
            userSenderCode:'',
            telesinessUserAudience:'',
            userMessage:'',
            usrMsgType : '', 
            key:1},
      ]);

      const [MessageMsgs , setMessageMsgs] = useState([
        { 
            messageCode:'', 
            userSenderCode:'',
            telesinessUserAudience:'',
            userMessage:'',
 
            key:1},
      ]);

       const [peopleLAW , setPeopleLAW] = useState([
        {AgentPic:'CusPic.png', AgentName:'',AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
      ]);



            if(localStorage.getItem('userType')==1)
            fetch('https://telesiness.ir/api/Dashboards/LawyerGetMessage' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: localStorage.getItem('email') })
                }).then(response => response.json()).then(response=>{
                     var i =0;
                     try
                     {
                         console.log(peopleA.length);
                        if(peopleA.length<2)
                        {
                            peopleA.pop();
                            var myActDec = ''
                        for (i in response.usersMessage){
                            // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                            // people[i].CustomerCreated = response[i].customerRegisterDate;
                            var msgUsrName = '';
                            var msgUsrAvatar = '';
                            var msgUserType ='';
                        
                            msgUsrName = response.usersMessage[i].firstName + " " + 
                            response.usersMessage[i].lastName;
                            msgUsrAvatar = response.usersMessage[i].userAvatar;
                            msgUserType =response.usersMessage[i].userTypeGetMessage;
                           
                            peopleA.push( {
                                AgentPic:msgUsrAvatar, 
                                AgentName:msgUsrName,
                                messageStatus:response.usersMessage[i].seenMessageStatus,
                                subject:response.usersMessage[i].subject,
                                submitDate:response.usersMessage[i].submitDate,
                                userSenderCode:response.usersMessage[i].userSenderCode,
                                telesinessUserAudience:response.usersMessage[i].telesinessUserAudience,
                                userMessage:response.usersMessage[i].userMessage, 
                                usrMsgType : msgUserType,

                                key:i},)
                                         }
                         }
                     }

                    catch{

                    }
                    // console.log(response[0]);
                    
                });

                
            if(localStorage.getItem('userType')==2)
            fetch('https://telesiness.ir/api/Dashboards/DistributorGetMessage' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: localStorage.getItem('email') })
                }).then(response => response.json()).then(response=>{
                     var i =0;
                     try
                     {
                         console.log(peopleA.length);
                        if(peopleA.length<2)
                        {
                            peopleA.pop();
                            var myActDec = ''
                        for (i in response.distributorMessages){
                            // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                            // people[i].CustomerCreated = response[i].customerRegisterDate;
                            var msgUsrName = '';
                            var msgUsrAvatar = '';
                            var msgUserType ='';
                          
                            msgUsrName = response.distributorMessages[i].firstName + " " + 
                            response.distributorMessages[i].lastName;
                            msgUsrAvatar = response.distributorMessages[i].userAvatar;
                            msgUserType =response.distributorMessages[i].userTypeGetMessage;
                            
                         
                            
                            peopleA.push( {
                                AgentPic:msgUsrAvatar, 
                                AgentName:msgUsrName,
                                messageStatus:response.distributorMessages[i].seenMessageStatus,
                                subject:response.distributorMessages[i].subject,
                                submitDate:response.distributorMessages[i].submitDate,
                                userSenderCode:response.distributorMessages[i].userSenderCode,
                                telesinessUserAudience:response.distributorMessages[i].telesinessUserAudience,
                                userMessage:response.distributorMessages[i].userMessage, 
                                usrMsgType : msgUserType,

                                key:i},)
                                         }}
                         
                     }

                    catch{

                    }
                    console.log(peopleA);
                    
                });

                if(localStorage.getItem('userType')==3)
                fetch('https://telesiness.ir/api/Dashboards/CustomerGetMessage' , {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Email: localStorage.getItem('email') })
                    }).then(response => response.json()).then(response=>{
                         var i =0;
                         try
                         {
                             console.log(peopleA.length);
                            if(peopleA.length<2)
                            {
                                peopleA.pop();
                                var myActDec = ''
                            for (i in response.customerMessages){
                                // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                                // people[i].CustomerCreated = response[i].customerRegisterDate;
                                var msgUsrName = '';
                                var msgUsrAvatar = '';
                                var msgUserType ='';
                              
                                msgUsrName = response.customerMessages[i].firstName + " " + 
                                response.customerMessages[i].lastName;
                                msgUsrAvatar = response.customerMessages[i].userAvatar;
                                msgUserType =response.customerMessages[i].userTypeGetMessage;
                                
                             
                                
                                peopleA.push( {
                                    AgentPic:msgUsrAvatar, 
                                    AgentName:msgUsrName,
                                    messageStatus:response.customerMessages[i].seenMessageStatus,
                                    subject:response.customerMessages[i].subject,
                                    submitDate:response.customerMessages[i].submitDate,
                                    userSenderCode:response.customerMessages[i].userSenderCode,
                                    telesinessUserAudience:response.customerMessages[i].telesinessUserAudience,
                                    userMessage:response.customerMessages[i].userMessage, 
                                    usrMsgType : msgUserType,
    
                                    key:i},)
                                             }}
                             
                         }
    
                        catch{
    
                        }
                         console.log(response[0]);
                        
                    });

                    if(localStorage.getItem('userType')==4)
                    fetch('https://telesiness.ir/api/Dashboards/MarketerGetMessage' , {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ Email: localStorage.getItem('email') })
                        }).then(response => response.json()).then(response=>{
                             var i =0;
                             try
                             {
                                 console.log(peopleA.length);
                                if(peopleA.length<2)
                                {
                                    peopleA.pop();
                                    var myActDec = ''
                                for (i in response.marketerMessages){
                                    // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                                    // people[i].CustomerCreated = response[i].customerRegisterDate;
                                    var msgUsrName = '';
                                    var msgUsrAvatar = '';
                                    var msgUserType ='';
                                  
                                    msgUsrName = response.marketerMessages[i].firstName + " " + 
                                    response.marketerMessages[i].lastName;
                                    msgUsrAvatar = response.marketerMessages[i].userAvatar;
                                    msgUserType =response.marketerMessages[i].userTypeGetMessage;
                                    
                                 
                                    
                                    peopleA.push( {
                                        AgentPic:msgUsrAvatar, 
                                        AgentName:msgUsrName,
                                        messageStatus:response.marketerMessages[i].seenMessageStatus,
                                        subject:response.marketerMessages[i].subject,
                                        submitDate:response.marketerMessages[i].submitDate,
                                        userSenderCode:response.marketerMessages[i].userSenderCode,
                                        telesinessUserAudience:response.marketerMessages[i].telesinessUserAudience,
                                        userMessage:response.marketerMessages[i].userMessage, 
                                        usrMsgType : msgUserType,
        
                                        key:i},)
                                                 }}
                                 
                             }
        
                            catch{
        
                            }
                             console.log(response[0]);
                            
                        });
              

        const [state, setState] = useState({ num: 0 });
        const counter = useRef(0);
        
        useEffect(() => {
          if (counter.current < 2) {
            counter.current += 1;
            const timer = setTimeout(() => setState({ num: state.num + 1 }), 3000);
      
            return () => clearTimeout(timer);
          }
        }, [state]);
        //  
    //   const [peopleA , setPeopleA] = useState([
    //     {AgentPic:'CusPic.png', AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
    //     {AgentPic:'CusPic.png',AgentName:'nariman',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'8 ', Email:'kambiz.fakhr@yahoo.com',key:'129122'},
    //     {AgentPic:'alireza.png',AgentName:'fatemeh',AgentPhone:'+989143158460',AgentStatus:'Deactive',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129123'},
    //     {AgentPic:'alireza.png',AgentName:'parisa',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129124'},
    //     {AgentPic:'CusPic.png',AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129125'},
    //     {AgentPic:'CusPic.png',AgentName:'nariman',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129126'},
    //     {AgentPic:'alireza.png',AgentName:'fatemeh',AgentPhone:'+989143158460',AgentStatus:'Deactive',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129127'},
    //     {AgentPic:'CusPic.png',AgentName:'parisa',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'31 ', Email:'kambiz.fakhr@yahoo.com',key:'129128'},
    //   ]);
    // console.log(peopleA);


      

      
      const handleClickMsg = (itemkey,AgentPic,usrMsgType,submitDate,AgentName,userSenderCode,telesinessUserAudience) =>{
          console.log(itemkey,AgentPic,usrMsgType,submitDate,AgentName,userSenderCode,telesinessUserAudience);
       document.getElementById('msgList').style.display= "none";
       document.getElementById("mySelectedMsg").style.display="flex";
      localStorage.setItem('Msgsrc',AgentPic);
      localStorage.setItem('usrMsgType',usrMsgType);
      localStorage.setItem('submitDate',submitDate);
      localStorage.setItem('msgAgentName',AgentName);
      localStorage.setItem('msguserSenderCode',userSenderCode);
      localStorage.setItem('msgtelesinessUserAudience',telesinessUserAudience);


      //******************fetch data ******/
      fetch('https://telesiness.ir/api/Dashboards/GetAllMessageSingleUser' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            Email: localStorage.getItem('email'),
            UserSenderCode:userSenderCode,
            TelesinessUserAudience:telesinessUserAudience,
         })
        }).then(response => response.json()).then(response=>{
             var i =0;
             var j = 0;
          
                 console.log(MessageMsgs.length);
                for(j=0; j<=MessageMsgs.length; j++)
                    MessageMsgs.pop();
                    console.log(MessageMsgs);
                    var myActDec = ''
                for (i in response.userMessage){
                    // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                    // people[i].CustomerCreated = response[i].customerRegisterDate;
      
                    MessageMsgs.push( {
                        messageCode:response.userMessage[i].messageCode, 
                        userSenderCode:response.userMessage[i].userSenderCode,
                        telesinessUserAudience:response.userMessage[i].telesinessUserAudience,
                        userMessage:response.userMessage[i].userMessage,
             
                        key:1},)
                                 }
                                 forceUpdate(); 
       
       
        });
        //***********************end fetch data************ */



    }
    const handleClickBackMsg = () =>{
        document.getElementById('msgList').style.display= "block";
        document.getElementById("mySelectedMsg").style.display="none";
        localStorage.removeItem('Msgsrc');
        localStorage.removeItem('usrMsgType');
        localStorage.removeItem('submitDate');
        localStorage.removeItem('msgAgentName');
        localStorage.removeItem('msguserSenderCode');
        localStorage.removeItem('msgtelesinessUserAudience');
        forceUpdate();
     }
    const handleDeactiveClick = (itemKey) =>{
        setIsOpen(!isOpen);
        for(var i in people){
            if(people[i].key==itemKey){
                people[i].CustomerStatus='Deactive';
                setVisaId(i);
            }
            
        }
        forceUpdate() ;
        setPeople(people);
    
    }
    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
   const [isOpen, setIsOpen] = useState(false);
   const [isOpen2, setIsOpen2] = useState(false);
   const [isOpen3, setIsOpen3] = useState(false);
   const [visaID,setVisaId]=useState(-1);
   const handleVisaPbox = (itemKey) =>{
    people[visaID].visa='Visa';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}

const handleRejectedPbox = (itemKey) =>{
    people[visaID].visa='Rejected';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}

const handleSendMsgIn3Dmodel = () =>{
    // localStorage.setItem(values.SendMsg);
    document.getElementById('MymsgBoxMsg').style.display ="block";


    fetch('https://telesiness.ir/api/Dashboards/SendMessage' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            Email: localStorage.getItem('email'),
            UserSenderCode:localStorage.getItem('msguserSenderCode'),
            UserTypeGetMessage:localStorage.getItem('usrMsgType'),
            TelesinessUserAudience:localStorage.getItem('msgtelesinessUserAudience'),
            Subject:"Inbox",
            UserMessage:values.SendMsg,
         })
        }).then(response => response.json()).then(response=>{
            
             try
             {
                     //******************fetch data ******/
      fetch('https://telesiness.ir/api/Dashboards/GetAllMessageSingleUser' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            Email: localStorage.getItem('email'),
            UserSenderCode:localStorage.getItem('msguserSenderCode'),
            TelesinessUserAudience:localStorage.getItem('msgtelesinessUserAudience'),
         })
        }).then(response => response.json()).then(response=>{
             var i =0;
             var j = 0;
             try
             {
                 console.log(MessageMsgs.length);
                for(j=0; j<=MessageMsgs.length; j++)
                    MessageMsgs.pop();
                    console.log(MessageMsgs);
                    var myActDec = ''
                for (i in response.userMessage){
                    // people[i].CustomerName = response[i].customerFirstName + " " + response[i].customerLastName;
                    // people[i].CustomerCreated = response[i].customerRegisterDate;
      
                    MessageMsgs.push( {
                        messageCode:response.userMessage[i].messageCode, 
                        userSenderCode:response.userMessage[i].userSenderCode,
                        telesinessUserAudience:response.userMessage[i].telesinessUserAudience,
                        userMessage:response.userMessage[i].userMessage,
             
                        key:1},)
                                 }
                                 forceUpdate(); 
                                //  localStorage.removeItem('Msgsrc');
                                //  localStorage.removeItem('usrMsgType');
                                //  localStorage.removeItem('submitDate');
                                //  localStorage.removeItem('msgAgentName');
                                //  localStorage.removeItem('msguserSenderCode');
                                //  localStorage.removeItem('msgtelesinessUserAudience');
                                 forceUpdate(); 
                                 document.getElementById('MymsgBoxMsg').style.display ="none";
                                 values.SendMsg='';
                                 forceUpdate(); 

                 }
             

            catch{

            }
       console.log(MessageMsgs);
        });
        //***********************end fetch data************ */
                 }
             

            catch{

            }

        });
}

const handleDeclinedPbox = (itemKey) =>{
    people[visaID].visa='Declined';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}
const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const togglePopup2 = () => {
      setIsOpen2(!isOpen2);
    }
    const togglePopup3 = () => {
        setIsOpen3(!isOpen3);
      }
    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen3(!isOpen3);
    }
    const handleChange2 = (event) => {
        const {
          target: { value },
        } = event;
        setPersonName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      const handleAddUserSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomerByLawyer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ CustomerEmailAddress: values.Email ,LawyerCode:localStorage.getItem('lawyerCode') })
            }).then().then(setIsOpen2(!isOpen2));
        
    }
    const handleAddUserAgentSendEmail = (itemKey) =>{
        fetch('https://telesiness.ir/api/InvitationSystem/InviteDistributorsByLawyer' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ DistributorEmailAddress: values.EmailA ,LawyerCode:localStorage.getItem('lawyerCode') })
            }).then().then(setIsOpen2(!isOpen3));
        
    }

    const [userTypeSelect,setUserTypeSelect] = useState(localStorage.getItem('MsgUserNewType'));


    console.log(userTypeSelect);
    const myfuncToUserType = (e) =>{
        setUserTypeSelect(e.target.value);
        localStorage.setItem('telesinessAudienceType',e.target.value);
        if(localStorage.getItem('userType')==2)
        fetch('https://telesiness.ir/api/Dashboards/GetDistributorUserConnectionsListOnMessage' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ Email: localStorage.getItem('email'),
    UserAudienceType:e.target.value,
})
    }).then(response => response.json()).then(response=>{
         var i =0;
         var j = 0;
         try{
            for(j = 0; j<peopleLAW.length;j++)
            peopleLAW.pop();
            
            if(peopleLAW.length<2)
            peopleLAW.pop();
            if(e.target.value==1)
            {

                console.log(peopleLAW);
                for (i in response.lawyersDistributor){
                    // console.log(response.lawyersDistributor[i].distributorFirstName);
                    // peopleLAW[i].AgentName = response.lawyersDistributor[i].distributorFirstName + " " + response.lawyersDistributor[i].distributorLastName;
                    // peopleLAW[i].AgentClients = response.lawyersDistributor[i].clientsCount;
                    // peopleLAW[i].AgentPhone = response.lawyersDistributor[i].distributorCode;
                    peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.lawyersDistributor[i].audienceFirstName + " " + response.lawyersDistributor[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com', key:response.lawyersDistributor[i].telesinessAudienceCode},)
                    
                }
                
                console.log(response);
                console.log(peopleLAW);
            }


            if(e.target.value==2)
            {
               
                    for (i in response.distributorList){
                        // console.log(response.distributorList[i].distributorFirstName);
                        // peopleLAW[i].AgentName = response.distributorList[i].distributorFirstName + " " + response.distributorList[i].distributorLastName;
                        // peopleLAW[i].AgentClients = response.distributorList[i].clientsCount;
                        // peopleLAW[i].AgentPhone = response.distributorList[i].distributorCode;
                        peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.distributorList[i].audienceFirstName + " " + response.distributorList[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com', key:response.distributorList[i].telesinessAudienceCode},)
                        
                    }
                    
                }

                if(e.target.value==3)
                {
                    
                    for (i in response.customersList){
                        // console.log(response.customersList[i].distributorFirstName);
                        // peopleLAW[i].AgentName = response.customersList[i].distributorFirstName + " " + response.customersList[i].distributorLastName;
                        // peopleLAW[i].AgentClients = response.customersList[i].clientsCount;
                        // peopleLAW[i].AgentPhone = response.customersList[i].distributorCode;
                        peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.customersList[i].audienceFirstName + " " + response.customersList[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com', key:response.customersList[i].telesinessAudienceCode},)
                        
                    }
                   
                }


            if(e.target.value==4)
            {
                
                    for (i in response.customersList){
                        // console.log(response.customersList[i].distributorFirstName);
                        // peopleLAW[i].AgentName = response.customersList[i].distributorFirstName + " " + response.customersList[i].distributorLastName;
                        // peopleLAW[i].AgentClients = response.customersList[i].clientsCount;
                        // peopleLAW[i].AgentPhone = response.customersList[i].distributorCode;
                        peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.customersList[i].audienceFirstName + " " + response.customersList[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com', key:response.lawyersDistributor[i].telesinessAudienceCode},)
                        
                    }
                   
                }
            }
          
            
         

        catch{}
        
         forceUpdate();
        // window.location.reload(false);
        console.log(response.lawyersDistributor);
        console.log(peopleLAW);
        console.log(e.target.value);
        
    });
    if(localStorage.getItem('userType')==1)
    fetch('https://telesiness.ir/api/Dashboards/GetLawyerUserConnectionsListOnMessage' , {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
body: JSON.stringify({ Email: localStorage.getItem('email'),
UserAudienceType:e.target.value,
})
}).then(response => response.json()).then(response=>{
     var i =0;
     var j = 0;
     try{
        for(j = 0; j<peopleLAW.length;j++)
        peopleLAW.pop()
        if(e.target.value==1)
        if(peopleLAW.length<2)
        {
            peopleLAW.pop();
            for (i in response.lawyersDistributor){
                // console.log(response.lawyersDistributor[i].distributorFirstName);
                // peopleLAW[i].AgentName = response.lawyersDistributor[i].distributorFirstName + " " + response.lawyersDistributor[i].distributorLastName;
                // peopleLAW[i].AgentClients = response.lawyersDistributor[i].clientsCount;
                // peopleLAW[i].AgentPhone = response.lawyersDistributor[i].distributorCode;
                peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.lawyersDistributor[i].audienceFirstName + " " + response.lawyersDistributor[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:response.lawyersDistributor[i].telesinessAudienceCode},)
            }
        }
        if(e.target.value==2)
        if(peopleLAW.length<2)
        {
            peopleLAW.pop();
            for (i in response.lawyersDistributor){
                // console.log(response.lawyersDistributor[i].distributorFirstName);
                // peopleLAW[i].AgentName = response.lawyersDistributor[i].distributorFirstName + " " + response.lawyersDistributor[i].distributorLastName;
                // peopleLAW[i].AgentClients = response.lawyersDistributor[i].clientsCount;
                // peopleLAW[i].AgentPhone = response.lawyersDistributor[i].distributorCode;
                peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.lawyersDistributor[i].audienceFirstName + " " + response.lawyersDistributor[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:response.lawyersDistributor[i].telesinessAudienceCode},)
            }
        }
        if(e.target.value==3)
        if(peopleLAW.length<2)
        {
            peopleLAW.pop();
            for (i in response.customerLawyers){
                // console.log(response.customerLawyers[i].distributorFirstName);
                // peopleLAW[i].AgentName = response.customerLawyers[i].distributorFirstName + " " + response.customerLawyers[i].distributorLastName;
                // peopleLAW[i].AgentClients = response.customerLawyers[i].clientsCount;
                // peopleLAW[i].AgentPhone = response.customerLawyers[i].distributorCode;
                peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.customerLawyers[i].audienceFirstName + " " + response.customerLawyers[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:response.customerLawyers[i].telesinessAudienceCode},)
            }
        }
        
     }

    catch{}
    // console.log(response.distributorList[0].distributorFirstName);
    //   forceUpdate();
    // window.location.reload(false);
    console.log(response.lawyersDistributor);
    console.log(peopleLAW);
    console.log(userTypeSelect);
    forceUpdate();
});
if(localStorage.getItem('userType')==3)
fetch('https://telesiness.ir/api/Dashboards/GetCustomerUserConnectionsListOnMessage' , {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
body: JSON.stringify({ Email: localStorage.getItem('email'),
UserAudienceType:e.target.value,
})
}).then(response => response.json()).then(response=>{
 var i =0;
 var j = 0;
 try{
    for(j = 0; j<peopleLAW.length;j++)
    peopleLAW.pop()
    if(e.target.value==2)
    if(peopleLAW.length<2)
    {
        peopleLAW.pop();
        for (i in response.customerLawyer){
            // console.log(response.customerLawyer[i].distributorFirstName);
            // peopleLAW[i].AgentName = response.customerLawyer[i].distributorFirstName + " " + response.customerLawyer[i].distributorLastName;
            // peopleLAW[i].AgentClients = response.customerLawyer[i].clientsCount;
            // peopleLAW[i].AgentPhone = response.customerLawyer[i].distributorCode;
            peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.customerLawyer[i].audienceFirstName + " " + response.customerLawyer[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:response.lawyersDistributor[i].telesinessAudienceCode},)
        }
    }
   
    
 }

catch{}
// console.log(response.distributorList[0].distributorFirstName);
//   forceUpdate();
// window.location.reload(false);

forceUpdate();
});

if(localStorage.getItem('userType')==4)
fetch('https://telesiness.ir/api/Dashboards/GetmarketerUserConnectionsListOnMessage' , {
method: 'POST',
headers: { 'Content-Type': 'application/json' },
body: JSON.stringify({ Email: localStorage.getItem('email'),
UserAudienceType:e.target.value,
})
}).then(response => response.json()).then(response=>{
 var i =0;
 var j = 0;
 try{
    for(j = 0; j<peopleLAW.length;j++)
    peopleLAW.pop()
    if(e.target.value==2)
    {
        for (i in response.marketerDistributors){
            // console.log(response.marketerDistributors[i].distributorFirstName);
            // peopleLAW[i].AgentName = response.marketerDistributors[i].distributorFirstName + " " + response.marketerDistributors[i].distributorLastName;
            // peopleLAW[i].AgentClients = response.marketerDistributors[i].clientsCount;
            // peopleLAW[i].AgentPhone = response.marketerDistributors[i].distributorCode;
            console.log(response.marketerDistributors);
            peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.marketerDistributors[i].audienceFirstName + " " + response.marketerDistributors[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:response.marketerDistributors[i].telesinessAudienceCode},)
        }
        console.log(response.marketerDistributors[i]);
    }
    if(e.target.value==3)
    if(peopleLAW.length<2)
    {
        peopleLAW.pop();
        for (i in response.marketerDistributors){
            // console.log(response.marketerDistributors[i].distributorFirstName);
            // peopleLAW[i].AgentName = response.marketerDistributors[i].distributorFirstName + " " + response.marketerDistributors[i].distributorLastName;
            // peopleLAW[i].AgentClients = response.marketerDistributors[i].clientsCount;
            // peopleLAW[i].AgentPhone = response.marketerDistributors[i].distributorCode;
            peopleLAW.push({AgentPic:'CusPic.png', AgentName:response.marketerDistributors[i].audienceFirstName + " " + response.marketerDistributors[i].audienceLastName,AgentPhone:'',AgentStatus:'Active',AgentClients:'', Email:'kambiz.fakhr@yahoo.com',key:response.marketerDistributors[i].telesinessAudienceCode},)
        }
    }
    
 }

catch{ console.log('error');}

forceUpdate();
});

if(e.target.value==5){
document.getElementById('SelectYourUser2').style.display='none';
document.getElementById('Subject').style.display='flex';
}else
{
    document.getElementById('SelectYourUser2').style.display='flex';
    document.getElementById('Subject').style.display='none'; 
}
    }
    return ( 
        <div className="HomeMainRow MsgMargin col-12">
            <div className="HomeRow2 col-12">
                <div className="total_HomeLightBoxRowTwo box_message col-lg-12 col-12">
                    <div className="box_HomeLightBoxRowTwo form_mesages col-lg-7 col-12">
                        <form action="" onSubmit={handleSubmit} className="HomeLightBoxRowTwo">                          
                            <div className="HomeR2R1Msg">
                                <p>{t('Messages')}</p>
                            </div>
                            <div className="HomeR2R2">
                                        <p>{t('Messaging System is Online tools for communication between our users, also you can send message to anybody.')} </p>
                                    <div className="mainRowCusHomeR2 col-12">
                                        <div className="Cus2Inputs">
                                            <div className="inputAndlabel col-lg-12 col-12">
                                                <select
                                                    aria-label="Default"
                                                    // labelId="demo-simple-select-Degree-helper-label"
                                                    id="SelectYourUser"
                                                    value={userTypeSelect}
                                                    label={t('Select Your User Type')}
                                                    // MenuProps={MenuProps}
                                                    onChange={myfuncToUserType}  
                                                    placeholder={t('Select Your User Type')}
                                                    defaultValue= {localStorage.getItem('MsgUserNewType')}
                                                    defaultChecked ={true}
                                                    >
                                                        <option  value="">
                                                        {t('Select Your User Type')}
                                                    </option >
                                                    {<option className='TelesinessSupport' value='5'>{t('Telesiness Support')}</option >}
                                                    {<option  value='2'>{t('Agent')}</option >}

                                                    {localStorage.getItem('userType')!='4'&& <option  value='1'>{t('Lawyer')}</option >}

                                                    {localStorage.getItem('userType')!='3'&& <option  value='3'>{t('Customer')}</option >}

                                                    {(localStorage.getItem('userType')=='2' || localStorage.getItem('userType')=='3')&&<option  value='4'>{t('Marketer')}</option >}

                                                </select >
                                                {/* <label htmlFor="To">{t('Enter the applicants Type of Military Card')}</label> */}
                                            </div>
                                            <div className="inputAndlabel col-lg-12 col-12">
                                           
                                            <select
                                                    aria-label="Default"
                                                    // labelId="demo-simple-select-Degree-helper-label"
                                                    id="SelectYourUser2"
                                                    value={values.SelectYourUser2}
                                                    label={t('Select Your User')}
                                                    // MenuProps={MenuProps}
                                                    onChange={handleChange('SelectYourUser2')}  
                                                    placeholder={t('Select Your User')}
                                                    >
                                                        {!localStorage.getItem('MsgUserNewName') && <option  value="">
                                                        {t('Select Your User')}
                                                    </option >}
                                                    {localStorage.getItem('MsgUserNewName') && <option  value="">
                                                        {localStorage.getItem('MsgUserNewName')}
                                                    </option >}
                                                    {peopleLAW.map(ozv =>(
                                                        <option  value={ozv.key}>{ozv.AgentName}</option >
                                                    ))}
                                                   
                                                  

                                                </select >
                                            
                                            </div>

                                            {/* <div className="inputAndlabel col-lg-12 col-12">
                                                <input
                                                    id="To" 
                                                    value={values.To}
                                                    type="text"
                                                    placeholder={t('To')+':'}
                                                    onChange={handleChange('To')}
                                                    
                                                    />
                                            
                                            </div> */}
                                            <div className="inputAndlabel col-lg-12 col-12 SubjectDsiaplayMsg">
                                                <input
                                                    id="Subject" 
                                                    value={values.Subject}
                                                    type="text"
                                                    placeholder={t('Subject')+':'}
                                                    onChange={handleChange('Subject')}
                                                    
                                                    />
                                                {/* <label htmlFor="Subject">{t('Enter the applicants Type of Military Card')}</label> */}
                                            </div>
                                            <div className="inputAndlabel col-lg-12 col-12">
                                                <textarea
                                                    id="UserMessage" 
                                                    value={values.UserMessage}
                                                    type="text"
                                                    placeholder={t('Type here...')}
                                                    onChange={handleChange('UserMessage')}
                                                    className="MessageTextArea"
                                                    />
                                                <label htmlFor="UserMessage">{t('Enter Your message in the box.')}</label>
                                            </div>
                                        </div>
                                        <div className="sendmessageBtn">
                                <button className="btn btn-dark msgBtn">{t('Send Message')}</button>
                            </div>
                                    </div>
                        
                            </div> 
                        </form>
                    </div>
                    <div className="box_HomeLightBoxRowTwo archive_mesages col-lg-5 col-12">
                        <div className="HomeLightBoxRowTwo">
                                <div className="mainRowCusHomeR2 col-12" id='msgList'>
                                    {peopleA.map(item =>(
                                        <div>
                                            {item.messageStatus==0 && <div className="itemBoxMsg2 col-12"  key={item.key} onClick={()=>handleClickMsg(item.key,item.AgentPic,item.usrMsgType,item.submitDate,item.AgentName,item.userSenderCode,item.telesinessUserAudience)}>
                                                {/* <div className="col-1"><p>
                                                <input type="checkbox"></input>
                                                    </p> </div> */}
                                                {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                            
                                                <div className="CusPicMsg col-lg-3 col-12">
                                                    {/* <img className='msgPic' src={`${baseUrl + item.AgentPic}`} alt="" /> */}
                                                    {/* {localStorage.getItem('userAvatar')=='UserAvatar.png' &&<img src={`${baseUrl + item.AgentPic}`} alt="Avatar" />} */}
                                                    {item.usrMsgType=='2' && <img src={'https://telesiness.ir/AgentsAvatar/'+ item.AgentPic} alt="Avatar" />}
                                                    {item.usrMsgType=='1' && <img src={'https://telesiness.ir/LawyersAvatar/'+ item.AgentPic} alt="Avatar" />}
                                                    {item.usrMsgType=='3' && <img src={'https://telesiness.ir/CustomersAvatar/'+ item.AgentPic} alt="Avatar" />}
                                                    {item.usrMsgType=='4' && <img src={'https://telesiness.ir/MarketersAvatar/'+ item.AgentPic} alt="Avatar" />}
                                                </div>
                                                {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                                {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                                { <div className="col-lg-9 col-12 SenderNameAndMsg">
                                                    <div className="msgRD2M1 col-12">
                                                        <div className='usernamesent'><p className='SenderNameStyle'><b>{item.AgentName}</b> </p></div> 
                                                        <div className='timesent'><p>{item.submitDate}</p></div> 
                                                    </div>
                                                    <div className="msgRD2M1 col-12">
                                                        <div className='msgBodyInList'><p>{item.userMessage}</p></div> 
                                                    </div>
                                                </div>}
                                             </div>}


                                             {item.messageStatus!=0 && <div className="itemBoxMsg col-12"  key={item.key} onClick={()=>handleClickMsg(item.key,item.AgentPic,item.usrMsgType,item.submitDate,item.AgentName,item.userSenderCode,item.telesinessUserAudience)}>
                                                {/* <div className="col-1"><p>
                                                <input type="checkbox"></input>
                                                    </p> </div> */}
                                                {/* <div className="col-1"><p>{item.key}</p> </div> */}
                                            
                                                <div className="CusPicMsg col-lg-3 col-12">
                                                    {/* <img className='msgPic' src={`${baseUrl + item.AgentPic}`} alt="" /> */}
                                                    {/* {localStorage.getItem('userAvatar')=='UserAvatar.png' &&<img src={`${baseUrl + item.AgentPic}`} alt="Avatar" />} */}
                                                    {item.usrMsgType=='2' && <img src={'https://telesiness.ir/AgentsAvatar/'+ item.AgentPic} alt="Avatar" />}
                                                    {item.usrMsgType=='1' && <img src={'https://telesiness.ir/LawyersAvatar/'+ item.AgentPic} alt="Avatar" />}
                                                    {item.usrMsgType=='3' && <img src={'https://telesiness.ir/CustomersAvatar/'+ item.AgentPic} alt="Avatar" />}
                                                    {item.usrMsgType=='4' && <img src={'https://telesiness.ir/MarketersAvatar/'+ item.AgentPic} alt="Avatar" />}
                                                </div>
                                                {/* <div className="col-2 PhoneDirection"><p>{item.CustomerPhone}</p></div> */}
                                                {/* <div className="col-2"><p>{item.CustomerAgent}</p></div> */}
                                                { <div className="col-lg-9 col-12 SenderNameAndMsg">
                                                    <div className="msgRD2M1 col-12">
                                                        <div><p className='SenderNameStyle'><b>{item.AgentName}</b> </p></div> 
                                                        <div className='timesent'><p>{item.submitDate}</p></div> 
                                                    </div>
                                                    <div className="msgRD2M1 col-12">
                                                        <div className='msgBodyInList'><p>{item.userMessage}</p></div> 
                                                    </div>
                                                </div>}
                                             </div>}
                                        </div>
                                    ))}
                                </div>
                                <div className="mySelectedMsg" id='mySelectedMsg'>
                                    <div className="MessageBody" >
                                        <ArrowLeft2 className="BackToMsgList"  onClick={()=>handleClickBackMsg()} size="18" color="#333333"/>
                                        <div className="CusPicMsg col-3">
                                            {/* <img className='msgPic' src={localStorage.getItem('Msgsrc')} alt="" /> */}
                                                {localStorage.getItem('usrMsgType')=='2' && <img src={'https://telesiness.ir/AgentsAvatar/'+ localStorage.getItem('Msgsrc')} alt="Avatar" />}
                                                {localStorage.getItem('usrMsgType')=='1' && <img src={'https://telesiness.ir/LawyersAvatar/'+ localStorage.getItem('Msgsrc')} alt="Avatar" />}
                                                {localStorage.getItem('usrMsgType')=='3' && <img src={'https://telesiness.ir/CustomersAvatar/'+ localStorage.getItem('Msgsrc')} alt="Avatar" />}
                                                {localStorage.getItem('usrMsgType')=='4' && <img src={'https://telesiness.ir/MarketersAvatar/'+ localStorage.getItem('Msgsrc')} alt="Avatar" />}
                                            </div>
                                        <div className="col-9 SenderNameAndMsg">
                                            <div className="msgRD2M1 col-12">
                                                <div><p className='SenderNameStyle'><b>{localStorage.getItem('msgAgentName')}</b></p></div>
                                                <div className='timesent'><p>{localStorage.getItem('submitDate')}</p></div>
                                               
                                            </div>
                                            <div className="msgRD2M1 col-12">
                                                {localStorage.getItem('usrMsgType')=='1' &&<p className='msgBodyInList'>{t('Lawyer')}</p>}
                                                {localStorage.getItem('usrMsgType')=='2' &&<p className='msgBodyInList'>{t('Distributor')}</p>}
                                                {localStorage.getItem('usrMsgType')=='3' &&<p className='msgBodyInList'>{t('Customer')}</p>}
                                                {localStorage.getItem('usrMsgType')=='4' &&<p className='msgBodyInList'>{t('Agent')}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    
                                    {MessageMsgs.map(item =>(
                                        <div>
                                            {localStorage.getItem('usrMsgType')=='1' &&
                                            localStorage.getItem('lawyerCode')==item.userSenderCode &&
                                            <div className="dirMainMsgMe col-12">
                                                <div className="MessageBodyMainMe col-8">
                                                    <p>{item.userMessage}</p>
                                                </div>
                                            </div>}

                                            {localStorage.getItem('usrMsgType')=='1' &&
                                            localStorage.getItem('lawyerCode')==item.telesinessUserAudience &&
                                            <div className="dirMainMsgYou col-12">
                                                <div className="MessageBodyMainYou col-8">
                                                    <p>{item.userMessage}</p>
                                                </div>
                                            </div>}


                                            {localStorage.getItem('usrMsgType')=='2' &&
                                            localStorage.getItem('AgentCode')==item.userSenderCode &&
                                            <div className="dirMainMsgMe col-12">
                                                <div className="MessageBodyMainMe col-8">
                                                    <p>{item.userMessage}</p>
                                                </div>
                                            </div>}

                                            {localStorage.getItem('usrMsgType')=='2' &&
                                            localStorage.getItem('AgentCode')==item.telesinessUserAudience &&
                                            <div className="dirMainMsgYou col-12">
                                                <div className="MessageBodyMainYou col-8">
                                                    <p>{item.userMessage}</p>
                                                </div>
                                            </div>}


                                            {localStorage.getItem('usrMsgType')=='3' &&
                                            localStorage.getItem('customerCode')==item.userSenderCode &&
                                            <div className="dirMainMsgMe col-12">
                                                <div className="MessageBodyMainMe col-8">
                                                    <p>{item.userMessage}</p>
                                                </div>
                                            </div>}

                                            {localStorage.getItem('usrMsgType')=='3' &&
                                            localStorage.getItem('customerCode')==item.telesinessUserAudience &&
                                            <div className="dirMainMsgYou col-12">
                                                <div className="MessageBodyMainYou col-8">
                                                    <p>{item.userMessage}</p>
                                                </div>
                                            </div>}



                                            {localStorage.getItem('usrMsgType')=='4' &&
                                            localStorage.getItem('marketerCode')==item.userSenderCode &&
                                            <div className="dirMainMsgMe col-12">
                                                <div className="MessageBodyMainMe col-8">
                                                    <p>{item.userMessage}</p>
                                                </div>
                                            </div>}

                                            {localStorage.getItem('usrMsgType')=='4' &&
                                            localStorage.getItem('marketerCode')==item.telesinessUserAudience &&
                                            <div className="dirMainMsgYou col-12">
                                                <div className="MessageBodyMainYou col-8">
                                                    <p>{item.userMessage}</p>
                                                </div>
                                            </div>}
                                        </div>
                                        
                                        
                                    ))}
                                  
                                   
                                    <div className="dirMainMsgMe col-12" id='MymsgBoxMsg'>
                                        <div className="MessageBodyMainMe col-8">
                                            <p>{values.SendMsg}</p>
                                        </div>
                                    </div>
                                    <Paper
                                        component="form"
                                        sx={{ p: '20px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                                        >
                                        <IconButton sx={{ p: '10px' }} aria-label="Message">
                                            <MessageIcon />
                                        </IconButton>
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder={t('Type here your message')}
                                            inputProps={{ 'aria-label': 't("Type here your message")' }}
                                            value= {values.SendMsg}
                                            onChange ={handleChange('SendMsg')}
                                        />
                                        
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <IconButton onClick={()=>{handleSendMsgIn3Dmodel()}} color="primary" sx={{ p: '10px' }} aria-label="Send">
                                            <SendTwoToneIcon />
                                        </IconButton>
                                    </Paper>   
                                    </div>
                                </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
                
     );
}
 
export default Home;