import onePic from './images/one.png';
import twoPic from './images/two.png';
import Carousel from 'react-elastic-carousel';
import {AddSquare,ArrowDown2,Warning2,Calendar2,Briefcase} from 'iconsax-react';
import Popup from './Popup';
import { useState,useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const Home = () => {


    const {t, i18n} = useTranslation(['Login']);
    const forceUpdate = useForceUpdate();
    const [people , setPeople] = useState([
        {visa:'false',CustomerPic:'CusPic.png', CustomerName:'behzad',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'1'},
        {visa:'false',CustomerPic:'CusPic.png',CustomerName:'nariman',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Nov 30 ,2021 ', CustomerAgent:'BaBaii',key:'2'},
        {visa:'true',CustomerPic:'alireza.png',CustomerName:'fatemeh',CustomerPhone:'+989143158460',CustomerStatus:'Deactive',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'3'},
        {visa:'false',CustomerPic:'alireza.png',CustomerName:'parisa',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'4'},
        {visa:'false',CustomerPic:'CusPic.png',CustomerName:'behzad',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'5'},
        {visa:'false',CustomerPic:'CusPic.png',CustomerName:'nariman',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'6'},
        {visa:'false',CustomerPic:'alireza.png',CustomerName:'fatemeh',CustomerPhone:'+989143158460',CustomerStatus:'Deactive',CustomerCreated:'Sep 30 ,2021 ', CustomerAgent:'BaBaii',key:'7'},
        {visa:'false',CustomerPic:'CusPic.png',CustomerName:'parisa',CustomerPhone:'+989143158460',CustomerStatus:'Active',CustomerCreated:'Sep 30 ,2020 ', CustomerAgent:'BaBaii',key:'8'},
      ]);

      const [peopleA , setPeopleA] = useState([
        {AgentPic:'CusPic.png', AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129121'},
        {AgentPic:'CusPic.png',AgentName:'nariman',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'8 ', Email:'kambiz.fakhr@yahoo.com',key:'129122'},
        {AgentPic:'alireza.png',AgentName:'fatemeh',AgentPhone:'+989143158460',AgentStatus:'Deactive',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129123'},
        {AgentPic:'alireza.png',AgentName:'parisa',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129124'},
        {AgentPic:'CusPic.png',AgentName:'behzad',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129125'},
        {AgentPic:'CusPic.png',AgentName:'nariman',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129126'},
        {AgentPic:'alireza.png',AgentName:'fatemeh',AgentPhone:'+989143158460',AgentStatus:'Deactive',AgentClients:'24 ', Email:'kambiz.fakhr@yahoo.com',key:'129127'},
        {AgentPic:'CusPic.png',AgentName:'parisa',AgentPhone:'+989143158460',AgentStatus:'Active',AgentClients:'31 ', Email:'kambiz.fakhr@yahoo.com',key:'129128'},
      ]);

      const [values, setValues] = useState({
        Lname: '',
        Lphone: '',
        LEmail: '',
        Lcode: '',
        Dname: '',
        Dphone: '',
        DEmail: '',
        Dcode: '',
        DCountry:'',
        DCity :'',
        visaProcess: '',
        requiredDocuments:'',
        nameOfVisa:'',

        showPassword: false,
      });
      const handleStatusClick = (itemKey) =>{
        if(document.getElementById(itemKey).style.display == "flex")
        {
            document.getElementById(itemKey).style.display = "none"
        }
        else{
            document.getElementById(itemKey).style.display = "flex";
        }
    }
    const handleDeactiveClick = (itemKey) =>{
        setIsOpen(!isOpen);
        for(var i in people){
            if(people[i].key==itemKey){
                people[i].CustomerStatus='Deactive';
                setVisaId(i);
            }
            
        }
        forceUpdate() ;
        setPeople(people);
    
    }
    const handleActiveClick = (itemKey) =>{
        for(var i in people){
            if(people[i].key==itemKey)
            people[i].CustomerStatus='Active';
        }
       forceUpdate() ;
       setPeople(people);
   }
   const [isOpen, setIsOpen] = useState(false);
   const [isOpen2, setIsOpen2] = useState(false);
   const [isOpen3, setIsOpen3] = useState(false);
   const [visaID,setVisaId]=useState(-1);
   const handleVisaPbox = (itemKey) =>{
    people[visaID].visa='Visa';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}

const handleRejectedPbox = (itemKey) =>{
    people[visaID].visa='Rejected';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}


const handleDeclinedPbox = (itemKey) =>{
    people[visaID].visa='Declined';
    forceUpdate() ;
    setPeople(people);
    setIsOpen(!isOpen);
}
const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const togglePopup2 = () => {
      setIsOpen2(!isOpen2);
    }
    const togglePopup3 = () => {
        setIsOpen3(!isOpen3);
      }
    const handleAddNewUserCustomer = (itemKey) =>{
        setIsOpen2(!isOpen2);
    }
    const handleAddNewUserAgent = (itemKey) =>{
        setIsOpen3(!isOpen3);
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      }
      const handleAddUserSendEmail = (itemKey) =>{
        // fetch('https://telesiness.ir/api/InvitationSystem/InviteCustomerByLawyer' , {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ CustomerEmailAddress: values.Email ,LawyerCode:localStorage.getItem('lawyerCode') })
        //     }).then().then(setIsOpen2(!isOpen2));
        setIsOpen2(!isOpen2)
        
    }
    const handleAddUserAgentSendEmail = (itemKey) =>{
        // fetch('https://telesiness.ir/api/InvitationSystem/InviteDistributorsByLawyer' , {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ DistributorEmailAddress: values.EmailA ,LawyerCode:localStorage.getItem('lawyerCode') })
        //     }).then().then(setIsOpen2(!isOpen3));
        setIsOpen3(!isOpen3)
        
    }
    fetch('https://telesiness.ir/api/Dashboards/CustomerLawyer' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: localStorage.getItem('email'), CustomerCode:localStorage.getItem('customerCode') })
        }).then(response => response.json()).then(response=>{
             var i =0;
             try
             {
               values.Lname = response.customerLawyer.lawyerFirstName + ' ' + response.customerLawyer.lawyerLastName;
               values.Lphone = response.customerLawyer.lawyerCode;
               values.LEmail = response.customerLawyer.lawyerEmailAddress;
               values.Lcode = response.customerLawyer.lawyerCode;
            
             }

            catch{

            }
            // console.log(response[0]);
            
        });

        fetch('https://telesiness.ir/api/Dashboards/CustomerDistributor' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: localStorage.getItem('email'), CustomerCode: localStorage.getItem('customerCode'), })
            }).then(response => response.json()).then(response=>{
                 var i =0;
                 try
                 {
                   values.Dname = response.customerDistributor.distributorFirstName + ' ' + response.customerDistributor.distributorLastName;
                   values.Dphone = response.customerDistributor.distributorPhoneNumber;
                   values.DEmail = response.customerDistributor.distributorEmailAddress;
                   values.Dcode = response.customerDistributor.distributorCode;
                   values.DCountry = response.customerDistributor.distributorCountry;
                   values.DCity = response.customerDistributor.distributorCity;
                   
                 }
    
                catch{
    
                }
                // console.log(response[0]);
                
            });

            fetch('https://telesiness.com/api/Dashboards/CustomerVisaStatusProgress' , {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: localStorage.getItem('email')})
                }).then(response => response.json()).then(response=>{
                     var i =0;
                     try
                     {
                       values.visaProcess = response.customerVisaProgressVisa[0].visaProcess ;
                       values.requiredDocuments = response.customerVisaProgressVisa[0].requiredDocuments;
                       values.nameOfVisa = response.customerVisaProgressVisa[0].nameOfVisa;
                      console.log(response);
                     }
        
                    catch{
        
                    }
                    // console.log(response[0]);
                    
                });

        const [state, setState] = useState({ num: 0 });
        const counter = useRef(0);
        
        useEffect(() => {
          if (counter.current < 4) {
            counter.current += 1;
            const timer = setTimeout(() => setState({ num: state.num + 1 }), 1000);
      
            return () => clearTimeout(timer);
          }
        }, [state]);

        // fetch('https://telesiness.ir/api/CustomerDocuments/GetDataEvaluationForm' , {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json; charset=utf-8' },
        //     body: JSON.stringify({ Email: localStorage.getItem('email') ,CustomerCode:localStorage.getItem('customerCode') })
        //   }).then(response => response.json()).then(response=>{
        //     if(response.customerEvaluationFormLastName!='')
        //     localStorage.setItem('firstTimeCustomer',true);
        //     else
        //     localStorage.setItem('firstTimeCustomer',false);
        //     console.log(response);
        //     console.log(localStorage.getItem('firstTimeCustomer'));
        //   }).catch(()=>{console.log('error');})

    return ( 
        <div className="HomeMainRow col-12">

            <div className="HomeRow2 home_dashpan col-12">
                <div className="total_HomeLightBoxRowTwo col-lg-10 col-12">
                <Carousel 
                itemsToShow={1} 
                className="col-12 d-lg-none"
                enableMouseSwipe
                showArrows={false}>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeR2R1 outer_box">
                            <p>{t('Lawyer Information')}</p>
                        </div>
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Lawyer Information')}</p>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    <div className="img_CusHomeR2">
                                        <img src={onePic} alt="" />
                                    </div>
                                    <p className='CusHomeP'><b>{values.Lname}</b></p>
                                    <p className='CusHomePGray'>{values.Lphone}</p>
                                    <p className='CusHomeP'>{t('Email Address')} :{values.LEmail}</p>
                                    <p className='CusHomeP'>{t('Advocacy Code')} :{values.Lcode}</p>
                                </div>
                            </div>  
                        </div>  
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12">
                        <div className="HomeR2R1 outer_box">
                            <p>{t('Distributer Information')}</p>
                        </div>
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Distributer Information')}</p>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                    <div className="img_CusHomeR2">
                                        <img src={twoPic} alt="" />
                                    </div>
                                    <p className='CusHomeP'><b>{values.Dname}</b></p>
                                    <p className='CusHomePGray'>{values.Dcode}</p>
                                    <p className='CusHomeP'>{t('Email Address')}: {values.DEmail}</p>
                                    <p className='CusHomeP'>{t('Phone Number')}:{values.Dphone}</p>
                                    <p className='CusHomePGray'>{values.DCity}, {values.DCountry}</p>
                                </div>
                            </div> 
                        </div>  
                    </div>
                    </Carousel> 
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12 d-none d-lg-block">
                        <div className="HomeR2R1 outer_box">
                            <p>{t('Lawyer Information')}</p>
                        </div>
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Lawyer Information')}</p>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                <div className="img_CusHomeR2">
                                        <img src={onePic} alt="" />
                                    </div>
                                    <p className='CusHomeP'><b>{values.Lname}</b></p>
                                    <p className='CusHomePGray'>{values.Lphone}</p>
                                    <p className='CusHomeP'>{t('Email Address')} :{values.LEmail}</p>
                                    <p className='CusHomeP'>{t('Advocacy Code')} :{values.Lcode}</p>
                                </div>
                            </div>  
                        </div>  
                    </div>
                    <div className="box_HomeLightBoxRowTwo col-lg-6 col-12 d-none d-lg-block">
                        <div className="HomeR2R1 outer_box">
                            <p>{t('Agent Information')}</p>
                        </div>
                        <div className="HomeLightBoxRowTwo">
                            <div className="HomeR2R1">
                                <p>{t('Agent Information')}</p>
                            </div>
                            <div className="HomeR2R2">
                                <div className="mainRowCusHomeR2 col-12">
                                <div className="img_CusHomeR2">
                                        <img src={twoPic} alt="" />
                                    </div>
                                    <p className='CusHomeP'><b>{values.Dname}</b></p>
                                    <p className='CusHomePGray'>{values.Dcode}</p>
                                    <p className='CusHomeP'>{t('Email Address')}: {values.DEmail}</p>
                                    <p className='CusHomeP'>{t('Phone Number')}:{values.Dphone}</p>
                                    <p className='CusHomePGray'>{values.DCity}, {values.DCountry}</p>
                                </div>
                            </div> 
                        </div>  
                    </div>
                </div>
                <div className="box_HomeLightBoxRowTwo col-lg-2 col-md-12 col-12">
                    <div className="HomeLightBoxRowTwo HomeCol3Row2">
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox">
                                <Calendar2 size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Registery Date')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('registerDate').split('T',1)}</p>
                        </div>
                        <div className="HomeCol3Row2_2 col-lg-12 col-4">
                            <div className="svg_HomeLightBox">
                                <Briefcase className='HomeBrifcasePad' size="35" color="#333333" variant="Outline"/>
                            </div>
                            <p className='HomeCol3P1'>{t('Agency Code')}</p>
                            <p className='HomeCol3P2'>{localStorage.getItem('customerCode')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="HomeRow2 col-12">
                <div className="box_HomeLightBoxRowTwo col-12">
                    <div className="HomeLightBoxRowTwo">
                        <div className="HomeR3R1">
                            {localStorage.getItem('verifyCustomerByDistributor')=='0' &&  
                            localStorage.getItem('lawyerReferenceCode') == 'null' && <p>{t('There is no information to display')}</p>}

                            {localStorage.getItem('verifyCustomerByDistributor')=='0'&& 
                            localStorage.getItem('lawyerReferenceCode') != 'null' &&<p>{t('Congratulations!!! your visa has been approved')}</p>}
                             {localStorage.getItem('verifyCustomerByDistributor')=='0'&& 
                            localStorage.getItem('lawyerReferenceCode') != 'null' &&<><br /><p>{t('your reqiured document is:') + values.requiredDocuments}</p></>}

                            {localStorage.getItem('verifyCustomerByDistributor')=='1'&& 
                            localStorage.getItem('lawyerReferenceCode')== 'null' &&<p>{t('Opps, We try with other Lawyer')}</p>}

                            {localStorage.getItem('verifyCustomerByDistributor')=='1'&& 
                            localStorage.getItem('lawyerReferenceCode')!= 'null' &&<p className='Congratulations'>{t('Congratulations!!! your visa process Started be in touch')}</p>}
                            {localStorage.getItem('verifyCustomerByDistributor')=='1'&& 
                            localStorage.getItem('lawyerReferenceCode') != 'null' && <><br /><p>{t('your reqiured document is: ')}<b>{values.requiredDocuments}</b></p></>}
                            {localStorage.getItem('verifyCustomerByDistributor')=='1'&& 
                            localStorage.getItem('lawyerReferenceCode') != 'null' && <><br /><p>{t('your visa name is: ')} <b>{values.nameOfVisa}</b></p></>}
                            {localStorage.getItem('verifyCustomerByDistributor')=='1'&& 
                            localStorage.getItem('lawyerReferenceCode') != 'null' && <><br /><p>{t('your visa process is: ') } <b>{values.visaProcess}</b></p></>}

                            {localStorage.getItem('verifyCustomerByDistributor')=='2' &&<p>{t('Your Document was Closed')}</p>}
                        </div> 
                    </div>
                </div>
            </div>
            {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <Warning2 size="32" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Why You wanna De-active this case?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please select the reason for deactivating this case.')}</p>
            </div>
            <div className="PboxForthRow col-12">
                <button onClick={handleVisaPbox} className="col-md-3 col-12 btn btn-success">{t('Visa')}</button>
                <button onClick={handleRejectedPbox} className="col-md-3 col-12 btn btn-danger">{t('Rejected')}</button>
                <button onClick={handleDeclinedPbox} className="col-md-3 col-12 btn btn-light">{t('Declined')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
     {isOpen2 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
               
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Adding a new Customer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the Customer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">

                    <input value={values.Email} onChange={handleChange('Email')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup2}
    />}
    {isOpen3 && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
            </div>
            <div className="PboxSecondRow">
                <h4>{t('Adding a New Lawyer')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('Please enter the email of the lawyer you want to cooperate with.')}</p>
            </div>
            <div className="PboxForthRow2 col-12">
            <label>{t('Email Address')}</label>
                <div className="input-group mb-3">
                    <input value={values.EmailA} onChange={handleChange('EmailA')} type="email" id="email" name="email" className="form-control" placeholder={t('Type an Email address...')} aria-label="Type an Email adress..." aria-describedby="button-addon2"/>
                    <button onClick={handleAddUserAgentSendEmail} className="btn btn-primary" type="button" id="button-addon2">{t('Send Link!')}</button>
                </div>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup3}
    />}
        </div>
                
     );
}
 
export default Home;