import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,CloseCircle} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const Profile2 = ({childToParentCus5,childToParentCus7}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    const forceUpdate = useForceUpdate();
    //****************submits**********************************/
    const [cusJobs , setCusJobs] = useState([
      {Jobtitle: 'UI/UX', Jobposition: ' Designer', Nameofworkplace:'Adab Company', Startdateofjob:'2021', Departuredate:'2022', key:'1'},
      {Jobtitle: 'Front-End Developer', Jobposition: 'Developer', Nameofworkplace:'Adab Company & Telesiness', Startdateofjob:'2021', Departuredate:'2022', key:'2'},
      {Jobtitle: 'Back-End Devepoler', Jobposition: 'Developer', Nameofworkplace:'Adab Company', Startdateofjob:'2021', Departuredate:'2022', key:'3'},
      {Jobtitle: 'UI/UX', Jobposition: ' Designer', Nameofworkplace:'Adab Company', Startdateofjob:'2021', Departuredate:'2022', key:'4'},
    ]);
  const handleAddJob =(e) => {
    e.preventDefault();
    cusJobs.push({ 
      Jobtitle: values.Jobtitle,
      Jobposition: values.Jobposition, 
      Nameofworkplace:values.Nameofworkplace, 
      Startdateofjob:values.Startdateofjob, 
      Departuredate:values.Departuredate,
      key:(cusJobs.length+1).toString(),
     },);
      forceUpdate();
      setCusJobs(cusJobs);
  }
  const handleRemoveCusJobs = (itemKey)=>{
    setCusJobs(cusJobs.filter(cusJobs => cusJobs.key != itemKey.toString()));
  }
  const handleSubmitPersonal = (e) => {
         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
        // fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepOne' , {
        //   method: 'PUT',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ 
        //     // Email: localStorage.email3 ,
        //     // Password:localStorage.password3 ,
        //     FullName:"",
        //     Gender:"",
        //     DateOfBirth:"",
        //     AboutMe:""
        //   })
        //   }).then().then(history.push('/Dashboard'));
        // //     setIspending(false);
        // document.getElementById('personalCancel').style.display="none";
        // document.getElementById('personalSave').style.display="none";
        // document.getElementById('personPen').style.display="none";
        // document.getElementById('personPenBlue').style.display="none";
        // document.getElementById('proFirstName').setAttribute("disabled","disabled");
        // document.getElementById('proLastName').setAttribute("disabled","disabled");
        // document.getElementById('Gender').setAttribute("disabled","disabled");
        // //document.getElementById('birthDate').setAttribute("disabled","disabled");
     
        // document.getElementById('inputproAbout').setAttribute("disabled","disabled");
        // setValues({ ...values, ['firstTimeEditPersonal']: false});
            // history.push('/Dashboard');
        // }
        // )
    }

        const [values, setValues] = useState({
 
          Monthlyturnover: '',
          amountofcash: '',
          Income: '',
          Noncashassets:'',
          Startdateofjob:'',
          Departuredate:'',
  
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });

        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus7 = true;
        const dataCus5 = true;
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <form onSubmit={handleSubmitPersonal} className="col-12">
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">Step 1 :</p><p className="Cus2Step1BlackP">Complete Basic Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div> <p className="Cus2Step1SmallP">Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy</p></div>
          <div className="Cus2PersonalInfo"> <p>Financial Information</p> </div>
          <hr width="55px" className="Cus2Step1UnderLine"/>
          <div className="Cus2Inputs">
            <div className="inputAndlabel col-4">
            <input
                id="Monthlyturnover" 
                value={values.Monthlyturnover}
                type="text"
                placeholder="Monthly turnover"
                onChange={handleChange('Monthlyturnover')}
                />
                <label htmlFor="Monthlyturnover">Enter The applicant's monthly turnover</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="amountofcash" 
                value={values.amountofcash}
                type="text"
                placeholder="Amount of cash"
                onChange={handleChange('amountofcash')}
                
                />
                <label  htmlFor="amountofcash">Enter the applicant approximate amount of cash for financial means</label>
            </div>
            <div className="inputAndlabel col-4">
              <input
                id="Income" 
                value={values.Income}
                type="text"
                placeholder="Income"
                onChange={handleChange('Income')}
                
                />
                <label  htmlFor="Income">Enter the salary received by the applicant</label>
            </div>
            <div className="inputAndlabel col-12">
              <textarea
                id="Noncashassets" 
                value={values.Noncashassets}
                type="text"
                placeholder="Non-cash assets"
                onChange={handleChange('Noncashassets')}
                />
                <label htmlFor="Noncashassets">Explain about the applicant's non-cash assets</label>
            </div>
            {/* <div className="mainGrayListRows col-12">
              {cusJobs.map(item =>(
                      <div key={item.key}>
                        <div className="grayListRow col-12">
                          <div className="GrayListBox">
                            <p><b>{item.Jobtitle}:</b>  {item.Jobposition , item.Nameofworkplace}</p>
                            <CloseCircle onClick={()=>handleRemoveCusJobs(item.key)} size="28" color="#9B9B9B" variant="Bold"/>
                          </div>
                        </div>
                      </div>))}
            </div> */}
           
          
            {/* <div className="inputAndlabel col-4">
              <a className="inputAndlabelAddA" onClick={handleAddJob}>+ Add</a>
            </div> */}
         </div>
         
          <div className="cusS1SaveColP6 col-12">
            <div className="col-6">
               <button  onClick={(dataCus5) => {childToParentCus5(dataCus5);}} className="btn col-12 btn-outline-light ">{t('Previous')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              <button className="btn col-6 btn-outline-light ">
                <ArchiveTick size="32" color="#D3A281"/>{t('Save Draft')}
              </button >
              {<button onClick={(dataCus7) => {childToParentCus7(dataCus7);}} className="btn btn-dark  col-6">{t('Next Step')}</button>}
            </div>
           
          </div>
        </div>
        </form>
        
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;