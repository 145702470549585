import { useState} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import RegisterLogo from './images/LogoRegister.png';
//*******************end of UI Materials */
import { useTranslation } from 'react-i18next'; // multi language package
//*******************************iconsax */
import { Briefcase, Home2 ,People,Trade,Hospital,
  Car,House,Airplane,MoneyChange,CodeCircle,
  Bank,Cake,Devices,Image,Pet,Scissor,
  Setting2,Smileys,Teacher,Truck,Weight,Crown,ShieldTick} from 'iconsax-react';
import { Profile2User } from 'iconsax-react';
import { Profile } from 'iconsax-react';
import Logo from './images/logo-tele.png';
//******************************** */
const ResetPassword = () => {
    //********************************fetching API */


    const history = useHistory();
    const handleSubmit = (e) => {
         e.preventDefault();
         localStorage.setItem('userType' , 1);
         history.push('/regstep2');

    }
    
    const handleSubmit2 = (e) => {
      e.preventDefault();
      localStorage.setItem('userType' , 2);
      history.push('/regastep2');

 }
 const handleSubmit3 = (e) => {
  e.preventDefault();
  localStorage.setItem('userType' , 3);
  history.push('/regcstep2');

}
const handleSubmit4 = (e) => {
  e.preventDefault();
  localStorage.setItem('userType' , 4);
  history.push('/regmstep2');

}
    //***************************************** */


//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);

//***************************************/
    return (
      <><div className="pagePic"></div>
          <div className="loginMainRow">
            <div className="rectangle sectionsLogin col-xxl-8 col-xl-9 col-12" id="main-row-2id">
                <div className="mainRegisterheader col-xxl-12 col-12">
                <div className="NewHeaderWithHomeIcone col-xxl-12 col-12">
                  <div className="subRegisterrightheader col-md-8 col-12">
                    <Link className="MyHomeNewReg d-md-flex d-none" to='/'>
                        <Home2 color="white" size="32" />
                    </Link>
                    <br/>
                    <h4 className="card-title font-face-gm">{t('Sign Up To Telesiness')}</h4>
                    <p className="RegisterHeaderp">{t('Access your specific features by selecting your user type.')}</p>
                  </div>
                  <div className="subRegisterleftheader col-md-4 col-12">
                    <img src={RegisterLogo} alt="" />
                    <Link className="font-face-gm backtohome ico_backhome" to='/'>
                        <Home2 color="white" size="24" />
                    </Link>
                  </div>
                  </div>
                </div>
                <div className="progressRow col-xxl-10 col-12">
                  <div className="myshapes">
                    <div className="mycircle">
                      <h6>2 of 6</h6> 
                    </div>
                    <div className="myProCircle"></div>
                  </div>
                  <div className="mytext">
                    <h5 className="step1">{t('Step 2')}</h5>
                    <h6 className="choosText">{t('Choose Your Business Service')}</h6>
                  </div>
                </div>
                <div className="selectProfile2 col-xxl-10 col-12">
                  {/* <div className="circle">
                    <Link onClick={handleSubmit} className="font-face-gm BriefcaseA" to='/regstep2'>
                      <Briefcase color="white" variant="Bold" size="50" />
                      <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Lawyer')}</span>
                    </Link>
                  </div> */}
                   <div className="CirRect" onClick={handleSubmit}>
                    <Link onClick={handleSubmit} className="font-face-gm BriefcaseA" to='/regstep2'>
                      <Briefcase color="white" variant="Bulk" size="50" />
                      <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Lawyer')}</span>
                    </Link>
                  </div>
                  <div className="CirRect CirRectDeactive">
                  <div className="font-face-gm BriefcaseA" to='/regastep2'>
                    <Hospital color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Doctor')}</span>
                    </div>
                  </div>
                  <div className="CirRect CirRectDeactive">
                  <div className="font-face-gm BriefcaseA" to='/regcstep3'>
                    <Car color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Car Service')}</span>
                    </div>
                  </div>
                  <div className="CirRect CirRectDeactive">
                  <div className="font-face-gm BriefcaseA" to='/regmstep2'>
                    <House color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Real Estate')}</span>
                    </div>
                  </div>
                  <div className="CirRect CirRectDeactive">
                    <div className="font-face-gm BriefcaseA" to='/regstep2'>
                      <ShieldTick color="white" variant="Bulk" size="50" />
                      <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Insurance')}</span>
                    </div>
                  </div>
                  <div className="CirRect CirRectDeactive">
                  <div className="font-face-gm BriefcaseA" to='/regastep2'>
                    <MoneyChange color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Trade')}</span>
                    </div>
                  </div>
                  {/* <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regcstep3'>
                    <CodeCircle color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Software Dev')}</span>
                    </div>
                  </div> */}
                  {/* <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regmstep2'>
                    <Bank color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Bank')}</span>
                    </div>
                  </div> */}
                  {/* <div className="CirRect CirRectDeactive">
                    <div className="font-face-gm BriefcaseA" to='/regstep2'>
                      <Cake color="white" variant="Bulk" size="50" />
                      <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Food')}</span>
                    </div>
                  </div> */}
                  <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regastep2'>
                    <Devices color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Degital Devices')}</span>
                    </div>
                  </div>
                  {/* <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regcstep3'>
                    <Image color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Designing')}</span>
                    </div>
                  </div> */}
                  {/* <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regmstep2'>
                    <Pet color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Pet Services')}</span>
                    </div>
                  </div> */}
                  <div className="CirRect CirRectDeactive">
                    <div className="font-face-gm BriefcaseA" to='/regstep2'>
                      <Scissor color="white" variant="Bulk" size="50" />
                      <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Beauty Services')}</span>
                    </div>
                  </div>
                  {/* <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regastep2'>
                    <Setting2 color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Reparing')}</span>
                    </div>
                  </div> */}
                  {/* <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regcstep3'>
                    <Smileys color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Art')}</span>
                    </div>
                  </div> */}
                  {/* <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regmstep2'>
                    <Teacher color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Educational')}</span>
                    </div>
                  </div>
                  <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regcstep3'>
                    <Truck color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Transporting')}</span>
                    </div>
                  </div> */}
                  <div className="CirRect CirRectDeactive">
                  <div  className="font-face-gm BriefcaseA" to='/regmstep2'>
                    <Crown color="white" variant="Bulk" size="50" />
                    <span className="css-1iz24ed-MuiChip-root MuiChip-icon">{t('Gold and Jewelry')}</span>
                    </div>
                  </div>
                </div>
            </div> 
            <div className="footerDasilav col-12">
              <div className="footer-copy">
                Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
              </div>
              <div className="footer-copy2">
                <br />
                Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. Privacy Policy
              </div>
            </div>
          </div>
      </>
           
    );
}
 
export default ResetPassword;