import { useState , useEffect } from "react";
import { useHistory } from "react-router";
import {Sms,Lock1,Eye,EyeSlash,Global,InfoCircle, Warning2,ShieldSecurity} from 'iconsax-react';
import { Link } from "react-router-dom";
import Logingroup from './images/Banner02.png';// right city image
import Logo from './images/logo-tele.png'
import './local.js'; // multi language file
import { useTranslation } from 'react-i18next'; // multi language package
import Popup from './Popup';
import CreatableSelect from 'react-select';
import { event } from "jquery";

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}
const techCompanies = [
  
  { label: "English", value: 1 },
  { label: "Persian", value: 2 },
  { label: "Mandarin", value: 3 },
  { label: "Hindi", value: 4 },
  { label: "Spanish", value: 5 },
  { label: "French", value: 6 },
  { label: "Arabic", value: 7 },
  { label: "Bengali", value: 8 },
  { label: "Russian", value: 9 },
  { label: "Portuguese", value: 10 },
  { label: "Indonesian", value: 11 },
  { label: "Japanese", value: 12 },
  { label: "Turkish", value: 13 },
  { label: "Korean", value: 14 },
  { label: "Dutch", value: 15 },
  { label: "Italian", value: 16 },
  { label: "Romanian", value: 17 },
  { label: "Thai", value: 18 },
  { label: "Nepali", value: 19 },
  { label: "Gujarati", value: 20 },
];
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '2px dotted green',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#31313131' : 'white'
  }),
  control: (provided) => ({
    ...provided,
    marginTop: "5%",
    backgroundColor: 'transparent',
  }),
  // control: (base, state) => ({
  //   ...base,
  //   height: '34px',
  //   minHeight: '34px',
  // }),
}
const Login = () => {
    //********************************fetching API */
    const [username, setUsername] = useState();
    const [passwordd, setPasswordd] = useState();
    const [helpEmail,setHelpEmail] = useState('');
    const [body, setBody] = useState();
    const [author, setAuthor] = useState('kafa');
    const [ispending, setIspending] = useState(false);
    const history = useHistory();
    const [error, setError] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [countGlobal,setCountGlobal] = useState(false);
    const [emailFoucus,setEmailFoucus] = useState(false);
    const [passFoucus,setPassFoucus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }

    const forceUpdate = useForceUpdate();
    const  handleSubmit = (e) => {
        e.preventDefault();
        setIspending(true);
        try{ 
          fetch('https://telesiness.ir/api/login' , {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Email: values.Email ,Password:values.password })
            }).then(response => response.json()).then(response=>{
  
                setIspending(false);
                
                if(response.statusCode==400){ 
                  console.log(response.statusMessage);
                  document.getElementById("errorLabelEmail").style.display="flex";
                  setHelpEmail(response.statusMessage);
                }
                localStorage.email3 = values.Email ;
                localStorage.password3 = values.password;
                if(response.userType==1 && response.isLawyerActive==true && response.isConfirmEmailActive== true){
                 // ReactSession.setStoreType("localStorage");
                 localStorage.removeItem('username');
                 localStorage.removeItem('email');
                 localStorage.removeItem('userType');
                 localStorage.removeItem('userId');
                 localStorage.removeItem('isAgentActive');
                 localStorage.removeItem('token');
                 localStorage.removeItem('lawyerCode');
                 localStorage.removeItem('userAvatar'); 
                 

                 localStorage.setItem('userAvatar',response.userAvatar); 
                 localStorage.setItem('src','');
                 localStorage.setItem('username', response.fullName);
                 localStorage.setItem('email', response.emailAddress);
                 localStorage.setItem('userType', response.userType);
                 localStorage.setItem('userId', response.userId);
                 localStorage.setItem('isAgentActive', response.isAgentActive);
                 localStorage.setItem('token', response.token.token);
                 localStorage.setItem('lawyerCode', response.lawyerCode);
                 history.push('/dashboard');
                 forceUpdate() ;
                }
                else if(response.userType==2 ){ 
// && response.isLawyerActive==true && response.isConfirmEmailActive== true
                  // ReactSession.setStoreType("localStorage");
                  localStorage.removeItem('username');
                  localStorage.removeItem('email');
                  localStorage.removeItem('userType');
                  localStorage.removeItem('userId');
                  localStorage.removeItem('isAgentActive');
                  localStorage.removeItem('token');
                  localStorage.removeItem('userAvatar'); 
                  localStorage.removeItem('isDistributorPlus');

                  localStorage.setItem('userAvatar',response.userAvatar);
                  localStorage.setItem('src','');
                  localStorage.setItem('username', response.fullName);
                  localStorage.setItem('email', response.emailAddress);
                  // localStorage.setItem('userType', response.userType);
                  localStorage.setItem('userType', response.userType);
                  localStorage.setItem('userId', response.userId);
                  localStorage.setItem('isAgentActive', response.isAgentActive);
                  localStorage.setItem('token', response.token.token);
                  localStorage.setItem('distributorPlusCode', response.distributorPlusCode);
                  localStorage.setItem('agentCode', response.agentCode);
                  localStorage.setItem('isDistributorPlus', response.isDistributorPlus);
                  history.push('/dashboard');
                  forceUpdate() ;
                 }
                 else if(response.userType==3 ){ 
                  // && response.isLawyerActive==true && response.isConfirmEmailActive== true
                  // ReactSession.setStoreType("localStorage");
                  localStorage.removeItem('username');
                  localStorage.removeItem('email');
                  localStorage.removeItem('userType');
                  localStorage.removeItem('userId');
                  localStorage.removeItem('isAgentActive');
                  localStorage.removeItem('token');
                  localStorage.removeItem('firstTimeCustomer');
                  localStorage.removeItem('userAvatar'); 
                  localStorage.removeItem('verifyCustomerByDistributor')
                  localStorage.removeItem('isCustomerDocumentExist');
                  localStorage.removeItem('lawyerReferenceCode');

                  localStorage.setItem('userAvatar',response.userAvatar);
                  localStorage.setItem('src','');
                  // localStorage.setItem('firstTimeCustomer',response.fullName);
                  
                  localStorage.setItem('username', response.fullName);
                  localStorage.setItem('email', response.emailAddress);
                  localStorage.setItem('userType', response.userType);
                  localStorage.setItem('userId', response.userId);
                  localStorage.setItem('isAgentActive', response.isAgentActive);
                  localStorage.setItem('token', response.token.token);
                  localStorage.setItem('customerCode', response.customerCode);
                  localStorage.setItem('lawyerReferenceCode', response.lawyerReferenceCode);
                  if(response.verifyCustomerByDistributor==1 || response.verifyCustomerByDistributor=='Active')
                  localStorage.setItem('verifyCustomerByDistributor','1');
                  else  if(response.verifyCustomerByDistributor==2 || response.verifyCustomerByDistributor=='Deactive')
                  localStorage.setItem('verifyCustomerByDistributor','2');
                  else
                  localStorage.setItem('verifyCustomerByDistributor','0');
                  
                  localStorage.setItem('isCustomerDocumentExist', response.isCustomerDocumentExist);

                 
                  history.push('/dashboard');
                  // forceUpdate() ;
                  }
                  else if(response.userType==4 ){ 
                    // && response.isLawyerActive==true && response.isConfirmEmailActive== true
                    // ReactSession.setStoreType("localStorage");
                    localStorage.removeItem('username');
                    localStorage.removeItem('email');
                    localStorage.removeItem('userType');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('isAgentActive');
                    localStorage.removeItem('token');
                    localStorage.removeItem('firstTimeCustomer');
                    localStorage.removeItem('userAvatar'); 

                    localStorage.setItem('userAvatar',response.userAvatar);
                    localStorage.setItem('src','');
                    localStorage.setItem('username', response.fullName);
                    localStorage.setItem('email', response.emailAddress);
                    localStorage.setItem('userType', response.userType);
                    localStorage.setItem('userId', response.userId);
                    localStorage.setItem('isAgentActive', response.isAgentActive);
                    localStorage.setItem('token', response.token.token);
                    localStorage.setItem('marketerCode', response.marketerCode);
                   
                    history.push('/dashboard');
                    forceUpdate() ;
                    }
                  else{
                    // history.push('/dashboard');
                    // localStorage.setItem('userType', '1');
                  }
                  localStorage.removeItem('registerDate');
                  localStorage.setItem('registerDate', response.registerDate);
                      console.log(localStorage.getItem('firstTimeCustomer'));
                      localStorage.removeItem('customerEvaluationFormAnotherDescriptions');
                      localStorage.removeItem('customerEvaluationFormSpouseCompanion');
                      localStorage.removeItem('customerEvaluationFormSpouseJobPosition');
                      localStorage.removeItem('customerEvaluationFormSpouseCareer');
                      localStorage.removeItem('lastEducationalCertificate');
                      localStorage.removeItem('customerEvaluationFormSpouseInsuranceHistory');
                      localStorage.removeItem('customerEvaluationFormSpouseFinalKoreanTestScore');
                      localStorage.removeItem('customerEvaluationFormSpouseFamiliarityWithTheKoreanLanguage');
                      localStorage.removeItem('customerEvaluationFormSpouseKoreanLanguageCanSpeak');
                      localStorage.removeItem('specializedSectionInTheFieldOfImmigration');
                      localStorage.removeItem('customerEvaluationFormSpouseFinalChineseTestScore');
                      localStorage.removeItem('customerEvaluationFormSpouseFamiliarityWithTheChineseLanguage');
                      localStorage.removeItem('customerEvaluationFormSpouseChineseLanguageCanSpeak');
                      localStorage.removeItem('customerEvaluationFormSpouseFinalSpanishTestScore');
                      localStorage.removeItem('customerEvaluationFormSpouseFamiliarityWithTheSpanishLanguage');
                      localStorage.removeItem('customerEvaluationFormSpouseSpanishLanguageCanSpeak');
                      localStorage.removeItem('customerEvaluationFormSpouseFinalArabicTestScore');
                      localStorage.removeItem('customerEvaluationFormSpouseFamiliarityWithTheArabicLanguage');
                      localStorage.removeItem('customerEvaluationFormSpouseArabicLanguageCanSpeak');
                      localStorage.removeItem('customerEvaluationFormSpouseFinalDanishTestScore');
                      localStorage.removeItem('customerEvaluationFormSpouseFamiliarityWithTheDanishLanguage');
                      localStorage.removeItem('customerEvaluationFormSpouseDanishLanguageCanSpeak');
                      localStorage.removeItem('customerEvaluationFormSpouseFinalGermanTestScore');
                      localStorage.removeItem('customerEvaluationFormSpouseFamiliarityWithTheGermanLanguage');
                      localStorage.removeItem('customerEvaluationFormSpouseGermanLanguageCanSpeak');
                      localStorage.removeItem('customerEvaluationFormSpouseFinalFrenchTestScore');
                      localStorage.removeItem('customerEvaluationFormSpouseFamiliarityWithTheFrenchLanguage');
                      localStorage.removeItem('customerEvaluationFormSpouseFrenchLanguageCanSpeak');
                      localStorage.removeItem('customerEvaluationFormSpouseFinalEnglishTestScore');
                      localStorage.removeItem('customerEvaluationFormSpouseFamiliarityWithTheEnglishLanguage');
                      localStorage.removeItem('customersEvaluationFormSpouseEnglishLanguageCanSpeak');
                      localStorage.removeItem('customerEvaluationFormSpouseEducationalDegrees');
                      localStorage.removeItem('customerEvaluationFormSpouseFieldOfStudy');
                      localStorage.removeItem('customerEvaluationFormSpouseLastName');
                      localStorage.removeItem('customerEvaluationFormExplainFinancialSupporter');  
                      localStorage.removeItem('customerEvaluationFormApplicantFinancialSupporter'); 
                      localStorage.removeItem('customerEvaluationFormExplainKinInChosenCountry'); 
                      localStorage.removeItem('customerEvaluationFormKinInChosenCountry'); 
                      localStorage.removeItem('customerEvaluationFormNamesOfCountriesRejectedLastFiveYears'); 
                      localStorage.removeItem('customerEvaluationFormRejectionHistoryFromACountry'); 
                      localStorage.removeItem('customerEvaluationFormNamesOfCountryCustomerVisited'); 
                      localStorage.removeItem('customerEvaluationFormTravelToSchengenAreaOtherPlace'); 
                      localStorage.removeItem('customerEvaluationFormPurposeOfMigration'); 
                      localStorage.removeItem('customerEvaluationFormTypeOfVisaRequested'); 
                      localStorage.removeItem('customerEvaluationFormCountryOfImmigration'); 
                      localStorage.removeItem('customerEvaluationFormCompanion'); 
                      localStorage.removeItem('customerEvaluationFormChildrenOverEighteenYearsGender'); 
                      localStorage.removeItem('customerEvaluationFormChildrenOverEighteenYearsAge'); 
                      localStorage.removeItem('customerEvaluationFormChildrenOverEighteenYearsQuantity'); 
                      localStorage.removeItem('customerEvaluationFormChildrenOverEighteenStatus'); 
                      localStorage.removeItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsGender'); 
                      localStorage.removeItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsAge'); 
                      localStorage.removeItem('customerEvaluationFormChildrenBetweenFourAndSeventeenYearsQuantity'); 
                      localStorage.removeItem('customerEvaluationFormChildrenBetweenFourAndSeventeenStatus'); 
                      localStorage.removeItem('customerEvaluationFormChildrenUnderFourYearsGender'); 
                      localStorage.removeItem('customerEvaluationFormChildrenUnderFourYearsAge'); 
                      localStorage.removeItem('customerEvaluationFormChildrenUnderFourYearsQuantity'); 
                      localStorage.removeItem('customerEvaluationFormChildrenUnderFourYearsStatus'); 
                      localStorage.removeItem('customerEvaluationFormTotalAssets'); 
                      localStorage.removeItem('customerEvaluationFormFinancialLiquidity'); 
                      localStorage.removeItem('customerEvaluationFormAnnualTurnover'); 
                      localStorage.removeItem('customerEvaluationFormJobPositionNumberThree'); 
                      localStorage.removeItem('customerEvaluationFormInsuranceHistoryNumberThree'); 
                      localStorage.removeItem('customerEvaluationFormCareerNumberThree'); 
                      localStorage.removeItem('customerEvaluationFormJobPositionNumberTwo'); 
                      localStorage.removeItem('customerEvaluationFormInsuranceHistoryNumberTwo'); 
                      localStorage.removeItem('customerEvaluationFormCareerNumberTwo'); 
                      localStorage.removeItem('customerEvaluationFormJobPositionNumberOne'); 
                      localStorage.removeItem('customerEvaluationFormInsuranceHistoryNumberOne'); 
                      localStorage.removeItem('customerEvaluationFormCareerNumberOne'); 
                      localStorage.removeItem('customerEvaluationFormFamiliarityWithTheKoreanLanguage'); 
                      localStorage.removeItem('customerEvaluationFormKoreanLanguageCanSpeak'); 
                      localStorage.removeItem('customerEvaluationFormFinalChineseTestScore'); 
                      localStorage.removeItem('customerEvaluationFormFamiliarityWithTheChineseLanguage'); 
                      localStorage.removeItem('customerEvaluationFormChineseLanguageCanSpeak'); 
                      localStorage.removeItem('customerEvaluationFormFinalSpanishTestScore'); 
                      localStorage.removeItem('customerEvaluationFormFamiliarityWithTheSpanishLanguage'); 
                      localStorage.removeItem('customerEvaluationFormSpanishLanguageCanSpeak'); 
                      localStorage.removeItem('customerEvaluationFormFinalArabicTestScore'); 
                      localStorage.removeItem('customerEvaluationFormFamiliarityWithTheArabicLanguage'); 
                      localStorage.removeItem('customerEvaluationFormArabicLanguageCanSpeak'); 
                      localStorage.removeItem('customerEvaluationFormFinalDanishTestScore'); 
                      localStorage.removeItem('customerEvaluationFormFamiliarityWithTheDanishLanguage'); 
                      localStorage.removeItem('customerEvaluationFormDanishLanguageCanSpeak'); 
                      localStorage.removeItem('customerEvaluationFormFinalGermanTestScore'); 
                      localStorage.removeItem('customerEvaluationFormFamiliarityWithTheGermanLanguage'); 
                      localStorage.removeItem('customerEvaluationFormGermanLanguageCanSpeak'); 
                      localStorage.removeItem('customerEvaluationFormFinalFrenchTestScore'); 
                      localStorage.removeItem('customerEvaluationFormFamiliarityWithTheFrenchLanguage'); 
                      localStorage.removeItem('customerEvaluationFormFrenchLanguageCanSpeak'); 
                      localStorage.removeItem('customerEvaluationFormFinalEnglishTestScore'); 
                      localStorage.removeItem('customerEvaluationFormFamiliarityWithTheEnglishLanguage'); 
                      localStorage.removeItem('customersEvaluationFormEnglishLanguageCanSpeak'); 
                      localStorage.removeItem('customersEvaluationFormResearchNumberThree'); 
                      localStorage.removeItem('customersEvaluationFormResearchNumberTwo'); 
                      localStorage.removeItem('customersEvaluationFormResearchNumberOne'); 
                      localStorage.removeItem('customersEvaluationFormCertificatesAcknowledgmentNumberThree'); 
                      localStorage.removeItem('customersEvaluationFormCertificatesAcknowledgmentNumberTwo'); 
                      localStorage.removeItem('customersEvaluationFormCertificatesAcknowledgmentNumberOne'); 
                      localStorage.removeItem('customerEvaluationFormCertificatesNonAcademicNumberThree'); 
                      localStorage.removeItem('customerEvaluationFormCertificatesNonAcademicNumberTwo'); 
                      localStorage.removeItem('customerEvaluationFormCertificatesNonAcademicNumberOne'); 
                      localStorage.removeItem('customerEvaluationFormFieldOfStudy'); 
                      localStorage.removeItem('customerEvaluationFormEducationalDegrees'); 
                      localStorage.removeItem('customerEvaluationFormMartialStatus'); 
                      localStorage.removeItem('customerEvaluationFormMilitaryServiceStatus'); 
                      localStorage.removeItem('customerEvaluationFormAge'); 
                      localStorage.removeItem('customerEvaluationFormPlaceOfBirth'); 
                      localStorage.removeItem('customerEvaluationFormAddress'); 
                      localStorage.removeItem('customerEvaluationFormFirstName'); 
                      localStorage.removeItem('customerEvaluationFormCity'); 
                      localStorage.removeItem('customerEvaluationFormProvince'); 
                      localStorage.removeItem('customerEvaluationFormCountry'); 
                      localStorage.removeItem('customerEvaluationFormPhoneNumber');     
                      localStorage.removeItem('customerEvaluationFormLandlineNumber'); 
                      localStorage.removeItem('customerEvaluationFormEmailAddress'); 
                      localStorage.removeItem('customerEvaluationFormGender'); 
                      localStorage.removeItem('customerEvaluationFormLastName'); 

                      localStorage.removeItem('EvFormUpdate');

              }).catch ((error) => console.error(error));
        } 
        catch(e)
         {}
         if (rmCheck.checked && document.getElementById("inputEmail").value !== "") {
          localStorage.Email2 = document.getElementById("inputEmail").value;
          localStorage.checkbox = rmCheck.checked;
          localStorage.PasswordRem = document.getElementById('inputpassword').value;
        } else {
          localStorage.Email2 = "";
          localStorage.checkbox = "";
          localStorage.PasswordRem = "";
        }
        
    }
    
    //***************************************** */
    console.log(localStorage.getItem('userAvatar'));
    const  handleSingUp = (e) => {
      e.preventDefault();
      setIspending(true);
      
        localStorage.setItem('Country','');
        localStorage.setItem('City','');
        localStorage.setItem('Job','');
        localStorage.setItem('iccrc','');
        localStorage.setItem('Certificate','');
        localStorage.setItem('Specialization','');
        localStorage.setItem('website','');
        localStorage.setItem('Upload','');
        localStorage.setItem('FieldOfStudy','');
        localStorage.setItem('University','');
        localStorage.setItem('Degree','');
        localStorage.setItem('FirstName','');
        localStorage.setItem('LastName','');
        localStorage.setItem('Phone','');
        localStorage.setItem('Email','');
        localStorage.setItem('About','');
        localStorage.setItem('myDate','');
        localStorage.setItem('userType','');
        localStorage.setItem('IDNumber','');

      
      history.push('/register');
  }
   // ui materials functions...
        const [values, setValues] = useState({
          Email: '',
          password: '',
          checked: '',
          refCode: '',
          SelectLanguage:localStorage.getItem('langV'),
          showPassword: false,
        });
      
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          if((!values.Email.match("^([a-zA-Z0-9_\.\-]{2,})+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$"))&
          !(document.activeElement.id=='inputEmail')){
            setHelpEmail('Envalid Email Format');
           
          }

            else{
              
              setHelpEmail('');
            }
        if(document.activeElement.id=='inputEmail')
        {
          document.getElementById('MyEmailplaceholder').style.color="transparent";
          document.getElementById('inputEmail').style.color="#313131";
        }
        else if(values.Email=="")
        {
          document.getElementById('MyEmailplaceholder').style.color="#313131";
        }

            // console.log(document.activeElement.id);
        if(document.activeElement.id=='inputpassword')
        {
          document.getElementById('Mypasswordplaceholder').style.color="transparent";
          document.getElementById('inputpassword').style.color="#313131";
        }
        else if(values.password=="")
        {
          document.getElementById('Mypasswordplaceholder').style.color="#313131";
        }  
        // if(document.activeElement.id=='inputpasswordM')
        // {
        //   document.getElementById('MypasswordplaceholderM').style.color="transparent";
        //   document.getElementById('inputpasswordM').style.color="#313131";
        // }
        // else if(values.password=="")
        // {
        //   document.getElementById('MypasswordplaceholderM').style.color="#313131";
        // } 
        if(document.activeElement.id=='refCode')
        {
          // document.getElementById('Mypasswordplaceholder').style.color="transparent";
          document.getElementById('refCode').style.color="#313131";
          document.getElementById('MyEmailplaceholder').style.color="white";
          document.getElementById('Mypasswordplaceholder').style.color="white";
        }
        else if(values.password=="")
        {
          // document.getElementById('Mypasswordplaceholder').style.color="#313131";
        }  
        };
      
        const handleClickShowPassword = () => {
          setValues({
            ...values,
            showPassword: !values.showPassword,
          });
        };
      
        const handleMouseDownPassword = (event) => {
          event.preventDefault();
        };
       const handleinputEmailBlur = (e) =>{
        
        if((!values.Email.match("^([a-zA-Z0-9_\.\-]{2,})+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$"))&
        !(document.activeElement.id=='inputEmail')){
          setHelpEmail('Envalid Email Format');
          setError(true);}
          else{
            setHelpEmail('');
            setError(false);
          }
          
         
           
          document.getElementById('errorLabelEmail').style.display="flex";
          document.getElementById('MyEmailplaceholder').style.color="transparent";
          document.getElementById('MyEmailSapn').style.color="transparent";
          if(document.getElementById('inputEmail').value==""){
          document.getElementById('errorLabelEmail').style.display="none";
          document.getElementById('MyEmailplaceholder').style.color="gray";
          document.getElementById('MyEmailSapn').style.color="red";
          setEmailFoucus(false);
         
         }
         
       }
       const handleinputEmailBlurM = (e) =>{
        
        if((!values.Email.match("^([a-zA-Z0-9_\.\-]{2,})+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$"))&
        !(document.activeElement.id=='inputEmail')){
          setHelpEmail('Envalid Email Format');
          setError(true);}
          else{
            setHelpEmail('');
            setError(false);
          }
          
         
           
        //   document.getElementById('errorLabelEmailM').style.display="flex";
        //   document.getElementById('MyEmailplaceholderM').style.color="transparent";
        //   document.getElementById('MyEmailSapnM').style.color="transparent";
        //   if(document.getElementById('inputEmail').value==""){
        //   document.getElementById('errorLabelEmailM').style.display="none";
        //   document.getElementById('MyEmailplaceholderM').style.color="gray";
        //   document.getElementById('MyEmailSapnM').style.color="red";
        //   setEmailFoucus(false);
         
        //  }
         
       }
       
       const handleinputpasswordBlur = (e) =>{
         if(!(document.getElementById('inputpassword').value=="")){
          document.getElementById('errorLabelpassword').style.display="flex";
          document.getElementById('Mypasswordplaceholder').style.color="transparent";
          document.getElementById('MypasswordSapn').style.color="transparent";
          
         }
         else{
          document.getElementById('errorLabelpassword').style.display="none";
          document.getElementById('Mypasswordplaceholder').style.color="gray";
          document.getElementById('MypasswordSapn').style.color="red";
          setPassFoucus(false);
         }
       }

      //  const handleinputpasswordBlurM = (e) =>{
      //   if(!(document.getElementById('inputpasswordM').value=="")){
      //    document.getElementById('errorLabelpasswordM').style.display="flex";
      //    document.getElementById('MypasswordplaceholderM').style.color="transparent";
      //    document.getElementById('MypasswordSapnM').style.color="transparent";
         
      //   }
      //   else{
      //    document.getElementById('errorLabelpasswordM').style.display="none";
      //    document.getElementById('MypasswordplaceholderM').style.color="gray";
      //    document.getElementById('MypasswordSapnM').style.color="red";
      //    setPassFoucus(false);
      //   }
      // }

       const handleEye =(e) =>{
         document.getElementById('eye').style.display='none';
         document.getElementById('eyeslash').style.display='flex';
         document.getElementById('inputpassword').setAttribute('type','text');
       }
       
       const handleEyeSlash =(e) =>{
        document.getElementById('eyeslash').style.display='none';
        document.getElementById('eye').style.display='flex';
        document.getElementById('inputpassword').setAttribute('type','password');
      }

    //   const handleEyeM =(e) =>{
    //     document.getElementById('eyeM').style.display='none';
    //     document.getElementById('eyeslashM').style.display='flex';
    //     document.getElementById('inputpasswordM').setAttribute('type','text');
    //   }
      
    //   const handleEyeSlashM =(e) =>{
    //    document.getElementById('eyeslashM').style.display='none';
    //    document.getElementById('eyeM').style.display='flex';
    //    document.getElementById('inputpasswordM').setAttribute('type','password');

    //  }
      
      
      const handleMouseOverEye =(e) =>{
        document.getElementById('eye').setAttribute('color','black');
      }

//***********multi language functios *******/
const {t, i18n} = useTranslation(['Login']);
const  handelIR = (e) => {
    // e.preventDefault();
    
    i18n.changeLanguage('fa_IR');
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.body.setAttribute('lang', 'fa');
    if(document.body.getAttribute("dir")== "rtl"){
      document.getElementById("fa").style.color = "#9b9b9b !important";
    }
}
const  handelUS = (e) => {
    // e.preventDefault();
    i18n.changeLanguage('en_US');
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.body.setAttribute('lang', 'en');
}

const handleClickGlobal = (e) => {

      document.getElementById('GlobalBtn1').style.display="none";
      document.getElementById('GlobalBtn2').style.display="inline-flex";
}
const handleMouseLeaveGlobal = (e) => {
  document.getElementById('GlobalBtn1').style.display="inline-flex";
  document.getElementById('GlobalBtn2').style.display="none";

}

const handleEmailOver = (e) => {
  if(values.Email=="")
  {
    document.getElementById('MyEmailplaceholder').style.color="#313131";
  }
  else{
    document.getElementById('MyEmailplaceholder').style.color="transparent";
  }
}

const handleEmailOut = (e) => {
  
  if(values.Email=="")
  {
    document.getElementById('MyEmailplaceholder').style.color="white";
  }
  else{
    document.getElementById('MyEmailplaceholder').style.color="transparent";
  }
}

const handleInviteCode = (e) => {
  fetch('https://telesiness.ir/api/Inquiry/' , {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ TelesinessCode: values.refCode  })
    }).then(response => response.json()).then(response=>{
      console.log(response);
      localStorage.removeItem('TelesinessUserFullName');
      localStorage.removeItem('Position');
      localStorage.setItem('TelesinessUserFullName',response.telesinessUserFirstName + ' ' + response.telesinessUserLastName);
      localStorage.setItem('Position',response.position);
      togglePopup();
    })
  
}
const handlePassOver = (e) => {
  if(values.password=="")
  {
    document.getElementById('Mypasswordplaceholder').style.color="#313131";
  }
  else{
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
  }
}

const handlePassOut = (e) => {
  if(values.password=="")
  {
    document.getElementById('Mypasswordplaceholder').style.color="white";
  }
  else{
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
  }
  
}

const handleEmailFocus = (e) => {
  setHelpEmail('');
  if(values.Email=="")
  {
    document.getElementById('MyEmailplaceholder').style.color="#313131";
    setEmailFoucus(!emailFoucus);
  }
  else{
    document.getElementById('MyEmailplaceholder').style.color="transparent";
  }
}

// const handleEmailFocusM = (e) => {
//   setHelpEmail('');
//   if(values.Email=="")
//   {
//     document.getElementById('MyEmailplaceholderM').style.color="#313131";
//     setEmailFoucus(!emailFoucus);
//   }
//   else{
//     document.getElementById('MyEmailplaceholderM').style.color="transparent";
//   }
// }

const handlePassFocus = (e) => {
  if(values.password=="")
  {
    document.getElementById('Mypasswordplaceholder').style.color="#313131";
    setPassFoucus(!passFoucus);
  }
  else{
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
  }
}
// const handlePassFocusM = (e) => {
//   if(values.password=="")
//   {
//     document.getElementById('MypasswordplaceholderM').style.color="#313131";
//     setPassFoucus(!passFoucus);
//   }
//   else{
//     document.getElementById('MypasswordplaceholderM').style.color="transparent";
//   }
// }



const rmCheck = document.getElementById('rememberMe'),
emailInput = document.getElementById('inputEmail');

const [isLoaded2, setIsLoaded2] = useState(false);
const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

useEffect(() => {
    setIsLoaded2(true);
}, []);



useEffect(() => {
  // Update the document title using the browser API
  if (localStorage.checkbox && localStorage.checkbox !== "" && localStorage.Email2) {
    document.getElementById('rememberMe').setAttribute("checked","checked");
  values.Email = localStorage.Email2;
  values.password = localStorage.PasswordRem;
  handleChange('Email');
  handleChange('inputpassword');
    document.getElementById('MyEmailplaceholder').style.color="transparent";
    //document.getElementById('MyEmailplaceholderM').style.color="transparent";
    document.getElementById('inputEmail').style.color="#313131";
    document.getElementById('inputEmail').innerHTML = localStorage.Email2;

    document.getElementById('Mypasswordplaceholder').style.color="transparent";
    document.getElementById('inputpassword').style.color="#313131";
    document.getElementById('inputpassword').innerHTML = localStorage.PasswordRem;

    // document.getElementById('MypasswordplaceholderM').style.color="transparent";
    // document.getElementById('inputpasswordM').style.color="#313131";
    // document.getElementById('inputpasswordM').innerHTML = localStorage.PasswordRem;

    setError(false);
    forceUpdate() ;
    console.log(document.getElementById("inputEmail").value);
  } else if(values.checked){
  rmCheck.removeAttribute("checked");
  document.getElementById("inputEmail").value ="";
  document.getElementById("inputpassword").value ="";
  // document.getElementById("inputpasswordM").value ="";
  forceUpdate() ;
  
  }
  if(document.getElementById("inputEmail").value!=""){
    document.getElementById('MyEmailplaceholder').style.color="transparent";
    document.getElementById('Mypasswordplaceholder').style.color="transparent";
    // document.getElementById('MyEmailplaceholderM').style.color="transparent";
    // document.getElementById('MypasswordplaceholderM').style.color="transparent";
    forceUpdate() ;
  
  }
  if(document.getElementById("inputEmail").value==""){
    document.getElementById('MyEmailplaceholder').style.color="white";
    document.getElementById('Mypasswordplaceholder').style.color="white";
    // document.getElementById('MyEmailplaceholderM').style.color="white";
    // document.getElementById('MypasswordplaceholderM').style.color="white";
    document.getElementById('inputEmail').style.color="transparent";
    document.getElementById('inputpassword').style.color="transparent";
    // document.getElementById('inputpasswordM').style.color="transparent";
  }
  
},[document.getElementById('rememberMe')]);
// const {t, i18n} = useTranslation(['Login']);
// const  handelIR = (e) => {
//   e.preventDefault();
//   i18n.changeLanguage('fa_IR');
//   document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
//   document.body.setAttribute('lang', 'fa');
// }
if(localStorage.getItem('langL')){

}
else
{
  localStorage.setItem('langL' , "English");
  localStorage.setItem('langV' , 1);
}

const [selectValue , setSelectValue] = useState({ label: localStorage.getItem('langL'), value: localStorage.getItem('langV') });
useEffect(() => {
  if(values.SelectLanguage != 2)
  {
    handelUS();
    localStorage.setItem('langV' , 1);
    localStorage.setItem('langL' , "English");
  }
   
  else
  {
    handelIR()
    localStorage.setItem('langV' , 2);
    localStorage.setItem('langL' , "Persian");
  }
    
}, [values.SelectLanguage]);
//***************************************/
    return (
      <><div className="pagePic"></div>
      <div className="loginMainRow">
        <div className="rectangle sectionsLogin col-xl-8 col-12">
            <div className="section leftSection col-xl-6 col-12">
              <div className="LogoMobile col-12">
                <img src={Logo} alt="" />
              </div>
              <div className="leftSectionTopGrup col-xl-11 col-lg-7 col-md-9 col-12">
                <h4 className="myBoldFont ">{t('Sign In to Telesiness')}</h4>
                <p className="LoginSomeText d-none d-sm-block">{t('Enter your username and password below to log in And have access to a world of immigration facilities')}</p>
                <form onSubmit={handleSubmit} noValidate >
                  <div className="input-placeholder" onBlur={handleinputEmailBlur} onMouseOver={handleEmailOver} onMouseLeave={handleEmailOut}>
                        <input 
                          value = {values.Email}
                          type="email"
                          className="form-control"
                          id="inputEmail"
                          autoComplete="anyrandomstring"
                          onChange={handleChange('Email')}
                          onFocus={handleEmailFocus}
                          
                          required 
                          />
                        {!emailFoucus && <><div id="MyEmailplaceholder" className="placeholder">
                      {t('Email')}<span id="MyEmailSapn"></span>
                    </div><Sms size="18" color="#ffffff" /><label id="errorLabelEmail">{helpEmail}</label></>}
                    {emailFoucus && <><div id="MyEmailplaceholder" className="placeholder">
                      {t('type your email...')}<span id="MyEmailSapn"></span>
                    </div>{helpEmail=='' &&<Sms size="18" color="#ffffff" />}{helpEmail!='' &&<Sms variant="Bold" size="18" color="#ff0000" />}<label id="errorLabelEmail">{helpEmail!='' &&<InfoCircle  size="18"  color="#ff0000" />}  {helpEmail}</label></>}
                    </div>
                    <div className="input-placeholder" onMouseOver={handlePassOver} onMouseLeave={handlePassOut}>
                        <input 
                          value = {values.password}
                          type="password"
                          className="form-control"
                          id="inputpassword"
                          autoComplete="anyrandomstring"
                          onBlur={handleinputpasswordBlur}
                          onChange={handleChange('password')}
                          onFocus={handlePassFocus}
                          required 
                          />
                          
                        {!passFoucus &&<><div id="Mypasswordplaceholder" className="placeholder">
                      {t('Password')}<span id="MypasswordSapn"></span>
                    </div><Lock1 size="18" color="#ffffff" /><label id="errorLabelpassword"></label><Eye onMouseOver={handleMouseOverEye} onClick={handleEye} id="eye" size="18" color="#ffffff" /><EyeSlash onClick={handleEyeSlash} id="eyeslash" size="18" color="#ffffff" /></>}
                    {passFoucus &&<><div id="Mypasswordplaceholder" className="placeholder">
                      {t('type your password here...')}<span id="MypasswordSapn"></span>
                    </div><Lock1 size="18" color="#ffffff" /><label id="errorLabelpassword"></label><Eye onMouseOver={handleMouseOverEye} onClick={handleEye} id="eye" size="18" color="#ffffff" /><EyeSlash onClick={handleEyeSlash} id="eyeslash" size="18" color="#ffffff" /></>}
                    </div>
                  <div className="remember-forget">
                    <div className="remember">
                    <input onChange={handleChange('checked')} className="secondary" type="checkbox" value={values.checked} id="rememberMe"/> <label htmlFor="rememberMe"> {t('Remember me')}</label>
                      
                    </div>
                    <label className="forgetPassword">
                      <Link className="" to='/resetpass'> {t('Forgot Password')}</Link>
                    </label>
                  </div>
                  <br />
                  {!ispending && !error &&<button className="btn btn-dark login-btn ">{t('Login')}</button>}
                  {!ispending && error &&<button disabled className="btn btn-dark login-btn ">{t('Login')}</button>}
                  {ispending && <button className="btn btn-dark login-btn " disabled>{t('Being login...')}</button>}
                  <div className="line-or">
                    <hr width="30%" className="mx-2" /> <p>{t('or')}</p>  <hr width="30%" className="mx-2" />
                  </div>
                  <Link onClick={handleSingUp} to="/register" className="btn btn-outline-light signup-btn ">{t('Sing Up Now')} </Link>
                  <div className="line-or inqueyLine">
                    <hr width="30%" className="mx-2" /> <p>{t('Inquiry')}</p>  <hr width="30%" className="mx-2" />
                  </div>
                  <div className="input-placeholder" >
                        <input 
                          value = {values.refCode}
                          type="text"
                          className="form-control"
                          id="refCode"
                          autoComplete="anyrandomstring"
                          onChange={handleChange('refCode')}
                          placeholder={t('Search by Telesiness Code')}
                          />
                          <Warning2 size="18" color="#ffffff" />
                          </div>
                  
                </form>
                <button onClick={handleInviteCode} className="btn btn-dark login-btn ">{t('Find')}</button>
              </div>
            </div>
            <div className="section rightSection col-xl-6">
              <img src={Logingroup} alt=""/>
            </div>
            <div className="rectangle_bottom col-12">
              <div className="CreatableSelectLogin">
               
                 <div className="inputAndlabel">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="SelectLanguage"
                  value={values.SelectLanguage}
                  label={t('SelectLanguage')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('SelectLanguage')}  
                  placeholder={t('SelectLanguage')}
                  >
                    
                  <option  value='1'>{t('English')}</option >
                  <option  value='2'>{t('Persian')}</option >
                  <option  value='3'>{t('Mandarin')}</option >
                  <option  value='4'>{t('Hindi')}</option >
                  <option  value='5'>{t('Spanish')}</option > 
                  <option  value='6'>{t('French')}</option > 
                  <option  value='7'>{t('Arabic')}</option >
                  <option  value='8'>{t('Bengali')}</option >
                  <option  value='9'>{t('Russian')}</option >
                  <option  value='10'>{t('Portuguese')}</option >
                  <option  value='11'>{t('Indonesian')}</option > 
                  <option  value='12'>{t('Japanese')}</option > 
                  <option  value='13'>{t('Turkish')}</option >
                  <option  value='14'>{t('Korean')}</option >
                  <option  value='15'>{t('Dutch')}</option >
                  <option  value='16'>{t('Italian')}</option >
                  <option  value='17'>{t('Romanian')}</option > 
                  <option  value='18'>{t('Thai')}</option > 
                  <option  value='19'>{t('Nepali')}</option >
                  <option  value='20'>{t('Gujarati')}</option >

                </select >
                {/* <label htmlFor="SelectLanguage">{t('Enter the applicants Skin Color')}</label> */}
            </div>
              </div>
              <div className="about-us">
                <div className="LangBox" id="GlobalBtn2" onMouseLeave={handleMouseLeaveGlobal}>
                  <a href="" onClick={handelIR} id="fa" className="handleIR">Fa</a> <hr />
                  <a href="" onClick={handelUS} id="en" className="handleUS">En</a><Global variant="Bold" color="#313131" size="24"/>
                </div>
                <div className="spanAbout">
                  <p> 
                     <span>|</span> <Link to="/services"><span>{t('Services')+' '}</span></Link>
                     <span>|</span>  <Link to="/AboutusLogin"><span>{t('About')}</span></Link> <span>|</span>
                     <Link to="/CollaborationsLogin"><span> {t('Collaborations')}</span></Link> <span>|</span> 
                     <Link to="/TermsLogin"><span>{' '+t('Terms')}</span></Link> <span>|</span>
                     <Link to="/HelpLogin"><span> {t('Help')}</span></Link> <span>|</span> 
                     <Link to="/ContactUsLogin"><span>{' '+t('Contact Us')}</span></Link> 
                  </p>
                </div>
              </div>
            </div>
        </div> 
        <div className="footerDasilav col-12">
          <div className="footer-copy">
             Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. <Link className="footer-copy" to="/TermsLogin">{'Privacy Policy'}</Link>
          </div>
          <div className="footer-copy2">
            <br />
            Copyright &copy; 2018- 2022 TELESINESS Company, INC.  All Rights Reserved. <Link className="footer-copy" to="/TermsLogin">{'Privacy Policy'}</Link>
          </div>
        </div>
      </div>
         
          {isOpen && <Popup
      content={<>
        <div className="PboxMainRow">
              <div className="PboxFirstRow">
                  <ShieldSecurity size="48" color="#313131" variant="Bold"/>
              </div>
              <div className="PboxSecondRow">
                { localStorage.getItem('Position') !='undefined' &&
              <h4>{t('Your Code is Valid.')}</h4>
                }
                { localStorage.getItem('Position') =='undefined' &&
                  <p>{t('Your Code is UnValid,  It is not registered in our database.')}</p>
                }
              </div>
              { localStorage.getItem('Position') !='undefined' &&<div className="PboxThirdRowLogin">
                  <p>{t('Full name is: ')} <b>{localStorage.getItem('TelesinessUserFullName')}</b> </p>
                  <p>{t('Position is: ')} <b>{localStorage.getItem('Position')}</b></p>
              </div>}
              <div className="PboxForthRow col-12">
                  
                  <button onClick={togglePopup} className="col-md-3 col-6 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                  <button onClick={togglePopup}  className="col-md-3 col-6 btn btn-dark">{t('I got it.')}</button>
              </div>
          
          </div>
        </>}
      handleClose={togglePopup}
    />}
         </>

       
    );
}
 
export default Login;