import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {ArchiveTick,GalleryAdd,Trash,ExportSquare,ArrowCircleLeft} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"


const Profile2 = ({childToParentCus1e3}) => {
    // const userActive =true;
    // const [formError , setFormError] = useState(false);
    // const [helpEmail,setHelpEmail] = useState('');
    // const [helpJob, setHelpJob] = useState();
    // const [helpCity, setHelpCity] = useState();
    // const [helpiccrc, setHelpiccrc] = useState();
    // const [body, setBody] = useState();
    // const [Certificate, setCertificate] = useState();
    // const [ispending, setIspending] = useState(false);
    // const history = useHistory();
    //****************submits**********************************/
  const handleSubmitPersonal = (e) => {
        e.preventDefault();
    localStorage.setItem('firstTimeCustomer',false);
    console.log((localStorage.getItem('firstTimeCustomer')));
    childToParentCus1e3(dataCus1);
    }
    const [dataC, setDataC] = useState([]);
    const fetchData = () => {
      fetch("https://telesiness.ir/api/Countries/AllCountery")
        .then((res) => res.json())
        .then((result) => setDataC(result))
        .catch((err) => console.log("error"));
        
    };
// const warperFunction = (e)=>{
//   (dataCus1) => {childToParentCus1(dataCus1);},
//    handleSubmitPersonal()
// }

    useEffect(() => {
      fetchData();
      // console.log(data[0].countery_Name);
    }, []);
        const [values, setValues] = useState({
          // proFirstName: 'kambiz',
          // proLastName: 'fakhr',
          // Gender: 'male',
          // birthDate: new Date(),
          // proEmail: 'kambiz.fakhr@yahoo.com',
          // proPhone:'3352258774',
          // proAbout:'I am Sofware Engineer',
          // firstTimeEdit:true,
          // firstTimeEditJob:true,
          // firstTimeEditEducational:true,
          // firstTimeEditAddress:true,
          // firstTimeEditPersonal:true,
          ProPic:'',
          // Country:'Iran',
          // City:'Tehran',
          // Address:'Iran - Tehran - Vanak - Zafar ',
          // ZipCode:'5174875988',
          // proNo:'61',
          // Level:'',
          // FieldOfStudy:'',
          // University:'',
          // JobCountry:'USA',
          // JobCity:'',
          // Jobspecialty:'',
          JobUpload:'',
          // iccrc:'',
          // website:'',
          // Specialization:'',
          // Certificate:'',
          // userNameLast:'setin',
          // showPassword: false,
          proFirstName: '',
          proLastName: '',
          Gender: '',
          IDNumber:'',
          NationalCode:'',
          birthDate: '',
          proEmail: '',
          proPhone: '',
          proCallNumber:"",
          proPhone2: '',
          proCallNumber2:"",
          proAbout: '',
          firstTimeEdit:'',
          firstTimeEditJob:'',
          firstTimeEditEducational:'',
          firstTimeEditAddress:true,
          firstTimeEditPersonal:true,
          // ProPic: '',
          Country: '',
          City: '',
          Address: '',
          ZipCode: '',
          Address2: '',
          ZipCode2: '',
          placeofBirth:'',
          proNo:'61',
          Level: '',
          FieldOfStudy: '',
          University: '',
          JobCountry: '',
          JobCity:'',
          Jobspecialty: '',
          PassportNumber: '',
          Name:'',
          Family:'',
          DateofCreation: '',
          ExpireDate:'',
          FieldStudyDiploma:'',
          TotalScoreDegreeTOEFL:'',
          LastEducationalCertificate:'',
          FieldStudy:'',
          UniversityNameFogDiplom:'',
          UniversityPlcaeFogDiplom:'',
          FieldStudyFogDiplom:'',
          UniversityNameBachelor:'',
          UniversityPlcaeBachelor:'',
          FieldStudyBachelor:'',
          UniversityNameMasters:'',
          UniversityPlcaeMasters:'',
          FieldStudyMasters:'',
          UniversityNamePHD:'',
          UniversityPlcaePHD:'',
          FieldStudyPHD:'',
          UniversityNamePostDoctoral:'',
          UniversityPlcaePostDoctoral:'',
          FieldStudyPostDoctoral:'',
          NonuniversityCertificate1:'',
          NonuniversityCertificate2:'',
          NonuniversityCertificate3:'',
          NonuniversityCertificate4:'',
          NonuniversityCertificate5:'',
          NonuniversityCertificate6:'',
          PlaceIssuanceNationalCard:'',
          FatherBirthCertificateNumber:'',
          MothersBirthCertificateNumber:'',
          AliasName:'',
          MilitaryCardNumber:'',
          TypeMilitaryCard:'',
          PlaceMilitaryService:'',
          IELTSReadingScore:'',
          IELTSWritingScore:'',
          IELTSListeningScore:'',
          IELTSSpeackingScore:'',
          TOEFLReadingScore:'',
          TOEFLWritingScore:'',
          TOEFLListeningScore:'',
          TOEFLSpeackingScore:'',
          OtherOneReadingScore:'',
          OtherOneWritingScore:'',
          OtherOneListeningScore:'',
          OtherOneSpeackingScore:'',
          OtherTwoReadingScore:'',
          OtherTwoWritingScore:'',
          OtherTwoListeningScore:'',
          OtherTwoSpeackingScore:'',
          OtherThreeReadingScore:'',
          OtherThreeWritingScore:'',
          OtherThreeListeningScore:'',
          OtherThreeSpeackingScore:'',
          WorkplaceCallNumber2:'',
          FormTrackingNumber:'',
          TypeMarriage:'',
        });
        const {t, i18n} = useTranslation(['Login']);  
        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
console.log(values.Gender);
        };

        const [Dvalue, setDValue] = useState(new Date())
        const dataCus3 = true;
        const dataCus1 = true;

const [image , setImage] = useState(null);
     const   onImageChange = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
              setImage(e.target.result);
              document.getElementById('target').style.display ="block";
              document.getElementById('MyGrayBox').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage = () =>{
          document.getElementById('target').style.display ="none";
          document.getElementById('MyGrayBox').style.display= "flex";
        }


        const [image2 , setImage2] = useState(null);
     const   onImage2Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader2 = new FileReader();
            reader2.onload = (e) => {
              setImage2(e.target.result);
              document.getElementById('target2').style.display ="block";
              document.getElementById('MyGrayBox2').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader2.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage2 = () =>{
          document.getElementById('target2').style.display ="none";
          document.getElementById('MyGrayBox2').style.display= "flex";
        }


        const [image3 , setImage3] = useState(null);
     const   onImage3Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader3 = new FileReader();
            reader3.onload = (e) => {
              setImage3(e.target.result);
              document.getElementById('target3').style.display ="block";
              document.getElementById('MyGrayBox3').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader3.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage3 = () =>{
          document.getElementById('target3').style.display ="none";
          document.getElementById('MyGrayBox3').style.display= "flex";
        }


        const [image4 , setImage4] = useState(null);
     const   onImage4Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader4 = new FileReader();
            reader4.onload = (e) => {
              setImage4(e.target.result);
              document.getElementById('target4').style.display ="block";
              document.getElementById('MyGrayBox4').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader4.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage4 = () =>{
          document.getElementById('target4').style.display ="none";
          document.getElementById('MyGrayBox4').style.display= "flex";
        }


    const [image5 , setImage5] = useState(null);
    const   onImage5Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader5 = new FileReader();
          reader5.onload = (e) => {
            setImage5(e.target.result);
            document.getElementById('target5').style.display ="block";
            document.getElementById('MyGrayBox5').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader5.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage5 = () =>{
        document.getElementById('target5').style.display ="none";
        document.getElementById('MyGrayBox5').style.display= "flex";
      }


    const [image6 , setImage6] = useState(null);
    const   onImage6Change = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader6 = new FileReader();
          reader6.onload = (e) => {
            setImage6(e.target.result);
            document.getElementById('target6').style.display ="block";
            document.getElementById('MyGrayBox6').style.display= "none";

            // this.setState({image: e.target.result});
          };
          reader6.readAsDataURL(event.target.files[0]);
        }
      }
      const handleTrashImage6 = () =>{
        document.getElementById('target6').style.display ="none";
        document.getElementById('MyGrayBox6').style.display= "flex";
      }

      const [image7 , setImage7] = useState(null);
      const   onImage7Change = (event) => {
          if (event.target.files && event.target.files[0]) {
            let reader7 = new FileReader();
            reader7.onload = (e) => {
              setImage7(e.target.result);
              document.getElementById('target7').style.display ="block";
              document.getElementById('MyGrayBox7').style.display= "none";

              // this.setState({image: e.target.result});
            };
            reader7.readAsDataURL(event.target.files[0]);
          }
        }
        const handleTrashImage7 = () =>{
          document.getElementById('target7').style.display ="none";
          document.getElementById('MyGrayBox7').style.display= "flex";
        }


      const [image8 , setImage8] = useState(null);
      const   onImage8Change = (event) => {
            if (event.target.files && event.target.files[0]) {
              let reader8 = new FileReader();
              reader8.onload = (e) => {
                setImage8(e.target.result);
                document.getElementById('target8').style.display ="block";
                document.getElementById('MyGrayBox8').style.display= "none";
  
                // this.setState({image: e.target.result});
              };
              reader8.readAsDataURL(event.target.files[0]);
            }
          }
          const handleTrashImage8 = () =>{
            document.getElementById('target8').style.display ="none";
            document.getElementById('MyGrayBox8').style.display= "flex";
          }


          const [image9 , setImage9] = useState(null);
          const   onImage9Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader9 = new FileReader();
                reader9.onload = (e) => {
                  setImage9(e.target.result);
                  document.getElementById('target9').style.display ="block";
                  document.getElementById('MyGrayBox9').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader9.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage9 = () =>{
              document.getElementById('target9').style.display ="none";
              document.getElementById('MyGrayBox9').style.display= "flex";
            }


        const [image10 , setImage10] = useState(null);
        const   onImage10Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader10 = new FileReader();
                reader10.onload = (e) => {
                  setImage10(e.target.result);
                  document.getElementById('target10').style.display ="block";
                  document.getElementById('MyGrayBox10').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader10.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage10 = () =>{
              document.getElementById('target10').style.display ="none";
              document.getElementById('MyGrayBox10').style.display= "flex";
            }


          const [image11 , setImage11] = useState(null);
          const   onImage11Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader11 = new FileReader();
                reader11.onload = (e) => {
                  setImage11(e.target.result);
                  document.getElementById('target11').style.display ="block";
                  document.getElementById('MyGrayBox11').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader11.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage11 = () =>{
              document.getElementById('target11').style.display ="none";
              document.getElementById('MyGrayBox11').style.display= "flex";
            }


          const [image12 , setImage12] = useState(null);
          const   onImage12Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader12 = new FileReader();
                  reader12.onload = (e) => {
                    setImage12(e.target.result);
                    document.getElementById('target12').style.display ="block";
                    document.getElementById('MyGrayBox12').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader12.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage12 = () =>{
                document.getElementById('target12').style.display ="none";
                document.getElementById('MyGrayBox12').style.display= "flex";
              }


          const [image13 , setImage13] = useState(null);
          const   onImage13Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader13 = new FileReader();
                reader13.onload = (e) => {
                  setImage13(e.target.result);
                  document.getElementById('target13').style.display ="block";
                  document.getElementById('MyGrayBox13').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader13.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage13 = () =>{
              document.getElementById('target13').style.display ="none";
              document.getElementById('MyGrayBox13').style.display= "flex";
            }


          const [image14 , setImage14] = useState(null);
          const   onImage14Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader14 = new FileReader();
                reader14.onload = (e) => {
                  setImage14(e.target.result);
                  document.getElementById('target14').style.display ="block";
                  document.getElementById('MyGrayBox14').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader14.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage14 = () =>{
              document.getElementById('target14').style.display ="none";
              document.getElementById('MyGrayBox14').style.display= "flex";
            }


            const [image15 , setImage15] = useState(null);
            const   onImage15Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader15 = new FileReader();
                  reader15.onload = (e) => {
                    setImage15(e.target.result);
                    document.getElementById('target15').style.display ="block";
                    document.getElementById('MyGrayBox15').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader15.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage15 = () =>{
                document.getElementById('target15').style.display ="none";
                document.getElementById('MyGrayBox15').style.display= "flex";
              }


            const [image16 , setImage16] = useState(null);
            const   onImage16Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader16 = new FileReader();
                  reader16.onload = (e) => {
                    setImage16(e.target.result);
                    document.getElementById('target16').style.display ="block";
                    document.getElementById('MyGrayBox16').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader16.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage16 = () =>{
                document.getElementById('target16').style.display ="none";
                document.getElementById('MyGrayBox16').style.display= "flex";
              }


            const [image17 , setImage17] = useState(null);
            const   onImage17Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader17 = new FileReader();
                  reader17.onload = (e) => {
                    setImage17(e.target.result);
                    document.getElementById('target17').style.display ="block";
                    document.getElementById('MyGrayBox17').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader17.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage17 = () =>{
                document.getElementById('target17').style.display ="none";
                document.getElementById('MyGrayBox17').style.display= "flex";
              }


              const [image18 , setImage18] = useState(null);
              const   onImage18Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader18 = new FileReader();
                    reader18.onload = (e) => {
                      setImage18(e.target.result);
                      document.getElementById('target18').style.display ="block";
                      document.getElementById('MyGrayBox18').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader18.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage18 = () =>{
                  document.getElementById('target18').style.display ="none";
                  document.getElementById('MyGrayBox18').style.display= "flex";
                }


              const [image19 , setImage19] = useState(null);
              const   onImage19Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader19 = new FileReader();
                    reader19.onload = (e) => {
                      setImage19(e.target.result);
                      document.getElementById('target19').style.display ="block";
                      document.getElementById('MyGrayBox19').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader19.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage19 = () =>{
                  document.getElementById('target19').style.display ="none";
                  document.getElementById('MyGrayBox19').style.display= "flex";
                }


              const [image20 , setImage20] = useState(null);
              const   onImage20Change = (event) => {
                  if (event.target.files && event.target.files[0]) {
                    let reader20 = new FileReader();
                    reader20.onload = (e) => {
                      setImage20(e.target.result);
                      document.getElementById('target20').style.display ="block";
                      document.getElementById('MyGrayBox20').style.display= "none";
        
                      // this.setState({image: e.target.result});
                    };
                    reader20.readAsDataURL(event.target.files[0]);
                  }
                }
                const handleTrashImage20 = () =>{
                  document.getElementById('target20').style.display ="none";
                  document.getElementById('MyGrayBox20').style.display= "flex";
                }


            const [image21 , setImage21] = useState(null);
            const   onImage21Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader21 = new FileReader();
                  reader21.onload = (e) => {
                    setImage21(e.target.result);
                    document.getElementById('target21').style.display ="block";
                    document.getElementById('MyGrayBox21').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader21.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage21 = () =>{
                document.getElementById('target21').style.display ="none";
                document.getElementById('MyGrayBox21').style.display= "flex";
              }


            const [image22 , setImage22] = useState(null);
            const   onImage22Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader22 = new FileReader();
                  reader22.onload = (e) => {
                    setImage22(e.target.result);
                    document.getElementById('target22').style.display ="block";
                    document.getElementById('MyGrayBox22').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader22.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage22 = () =>{
                document.getElementById('target22').style.display ="none";
                document.getElementById('MyGrayBox22').style.display= "flex";
              }


            const [image23 , setImage23] = useState(null);
            const   onImage23Change = (event) => {
                if (event.target.files && event.target.files[0]) {
                  let reader23 = new FileReader();
                  reader23.onload = (e) => {
                    setImage23(e.target.result);
                    document.getElementById('target23').style.display ="block";
                    document.getElementById('MyGrayBox23').style.display= "none";
      
                    // this.setState({image: e.target.result});
                  };
                  reader23.readAsDataURL(event.target.files[0]);
                }
              }
              const handleTrashImage23 = () =>{
                document.getElementById('target23').style.display ="none";
                document.getElementById('MyGrayBox23').style.display= "flex";
              }


          const [image24 , setImage24] = useState(null);
          const   onImage24Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader24 = new FileReader();
                reader24.onload = (e) => {
                  setImage24(e.target.result);
                  document.getElementById('target24').style.display ="block";
                  document.getElementById('MyGrayBox24').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader24.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage24 = () =>{
              document.getElementById('target24').style.display ="none";
              document.getElementById('MyGrayBox24').style.display= "flex";
            }

          const [image25 , setImage25] = useState(null);
          const   onImage25Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader25 = new FileReader();
                reader25.onload = (e) => {
                  setImage25(e.target.result);
                  document.getElementById('target25').style.display ="block";
                  document.getElementById('MyGrayBox25').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader25.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage25 = () =>{
              document.getElementById('target25').style.display ="none";
              document.getElementById('MyGrayBox25').style.display= "flex";
            }

          const [image26 , setImage26] = useState(null);
          const   onImage26Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader26 = new FileReader();
                reader26.onload = (e) => {
                  setImage26(e.target.result);
                  document.getElementById('target26').style.display ="block";
                  document.getElementById('MyGrayBox26').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader26.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage26 = () =>{
              document.getElementById('target26').style.display ="none";
              document.getElementById('MyGrayBox26').style.display= "flex";
            }

          const [image27 , setImage27] = useState(null);
          const   onImage27Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader27 = new FileReader();
                reader27.onload = (e) => {
                  setImage27(e.target.result);
                  document.getElementById('target27').style.display ="block";
                  document.getElementById('MyGrayBox27').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader27.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage27 = () =>{
              document.getElementById('target27').style.display ="none";
              document.getElementById('MyGrayBox27').style.display= "flex";
            }

          const [image28 , setImage28] = useState(null);
          const   onImage28Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader28 = new FileReader();
                reader28.onload = (e) => {
                  setImage28(e.target.result);
                  document.getElementById('target28').style.display ="block";
                  document.getElementById('MyGrayBox28').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader28.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage28 = () =>{
              document.getElementById('target28').style.display ="none";
              document.getElementById('MyGrayBox28').style.display= "flex";
            }

          const [image29 , setImage29] = useState(null);
          const   onImage29Change = (event) => {
              if (event.target.files && event.target.files[0]) {
                let reader29 = new FileReader();
                reader29.onload = (e) => {
                  setImage29(e.target.result);
                  document.getElementById('target29').style.display ="block";
                  document.getElementById('MyGrayBox29').style.display= "none";
    
                  // this.setState({image: e.target.result});
                };
                reader29.readAsDataURL(event.target.files[0]);
              }
            }
            const handleTrashImage29 = () =>{
              document.getElementById('target29').style.display ="none";
              document.getElementById('MyGrayBox29').style.display= "flex";
            }
                
        const [DateofEnteringHighSchool, setDateofEnteringHighSchool] = useState()
        const [GraduationDate, setGraduationDate] = useState()
        const [DateofEnteringUniversityFogDiplom , setDateofEnteringUniversityFogDiplom ] = useState()
        const [GraduationDateFogDiplom , setGraduationDateFogDiplom ] = useState()
        const [DateofEnteringUniversityBachelor , setDateofEnteringUniversityBachelor ] = useState()
        const [GraduationDateBachelor , setGraduationDateBachelor ] = useState()
        const [DateofEnteringUniversityMasters , setDateofEnteringUniversityMasters ] = useState()
        const [GraduationDateMasters , setGraduationDateMasters ] = useState()
        const [DateofEnteringUniversityPHD , setDateofEnteringUniversityPHD ] = useState()
        const [GraduationDatePHD , setGraduationDatePHD ] = useState()
        const [DateofEnteringUniversityPostDoctoral , setDateofEnteringUniversityPostDoctoral ] = useState()
        const [GraduationDatePostDoctoral , setGraduationDatePostDoctoral ] = useState()
        const [DateofOther1degreevalue, setDateofOther1degreeValue] = useState()
        const [DateofOther2degreevalue, setDateofOther2degreeValue] = useState()
        const [DateofOther3degreevalue, setDateofOther3degreeValue] = useState()
        const [DateofExpirevalue, setDateofExpireValue] = useState()
        const [BirthDayvalue, setBirthDayValue] = useState()
        
        const [DateIssuanceMilitaryCardvalue, setDateIssuanceMilitaryCardValue] = useState()
//-------------------------------

     return ( 
         
      <div className="mainRowPro col-12">
        <fieldset disabled={localStorage.getItem('userType')=='1'}>
        <form onSubmit={handleSubmitPersonal} className="col-12">
        <div className="personalInfo col-12">
          <div className="Step1"> <p className="Cus2Step1GoldP">{t('Step 2-2')} :</p><p className="Cus2Step1BlackP">{localStorage.getItem('whoIs')=='ap' && t('Applicant Educational Information')}{localStorage.getItem('whoIs')=='sp' && t('Applicants Spouse Educational Information')}</p> <a className="back_pagelink" onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}}><ArrowCircleLeft size="18" color="#313131" variant="Bold"/>{t('Back')}</a></div>
          <div> <p className="Cus2Step1SmallP">{t('Fill in all the required information carefully and check it again and again. All information entered will be used precisely at the embassy')}</p></div>
          <div className="Cus2PersonalInfo col-12"> <p>{t('Last Degree and Field of Study')}</p> </div>
          <div className="Cus2Inputs">

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <select
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="EnglishLanguageStatus"
                  value={values.EnglishLanguageStatus}
                  label={t('Skin Color')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('EnglishLanguageStatus')}  
                  placeholder={t('English Language Status')}
                  >
                    <option  value="">
                    {t('English Language Status')}
                  </option >
                  <option  value='1'>{t('I Have')}</option >
                  <option  value='2'>{t('I do not have')}</option >
                  <option  value='3'>{t('I am reading')}</option >
                  <option  value='4'>{t('I will be able to get a degree in the next 6 months')}</option >
                  <option  value='5'>{t('I will not be able to get a degree for the next 8 months')}</option > 
                </select >
                <label htmlFor="EnglishLanguageStatus">{t('Enter the applicants English Language Status')}</label>
            </div> */}
            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="LastEducationalCertificate" 
                value={values.LastEducationalCertificate}
                type="text"
                placeholder={t('Last Educational Certificate')}
                onChange={handleChange('LastEducationalCertificate')}
                required
                />
                <label htmlFor="LastEducationalCertificate">{t('Enter the applicants Last Educational Certificate')} <p className="ReqiredCus">{t('(Required)')}</p> </label>
            </div>

            <div className="inputAndlabel col-lg-6 col-12">
              <input
                id="FieldStudy" 
                value={values.FieldStudy}
                type="text"
                placeholder={t('Field of Study')}
                onChange={handleChange('FieldStudy')}
                required
                />
                <label htmlFor="FieldStudy">{t('Enter the applicants Field of Study')} <p className="ReqiredCus">{t('(Required)')}</p></label>
            </div>


            
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('High School')}</p> 
          
          </div>
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofEnteringHighSchool}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Entering High School')}
              id="DateofEnteringHighSchool"
              onChange={setDateofEnteringHighSchool} 
              className="col-12"
            />}
              <label htmlFor="DateofEnteringHighSchool">{t('Enter the applicants Date of Entering High School')}</label>
          </div>
          <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={GraduationDate}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Graduation Date')}
              id="GraduationDate"
              onChange={setGraduationDate} 
              className="col-12"
            />}
              <label htmlFor="GraduationDate">{t('Enter the applicants Graduation Date')}</label>
          </div>

            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="ExpireDate" 
                value={values.ExpireDate}
                type="text"
                placeholder="Expiration Date"
                onChange={handleChange('ExpireDate')}
                />
                <label htmlFor="ExpireDate">Enter the applicant's Passport Expiration Date</label>
            </div> */}
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FieldStudyDiploma" 
                value={values.FieldStudyDiploma}
                type="text"
                placeholder={t('Field of Study in Diploma')}
                onChange={handleChange('FieldStudyDiploma')}
                />
                <label htmlFor="FieldStudyDiploma">{t('Enter the applicants Field of Study in Diploma')}</label>
            </div>
            
           
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Associate Degree')}</p> 
          
          </div>
          
            
       
           
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofEnteringUniversityFogDiplom }
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Entering University')}
              id="DateofEnteringUniversity"
              onChange={setDateofEnteringUniversityFogDiplom } 
              className="col-12"
            />}
              <label htmlFor="DateofEnteringUniversity">{t('Enter the applicants Date of Entering University')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityNameFogDiplom" 
                value={values.UniversityNameFogDiplom}
                type="text"
                placeholder={t('University Name')}
                onChange={handleChange('UniversityNameFogDiplom')}
                />
                <label htmlFor="UniversityNameFogDiplom">{t('Enter the applicants University Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityPlcaeFogDiplom" 
                value={values.UniversityPlcaeFogDiplom}
                type="text"
                placeholder={t('University Plcae')}
                onChange={handleChange('UniversityPlcaeFogDiplom')}
                />
                <label htmlFor="UniversityPlcaeFogDiplom">{t('Enter the applicants University Plcae')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FieldStudyFogDiplom" 
                value={values.FieldStudyFogDiplom}
                type="text"
                placeholder={t('Field of Study')}
                onChange={handleChange('FieldStudyFogDiplom')}
                />
                <label htmlFor="FieldStudyFogDiplom">{t('Enter the applicants Field of Study')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={GraduationDateFogDiplom}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Graduation Date')}
              id="GraduationDateFogDiplom"
              onChange={setGraduationDateFogDiplom} 
              className="col-12"
            />}
              <label htmlFor="DateofTOEFLdegree">{t('Enter the applicants Graduation Date')}</label>
          </div>

            


          <div className="Cus2PersonalInfo col-12"> 
            <p>{t('Bachelor Degree')}</p>
          </div>

       
           
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofEnteringUniversityBachelor}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Entering University')}
              id="DateofEnteringUniversityBachelor"
              onChange={setDateofEnteringUniversityBachelor } 
              className="col-12"
            />}
              <label htmlFor="DateofEnteringUniversityBachelor">{t('Enter the applicants Date of Entering University')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityNameBachelor" 
                value={values.UniversityNameBachelor}
                type="text"
                placeholder={t('University Name')}
                onChange={handleChange('UniversityNameBachelor')}
                />
                <label htmlFor="UniversityNameBachelor">{t('Enter the applicants University Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityPlcaeBachelor" 
                value={values.UniversityPlcaeBachelor}
                type="text"
                placeholder={t('University Plcae')}
                onChange={handleChange('UniversityPlcaeBachelor')}
                />
                <label htmlFor="UniversityPlcaeBachelor">{t('Enter the applicants University Plcae')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FieldStudyBachelor" 
                value={values.FieldStudyBachelor}
                type="text"
                placeholder={t('Field of Study')}
                onChange={handleChange('FieldStudyBachelor')}
                />
                <label htmlFor="FieldStudyBachelor">{t('Enter the applicants Field of Study')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={GraduationDateBachelor}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Graduation Date')}
              id="GraduationDateBachelor"
              onChange={setGraduationDateBachelor} 
              className="col-12"
            />}
              <label htmlFor="DateofTOEFLdegree">{t('Enter the applicants Graduation Date')}</label>
          </div>

            


            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Masters Degree')}</p> 
          
          </div>
          
       
           
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofEnteringUniversityMasters}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Entering University')}
              id="DateofEnteringUniversityMasters"
              onChange={setDateofEnteringUniversityMasters } 
              className="col-12"
            />}
              <label htmlFor="DateofEnteringUniversityMasters">{t('Enter the applicants Date of Entering University')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityNameMasters" 
                value={values.UniversityNameMasters}
                type="text"
                placeholder={t('University Name')}
                onChange={handleChange('UniversityNameMasters')}
                />
                <label htmlFor="UniversityNameMasters">{t('Enter the applicants University Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityPlcaeMasters" 
                value={values.UniversityPlcaeMasters}
                type="text"
                placeholder={t('University Plcae')}
                onChange={handleChange('UniversityPlcaeMasters')}
                />
                <label htmlFor="UniversityPlcaeMasters">{t('Enter the applicants University Plcae')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FieldStudyMasters" 
                value={values.FieldStudyMasters}
                type="text"
                placeholder={t('Field of Study')}
                onChange={handleChange('FieldStudyMasters')}
                />
                <label htmlFor="FieldStudyMasters">{t('Enter the applicants Field of Study')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={GraduationDateMasters}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Graduation Date')}
              id="GraduationDateMasters"
              onChange={setGraduationDateMasters} 
              className="col-12"
            />}
              <label htmlFor="GraduationDateMasters">{t('Enter the applicants Graduation Date')}</label>
          </div>

            
            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('P.H.D Degree')}</p> 
          
          </div>
         
          
       
           
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofEnteringUniversityPHD}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Entering University')}
              id="DateofEnteringUniversityPHD"
              onChange={setDateofEnteringUniversityPHD } 
              className="col-12"
            />}
              <label htmlFor="DateofEnteringUniversityPHD">{t('Enter the applicants Date of Entering University')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityNamePHD" 
                value={values.UniversityNamePHD}
                type="text"
                placeholder={t('University Name')}
                onChange={handleChange('UniversityNamePHD')}
                />
                <label htmlFor="UniversityNamePHD">{t('Enter the applicants University Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityPlcaePHD" 
                value={values.UniversityPlcaePHD}
                type="text"
                placeholder={t('University Plcae')}
                onChange={handleChange('UniversityPlcaePHD')}
                />
                <label htmlFor="UniversityPlcaePHD">{t('Enter the applicants University Plcae')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FieldStudyPHD" 
                value={values.FieldStudyPHD}
                type="text"
                placeholder={t('Field of Study')}
                onChange={handleChange('FieldStudyPHD')}
                />
                <label htmlFor="FieldStudyPHD">{t('Enter the applicants Field of Study')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={GraduationDatePHD}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Graduation Date')}
              id="GraduationDatePHD"
              onChange={setGraduationDatePHD} 
              className="col-12"
            />}
              <label htmlFor="GraduationDatePHD">{t('Enter the applicants Graduation Date')}</label>
          </div>

           

            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Post-Doctoral Degree')}</p> 
          
          </div>
          
            
           
            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={DateofEnteringUniversityPostDoctoral}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Date of Entering University')}
              id="DateofEnteringUniversityPostDoctoral"
              onChange={setDateofEnteringUniversityPostDoctoral } 
              className="col-12"
            />}
              <label htmlFor="DateofEnteringUniversityPostDoctoral">{t('Enter the applicants Date of Entering University')}</label>
          </div>
            {/* <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="DateofCreation" 
                value={values.DateofCreation}
                type="text"
                placeholder="Creation Date"
                onChange={handleChange('DateofCreation')}
                />
                <label htmlFor="DateofCreation">Enter the applicant's Passport Creation Date</label>
            </div> */}

            
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityNamePostDoctoral" 
                value={values.UniversityNamePostDoctoral}
                type="text"
                placeholder={t('University Name')}
                onChange={handleChange('UniversityNamePostDoctoral')}
                />
                <label htmlFor="UniversityNamePostDoctoral">{t('Enter the applicants University Name')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="UniversityPlcaePostDoctoral" 
                value={values.UniversityPlcaePostDoctoral}
                type="text"
                placeholder={t('University Plcae')}
                onChange={handleChange('UniversityPlcaePostDoctoral')}
                />
                <label htmlFor="UniversityPlcaePostDoctoral">{t('Enter the applicants University Plcae')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="FieldStudyPostDoctoral" 
                value={values.FieldStudyPostDoctoral}
                type="text"
                placeholder={t('Field of Study')}
                onChange={handleChange('FieldStudyPostDoctoral')}
                />
                <label htmlFor="FieldStudyPostDoctoral">{t('Enter the applicants Field of Study')}</label>
            </div>

            <div className="inputAndlabel col-lg-4 col-12">
            {  <DatePicker 
              value={GraduationDatePostDoctoral}
              format="YYYY/MM/DD "
              calendar="persian"
              
              locale={gregorian_en}
              placeholder={t('Graduation Date')}
              id="GraduationDatePostDoctoral"
              onChange={setGraduationDatePostDoctoral} 
              className="col-12"
            />}
              <label htmlFor="GraduationDatePostDoctoral">{t('Enter the applicants Graduation Date')}</label>
          </div>

            


            <div className="Cus2PersonalInfo col-12"> 
          <p>{t('Non-university Certificate')}</p> 
          
          </div>
          
            

           
           

            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="NonuniversityCertificate1" 
                value={values.NonuniversityCertificate1}
                type="text"
                placeholder={t('Non-university Certificate Name(1)')}
                onChange={handleChange('NonuniversityCertificate1')}
                />
                <label htmlFor="NonuniversityCertificate1">{t('Enter the applicants Non-university Certificate Name(1)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="NonuniversityCertificate2" 
                value={values.NonuniversityCertificate2}
                type="text"
                placeholder={t('Non-university Certificate Name(2)')}
                onChange={handleChange('NonuniversityCertificate2')}
                />
                <label htmlFor="NonuniversityCertificate2">{t('Enter the applicants Non-university Certificate Name(2)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="NonuniversityCertificate3" 
                value={values.NonuniversityCertificate3}
                type="text"
                placeholder={t('Non-university Certificate Name(3)')}
                onChange={handleChange('NonuniversityCertificate3')}
                />
                <label htmlFor="NonuniversityCertificate3">{t('Enter the applicants Non-university Certificate Name(3)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="NonuniversityCertificate4" 
                value={values.NonuniversityCertificate4}
                type="text"
                placeholder={t('Non-university Certificate Name(4)')}
                onChange={handleChange('NonuniversityCertificate4')}
                />
                <label htmlFor="NonuniversityCertificate4">{t('Enter the applicants Non-university Certificate Name(4)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="NonuniversityCertificate5" 
                value={values.NonuniversityCertificate5}
                type="text"
                placeholder={t('Non-university Certificate Name(5)')}
                onChange={handleChange('NonuniversityCertificate5')}
                />
                <label htmlFor="NonuniversityCertificate5">{t('Enter the applicants Non-university Certificate Name(5)')}</label>
            </div>
            <div className="inputAndlabel col-lg-4 col-12">
              <input
                id="NonuniversityCertificate6" 
                value={values.NonuniversityCertificate6}
                type="text"
                placeholder={t('Non-university Certificate Name(6)')}
                onChange={handleChange('NonuniversityCertificate6')}
                />
                <label htmlFor="NonuniversityCertificate6">{t('Enter the applicants Non-university Certificate Name(6)')}</label>
            </div>


          </div>
          <div className="Cus2PersonalInfoMidline"> 
          <p>{t('Educational Documents')}</p> 
          
          </div>
          <hr width="75px" className="Cus2Step1UnderLineMidle"/>
          
  <div className="MyFileUploadRow">

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImageChange} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target" src={image} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Pre-High School (1)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox2">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage2Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target2" src={image2} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage2} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Pre-High School (2)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox3">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage3Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target3" src={image3} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage3} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Pre-High School (3)')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox4">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage4Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target4" src={image4} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage4} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('High School(transcripts) 1th')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox5">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage5Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target5" src={image5} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage5} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('High School(transcripts) 2th')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox6">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage6Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target6" src={image6} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage6} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('High School(transcripts) 3th')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox7">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage7Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target7" src={image7} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage7} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('High School(transcripts) 4th')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox8">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage8Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target8" src={image8} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage8} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Diploma Certificate')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox9">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage9Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target9" src={image9} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage9} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Associate Diploma Transcripts')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox10">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage10Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target10" src={image10} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage10} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Associate Cerificate')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox11">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage11Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target11" src={image11} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage11} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Bachlor Transcripts')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox12">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage12Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target12" src={image12} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage12} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Bachlor Cerificate')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox13">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage13Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target13" src={image13} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage13} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Masters Transcripts')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox14">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage14Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target14" src={image14} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage14} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Masters Cerificate')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox15">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage15Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target15" src={image15} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage15} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('P.H.D Transcripts')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox16">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage16Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target16" src={image16} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage16} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('P.H.D Cerificate')}</p> 
    </div>
    </div>



    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox17">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage17Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target17" src={image17} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage17} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Post-Doctoral Transcripts')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
     <div className="MyCusUploadGray" id="MyGrayBox18">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage18Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target18" src={image18} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage18} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Post-Doctoral Cerificate')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox19">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage19Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target19" src={image19} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage19} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('ISI Papers')}</p> 
    </div>
    </div>

    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox20">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage20Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target20" src={image20} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage20} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('CV')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox21">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage21Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target21" src={image21} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage21} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Motivation Letter')}</p> 
    </div>
    </div>



    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox22">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage22Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target22" src={image22} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage22} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Non-University Degrees (1)')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox23">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage23Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target23" src={image23} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage23} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Non-University Degrees (2)')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox24">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage24Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target24" src={image24} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage24} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Non-University Degrees (3)')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox25">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage25Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target25" src={image25} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage25} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Non-University Degrees (4)')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox26">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage26Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target26" src={image26} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage26} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Non-University Degrees (5)')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox27">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input
            className="MyCusInputFileUpload"
            type="file" 
            onChange={onImage27Change} 
            id="group_image"
            accept="image/gif, image/jpeg, image/png"
            />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target27" src={image27} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage27} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Non-University Degrees (6)')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox28">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input 
          className="MyCusInputFileUpload" 
          type="file" 
          onChange={onImage28Change} 
          id="group_image"
          accept="image/gif, image/jpeg, image/png"
          />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target28" src={image28} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage28} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Scientific Documents of Awards or Festivals')}</p> 
    </div>
    </div>


    <div className="box_MyCusUploadReactangle col-lg-3 col-md-4 col-6">
            <div className="MyCusUploadReactangle">
      <div className="MyCusUploadGray" id="MyGrayBox29">
          <GalleryAdd  size="32" color="#9B9B9B" variant="Bold" />
          <input 
          className="MyCusInputFileUpload" 
          type="file" 
          onChange={onImage29Change} 
          id="group_image"
          accept="image/gif, image/jpeg, image/png"
          />
          {/* <input 
          type="file" 
          className="MyCusInputFileUpload"
          onChange={}
          /> */}
      </div>
      <div className="MyClickAbleRectForHide">
      <img id="target29" src={image29} className="MyCusImageUploadPreview"/>
      <div className="MyCusUploadGrayTrans" id="MyGrayBoxTrans">
          <Trash size="24" color="#E60000" variant="Bold" onClick={handleTrashImage29} className="MyRedTrash"/>
          <ExportSquare size="24" color="#ffffff" />
        </div>
      </div>
      
      <p>{t('Other Documents')}</p> 
    </div>
    </div>


   </div>


   {localStorage.getItem('userType')=='3' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Cancel')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {<button className="btn btn-dark">{t('Submit')}</button>}
            </div>
           
          </div>}
        </div>
        </form>
        </fieldset>

        {localStorage.getItem('userType')=='1' &&<div className="cusS1SaveCol col-12">
            <div className="col-6">
               <button  onClick={(dataCus1) => {childToParentCus1e3(dataCus1);}} className="btn col-12 btn-outline-light ">{t('Back')}</button >
            </div>
            <div className="botCusDraftCol col-6">
              
              {/* {<button className="btn btn-dark">{t('Submit')}</button>} */}
            </div>
           
          </div>}
       
{/*         
        {isOpen && <Popup
      content={<>
      <div className="PboxMainRow">
            <div className="PboxFirstRow">
                <ShieldSecurity size="48" color="#313131" variant="Bold"/>
            </div>
            <div className="PboxSecondRow">
            <h4>{t('Are you sure?')}</h4>
            </div>
            <div className="PboxThirdRow">
                <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
            </div>
            <div className="PboxForthRowSendMail col-12">
                
                <button onClick={togglePopup} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
                <button onClick={handleSubmitChangePassword} className="col-3 btn btn-dark">{t('Send Link!')}</button>
            </div>
        
        </div>
      </>}
      handleClose={togglePopup}
    />}
 */}

     </div>
      );
 }
 
export default Profile2;