import { useState, useEffect} from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {Edit2,InfoCircle,ArrowUp2,TickSquare,ShieldSecurity,Facebook,Whatsapp,CallCalling,Instagram,Youtube} from 'iconsax-react';
import { useTranslation } from 'react-i18next'; // multi language package
import proFilePic from './images/profilePic.png';
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import gregorian from "react-date-object/calendars/gregorian"
import persian_fa from "react-date-object/locales/persian_fa"
import gregorian_en from "react-date-object/locales/gregorian_en"
import Popup from './Popup';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { blue, grey } from '@mui/material/colors';
import axios from 'axios';
function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const Profile = () => {
    const forceUpdate = useForceUpdate();
    const userActive =true;
    const [formError , setFormError] = useState(false);
    const [helpEmail,setHelpEmail] = useState('');
    const [helpJob, setHelpJob] = useState();
    const [helpCity, setHelpCity] = useState();
    const [helpiccrc, setHelpiccrc] = useState();
    const [body, setBody] = useState();
    const [Certificate, setCertificate] = useState();
    const [ispending, setIspending] = useState(false);
    const history = useHistory();
    //****************submits**********************************/
  const handleSubmitSubmitPopPersonal = () =>{
    if(localStorage.getItem('userType')=='1')
    {
      fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepOne' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
        Email: localStorage.email3 ,
        Password:localStorage.password3 ,
          // Email: "kambiz.fakhr22@gmail.com" ,
          // Password:"123" ,
        FirstName:values.proFirstName,
        LastName: values.proLastName ,
        Gender: values.Gender,
        DateOfBirth:values.birthDate,
        AboutMe:values.proAbout
      })
      }).then().then(history.push('/Dashboard'));
    }
else if(localStorage.getItem('userType')=='2')
  {
    fetch('https://telesiness.ir/api/AgentProfile/AgentEditStatusStepEducationalInformation' , {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
      Email: localStorage.email3 ,
      firstName:values.proFirstName,
      lastName: values.proLastName ,
      Gender: values.Gender,
      DateOfBirth:values.birthDate,
      AboutMe:values.proAbout
    })
    }).then().then(history.push('/Dashboard'));
  }
else if(localStorage.getItem('userType')=='3')
    {
      fetch('https://telesiness.ir/api/CustomerProfile/CustomerEditStatusStepPersonalInformation' , {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
        Email: localStorage.email3 ,
        Password:localStorage.password3 ,
          // Email: "kambiz.fakhr22@gmail.com" ,
          // Password:"123" ,
        FirstName:values.proFirstName,
        LastName :values.proLastName ,
        Gender: values.Gender,
        DateOfBirth:values.birthDate,
        AboutMe:values.proAbout,
        PhoneNumber: values.proPhone,

      })
      }).then().then(history.push('/Dashboard'));
    }
    //     setIspending(false);
    document.getElementById('personalCancel').style.display="none";
    document.getElementById('personalSave').style.display="none";
    document.getElementById('personPen').style.display="none";
    document.getElementById('personPenBlue').style.display="none";
    document.getElementById('proFirstName').setAttribute("disabled","disabled");
    document.getElementById('proLastName').setAttribute("disabled","disabled");
    document.getElementById('Gender').setAttribute("disabled","disabled");
    //document.getElementById('birthDate').setAttribute("disabled","disabled");
    setMyCalendar(false);
    document.getElementById('inputproAbout').setAttribute("disabled","disabled");
    // setValues({ ...values, ['firstTimeEditPersonal']: false});
        // history.push('/Dashboard');
    // }
    // )
    forceUpdate();
    togglePopup2();
  }
  const handleSubmitPersonal = (e) => {

         e.preventDefault();
        // setIspending(true);
        // const blog = { username, body , author};
       
        handlePopPersonslInfo();
    }

const handleSubmitSubmitPopAddress = () =>{
  if(localStorage.getItem('userType')=='1')
  {
   fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepTwo' , {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ 
     Email: localStorage.email3 ,
     Password:localStorage.password3 ,
       // Email: "kambiz.fakhr22@gmail.com" ,
       // Password:"123" ,
     LawyerCountry:values.Country,
     LawyerCity:values.City,
     LawyerAddress:values.Address,
     PostagePlate:values.proNo,
     ZipCode:values.ZipCode
   })
   }).then().then(history.push('/Dashboard'));
  }
  if(localStorage.getItem('userType')=='3')
  {
   fetch('https://telesiness.ir/api/CustomerProfile/CustomerEditStatusStepAddressInformation' , {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ 
     Email: localStorage.email3 ,

     CustomerCountry:values.Country,
     CustomerCity:values.City,
     CustomerAddress:values.Address,
     Number:values.proNo,
     ZipCode:values.ZipCode
   })
   }).then().then(history.push('/Dashboard'));
  }
  //     setIspending(false);
  document.getElementById('addressCancel').style.display="none";
  document.getElementById('addressSave').style.display="none";
  document.getElementById('addressPen').style.display="none";
  document.getElementById('addressPenBlue').style.display="none";
  document.getElementById('inputCountry').setAttribute("disabled","disabled");
  document.getElementById('inputCity').setAttribute("disabled","disabled");
  document.getElementById('proNo').setAttribute("disabled","disabled");
  document.getElementById('Address').setAttribute("disabled","disabled");
  document.getElementById('ZipCode').setAttribute("disabled","disabled");
 //  setValues({ ...values, ['firstTimeEditAddress']: false});
       history.push('/Dashboard');
       forceUpdate();
       togglePopup3();
}

  const handleSubmitaddress = (e) => {
      e.preventDefault();
     // setIspending(true);
    togglePopup3();
     // }
     // )
 }
 

const handleSubmitSubmitPopEducational = () => {
  if(localStorage.getItem('userType')=='1')
  {
   fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepThree' , {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ 
     Email: localStorage.email3 ,
     Password:localStorage.password3 ,
       // Email: "kambiz.fakhr22@gmail.com" ,
       // Password:"123" ,
      FieldOfStudy:values.FieldOfStudy,
      UniveristyName:values.University,
      LastEducationalCertificate:values.Level
   })
   }).then().then(history.push('/Dashboard'));
  }
  else if(localStorage.getItem('userType')=='2')
  {
   fetch('https://telesiness.ir/api/AgentProfile/AgentEditStatusStepEducationalInformation' , {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ 
     Email: localStorage.email3 ,
     Password:localStorage.password3 ,
       // Email: "kambiz.fakhr22@gmail.com" ,
       // Password:"123" ,
      FieldOfStudy:values.FieldOfStudy,
      InstituteOrUniversity:values.University,
      LevelOrDegree:values.Level
   })
   }).then().then(history.push('/Dashboard'));
  }
  else if(localStorage.getItem('userType')=='3')
  {
   fetch('https://telesiness.ir/api/CustomerProfile/CustomerEditStatusStepEducationalInformation' , {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ 
     Email: localStorage.email3 ,
     Password:localStorage.password3 ,
       // Email: "kambiz.fakhr22@gmail.com" ,
       // Password:"123" ,
      FieldOfStudy:values.FieldOfStudy,
      University:values.University,
      Degree:values.Level
   })
   }).then().then(history.push('/Dashboard'));
  }
  //     setIspending(false);
  document.getElementById('educationalCancel').style.display="none";
  document.getElementById('educationalSave').style.display="none";
  document.getElementById('educationalPen').style.display="none";
  document.getElementById('educationalPenBlue').style.display="none";
  document.getElementById('FieldOfStudy').setAttribute("disabled","disabled");
  document.getElementById('Level').setAttribute("disabled","disabled");
  document.getElementById('University').setAttribute("disabled","disabled");
 //  setValues({ ...values, ['firstTimeEditEducational']: false});
      // history.push('/Dashboard');
  // }
  // )
  forceUpdate();
  togglePopup4();
}

const handleSubmiteducational = (e) => {
  e.preventDefault();
 // setIspending(true);
 togglePopup4();
}


const handleSubmitSubmitPopJob =() => {
  if(localStorage.getItem('userType')=='1')
  {
   fetch('https://telesiness.ir/api/LawyerProfile/LawyerEditProfileStepFour' , {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ 
     Email: localStorage.email3 ,
     Password:localStorage.password3 ,
       // Email: "kambiz.fakhr22@gmail.com" ,
       // Password:"123" ,
     CountryInWhichYouWork:values.JobCountry,
     TheCityWhereYouWork:values.JobCity,
     SpecializedProfession:values.Specialization,
     IccrcCode:values.iccrc,
     WebsiteAddress:values.website,
     Certificates:values.Certificate
   })
   }).then().then(history.push('/Dashboard'));
  }

  if(localStorage.getItem('userType')=='2')
  {
   fetch('https://telesiness.ir/api/AgentProfile/AgentEditStatusStepJobInformation' , {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ 
     Email: localStorage.email3 ,
     
       // Email: "kambiz.fakhr22@gmail.com" ,
       // Password:"123" ,
       Country:values.JobCountry,
       City:values.JobCity,
       CVName:values.JobUpload,
     
     WebsiteAddress:values.website,
     Certificate:values.Certificate
   })
   }).then().then(history.push('/Dashboard'));
  }
  
  //     setIspending(false);
  document.getElementById('JobCancel').style.display="none";
  document.getElementById('JobSave').style.display="none";
  document.getElementById('JobPen').style.display="none";
  document.getElementById('JobPenBlue').style.display="none";
  document.getElementById('JobCountry').setAttribute("disabled","disabled");
  document.getElementById('JobCity').setAttribute("disabled","disabled");
  document.getElementById('Jobspecialty').setAttribute("disabled","disabled");
  document.getElementById('JobUpload').setAttribute("disabled","disabled");
  if(localStorage.getItem('userType')==1)
  document.getElementById('iccrc').setAttribute("disabled","disabled");
  document.getElementById('website').setAttribute("disabled","disabled");
  document.getElementById('Specialization').setAttribute("disabled","disabled");
  document.getElementById('Certificate').setAttribute("disabled","disabled");
  setValues({ ...values, ['firstTimeEditJob']: false});
  forceUpdate();
  togglePopup5();
 
}
const handleSubmitJob = (e) => {
  e.preventDefault();
 // setIspending(true);
 togglePopup5();
     // history.push('/Dashboard');
 // }
 // )
}

const handleSubmitChangePassword = (e) => {
  e.preventDefault();
 // setIspending(true);
 // const blog = { username, body , author};
 // fetch('http://localhost:8000/blogs' , {
 //     method: 'POST',
 //     headers: {"Content-type": "application/json"},
 //     body:JSON.stringify(blog)
 // }).then(()=>{
 //     console.log('new blog added');
 //     setIspending(false);
  history.push('/resetpass2');
 // }
 // )
}
    //********************cancels***************************** */
const handleCancelPersonal = (e) => {
      e.preventDefault();
     // setIspending(true);
     // const blog = { username, body , author};
     // fetch('http://localhost:8000/blogs' , {
     //     method: 'POST',
     //     headers: {"Content-type": "application/json"},
     //     body:JSON.stringify(blog)
     // }).then(()=>{
     //     console.log('new blog added');
     //     setIspending(false);
     document.getElementById('personalCancel').style.display="none";
     document.getElementById('personalSave').style.display="none";
     document.getElementById('personPen').style.display="flex";
     document.getElementById('personPenBlue').style.display="none";
     document.getElementById('proFirstName').setAttribute("disabled","disabled");
     document.getElementById('proLastName').setAttribute("disabled","disabled");
     document.getElementById('Gender').setAttribute("disabled","disabled");
     //document.getElementById('birthDate').setAttribute("disabled","disabled");
     setMyCalendar(false);
     document.getElementById('inputproAbout').setAttribute("disabled","disabled");
    //history.push('/Dashboard');
     // }
     // )
 }
 const handleCanceladdress = (e) => {
  e.preventDefault();
 // setIspending(true);
 // const blog = { username, body , author};
 // fetch('http://localhost:8000/blogs' , {
 //     method: 'POST',
 //     headers: {"Content-type": "application/json"},
 //     body:JSON.stringify(blog)
 // }).then(()=>{
 //     console.log('new blog added');
 //     setIspending(false);
 document.getElementById('addressCancel').style.display="none";
 document.getElementById('addressSave').style.display="none";
 document.getElementById('addressPen').style.display="flex";
 document.getElementById('addressPenBlue').style.display="none";
 document.getElementById('inputCountry').setAttribute("disabled","disabled");
 document.getElementById('inputCity').setAttribute("disabled","disabled");
 document.getElementById('proNo').setAttribute("disabled","disabled");
 document.getElementById('Address').setAttribute("disabled","disabled");
 document.getElementById('ZipCode').setAttribute("disabled","disabled");
//history.push('/Dashboard');
 // }
 // )
}
const handleCanceleducational = (e) => {
  e.preventDefault();
 // setIspending(true);

 //     setIspending(false);
 document.getElementById('educationalCancel').style.display="none";
 document.getElementById('educationalSave').style.display="none";
 document.getElementById('educationalPen').style.display="flex";
 document.getElementById('educationalPenBlue').style.display="none";
 document.getElementById('FieldOfStudy').setAttribute("disabled","disabled");
 document.getElementById('Level').setAttribute("disabled","disabled");
 document.getElementById('University').setAttribute("disabled","disabled");
//history.push('/Dashboard');
 // }
 // )
}
const handleCancelJob = (e) => {
  e.preventDefault();
 // setIspending(true);
 // const blog = { username, body , author};
 // fetch('http://localhost:8000/blogs' , {
 //     method: 'POST',
 //     headers: {"Content-type": "application/json"},
 //     body:JSON.stringify(blog)
 // }).then(()=>{
 //     console.log('new blog added');
 //     setIspending(false);
 document.getElementById('JobCancel').style.display="none";
 document.getElementById('JobSave').style.display="none";
 document.getElementById('JobPen').style.display="flex";
 document.getElementById('JobPenBlue').style.display="none";
document.getElementById('JobCountry').setAttribute("disabled","disabled");
document.getElementById('JobCity').setAttribute("disabled","disabled");
document.getElementById('Jobspecialty').setAttribute("disabled","disabled");
document.getElementById('JobUpload').setAttribute("disabled","disabled");
if(localStorage.getItem('userType')==1)
document.getElementById('iccrc').setAttribute("disabled","disabled");
document.getElementById('website').setAttribute("disabled","disabled");
document.getElementById('Specialization').setAttribute("disabled","disabled");
document.getElementById('Certificate').setAttribute("disabled","disabled");
//history.push('/Dashboard');
 // }
 // )
}


//*********************my values******************************* */
        const [values, setValues] = useState({
          // proFirstName: 'kambiz',
          // proLastName: 'fakhr',
          // Gender: 'male',
          // birthDate: new Date(),
          // proEmail: 'kambiz.fakhr@yahoo.com',
          // proPhone:'3352258774',
          // proAbout:'I am Sofware Engineer',
          // firstTimeEdit:true,
          // firstTimeEditJob:true,
          // firstTimeEditEducational:true,
          // firstTimeEditAddress:true,
          // firstTimeEditPersonal:true,
          ProPic:'',
          // Country:'Iran',
          // City:'Tehran',
          // Address:'Iran - Tehran - Vanak - Zafar ',
          // ZipCode:'5174875988',
          // proNo:'61',
          // Level:'',
          // FieldOfStudy:'',
          // University:'',
          // JobCountry:'USA',
          // JobCity:'',
          files2:'',
          files3:'',
          JobUpload:'',
          // iccrc:'',
          // website:'',
          // Specialization:'',
          // Certificate:'',
          // userNameLast:'setin',
          // showPassword: false,
          proFirstName: localStorage.getItem('firstName'),
          proLastName: localStorage.getItem('lastName'),
          Gender: localStorage.getItem('gender'),
          birthDate: localStorage.getItem('dateOfBirth'),
          proEmail: localStorage.getItem('email'),
          proPhone: localStorage.getItem('phoneNumber'),
          proAbout: localStorage.getItem('aboutMe'),
          firstTimeEdit:localStorage.getItem('lawyersEditSection1'),
          firstTimeEditJob:localStorage.getItem('lawyersEditSection4'),
          firstTimeEditEducational:localStorage.getItem('lawyersEditSection3'),
          firstTimeEditAddress:localStorage.getItem('lawyersEditSection2'),
          firstTimeEditPersonal:true,
          // ProPic: localStorage.getItem('avatarName'),
          Country: localStorage.getItem('lawyerCountry'),
          City: localStorage.getItem('lawyerCity'),
          Address: localStorage.getItem('laywerAddress'),
          ZipCode: localStorage.getItem('zipCode'),
          proNo: localStorage.getItem('postagePlate'),
          Level: localStorage.getItem('lastEducationalCertificate'),
          FieldOfStudy: localStorage.getItem('fieldOfStudy'),
          University: localStorage.getItem('univeristyName'),
          JobCountry: localStorage.getItem('countryInWhichYouWork'),
          JobCity:localStorage.getItem('theCityWhereYouWork'),
          Jobspecialty: localStorage.getItem('specializedSectionInTheFieldOfImmigration'),
          // JobUpload: localStorage.getItem('cvFile'),
          iccrc: localStorage.getItem('iccrcCode'),
          website: localStorage.getItem('websiteAddress'),
          Specialization: localStorage.getItem('specializedProfession'),
          Certificate: localStorage.getItem('certificates'),
          userNameLast: localStorage.getItem('userName'),
          showPassword: false,
        });
      if(localStorage.getItem('userType')==2){

        values.firstTimeEdit=localStorage.getItem('AgentEditSection1');
        values.firstTimeEditJob=localStorage.getItem('AgentEditSection3');
        values.firstTimeEditEducational=localStorage.getItem('AgentEditSection2');
        values.Country= localStorage.getItem('AgentCountry');
        values.City= localStorage.getItem('AgentCity');
        values.Address= localStorage.getItem('AgentAddress');
        values.ZipCode= localStorage.getItem('zipCode');
        values.proNo= localStorage.getItem('Number');
        // values.FieldOfStudy,
        // values.University,
        // values.Level
      }
      if(localStorage.getItem('userType')==3){

        values.firstTimeEdit=localStorage.getItem('CustomerEditSection1');
        values.firstTimeEditAddress=localStorage.getItem('CustomerEditSection2');
        values.firstTimeEditEducational=localStorage.getItem('CustomerEditSection3');
        values.proNo= localStorage.getItem('Number');
        // values.FieldOfStudy,
        // values.University,
        // values.Level
      }
      if(localStorage.getItem('userType')==4){

        values.firstTimeEdit=localStorage.getItem('CustomerEditSection1');
        values.firstTimeEditAddress=localStorage.getItem('CustomerEditSection2');
        values.firstTimeEditEducational=localStorage.getItem('CustomerEditSection3');
        // values.FieldOfStudy,
        // values.University,
        // values.Level
      }

        const handleChange = (prop) => (event) => {
          setValues({ ...values, [prop]: event.target.value });
          if(!(document.getElementById('Gender').value=="")){
            //  document.getElementById('errorLabelDegree').style.display="flex";
             document.getElementById('MyGenderplaceholder').style.color="transparent";
             document.getElementById('MyGenderSapn').style.color="transparent";
            }
            else{
            //  document.getElementById('errorLabelDegree').style.display="none";
             document.getElementById('MyGenderplaceholder').style.color="gray";
             document.getElementById('MyGenderSapn').style.color="red";
            }
            if( localStorage.getItem('userType')!=2)
          if((document.getElementById('inputCountry').value!="" && localStorage.getItem('userType')!=2)){
            //  document.getElementById('errorLabelDegree').style.display="flex";
              document.getElementById('MyCountryplaceholder').style.color="transparent";
              document.getElementById('MyCountrySapn').style.color="transparent";
            }
            else if( localStorage.getItem('userType')!=2){
            //  document.getElementById('errorLabelDegree').style.display="none";
              document.getElementById('MyCountryplaceholder').style.color="gray";
              document.getElementById('MyCountrySapn').style.color="red";
            }
            if( localStorage.getItem('userType')!=2)
          if((document.getElementById('inputCity').value!="" && localStorage.getItem('userType')!=2)){
            //  document.getElementById('errorLabelDegree').style.display="flex";
              document.getElementById('MyCityplaceholder').style.color="transparent";
              document.getElementById('MyCitySapn').style.color="transparent";
            }
            else if( localStorage.getItem('userType')!=2){
            //  document.getElementById('errorLabelDegree').style.display="none";
              document.getElementById('MyCityplaceholder').style.color="gray";
              document.getElementById('MyCitySapn').style.color="red";
            }

          if(!(document.getElementById('Level').value=="")){
            //  document.getElementById('errorLabelDegree').style.display="flex";
              document.getElementById('MyLevelplaceholder').style.color="transparent";
              document.getElementById('MyLevelSapn').style.color="transparent";
            }
            else{
            //  document.getElementById('errorLabelDegree').style.display="none";
              document.getElementById('MyLevelplaceholder').style.color="gray";
              document.getElementById('MyLevelSapn').style.color="red";
            }
            if(localStorage.getItem('userType')!=3)
            if(!(document.getElementById('JobCountry').value=="")){
              //  document.getElementById('errorLabelDegree').style.display="flex";
                document.getElementById('MyJobCountryplaceholder').style.color="transparent";
                document.getElementById('MyJobCountrySapn').style.color="transparent";
              }
              else if(localStorage.getItem('userType')!=3){
              //  document.getElementById('errorLabelDegree').style.display="none";
                document.getElementById('MyJobCountryplaceholder').style.color="gray";
                document.getElementById('MyJobCountrySapn').style.color="red";
              }
              if(localStorage.getItem('userType')!=3)
            if(!(document.getElementById('JobCity').value=="")){
              //  document.getElementById('errorLabelDegree').style.display="flex";
                document.getElementById('MyJobCityplaceholder').style.color="transparent";
                document.getElementById('MyJobCitySapn').style.color="transparent";
              }
              else if(localStorage.getItem('userType')!=3){
              //  document.getElementById('errorLabelDegree').style.display="none";
                document.getElementById('MyJobCityplaceholder').style.color="gray";
                document.getElementById('MyJobCitySapn').style.color="red";
              }
              if(localStorage.getItem('userType')!=3)
              {
                var name = document.getElementById('JobUpload'); 
                var totalFileSize = 0;
                for (var i = 0; i <= name.files.length - 1; i++)
              {
                  //ACCESS THE SIZE PROPERTY OF THE ITEM OBJECT IN FILES COLLECTION. IN THIS WAY ALSO GET OTHER PROPERTIES LIKE FILENAME AND FILETYPE
                  var fsize = name.files.item(i).size;
                  totalFileSize = totalFileSize + fsize;
                  document.getElementById('files').innerHTML =
                  document.getElementById('files').innerHTML
                  +
                  '<b>' + name.files.item(i).name
                  +
                   '</b> '//'and Size is <b>' + Math.round((fsize / 1024)) //DEFAULT SIZE IS IN BYTES SO WE DIVIDING BY 1024 TO CONVERT IT IN KB
                  // +
                  // '</b> KB and File Type is <b>' + name.files.item(i).type + "</b>.";
              }
              }
             
        };
      
        const handleClickShowPassword = () => {
          setValues({
            ...values,
            showPassword: !values.showPassword,
          });
        };
      
        const handleMouseDownPassword = (event) => {
          event.preventDefault();
        };
       
//**********************multi language functios **********************/
const {t, i18n} = useTranslation(['Login']);
const [myCalaedar , setMyCalendar]= useState(false);

const handleinputEmailBlur = (e) =>{
  if((!values.Email.match("^[a-zA-Z0-9]+\.+[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$"))&
  !(document.activeElement.id=='inputEmail')){
    setHelpEmail('Envalid Email Format');
    setFormError(true);
  }
    else
    {
    setHelpEmail('');
    setFormError(false);
    }
   if(!(document.getElementById('inputEmail').value=="")){
    document.getElementById('errorLabelEmail').style.display="flex";
    document.getElementById('MyEmailplaceholder').style.color="transparent";
    document.getElementById('MyEmailSapn').style.color="transparent";
   }
   else{
    document.getElementById('errorLabelEmail').style.display="none";
    document.getElementById('MyEmailplaceholder').style.color="gray";
    document.getElementById('MyEmailSapn').style.color="red";
   }
 }

 
 const handleinputproFirstNameBlur = (e) =>{
   if(!(document.getElementById('proFirstName').value=="")){
    // document.getElementById('errorLabelCountry').style.display="flex";
    document.getElementById('MyproFirstNameplaceholder').style.color="transparent";
    document.getElementById('MyproFirstNameSapn').style.color="transparent";
   }
   else{
    // document.getElementById('errorLabelCountry').style.display="none";
    document.getElementById('MyproFirstNameplaceholder').style.color="gray";
    document.getElementById('MyproFirstNameSapn').style.color="red";
   }
 }

 const handleinputproLastNameBlur = (e) =>{
  if(!(document.getElementById('proLastName').value=="")){
   document.getElementById('errorLabelproLastName').style.display="flex";
   document.getElementById('MyproLastNameplaceholder').style.color="transparent";
   document.getElementById('MyproLastNameSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelproLastName').style.display="none";
   document.getElementById('MyproLastNameplaceholder').style.color="gray";
   document.getElementById('MyproLastNameSapn').style.color="red";
  }
}

const handleinputGenderBlur = (e) =>{
  if(!(document.getElementById('Gender').value=="")){
  //  document.getElementById('errorLabelDegree').style.display="flex";
   document.getElementById('MyGenderplaceholder').style.color="transparent";
   document.getElementById('MyGenderSapn').style.color="transparent";
  }
  else{
  //  document.getElementById('errorLabelDegree').style.display="none";
   document.getElementById('MyGenderplaceholder').style.color="gray";
   document.getElementById('MyGenderSapn').style.color="red";
  }
}

const handleinputbirthDateBlur = (e) =>{
  if(!(document.getElementById('birthDate').value=="")){
   document.getElementById('errorLabelbirthDate').style.display="flex";
   document.getElementById('MybirthDateplaceholder').style.color="transparent";
   document.getElementById('MybirthDateSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelbirthDate').style.display="none";
   document.getElementById('MybirthDateplaceholder').style.color="gray";
   document.getElementById('MybirthDateSapn').style.color="red";
  }
}
//***********************edit pen************************* */
const handelPersonalVisible =(e) => {
    if(values.firstTimeEdit){
      document.getElementById('personalCancel').style.display="flex";
      document.getElementById('personalSave').style.display="flex";
      document.getElementById('personalCancel').style.justifyContent="center";
      document.getElementById('personalSave').style.justifyContent="center";
      document.getElementById('personPen').style.display="none";
      document.getElementById('personPenBlue').style.display="flex";
        //enable
      document.getElementById('proFirstName').removeAttribute("disabled");
      document.getElementById('proLastName').removeAttribute("disabled");
      document.getElementById('Gender').removeAttribute("disabled");
      //document.getElementById('birthDate').removeAttribute("disabled");
      setMyCalendar(true);
      document.getElementById('inputproAbout').removeAttribute("disabled");
    }
}
const handelPersonalBlueVisible =(e) => {
  if(values.firstTimeEdit){
    // document.getElementById('personalCancel').style.display="flex";
    // document.getElementById('personalSave').style.display="flex";
    // document.getElementById('personPen').style.display="none";
    // document.getElementById('personPenBlue').style.display="flex";
  }
}
const handeladdressVisible =(e) => {
  if(values.firstTimeEdit){
    document.getElementById('addressCancel').style.display="flex";
    document.getElementById('addressSave').style.display="flex";
    document.getElementById('addressCancel').style.justifyContent="center";
    document.getElementById('addressSave').style.justifyContent="center";
    document.getElementById('addressPen').style.display="none";
    document.getElementById('addressPenBlue').style.display="flex";
      //enable
    document.getElementById('inputCountry').removeAttribute("disabled");
    document.getElementById('inputCity').removeAttribute("disabled");
    document.getElementById('proNo').removeAttribute("disabled");
    document.getElementById('Address').removeAttribute("disabled");
    document.getElementById('ZipCode').removeAttribute("disabled");
   }
}
const handeleducationalVisible =(e) => {
  if(values.firstTimeEdit){
    document.getElementById('educationalCancel').style.display="flex";
    document.getElementById('educationalSave').style.display="flex";
    document.getElementById('educationalCancel').style.justifyContent="center";
    document.getElementById('educationalSave').style.justifyContent="center";
    document.getElementById('educationalPen').style.display="none";
    document.getElementById('educationalPenBlue').style.display="flex";
      //enable
    document.getElementById('FieldOfStudy').removeAttribute("disabled");
    document.getElementById('Level').removeAttribute("disabled");
    document.getElementById('University').removeAttribute("disabled");
   }
}
const handeleducationalBlueVisible =(e) => {
  if(values.firstTimeEdit){
    // document.getElementById('addressalCancel').style.display="flex";
    // document.getElementById('personalSave').style.display="flex";
    // document.getElementById('personPen').style.display="none";
    // document.getElementById('personPenBlue').style.display="flex";
  }
  }
const handeladdressBlueVisible =(e) => {
if(values.firstTimeEdit){
  // document.getElementById('addressalCancel').style.display="flex";
  // document.getElementById('personalSave').style.display="flex";
  // document.getElementById('personPen').style.display="none";
  // document.getElementById('personPenBlue').style.display="flex";
}
}
const handelJobVisible =(e) => {
  if(values.firstTimeEdit){
    document.getElementById('JobCancel').style.display="flex";
    document.getElementById('JobSave').style.display="flex";
    document.getElementById('JobCancel').style.justifyContent="center";
    document.getElementById('JobSave').style.justifyContent="center";
    document.getElementById('JobPen').style.display="none";
    document.getElementById('JobPenBlue').style.display="flex";
      //enable
    document.getElementById('JobCity').removeAttribute("disabled");
    document.getElementById('JobCountry').removeAttribute("disabled");
    document.getElementById('Jobspecialty').removeAttribute("disabled");
    document.getElementById('JobUpload').removeAttribute("disabled");
    if(localStorage.getItem('userType')==1)
    document.getElementById('iccrc').removeAttribute("disabled");
    document.getElementById('website').removeAttribute("disabled");
    document.getElementById('Specialization').removeAttribute("disabled");
    document.getElementById('Certificate').removeAttribute("disabled");
   }
}
const handelJobBlueVisible =(e) => {
  if(values.firstTimeEdit){
    // document.getElementById('addressalCancel').style.display="flex";
    // document.getElementById('personalSave').style.display="flex";
    // document.getElementById('personPen').style.display="none";
    // document.getElementById('personPenBlue').style.display="flex";
  }
  }
//---------------------------------------------------------
const handleinputproAboutBlur = (e) =>{
  if(!(document.getElementById('inputproAbout').value=="")){
   document.getElementById('errorLabelproAbout').style.display="flex";
   document.getElementById('MyproAboutplaceholder').style.color="transparent";
   document.getElementById('MyproAboutSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelproAbout').style.display="none";
   document.getElementById('MyproAboutplaceholder').style.color="gray";
   document.getElementById('MyproAboutSapn').style.color="red";
  }
}
const handleinputAddressBlur = (e) =>{
  if(!(document.getElementById('Address').value=="")){
   document.getElementById('errorLabelAddress').style.display="flex";
   document.getElementById('MyAddressplaceholder').style.color="transparent";
   document.getElementById('MyAddressSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelAddress').style.display="none";
   document.getElementById('MyAddressplaceholder').style.color="gray";
   document.getElementById('MyAddressSapn').style.color="red";
  }
}
const handleinputZipCodeBlur = (e) =>{
  if(!(document.getElementById('ZipCode').value=="")){
   document.getElementById('errorLabelZipCode').style.display="flex";
   document.getElementById('MyZipCodeplaceholder').style.color="transparent";
   document.getElementById('MyZipCodeSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelZipCode').style.display="none";
   document.getElementById('MyZipCodeplaceholder').style.color="gray";
   document.getElementById('MyZipCodeSapn').style.color="red";
  }
}
const handleinputproNoBlur = (e) =>{
  if(!(document.getElementById('proNo').value=="")){
   document.getElementById('errorLabelproNo').style.display="flex";
   document.getElementById('MyproNoplaceholder').style.color="transparent";
   document.getElementById('MyproNoSapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelproNo').style.display="none";
   document.getElementById('MyproNoplaceholder').style.color="gray";
   document.getElementById('MyproNoSapn').style.color="red";
  }
}
const handleinputLevelBlur = (e) =>{
  if(!(document.getElementById('Level').value=="")){
  //  document.getElementById('errorLabelLevel').style.display="flex";
   document.getElementById('MyLevelplaceholder').style.color="transparent";
   document.getElementById('MyLevelSapn').style.color="transparent";
  }
  else{
  //  document.getElementById('errorLabelLevel').style.display="none";
   document.getElementById('MyLevelplaceholder').style.color="gray";
   document.getElementById('MyLevelSapn').style.color="red";
  }
}
const handleinputFieldOfStudyBlur = (e) =>{
  if(!(document.getElementById('FieldOfStudy').value=="")){
  document.getElementById('errorLabelFieldOfStudy').style.display="flex";
   document.getElementById('MyFieldOfStudyplaceholder').style.color="transparent";
   document.getElementById('MyFieldOfStudySapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelFieldOfStudy').style.display="none";
   document.getElementById('MyFieldOfStudyplaceholder').style.color="gray";
   document.getElementById('MyFieldOfStudySapn').style.color="red";
  }
}
const handleinputUniversityBlur = (e) =>{
  if(!(document.getElementById('University').value=="")){
  document.getElementById('errorLabelUniversity').style.display="flex";
   document.getElementById('MyUniversityplaceholder').style.color="transparent";
   document.getElementById('MyUniversitySapn').style.color="transparent";
  }
  else{
   document.getElementById('errorLabelUniversity').style.display="none";
   document.getElementById('MyUniversityplaceholder').style.color="gray";
   document.getElementById('MyUniversitySapn').style.color="red";
  }
}
const handleinputCountryBlur = (e) =>{
  if(!(document.getElementById('inputCountry').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('MyCountryplaceholder').style.color="transparent";
   document.getElementById('MyCountrySapn').style.color="transparent";
  }
  else{
   // document.getElementById('errorLabelCountry').style.display="none";
   document.getElementById('MyCountryplaceholder').style.color="gray";
   document.getElementById('MyCountrySapn').style.color="red";
  }
}
const handleinputCityBlur = (e) =>{
 if(!(document.getElementById('inputCity').value=="")){
 //  document.getElementById('errorLabelDegree').style.display="flex";
  document.getElementById('MyCityplaceholder').style.color="transparent";
  document.getElementById('MyCitySapn').style.color="transparent";
 }
 else{
 //  document.getElementById('errorLabelDegree').style.display="none";
  document.getElementById('MyCityplaceholder').style.color="gray";
  document.getElementById('MyCitySapn').style.color="red";
 }
}
const handleJobCountryBlur = (e) =>{
  if(!(document.getElementById('JobCountry').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('MyJobCountryplaceholder').style.color="transparent";
   document.getElementById('MyJobCountrySapn').style.color="transparent";
  }
  else{
   // document.getElementById('errorLabelJobCountry').style.display="none";
   document.getElementById('MyJobCountryplaceholder').style.color="gray";
   document.getElementById('MyJobCountrySapn').style.color="red";
  }
}
const handleJobCityBlur = (e) =>{
  if(!(document.getElementById('JobCity').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('MyJobCityplaceholder').style.color="transparent";
   document.getElementById('MyJobCitySapn').style.color="transparent";
  }
  else{
   // document.getElementById('errorLabelJobCity').style.display="none";
   document.getElementById('MyJobCityplaceholder').style.color="gray";
   document.getElementById('MyJobCitySapn').style.color="red";
  }
}
const handleinputJobspecialtyBlur = (e) =>{
  if(!(document.getElementById('Jobspecialty').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('MyJobspecialtyplaceholder').style.color="transparent";
   document.getElementById('MyJobspecialtySapn').style.color="transparent";
  }
  else{
   // document.getElementById('errorLabelJobspecialty').style.display="none";
   document.getElementById('MyJobspecialtyplaceholder').style.color="gray";
   document.getElementById('MyJobspecialtySapn').style.color="red";
  }
}
const handleJobUploadBlur = (e) =>{
  if(!(document.getElementById('JobUpload').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('MyJobUploadplaceholder').style.color="#313131";
   document.getElementById('MyJobUploadSapn').style.color="transparent";
   document.getElementById('JobUpload').style.color ="transparent";
  }
  else{
   // document.getElementById('errorLabelJobUpload').style.display="none";
   document.getElementById('MyJobUploadplaceholder').style.color="gray";
   document.getElementById('MyJobUploadSapn').style.color="red";
  }
}
const handleinputiccrcBlur = (e) =>{
  if(!(document.getElementById('iccrc').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('Myiccrcplaceholder').style.color="transparent";
   document.getElementById('MyiccrcSapn').style.color="transparent";
  }
  else{
   // document.getElementById('errorLabeliccrc').style.display="none";
   document.getElementById('Myiccrcplaceholder').style.color="gray";
   document.getElementById('MyiccrcSapn').style.color="red";
  }
}
const handleinputwebsiteBlur = (e) =>{
  if(!(document.getElementById('website').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('Mywebsiteplaceholder').style.color="transparent";
   document.getElementById('MywebsiteSapn').style.color="transparent";
  }
  else{
   // document.getElementById('errorLabelwebsite').style.display="none";
   document.getElementById('Mywebsiteplaceholder').style.color="gray";
   document.getElementById('MywebsiteSapn').style.color="red";
  }
}
const handleinputSpecializationBlur = (e) =>{
  if(!(document.getElementById('Specialization').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('MySpecializationplaceholder').style.color="transparent";
   document.getElementById('MySpecializationSapn').style.color="transparent";
  }
  else{
   // document.getElementById('errorLabelSpecialization').style.display="none";
   document.getElementById('MySpecializationplaceholder').style.color="gray";
   document.getElementById('MySpecializationSapn').style.color="red";
  }
}
const handleinputCertificateBlur = (e) =>{
  if(!(document.getElementById('Certificate').value=="")){
   // document.getElementById('errorLabelCountry').style.display="flex";
   document.getElementById('MyCertificateplaceholder').style.color="transparent";
   document.getElementById('MyCertificateSapn').style.color="transparent";
  }
  else{
   // document.getElementById('errorLabelCertificate').style.display="none";
   document.getElementById('MyCertificateplaceholder').style.color="gray";
   document.getElementById('MyCertificateSapn').style.color="red";
  }
}

const [Dvalue, setDValue] = useState(new Date())
// console.log(Dvalue.format("dddd DD MMMM YYYY"));
const [isOpen, setIsOpen] = useState(false);
const togglePopup = () => {
  setIsOpen(!isOpen);
  //console.log(isOpen);
} 

const handleSendEmail = () =>{
  setIsOpen(!isOpen);
}

const [isOpen2, setIsOpen2] = useState(false);
const togglePopup2 = () => {
  setIsOpen2(!isOpen2);
  //console.log(isOpen2);
} 

const handlePopPersonslInfo = () =>{
  setIsOpen2(!isOpen2);
}

const [isOpen3, setIsOpen3] = useState(false);
const togglePopup3 = () => {
  setIsOpen3(!isOpen3);
  //console.log(isOpen3);
} 

const handlePopAddressInfo = () =>{
  setIsOpen3(!isOpen3);
}

const [isOpen4, setIsOpen4] = useState(false);
const togglePopup4 = () => {
  setIsOpen4(!isOpen4);
  //console.log(isOpen4);
} 

const handlePopEducationalInfo = () =>{
  setIsOpen4(!isOpen4);
}

const [isOpen5, setIsOpen5] = useState(false);
const togglePopup5 = () => {
  setIsOpen5(!isOpen5);
  //console.log(isOpen5);
} 

const handlePopJobInfo = () =>{
  setIsOpen5(!isOpen5);
}

console.log(values.firstTimeEdit);
console.log(values.firstTimeEditAddress);
console.log(values.firstTimeEditEducational);
console.log(values.firstTimeEditJob);
console.log(values.Address);



const handleScrollUp = () =>{
 window.scroll({ behavior: 'smooth' }, 0)
}
const [selectedFile,setSelectedFile] = useState(null);
const uploadImage = (files) => {
console.log(files[0]);
setSelectedFile(files[0]);
const formData = new FormData();
formData.append('UserAvatar',files[0]);
formData.append('Email',localStorage.getItem('email'));
const headers = { 
  'Authorization': 'Bearer my-token',
  'My-Custom-Header': 'foobar'
};
if(localStorage.getItem('userType')==2)
if(files[0].size > 2000000){
  alert("File is too big!");
}else
axios.post('https://telesiness.ir/api/AgentProfile/AgentUploadAvatar',formData,{
  onUploadProgress:progressEvent=> {console.log(progressEvent.loaded/progressEvent.total);}}) .then(res => {
    
     localStorage.setItem('userAvatar',res.data.userAvatar);

    forceUpdate();
  })
  else if(localStorage.getItem('userType')==1)
  if(files[0].size > 2000000){
    alert("File is too big!");
  }else
axios.post('https://telesiness.ir/api/LawyerProfile/LawyerUploadAvatar',formData,{
  onUploadProgress:progressEvent=> {console.log(progressEvent.loaded/progressEvent.total);}}) .then(res => {
    
     localStorage.setItem('userAvatar',res.data.userAvatar);

    forceUpdate();
  })
  else if(localStorage.getItem('userType')==3)
  if(files[0].size > 2000000){
    alert("File is too big!");
  }else
  axios.post('https://telesiness.ir/api/CustomerProfile/CustomerUploadAvatar',formData,{
    onUploadProgress:progressEvent=> {console.log(progressEvent.loaded/progressEvent.total);}}) .then(res => {
      
       localStorage.setItem('userAvatar',res.data.userAvatar);

      forceUpdate();
    })
    else if(localStorage.getItem('userType')==4)
    if(files[0].size > 2000000){
      alert("File is too big!");
    }else
    axios.post('https://telesiness.ir/api/MarketerProfile/MarketerUploadAvatar',formData,{
      onUploadProgress:progressEvent=> {console.log(progressEvent.loaded/progressEvent.total);}}) .then(res => {
        
         localStorage.setItem('userAvatar',res.data.userAvatar);

        forceUpdate();
      })

  
// fetch('https://telesiness.ir/api/AgentProfile/AgentUploadAvatar' , {
//   method: 'POST',
//   headers: { 'Content-Type': 'application/json' },
//   body: JSON.stringify({ 
//     Email: localStorage.getItem('email') ,
//     ProfilePictureName:files[0].name ,
//     ProfilePictureFile:files[0] ,


// })
// }).then().then(history.push('/Dashboard'));
// console.log(files[0]);
}

const [data, setData] = useState([]);

const fetchData = () => {
  fetch("https://telesiness.ir/api/Countries/AllCountery")
    .then((res) => res.json())
    .then((result) => setData(result))
    .catch((err) => console.log("error"));
    
};

useEffect(() => {
  fetchData();
  // console.log(data[0].countery_Name);
}, []);



///////////////////////////////////
const [selectedFile2,setSelectedFile2] = useState(null);
const uploadImage2 = (files) => {
console.log(files[0]);
setSelectedFile2(files[0]);
const formData = new FormData();
formData.append('AttachmentFile',files[0]);
formData.append('Email',localStorage.getItem('email'));
const headers = { 
  'Authorization': 'Bearer my-token',
  'My-Custom-Header': 'foobar'
};

if(localStorage.getItem('userType')==1)
if(files[0].size > 2000000){
  // document.getElementById('files2').innerHTML =  '<b>' + 'File Must be Lower than 4MB'+ '</b> ';
  values.files2 = 1;
  forceUpdate();
}else
  axios.post('https://telesiness.com/api/LawyerProfile/LawyerUploadCV',formData,{
    onUploadProgress:progressEvent=> {console.log(progressEvent.loaded/progressEvent.total);}}) .then(res => {
      
       localStorage.setItem('userAvatar',res.data.attachmentFile);
       console.log(res.data.attachmentFile,res.data,res);

       if(localStorage.getItem('userType')==1)
       {
         var name = files[0]; 
         var totalFileSize = 0;
       
           //ACCESS THE SIZE PROPERTY OF THE ITEM OBJECT IN FILES COLLECTION. IN THIS WAY ALSO GET OTHER PROPERTIES LIKE FILENAME AND FILETYPE
           var fsize = name.size;
           totalFileSize = totalFileSize + fsize;
           document.getElementById('files2').innerHTML =
           document.getElementById('files2').innerHTML
           +
           '<b>' + name.name
           +
            '</b> ' + 'and Size is <b>' + Math.round((fsize / 1024)) //DEFAULT SIZE IS IN BYTES SO WE DIVIDING BY 1024 TO CONVERT IT IN KB
            +
            '</b> KB and File Type is <b>' + name.type + "</b>.";
            values.files2 = 0;
       
       }
      forceUpdate();
    })


if(localStorage.getItem('userType')==2)
if(files[0].size > 2000000){
  // document.getElementById('files2').innerHTML =  '<b>' + 'File Must be Lower than 4MB'+ '</b> ';
  values.files2 = 1;
  forceUpdate();
}else
  axios.post('https://telesiness.com/api/AgentProfile/DistributorUploadCV',formData,{
    onUploadProgress:progressEvent=> {console.log(progressEvent.loaded/progressEvent.total);}}) .then(res => {
      
       localStorage.setItem('userAvatar',res.data.attachmentFile);
       console.log(res.data.attachmentFile,res.data,res);

       if(localStorage.getItem('userType')==2)
       {
         var name = files[0]; 
         var totalFileSize = 0;
       
           //ACCESS THE SIZE PROPERTY OF THE ITEM OBJECT IN FILES COLLECTION. IN THIS WAY ALSO GET OTHER PROPERTIES LIKE FILENAME AND FILETYPE
           var fsize = name.size;
           totalFileSize = totalFileSize + fsize;
           document.getElementById('files2').innerHTML =
           document.getElementById('files2').innerHTML
           +
           '<b>' + name.name
           +
            '</b> ' + 'and Size is <b>' + Math.round((fsize / 1024)) //DEFAULT SIZE IS IN BYTES SO WE DIVIDING BY 1024 TO CONVERT IT IN KB
            +
            '</b> KB and File Type is <b>' + name.type + "</b>.";
            values.files2 = 0;
       
       }
      forceUpdate();
    })

}
///////////////////////////////////////
//-------------------------------

return ( 
         
  <div className="mainRowPro MsgMargin col-12">
    <form onSubmit={handleSubmitPersonal} className="fprm_profile">
      <div className="personalInfoRowOne col-12">
        <div className="proBox mob_proBox col-12">
          
            <div className="brownBox col-12">
              <div className="img_brownBox">
                <div className="imgup_brownBox">
                  {<Edit2 htmlFor="imgupload" id="personPicPen" size="16" color="#313131"/>} 
                  <input
                  id="imgupload"
                      type="file"
                      onChange={(event) => {
                        uploadImage(event.target.files);
                      }}
      />
                </div>
                {localStorage.getItem('userAvatar')=='UserAvatar.png' || localStorage.getItem('userAvatar')==''  &&<img src={proFilePic} alt="Avatar" />}
                {localStorage.getItem('userType')==2 &&<img src={'https://telesiness.ir/AgentsAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==1 &&<img src={'https://telesiness.ir/LawyersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==3 &&<img src={'https://telesiness.ir/CustomersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==4 &&<img src={'https://telesiness.ir/MarketersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
              </div>
            </div>

            <label className="proBoxLabel"> {localStorage.getItem('firstName') + ' ' +
              localStorage.getItem('lastName')}</label>
          {localStorage.getItem('userType')==1 && <label className="proBoxLabelDis" disabled> {localStorage.getItem('lawyerCode')}</label>}
          {localStorage.getItem('userType')==2 && <label className="proBoxLabelDis" disabled> {localStorage.getItem('agentCode')}</label>}
          {localStorage.getItem('userType')==3 && <label className="proBoxLabelDis" disabled> {localStorage.getItem('customerCode')}</label>}
          {localStorage.getItem('userType')==4 && <label className="proBoxLabelDis" disabled> {localStorage.getItem('marketerCode')}</label>}
            <label className="proBoxLabelDis" disabled> {localStorage.getItem('registerDate').split('T',1)}</label>
            <div className="ProSocial">
                <div className="ShackeShapeSocial"><Facebook className='SocialIcon404' size="28" color="#000" variant="Bold"/></div>
                <div className="ShackeShapeSocial"><Instagram className='SocialIcon404' size="28" color="#000" variant="Bold"/></div>
                <div className="ShackeShapeSocial"><LinkedInIcon  sx={{ fontSize: 30 , color: grey[900] }}/></div>
                <div className="ShackeShapeSocial"><TwitterIcon  sx={{ fontSize: 30 , color: grey[900] }}/></div>
            </div>
        </div>

        <div className="penAndPerson">
          <h4>{t('Personal Information')}</h4>
          <div className="pen">
          {values.firstTimeEdit!='true'  && <Edit2 onClick={handelPersonalVisible} id="personPen" size="16" color="#313131"/>} 
            <Edit2 onClick={handelPersonalBlueVisible} id="personPenBlue" size="16" color="#2727ff"/>
          </div>
        </div>
        {/* {values.firstTimeEdit!='true'  && 
        <div className="redAttention">
          <InfoCircle id="personPen" size="16" color="#ff2727"/>
          <p>{t('Lorem ipsum dolor sit amet, consectetuer adipiscing elit')}</p>
        </div>} */}
        {values.firstTimeEdit!='false'  && 
        <div className="greyAttention">
          <InfoCircle id="personPen" size="16" color="#313131"/>
          <p>{t('You Changed these fields before ... ')}</p>
        </div>}
      </div>
      <div className="personalInfoRowTwo col-12">
        <div className="rowTwoColOne col-lg-8 col-12">
            <div className="input-placeholder col-lg-6 col-12">
              <label htmlFor="proFirstName">{t('First Name')}</label>
              <input 
                type="text"
                className="form-control"
                id="proFirstName"
                disabled 
                value={values.proFirstName}
                onChange={handleChange('proFirstName')}  
                onBlur={handleinputproFirstNameBlur} 
                required 
                />
              <div id="MyproFirstNameplaceholder" className="placeholderPro">
              {!values.proFirstName && t('First Name')}
              <span id="MyproFirstNameSapn"></span>
              </div>
              <label id="errorLabelproFirstName"></label>
            </div>
            <div className="input-placeholder col-lg-6 col-12">
            <label htmlFor="proLastName">{t('Last Name')}</label>
            <input 
              type="text"
              className="form-control"
              id="proLastName"
              disabled 
              value={values.proLastName}
              onChange={handleChange('proLastName')}  
              onBlur={handleinputproLastNameBlur}  
              required
              />
            <div id="MyproLastNameplaceholder" className="placeholderPro">
            {!values.proLastName && t('Last Name')}
            <span id="MyproLastNameSapn"></span>
            </div>
            <label id="errorLabelproLastName"></label>
            </div>
            <div className="input-placeholder col-lg-6 col-12">
              <label htmlFor="Gender">{t('Gender')}</label>
              <select
                    className="form-select "
                    aria-label="Default"
                    disabled
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="Gender"
                    value={values.Gender}
                    label={t('Gender')}
                    // MenuProps={MenuProps}
                    onChange={handleChange('Gender')}  
                    onBlur={handleinputGenderBlur}  
                    required 
                    placeholder={t('Gender')}
                    >
                    <option  value="">
                    </option >
                    <option  value='1'>{t('Male')}</option >
                    <option  value='2'>{t('Female')}</option >
                </select >
                <div id="MyGenderplaceholder" className="placeholderPro">
                <span id="MyGenderSapn"></span>
              </div>
            </div>
            <div className="input-placeholder col-lg-6 col-12">
          <label htmlFor="birthDate">{t('Birthday')}</label>
          { (document.body.getAttribute('Lang')== 'fa') && !myCalaedar && <DatePicker 
            
            value={Dvalue}
            format="YYYY/MM/DD "
            
            calendar={persian}
            locale={persian_fa}
            id="birthDate"
            onBlur={handleinputbirthDateBlur}
            onChange={setDValue} 
            disabled
          
          />}
          { (document.body.getAttribute('Lang')== 'fa') && myCalaedar && <DatePicker 
            
            value={Dvalue}
            format="YYYY/MM/DD "
            
            calendar={persian}
            locale={persian_fa}
            id="birthDate"
            onBlur={handleinputbirthDateBlur}
            onChange={setDValue} 
            
          
          />}
          { (document.body.getAttribute('Lang')== 'en') && !myCalaedar && <DatePicker 
            
            value={Dvalue}
            format="YYYY/MM/DD "
            
            calendar={gregorian}
            locale={gregorian_en}
            id="birthDate"
            onBlur={handleinputbirthDateBlur}
            onChange={setDValue} 
            disabled
          
          />}
          { (document.body.getAttribute('Lang')== 'en') && myCalaedar && <DatePicker 
            
            value={Dvalue}
            format="YYYY/MM/DD "
            
            calendar={gregorian}
            locale={gregorian_en}
            id="birthDate"
            onBlur={handleinputbirthDateBlur}
            onChange={setDValue} 
            
          
          />}
              <div id="MybirthDateplaceholder" className="placeholderPro">
              {}<span id="MybirthDateSapn"></span>
              </div>
              <label id="errorLabelbirthDate">{}</label>
            </div>
            <div className="input-placeholder col-lg-6 col-12">
                <label htmlFor="proEmail">{t('Email')}</label>
                <input 
                  type="text"
                  className="form-control"
                  id="proEmail"
                  required 
                  disabled
                  value={values.proEmail}
                  />
                <div id="MyproEmailplaceholder" className="placeholderProEmail">
                {!values.proEmail && t('Email')}
                <span id="MyproEmailSapn"></span>
                </div>
                <label id="errorLabelproEmail"></label>
            </div>
            <div className="input-placeholder col-lg-6 col-12">
              <label htmlFor="proPhone">{t('Phone')}</label>
              <input 
                type="text"
                className="form-control"
                id="proPhone"
                required 
                disabled
                value={values.proPhone}
                />
              <div id="MyproPhonelplaceholder" className="placeholderProEmail">
              {t('Phone')}
              <span id="MyproPhoneSapn"></span>
              </div>
              <label id="errorLabelproPhone"></label>
            </div>
            <div className="input-placeholder col-12">
          <label htmlFor="inputproAbout">{t('About')}</label>
            <textarea 
              value = {values.proAbout}
              type="text"
              className="form-control"
              id="inputproAbout"
              onBlur={handleinputproAboutBlur}
              onChange={handleChange('proAbout')}
              disabled 
              // autocomplete="off"
              />
            <div id="MyproAboutplaceholder" className="placeholderPro">
            {!values.proAbout && t('About')}<span id="MyproAboutSapn"></span>
            </div>
            <label id="errorLabelproAbout">{}</label>
            </div>
      </div>
      <div className="proBox col-lg-4 col-12">
          <div className="brownBox col-12">
          <div className="img_brownBox">
          <div className="imgup_brownBox">
          {<Edit2 htmlFor="imgupload" id="personPicPen" size="16" color="#313131"/>} 
          <input
            id="imgupload"
            type="file"
            onChange={(event) => {
              uploadImage(event.target.files);
            }}
          />
          </div>
          {localStorage.getItem('userAvatar')=='UserAvatar.png' || localStorage.getItem('userAvatar')==''  &&<img src={proFilePic} alt="Avatar" />}
                {localStorage.getItem('userType')==2 &&<img src={'https://telesiness.ir/AgentsAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==1 &&<img src={'https://telesiness.ir/LawyersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==3 &&<img src={'https://telesiness.ir/CustomersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
                {localStorage.getItem('userType')==4 &&<img src={'https://telesiness.ir/MarketersAvatar/'+ localStorage.getItem('userAvatar')} alt="Avatar" />}
          </div>
          </div>
          <label className="proBoxLabel"> {localStorage.getItem('firstName') + ' ' +
              localStorage.getItem('lastName')}</label>
          {localStorage.getItem('userType')==1 && <label className="proBoxLabelDis" disabled> {localStorage.getItem('lawyerCode')}</label>}
          {localStorage.getItem('userType')==2 && <label className="proBoxLabelDis" disabled> {localStorage.getItem('agentCode')}</label>}
          {localStorage.getItem('userType')==3 && <label className="proBoxLabelDis" disabled> {localStorage.getItem('customerCode')}</label>}
          {localStorage.getItem('userType')==4 && <label className="proBoxLabelDis" disabled> {localStorage.getItem('marketerCode')}</label>}
            <label className="proBoxLabelDis" disabled> {localStorage.getItem('registerDate').split('T',1)}</label>
            <div className="ProSocial">
                <div className="ShackeShapeSocial"><Facebook className='SocialIcon404' size="32" color="#000" variant="Bold"/></div>
                <div className="ShackeShapeSocial"><Instagram className='SocialIcon404' size="32" color="#000" variant="Bold"/></div>
                <div className="ShackeShapeSocial"><LinkedInIcon  sx={{ fontSize: 36 , color: grey[900] }}/></div>
                <div className="ShackeShapeSocial"><TwitterIcon  sx={{ fontSize: 36 , color: grey[900] }}/></div>
            </div>
      </div>
      </div>
      <div className="personalInfoRowFour col-12">
        <Link onClick={handleCancelPersonal} to="/Dashboard" id="personalCancel" className="btn col-2 btn-outline-light signup-btn">{t('Cancel')}</Link>
        {!ispending && !formError && <button id="personalSave" className="btn btn-dark login-btn col-2">{t('Save')}</button>}
        {formError && <button  className="btn btn-dark login-btn col-2" id="personalSave" disabled>{t('Save')}</button>}
        {ispending && <button className="btn btn-dark login-btn col-2" id="personalSave" disabled>{t('Being login...')}</button>}
      </div>
    </form>
    
    {localStorage.getItem('userType')!='2' && <form onSubmit={handleSubmitaddress} className="fprm_profile">
      <div className="personalInfoRowOne col-12">
        <div className="penAndPerson">
          <h4>{t('Address Information')}</h4>
          <div className="pen">
            {values.firstTimeEditAddress!="true"  &&<Edit2 onClick={handeladdressVisible} id="addressPen" size="16" color="#313131"/>} 
            <Edit2 onClick={handeladdressBlueVisible} id="addressPenBlue" size="16" color="#2727ff"/>
          </div>
        </div>
        {/* {values.firstTimeEditAddress!="true" && 
        <div className="redAttention">
          <InfoCircle id="personPen" size="16" color="#ff2727"/>
          <p>{t('Lorem ipsum dolor sit amet, consectetuer adipiscing elit')}</p>
        </div>} */}
        {values.firstTimeEditAddress!="false" && 
        <div className="greyAttention">
          <InfoCircle id="personPen" size="16" color="#313131"/>
          <p>{t('You Changed these fields before ... ')}</p>
        </div>}
      </div>
      <div className="personalInfoRowTwo col-12">
        <div className="AddressColLeft col-lg-4 col-12">
          <div className="input-placeholder col-lg-6 col-12">
          <label htmlFor="inputCountry">{t('Country')}</label>
            <select
                  required
                  className="form-select "
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="inputCountry"
                  value={values.Country}
                  label={t('Country')}
                  disabled
                  // MenuProps={MenuProps}
                  onChange={handleChange('Country')}  
                  onBlur={handleinputCountryBlur}   
                  placeholder={t('Country')}
                  >
                  <option  value="">
                  </option >
                  <option  value='1'>{t('Iran')}</option >
                  <option  value='2'>{t('England')}</option >
                  <option  value='3'>{t('Turkey')}</option >
                  <option  value='4'>{t('UAE')}</option >
                  <option  value='5'>{t('USA')}</option >
              </select >
              <div id="MyCountryplaceholder" className="placeholderPro">
                <span id="MyCountrySapn"></span>
            </div>
          </div>
          <div className="input-placeholder col-lg-6 col-12">
            <label htmlFor="inputCity">{t('City')}</label>
            <input 
              type="text"
              required
              className="form-control "
              aria-label="Default"
              // labelId="demo-simple-select-Degree-helper-label"
              id="inputCity"
              value={values.City}
              label={t('City')}
              disabled
              // MenuProps={MenuProps}
              onChange={handleChange('City')}  
              onBlur={handleinputCityBlur}   
              placeholder={t('City')}
              />
              <div id="MyCityplaceholder" className="placeholderPro">
                <span id="MyCitySapn"></span>
            </div>
          </div>
        </div>
        <div className="input-placeholder col-lg-8 col-12">
        <label htmlFor="Address">{t('Address')}</label>
        <input 
          type="text"
          className="form-control"
          id="Address"
          disabled 
          required
          value={values.Address}
          onChange={handleChange('Address')}  
          onBlur={handleinputAddressBlur}  
          />
        <div id="MyAddressplaceholder" className="placeholderPro">
        {!values.Address && t('Address')}
        <span id="MyAddressSapn"></span>
        </div>
        <label id="errorLabelAddress"></label>
        </div>
        <div className="input-placeholder col-lg-4 col-12">
          <label htmlFor="proNo">{t('No')}</label>
          <input 
            type="text"
            className="form-control"
            id="proNo"
            required 
            disabled
            onChange={handleChange('proNo')}  
            onBlur={handleinputproNoBlur}  
            value={values.proNo}
            />
          <div id="MyproNoplaceholder" className="placeholderPro">
          {!values.proNo && t('No')}
          <span id="MyproNoSapn"></span>
          </div>
          <label id="errorLabelproNo"></label>
        </div>
        <div className="input-placeholder col-lg-4 col-12">
          <label htmlFor="ZipCode">{t('Zip Code')}</label>
          <input 
            type="text"
            className="form-control"
            id="ZipCode"
            required 
            onChange={handleChange('ZipCode')}  
            onBlur={handleinputZipCodeBlur}  
            value={values.ZipCode}
            disabled
            />
          <div id="MyZipCodeplaceholder" className="placeholderProEmail">
          {!values.ZipCode && t('Zip Code')}
          <span id="MyZipCodeSapn"></span>
          </div>
          <label id="errorLabelZipCode"></label>
      </div>
      </div>
      <div className="personalInfoRowFour col-12">
        <Link onClick={handleCanceladdress} to="/Dashboard" id="addressCancel" className="btn col-2 btn-outline-light signup-btn">{t('Cancel')}</Link>
        {!ispending && !formError && <button id="addressSave" className="btn btn-dark login-btn col-2">{t('Save')}</button>}
        {formError && <button  className="btn btn-dark login-btn col-2" id="addressSave" disabled>{t('Save')}</button>}
        {ispending && <button className="btn btn-dark login-btn col-2" id="addressSave" disabled>{t('Being login...')}</button>}
      </div>
    </form>}
    

    <form onSubmit={handleSubmiteducational} className="fprm_profile">
      <div className="educationalInfo col-12">
          <div className="personalInfoRowOne col-12">
            <div className="penAndPerson">
            <h4>{t('Educational Information')}</h4>
              <div className="pen">
              {values.firstTimeEditEducational!="true"&&<Edit2 onClick={handeleducationalVisible} id="educationalPen" size="16" color="#313131"/>} 
                <Edit2 onClick={handeleducationalBlueVisible} id="educationalPenBlue" size="16" color="#2727ff"/>
              </div>
            </div>
            {/* {values.firstTimeEditEducational!="true"&&
            <div className="redAttention">
              <InfoCircle id="personPen" size="16" color="#ff2727"/>
              <p>{t('Lorem ipsum dolor sit amet, consectetuer adipiscing elit')}</p>
            </div>} */}
            {values.firstTimeEditEducational!="false"&&
            <div className="greyAttention">
              <InfoCircle id="personPen" size="16" color="#313131"/>
              <p>{t('You Changed these fields before ... ')}</p>
            </div>}
          </div>
          <div className="personalInfoRowTwo col-12">
            <div className="input-placeholder col-lg-4 col-12">
                <label htmlFor="FieldOfStudy">{t('Field of Study')}</label>
                <input 
                  type="text"
                  className="form-control"
                  id="FieldOfStudy"
                  disabled 
                  value={values.FieldOfStudy}
                  onChange={handleChange('FieldOfStudy')}  
                  onBlur={handleinputFieldOfStudyBlur}  
                  />
                <div id="MyFieldOfStudyplaceholder" className="placeholderPro">
                {!values.FieldOfStudy && t('Field of Study')}
                <span id="MyFieldOfStudySapn"></span>
                </div>
                <label id="errorLabelFieldOfStudy"></label>
            </div>
            <div className="input-placeholder col-lg-4 col-12">
                  <label htmlFor="University">{t('Institute/University')}</label>
                  <input 
                    type="text"
                    className="form-control"
                    id="University"
                    required 
                    disabled
                    value={values.University}
                    onChange={handleChange('University')}  
                    onBlur={handleinputUniversityBlur}  
                    />
                  <div id="MyUniversityplaceholder" className="placeholderPro">
                  {!values.University && t('Institute/University...')}
                  <span id="MyUniversitySapn"></span>
                  </div>
                  <label id="errorLabelUniversity"></label>
            </div>
            <div className="input-placeholder col-lg-4 col-12">
                      <label className="Level" htmlFor="inputLevel">{t('Level/Degree')}</label>
                        <select
                          required
                          className="form-select"
                          aria-label="Default"
                          // labelId="demo-simple-select-Degree-helper-label"
                          id="Level"
                          value={values.Level}
                          label={t('Level/Degree')}
                          // MenuProps={MenuProps}
                          onChange={handleChange('Level')}  
                          onBlur={handleinputLevelBlur}   
                          disabled
                          placeholder={t('Level/Degree')}
                          >
                          <option  value="">
                          </option >
                          <option  value='1'>{t('Less than a high school diploma')}</option >
                          <option  value='2'>{t('High school diploma')}</option >
                          <option  value='3'>{t('Some college, no degree')}</option >
                          <option  value='4'>{t('Associate degree')}</option >
                          <option  value='5'>{t('Bachelor degree')}</option >
                          <option  value='6'>{t('Master Degree')}</option >
                          <option  value='7'>{t('Profissional degree')}</option >
                          <option  value='8'>{t('Doctoral degree')}</option >
                        </select >
                          <div id="MyLevelplaceholder" className="placeholderPro">
                           <span id="MyLevelSapn"></span>
                        </div>
            </div>
          </div>
          <div className="personalInfoRowFour col-12">
            <Link onClick={handleCanceleducational} to="/Dashboard" id="educationalCancel" className="btn col-2 btn-outline-light signup-btn">{t('Cancel')}</Link>
            {!ispending && !formError && <button id="educationalSave" className="btn btn-dark login-btn col-2">{t('Save')}</button>}
            {formError && <button  className="btn btn-dark login-btn col-2" id="educationalSave" disabled>{t('Save')}</button>}
            {ispending && <button className="btn btn-dark login-btn col-2" id="educationalSave" disabled>{t('Being login...')}</button>}
          </div>
      </div>
    </form>

   
    {(localStorage.getItem('userType')!='3' && localStorage.getItem('userType')!='4') &&<form onSubmit={handleSubmitJob} className="fprm_profile">
      <div className="personalInfoRowOne col-12">
        <div className="penAndPerson">
        <h4>{t('Job Information')}</h4>
          <div className="pen">
          {values.firstTimeEditJob!='true' && <Edit2 onClick={handelJobVisible} id="JobPen" size="16" color="#313131"/>} 
            <Edit2 onClick={handelJobBlueVisible} id="JobPenBlue" size="16" color="#2727ff"/>
          </div>
        </div>
        {/* {values.firstTimeEditJob!="true"&&
        <div className="redAttention">
          <InfoCircle id="personPen" size="16" color="#ff2727"/>
          <p>{t('Lorem ipsum dolor sit amet, consectetuer adipiscing elit')}</p>
        </div>} */}
        {values.firstTimeEditJob!="false"&&
        <div className="greyAttention">
          <InfoCircle id="personPen" size="16" color="#313131"/>
          <p>{t('You Changed these fields before ... ')}</p>
        </div>}

      </div>
      <div className="personalInfoRowTwo col-12">
        <div className="AddressColLeft col-lg-4 col-12">
            <div className="input-placeholder col-lg-6 col-12">
            <label htmlFor="JobCountry">{t('Country')}</label>
            <select
                  required
                  className="form-select "
                  aria-label="Default"
                  // labelId="demo-simple-select-Degree-helper-label"
                  id="JobCountry"
                  value={values.JobCountry}
                  label={t('Country')}
                  // MenuProps={MenuProps}
                  onChange={handleChange('JobCountry')}  
                  disabled
                   
                  onBlur={handleJobCountryBlur}   
                  placeholder={t('Country')}
                  >
                    <option  value="">
                  </option >
                  {data &&
                    data.map((element) => (
                      <option  value={element.countery_ID}>{element.countery_Name}</option >
                  ))}
                  {/* <option  value="">
                  </option >
                  <option  value='1'>{t('Iran')}</option >
                  <option  value='2'>{t('England')}</option >
                  <option  value='3'>{t('Turkey')}</option >
                  <option  value='4'>{t('UAE')}</option >
                  <option  value='5'>{t('USA')}</option > */}
              </select >
              {/* <select
                    required
                    className="form-select "
                    aria-label="Default"
                    // labelId="demo-simple-select-Degree-helper-label"
                    id="JobCountry"
                    value={values.JobCountry}
                    label={t('Country')}
                    disabled
                    // MenuProps={MenuProps}
                    onChange={handleChange('JobCountry')}  
                    onBlur={handleJobCountryBlur}   
                    placeholder={t('Country')}
                    >
                    <option  value="">
                    </option >
                    <option  value='1'>{t('Iran')}</option >
                    <option  value='2'>{t('England')}</option >
                    <option  value='3'>{t('Turkey')}</option >
                    <option  value='4'>{t('UAE')}</option >
                    <option  value='5'>{t('USA')}</option >
                </select > */}
                <div id="MyJobCountryplaceholder" className="placeholderPro">
                  <span id="MyJobCountrySapn"></span>
              </div>
            </div>
            <div className="input-placeholder col-lg-6 col-12">
        <label htmlFor="JobCity">{t('City')}</label>
        <input 
          type="text"
          className="form-control"
          id="JobCity"
          disabled 
          value={values.JobCity}
          onChange={handleChange('JobCity')}  
          onBlur={handleJobCityBlur} 
          placeholder={t('City')} 
          />
        <div id="MyJobCityplaceholder" className="placeholderPro">
        {/* {! values.JobCity && t('City')} */}
        <span id="MyJobCitySapn"></span>
        </div>
        <label id="errorLabelJobCity"></label>
        </div>
        </div>
        <div className="input-placeholder col-lg-4 col-12">
        <label htmlFor="Jobspecialty">{t('Job specialty')}</label>
        <input 
          type="text"
          className="form-control"
          id="Jobspecialty"
          disabled 
          value={values.Jobspecialty}
          onChange={handleChange('Jobspecialty')}  
          onBlur={handleinputJobspecialtyBlur}  
          />
        <div id="MyJobspecialtyplaceholder" className="placeholderPro">
        {! values.Jobspecialty && t('Job specialty')}
        <span id="MyJobspecialtySapn"></span>
        </div>
        <label id="errorLabelJobspecialty"></label>
        </div>
        <div className="input-placeholder col-lg-4 col-12">
      <label htmlFor="Specialization">{t('Specialization in immigration')}</label>
      <input 
        type="text"
        className="form-control"
        id="Specialization"
        disabled 
        value={values.Specialization}
        onChange={handleChange('Specialization')}  
        onBlur={handleinputSpecializationBlur}  
        />
      <div id="MySpecializationplaceholder" className="placeholderPro">
      {!values.Specialization && t('Specialization in immigration')}
      <span id="MySpecializationSapn"></span>
      </div>
      <label id="errorLabelSpecialization"></label>
        </div>
        {localStorage.getItem('userType')==1 && <div className="input-placeholder col-lg-4 col-12">
        <label htmlFor="iccrc">{t('Law society number/ICCRC Code')}</label>
        <input 
          type="text"
          className="form-control"
          id="iccrc"
          disabled 
          value={values.iccrc}
          onChange={handleChange('iccrc')}  
          onBlur={handleinputiccrcBlur}  
          />
        {/* <div id="Myiccrcplaceholder" className="placeholderPro">
        {t('Law society number/ICCRC Code')}
        <span id="MyiccrcSapn"></span>
        </div> */}
        <label id="errorLabeliccrc"></label>
        </div>}
        <div className="input-placeholder col-lg-4 col-12">
        <label htmlFor="website">{t('Website Address')}</label>
        <input 
          type="text"
          className="form-control"
          id="website"
          disabled 
          value={values.website}
          onChange={handleChange('website')}  
          onBlur={handleinputwebsiteBlur}  
          />
        <div id="Mywebsiteplaceholder" className="placeholderPro">
        {!values.website && t('Website Address')}
        <span id="MywebsiteSapn"></span>
        </div>
        <label id="errorLabelwebsite"></label>
        </div>
        <div className="input-placeholder col-lg-4 col-12">
        <label htmlFor="Certificate">{t('Certificate')}</label>
        <input 
          type="text"
          className="form-control"
          id="Certificate"
          disabled 
          value={values.Certificate}
          onChange={handleChange('Certificate')}  
          onBlur={handleinputCertificateBlur}  
          placeholder={t('Certificate')}
          />
        <div id="MyCertificateplaceholder" className="placeholderPro">
        
        <span id="MyCertificateSapn"></span>
        </div>
        <label id="errorLabelCertificate"></label>
        </div>
        {localStorage.getItem('userType')!=1 &&<div className="input-placeholder col-lg-4 col-12"></div>}
        <div className="input-placeholder col-lg-4 col-12">
        <div className="imgup_brownBox2">
                {/* {<Edit2 htmlFor="imgupload" id="personPicPen" size="16" color="#313131"/>}  */}
                {<a htmlFor="imgupload" id="personPicPen" className="btn">{t('Upload Resume/CV')}</a>}
                  <input
                    id="imgupload"
                    type="file"
                    accept=".pdf"
                    size={2000000}
                    onChange={(event) => {
                      uploadImage2(event.target.files);
                    }}
                  />
              </div>
          {/* <div id="MyJobUploadplaceholder" className="placeholderProUpload">
          {t('Upload Resume/CV')}<span id="MyJobUploadSapn"></span>
          </div> */}
          <label id="errorLabelJobUpload">{helpJob}</label>
        </div>
        <div className="input-placeholder-jobUpload col-lg-4 col-12">
              <label id="files2">{localStorage.getItem('attachmentFile')!=''&&
                <p><a target="_blank" href={'https://telesiness.ir/CustomerEvaluationFormDocuments/'+ localStorage.getItem('attachmentFile')} className="UploadedFileBasicForm">{localStorage.getItem('attachmentFile')}</a></p>
                }</label>
                {/* <label id="jobUploadLabel"> {values.JobUpload}</label> */}

                <TickSquare  size="16" color="#2727ff"/>
                {values.files2 == 1 && <label id="files2">{t('File Must be Lower than 4MB')}</label>}
          
        </div>
      </div>
      <div className="personalInfoRowFour col-12">
        <Link onClick={handleCancelJob} to="/Dashboard" id="JobCancel" className="btn col-2 btn-outline-light signup-btn">{t('Cancel')}</Link>
        {!ispending && !formError && <button id="JobSave" className="btn btn-dark login-btn col-2">{t('Save')}</button>}
        {formError && <button  className="btn btn-dark login-btn col-2" id="JobSave" disabled>{t('Save')}</button>}
        {ispending && <button className="btn btn-dark login-btn col-2" id="JobSave" disabled>{t('Being login...')}</button>}
      </div>
    </form>}
   


    
      <div className="profileInfo col-12">
          <div className="personalInfoRowOne col-12">
            <div className="penAndPerson">
            <h4>{t('Profile Information')}</h4>
              <div className="pen">
             
              </div>
            </div>
            <div className="greyAttention">
             
              <p></p>
            </div>
          </div>
          
          <div className="personalInfoRowFourLast">
          <div className="input-placeholder col-lg-4 col-12">
                  <label id="userNameLast" htmlFor="proPhone">{t('User Name')}</label>
                  <input 
                    type="text"
                    className="form-control"
                    id="userNameLast"
                    required 
                    disabled
                    value={values.userNameLast}
                    />
                  <div id="MyuserNameLastlplaceholder" className="placeholderProEmail">
                  {t('User Name')}
                  <span id="MyuserNameLastSapn"></span>
                  </div>
                  <label id="errorLabeluserNameLast"></label>
              </div>
              <div className="input-placeholder col-lg-8 col-12">
                    {!ispending && !formError && <button id="ChangePasswordSave" onClick={handleSendEmail} className="btn btn-dark login-btn col-lg-4 col-12">{t('Change Password')}</button>}
                    {formError && <button  className="btn btn-dark login-btn col-6" id="ChangePasswordSave" disabled>{t('Change Password')}</button>}
                    {ispending && <button className="btn btn-dark login-btn col-6" id="ChangePasswordSave" disabled>{t('Being login...')}</button>}
                    <button id="ScrollUp" onClick={handleScrollUp} className="ScrollUp d-lg-none"><ArrowUp2 size="20" color="#313131"/></button>
              </div>
           
          </div>
      </div>
    
    {isOpen && <Popup
  content={<>
  <div className="PboxMainRow">
        <div className="PboxFirstRow">
            <ShieldSecurity size="48" color="#313131" variant="Bold"/>
        </div>
        <div className="PboxSecondRow">
        <h4>{t('Are you sure?')}</h4>
        </div>
        <div className="PboxThirdRow">
            <p>{t('We will send a link to ')}{localStorage.getItem('email')}{t(' for reset your password')}</p>
        </div>
        <div className="PboxForthRowSendMail col-12">
            
            <button onClick={togglePopup} className="col-md-3 col-6 btn btn-outline myBlackBtn">{t('Cancel')}</button>
            <button onClick={handleSubmitChangePassword} className="col-md-3 col-6 btn btn-dark">{t('Send Link!')}</button>
        </div>
    
    </div>
  </>}
  handleClose={togglePopup}
/>}


{isOpen2 && <Popup
  content={<>
  <div className="PboxMainRow">
        <div className="PboxFirstRow">
            <ShieldSecurity size="48" color="#313131" variant="Bold"/>
        </div>
        <div className="PboxSecondRow">
        <h4>{t('Dear User Pay Attention!')}</h4>
        </div>
        <div className="PboxThirdRow">
            <p> <b>{t('You Can edit your information JUST ONE TIME!!!')}</b> </p>
        </div>
        <div className="PboxForthRowSendMail col-12">
            
            <button onClick={togglePopup2} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
            <button onClick={handleSubmitSubmitPopPersonal} className="col-3 btn btn-dark">{t('Submit')}</button>
        </div>
    
    </div>
  </>}
  handleClose={togglePopup2}
/>}

{isOpen3 && <Popup
  content={<>
  <div className="PboxMainRow">
        <div className="PboxFirstRow">
            <ShieldSecurity size="48" color="#313131" variant="Bold"/>
        </div>
        <div className="PboxSecondRow">
        <h4>{t('Dear User Pay Attention!')}</h4>
        </div>
        <div className="PboxThirdRow">
            <p> <b>{t('You Can edit your information JUST ONE TIME!!!')}</b> </p>
        </div>
        <div className="PboxForthRowSendMail col-12">
            
            <button onClick={togglePopup3} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
            <button onClick={handleSubmitSubmitPopAddress} className="col-3 btn btn-dark">{t('Submit')}</button>
        </div>
    
    </div>
  </>}
  handleClose={togglePopup3}
/>}


{isOpen4 && <Popup
  content={<>
  <div className="PboxMainRow">
        <div className="PboxFirstRow">
            <ShieldSecurity size="48" color="#313131" variant="Bold"/>
        </div>
        <div className="PboxSecondRow">
        <h4>{t('Dear User Pay Attention!')}</h4>
        </div>
        <div className="PboxThirdRow">
            <p> <b>{t('You Can edit your information JUST ONE TIME!!!')}</b> </p>
        </div>
        <div className="PboxForthRowSendMail col-12">
            
            <button onClick={togglePopup4} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
            <button onClick={handleSubmitSubmitPopEducational} className="col-3 btn btn-dark">{t('Submit')}</button>
        </div>
    
    </div>
  </>}
  handleClose={togglePopup4}
/>}

{isOpen5 && <Popup
  content={<>
  <div className="PboxMainRow">
        <div className="PboxFirstRow">
            <ShieldSecurity size="48" color="#313131" variant="Bold"/>
        </div>
        <div className="PboxSecondRow">
        <h4>{t('Dear User Pay Attention!')}</h4>
        </div>
        <div className="PboxThirdRow">
            <p> <b>{t('You Can edit your information JUST ONE TIME!!!')}</b> </p>
        </div>
        <div className="PboxForthRowSendMail col-12">
            
            <button onClick={togglePopup5} className="col-3 btn btn-outline myBlackBtn">{t('Cancel')}</button>
            <button onClick={handleSubmitSubmitPopJob} className="col-3 btn btn-dark">{t('Submit')}</button>
        </div>
    
    </div>
  </>}
  handleClose={togglePopup5}
/>}

 </div>
  );
 }
 
export default Profile;